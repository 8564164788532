<form [formGroup]="formGroup" (keydown.enter)="$event.preventDefault()">
  <h5>Work Description and Notifications</h5>
  <div class="row">
    <div class="col-6">
      <mat-form-field
        appearance="outline"
        [ngClass]="{
          hilite: scfMaster && status?.isViewingAmendment && diffLocations()
        }"
        ><mat-label>Locations</mat-label>
        <mat-chip-grid
          #chipListLoc
          aria-label="Location selection"
          [disabled]="disableControls"
        >
          <mat-chip-row
            *ngFor="let location of locations"
            [ngClass]="{
              'manual-entry': location.newLocation,
              Location: !location.newLocation,
              AutoEntry: location.autoLocation
            }"
            [selectable]="!disableControls"
            [removable]="!disableControls"
            (removed)="removeLoc(location)"
          >
            {{ location?.name }}
            <mat-icon
              matChipRemove
              *ngIf="!disableControls && !checkRemove(location)"
              class="d-print-none"
              >cancel</mat-icon
            >
          </mat-chip-row>
          <input
            aria-label="locations"
            #locationInput
            formControlName="locationsCtrl"
            placeholder="Enter Locations"
            [matAutocomplete]="autoLoc"
            [matChipInputFor]="chipListLoc"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
            [matChipInputAddOnBlur]="addOnBlur"
            (matChipInputTokenEnd)="addLoc($event)"
            (keyup)="onLocChange($event.code)"
            #trigger="matAutocompleteTrigger"
          />
        </mat-chip-grid>
        <mat-autocomplete
          #autoLoc="matAutocomplete"
          (optionSelected)="selectedLoc($event)"
          #locationAutocomplete
        >
          <mat-optgroup
            *ngFor="let group of locationGroups"
            [label]="group.name ?? ''"
          >
            <mat-option
              *ngFor="let location of group.resources"
              [value]="location"
            >
              {{ location?.name }}
            </mat-option>
          </mat-optgroup>
        </mat-autocomplete>
        <mat-error *ngIf="locationsCtrl?.invalid">{{
          getErrorMsg(locationsCtrl)
        }}</mat-error>
      </mat-form-field>

      <!-- ASCC Links -->
      <related-documents
        [relatedDocuments]="documents"
        [formID]="scfMaster?.id"
        [formType]="formTypeEnum.SCF"
        [disabled]="disableControls"
        [hilite]="
          scfMaster && this.status?.isViewingAmendment && diffDocuments()
        "
        (changed)="documents = $event; setValues()"
      ></related-documents>

      <!-- Related Documents and Links -->
      <related-link-chips
        [relatedLinks]="relatedLinks"
        [disabled]="disableControls"
        [formId]="scfMaster?.id"
        [hilite]="scfMaster && status?.isViewingAmendment && diffRelatedLinks()"
        (onEdited)="relatedLinks = $event; setValues()"
      ></related-link-chips>
    </div>

    <div class="col-md-12 col-lg-6">
      <mat-form-field
        floatLabel="always"
        class="pt-1 work-required"
        [ngClass]="{
          hilite: scfMaster && status?.isViewingAmendment && diffWorkRequired()
        }"
      >
        <mat-label>Type of Work</mat-label>
        <mat-checkbox
          [ngClass]="{
            hilite:
              scfMaster &&
              status?.isViewingAmendment &&
              checkAccRFCtrl?.value != scfMaster.accRFShielding
          }"
          (change)="setNotificationChecks(workRequired.AccRFShielding, $event)"
          formControlName="checkAccRFCtrl"
          >{{
            scfMaster?.newWorkLabel === false
              ? "Acc/RF Shielding: Add or
					Remove/Replace [Verifier - OIC]"
              : "Remove & Replace ACC/BL/RF
					Shielding"
          }}</mat-checkbox
        >
        <br *ngIf="scfMaster?.newWorkLabel === false" />
        <mat-checkbox
          [ngClass]="{
            hilite:
              scfMaster &&
              status?.isViewingAmendment &&
              checkBlShCtrl?.value != scfMaster.blShielding
          }"
          *ngIf="scfMaster?.newWorkLabel === false"
          (change)="setNotificationChecks(workRequired.BlShielding, $event)"
          formControlName="checkBlShCtrl"
        >
          BL Shielding: Add or Remove/Replace [Verifier - Floor Op] </mat-checkbox
        ><br />
        <mat-checkbox
          [ngClass]="{
            hilite:
              scfMaster &&
              status?.isViewingAmendment &&
              checkTocaMovCtrl?.value != scfMaster.tocaMovement
          }"
          (change)="setNotificationChecks(workRequired.TocaMovement, $event)"
          formControlName="checkTocaMovCtrl"
          >{{
            scfMaster?.newWorkLabel === false
              ? "Toca Movement [Verifier -
					S&A]"
              : "TOCA Shielding Movement [Verifier - S&A]"
          }}</mat-checkbox
        >
        <br />
        <mat-checkbox
          [ngClass]="{
            hilite:
              scfMaster &&
              status?.isViewingAmendment &&
              checkPermBlCtrl?.value != scfMaster.permanentBLRemoval
          }"
          (change)="
            setNotificationChecks(workRequired.PermanentBLRemoval, $event)
          "
          formControlName="checkPermBlCtrl"
        >
          <span
            *ngIf="scfMaster?.serialNo ?? '' > 'SCF-22-064'; else elseBlock1"
            >Add, Permanently Remove, Modify, or Align Beamline Shielding
            [Verifiers - RP]</span
          >
          <ng-template #elseBlock1>
            <span
              >"Add, Remove, or Permanently Modify Beamline Shielding [Verifiers
              - RP, HP]"</span
            >
          </ng-template>
        </mat-checkbox>
        <br />
        <mat-checkbox
          [ngClass]="{
            hilite:
              scfMaster &&
              status?.isViewingAmendment &&
              checkPermAccCtrl?.value != scfMaster.permanentAccRemoval
          }"
          (change)="
            setNotificationChecks(workRequired.PermanentAccRemoval, $event)
          "
          formControlName="checkPermAccCtrl"
        >
          <span
            *ngIf="scfMaster?.serialNo ?? '' > 'SCF-22-064'; else elseBlock2"
            >Add, Permanently Remove, Modify, or Align Accelerator Shielding
            [Verifiers - RP]</span
          >
          <ng-template #elseBlock2>
            <span
              >"Add, Remove, or Permanently Modify Accelerator Shielding
              [Verifiers - RP, HP]"</span
            >
          </ng-template>
        </mat-checkbox>
        <input
          matInput
          [hidden]="true"
          aria-label="hidden"
          formControlName="inputWorkHiddenCtrl"
        />
        <mat-error *ngIf="inputWorkHiddenCtrl?.invalid">{{
          getErrorMsg(inputWorkHiddenCtrl)
        }}</mat-error>
      </mat-form-field>
    </div>
    <div class="col-12 mt-3">
      <mat-form-field
        cdkDropListGroup
        class="hilited-input"
        [ngClass]="{
          hilite: scfMaster && status?.isViewingAmendment && diffShieldings()
        }"
      >
        <mat-label class="shieldings-label">Shielding Affected</mat-label>
        <mat-chip-grid
          #chipListShl
          aria-label="Shielding selection"
          cdkDropList
          cdkDropListOrientation="horizontal"
          (cdkDropListDropped)="dropListDropped($event)"
          [cdkDropListData]="shieldings"
          [disabled]="disableControls"
        >
          <mat-chip-row
            cdkDrag
            (cdkDragMoved)="dragMoved($event)"
            [cdkDragDisabled]="disableControls"
            [cdkDragData]="shielding"
            *ngFor="let shielding of sortedShieldings()"
            [selectable]="!disableControls"
            [removable]="!disableControls"
            (removed)="removeShl(shielding)"
            class="matChipStyle chip-row-height"
          >
            <span
              [matTooltip]="shielding.childResource?.keText ?? ''"
              class="tool-tip d-flex align-items-center text-wrap"
              [ngClass]="{
                red: shared(shielding),
                yellow: shielding.childResourceType == 99
              }"
            >
              <mat-icon
                matChipTrailingIcon
                cdkDragHandle
                *ngIf="!disableControls"
                class="d-print-none ml--6 mr-1 p-0"
                >drag_indicator</mat-icon
              >
              {{ shielding.childResourceName }}
              <mat-icon
                matChipRemove
                *ngIf="!disableControls"
                class="d-print-none"
                >cancel</mat-icon
              ></span
            >
          </mat-chip-row>
          <input
            placeholder="Shielding Affected"
            aria-label="shieldings"
            #shieldingInput
            formControlName="shieldingsCtrl"
            [matAutocomplete]="autoShl"
            (focus)="shieldingInput.focus()"
            [matChipInputFor]="chipListShl"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
            [matChipInputAddOnBlur]="addOnBlur"
            (matChipInputTokenEnd)="addShl($event)"
            (keydown.enter)="addShl($event)"
            (keyup)="onShlChange($event.code)"
          />
        </mat-chip-grid>
        <mat-autocomplete
          #autoShl="matAutocomplete"
          (optionSelected)="selectedShl($event)"
        >
          <mat-optgroup
            *ngFor="let group of shieldingGroups"
            [label]="group.name ?? ''"
          >
            <mat-option
              *ngFor="let shielding of group.shieldings"
              [value]="shielding"
              [ngClass]="{
                red:
                  shielding.parentResourceType == resourceTypeEnum.Shutter
                    ? shielding.shared
                    : shielding.sharedWith?.length ?? 0 > 0,
                yellow: shielding.childResourceType == 99
              }"
            >
              {{ shielding.childResourceName }}
            </mat-option>
          </mat-optgroup>
        </mat-autocomplete>

        <mat-hint>
          ONLY work on the shielding listed above. Shared Shielding indicated in
          red color, TOCAs in yellow.
        </mat-hint>
        <mat-error *ngIf="shieldingsCtrl?.invalid">{{
          getErrorMsg(shieldingsCtrl)
        }}</mat-error>
      </mat-form-field>
    </div>
  </div>
  <div class="row mt-4 mb-2">
    <div class="col-12">
      <!-- Description of work being performed section -->
      <comments-box
        [control]="descriptionCtrl"
        [textValue]="descriptionCtrlText"
        [disabled]="disableControls"
        formType="SCF"
        [hasError]="descriptionHasError"
        [serialNo]="scfMaster?.serialNo"
        title="Work Description"
        (textChanged)="descriptionChanged($event)"
        [hilite]="
          status?.isViewingAmendment &&
          scfMaster?.description != scfAmendment?.description
        "
      ></comments-box>
    </div>
  </div>
  <!-- First section Footer -->
  <div class="row top-margin">
    <div class="col-12 col-md-4 col-lg-3">
      <mat-form-field
        [ngClass]="{
          hilite:
            scfMaster &&
            status?.isViewingAmendment &&
            requesterCtrl?.value != scfMaster.requesterId
        }"
      >
        <mat-label>Requester</mat-label>

        <mat-select
          matNativeControl
          name="requester"
          formControlName="requesterCtrl"
          (selectionChange)="selectedRequester($event)"
        >
          <mat-option [value]="user.id" *ngFor="let user of requesters">{{
            user.name
          }}</mat-option>
        </mat-select>
        <mat-error *ngIf="requesterCtrl?.invalid">{{
          getErrorMsg(requesterCtrl)
        }}</mat-error>
      </mat-form-field>
    </div>

    <div class="col-12 col-lg-3 d-md-none d-lg-flex"></div>
    <div class="col-12 col-md-4 col-lg-3">
      <div class="inline">
        <mat-form-field
          [ngClass]="{
            hilite:
              scfMaster &&
              status?.isViewingAmendment &&
              estimatedInitCtrl?.value != scfMaster.estimatedStartOn
          }"
        >
          <mat-label>Estimate Start Date</mat-label>
          <input
            matInput
            [matDatepicker]="startDate"
            [min]="today"
            placeholder="Estimate Start Date"
            name="startDate"
            (dateChange)="changedDate($event)"
            formControlName="estimatedInitCtrl"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="startDate"
          ></mat-datepicker-toggle>
          <mat-datepicker #startDate></mat-datepicker>
          <mat-error *ngIf="estimatedInitCtrl?.invalid">{{
            getErrorMsg(estimatedInitCtrl)
          }}</mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="col-12 col-md-4 col-lg-3">
      <div class="inline">
        <mat-form-field
          [ngClass]="{
            hilite:
              scfMaster &&
              status?.isViewingAmendment &&
              estimatedEndCtrl?.value != scfMaster.estimatedEndOn
          }"
        >
          <mat-label>Estimate Completion Date</mat-label>
          <input
            matInput
            [matDatepicker]="endDate"
            [min]="estimatedInitCtrl?.value"
            placeholder="Estimate Completion Date"
            name="endDate"
            (dateChange)="setValues()"
            formControlName="estimatedEndCtrl"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="endDate"
          ></mat-datepicker-toggle>
          <mat-datepicker #endDate></mat-datepicker>
          <mat-error *ngIf="estimatedEndCtrl?.invalid">{{
            getErrorMsg(estimatedEndCtrl)
          }}</mat-error>
        </mat-form-field>
      </div>
    </div>
  </div>

  <!-- Second section Footer -->

  <div class="row">
    <div class="col-12">
      <mat-form-field
        floatLabel="always"
        [ngClass]="{
          hilite:
            scfMaster && status?.isViewingAmendment && diffNotificationChecks()
        }"
      >
        <mat-label> Notify </mat-label>

        <input
          aria-label="hidden"
          matInput
          [hidden]="true"
          formControlName="inputNotificationsHiddenCtrl"
        />
        <div class="row">
          <div class="col-9 d-flex w-100 justify-content-between pt-2">
            <div>
              <mat-checkbox
                [ngClass]="{
                  hilite:
                    scfMaster &&
                    status?.isViewingAmendment &&
                    notiCheckHPCtrl?.value != scfMaster.notifyHP
                }"
                matTooltip="ALS Health Physicist"
                formControlName="notiCheckHPCtrl"
                (change)="saveNotification()"
                >HP
              </mat-checkbox>
            </div>
            <div>
              <mat-checkbox
                [ngClass]="{
                  hilite:
                    scfMaster &&
                    status?.isViewingAmendment &&
                    notiCheckBLCtrl?.value != scfMaster.notifyBLSci
                }"
                matTooltip="Beamline Scientist"
                formControlName="notiCheckBLCtrl"
                (change)="saveNotification()"
                >BL Sci</mat-checkbox
              >
            </div>
            <div>
              <mat-checkbox
                [ngClass]="{
                  hilite:
                    scfMaster &&
                    status?.isViewingAmendment &&
                    notiCheckBRCCtrl?.value != scfMaster.notifyBRC
                }"
                matTooltip="BRC Chairs"
                formControlName="notiCheckBRCCtrl"
                (change)="saveNotification()"
                >BRC Chairs</mat-checkbox
              >
            </div>
            <div>
              <mat-checkbox
                [ngClass]="{
                  hilite:
                    scfMaster &&
                    status?.isViewingAmendment &&
                    notiCheckRFCtrl?.value != scfMaster.notifyRF
                }"
                matTooltip="RF Group"
                formControlName="notiCheckRFCtrl"
                (change)="saveNotification()"
                >RF Group</mat-checkbox
              >
            </div>
            <div>
              <mat-checkbox
                [ngClass]="{
                  hilite:
                    scfMaster &&
                    status?.isViewingAmendment &&
                    notiCheckSACtrl?.value != scfMaster.notifySnA
                }"
                matTooltip="Survey and Alignment"
                formControlName="notiCheckSACtrl"
                (change)="saveNotification()"
                >S&A</mat-checkbox
              >
            </div>
            <div>
              <mat-checkbox
                [ngClass]="{
                  hilite:
                    scfMaster &&
                    status?.isViewingAmendment &&
                    notiCheckARCtrl?.value != scfMaster.notifyARC
                }"
                matTooltip="ARC Chairs"
                formControlName="notiCheckARCtrl"
                (change)="saveNotification()"
                >ARC Chairs</mat-checkbox
              >
            </div>
            <div>
              <mat-checkbox
                [ngClass]="{
                  hilite:
                    scfMaster &&
                    status?.isViewingAmendment &&
                    notiCheckRPCtrl?.value != scfMaster.notifyRP
                }"
                matTooltip="RP Group"
                formControlName="notiCheckRPCtrl"
                (change)="saveNotification()"
                >RP</mat-checkbox
              >
            </div>
          </div>
          <div
            class="col-3 d-print-none d-flex w-100 justify-content-end center"
          >
            <button
              type="button"
              mat-stroked-button
              *ngIf="scfMaster && scfMaster.status == formStatusEnum.Draft"
              [disabled]="
                scfMaster.editingBy?.id !== currentUser?.id || disableControls
              "
              class="float-right mr-2"
              (click)="editOlog()"
              color="primary"
            >
              Edit Olog
            </button>
            <button
              type="button"
              mat-stroked-button
              *ngIf="scfMaster && scfMaster.status == formStatusEnum.Draft"
              [disabled]="notificationButtonDisabled"
              (click)="editNotification()"
              color="accent"
              class="float-right"
            >
              {{ notificationButtonLabel }}
            </button>
          </div>
        </div>
      </mat-form-field>
    </div>
  </div>
  <h5>Hazard Mitigation & Work Authorizations</h5>
</form>

<cl-image-upload-info
  [width]="300"
  [height]="150"
  [left]="10"
  [top]="10"
  [rowId]="row?.rowId"
  [actionType]="action"
  [imageId]="imageId"
  [imageList]="imagesList"
  *ngIf="row || showImageOptions"
  (cancel)="setRow(null)"
  (imageChanged)="imageChanged($event)"
>
  ></cl-image-upload-info
>
<button
  matTooltip="Add Image"
  matTooltipClass="blue white-background"
  mat-icon-button
  *ngIf="!images?.length && checkRoles() && !editor && !builder"
  (click)="showImageDialog()"
>
  <mat-icon color="primary">add_photo_alternate</mat-icon>
</button>
<div class="container-fluid">
  <div class="row">
    <div #containerCanvas class="col-12 p-0">
      <canvas
        #mycanvas
        width="0"
        height="0"
        class="canva"
        [ngClass]="{ adding: uploadImageTable?.addingSpot ?? false }"
        (click)="imageClicked($event)"
      ></canvas>
    </div>
  </div>

  <div class="row">
    <div class="col-12 p-0 small-font">
      <cl-image-upload-table
        #mytable
        [disabled]="disabled ?? false"
        [configuration]="configuration ?? ''"
        [editor]="editor ?? false"
        [builder]="builder ?? false"
        [enableColumnsBuilder]="enableColumnsBuilder"
        (newRow)="addRow($event)"
        (rowDeleted)="deleteRow($event)"
        (rowDuplicated)="duplicateRow($event)"
        (spotDeleted)="deleteSpot($event)"
        (tableChanged)="tableChange($event)"
        [canEdit]="checkRoles() && !disabled"
        (setRow)="setRow($event)"
        (setLoading)="loading.emit($event)"
      ></cl-image-upload-table>
    </div>
  </div>
</div>

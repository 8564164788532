<mat-form-field class="d-print-none">
  <input
    matInput
    [placeholder]="placeholder ?? 'Select Restriction'"
    #restrictionInput
    [matAutocomplete]="restrictionAutocomplete"
    [formControl]="control"
    (keyup)="onRestrictionInputKeyup($event)"
    (blur)="blur(restrictionInput.value)"
  />
  <mat-autocomplete
    #restrictionAutocomplete="matAutocomplete"
    (optionSelected)="select($event.option.value)"
  >
    <mat-option
      *ngFor="let restriction of uniqueRestrictions"
      [value]="restriction"
    >
      {{ restriction.restrictionLanguage }}
    </mat-option>
  </mat-autocomplete>
  <mat-error *ngIf="control?.invalid">{{ getErrorMsg(control) }}</mat-error>
</mat-form-field>

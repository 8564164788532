<div class="ml-0 mr-0 mt-4 pl-3" *ngIf="sectionInfo">
  <div class="row">
    <div class="col-6">
      <h5>Review and Concurrence</h5>
    </div>
    <div class="col-6 d-flex justify-content-end">
      <span *ngIf="showReloadButton" class="btn m-0">
        <button
          mat-stroked-button
          color="warn"
          (click)="refresh()"
          [disabled]="disableButtons"
        >
          <mat-icon color="warn">refresh</mat-icon> Reload BL Staff
        </button>
      </span>
      <span
        *ngIf="
          section?.showCompleteButton && absi?.status == formStatusEnum.Active
        "
        class="btn m-0"
      >
        <button
          mat-flat-button
          color="primary"
          (click)="complete()"
          [disabled]="disableButtons"
        >
          Inspections Complete
        </button>
      </span>
      <span
        *ngIf="
          section?.showCloseButton &&
          absi?.status == formStatusEnum.ReviewAndConcurrence
        "
        class="btn m-0"
      >
        <button
          mat-flat-button
          color="accent"
          (click)="revert()"
          [disabled]="disableButtons"
        >
          Return to Inspections
        </button>
      </span>
    </div>
  </div>

  <absi-sectionX
    id="section"
    #section
    [section]="sectionInfo"
    [formId]="absi?.id"
    [sectionId]="sectionInfo.id"
    [absi]="absi"
    [message]="message"
    [collapse]="false"
  ></absi-sectionX>
</div>
<div class="row">
  <div class="col-12 d-flex justify-content-end">
    <span
      *ngIf="
        section?.showCloseButton &&
        absi?.status == formStatusEnum.ReviewAndConcurrence
      "
      class="btn m-0"
    >
      <button mat-stroked-button color="primary" (click)="editOlog()">
        Edit Olog Notification&nbsp;<mat-icon color="primary"
          >feedback</mat-icon
        >
      </button>
    </span>
  </div>

  <div class="col-12 close-col">
    <cl-close-action
      [currentChecklist]="absi"
      [disabled]="
        !(
          section?.showCloseButton &&
          absi?.status == formStatusEnum.ReviewAndConcurrence
        )
      "
      [roleCodes]="section?.closeSignature?.roleCodes"
      [roleIds]="section?.closeSignature?.roles"
      title="Close ABSI Checklist"
      [value]="section?.closeSignature?.approved"
      [user]="section?.closeSignature?.approvedBy"
      [date]="section?.closeSignature?.approvedOn"
      [taskID]="section?.closeSignature?.taskId"
      (loading)="loading.emit($event)"
      (closed)="close()"
    ></cl-close-action>
  </div>
</div>

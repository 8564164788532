import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { StatusPendingCatalog, ActionPendingCatalog, Catalog } from 'src/app/common/enumerations/enumerations';
import { YesNoDialogComponent } from 'src/app/controls/yes-no-dialog/yes-no-dialog.component';
import { PendingCatalogs, PendingAccelerator, PendingResource, PendingUser, PendingRole, PendingNotificationTemplate } from 'src/app/services/pending-approvals/pending-approval';
import { CatalogItem } from '../pending-approvals-catalogs/pending-approvals-catalogs.component';
import { PendingApprovalsV2Service } from '../pending-approvals-v2.service';
import { CatalogService } from '../../catalogs/catalog-service';

@Component({
  selector: 'pending-approvals-changes',
  templateUrl: './pending-approvals-changes.component.html',
  styleUrls: ['./pending-approvals-changes.component.scss']
})
export class PendingApprovalsChangesComponent implements OnInit, OnDestroy {

  @Output() updateMyChanges = new EventEmitter<CatalogItem[]>();

  pendingCatalogs!: PendingCatalogs;
  pendingCatalogs$!: Observable<PendingCatalogs>;
  pendingCatalogsSubs!: Subscription;

  public changePendingApproval!: boolean;
  pendingApprovals!: number;

  public isRemoving!: boolean;

  catalog = Catalog;

  myChangesData: CatalogItem[] = [{ name: 'Accelerator Catalog', id: 'accCatalog' }, { name: 'Beamline Catalog', id: 'beamCatalog' }, { name: 'Users', id: 'users' }, { name: 'Roles', id: 'roles' }, { name: 'Notification Templates', id: 'notificationTemplates' }, { name: 'RSS Database', id: 'rssdb' }];
  public rolesDisplayedColumns: string[] = ['name', 'status', 'changes', 'action', 'date', 'disapprovalReason', 'remove'];
  public displayedColumns: string[] = ['name', 'status', 'action', 'date', 'disapprovalReason', 'remove'];
  public dataSourcePendingAccelerator!: MatTableDataSource<PendingAccelerator>;
  public dataSourcePendingResource!: MatTableDataSource<PendingResource>;
  public dataSourcePendingUser!: MatTableDataSource<PendingUser>;
  public dataSourcePendingRole!: MatTableDataSource<PendingRole>;
  public dataSourcePendingNotificationTemplate!: MatTableDataSource<PendingNotificationTemplate>;
  public dataSourcePendingRSSDatabase!: MatTableDataSource<PendingResource>;

  constructor(
    private _catalogService: CatalogService,
    private router: Router,
    private dialog: MatDialog,
    private store: Store,
    private service: PendingApprovalsV2Service
  ) { }

  ngOnDestroy(): void {
    this.pendingCatalogsSubs?.unsubscribe();
  }

  ngOnInit(): void {
    this.clear();
    this.loadPendingApprovals();
  }

  refresh() {
    this.clear();
    this.loadPendingApprovals();
  }


  loadPendingApprovals() {
    this.pendingCatalogs$ = this.store.select(state => state.MyPendingCatalogs.data);
    this.pendingCatalogsSubs = this.pendingCatalogs$.subscribe(() => {
      this.service.getMyPendingChanges().then(data => {
        this.dataSourcePendingAccelerator = new MatTableDataSource(data.accelerators);
        this.dataSourcePendingUser = new MatTableDataSource(data.users);
        this.dataSourcePendingRole = new MatTableDataSource(data.roles);
        this.dataSourcePendingResource = new MatTableDataSource(data.resources);
        this.dataSourcePendingNotificationTemplate = new MatTableDataSource(data.notifications);
        this.dataSourcePendingRSSDatabase = new MatTableDataSource(data.rssDatabase);
        this.pendingApprovals = data.total ?? 0;
        const dataMappings = [
          { id: 'accCatalog', dataSource: this.dataSourcePendingAccelerator },
          { id: 'beamCatalog', dataSource: this.dataSourcePendingResource },
          { id: 'rssdb', dataSource: this.dataSourcePendingRSSDatabase },
          { id: 'users', dataSource: this.dataSourcePendingUser },
          { id: 'roles', dataSource: this.dataSourcePendingRole },
          { id: 'notificationTemplates', dataSource: this.dataSourcePendingNotificationTemplate }
        ];

        for (const mapping of dataMappings) {
          const item = this.myChangesData.find(x => x.id === mapping.id);
          if (item) {
            item.qty = mapping.dataSource.data.length;
          }
        }
        this.updateMyChanges.emit(this.myChangesData);
      });
    });
  }

  setItemAccelerator(pendingAccelerator: PendingAccelerator) {
    if (!this.isRemoving && !(pendingAccelerator.status == StatusPendingCatalog.Disapproved && pendingAccelerator.action == ActionPendingCatalog.Create)) {
      this._catalogService.currentDocAccelerator = pendingAccelerator;
      this.router.navigate(['/accelerator-catalog']);
    }
  }

  setItemResource(pendingResource: PendingResource) {
    if (!this.isRemoving && !(pendingResource.status == StatusPendingCatalog.Disapproved && pendingResource.action == ActionPendingCatalog.Create)) {
      this._catalogService.currentDocResource = pendingResource;
      this.router.navigate(['/resources-new']);
    }
  }

  setItemUser(pendingUser: PendingUser) {
    if (!this.isRemoving && !(pendingUser.status == StatusPendingCatalog.Disapproved && pendingUser.action == ActionPendingCatalog.Create)) {
      this._catalogService.currentDocUser = pendingUser;
      this.router.navigate(['/users']);
    }
  }

  setItemRole(pendingRole: PendingRole) {
    if (!this.isRemoving && !(pendingRole.status == StatusPendingCatalog.Disapproved && pendingRole.action == ActionPendingCatalog.Create)) {
      this._catalogService.currentDocRole = pendingRole;
      this.router.navigate(['/roles']);
    }
  }

  setItemNotificationTemplate(pendingNotificationTemplate: PendingNotificationTemplate) {
    if (!this.isRemoving && !(pendingNotificationTemplate.status == StatusPendingCatalog.Disapproved && pendingNotificationTemplate.action == ActionPendingCatalog.Create)) {
      this._catalogService.currentDocNotificationTemplate = pendingNotificationTemplate;
      this.router.navigate(['/email-notification-catalog']);
    }
  }

  setItemRssDb(pendingResource: PendingResource) {
    this._catalogService.currentDocResource = pendingResource;
    this.router.navigate(['/resources-new/2']);
  }

  calculateTotals() {
    this.pendingApprovals = this.dataSourcePendingAccelerator.data.length + this.dataSourcePendingResource.data.length + this.dataSourcePendingRole.data.length + this.dataSourcePendingUser.data.length;

    const dataMappings = [
      { id: 'accCatalog', dataSource: this.dataSourcePendingAccelerator },
      { id: 'beamCatalog', dataSource: this.dataSourcePendingResource },
      { id: 'rssdb', dataSource: this.dataSourcePendingRSSDatabase },
      { id: 'users', dataSource: this.dataSourcePendingUser },
      { id: 'roles', dataSource: this.dataSourcePendingRole },
      { id: 'notificationTemplates', dataSource: this.dataSourcePendingNotificationTemplate }
    ];

    for (const mapping of dataMappings) {
      const item = this.myChangesData.find(x => x.id === mapping.id);
      if (item) {
        item.qty = mapping.dataSource.data.length;
      }
    }
    this.updateMyChanges.emit(this.myChangesData);
  }

  clear() {
    this.dataSourcePendingAccelerator = new MatTableDataSource();
    this.dataSourcePendingResource = new MatTableDataSource();
    this.dataSourcePendingUser = new MatTableDataSource();
    this.dataSourcePendingRole = new MatTableDataSource();

    this.changePendingApproval = false;
    this.isRemoving = false;
  }

  removeRow(pendingElement: any, catalogId: number) {
    this.isRemoving = true;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { message: 'Are you sure you want to remove this element from the list?' };
    const dialogRef = this.dialog.open(YesNoDialogComponent, dialogConfig);
    dialogRef.afterClosed().toPromise().then(async (data) => {
      if (data) {
        await this._catalogService.RemoveRow(pendingElement?.id, catalogId).toPromise();
        this.loadPendingApprovals();
      }
      this.isRemoving = false;
    });
    console.log(pendingElement);
  }

}

import { Component, EventEmitter, Injector, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { Checklist } from 'src/app/components/checklists/checklists';
import { YesNoDialogComponent } from 'src/app/controls/yes-no-dialog/yes-no-dialog.component';
import { PrivilegeEnum } from 'src/app/services/role-privilege/privilege-enum';
import { RSSFunctionalTest } from '../../../rss-functional-test';
import { RSSFunctionalTestService } from 'src/app/services/rss-functional-test/rss-functional-test.service';
import { ChecklistsService } from 'src/app/components/checklists/checklists.service';
import { ChecklistBuilderEditPopupComponent, ChecklistBuilderEditPopupParameters } from 'src/app/components/checklists/checklist-builder/checklist-builder-edit-popup/checklist-builder-edit-popup.component';
import { ChecklistTempComponent } from 'src/app/components/checklists/checklist-temp/checklist-temp.component';
import { BaseComponent } from 'src/app/common/base/base.component';
import { ENTER, COMMA } from '@angular/cdk/keycodes';

@Component({
  selector: 'rss-functional-test-chips',
  templateUrl: './rss-functional-test-chips.component.html',
  styleUrls: ['./rss-functional-test-chips.component.scss']
})
export class RssFunctionalTestChipsComponent extends BaseComponent implements OnInit {
  @Input() placeholder!: string;
  @Input() disabled!: boolean;
  @Input() rssFunctionalTests?: RSSFunctionalTest[];
  @Input() amendment: boolean = false;
  @Input() control!: FormControl;

  @Output() edited = new EventEmitter();
  @Output() remove = new EventEmitter();
  @Output() error = new EventEmitter<string>();

  loading: boolean = false;
  editPrivilege: boolean | null = false;
  deletePrivilege: boolean | null = false;

  constructor(
    private rssFunctionalTestService: RSSFunctionalTestService,
    private checklistService: ChecklistsService,
    protected override injector: Injector
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.editPrivilege = this.hasPrivilege(PrivilegeEnum.RSSFunctionalTestEdit);
    this.deletePrivilege = this.hasPrivilege(PrivilegeEnum.RSSFunctionalTestDelete);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.control.disable();
    if (!this.disabled)
      this.control.enable();
  }

  removeRSSFunctionalTest(rssFunctionalTest: RSSFunctionalTest) {
    this.dialog.open(YesNoDialogComponent, {
      width: '500px',
      data: {
        message: 'Are you sure you want to delete the RSS functional test?',
        icon: 'warn'
      },
    }).afterClosed().subscribe(
      data => {
        if (data && rssFunctionalTest?.id) {
          this.loading = true;
          this.rssFunctionalTestService.delete(rssFunctionalTest.id, this.amendment).subscribe(
            () => {
              this.alert.success("The RSS functional test was deleted");
              this.remove.emit();
            },
            error => this.error.emit(error.error),
            () => this.loading = false,
          );
        }
      }
    );
  }

  editRSSFunctionalTest(rssFunctionalTest: RSSFunctionalTest) {
    if (rssFunctionalTest?.checklistID == null || rssFunctionalTest?.checklistID == undefined) {
      this.openEditPopUp(rssFunctionalTest);
    } else {
      this.dialog.open(YesNoDialogComponent, {
        width: '500px',
        data: {
          message: 'This RSS functional test has an active checklist, are you sure you want to delete the checklist to edit the template?',
          icon: 'warn'
        },
      }).afterClosed().subscribe(
        data => {
          if (data && rssFunctionalTest?.id) {
            this.rssFunctionalTestService.deleteActive(rssFunctionalTest.id, this.amendment).subscribe(
              () => this.openEditPopUp(rssFunctionalTest),
              error => this.error.emit(error.error),
            );
          }
        }
      );
    }
  }

  openEditPopUp(rssFunctionalTest: RSSFunctionalTest) {
    this.dialog.open(ChecklistBuilderEditPopupComponent, {
      width: '75vw',
      height: '85vh',
      data: {
        rssFunctionalTest: rssFunctionalTest,
        amendment: this.amendment,
      } as ChecklistBuilderEditPopupParameters,
    }).afterClosed().subscribe(
      () => {
        this.edited.emit();
      }
    );
  }

  openViewMode(rssFunctionalTest: RSSFunctionalTest) {
    this.dialog.open(ChecklistTempComponent, {
      minWidth: '60vw',
      disableClose: true,
      data: {
        checklistTemplate: rssFunctionalTest.checklistTemplate,
      }
    })
      .afterClosed().toPromise().then((checklist: Checklist) => {
        if (checklist) {
          this.checklistService.deleteChecklist(checklist.id).toPromise();
        }
      });
  }

}

<form>
  <div class="ai-box row no-gutters p-2">
    <div class="col-12 col-xl-7 pr-2">
      <div class="frame">
        <span [innerHTML]="textValueSanitized"></span>
      </div>
    </div>
    <div class="col-12 col-xl-5">
      <div class="flex-display flex-column">
        <div>
          <mat-form-field>
            <mat-label>Owner</mat-label>
            <input
              type="text"
              #ownerInput
              placeholder="Owner"
              aria-label="Owner"
              matInput
              [formControl]="ownerCtrl"
            />
          </mat-form-field>
          <mat-form-field>
            <mat-label>Location</mat-label>
            <input
              type="text"
              placeholder="Location"
              aria-label="Location"
              matInput
              [formControl]="locationCtrl"
            />
          </mat-form-field>
          <div class="d-flex align-items-center">
            <mat-label class="top-5">Due period: </mat-label>
            <mat-radio-group
              class="radio-group"
              aria-label="Select an option"
              [formControl]="radioCtrl"
            >
              <mat-radio-button
                *ngFor="let r of radioOptions"
                [value]="r.value"
              >
                {{ r.label }}
              </mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
        <div>
          <span class="float-right">
            <button
              mat-icon-button
              color="accent"
              (click)="edit()"
              *ngIf="!disable"
            >
              <mat-icon>edit</mat-icon>
            </button>
            <button
              mat-icon-button
              color="warn"
              (click)="del()"
              *ngIf="!disable"
            >
              <mat-icon>delete</mat-icon>
            </button>
          </span>
        </div>
      </div>
    </div>
  </div>
</form>

import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { AbsiCreate, AbsiDelete, AbsiRefresh, AbsiRefreshByID, AbsiUpdate } from './absi.action';
import { AbsiBuilderService } from '../../absi-builder/absi-builder.service';
import { AbsiResource } from '../../absi';
import { AbsiService } from '../../absi.service';

export class AbsiStateModel {
  data: AbsiResource[] = [];
}

@State<AbsiStateModel>({
  name: 'Absi',
  defaults: {
    data: []
  }
})

@Injectable()
export class AbsiState {
  constructor(
    private service: AbsiService
  ) { }

  @Selector()
  static read(state: AbsiStateModel) {
    return state.data;
  }

  @Action(AbsiRefresh)
  refresh({ patchState }: StateContext<AbsiStateModel>, { id }: AbsiRefresh) {
    this.service.getAll().subscribe(
      data => patchState({ data }),
      error => console.error(error)
    );
  }

  @Action(AbsiRefreshByID)
  refreshByID({ getState, patchState }: StateContext<AbsiStateModel>, { id }: AbsiRefreshByID) {
    this.service.getById(id).subscribe(data => {
      const state = getState();
      const index = state.data.map(x => x.id).indexOf(id);

      if (index >= 0) {
        state.data.splice(index, 1);
      }
      patchState({
        data: [...state.data, data]
      });
    },
      error => console.error(error)
    );
  }

  @Action(AbsiCreate)
  create({ getState, patchState }: StateContext<AbsiStateModel>, { item }: AbsiCreate) {
    patchState({
      data: [...getState().data, item]
    });
  }

  @Action(AbsiUpdate)
  update({ getState, patchState }: StateContext<AbsiStateModel>, { id, item }: AbsiUpdate) {
    const state = getState();
    const index = state.data.map(x => x.id).indexOf(id);

    if (index >= 0) {
      state.data.splice(index, 1);
      patchState({
        data: [...state.data, item]
      });
    }
  }

  @Action(AbsiDelete)
  delete({ getState, patchState }: StateContext<AbsiStateModel>, { id }: AbsiDelete) {
    patchState({
      data: getState().data.filter(x => x.id != id)
    });
  }

}

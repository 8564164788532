import { Component, OnInit, AfterViewInit, ViewChild, Output, EventEmitter, HostListener, ElementRef } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { Store } from '@ngxs/store';
import { BeamlineStatusRefresh } from './store/bl-status.action';
import { utils } from 'src/app/modules/libs/utils';
import { BeamlineObj } from 'src/app/components/catalogs/beamline-catalog/resource/resources';
import { BeamlineStatus } from './beamline-status';
import { SendIdService } from './send-id.service';
import { PV } from 'src/app/services/pv-info/pv-info';

@Component({
  selector: 'app-beam-status',
  templateUrl: './beamline-status.component.html',
  styleUrls: ['./beamline-status.component.scss']
})
export class BeamStatusComponent implements OnInit, AfterViewInit {

  @ViewChild('beamStatus')
  beamStatus!: ElementRef;
  @ViewChild('header1')
  header1!: ElementRef;
  headerOnTop = false;
  tableWidth!: string;
  headerBottom = '';

  beamlineStatuses!: BeamlineStatus[];
  beamlineStatusesFiltered!: BeamlineStatus[];
  beamlineStatuses$!: Observable<BeamlineStatus[]>;
  beamlineStatusesSubs!: Subscription;

  pvs?: PV[];
  pvs$!: Observable<PV[]>;
  pvsSubs!: Subscription;

  public displayedColumns: string[] = ['beamlineName', 'shutterName', 'shutterStatus', 'rssStatus', 'keReady'];
  public dataSource = new MatTableDataSource<BeamlineStatus>();
  public selectedRowIndex = -1;

  spanningColumns = ['beamlineName'];
  spans = [];
  // DATA!: BeamlineObj[];

  public innerWidth: any;
  public innerHeight: any;
  maxHeight: string | null = null;
  loading!: boolean;

  filter?: string;

  @ViewChild(MatSort)
  sort!: MatSort;
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;

  @Output() rowSelected = new EventEmitter<any>();
  @Output() sendId = new EventEmitter<any>();

  routerPath: string;

  constructor(
    private store: Store,
    private router: Router,
    private sendIdService: SendIdService
  ) {
    this.routerPath = router.url;
  }

  ngOnInit() {
    this.loadPVs();
    this.loadBeamlineStatuses();
  }

  loadBeamlineStatuses() {
    this.loading = true;
    this.beamlineStatuses$ = this.store.select(state => state.BeamlineStatus.data);
    this.beamlineStatusesSubs = this.beamlineStatuses$.subscribe(data => {
      if (data.length) {
        this.beamlineStatuses = data.sort((a, b) => utils.sortArrayAlphabeticallyWithComplexNumbers(a.beamlineName ?? '', b.beamlineName ?? '')).filter(d => this.filter ? (d.beamlineName?.toLowerCase().includes(this.filter) || d.shutterName?.toLowerCase().includes(this.filter)) : d);
        this.getAdditionalInfo();
        this.loading = false;
      }
    });
  }

  loadPVs() {
    this.pvs$ = this.store.select(state => state.PV.data);
    this.pvsSubs = this.pvs$.subscribe(data => {
      if (data?.length) {
        this.pvs = data;
        this.getAdditionalInfo();
      }
    });
  }

  ngAfterViewInit() {
    this.store.dispatch(new BeamlineStatusRefresh());
  }

  applyFilter(e: any) {
    this.filter = e;
    this.loadBeamlineStatuses();
  }

  SendId(id: number) {
    if (this.router.url === '/beamline-overview') {
      this.sendId.emit(id);
    } else {
      this.sendIdService.SendId(id);
      this.router.navigate(['beamline-overview']);
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: { target: { innerWidth: any; innerHeight: any; }; }) {
    this.innerWidth = event.target.innerWidth;
    this.innerHeight = event.target.innerHeight;
    this.maxHeight = (parseInt((this.innerHeight * 0.815).toFixed(0), 10) - 100) + 'px';
    this.isHeaderOnTop();
  }

  @HostListener('window:scroll')
  isHeaderOnTop() {
    let elemRec;
    if (this.beamStatus?.nativeElement !== undefined) {
      elemRec = this.beamStatus.nativeElement.getBoundingClientRect();
      // const docViewTop = window.screenTop;
      const elemTop = elemRec.top;
      const elemBottom = elemRec.bottom;
      // const headerTop = this.header1.nativeElement.getBoundingClientRect().top;
      const headerBottom = this.header1.nativeElement.getBoundingClientRect().bottom;
      if (elemTop <= headerBottom && elemBottom + 10 >= headerBottom) {
        this.headerOnTop = true;
        // console.log("Element Top: " + elemTop, "Element Bottom: " + elemBottom, "Header Bottom: " + headerBottom);
      }
      else {
        if (!(elemTop <= headerBottom && elemBottom + 10 >= headerBottom)) {
          this.headerOnTop = false;
        }
      }
      this.tableWidth = this.beamStatus.nativeElement.offsetWidth + 'px';
      this.headerBottom = headerBottom + 'px';
    } else { this.headerOnTop = false; }
  }

  tablePercentage(percent: number) {
    if (this.beamStatus) {
      return (this.beamStatus.nativeElement.offsetWidth * percent).toFixed(0);
    } else { return 50; }
  }

  statusClass(status: number) {
    switch (status) {
      case 1: return 'blue';
      case 2: return 'yellow';
      case 3: return 'res-status-color-decommissioned';
      case 4: return 'res-status-color-inactive';
      default: return '';
    }
  }

  getClass(text: string) {
    let style = '';
    if (text) {
      style = text.trim().toLowerCase();
    }
    switch (style) {
      case 'offline': return 'badge badge-offline badge-pill';
      case 'online': return 'badge badge-online badge-pill';
      case 'open': return 'badge badge-open badge-pill';
      case 'closed': return 'badge badge-closed badge-pill';
      case 'error': return 'badge badge-error badge-pill';
      default: return 'badge badge-unknown badge-pill';
    }
  }

  getAdditionalInfo() {
    this.beamlineStatuses?.map(s => {
      s.moreInfo = s.documents?.length ? s.documents.map(doc => doc.serialNo).join(', ') : null;

      const pssPV = this.pvs?.find(p => p.pvName == s.pssChannelName)
      if (pssPV)
        s.pssChannelStatus = pssPV.value == '1' ? 'Open' : 'Closed';

      const rssPV = this.pvs?.find(p => p.pvName == s.rssChannelName);
      if (rssPV) {

        switch (rssPV.value) {
          case "1":
            s.rssChannelStatus = 'Online';
            if (s.keReady)
              s.keReady.cssClass = s.keReady.ready ? "hidden" : "badge badge-danger blinking small";
            break;

          case "0":
            s.rssChannelStatus = 'Offline';
            if (s.keReady)
              s.keReady.cssClass = s.keReady.ready ? "badge badge-primary" : "badge badge-warning small";
            break;

          default:
            if (s.keReady)
              s.keReady.cssClass = "hidden";
            break;
        }
      }
      else {
        if (s.keReady)
          s.keReady.cssClass = "hidden";
      }
    });
    this.dataSource = new MatTableDataSource(this.beamlineStatuses);
  }

}

import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ResourceUserRelationComponent } from 'src/app/components/catalogs/beamline-catalog/dialogs/resource-user-relation/resource-user-relation.component';
import { AlertService } from 'src/app/services/alert/alert.service';
import { ResourceUserRelation, Resource } from 'src/app/components/catalogs/beamline-catalog/resource/resources';
import { User } from 'src/app/components/catalogs/user-catalog/services/user';

@Component({
  selector: 'app-resource-user-relation-table',
  templateUrl: './resource-user-relation-table.component.html',
  styleUrls: ['./resource-user-relation-table.component.scss']
})
export class ResourceUserRelationTableComponent implements OnInit, OnChanges {

  @Input() isEdition!: boolean;
  @Input() userTitle!: string;
  @Input() dialogTitle!: string;
  @Input() roleTitle!: string;
  @Input() roleSelected!: number;
  @Input() resourceUserRelations!: ResourceUserRelation[];
  @Input() curr?: ResourceUserRelation[];
  @Input() prev?: ResourceUserRelation[];
  @Input() branchlines!: Resource[];
  @Input() endstations!: Resource[];
  @Input() hilite?: boolean;

  @Output() changed = new EventEmitter();

  public errorMsg!: string[];

  public displayedColumnsResourceUserRelation: string[] = ['user', 'endstation', 'branchline'];
  public dataSourceResourceUserRelation: MatTableDataSource<ResourceUserRelation> = new MatTableDataSource();

  isModified!: boolean;

  constructor(
    private dialog: MatDialog,
    private alert: AlertService
  ) { }


  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['resourceUserRelations']?.currentValue) {
      this.dataSourceResourceUserRelation.data = changes['resourceUserRelations'].currentValue;
      this.checkModified();
    }
    if (changes['isEdition']) {
      if (changes['isEdition'].currentValue) { this.create(); }
      else { this.remove(); }
    }
  }

  checkModified() {
    this.isModified = false;
    if (this.curr && this.prev) {
      const prev = this.prev;
      const curr = this.curr;
      prev.map(p => {
        const q = curr.find(c => c.user?.id == p.user?.id);
        p.isModified = !q || (p.endstation?.id ?? 0) != (q.endstation?.id ?? 0) || (p.branchline?.id ?? 0) != (q.branchline?.id ?? 0);
        const rr = this.resourceUserRelations.find(r => r.user?.id == p.user?.id);
        if (rr)
          rr.isModified = p.isModified;
      });
      this.isModified = prev.some(x => x.isModified) || prev.length != curr.length;
    }
  }

  getData(data: ResourceUserRelation[]) {
    const usersIds = Array.from(new Set(data.map(d => d.userId)));
    const userRelations: UserRelation[] = [];
    usersIds.map(id => {
      const endstations = data.filter(d => d.userId == id).map(d => d.endstation?.name).filter(n => n).join(", ");
      const branchlines = data.filter(d => d.userId == id).map(d => d.branchline?.name).filter(n => n).join(", ");
      const ur: UserRelation = {
        userId: id ?? 0,
        user: data.find(d => d.userId == id)?.user,
        endstations,
        branchlines,
        isModified: false
      };
      userRelations.push(ur);
    });
    return userRelations;
  }

  create() {
    this.displayedColumnsResourceUserRelation.push('add');
  }

  remove() {
    if (this.displayedColumnsResourceUserRelation.includes('add')) {
      const index = this.displayedColumnsResourceUserRelation.indexOf('add');
      this.displayedColumnsResourceUserRelation.splice(index, 1);
    }
  }

  openAddResourceUserRelation() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      resourceUserRelation: new ResourceUserRelation(),
      branchlines: this.branchlines,
      endstations: this.endstations,
      dialogTitle: 'Add New ' + this.dialogTitle,
      roleTitle: this.roleTitle,
      roleSelected: this.roleSelected
    };
    const dialogRef = this.dialog.open(ResourceUserRelationComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((data: {
      resourceUserRlations: ResourceUserRelation[],
      user: User,
      endstationsList: string[],
      branchlinesList: string[]
    }) => {
      if (data) {
        if (this.isUserRepeated(this.dataSourceResourceUserRelation.data, data.user)) {
          this.addResourceUserRelation(this.dataSourceResourceUserRelation, data);
          this.changed.emit();
        } else {
          this.alertError();
        }
        this.errorMsg = [];
      }
    });
  }

  openEditResourceUserRelation(resourceUserRelation: ResourceUserRelation, index: number) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      resourceUserRelation,
      branchlines: this.branchlines,
      endstations: this.endstations,
      dataJoin: {
        resourceUserRlations: [],
        user: resourceUserRelation.user,
        endstationsList: resourceUserRelation.joinEndstations,
        branchlinesList: resourceUserRelation.joinBranchlines
      },
      dialogTitle: 'Edit ' + this.dialogTitle,
      roleTitle: this.roleTitle,
      roleSelected: this.roleSelected
    };
    const dialogRef = this.dialog.open(ResourceUserRelationComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((data: {
      resourceUserRlations: ResourceUserRelation[],
      user: User,
      endstationsList: string[],
      branchlinesList: string[]
    }) => {
      if (data) {
        if (this.dataSourceResourceUserRelation.data[index].user?.id !== data.user.id) {
          if (this.isUserRepeated(this.dataSourceResourceUserRelation.data, data.user)) {
            this.updateResourceUserRelations(this.dataSourceResourceUserRelation, data, index);
            this.changed.emit();
          } else {
            this.alertError();
          }
        } else {
          this.updateResourceUserRelations(this.dataSourceResourceUserRelation, data, index);
          this.changed.emit();
        }
        this.errorMsg = [];
      }
    });
  }

  deleteResourceUserRelatio(data: ResourceUserRelation) {
    const index = this.dataSourceResourceUserRelation.data.indexOf(data);
    this.dataSourceResourceUserRelation.data.splice(index, 1);
    this.dataSourceResourceUserRelation.filter = '';
  }

  isUserRepeated(resourceUserRelation: ResourceUserRelation[], user: User): boolean {
    let valid = true;
    if (resourceUserRelation.filter(x => x.user?.name === user.name).length) {
      this.errorMsg.push('User <b>' + user.name + '</b> is already added');
      valid = false;
    }
    return valid;
  }

  addResourceUserRelation(dataSourceRUR: MatTableDataSource<ResourceUserRelation>, data: { resourceUserRlations: ResourceUserRelation[], user: User, endstationsList: string[], branchlinesList: string[] }) {
    const resourceUserRelation: ResourceUserRelation = {
      user: data.user,
      joinEndstations: data.endstationsList,
      joinBranchlines: data.branchlinesList,
      resourceUserRelations: data.resourceUserRlations
    };
    dataSourceRUR.data.push(resourceUserRelation);
    dataSourceRUR.filter = '';
  }

  updateResourceUserRelations(dataSourceRUR: MatTableDataSource<ResourceUserRelation>, data: { resourceUserRlations: ResourceUserRelation[], user: User, endstationsList: string[], branchlinesList: string[] }, index: number) {
    const RURToUpdate: ResourceUserRelation = {
      user: data.user,
      joinEndstations: data.endstationsList,
      joinBranchlines: data.branchlinesList,
      resourceUserRelations: data.resourceUserRlations
    };
    dataSourceRUR.data[index] = RURToUpdate;
    dataSourceRUR.filter = '';
  }

  alertError() {
    this.alert.error(this.errorMsg.join(', '));
    const that = this;
    setTimeout(() => { that.alert.clear(); }, 3000);
  }

}

class UserRelation {
  userId!: number;
  user?: User;
  endstations!: string;
  branchlines!: string;
  isModified!: boolean;
}

import { Component, HostListener, OnInit, OnDestroy, Output, EventEmitter, Injector } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Observable, Subscription } from 'rxjs';
import { PendingApprovalsV2Service } from '../pending-approvals-v2.service';
import { BaseComponent } from 'src/app/common/base/base.component';
import { PendingCatalogs, PendingCatalog, PendingCatalogType } from 'src/app/services/pending-approvals/pending-approval';

@Component({
  selector: 'pending-approvals-catalogs',
  templateUrl: './pending-approvals-catalogs.component.html',
  styleUrls: ['./pending-approvals-catalogs.component.scss']
})
export class PendingApprovalsCatalogsComponent extends BaseComponent implements OnInit, OnDestroy {

  @Output() updateCatalogs = new EventEmitter<CatalogItem[]>();

  pendingCatalogs$!: Observable<PendingCatalogs>;
  pendingCatalogsSubs!: Subscription;

  pendingCatalogType = PendingCatalogType;

  constructor(
    protected override injector: Injector,
    private service: PendingApprovalsV2Service,
  ) {
    super(injector);
    this.service.pendingCatalogsSubject.subscribe(data => {
      this.loadPendingApprovals();
    });
  }

  public pendingApprovals!: number;

  public changePendingApproval!: boolean;

  catalogsData: CatalogItem[] = [{ name: 'Accelerator Catalog', id: 'accCatalog' }, { name: 'Beamline Catalog', id: 'beamCatalog' }, { name: 'Users', id: 'users' }, { name: 'Roles', id: 'roles' }, { name: 'Notification Templates', id: 'notificationTemplates' }, { name: 'RSS Database', id: 'rssdb' }];

  public dataSourcePendingAccelerator!: MatTableDataSource<PendingCatalog>;
  public dataSourcePendingResource!: MatTableDataSource<PendingCatalog>;
  public dataSourcePendingRSSDatabase!: MatTableDataSource<PendingCatalog>;
  public dataSourcePendingUser!: MatTableDataSource<PendingCatalog>;
  public dataSourcePendingRole!: MatTableDataSource<PendingCatalog>;
  public dataSourcePendingNotificationTemplate!: MatTableDataSource<PendingCatalog>;

  windowScrolled!: boolean;

  override  ngOnDestroy(): void {
    this.pendingCatalogsSubs?.unsubscribe();
    super.ngOnDestroy();
  }

  ngOnInit(): void {
    this.refresh();
  }

  refresh() {
    this.clear();
    this.loadPendingApprovals();
  }

  loadPendingApprovals() {
    this.dataSourcePendingAccelerator = new MatTableDataSource(this.service.pendingApprovals?.pendingAccelerators ?? []);
    this.dataSourcePendingUser = new MatTableDataSource(this.service.pendingApprovals?.pendingUsers ?? []);
    this.dataSourcePendingRole = new MatTableDataSource(this.service.pendingApprovals?.pendingRoles ?? []);
    this.dataSourcePendingResource = new MatTableDataSource(this.service.pendingApprovals?.pendingResources ?? []);
    this.dataSourcePendingNotificationTemplate = new MatTableDataSource(this.service.pendingApprovals?.pendingNotificationTemplates ?? []);
    this.dataSourcePendingRSSDatabase = new MatTableDataSource(this.service.pendingApprovals?.pendingRSSDb ?? []);
    this.pendingApprovals = this.service.pendingApprovals?.total ?? 0;
    const catalogDataMapping = [
      { id: 'accCatalog', dataSource: this.dataSourcePendingAccelerator },
      { id: 'beamCatalog', dataSource: this.dataSourcePendingResource },
      { id: 'rssdb', dataSource: this.dataSourcePendingRSSDatabase },
      { id: 'users', dataSource: this.dataSourcePendingUser },
      { id: 'roles', dataSource: this.dataSourcePendingRole },
      { id: 'notificationTemplates', dataSource: this.dataSourcePendingNotificationTemplate },
      // Add more mappings if needed
    ];

    for (const mapping of catalogDataMapping) {
      const catalog = this.catalogsData.find(x => x.id === mapping.id);
      if (catalog) {
        catalog.qty = mapping.dataSource.data.length;
      }
    }
    this.updateCatalogs.emit(this.catalogsData);
  }

  clear() {
    this.dataSourcePendingAccelerator = new MatTableDataSource();
    this.dataSourcePendingResource = new MatTableDataSource();
    this.dataSourcePendingRSSDatabase = new MatTableDataSource();
    this.dataSourcePendingUser = new MatTableDataSource();
    this.dataSourcePendingRole = new MatTableDataSource();
    this.dataSourcePendingNotificationTemplate = new MatTableDataSource();

    this.changePendingApproval = false;
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    if (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop > 100) {
      this.windowScrolled = true;
    } else if (this.windowScrolled && window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop < 10) {
      this.windowScrolled = false;
    }
    this.onSectionsScroll();
  }

  onSectionsScroll() {
    const active = 'section-body-active';

    if (this.isScrolledIntoView('#accCatalog')) {
      this.setAllInactive();
      $('#acceleratorButton').addClass(active);
    } else {
      if (this.isScrolledIntoView('#beamCatalog')) {
        this.setAllInactive();
        $('#beamButton').addClass(active);
      } else {
        if (this.isScrolledIntoView('#users')) {
          this.setAllInactive();
          $('#usersButton').addClass(active);
        } else {
          if (this.isScrolledIntoView('#roles')) {
            this.setAllInactive();
            $('#rolesButton').addClass(active);
          } else {
            if (this.isScrolledIntoView('#notificationTemplates')) {
              this.setAllInactive();
              $('#notificationTemplatesButton').addClass(active);
            }
          }
        }
      }
    }
  }
  setAllInactive() {
    const active = 'section-body-active';
    $('#acceleratorButton').removeClass(active);
    $('#beamButton').removeClass(active);
    $('#usersButton').removeClass(active);
    $('#rolesButton').removeClass(active);
    $('#notificationTemplates').removeClass(active);
  }

  override scrollToTop(elem: any) {
    // var that = this;
    (function smoothscroll() {
      // $(elem)[0].scrollIntoView({ behavior: 'smooth', block: 'start' });
      const yOffset = -230;
      const element = $(elem)[0];
      const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

      window.scrollTo({ top: y, behavior: 'smooth' });
    })();

    // setTimeout(function () {
    //   that.sections.nativeElement.scrollTop -= 20;
    // }, 500);
  }
  isScrolledIntoView(elem: any) {
    const elemRect = $(elem)[0].getBoundingClientRect();
    const docViewTop = window.screenTop;
    const elemTop = elemRect.top - 30;
    return (elemTop >= docViewTop);
  }

}

export class CatalogItem {
  name!: string;
  id!: string;
  qty?: number;
}

<div class="card section">
  <div class="card-body">
    <div class="row">
      <div class="col-1 pt-1">
        <h6>{{ number ?? "#" }}</h6>
      </div>
      <div class="col-9 pt-1">
        <mat-label
          *ngIf="textBefore"
          [innerHTML]="textBefore | placeholderHighlight : editor"
        ></mat-label
        >&nbsp;

        <span
          *ngIf="!editing"
          class="input bold"
          [ngClass]="{
            input: textValue && !disableEdit,
            placeholder: !textValue && !disableEdit,
            'input-disabled': disableEdit && checked,
            'placeholder-disabled': disableEdit && !checked,
            red: moreInfo
          }"
          (click)="enableEdit()"
          [innerHTML]="textValue"
        >
        </span>
        <span *ngIf="editing">
          <mat-form-field
            *ngIf="editing"
            class="text-input no-subscript mb-0"
            appearance="fill"
          >
            <input
              #txtInput
              aria-label="text"
              matInput
              (blur)="inputBlurred()"
              type="text"
              [(ngModel)]="textValue"
              (keyup)="keyup($event)"
            />
            <mat-icon (click)="textChanged()" class="green pointer"
              >check_circle</mat-icon
            >
            <mat-icon (click)="cancelEdit()" class="red pointer ml-1"
              >cancel</mat-icon
            >
          </mat-form-field>
        </span>
        &nbsp;<mat-label
          *ngIf="textAfter"
          [innerHTML]="textAfter | placeholderHighlight : editor"
        ></mat-label>
      </div>
      <div class="col-2 d-flex flex-row justify-content-end align-items-center">
        <mat-checkbox-with-roles
          [labelPosition]="'before'"
          [checked]="checked"
          [disabled]="checkboxDisable"
          (change)="checkboxChanged($event)"
          [color]="color"
          [roleCodes]="roleCodes"
          [user]="user"
          [warning]="moreInfo"
          [date]="date"
          *ngIf="checkboxVisible"
        >
        </mat-checkbox-with-roles>
        <role-chips [roleCodes]="roleCodes" *ngIf="!checkboxVisible">
        </role-chips>
      </div>
    </div>
  </div>
</div>

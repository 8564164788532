import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { MyPendingCatalogsRefresh } from './my-pending-catalogs.action';
import { PendingCatalogs } from 'src/app/services/pending-approvals/pending-approval';
import { CatalogService } from 'src/app/components/catalogs/catalog-service';

export class MyPendingCatalogsStateModel {
  data!: PendingCatalogs | null;
}

@State<MyPendingCatalogsStateModel>({
  name: 'MyPendingCatalogs',
  defaults: {
    data: null,
  }
})
@Injectable()
export class MyPendingCatalogsState {
  constructor(
    private service: CatalogService
  ) { }

  @Selector()
  static read(state: MyPendingCatalogsStateModel) {
    return state.data;
  }

  @Action(MyPendingCatalogsRefresh)
  refresh({ patchState }: StateContext<MyPendingCatalogsStateModel>) {
    this.service.GetMyPendingCatalogs().subscribe(
      data => patchState({ data }),
      error => console.error(error)
    );
  }


}

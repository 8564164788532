import { BaseComponent } from 'src/app/common/base/base.component';
import { Component, Injector, Input, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BeamlineRestriction } from './models';
import { MatTableDataSource } from '@angular/material/table';
import { Observable, Subscription } from 'rxjs';
import { Resource } from '../beamline-catalog/resource/resources';
import { BeamlineRestrictionService } from './services/beamline-restriction.service';
import { YesNoDialogComponent } from 'src/app/controls/yes-no-dialog/yes-no-dialog.component';
import { CommentsBoxComponent } from 'src/app/controls/comments-box/comments-box.component';
import { DisapprovalReasonComponent } from 'src/app/controls/disapproval-reason/disapproval-reason.component';

@Component({
  selector: 'operational-restrictions',
  templateUrl: './operational-restrictions.component.html',
  styleUrls: ['./operational-restrictions.component.scss']
})
export class OperationalRestrictionsComponent extends BaseComponent {

  @Input() beamline?: Resource;
  @Input() disabled?: boolean | null;
  @Input() hilite?: boolean | null;
  @Input() control!: FormControl;

  dataSource!: MatTableDataSource<BeamlineRestriction>;
  customTitle = 'Operational Restrictions';

  beamlineRestrictions?: BeamlineRestriction[];
  beamlineRestrictionsFiltered?: BeamlineRestriction[];
  beamlineRestrictions$!: Observable<BeamlineRestriction[]>;
  beamlineRestrictionsSubs!: Subscription;

  displayedColumns = ["text", "status", "actions"];

  @ViewChild(CommentsBoxComponent) commentBox?: CommentsBoxComponent;

  adding?: boolean;
  hasPending?: boolean;
  editing?: BeamlineRestriction | null;
  math = Math;

  constructor(
    protected override injector: Injector,
    private service: BeamlineRestrictionService
  ) {
    super(injector);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.loadBeamlineRestrictions();
  }

  loadBeamlineRestrictions() {
    this.beamlineRestrictions$ = this.store.select(state => state.BeamlineRestrictions.data);
    this.beamlineRestrictionsSubs = this.beamlineRestrictions$.subscribe(data => {
      if (data?.length) {
        this.beamlineRestrictions = data.filter(r => r.beamlineID == this.beamline?.id);
        this.beamlineRestrictionsFiltered = this.beamlineRestrictions.filter(r => Math.abs(r.status) == 1 || this.hasPrivilege(this.privilegeEnum.BeamlineRestrictions) || this.hasPrivilege(this.privilegeEnum.BeamlineRestrictionsApprove)).sort((a, b) => this.utils.sort(a.id, b.id));
        this.dataSource = new MatTableDataSource(this.beamlineRestrictionsFiltered);
      }
      this.hasPending = this.beamlineRestrictionsFiltered?.some(x => x.status == 0);
    });
  }

  save(text: string) {
    let beamlineRestriction = { name: this.beamline?.name + ' - Restriction', description: text, beamlineID: this.beamline?.id } as BeamlineRestriction;
    if (this.editing) {
      beamlineRestriction = this.editing;
      beamlineRestriction.description = text;
    }
    if (beamlineRestriction.id)
      this.service.update(beamlineRestriction).toPromise();
    else
      this.service.create(beamlineRestriction).toPromise();
    this.adding = false;
    this.editing = null;
  }

  delete(restriction: BeamlineRestriction) {
    const index = this.beamlineRestrictions?.indexOf(restriction) ?? -1;

    if (index >= 0) {
      this.beamlineRestrictionsFiltered?.splice(index, 1);
      this.dataSource = new MatTableDataSource(this.beamlineRestrictionsFiltered);
    }

    this.service.delete(restriction.id).toPromise();
  }

  edit(beamlineRestriction?: BeamlineRestriction) {
    this.adding = !beamlineRestriction;
    this.editing = beamlineRestriction;
    setTimeout(() => {
      if (this.commentBox) {
        this.commentBox.textValue = beamlineRestriction?.description;
        this.commentBox.editText();
      }
    }, 500);
  }

  cancel(restriction: BeamlineRestriction) {
    const confirm = this.dialog.open(YesNoDialogComponent, {
      width: "500px", data: {
        message: 'Are you sure you want to Cancel this Restriction?',
        icon: "question",
      },
    });
    confirm.afterClosed().subscribe(async (data) => {
      if (data) {
        const index = this.beamlineRestrictions?.indexOf(restriction) ?? -1;

        if (index >= 0) {
          this.beamlineRestrictionsFiltered?.splice(index, 1);
          this.dataSource = new MatTableDataSource(this.beamlineRestrictionsFiltered);
        }

        await this.service.cancel(restriction.id).toPromise();
        this.alert.warning('Restriction Cancelled!');
      }
    });
  }

  async cancelDeletion(restriction: BeamlineRestriction) {
    await this.service.cancel(restriction.id).toPromise();
    this.alert.warning('Restriction Deletion Cancelled!');
  }

  async approve(restriction: BeamlineRestriction, value: boolean) {
    if (value) {
      await this.service.approve(restriction.id, true).toPromise();
      this.alert.success('Restriction Approved!');
    }
    else {
      const confirm = this.dialog.open(DisapprovalReasonComponent, {
        width: "500px"
      });
      confirm.afterClosed().subscribe(async (data) => {
        if (data) {
          await this.service.approve(restriction.id, false, data).toPromise();
          this.alert.warning('Restriction Disapproved!');
        }
      });
    }
  }

}

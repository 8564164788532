import { BaseComponent } from 'src/app/common/base/base.component';
import { CdkDragDrop, moveItemInArray, transferArrayItem, } from '@angular/cdk/drag-drop';
import { Component, Input, QueryList, ViewChildren, OnChanges, Output, EventEmitter, OnInit, SimpleChanges, Injector } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ClEditorComponent } from 'src/app/controls/checklist-components/cl-editor/cl-editor.component';
import { Procedure } from 'src/app/components/procedure/models/procedure.model';
import { WfTaskLocalResource, WfSignature } from 'src/app/services/work-flow/work-flow';
import { ChecklistTemplate } from '../../checklists';
import { ChecklistBuilderService } from '../checklist-builder.service';
import { ChecklistBuilderTaskComponent } from './checklist-builder-task/checklist-builder-task.component';
import { utils } from 'src/app/modules/libs/utils';
import { TransactionLogService } from 'src/app/services/logs/transaction-log.service';
import { YesNoDialogComponent } from 'src/app/controls/yes-no-dialog/yes-no-dialog.component';
import { ChecklistTemplatesRefreshById } from '../../store/checklist-templates/checklist-templates.action';

@Component({
  selector: 'checklist-builder-tasks',
  templateUrl: './checklist-builder-tasks.component.html',
  styleUrls: ['./checklist-builder-tasks.component.scss'],
})
export class ChecklistBuilderTasksComponent extends BaseComponent implements OnInit, OnChanges {
  @Input() tasks!: WfTaskLocalResource[];
  @Input() currentVersion!: ChecklistTemplate;
  @Input() wfStepLocalID!: number;
  @Input() disabled!: boolean;
  @Input() procedure?: Procedure | null;
  @Input() stepDropLists!: string[];
  @Input() showDeleted?: boolean;
  @Input() isDeletedStep?: boolean;

  @Output() loading = new EventEmitter<any>();
  @Output() refreshPreviousStep = new EventEmitter<number>();
  @Output() refreshDeletedTasks = new EventEmitter();
  @Output() restoredTask = new EventEmitter<WfTaskLocalResource>();
  @Output() refreshMap = new EventEmitter();

  @ViewChildren(ChecklistBuilderTaskComponent)
  taskComponents!: QueryList<ChecklistBuilderTaskComponent>;

  util = utils;
  constructor(
    protected override injector: Injector,
    public service: ChecklistBuilderService,
    private logService: TransactionLogService,
  ) {
    super(injector);
  }

  ngOnInit(): void { }

  ngOnChanges(changes: SimpleChanges) {
    this.tasks = this.tasks.sort((a, b) => utils.sort(a.order, b.order));
    this.refreshTasks();
  }

  add() {
    let s: WfSignature = {
      name: '[New Name]',
      options: [],
      roles: [],
      required: true,
      approve: true,
      unapprove: true,
      stepId: this.wfStepLocalID,
    };
    const editor = this.dialog.open(ClEditorComponent, {
      width: '80vw',
      data: {
        s,
        formType: this.currentVersion.documentType?.code,
        serialNo: this.currentVersion.serialNo,
        roles: s.roles,
        checklistTemplate: this.currentVersion,
      },
      disableClose: true,
      autoFocus: false,
      hasBackdrop: true,
    });
    editor.afterClosed().subscribe((data) => {
      this.service.createWFTaskLocal(data).subscribe((data) => {
        if (!this.tasks.map(t => t.id).includes(data.id))
          this.tasks.push(data);
        this.refreshTasks();
      });
    });
  }

  duplicated(task: WfTaskLocalResource) {
    this.tasks.push(task);
    this.refreshTasks();
  }

  deleted(task?: WfTaskLocalResource) {
    this.refreshDeletedTasks.emit();
    const index = this.tasks.findIndex((x) => x.id === task?.id);
    if (index !== -1) {
      this.tasks.splice(index, 1);
    }
    this.refreshTasks();
  }

  refreshTasks(refresh?: boolean) {
    this.renumberArray(refresh);
    setTimeout(() => {
      this.taskComponents?.map((x) => {
        const task = this.tasks.find((t) => t.id == x.task.id);
        if (task) x.task = task;
        x.refreshSignature();
      });
    }, 500);
  }

  updateTask(task: WfTaskLocalResource) {
    const index = this.tasks.findIndex((x) => x.id == task.id);
    if (index >= 0) {
      this.tasks[index] = task;
      this.refreshTasks();
    }
  }

  renumberArray(refresh?: boolean) {
    let number = 0;
    let order = 0;
    this.tasks = this.tasks.filter((t) => (this.showDeleted ? true : !t.logID));
    const unorderedTasks = utils.cloneDeep(this.tasks.filter(t => !t.logID));
    this.tasks.filter(t => !t.logID).map((t) => {
      if (t.wfTasks?.[0]) {
        if (t.component?.numericHeader && !t.logID) {
          number = t.wfTasks?.[0].required ? number + 1 : number;
          t.name = t.wfTasks?.[0].required ? number.toString() : '';
          t.wfTasks[0].name = t.name;
        }
        t.order = t.wfTasks?.[0].required ? order : -1;
        t.wfTasks[0].order = t.order;
        order++;
      }
    });

    var changedTasks = this.getChanges(unorderedTasks, this.tasks.filter(t => !t.logID));
    if (changedTasks.length)
      this.service.saveOrder(changedTasks.filter((t) => !t.logID), refresh).toPromise();
  }

  getChanges(
    unorderedTasks: WfTaskLocalResource[],
    orderedTasks: WfTaskLocalResource[]
  ) {
    const wfTaskLocals: WfTaskLocalResource[] = [];
    unorderedTasks.map((t) => {
      const orderedTask = orderedTasks.find((x) => x.id == t.id);
      if (
        orderedTask &&
        (orderedTask?.order != t.order || orderedTask?.name != t.name)
      )
        wfTaskLocals.push(orderedTask);
    });
    return wfTaskLocals;
  }

  refreshRestored(task: WfTaskLocalResource) {
    this.tasks.push(task);
    this.tasks = this.tasks.sort((a, b) => utils.sort(a.order, b.order));
    this.refreshTasks();
  }

  drop(event: CdkDragDrop<WfTaskLocalResource[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(this.tasks, event.previousIndex, event.currentIndex);
      this.refreshTasks(true);
    } else {
      const stepID = event.container.data[0].wfStepLocalID;
      const task = event.previousContainer.data[event.previousIndex];
      const previosStepID = task.wfStepLocalID;
      task.wfStepLocalID = stepID;
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
      this.refreshTasks(true);
      this.refreshPreviousStep.emit(previosStepID);
    }
  }

  restore(task: WfTaskLocalResource) {
    if (task.logID)
      this.service
        .restoreTask(task.logID)
        .toPromise()
        .then((data) => {
          if (data?.id) {
            data.order = (data.order ?? 0) - 1;
            this.restoredTask.emit(data);
            this.refreshDeletedTasks.emit();
            this.scrollToTask(data.id);
          }
        });
  }

  deleteForever(task: WfTaskLocalResource) {
    const yesno = this.dialog.open(YesNoDialogComponent, {
      width: '400px',
      data: {
        message: 'Are you sure you want to Remove this Deleted Task Forever?', // this.getMessage("CB_Delete_Step_Confirmation")          .description,
        icon: 'stop',
      },
    });
    yesno.afterClosed().subscribe((data) => {
      if (data) {
        if (task.logID)
          this.logService
            .UpdateStatus(task.logID)
            .toPromise()
            .then(() => {
              this.refreshDeletedTasks.emit();
            });
      }
    });
  }

  scrollToTask(taskID: number) {
    setTimeout(() => {
      const element = document.getElementById('task-' + taskID);
      if (element) {
        element.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'start',
        });
      }
    }, 500);
  }

  setLoading(e: boolean) {
    this.loading.emit(e);
  }
}

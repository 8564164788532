import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { BaseComponent } from 'src/app/common/base/base.component';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { AbsiTemplateTypeResource, AbsiTemplateVersionResource } from '../../absi';
import { FormControl } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import * as moment from 'moment';
import { Observable, Subscription } from 'rxjs';
import { AbsiTemplateVersionRefresh } from '../../store/absi-template/absi-template.action';

@Component({
  selector: 'absi-builder-side',
  templateUrl: './absi-builder-side.component.html',
  styleUrls: ['./absi-builder-side.component.scss']
})
export class AbsiBuilderSideComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {

  @Input() filter?: string;
  @Input() version?: AbsiTemplateVersionResource | null;

  @Output() loading = new EventEmitter<boolean>();
  @Output() selectedRow = new EventEmitter<AbsiTemplateVersionResource>();
  @Output() selectedType = new EventEmitter<AbsiTemplateTypeResource>();

  versions!: AbsiTemplateVersionResource[];
  versions$!: Observable<AbsiTemplateVersionResource[]>;
  versionsSubs!: Subscription;

  absiTemplateTypes?: AbsiTemplateTypeResource[];
  absiTemplateTypes$!: Observable<AbsiTemplateTypeResource[]>;
  absiTemplateTypesSubs!: Subscription;

  type!: AbsiTemplateTypeResource;
  typeCtrl = new FormControl();

  public displayedColumns: string[] = ['name', 'status'];

  dataSource!: MatTableDataSource<AbsiTemplateVersionResource>;

  ngOnInit(): void {
    this.loadAbsiTemplateTypes();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.loadVersions();
  }

  override ngOnDestroy(): void {
    this.absiTemplateTypesSubs?.unsubscribe();
    this.versionsSubs?.unsubscribe();
    super.ngOnDestroy();
  }

  loadAbsiTemplateTypes() {
    this.absiTemplateTypes$ = this.store.select(state => state.AbsiTemplateType.data);
    this.absiTemplateTypesSubs = this.absiTemplateTypes$.subscribe(data => {
      if (data?.length) {
        this.absiTemplateTypes = data;
      }
    });
  }

  displayType(type: any) {
    return type ? type.name : null;
  }

  selected(event: MatAutocompleteSelectedEvent) {
    this.loading.emit(true);
    this.type = event.option.value;
    this.selectedType.emit(this.type);
    if (this.type.id) {
      this.store.dispatch(new AbsiTemplateVersionRefresh(this.type.id));
      this.loadVersions();
    }
  }

  loadVersions() {
    this.versions$ = this.store.select(state => state.AbsiTemplateVersions.data);
    this.versionsSubs = this.versions$.subscribe(data => {
      if (data) {
        this.versions = data.sort((a, b) => this.utils.sort(a.name, b.name, false));
        if (this.version) {
          this.version = this.versions.find(x => x.id == this.version?.id);
        }
        this.versions.map(v => this.setStatus(v));
        this.dataSource = new MatTableDataSource(this.versions);
        this.loading.emit(false);
      }
    });
  }

  setStatus(e: AbsiTemplateVersionResource) {
    const activeVersion = this.versions.find(x => x.status == 1);
    if (e == activeVersion) {
      activeVersion.statusClass = 'current-version';
      activeVersion.statusName = 'Active';
    }

    if (moment(e.createdOn).isBefore(activeVersion?.createdOn)) {
      e.statusClass = 'old-version';
      e.statusName = 'Archived';
    }

    if (moment(e.createdOn).isAfter(activeVersion?.createdOn)) {
      e.statusClass = 'new-version';
      e.statusName = 'New';
    }
  }

  onRowClick(row: any) {
    this.version = row;
    if (this.version)
      this.selectedRow.emit(this.version);
  }

}

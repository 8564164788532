import { Injectable, Injector } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { KEResource, SaveKEResource, SaveKEStatusResource, KEStatusResource } from './ke';
import { SaveTaskResource, WfTaskLocalResource, WfSignatureResource, WfTaskResource, WFSectionLocalResource } from '../../services/work-flow/work-flow';
import { FormType, FormStatusEnum } from 'src/app/common/enumerations/enumerations';
import { OlogDataBaseObject } from 'src/app/components/olog/olog';
import { utils } from 'src/app/modules/libs/utils';
import { WFComponent } from '../workflow/workflow';
import { BaseService } from 'src/app/common/base/base.service';


@Injectable()
export class KEService extends BaseService<KEResource, number> {

  SerialNumber = '/SerialNumbers/' + FormType.KE;
  Locations = '/Locations';

  Api = '/KEnable';

  components!: WFComponent[];
  components$!: Observable<WFComponent[]>;
  componentsSubs!: Subscription;

  constructor(
    protected override injector: Injector,
  ) {
    super(injector, '/KEnable');
  }

  // Gets the Serial Number of the Form
  getSerialNo(): Observable<SerialNumber> {
    const httpresult1 = this.http.get<SerialNumber>(this.BASE_URL + this.SerialNumber);
    return httpresult1;
  }

  getOpen(): Observable<KEResource[]> {
    const httpresult = this.http.get<KEResource[]>(this.BASE_URL + this.Api + '/Open');
    return httpresult;
  }

  // getAll(): Observable<KEResource[]> {
  //   const httpresult = this.http.get<KEResource[]>(this.BASE_URL + this.Api);
  //   return httpresult;
  // }

  getBySerialNo(sn: string): Observable<KEResource> {
    const httpresult = this.http.get<KEResource>(this.BASE_URL + this.Api + '/SerialNo/' + sn);
    return httpresult;
  }

  getStatusComplete(wfTableID: number, formStatus: FormStatusEnum): Observable<KEStatusResource> {
    const httpresult = this.http.get<KEStatusResource>(this.BASE_URL + this.Api + '/StatusComplete/' + wfTableID + '/' + formStatus);
    return httpresult;
  }

  // override getById(id: number): Observable<KEResource> {
  //   const url = this.BASE_URL + this.Api + '/' + id;
  //   const httpresult = this.http.get<KEResource>(url);
  //   return httpresult;
  // }

  getOlog(serialNo: string): Observable<OlogDataBaseObject> {
    const httpresult = this.http.get<OlogDataBaseObject>(this.BASE_URL + this.Api + '/GetOlog/' + serialNo);
    return httpresult;
  }

  post(saveKE: SaveKEResource) {
    return this.http.post<KEResource>(this.BASE_URL + this.Api, saveKE);
  }

  putStatus(saveKEStatus: SaveKEStatusResource) {
    return this.http.put<KEResource>(this.BASE_URL + this.Api + '/SetStatus/' + saveKEStatus.id, saveKEStatus);
  }

  put(saveKE: SaveKEResource) {
    return this.http.put<KEResource>(this.BASE_URL + this.Api + '/' + saveKE.id, saveKE);
  }

  // delete(id: number, reason: string) {
  //   return this.http.delete(this.BASE_URL + this.Api + '/' + id + '/' + reason)
  // }
  /////

  public sign(taskId: number, actionId: number, reason?: string) {
    let url: string = this.BASE_URL + this.Api + '/Sign?task=' + taskId + '&action=' + actionId;

    if (reason != null) {
      url += '&reason=' + reason;
    }

    const httpresult = this.http.put<any>(url, null);
    return httpresult;
  }

  public readWFBySection(id: number, sectionNumber: number) {
    const httpresult = this.http.get<any[]>(this.BASE_URL + this.Api + '/WFBySection/' + id + '?SectionNumber=' + sectionNumber);
    return httpresult;
  }

  public getSection(id: number, sectionNumber: number): Observable<WFSectionLocalResource> {
    const httpresult = this.http.get<WFSectionLocalResource>(this.BASE_URL + this.Api + '/BySection/' + id + '?SectionNumber=' + sectionNumber);
    return httpresult;
  }

  public convertToSignatures(wfTaskLoclas: WfTaskLocalResource[]) {
    const httpresult = this.http.get<any[]>(this.BASE_URL + this.Api + '/ConvertToSignatures');
    return httpresult;
  }

  public saveWFTask(s: SaveTaskResource) {
    const httpresult = this.http.put<WfSignatureResource>(this.BASE_URL + this.Api + '/Save/' + s.id, s);
    return httpresult;
  }

  public saveWFTasks(s: SaveTaskResource[]) {
    const httpresult = this.http.put<KEResource>(this.BASE_URL + this.Api + '/SaveTasks', s);
    return httpresult;
  }

  public saveRequiredWFTask(id: number, required: boolean) {
    const httpresult = this.http.put<WfTaskResource>(this.BASE_URL + this.Api + '/SaveRequiredWFTask/' + id + '/' + (required ? 1 : 0).toString(), null);
    return httpresult;
  }

  public processKES(ids: number[]) {
    return this.http.post(this.BASE_URL + this.Api + '/ProcessKES', ids).subscribe(
      response => {
        console.log('ProcessKES succeeded:', response);
      },
      error => {
        console.error('ProcessKES failed:', error);
      }
    );
  }

  loadComponents() {
    this.components$ = this.store.select(state => state.Components.data);
    this.componentsSubs = this.components$.subscribe(data => {
      this.components = data;
    });
  }

  updateTask(t: WfTaskLocalResource) {
    this.loadComponents();
    if (!t.component) {
      t.component = this.components.find(x => x.id == (t.componentID ? t.componentID : t.type));
      t.componentID = t.component?.id;
    }
    t.isCompleted = t.required ? utils.taskComplete(t) : true;
    const tasks = this.allTasks.filter(x => x.id != t.id);
    tasks.push(t);
    this.updateAllTasks(tasks);
  }

  allTasks: WfTaskLocalResource[] = [];
  allTasksObservable = new BehaviorSubject<WfTaskLocalResource[]>(this.allTasks);

  updateAllTasks(tasks: WfTaskLocalResource[]) {
    this.allTasks = utils.parseConditions(tasks);
    this.allTasksObservable.next(tasks);
  }
}

class SerialNumber {
  serialNo?: string;
}

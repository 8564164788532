import { HttpClient } from "@angular/common/http";
import { Injector } from "@angular/core";
import { Store } from "@ngxs/store";

import { environment } from "src/environments/environment";
import { AlertService } from "src/app/services/alert/alert.service";
import { MessageBannerService } from "src/app/components/messages/services/message-banner.service";
import { Observable, Subscription } from "rxjs";
import { AppStateService } from "src/app/store/app-state-service";
import { User } from "src/app/components/catalogs/user-catalog/services/user";
import { utils } from "src/app/modules/libs/utils";
import { MatDialog } from "@angular/material/dialog";
import { MessageBanner } from "src/app/components/messages/services/message-banner";

export class BaseService<T, K> {

  BASE_URL = environment.urls.baseApiUrl; // Api URL
  URL!: string;

  private _mbs!: MessageBannerService;
  private _ass!: AppStateService;
  http!: HttpClient;
  store!: Store;
  alert!: AlertService;
  utils!: utils;
  dialog!: MatDialog;

  subs: Subscription = new Subscription;
  public currentUser!: User | null;

  constructor(
    protected injector: Injector,
    protected endpoint: string
  ) {
    this.http = this.injector.get(HttpClient);
    this._mbs = this.injector.get(MessageBannerService);
    this._ass = this.injector.get(AppStateService);
    this.store = this.injector.get(Store);
    this.alert = this.injector.get(AlertService);
    this.dialog = this.injector.get(MatDialog);

    this.URL = this.BASE_URL + endpoint;
    this.subs = this._ass.AppState.subscribe(appstate => {
      this.currentUser = appstate?.currentUser;
      this.currentUserUpdated();
    });
  }

  currentUserUpdated(): void {
    // this.method show be overridden in derived class to trigger other methos when user updates!
  };

  create(data: T): Observable<T> {
    return this.http.post<T>(this.URL, data);
  }

  createRange(data: T[]): Observable<T[]> {
    return this.http.post<T[]>(this.URL + '/range', data);
  }

  getAll(): Observable<T[]> {
    return this.http.get<T[]>(this.URL);
  }

  getById(id: K): Observable<T> {
    return this.http.get<T>(this.URL + '/' + id);
  }

  update(data: T): Observable<T> {
    return this.http.put<T>(this.URL, data);
  }

  delete(id: K): Observable<any> {
    return this.http.delete<T>(this.URL + '/' + id);
  }

  getMessage(val: number | string): MessageBanner {
    if (typeof (val) === 'string')
      return this._mbs.get(val);
    // if (typeof (val) === 'number')
    //   return this._mbs.getById(val) as MessageBanner;
    return new MessageBanner();
  }

}

import { Component, EventEmitter, Injector, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { BaseComponent } from 'src/app/common/base/base.component';
import { MessageBanner, SystemCategory } from '../services/message-banner';
import { MatTableDataSource } from '@angular/material/table';
import { MessageBannerService } from '../services/message-banner.service';
import { EditDisplayMessageComponent } from './edit-display-message/edit-display-message.component';
import { MessagePlaceholder } from 'src/app/common/models/placeholder';
import { MessageTypeEnum } from '../services/message-banner.enums';
import { Clipboard } from '@angular/cdk/clipboard';

@Component({
  selector: 'messages-table',
  templateUrl: './messages-table.component.html',
  styleUrls: ['./messages-table.component.scss']
})
export class MessagesTableComponent extends BaseComponent implements OnChanges {

  @Input() systemCategory?: SystemCategory;
  @Input() filter?: string;
  @Output() loading = new EventEmitter<boolean>();

  messageBanners?: MessageBanner[];
  messageBannersFiltered?: MessageBanner[];
  messageBanner$!: Observable<MessageBanner[]>;
  messageBannerSubs!: Subscription;

  messageType = MessageTypeEnum;

  public displayedColumns: string[] = ['name', 'description', 'type', 'category', 'timeout', 'actions'];

  dataSource = new MatTableDataSource<MessageBanner>();

  constructor(
    protected override injector: Injector,
    private service: MessageBannerService,
    private clipboard: Clipboard,
  ) {
    super(injector);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.loadMessageBanners();
  }

  loadMessageBanners() {
    this.messageBanner$ = this.store.select(state => state.MessageBanners.data);
    this.messageBannerSubs = this.messageBanner$.subscribe(data => {
      if (data?.length) {
        this.messageBanners = data;
        if (this.filter) {
          const filter = this.filter?.toLowerCase() as string;
          this.messageBannersFiltered = this.messageBanners.filter(m => m.name.toLowerCase().includes(filter) || m.description.toLowerCase().includes(filter) || this.messageType[m.type].toLowerCase().includes(filter) || this.getCategories(m)?.toLowerCase().includes(filter));
        }
        else {
          this.messageBannersFiltered = this.messageBanners.filter(m => m.messageCategoryRelations?.map(r => r.systemCategoryID).includes(this.systemCategory?.id ?? 0));

        }
        this.messageBannersFiltered?.map(m => m.messageCategoryRelations?.map(c => {
          c.systemCategory = this.service.categories.find(x => x.id == c.systemCategoryID);
        }));

        this.dataSource = new MatTableDataSource(this.messageBannersFiltered);
      }
    });
  }

  openEdition(element: MessageBanner) {
    const copy = this.utils.cloneDeep(element);
    const dialogRef = this.dialog.open(EditDisplayMessageComponent, {
      width: '30em',
      data: copy
    });
    dialogRef.afterClosed().subscribe((msg: MessageBanner) => {
      if (msg) {
        this.updateMessage(msg);
      }
    });
  }

  async updateMessage(messageToUpdate: MessageBanner) {
    await this.service.update(messageToUpdate).toPromise().then(() => {
      this.alert.message('update', [new MessagePlaceholder('{what}', 'Message')]);
    }).catch(() => this.alert.defaultError());
  }

  getCategories(m: MessageBanner) {
    return m.messageCategoryRelations?.map(c => c.systemCategory?.name).sort((a, b) => this.utils.sort(a, b)).join(', ');
  }

  copy(m: MessageBanner) {
    this.clipboard.copy(m.name);
    this.alert.info('<b>' + m.name + '</b> copied to the Clipboard!', 'Clipboard');
  }

}

import { Component, OnInit, Output, EventEmitter, ViewChild, ViewChildren, ViewContainerRef, QueryList, AfterViewInit, OnDestroy, Input, OnChanges, SimpleChanges, Injector } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatRow, MatTableDataSource } from '@angular/material/table';
import { Observable, Subscription } from 'rxjs';
import { BaseComponent } from 'src/app/common/base/base.component';
import { Roles, PendingResourcesType } from 'src/app/common/enumerations/enumerations';
import { BeamlineShutterRelation } from 'src/app/components/catalogs/beamline-catalog/resource/resources';
import { PrivilegeEnum } from 'src/app/services/role-privilege/privilege-enum';
import { CatalogService } from '../../catalog-service';

@Component({
  selector: 'resources-list-new',
  templateUrl: './resources-list-new.component.html',
  styleUrls: ['./resources-list-new.component.scss']
})
export class ResourcesListNewComponent extends BaseComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {

  @Input() selectedTab = 0;
  @Input() filter?: string;
  @Input() beamlineShutter?: BeamlineShutterRelation;

  @Output() selectedRow = new EventEmitter<BeamlineShutterRelation>();
  @Output() loading = new EventEmitter<boolean>();

  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChildren('matrow', { read: ViewContainerRef }) rows!: QueryList<ViewContainerRef>;
  @ViewChildren('matrow') datarows!: QueryList<MatRow>;

  beamlineShutterRelations!: BeamlineShutterRelation[];
  beamlineShutterRelations$!: Observable<BeamlineShutterRelation[]>;
  beamlineShutterRelationsSubs!: Subscription;

  public createAvailable!: boolean;

  public selectedRowIndex!: number;

  public displayedColumns: string[] = ['beamlineName', 'shutterName', 'beamlineStatus', 'ketStatus'];
  public dataSource!: MatTableDataSource<BeamlineShutterRelation>;
  public pending = false;

  editPrivilege!: boolean;
  authPrivilege!: boolean;


  constructor(
    protected override injector: Injector,
    private _catalogService: CatalogService,
  ) {
    super(injector);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.selectedTab = isNaN(this.selectedTab) ? this.selectedTab = 0 : this.selectedTab;
    this.createAvailable = this.hasRoles([Roles.ADM]);
    if (changes['filter']) this.applyFilter(this.filter);
    if (changes['selectedTab']) this.beamlineShutterRelations?.map(b => b.statusClass = this.getColor(b));
    if (changes['beamlineShutter']) {
      if (!this.beamlineShutter?.beamlineId) {
        this.selectedRowIndex = -1;
      }
    }
  }

  async ngOnInit() {
    this.checkPrivileges();
    this.loadAllResources();
    if (this.dataSource) {
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.dataSource.sortingDataAccessor = (data, sortHeaderId) => (data as any)[sortHeaderId]?.toLocaleLowerCase();
    }
  }

  checkPrivileges() {
    this.editPrivilege = this.hasPrivilege(PrivilegeEnum.RSSTestDBEdit);
    this.authPrivilege = this.hasPrivilege(PrivilegeEnum.RSSTestDBAuth);
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  override  ngOnDestroy(): void {
    this.beamlineShutterRelationsSubs?.unsubscribe();
    super.ngOnDestroy();
  }

  loadAllResources() {
    this.loading.emit(true);
    const pendingResource = this._catalogService.pendingCatalog;
    this.beamlineShutterRelations$ = this.store.select(state => state.BeamlineShutter.data);
    this.beamlineShutterRelationsSubs = this.beamlineShutterRelations$.subscribe(data => {
      if (data?.length) {
        this.beamlineShutterRelations = data;
        this.beamlineShutterRelations.map(b => b.statusClass = this.getColor(b));
        const priv = this.hasPrivilege(PrivilegeEnum.BeamlineCatalog) || this.hasPrivilege(PrivilegeEnum.RSSTestDBAuth);
        this.dataSource = new MatTableDataSource(this.beamlineShutterRelations.filter(x => priv || !priv && x.beamlineId).sort((a, b) => this.utils.sortArrayAlphabeticallyWithComplexNumbers(a.beamlineName, b.beamlineName)));
        if (pendingResource) {
          const index = this.dataSource.data.findIndex(r => r.beamlineId == pendingResource.id);
          this.showIndex(index);
        }
        this.loading.emit(false);
      }
    });
  }

  applyFilter(filterValue?: string) {
    if (this.dataSource)
      this.dataSource.filter = filterValue?.trim().toLowerCase() ?? '';
  }

  async selectRow(row: BeamlineShutterRelation) {
    if (await this.canDeactivate()) {
      if (row?.beamlineId) {
        this.selectedRowIndex = row.beamlineId;
        this._catalogService.pendingCatalog = undefined;
        this.selectedRow.emit(row);
      }
    }
  }

  getColor(beamlineShutter: BeamlineShutterRelation): string {
    switch (this.selectedTab) {
      case 0:
        if ((beamlineShutter.type == PendingResourcesType.Details || beamlineShutter.type == PendingResourcesType.Both || (beamlineShutter.beamlineId ?? 0) < 0) && this.hasPrivilege(PrivilegeEnum.BeamlineCatalog)) {
          return 'yellow';
        }
        else {
          switch (beamlineShutter.status) {
            case 1: return 'blue';
            case 2: return 'orange';
            case 3: return 'black';
            case 4: return 'gray';
          }
        }
        break;

      case 2:
        if ((beamlineShutter.type == PendingResourcesType.Rss_Database || beamlineShutter.type == PendingResourcesType.Both) && (this.editPrivilege || this.authPrivilege)) {
          return 'yellow';
        }
        break;
    }
    return '';
  }

  showIndex(id?: number) {
    if ((id ?? 0) >= 0) {
      setTimeout(() => {
        this.showElement(id, 7);
        this.selectRow(this.dataSource.data[id as number]);
        this.loading.emit(false);
      }, 100);
    }
  }

  showElement(index: number | undefined, height: number) {
    const indexstr = index?.toString();
    const row = this.rows.find(row => row.element.nativeElement.getAttribute('position') === indexstr);
    if (row) {
      const rect = row.element.nativeElement.getBoundingClientRect();
      if ((rect.y <= 0) || ((rect.y + rect.height) > height)) {
        row.element.nativeElement.scrollIntoView(false, { behavior: 'instant' });
      }
      return;
    }
  }

  getPosition(row: any): number {
    const i = this.dataSource.data.findIndex(r => {
      return r.beamlineId == row.id;
    });
    return i;
  }

  sortData(sort: Sort) {
    const data = this.dataSource.data;
    let sortedData: BeamlineShutterRelation[];
    if (!sort.active || sort.direction === '') {
      sortedData = data;
      return;
    }
    sortedData = data.sort((a, b) => {
      const isDesc = sort.direction === 'desc';
      switch (sort.active) {
        case 'beamlineName':
          if (isDesc) {
            return this.utils.SortBeamlines(a.beamlineName, b.beamlineName);
          }
          else {
            return - this.utils.SortBeamlines(a.beamlineName, b.beamlineName);
          }
          break;
        case 'shutterName':
          return compare(a.shutterName, b.shutterName, isDesc);
          break;
        case 'beamlineStatus':
          return compare(a.beamlineStatus, b.beamlineStatus, isDesc);
          break;
        default: this.utils.SortBeamlines(a.beamlineName, b.beamlineName);
      }
      return 0;
    });
    this.dataSource = new MatTableDataSource(sortedData);
  }

  getClass(e: BeamlineShutterRelation, type: number) {
    if (e.ketStatus) {
      const t = e.ketStatus;
      switch (type) {
        case 2:
          if (t?.templateStatus == 0) { return ''; }
          if (t?.templateStatus == 1) { return ' indicator ket-active'; }
          if (t?.templateStatus == 2) { return ' indicator ket-new'; }
          break;
        case 1:
          if (t?.activeFullVersion != null && t?.newFullVersions == 0) { return ' indicator ket-active'; }
          if (t?.activeFullVersion != null && t?.newFullVersions != 0) { return ' indicator ket-active-new'; }
          if (t?.activeFullVersion == null && t?.newFullVersions != 0) { return ' indicator ket-new'; }
          return ' indicator';
          break;
        case 0:
          if (t?.activeAbbrVersion != null && t?.newAbbrVersions == 0) { return ' indicator ket-active'; }
          if (t?.activeAbbrVersion != null && t?.newAbbrVersions != 0) { return ' indicator ket-active-new'; }
          if (t?.activeAbbrVersion == null && t?.newAbbrVersions != 0) { return ' indicator ket-new'; }
          return 'indicator ket-inactive';
          break;
      }
    } else {
      return 'indicator';
    }
    return '';
  }


}

function compare(a?: number | string, b?: number | string, isAsc?: boolean) {
  if (a && b)
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  return 0;
}

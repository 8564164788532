<!-- This is shown in the Component Editor in Checklist Builder  -->
<div
  cdkDropListGroup
  class="form-field-channels relative"
  appearance="outline"
  *ngIf="editor"
>
  <mat-chip-set
    #chipListChannels
    aria-label="Channels"
    class="channels"
    cdkDropList
    (cdkDropListDropped)="dropListDropped($event)"
    [cdkDropListData]="channels"
  >
    <mat-chip
      cdkDrag
      [cdkDragData]="channels"
      *ngFor="let channel of channels"
      [selectable]="true"
      [removable]="true"
      (removed)="remove(channel)"
    >
      {{ channel.label }}
      <mat-icon class="copy-btn" (click)="copy(channel)" matTooltip="Copy"
        >content_copy</mat-icon
      >
      <mat-icon class="edit-btn" (click)="edit(channel)" matTooltip="Modify"
        >edit</mat-icon
      >
      <mat-icon matChipRemove>cancel</mat-icon>
    </mat-chip>
  </mat-chip-set>
  <button mat-icon-button color="accent" (click)="add()" class="add-button">
    <mat-icon>add_box</mat-icon>
  </button>
  <button
    mat-icon-button
    color="primary"
    (click)="paste()"
    class="paste-button"
    *ngIf="channelCopy && pasteAllowed()"
    matTooltip="Paste Channel Here"
  >
    <mat-icon>content_paste</mat-icon>
  </button>
</div>
<div *ngIf="!editor">
  <div class="row no-gutters border-top" *ngFor="let channel of channels">
    <div class="col-12 border-bottom">
      <div class="row">
        <div class="col-md-12 col-lg-6">
          <div class="nowrap" [matTooltip]="channel.name">
            {{ channel.label }}
          </div>
        </div>
        <div class="col-md-6">
          <div
            class="float-lg-right nowrap"
            [ngClass]="{
              blue: !channel.error,
              red: channel.error
            }"
            [innerHTML]="channel.error ? 'ERROR!' : channel.value"
          ></div>
        </div>
      </div>
    </div>
  </div>
  <div class="row border-top no-gutters">
    <div class="col-12 border-bottom d-flex justify-content-between">
      <div>
        <mat-icon
          matTooltip="Update values"
          class="update-button pl-1"
          color="primary"
          *ngIf="!disabled && channels.length && !updating && !error"
          (click)="updateChannelsConfirm()"
          >update</mat-icon
        >
        <span
          *ngIf="updating && channels.length"
          color="primary"
          class="spinner-border spinner-border-sm d-print-none ml-1"
        ></span>
        <span *ngIf="channels.length && error && !updating">
          <mat-icon
            color="warn"
            matTooltip="There was an error retrieving values! Try again!"
            (click)="updateChannelsConfirm()"
            >error</mat-icon
          >
        </span>
      </div>
      <div class="date">
        <b> {{ channels[0].date | date : "MM/dd/yyyy HH:mm:ss" }}</b>
      </div>
      <div class="text-right" [matTooltip]="channels[0].user?.name ?? ''">
        {{ channels[0].user?.initials }}
      </div>
    </div>
  </div>
</div>

import { Component, Input, Output, EventEmitter, OnChanges, OnDestroy, OnInit, Injector, ChangeDetectorRef, ViewChild } from '@angular/core';
import { ClBaseComponent } from '../cl-base/cl-base.component';
import { Role } from 'src/app/components/catalogs/roles/services/role';
import { YesNoDialogComponent } from '../../yes-no-dialog/yes-no-dialog.component';
import { MatCheckboxWithRolesComponent } from '../../mat-checkbox-with-roles/mat-checkbox-with-roles.component';
// import { Role } from '../../../Services/Roles/role';
// import { User } from 'src/app/Services/Users/user';
// import { YesNoDialogComponent } from '../../common/yes-no-dialog/yes-no-dialog.component';
// import { MatDialog } from '@angular/material/dialog';
// import { AppStateService } from 'src/app/Store/AppStateService';
// import { DisplayMessageService } from 'src/app/Services/display-message-service/display-message.service';
// import { ClBaseComponent } from '../../checklist/cl-base/cl-base.component';
// import { Store } from '@ngxs/store';

@Component({
  selector: 'checkbox-signature',
  templateUrl: './checkbox-signature.component.html',
  styleUrls: ['./checkbox-signature.component.scss']
})
/* checkbox-signature component*/
export class CheckboxSignatureComponent extends ClBaseComponent implements OnInit, OnDestroy, OnChanges {

  @Input() confirm?: boolean;

  @Output() check = new EventEmitter<boolean>();

  public role!: string;
  public name?: string | null;
  allRoles: Role[] = [];
  roles = [];

  public visible!: boolean;
  @ViewChild(MatCheckboxWithRolesComponent) matCheckbox!: MatCheckboxWithRolesComponent;

  constructor(
    protected override injector: Injector,
  ) {
    super(injector);
  }

  ngOnInit(): void { }


  ngOnChanges() {
    this.visible = false;
    this.name = this.getCurrentUser()?.name;
  }

  checkboxChanged(e: any) {
    this.checked = e.checked;
    if (this.checked) {
      const confirm = this.dialog.open(YesNoDialogComponent, {
        width: '400px',
        data: {
          message: this.getMessage('approve_message')?.description
        }
      });

      confirm.afterClosed().subscribe(data => {
        if (data) {
          this.emit_results();
        }
        else {
          this.matCheckbox.checked = false;
          this.checked = false;
          this.value = false;
        }
      });
    }
    else {
      const confirm = this.dialog.open(YesNoDialogComponent, {
        width: '400px',
        data: {
          message: this.getMessage('unapprove_message')?.description
        }
      });

      confirm.afterClosed().subscribe(data => {
        if (data) {
          this.emit_results();
        }
        else {
          this.matCheckbox.checked = true;
          this.checked = true;
          this.value = true;
        }
      });
    }
  }

  emit_results() {
    if (this.checked) {
      this.name = this.getCurrentUser()?.name;
      this.date = new Date();
    } else {
      this.name = null;
      this.date = null;
    }

    this.check.emit(this.checked);
  }

}

import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { ResourcesRefresh } from 'src/app/components/catalogs/store/resources/resources.action';
import { Resource } from 'src/app/components/catalogs/beamline-catalog/resource/resources';
import { ResourcesService } from 'src/app/components/catalogs/beamline-catalog/resource/resources.service';

@Component({
  selector: 'resource-autocomplete',
  templateUrl: './resource-autocomplete.component.html',
  styleUrls: ['./resource-autocomplete.component.scss']
})
export class ResourceAutocompleteComponent implements OnInit, OnChanges {
  @Input() placeholder!: string;
  @Input() disabled = false;
  @Input() resourceType?: number;
  @Input() templateTypeID?: number;
  @Input() customResources?: Resource[];

  @Output() error = new EventEmitter<string>();

  @Input() get resource(): Resource | undefined { return this.resourceValue; }

  @Output() resourceChange = new EventEmitter<Resource>();
  set resource(val) {
    this.resourceValue = val;
    this.resourceChange.emit(this.resourceValue);
  }

  @ViewChild('resourceInput') resourceInput!: ElementRef<HTMLInputElement>;

  resource$!: Observable<Resource[]>;
  resourceSubscription!: Subscription;
  resources: Resource[] = [];
  filteredResources: Resource[] = [];
  selectedResource!: Resource | null;
  resourceValue?: Resource;

  constructor(
    private store: Store,
    private resourceService: ResourcesService,
  ) { }

  ngOnInit(): void {
    // this.resource$ = this.store.select(state => state.Resource.Resources);
    // this.resourceSubscription = this.resource$.subscribe(data => {
    //   this.resources = data;
    //   this.onResourceInputKeyup(this.resourceInput?.nativeElement?.value);
    // });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('resourceType' in changes) {
      if (this.resourceType != undefined && this.resourceType != null) {
        this.resourceService.getByTypes([this.resourceType]).subscribe(
          data => this.store.dispatch(new ResourcesRefresh()),
          error => this.error.emit(error.error),
        );
      }
    }
    if ('templateTypeID' in changes) {
      if (this.templateTypeID != undefined && this.templateTypeID != null) {
        this.resourceService.getByTemplateType(this.templateTypeID).subscribe(
          data => this.store.dispatch(new ResourcesRefresh()),
          error => this.error.emit(error.error),
        );
      }
    }
    if ('customResources' in changes) {
      this.store.dispatch(new ResourcesRefresh());
    }
  }

  select(e: any) {
    const resource = e.option.value;
    this.selectedResource = resource;
    this.resource = resource;
    this.resourceInput.nativeElement.value = resource.name ?? '';
  }

  clear() {
    this.selectedResource = null;
    this.resource = undefined;
    this.resourceInput.nativeElement.value = '';
  }

  onResourceInputKeyup(e: any) {
    const text = e.target.value;
    this.filteredResources = this.resources?.filter(x => x.name?.trim()?.toLowerCase()?.includes(text?.trim()?.toLowerCase()));
    this.selectedResource = null;
    this.resource = undefined;
  }

}

import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { ChecklistTemplatesCreate, ChecklistTemplatesDelete, ChecklistTemplatesUpdate, ChecklistTemplatesRefreshById, ChecklistTemplatesRefreshByDocument } from './checklist-templates.action';
import { ChecklistTemplate } from '../../checklists';
import { ChecklistBuilderService } from '../../checklist-builder/checklist-builder.service';
import { TasksUpdateAll } from '../tasks/task.action';


export class ChecklistTemplatesStateModel {
  data: ChecklistTemplate[] = [];
}

@State<ChecklistTemplatesStateModel>({
  name: 'ChecklistTemplates',
  defaults: {
    data: [],
  }
})
@Injectable()
export class ChecklistTemplatesState {
  constructor(
    private _service: ChecklistBuilderService,
    private store: Store
  ) { }

  @Selector()
  static read(state: ChecklistTemplatesStateModel) {
    return state.data;
  }

  @Action(ChecklistTemplatesRefreshById)
  refreshByByID({ patchState, getState }: StateContext<ChecklistTemplatesStateModel>, { id }: ChecklistTemplatesRefreshById) {
    this._service.getchecklistTemplateById(id).toPromise().then(
      data => {
        const state = getState();
        const index = state.data.map(x => x.id).indexOf(id);

        if (index >= 0) {
          state.data.splice(index, 1);
        }
        if (data) {
          patchState({
            data: [...state.data, data]
          });
          this.refreshStoredTasks(data);
        }
      },
      error => console.error(error)
    );
  }

  @Action(ChecklistTemplatesRefreshByDocument)
  refreshByDocument({ patchState, getState }: StateContext<ChecklistTemplatesStateModel>, { id }: ChecklistTemplatesRefreshByDocument) {
    this._service.getChecklistTemplatesByDocumentTypeID(id).toPromise().then(
      data => {
        patchState({ data });
      },
      error => console.error(error)
    );
  }

  @Action(ChecklistTemplatesCreate)
  create({ getState, patchState }: StateContext<ChecklistTemplatesStateModel>, { item }: ChecklistTemplatesCreate) {
    const state = getState();
    const index = state.data.map(x => x.id).indexOf(item.id);
    if (index >= 0) {
      state.data.splice(index, 1);
    }
    patchState({
      data: [...getState().data, item]
    });
    this.refreshStoredTasks(item);
  }

  @Action(ChecklistTemplatesUpdate)
  update({ getState, patchState }: StateContext<ChecklistTemplatesStateModel>, { id, item }: ChecklistTemplatesUpdate) {
    const state = getState();
    const index = state.data.map(x => x.id).indexOf(id);

    if (index >= 0) {
      state.data.splice(index, 1);
      patchState({
        data: [...state.data, item]
      });
    }

    this.refreshStoredTasks(item);
  }

  @Action(ChecklistTemplatesDelete)
  delete({ getState, patchState }: StateContext<ChecklistTemplatesStateModel>, { id }: ChecklistTemplatesDelete) {
    patchState({
      data: getState().data.filter(x => x.id !== id)
    });
  }

  refreshStoredTasks(checklistTemplate: ChecklistTemplate) {
    const wfTaskLocals =
      checklistTemplate.wfTable?.wfTableLocal?.wfSectionLocals?.flatMap(
        (s) => s.wfStepLocals?.flatMap((z) => z.wfTaskLocals ?? []) ?? []
      ) ?? [];

    this.store.dispatch(new TasksUpdateAll(wfTaskLocals));
  }

}

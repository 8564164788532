<div class="row">
  <div class="col-6">
    <h1 mat-dialog-title>Select Rad Meter to Add</h1>
  </div>
  <div class="col-6 text-right">
    <button mat-stroked-button (click)="addNewMeter()">
      Add New RAD Meter
    </button>
  </div>
</div>

<div mat-dialog-content>
  <mat-form-field appearance="outline">
    <mat-label>RadMeter</mat-label>
    <input
      type="text"
      placeholder="Select RadMeter"
      matInput
      name="radMeterCtrl"
      [formControl]="radMeterCtrl"
      [matAutocomplete]="autoRadMeter"
      (keyup)="changedRadMeter($event)"
    />
    <mat-autocomplete
      #autoRadMeter="matAutocomplete"
      #radMeterAutocomplete
      [displayWith]="displayObject"
      (optionSelected)="selectedRadMeter($event.option.value)"
    >
      <mat-option
        class="option"
        *ngFor="let radMeter of filteredRadMeters"
        [value]="radMeter"
        class="mat-option-baseline"
      >
        <div class="row border-bottom">
          <div class="col-3">
            {{ radMeter.serialNumber }}
          </div>
          <div class="col-9 small lh18">
            Model:
            <b>{{
              radMeter.radMeterModel?.name + "/" + radMeter.radMeterType?.name
            }}</b
            ><br />
            Cal Due:
            <b
              class="date"
              [ngClass]="{
                expired: isDateExpired(radMeter.calibrationDueDate)
              }"
              >{{ radMeter.calibrationDueDate | date }}</b
            >
          </div>
        </div>
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <div class="container-fluid">
    <div class="row">
      <div class="col-8">
        <!-- {{ selected }} -->
      </div>
      <div class="col-4 text-right">
        <button mat-flat-button color="warn" (click)="close()">Cancel</button>
        <button
          mat-flat-button
          color="primary"
          [disabled]="!radMeterCtrl.value"
          (click)="add()"
        >
          Add
        </button>
      </div>
    </div>
  </div>
</div>

import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule, MatOptionModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldDefaultOptions, MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, PreloadAllModules, Routes } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { QuillModule } from 'ngx-quill';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { ChecklistComponentsModule } from 'src/app/controls/checklist-components/checklist-components.module';
import { SharedModule } from 'src/app/modules/shared.module';
import { ReviewsActionItemsDetailsComponent } from './reviews-action-items/reviews-action-items-details/reviews-action-items-details.component';
import { ReviewsActionItemsCommentsComponent } from './reviews-action-items/reviews-action-items-comments/reviews-action-items-comments.component';
import { ChecklistsModule } from '../checklists/checklists.module';
import { ReviewsActionItemsCompleteComponent } from './reviews-action-items/reviews-action-items-details/reviews-action-items-complete/reviews-action-items-complete.component';
import { AppComponent } from 'src/app/app.component';
import { ReviewRequestComponent } from './reviews-requests/review-request/review-request.component';
import { ReviewsRequestsTableComponent } from './reviews-requests/reviews-requests-table/reviews-requests-table.component';
import { ReviewsRequestsComponent } from './reviews-requests/reviews-requests.component';
import { ReviewsComponent } from './reviews.component';
import { ReviewsSettingsComponent } from './reviews-settings/reviews-settings.component';
import { ReviewsSettingsBoardTypesComponent } from './reviews-settings/reviews-settings-board-types/reviews-settings-board-types.component';
import { ReviewsSettingsMenuComponent } from './reviews-settings/reviews-settings-menu/reviews-settings-menu.component';
import { ReviewsSettingsReviewTypesComponent } from './reviews-settings/reviews-settings-review-types/reviews-settings-review-types.component';
import { ReviewsSettingsReviewTypesDetailsComponent } from './reviews-settings/reviews-settings-review-types/reviews-settings-review-types-details/reviews-settings-review-types-details.component';
import { ReviewsSettingsStatusesComponent } from './reviews-settings/reviews-settings-statuses/reviews-settings-statuses.component';
import { ReviewsSettingsStatusesDetailsComponent } from './reviews-settings/reviews-settings-statuses/reviews-settings-statuses-details/reviews-settings-statuses-details.component';
import { ReviewsSettingsStatusesActionsComponent } from './reviews-settings/reviews-settings-statuses/reviews-settings-statuses-details/reviews-settings-statuses-actions/statuses-actions.component';
import { ReviewsSettingsStatusesActionsDetailsComponent } from './reviews-settings/reviews-settings-statuses/reviews-settings-statuses-details/reviews-settings-statuses-actions/statuses-actions-details/statuses-actions-details.component';
import { ReviewsIndexComponent } from './reviews-index/reviews-index.component';
import { ReviewsBoardsCyclesComponent } from './reviews-boards/reviews-boards-cycles/reviews-boards-cycles.component';
import { ReviewsBoardsComponent } from './reviews-boards/reviews-boards.component';
import { ReviewsBoardsReviewsComponent } from './reviews-boards/reviews-boards-reviews/reviews-boards-reviews.component';
import { ReviewsActionItemsComponent } from './reviews-action-items/reviews-action-items.component';
import { ReviewAttachmentDocumentsComponent } from './reviews-attachments-documents/review-attachment-documents/review-attachment-documents.component';
import { ReviewsBoardsReviewsDetailsComponent } from './reviews-boards/reviews-boards-reviews/reviews-boards-reviews-details/reviews-boards-reviews-details.component';
import { NGX_MAT_DATE_FORMATS, NgxMatDateFormats } from '@angular-material-components/datetime-picker';
import { ReviewsBoardsReviewCreateComponent } from './reviews-boards/reviews-boards-review-create/reviews-boards-review-create.component';
import { ReviewsBoardsReviewsDetailsTemplateComponent } from './reviews-boards/reviews-boards-reviews/reviews-boards-reviews-details/reviews-details-template/reviews-details-template.component';
import { ReviewsBoardsReviewsMembersComponent } from './reviews-boards/reviews-boards-reviews/reviews-boards-reviews-details/reviews-boards-reviews-members/reviews-members.component';
import { ReviewAttendanceSavedEditComponent } from './reviews-boards/reviews-boards-reviews/reviews-boards-reviews-details/reviews-boards-reviews-members/review-attendance-saved/saved-edit/saved-edit.component';
import { ReviewMembersComponent } from './reviews-boards/reviews-boards-reviews/reviews-boards-reviews-details/reviews-boards-reviews-members/dialogs/review-members/review-members.component';
import { ReviewAttendanceSaveComponent } from './reviews-boards/reviews-boards-reviews/reviews-boards-reviews-details/reviews-boards-reviews-members/dialogs/review-attendance-save/review-attendance-save.component';
import { ReviewAttendanceCategoriesComponent } from './reviews-boards/reviews-boards-reviews/reviews-boards-reviews-details/reviews-boards-reviews-members/dialogs/review-attendance-categories/review-attendance-categories.component';
import { LoadSavedRosterRightComponent } from './reviews-boards/reviews-boards-reviews/reviews-boards-reviews-details/reviews-boards-reviews-members/dialogs/load-saved/right/load-saved-roster-right.component';
import { ReviewMembersLoadSavedRosterComponent } from './reviews-boards/reviews-boards-reviews/reviews-boards-reviews-details/reviews-boards-reviews-members/dialogs/load-saved/load-saved-roster.component';
import { ReviewActionsRequestExtensionComponent } from './reviews-boards/reviews-boards-reviews/reviews-boards-reviews-details/review-actions/review-actions-request-extension/review-actions-request-extension.component';
import { ReviewsBoardsAddResourceComponent } from './reviews-boards/reviews-boards-reviews/reviews-boards-add-resource/reviews-boards-add-resource.component';
import { ReviewLinksComponent } from './reviews-attachments-documents/review-links/review-links.component';
import { ReviewAttachmentComponent } from './reviews-attachments-documents/review-attachment/review-attachment.component';
import { ReviewAttachmentTypesComponent } from './reviews-attachments-documents/review-attachment-types/review-attachment-types.component';
import { ReviewAttachmentTypeDetailsComponent } from './reviews-attachments-documents/review-attachment-types/review-attachment-type-details/review-attachment-type-details.component';
import { ReviewsActionItemTypesComponent } from './reviews-action-items/reviews-action-item-types/reviews-action-item-types.component';
import { ReviewsService } from './reviews.service';
import { ReviewIndexTableComponent } from './reviews-index/review-index-table/review-index-table.component';
import { ReviewsSettingsPermissionsStatusComponent } from './reviews-settings/reviews-settings-permissions/reviews-settings-permissions-status/reviews-settings-permissions-status.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { ReviewsSettingsPermissionsComponent } from './reviews-settings/reviews-settings-permissions/reviews-settings-permissions.component';
import { ReviewsSettingsPermissionsRolesComponent } from './reviews-settings/reviews-settings-permissions/reviews-settings-permissions-roles/reviews-settings-permissions-roles.component';
import { LoadSavedRosterLeftComponent } from './reviews-boards/reviews-boards-reviews/reviews-boards-reviews-details/reviews-boards-reviews-members/dialogs/load-saved/left/load-saved-roster-left.component';

const CUSTOM_DATE_FORMATS: NgxMatDateFormats = {
  parse: {
    dateInput: 'l, LTS'
  },
  display: {
    dateInput: 'l, LTS',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  }
};

const appRoutes: Routes = [
  { path: 'review-requests', component: ReviewsRequestsComponent },
  { path: 'reviews/:type', component: ReviewsComponent },
  // {
  //   path: 'checklist-exec/:type',
  //   component: ChecklistExecComponent
  // },
  // { path: 'rss-test-view-mode', component: ChecklistTempComponent }
];

const appearance: MatFormFieldDefaultOptions = {
  appearance: 'outline'
};

@NgModule({
  declarations: [
    ReviewsComponent,

    ReviewsIndexComponent,
    ReviewIndexTableComponent,

    ReviewAttachmentComponent,
    ReviewAttachmentDocumentsComponent,
    ReviewAttachmentTypesComponent,
    ReviewLinksComponent,
    ReviewAttachmentTypeDetailsComponent,

    ReviewsBoardsComponent,
    ReviewsBoardsCyclesComponent,
    ReviewsBoardsReviewsComponent,
    ReviewsBoardsReviewCreateComponent,
    ReviewsBoardsReviewsDetailsComponent,
    ReviewsBoardsReviewsDetailsTemplateComponent,
    ReviewsBoardsReviewsMembersComponent,
    ReviewsBoardsAddResourceComponent,

    ReviewsSettingsComponent,
    ReviewsSettingsBoardTypesComponent,
    ReviewsSettingsMenuComponent,
    ReviewsSettingsReviewTypesComponent,
    ReviewsSettingsReviewTypesDetailsComponent,
    ReviewsSettingsStatusesComponent,
    ReviewsSettingsStatusesDetailsComponent,
    ReviewsSettingsStatusesActionsComponent,
    ReviewsSettingsStatusesActionsDetailsComponent,
    ReviewsSettingsPermissionsComponent,
    ReviewsSettingsPermissionsStatusComponent,
    ReviewsSettingsPermissionsRolesComponent,

    ReviewRequestComponent,
    ReviewsRequestsComponent,
    ReviewsRequestsTableComponent,

    ReviewsActionItemsComponent,
    ReviewsActionItemsDetailsComponent,
    ReviewsActionItemsCompleteComponent,
    ReviewsActionItemsCommentsComponent,
    ReviewsActionItemTypesComponent,

    ReviewActionsRequestExtensionComponent,

    ReviewMembersComponent,
    ReviewAttendanceSaveComponent,
    ReviewAttendanceSavedEditComponent,
    ReviewAttendanceCategoriesComponent,
    ReviewMembersLoadSavedRosterComponent,
    LoadSavedRosterRightComponent,
    LoadSavedRosterLeftComponent

  ],
  imports: [
    CommonModule,
    BrowserModule,
    NgbModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatMenuModule,
    MatButtonModule,
    MatDialogModule,
    HttpClientModule,
    ScrollingModule,
    HttpClientModule,
    MatSelectModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatChipsModule,
    MatIconModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatListModule,
    MatProgressSpinnerModule,
    MatCardModule,
    MatRadioModule,
    // MatFileUploadModule,
    MatStepperModule,
    MatSlideToggleModule,
    MatTabsModule,
    MatTooltipModule,
    MatGridListModule,
    FormsModule,
    ReactiveFormsModule,
    MatProgressBarModule,
    DragDropModule,
    MatTreeModule,
    MatExpansionModule,
    MatOptionModule,
    MatSidenavModule,
    DatePipe,
    MatToolbarModule,
    // SavingModule,
    QuillModule.forRoot(),
    RouterModule.forRoot(
      appRoutes,
      {
        enableTracing: true,
        useHash: true,
        anchorScrolling: 'enabled',
        preloadingStrategy: PreloadAllModules,
      }
    ),
    SharedModule,
    ChecklistsModule,
    ChecklistComponentsModule,
  ],
  providers: [
    ReviewsService,
    { provide: NGX_MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS }
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ReviewsModule { }

import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable, Subscription } from 'rxjs';
// import { AlertService } from 'src/app/controls/alert/alert.service';
// import { DisplayMessageService } from 'src/app/Services/display-message-service/display-message.service';
// import { WFSectionLocalResource } from 'src/app/Services/WorkFlow/work-flow';
// import { FormStatusEnum } from 'src/app/_interface/Enumerations';
// import { ProcedureTypeEnum } from 'src/app/_interface/procedure/procedure-type.enum';
// import { Schedule } from 'src/app/_interface/Schedules/Schedule';
// import { PlaceholdersService } from '../checklist-builder/checklist-builder-placeholders/checklist-builder-placeholders.service';
import { Checklist, ChecklistTemplateStatusEnum, ChecklistTemplate, TemplateType, RSSTest } from '../checklists';
import { ChecklistsService } from '../checklists.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ChecklistBuilderService } from '../checklist-builder/checklist-builder.service';
import { SchedulesRefresh } from '../../schedules/store/schedules/schedule.action';
import { FormStatusEnum } from 'src/app/common/enumerations/enumerations';
import { PlaceholdersService } from 'src/app/services/placeholders/placeholders.service';
import { ProcedureTypeEnum } from 'src/app/components/procedure/enums/procedure-type.enum';
import { WFSectionLocalResource } from 'src/app/services/work-flow/work-flow';
import { Schedule } from '../../schedules/models/schedule';
import { BaseComponent } from 'src/app/common/base/base.component';
import { TemplateTypesRefresh } from '../store/template-types/template-types.action';
// import { TemplateTypesRefresh } from '../store/template-types/template-types.action';
@Component({
  selector: 'app-checklist-temp',
  templateUrl: './checklist-temp.component.html',
  styleUrls: ['./checklist-temp.component.scss']
})
export class ChecklistTempComponent extends BaseComponent implements OnInit, OnDestroy {

  public schedule?: Schedule;
  public templateType?: TemplateType;
  public checklistTemplate?: ChecklistTemplate;
  public checklist!: Checklist;

  public schedules!: Schedule[];
  public templateTypes!: TemplateType[];
  public sections?: WFSectionLocalResource[];

  public schedules$!: Observable<Schedule[]>;
  public templateTypes$!: Observable<TemplateType[]>;

  public scheduleSubscribe!: Subscription;
  public templateTypeSubscribe!: Subscription;

  public loadingSchedules!: boolean;
  public loadingTemplateTypes!: boolean;
  public isLoading!: boolean;

  public dialogRef: MatDialogRef<any, any> | null;
  public dialogData: RSSTest;
  isPrintView = false;

  constructor(
    private checklistsService: ChecklistsService,
    private placeholdersService: PlaceholdersService,
    private router: Router,
    private route: ActivatedRoute,
    private checklistTempServ: ChecklistBuilderService,
    protected override injector: Injector
  ) {
    super(injector);
    this.dialogRef = this.injector.get(MatDialogRef, null);
    this.dialogData = this.injector.get(MAT_DIALOG_DATA, null);
  }

  override ngOnDestroy(): void {
    this.scheduleSubscribe?.unsubscribe();
    this.templateTypeSubscribe?.unsubscribe();
  }

  async ngOnInit() {
    this.isLoading = true;
    if (this.router.url.includes('/rss-test-view-mode')) {
      const id = this.route.snapshot.queryParams['id'];
      this.checklistTempServ.getchecklistTemplateById(id).toPromise().then(result => {
        this.isPrintView = true;
        this.dialogData = {
          procedureMasterId: result?.procedureMasterID,
          procedureTypeEnum: ProcedureTypeEnum.RSSTest,
          checklistTemplate: result
        };
        if (this.dialogData?.checklistTemplate !== undefined && this.dialogData?.checklistTemplate != null) {
          this.createChecklistFromTemplate(this.dialogData?.checklistTemplate);
        } else {
          this.loadSchedules();
        }
      });
    } else {
      if (this.dialogData?.checklistTemplate !== undefined && this.dialogData?.checklistTemplate != null) {
        this.createChecklistFromTemplate(this.dialogData?.checklistTemplate);
      } else {
        await this.loadSchedules();
      }
    }
  }



  async loadChecklistTemplates() {
    this.templateTypes$ = this.store.select(state => state.TemplateTypes.data);
    this.templateTypeSubscribe = this.templateTypes$.subscribe(data => {
      if (data && data?.length) {
        this.templateTypes = data.filter(x => x.scheduleTypeID === (this.dialogData.procedureTypeEnum === ProcedureTypeEnum.RSSTest ? 5 : this.dialogData.procedureTypeEnum === ProcedureTypeEnum.EPSTest ? 2 : 0));
        this.templateType = this.templateTypes.find(templateType => templateType.checklistTemplates?.some(x => x.status === ChecklistTemplateStatusEnum.Active && x.resourceID === this.schedule?.scheduleResources.find(resource => resource.resourceId)?.resourceId));
        this.checklistTemplate = this.templateType?.checklistTemplates?.find(x => x.status === ChecklistTemplateStatusEnum.Active && x.resourceID === this.schedule?.scheduleResources.find(resource => resource.resourceId)?.resourceId);
        if (this.checklistTemplate) { this.createChecklist(); }
        else {
          'ChecklistNotFound'
            ;
          this.dialogRef?.close();
        }
      } else {
        if (!this.loadingTemplateTypes) {
          this.loadingTemplateTypes = true;
          this.store.dispatch(new TemplateTypesRefresh());
        }
      }
    });
  }

  async loadSchedules() {
    this.schedules$ = this.store.select(state => state.Schedules.data);
    this.scheduleSubscribe = this.schedules$.subscribe(data => {
      if (data) {
        this.schedules = data;
        this.schedule = this.schedules.find(x => x.procedureMasterID === this.dialogData.procedureMasterId);
        if (!this.schedule || !this.schedule.scheduleResources.filter(x => x.resourceId).length) {
          'ChecklistNotFound'
            ;
          this.dialogRef?.close();
        } else {
          this.loadChecklistTemplates();
        }
      } else {
        if (!this.loadingSchedules) {
          this.loadingSchedules = true;
          this.store.dispatch(new SchedulesRefresh());
        }
      }
    });
  }

  createChecklist() {
    const checklist: Checklist = {
      id: 0,
      resourceID: this.schedule?.scheduleResources.find(x => x.resourceId)?.resourceId,
      scheduleId: this.schedule?.id,
      checklistTemplateID: this.checklistTemplate?.id,
      status: FormStatusEnum.Temporal
    };
    this.checklistsService.post(checklist).toPromise().then(async data => {
      if (data) {
        this.checklist = data;
        if (this.checklist.resource)
          await this.placeholdersService.setKeyValuePairs(this.checklist.resource, false);
        this.sections = this.checklist.wfTable?.wfTableLocal?.wfSectionLocals;
      }
      this.isLoading = false;
    }).catch((error) => {
      console.log(error);
      this.alert.defaultError();

      ;
    });
  }

  createChecklistFromTemplate(checklistTemplate: ChecklistTemplate) {
    const checklist: Checklist = {
      id: 0,
      resourceID: checklistTemplate.resourceID,
      checklistTemplateID: checklistTemplate.id,
      status: FormStatusEnum.Temporal,
    };
    this.checklistsService.post(checklist).toPromise()
      .then(data => {
        if (data) {
          this.checklist = data;
          if (this.checklist.resource)
            this.placeholdersService.setKeyValuePairs(this.checklist.resource, false);
          this.sections = this.checklist.wfTable?.wfTableLocal?.wfSectionLocals;
        }
        this.isLoading = false;
      })
      .catch(error => {
        console.error(error);
        this.alert.defaultError();

        ;
      });
  }

  sendPrintView() {
    const newRelativeUrl = this.router.createUrlTree(['/rss-test-view-mode'], { queryParams: { id: this.dialogData.checklistTemplate?.id } });
    const baseUrl = window.location.href.replace(this.router.url, '');
    window.open(baseUrl + newRelativeUrl, '_blank');
  }

}

<div class="container-fluid mt-2">
  <form [formGroup]="formGroup">
    <div class="row main-panel scrollbar pt-1">
      <div class="col-12">
        <div class="row">
          <div class="col-12 col-sm-3">
            <mat-form-field
              appearance="outline"
              [ngClass]="{
                hilite: isModified('name')
              }"
            >
              <mat-label>Accelerator Name</mat-label>
              <input
                matInput
                name="acceleratorName"
                (change)="setAcceleratorName()"
                formControlName="nameCtrl"
              />
            </mat-form-field>
          </div>
          <div class="col-12 col-sm-3">
            <mat-form-field
              appearance="outline"
              [ngClass]="{
                hilite: isModified('description')
              }"
            >
              <mat-label>Label</mat-label>
              <input
                matInput
                name="acceleratorDescription"
                formControlName="descriptionCtrl"
                (change)="setFormDirty()"
              />
            </mat-form-field>
          </div>
          <div class="col-12 col-sm-3">
            <div
              class="slide-center"
              [ngClass]="{
                hilite: isModified('show')
              }"
            >
              <!-- <mat-label class="label">Show?</mat-label> -->
              <mat-slide-toggle
                [ngClass]="{
                  hilite: isModified('show')
                }"
                name="accVisible"
                formControlName="showCtrl"
                (change)="setFormDirty()"
                >Show?</mat-slide-toggle
              >
            </div>
          </div>
          <div class="col-12 col-sm-3">
            <div
              class="slide-center"
              [ngClass]="{
                hilite: isModified('active')
              }"
            >
              <!-- <mat-label class="label">Active</mat-label> -->
              <mat-slide-toggle
                [ngClass]="{
                  hilite: isModified('active')
                }"
                name="accActive"
                formControlName="activeCtrl"
                (change)="setFormDirty()"
                >Active</mat-slide-toggle
              >
            </div>
          </div>
        </div>
        <div class="mb-4">
          <div class="row mb-1">
            <div class="col-12 col-sm-12">
              <mat-label>Restrictions</mat-label>
            </div>
          </div>
          <div
            class="table-container"
            [ngClass]="{
              hilite: isModified('restrictions')
            }"
          >
            <app-restriction-table
              [isEditionChild]="isEditing || isCreating"
              [restrictionsChild]="restrictionsParent"
              [branchlinesChild]="locationsParent"
              [shutterChild]="accelerator"
              (emitData)="getDataFromRestriction($event)"
            >
            </app-restriction-table>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-sm-12">
            <mat-label>Locations</mat-label>
          </div>
        </div>
        <div
          class="table-container mb-4"
          [ngClass]="{
            hilite: isModified('locations')
          }"
        >
          <div class="row">
            <div class="col-12 col-sm-12">
              <div class="mat-container">
                <mat-table cdkDropList #table [dataSource]="dataSourceLocation">
                  <ng-container matColumnDef="location">
                    <mat-header-cell *matHeaderCellDef>
                      Location
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">{{
                      element.name
                    }}</mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="add">
                    <mat-header-cell *matHeaderCellDef>
                      <mat-icon
                        mat-list-icon
                        class="add-icon"
                        (click)="openAddLocationDialog()"
                        >add</mat-icon
                      >
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                      <mat-icon
                        mat-list-icon
                        class="edit"
                        color="accent"
                        (click)="openEditLocationDialog(element)"
                        >edit</mat-icon
                      >
                      <mat-icon
                        mat-list-icon
                        class="delete"
                        color="warn"
                        (click)="deleteLocation(element)"
                        >delete</mat-icon
                      >
                    </mat-cell>
                  </ng-container>
                  <mat-header-row
                    *matHeaderRowDef="getColumns()"
                  ></mat-header-row>
                  <mat-row
                    *matRowDef="let row; columns: getColumns()"
                    [ngClass]="{
                      'background-yellow': row.isModified
                    }"
                  ></mat-row>
                </mat-table>
              </div>
            </div>
          </div>
        </div>
        <div class="row h-20">
          <div class="col-10">
            <mat-label>Shielding</mat-label>
          </div>
          <div class="col-2">
            <button
              *ngIf="(isEditing || isCreating) && !enableMove"
              class="float-right top-20"
              mat-icon-button
              color="primary"
              (click)="enableMove = !enableMove"
              matTooltip="Enable drag and drop"
            >
              <mat-icon>swipe_vertical</mat-icon>
            </button>
            <button
              *ngIf="(isEditing || isCreating) && enableMove"
              class="float-right top-20"
              mat-icon-button
              color="warn"
              (click)="enableMove = !enableMove"
              matTooltip="Disable drag and drop"
            >
              <mat-icon>mobiledata_off</mat-icon>
            </button>
          </div>
        </div>
        <div class="row mt-1 mb-4">
          <div class="col-12 col-sm-12">
            <div
              class="table-container"
              [ngClass]="{
                hilite: isModified('shieldings')
              }"
            >
              <accelerator-shielding
                [accelerator]="accelerator"
                [shieldings]="shieldings"
                [isEditing]="isEditing || isCreating"
                [showPrev]="showModification"
                [enableMove]="enableMove"
                (changed)="shieldingsChanged($event)"
              >
              </accelerator-shielding>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
<div id="sidenav-content-buttons" class="sidenav-content-buttons">
  <div class="row no-gutters">
    <div class="col-6">
      <button
        mat-flat-button
        color="warn"
        *ngIf="canCancel || formGroup.dirty || isEditing || isCreating"
        (click)="cancel()"
      >
        Cancel
      </button>
      <button
        mat-flat-button
        color="warn"
        *ngIf="
          canModify &&
          !formGroup.dirty &&
          !isEditing &&
          !isCreating &&
          !acceleratorCatalog?.pendingDetails
        "
        (click)="delete()"
      >
        Delete
      </button>
    </div>
    <div class="col-6">
      <button
        mat-flat-button
        color="accent"
        class="right-btn"
        *ngIf="
          canModify &&
          !isCreating &&
          !isEditing &&
          acceleratorCatalog?.pendingAcceleratorCatalog?.action != 3
        "
        [disabled]="!canModify || isEditing"
        (click)="modifyAccelerator()"
      >
        Modify
      </button>
      <button
        mat-flat-button
        color="primary"
        class="right-btn"
        *ngIf="isEditing || isCreating"
        [disabled]="!isformDirty()"
        (click)="save()"
      >
        Save
      </button>
      <button
        mat-flat-button
        color="primary"
        class="right-btn"
        *ngIf="canApprove && !isCreating && !isEditing"
        (click)="approve()"
      >
        Approve
      </button>
      <button
        mat-flat-button
        color="warn"
        class="right-btn"
        *ngIf="canApprove && !isCreating && !isEditing"
        (click)="openAddDisapprovalDialog()"
      >
        Disapprove
      </button>
    </div>
  </div>
</div>

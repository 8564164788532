export enum NotificationType {
  CreateSCF = 1,
  CreateRSSWA = 101,
  Procedure = 200,
  ChecklistStatusChange = 300
}

export enum Roles {
  SA = 1,
  ADM = 2,
  HP = 4,
  ATS = 5,
  VAC = 7,
  BLSCI = 8,
  EMT = 11,
  FO = 12,
  MT = 13,
  OIC = 14,
  SnA = 21,
  AO = 24,
  OT = 25,
  ALTBLSCI = 47,
  KEE = 1054,
  WFE = 57,
  BRC = 1047,
  RFEng = 1048,
  RP = 1051,
  PC = 1052,
  ARC = 1062,
  RWAPI = 20,
  EMSup = 1066,
  PPSTBTA = 1068,
  RWPADes = 1110
}

export enum FormType {
  SCF = 1,
  KE = 2,
  RSSWA = 3,
  PPSTB = 4,
  EBF = 9,
  ABSI = 11,
  RCP = 200,
  REVIEW = 201,
  REVIEW_REQUEST = 999
}

export enum FormStatusEnum {
  Temporal = -2,
  Canceled = -1,
  New = 0,
  Draft = 1,
  WorkApprovals = 2,
  Active = 3,
  WorkCompleted = 4,
  Closed = 5,
  Amendment = 6,
  RestrictionsAmendment = 7,
  Pending = 8,
  AmendmentSign = 9,
  Initiated = 10,
  Vacuum = 11,
  Shielding = 12,
  KeyEnable = 13,
  Handshake = 14,
  ActiveBypassInstalled = 15,
  ActiveOperationsVerified = 16,
  ActiveBypassRemoved = 17,
  ActiveMitigationsInPlace = 18,
  AwaitingInspection = 19,
  PendingReview = 30,
  Reviewing = 31,
  Correcting = 32,
  ApprovalsPending = 33,
  Approvals = 34,
  Preparation = 39,
  ReviewAndConcurrence = 41,
  HoldPoint = 50,
  PendingCancelation = 51
}

export enum ResourceType {
  All = 0,
  Accelerator = 1,
  Beamline = 2,
  Branchline = 3,
  Shielding = 4,
  Vacuum = 6,
  Shutter = 7,
  RSSSystems = 10,
  TOMBISSystems = 11,
  Endstation = 13,
  FastValveSensor = 14,
  AcceleratorLocation = 15,
  ReviewProcessResource = 20,
  RadMonitor = 90,

  // To use only on Beamline Catalog
  Note = 1001,
  Organization = 1002,
  TOCA = 1003
}

export enum AffectedSystemType {
  BLSystem = 1,
  AccSystem = 2,
  TOMBISSystem = 3
}

export enum Action {
  Approve = 1,
  Disapprove = 2,
  Unapprove = 3,
  Amendment = 4,
  Cancel = 5
}

export enum ActionPendingCatalog {
  None = 0,
  Create = 1,
  Update = 2,
  Delete = 3
}

export enum StatusPendingCatalog {
  Pending = 1,
  Approved = 2,
  Disapproved = 3
}

export enum Catalog {
  None = 0,
  BeamlineCatalog = 1,
  Users = 2,
  Roles = 3,
  AcceleratorCatalog = 4,
  EmailNotificationCatalog = 5,
  RolePrivileges = 6,
  Trainings = 7
}

export enum WFSaveOptions {
  AUTO = 1,
  MANUAL = 2
}

export enum PendingCatalogColor {
  Modify = 1,
  Create = 2,
  Inactive = 3,
  ModifyRSS = 4
}

export enum RSSTestType {
  Hutchless = 1,
  HutchHutchFunctional = 2,
  MiniHutch = 3,
  VacuumSwitch = 4
}

export enum RadNoteType {
  Normal = 1,
  Fault = 2
}

export enum PendingResourcesType {
  Default = 0,
  Details = 1,
  Rss_Database = 2,
  Both = 3
}

export enum CustomImageSizeOptions {
  Fit = 1,
  Small = 2,
  Original = 3
}

export enum ReviewCycleStatus {
  Rejected = -1,
  Pending = 0,
  Approved = 1,
  Archived = 9
}

export enum StatusType {
  New = 0,
  Active = 1,
  Closed = 2,
  Expired = 3,
  Canceled = 4,
  Pending = 5,
}

export enum ComponentType {
  Button = -1,
  ButtonSignature = 1,
  CheckboxSignature = 2,
  QuestionSignature = 3,
  Checkbox = 4,
  CheckboxAndRadio = 6,
  Note = 7,
  CheckboxAndTextInput = 8,
  ScheduleAndCheckbox = 9,
  CheckboxYesNo = 10,
  CheckboxAndComments = 11,
  Comments = 12,
  Subtitle = 13,
  Catl = 14,
  ScheduleAbsi = 15,
  CheckboxMultiple = 16,
  CheckboxMultipleWithTextbox = 17,
  MultipleTextbox = 18,
  TextNumericHead = 19,
  CheckboxRadioLocation = 20,
  ButtonSignatureDuplicate = 21,
  RADSurveyImageTable = 22,
  CloseButton = 23,
  BRATOI = 24,
  Olog = 25,
  RadMonitorIncoming = 26,
  RadMonitorOutgoing = 27,
  StatusChange = 28,
  RadMeters = 29,
  CheckboxMultipleSign = 30,
  PlaceholderCondition = 31,
  ShieldingsComponent = 32,
  Restrictions = 33,
  Locations = 34,
  ButtonSignatureRadio = 35,
  OperationalApproval = 36,
  RadMonitorDueDate = 37
}

export enum WorkRequired {
  None = 0,
  AccRFShielding = 1,
  BlShielding = 2,
  PermanentAccRemoval = 3,
  PermanentBLRemoval = 4,
  TocaMovement = 5
}

export enum OperationalApprovalCode {
  BLREVREQ = 'BLREVREQ',
  ACREVREQ = 'ACREVREQ',
  RADSVREQ = 'RADSVREQ',
  DOCUPREQ = 'DOCUPREQ'
}

export enum SignatureAction {
  Approve = 1,
  Disapprove = 2,
  Unnaprove = 3
}

export enum ReturnType {
  None = 0,
  Numeric = 1,
  Boolean = 2,
  Text = 3,
  Sign = 4,
}

export enum CompareOperator {
  '=' = 0,
  '>' = 1,
  '>=' = 2,
  '<' = 3,
  '<=' = 4,
  '<>' = 5
}

export enum GeneralCondition {
  AllTasksCompleted = 'ALL-TASKS-COMPLETE',
  ChecklistComplete = 'CHECKLIST-COMPLETE'

}

export enum LoadingOrigin {
  Side = 1, Buttons = 2, Main = 3, Self = 4
}


export enum MenuItemType {
  Divider = -1,
  Route = 1,
  Submenu = 2,
  Table = 3
}

export enum OlogNotificationType {
  Activate = 1,
  Amended = 2,
  Closed = 3,
  Handshake = 4
}


export enum Fields {
  TEXT = 'text',
  BOOLEAN = "booleanValue",
  DOUBLE = "doubleValue",
  NUMERIC = "numericValue",
  VALUE = "value",
}

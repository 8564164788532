<!-- <div class="waiting-dots">
  <div></div>
  <div></div>
  <div></div>
</div> -->
<!-- Placeholder loading for text -->
<!-- <div class="skeleton-text" style="width: 300px; height: 20px"></div> -->
<div
  class="skeleton-text"
  style="width: 250px; height: 20px; margin-top: 10px"
></div>
<!-- <div
  class="skeleton-text"
  style="width: 200px; height: 20px; margin-top: 10px"
></div> -->

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { saveActionItemResource } from './action-item';
import { Observable } from 'rxjs';
import { ActionItem } from 'src/app/common/models/action-item';
import { environment } from 'src/environments/environment';

@Injectable()
export class ActionItemService {
  BASE_URL = environment.urls.baseApiUrl; // Api URL
  Api = '/ActionItems';

  constructor(private http: HttpClient) {

  }

  public postActionItem(actionItem: saveActionItemResource): Observable<ActionItem> {
    const httpresult = this.http.post<ActionItem>(this.BASE_URL + this.Api, actionItem);
    return httpresult;
  }

  public putActionItem(id: number, actionItem: saveActionItemResource): Observable<ActionItem> {
    const httpresult = this.http.put<ActionItem>(this.BASE_URL + this.Api + '/' + id, actionItem);
    return httpresult;
  }

  public readAll(): Observable<ActionItem[]> {
    const httpresult = this.http.get<ActionItem[]>(this.BASE_URL + this.Api);
    return httpresult;
  }

  public readById(id: number): Observable<ActionItem> {
    const httpresult = this.http.get<ActionItem>(this.BASE_URL + this.Api + '/' + id);
    return httpresult;
  }

  public readBySection(id: number): Observable<ActionItem[]> {
    const httpresult = this.http.get<ActionItem[]>(this.BASE_URL + this.Api + '/BySection/' + id);
    return httpresult;
  }

  public readBySectionNumber(section: number): Observable<ActionItem[]> {
    const httpresult = this.http.get<ActionItem[]>(this.BASE_URL + this.Api + '/BySectionNo/' + section);
    return httpresult;
  }

  public readByTask(id: number): Observable<ActionItem[]> {
    const httpresult = this.http.get<ActionItem[]>(this.BASE_URL + this.Api + '/ByTask/' + id);
    return httpresult;
  }

  public readByForm(id: number): Observable<ActionItem[]> {
    const httpresult = this.http.get<ActionItem[]>(this.BASE_URL + this.Api + '/ByForm/' + id);
    return httpresult;
  }

  public emailByForm(id: number): Observable<ActionItem[]> {
    const httpresult = this.http.get<ActionItem[]>(this.BASE_URL + this.Api + '/Email/' + id);
    return httpresult;
  }

  public deleteActionItem(id: number) {
    const httpresult = this.http.delete(this.BASE_URL + this.Api + '/' + id);
    return httpresult;
  }
}

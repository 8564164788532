<div class="d-none d-xl-block">
  <div class="row d-flex align-items-end">
    <div class="col-xl-3 col-9 d-flex align-items-end">
        <div class="col-3 small text-right nowrap">{{ signatureDate }}</div>
        <div class="col-9 p-0 d-flex justify-content-center border-bottom date nowrap">{{ date | date : "medium" }}</div>
    </div>
    <div class="col-xl-5 col-9 d-flex align-items-end">
        <div class="col-3 small text-right">{{ inspector }}</div>
        <div class="col-9 border-bottom bold">
          {{
            user && user.name ? user.name + " (" + user.initials + ")" : null
          }}
        </div>
    </div>
    <div class="col-xl-4 col-3 d-flex align-items-end">
        <div class="col-8 small text-right nowrap ellipsis">{{ pendingActions }}</div>
        <div class="col-4 border-bottom bold d-flex justify-content-center">{{ catl }}</div>
    </div>
  </div>
</div>

<div class="d-xl-none">
  <div class="row d-flex align-items-end">
    <div class="col-7">
      <div class="row">
        <div class="col-3 small text-right nowrap">{{ inspector }}</div>
        <div class="col-9 border-bottom bold">
          {{
            user && user.name ? user.name + " (" + user.initials + ")" : null
          }}
        </div>
      </div>
      <div class="row">
        <div class="col-3 small text-right nowrap">{{ signatureDate }}</div>
        <div class="col-9 p-0 d-flex justify-content-center border-bottom date">{{ date | date : "medium" }}</div>
      </div>
    </div>
    <div class="col-5">
      <div class="row">
        <div class="col-8 small text-right nowrap ellipsis">{{ pendingActions }}</div>
        <div class="col-4 border-bottom bold d-flex justify-content-center">{{ catl }}</div>
      </div>
    </div>
  </div>
</div>

<div
  class="container-fluid mt-2 pb-2"
  [ngClass]="{ invisible: !dataSourcePendingAccelerator.data.length }"
>
  <pending-approvals-catalogs-table
    id="accCatalog"
    [dataSource]="dataSourcePendingAccelerator"
    [pendingCatalog]="pendingCatalogType.Accelerator"
  ></pending-approvals-catalogs-table>
</div>

<div
  class="container-fluid mt-2 pb-2"
  [ngClass]="{ invisible: !dataSourcePendingResource.data.length }"
>
  <pending-approvals-catalogs-table
    id="beamCatalog"
    [dataSource]="dataSourcePendingResource"
    [pendingCatalog]="pendingCatalogType.Beamline"
  ></pending-approvals-catalogs-table>
</div>

<div
  class="container-fluid mt-2 pb-2"
  [ngClass]="{ invisible: !dataSourcePendingUser.data.length }"
>
  <pending-approvals-catalogs-table
    id="users"
    [dataSource]="dataSourcePendingUser"
    [pendingCatalog]="pendingCatalogType.User"
  ></pending-approvals-catalogs-table>
</div>

<div
  class="container-fluid mt-2 pb-2"
  [ngClass]="{ invisible: !dataSourcePendingRole.data.length }"
>
  <pending-approvals-catalogs-table
    id="roles"
    [dataSource]="dataSourcePendingRole"
    [pendingCatalog]="pendingCatalogType.Role"
  ></pending-approvals-catalogs-table>
</div>

<div
  class="container-fluid mt-2 pb-2"
  [ngClass]="{
    invisible: !dataSourcePendingNotificationTemplate.data.length
  }"
>
  <pending-approvals-catalogs-table
    id="notificationTemplates"
    [dataSource]="dataSourcePendingNotificationTemplate"
    [pendingCatalog]="pendingCatalogType.NotificationTemplate"
  ></pending-approvals-catalogs-table>
</div>

<div
  class="container-fluid mt-2 pb-2"
  [ngClass]="{ invisible: !dataSourcePendingRSSDatabase.data.length }"
>
  <pending-approvals-catalogs-table
    id="rssdb"
    [dataSource]="dataSourcePendingRSSDatabase"
    [pendingCatalog]="pendingCatalogType.RSSDb"
  ></pending-approvals-catalogs-table>
</div>

<!-- <div
  class="container-fluid mt-2"
  *ngIf="dataSourcePendingAccelerator.data.length"
>
  <h6 id="accCatalog">Accelerator Catalog</h6>
  <div class="mat-container scrollbar">
    <mat-table mat-table [dataSource]="dataSourcePendingAccelerator" matSort>
      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          Name
        </mat-header-cell>
        <mat-cell class="font-weight-bold" *matCellDef="let element" nowrap>
          {{ element.acceleratorName }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="status">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          Status
        </mat-header-cell>
        <mat-cell class="pl-1" *matCellDef="let element">
          {{ element.statusName }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="action">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          Action
        </mat-header-cell>
        <mat-cell class="pl-1" *matCellDef="let element">
          {{ element.actionName }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="date">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          Date
        </mat-header-cell>
        <mat-cell class="pl-1" *matCellDef="let element">
          {{ element.createdOn | date : "longDate" }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="createdBy">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          Submitted By
        </mat-header-cell>
        <mat-cell class="pl-1" *matCellDef="let element">
          {{ element.userCreatedBy.name }}
        </mat-cell>
      </ng-container>
      <mat-header-row
        *matHeaderRowDef="displayedColumns; sticky: true"
      ></mat-header-row>
      <mat-row
        *matRowDef="let row; columns: displayedColumns"
        (click)="setItemAccelerator(row)"
        class="table-row"
      ></mat-row>
    </mat-table>
  </div>
</div> -->

<!-- <div class="container-fluid mt-2" *ngIf="dataSourcePendingResource.data.length">
  <h6 id="beamCatalog">Beamline Catalog</h6>
  <div class="mat-container scrollbar">
    <mat-table mat-table [dataSource]="dataSourcePendingResource" matSort>
      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          Name
        </mat-header-cell>
        <mat-cell class="font-weight-bold" *matCellDef="let element" nowrap>
          {{ element.resourceName }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="status">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          Status
        </mat-header-cell>
        <mat-cell class="pl-1" *matCellDef="let element">
          {{ element.statusName }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="action">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          Action
        </mat-header-cell>
        <mat-cell class="pl-1" *matCellDef="let element">
          {{ element.actionName }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="date">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          Date
        </mat-header-cell>
        <mat-cell class="pl-1" *matCellDef="let element">
          {{ element.createdOn | date : "longDate" }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="createdBy">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          Submitted By
        </mat-header-cell>
        <mat-cell class="pl-1" *matCellDef="let element">
          {{ element.userCreatedBy?.name }}
        </mat-cell>
      </ng-container>
      <mat-header-row
        *matHeaderRowDef="displayedColumns; sticky: true"
      ></mat-header-row>
      <mat-row
        *matRowDef="let row; columns: displayedColumns"
        (click)="setItemResource(row)"
        class="table-row"
      ></mat-row>
    </mat-table>
  </div>
</div> -->

<!-- <div class="container-fluid mt-2" *ngIf="dataSourcePendingUser.data.length">
  <h6 id="users">Users</h6>
  <div class="mat-container scrollbar">
    <mat-table mat-table [dataSource]="dataSourcePendingUser" matSort>
      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          Name
        </mat-header-cell>
        <mat-cell class="font-weight-bold" *matCellDef="let element" nowrap>
          {{ element.userName }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="status">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          Status
        </mat-header-cell>
        <mat-cell class="pl-1" *matCellDef="let element">
          {{ element.statusName }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="action">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          Action
        </mat-header-cell>
        <mat-cell class="pl-1" *matCellDef="let element">
          {{ element.actionName }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="date">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          Date
        </mat-header-cell>
        <mat-cell class="pl-1" *matCellDef="let element">
          {{ element.createdOn | date : "longDate" }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="createdBy">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          Submitted By
        </mat-header-cell>
        <mat-cell class="pl-1" *matCellDef="let element">
          {{ element.userCreatedBy.name }}
        </mat-cell>
      </ng-container>
      <mat-header-row
        *matHeaderRowDef="displayedColumns; sticky: true"
      ></mat-header-row>
      <mat-row
        *matRowDef="let row; columns: displayedColumns"
        (click)="setItemUser(row)"
        class="table-row"
      ></mat-row>
    </mat-table>
  </div>
</div> -->

<!-- <div class="container-fluid mt-2" *ngIf="dataSourcePendingRole.data.length">
  <h6 id="roles">Roles</h6>
  <div class="mat-container scrollbar">
    <mat-table mat-table [dataSource]="dataSourcePendingRole" matSort>
      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          Name
        </mat-header-cell>
        <mat-cell class="font-weight-bold" *matCellDef="let element" nowrap>
          {{ element.name }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="status">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          Status
        </mat-header-cell>
        <mat-cell class="pl-1" *matCellDef="let element">
          {{ element.statusName }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="changes">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          Changes
        </mat-header-cell>
        <mat-cell class="pl-1" *matCellDef="let element">
          {{ element.changes }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="action">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          Action
        </mat-header-cell>
        <mat-cell class="pl-1" *matCellDef="let element">
          {{ element.actionName }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="date">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          Date
        </mat-header-cell>
        <mat-cell class="pl-1" *matCellDef="let element">
          {{ element.createdOn | date : "longDate" }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="createdBy">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          Submitted By
        </mat-header-cell>
        <mat-cell class="pl-1" *matCellDef="let element">
          {{ element.userCreatedBy?.name }}
        </mat-cell>
      </ng-container>
      <mat-header-row
        *matHeaderRowDef="rolesDisplayedColumns; sticky: true"
      ></mat-header-row>
      <mat-row
        *matRowDef="let row; columns: rolesDisplayedColumns"
        (click)="setItemRole(row)"
        class="table-row"
      ></mat-row>
    </mat-table>
  </div>
</div> -->

<!-- <div
  class="container-fluid mt-2"
  *ngIf="dataSourcePendingNotificationTemplate.data.length"
>
  <h6 id="notificationTemplates">Notification Templates</h6>
  <div class="mat-container scrollbar">
    <mat-table
      mat-table
      [dataSource]="dataSourcePendingNotificationTemplate"
      matSort
    >
      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          Name
        </mat-header-cell>
        <mat-cell class="font-weight-bold" *matCellDef="let element" nowrap>
          {{ element.notificationTemplateName }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="status">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          Status
        </mat-header-cell>
        <mat-cell class="pl-1" *matCellDef="let element">
          {{ element.statusName }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="action">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          Action
        </mat-header-cell>
        <mat-cell class="pl-1" *matCellDef="let element">
          {{ element.actionName }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="date">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          Date
        </mat-header-cell>
        <mat-cell class="pl-1" *matCellDef="let element">
          {{ element.createdOn | date : "longDate" }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="createdBy">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          Submitted By
        </mat-header-cell>
        <mat-cell class="pl-1" *matCellDef="let element">
          {{ element.userCreatedBy.name }}
        </mat-cell>
      </ng-container>
      <mat-header-row
        *matHeaderRowDef="displayedColumns; sticky: true"
      ></mat-header-row>
      <mat-row
        *matRowDef="let row; columns: displayedColumns"
        (click)="setItemNotificationTemplate(row)"
        class="table-row"
      ></mat-row>
    </mat-table>
  </div>
</div> -->

<!-- <div
  class="container-fluid mt-2"
  *ngIf="dataSourcePendingRSSDatabase.data.length"
>
  <h6 id="rssdb">RSS Database</h6>
  <div class="mat-container scrollbar">
    <mat-table mat-table [dataSource]="dataSourcePendingRSSDatabase" matSort>
      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          Name
        </mat-header-cell>
        <mat-cell class="font-weight-bold" *matCellDef="let element" nowrap>
          {{ element.resourceName }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="status">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          Status
        </mat-header-cell>
        <mat-cell class="pl-1" *matCellDef="let element">
          {{ element.statusName }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="action">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          Action
        </mat-header-cell>
        <mat-cell class="pl-1" *matCellDef="let element">
          {{ element.actionName }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="date">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          Date
        </mat-header-cell>
        <mat-cell class="pl-1" *matCellDef="let element">
          {{ element.createdOn | date : "longDate" }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="createdBy">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          Submitted By
        </mat-header-cell>
        <mat-cell class="pl-1" *matCellDef="let element">
          {{ element.userCreatedBy?.name }}
        </mat-cell>
      </ng-container>
      <mat-header-row
        *matHeaderRowDef="displayedColumns; sticky: true"
      ></mat-header-row>
      <mat-row
        *matRowDef="let row; columns: displayedColumns"
        (click)="setItemRssDb(row)"
        class="table-row"
      ></mat-row>
    </mat-table>
  </div>
</div> -->

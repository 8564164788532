import { WFComponent, WFStepConfig } from '../../components/workflow/workflow';
import { PrivilegeEnum } from '../role-privilege/privilege-enum';
import { ComponentType, FormStatusEnum } from 'src/app/common/enumerations/enumerations';
import { ChecklistExecStatus, Annotation } from 'src/app/components/checklists/checklists';
import { SCFHoldPointResource } from 'src/app/components/scf-v2/scf';
import { ReviewSatisfactionCheck } from 'src/app/components/reviews/reviews.models';
import { Role } from 'src/app/components/catalogs/roles/services/role';
import { User } from 'src/app/components/catalogs/user-catalog/services/user';
import { utils } from 'src/app/modules/libs/utils';

export class WfActionLocalResource {
  id?: number;
  name?: string;
  label?: string;
  wfActionConfigID?: number;
  action?: number;
  wfTaskLocalID?: number;
  wfNextStepLocalID?: number;
  subAction?: number;
  nextStatusID?: number | null;
  nextStatus?: ChecklistExecStatus;
}
export class WfTaskLocalResource {
  id?: number;
  name!: string;
  name2?: string | null;
  name3?: string | null;
  name4?: string | null;
  value?: boolean;
  numericValue?: number;
  doubleValue?: number;
  text?: string;
  enabled?: boolean;
  type?: number;
  order?: number;
  wfTaskConfigID?: number;
  wfActionLocals?: WfActionLocalResource[];
  wfRoleLocals?: WFRoleLocal[];
  wfTasks?: WfTaskResource[];
  wfStepLocalID?: number;
  wfStepLocal?: WFStepLocalResource;
  scheduleResourceId?: number;
  scheduleType?: number;
  resourceId?: number;
  resourceName?: string | null;
  configuration?: string;
  condition?: string;
  code?: string;
  options?: string;
  componentID?: number;
  component?: WFComponent;
  hashCode?: string;
  disabled?: boolean;
  reviewSatisfactionChecks?: ReviewSatisfactionCheck[];
  booleanValue?: boolean;
  amendmentID?: number;
  holdPointID?: number;
  holdPoint?: SCFHoldPointResource;
  requesterSignature?: boolean;
  required?: boolean;
  annotations?: Annotation[];
  isCompleted?: boolean;
  isTouched?: boolean;

  // Checklist Builder Undo Feature Properties
  logID?: number;
  checklistTemplateID?: number;
}

export interface WFSectionLocalResource {
  id: number;
  number?: number;
  name?: string;
  description?: string;
  code?: string;
  type?: number;
  status?: WFSectionLocalStatus;
  order?: number;
  wfTableID?: number;
  wfTableLocalID?: number;
  wfSectionConfigID?: number;
  actionItems?: number;
  collapsible?: boolean;
  collapsed?: boolean;
  wfStepLocals?: WFStepLocalResource[];
  wfSignatures?: WfSignatureResource[];
  createdBy?: User;
  createdOn?: Date;
  updatedBy?: User;
  updatedOn?: Date;
  configuration?: string;

  //---
  expanded?: boolean;

  // Checklist Builder Undo Feature Properties
  logID?: number;
  checklistTemplateID?: number;
}

export class WFStepLocalResource {
  id!: number;
  name!: string;
  description!: string;
  type!: number;
  status!: WFStepLocalStatus;
  start!: boolean;
  end!: boolean;
  order!: number;
  code!: string;
  collapsible?: boolean;
  collapsed?: boolean;
  avoidUnapprove!: boolean;
  wfSectionLocalID!: number;
  wfSectionLocal!: WFSectionLocalResource;
  wfTaskLocals!: WfTaskLocalResource[];
  wfStepStatusLocals!: WFStepStatusLocal[];
  createdBy!: User;
  createdOn!: Date;
  updatedBy!: User;
  updatedOn!: Date;
  wfSteps!: WFStep[];
  wfStepConfig!: WFStepConfig;
  configuration?: string;

  // Checklist Builder Undo Feature Properties
  logID?: number;
  checklistTemplateID?: number;
}

export class WFStep {
  id!: number;
  name!: string;
  start!: boolean;
  end!: boolean;
  wfTableID!: number;
  active!: boolean;
  wfStepLocalID!: number;
  status!: FormStatusEnum;
}

export interface WFStepStatusLocal {
  id: number;
  wfStepLocalID: number;
  status?: FormStatusEnum;
  checklistExecStatus?: ChecklistExecStatus;
  hidden: boolean;
  enabled: boolean;
  cssClass: string;
}

export class SaveTaskResource {
  id!: number;
  value?: boolean;
  numericValue?: number;
  text?: string | null;
  doubleValue?: number | null;
  field?: string;
  formType?: number;
  option?: number;
  resourceId?: number;
  answerChangedById?: number | null;
  disabled?: boolean;
  booleanValue?: boolean;
  isCompleted?: boolean;
  pendingChange?: boolean;
}

export class WfTaskResource {
  id?: number;
  name?: string;
  required?: boolean;
  order?: number;
  enabled?: boolean;
  signedOn?: Date;
  signedBy!: sign | null;
}

export class WFTaskSignatureResource {
  taskId?: number;
  actionId?: number;
  reason?: string | null;
  formID?: number;
  amendment?: boolean;
  options?: string;
}

export class WFConfiguration {
  ID?: number;
  form?: number;
  identificator?: number;
  wfSectionLocalID?: number;
  wfTaskLocalID?: number;
  wfTableID?: number;
}

export class WfSignatureResource {
  amendmentID!: number;
  order?: number;
  id!: number;
  code?: string;
  condition?: string;
  taskID!: number;
  titleBefore!: string;
  titleAfter!: string;
  titleAfter2!: string;
  enabled?: boolean;
  required?: boolean;
  visible?: boolean;
  question!: string;
  signed!: boolean;
  signedBy?: User | null;
  signedOn!: Date | null;
  roles!: Role[];
  users!: User[];
  approveID!: number;
  unapproveID?: number;
  disapproveID?: number;
  cancelID?: number;
  approveEnabled!: boolean;
  unapproveEnabled!: boolean;
  disapproveEnabled!: boolean;
  disapproveReason!: string;
  amendmentEnabled!: boolean;
  cancelEnabled!: boolean;
  value!: boolean;
  text!: string;
  type!: number;
  options!: string;
  numericValue?: number;
  doubleValue!: number | null;
  wfTaskConfigID?: number;
  wfTaskLocal!: WfTaskLocalResource;
  sectionNumber!: number;
  sectionName!: string;
  iterable!: boolean;
  status!: number;
  warning!: boolean;
  moreInfo?: string | null;
  disabled?: boolean;
  amendmentNumber?: number;
  resourceId?: number;
  scheduleResourceId?: number;
  scheduleType?: number;
  dateValue1?: Date;
  dateValue2?: Date;
  dateValue3?: Date;
  scheduleName?: string;
  locationName?: string;
  privilegeToApproveAnswer?: PrivilegeEnum;
  answerChangedBy?: User;
  answerChangedById?: number;
  requesterSignature?: boolean;
  component?: WFComponent;
  configuration?: string;
  extraActions!: ExtraAction[];
  followUpChanged?: boolean;
  lastFollowUpChanged?: string;
  lastFollowUpCheckChanged?: string;
  holdPointID?: number;
  isCompleted?: boolean;
  pendingChange?: boolean;
  pendingChangeObj?: string;

  public static toWFSignatureResource(signature: WfSignatureResource, showRoleCodes: boolean = true): WfSignature {
    return {
      id: signature.id,
      approveId: signature.approveID,
      unapproveId: signature.unapproveID,
      disapproveId: signature.disapproveID,
      amendmentId: signature.amendmentID,
      name: signature.titleBefore,
      question: signature.question,
      roles: signature.roles.map(r => r.id as number),
      roleCodes: showRoleCodes ? signature.roles.map(r => r.code).join('/') : signature.roles.map(r => r.name).join('/'),
      userNames: signature.users.map(user => user.name).join('/'),
      disabled: !(signature.approveEnabled || signature.unapproveEnabled),
      approvedBy: signature.signedBy,
      approved: signature.signed,
      approvedOn: signature.signedOn,
      approve: signature.approveEnabled,
      unapprove: signature.unapproveEnabled,
      disapprove: signature.disapproveEnabled,
      amendment: signature.amendmentEnabled,
      required: signature.required,
      taskId: signature.taskID,
      value: signature.value,
      cancelID: signature.cancelID,
      cancel: signature.cancelEnabled,
      enabled: signature.enabled,
      text: signature.text,
      warning: false,
      options: utils.JSONparse(signature.options),
      titleAfter: signature.titleAfter,
      followUpChanged: signature.followUpChanged,
      lastFollowUpChanged: signature.lastFollowUpChanged,
      lastFollowUpCheckChanged: signature.lastFollowUpCheckChanged,
      isCompleted: signature.isCompleted,
      type: !signature.type ? ComponentType.ButtonSignature : signature.type,
      visible: true,
    };
  }

  public static toWFSignatureResourceWithPerson(signature: WfSignatureResource, signatures: WfSignatureResource[], leftPersons?: WFConfiguration[][], rightPersons?: WFConfiguration[][], userID?: number): WfSignature {
    let previousSigned = false;
    if (leftPersons?.length && rightPersons?.length)
      for (let j = 0; j < leftPersons.length; j++) {
        if (leftPersons[j].some(x => x.wfTaskLocalID === signature.id)) {
          for (let i = 0; i < rightPersons[j].length; i++) {
            if (signatures.find(x => x.id != signature.id && x.id === rightPersons[j][i].wfTaskLocalID && x.amendmentID === signature.amendmentID)?.signedBy?.id === userID) {
              previousSigned = true;
              break;
            }
          }
        } else if (rightPersons[j].some(x => x.wfTaskLocalID === signature.id)) {
          for (let i = 0; i < leftPersons[j].length; i++) {

            if (signatures.find(x => x.id != signature.id && x.id === leftPersons[j][i].wfTaskLocalID && x.amendmentID === signature.amendmentID)?.signedBy?.id === userID) {
              previousSigned = true;
              break;
            }
          }
        }

        if (previousSigned) {
          break;
        }
      }

    return {
      id: signature.id,
      visible: true,
      approveId: signature.approveID,
      unapproveId: signature.unapproveID,
      disapproveId: signature.disapproveID,
      amendmentId: signature.amendmentID,
      amendmentNumber: signature.amendmentNumber,
      name: signature.titleBefore,
      question: signature.question,
      roles: signature.roles.map(r => r.id as number),
      roleCodes: signature.roles.map(r => r.code).join('/'),
      userNames: signature.users.map(user => user.name).join('/'),
      disabled: !(signature.approveEnabled || signature.unapproveEnabled || signature.disapproveEnabled) || previousSigned,
      approvedBy: signature.signedBy,
      approved: signature.signed,
      approvedOn: signature.signedOn,
      approve: signature.approveEnabled,
      unapprove: signature.unapproveEnabled,
      disapprove: signature.disapproveEnabled,
      amendment: signature.amendmentEnabled,
      required: signature.required,
      taskId: signature.taskID,
      value: signature.value,
      warning: false,
      options: utils.JSONparse(signature.options),
      cancelID: signature.cancelID,
      cancel: signature.cancelEnabled,
      enabled: signature.enabled,
      followUpChanged: signature.followUpChanged,
      lastFollowUpChanged: signature.lastFollowUpChanged,
      lastFollowUpCheckChanged: signature.lastFollowUpCheckChanged,
      type: !signature.type ? ComponentType.ButtonSignature : signature.type
    };
  }
}

export class WfSignature {
  id?: number;
  number?: string | null;
  taskId?: number;
  name?: string | null;
  disabled?: boolean;
  disableEdit?: boolean;
  required?: boolean;
  visible?: boolean;
  question?: string | null;
  question2?: string | null;
  question3?: string | null;
  approved?: boolean;
  approvedBy?: User | null;
  approvedOn?: Date | null;
  roles?: number[] | null;
  roleCodes?: string;
  userNames?: string;
  approveId?: number;
  unapproveId?: number;
  disapproveId?: number;
  amendmentId?: number;
  amendmentNumber?: number;
  approve?: boolean;
  unapprove?: boolean;
  disapprove?: boolean;
  amendment?: boolean;
  value?: boolean;
  value2?: number;
  textValue?: string | null;
  type?: number;
  options?: any[];
  sectionName?: string;
  sectionNumber?: number;
  iterable?: boolean;
  order?: number;
  cancelID?: number;
  cancel?: boolean;
  isVacuum?: boolean;
  warning?: boolean;
  moreInfo?: string;
  resourceId?: number;
  scheduleResourceId?: number;
  scheduleTypeId?: number;
  dateValue1?: Date;
  dateValue2?: Date;
  dateValue3?: Date | null;
  scheduleName?: string;
  locationName?: string | null;
  editMode?: boolean;
  condition?: string;
  code?: string;
  enabled?: boolean;
  component?: WFComponent;
  configuration?: string;
  requesterSignature?: boolean;
  privilegeToApproveAnswer?: PrivilegeEnum;
  answerChangedBy?: User;
  answerChangedById?: number;
  text?: string;
  titleAfter?: string;
  status?: number;
  stepId?: number;
  followUpChanged?: boolean;
  lastFollowUpChanged?: string;
  lastFollowUpCheckChanged?: string;
  booleanValue?: boolean;
  holdPointID?: number;
  isCompleted?: boolean;
  wfTaskLocal?: WfTaskLocalResource;
  cssClass?: string;
  pendingChange?: boolean;
  pendingChangeObj?: string;

  invalid?: boolean;
  errors?: string;
}

class sign {
  id?: number | null;
  name?: string | null;
  initials?: string | null;
}

export class WFRoleLocal {
  id!: number;
  role?: role;
  roleID!: number;
  roleCode?: string;
  wfTaskLocalID!: number;
  wfRoleConfigID?: number;
}
// tslint:disable-next-line: class-name
class role {
  id!: number;
  name!: string;
  code!: string;
}

export class ExtraAction {
  public id!: number;
  public taskID!: number;
  public name!: string;
}

export enum WFStepLocalStatus {
  NONE = 0,
  DUPLICATED = 1
}

export enum WFSectionLocalStatus {
  NONE = 0,
  DUPLICATED = 1
}

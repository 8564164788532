import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { ChecklistBuilderSettingsStatusComponent } from "./checklist-builder-settings-status/checklist-builder-settings-status.component";
import { Observable, Subscription } from "rxjs";
import { Component, OnInit, OnChanges, OnDestroy, SimpleChanges, Input, Injector, EventEmitter, Output } from "@angular/core";
import { YesNoDialogComponent } from "src/app/controls/yes-no-dialog/yes-no-dialog.component";
import { ChecklistExecStatus } from "../../../checklists";
import { ChecklistBuilderService } from "../../checklist-builder.service";
import { DocumentType } from "src/app/services/documents/documents";
import { BaseComponent } from "src/app/common/base/base.component";
import { DocumentTypeRefresh } from "src/app/components/catalogs/document-type/store/document-type/document-type.action";

@Component({
  selector: "checklist-builder-settings-statuses",
  templateUrl: "./checklist-builder-settings-statuses.component.html",
  styleUrls: ["./checklist-builder-settings-statuses.component.scss"],
})
export class ChecklistBuilderSettingsStatusesComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {
  @Input() documentType!: DocumentType;
  @Output() loading = new EventEmitter<boolean>();

  statuses!: ChecklistExecStatus[];

  documentTypes!: DocumentType[];
  documentTypes$!: Observable<DocumentType[]>;
  documentTypeSubs!: Subscription;

  saving!: boolean;

  constructor(
    protected override injector: Injector,
    private _srv: ChecklistBuilderService,
  ) {
    super(injector);
  }

  override ngOnDestroy(): void {
    this.documentTypeSubs?.unsubscribe();
    super.ngOnDestroy();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.loading.emit(true);
    this.statuses = this.documentType?.checklistExecStatuses?.sort((a, b) => this.utils.sort(a.order, b.order)) ?? [];
  }

  ngOnInit(): void {
    this.documentTypes$ = this.store.select((state) => state.DocumentType.data);
    this.documentTypeSubs = this.documentTypes$.subscribe((data) => {
      this.documentTypes = data;
      if (this.documentType) {
        const documentType = this.documentTypes.find((x) => x.id == this.documentType.id);
        if (documentType) this.documentType = documentType;
        this.statuses = this.documentType?.checklistExecStatuses?.sort((a, b) => this.utils.sort(a.order, b.order)) ?? [];
        this.loading.emit(false);
      }
    });
  }

  edit(status: ChecklistExecStatus) {
    const confirm = this.dialog.open(ChecklistBuilderSettingsStatusComponent, {
      width: "400px",
      data: {
        title: "Modify Status",
        documentType: this.documentType,
        status,
        disableClose: true,
        autoFocus: false,
      },
    });
    confirm.afterClosed().toPromise().then((data) => {
      this._srv.updateChecklistExecStatus(data.status).toPromise().then(() => {
        this.alert.message("ReviewStatusUpdated");
        this.store.dispatch(new DocumentTypeRefresh());
      });
    });
  }

  delete(status: ChecklistExecStatus) {
    const confirm = this.dialog.open(YesNoDialogComponent, {
      width: "400px",
      data: {
        message: this.getMessage("ReviewStatusDeleteConfirmation").description,
        icon: "warn",
      },
    });
    confirm.afterClosed().toPromise().then((data) => {
      if (data) {
        this._srv.deleteChecklistExecStatus(status).toPromise().then(() => {
          this.alert.message("ReviewStatusDeleted");
          this.store.dispatch(new DocumentTypeRefresh());
        });
      }
    });
  }

  add() {
    const confirm = this.dialog.open(ChecklistBuilderSettingsStatusComponent, {
      width: "400px",
      data: {
        title: "Add Status",
        documentType: this.documentType,
        disableClose: true,
        autoFocus: false,
      },
    });
    confirm.afterClosed().toPromise().then((data) => {
      const status: ChecklistExecStatus = data.status;
      status.id = 0;
      status.documentTypeID = this.documentType.id;

      this._srv.createChecklistExecStatus(status).toPromise().then((res) => {
        if (res) {
          this.statuses.push(res);
          this.alert.message("ReviewStatusCreated");
          this.store.dispatch(new DocumentTypeRefresh());
        }
      });
    });
  }

  drop(event: CdkDragDrop<ChecklistExecStatus[]>) {
    this.saving = true;
    if (event?.previousIndex !== event?.currentIndex) {
      moveItemInArray(this.statuses, event.previousIndex, event.currentIndex);
      this.renumberArray().then(() => {
        this.saving = false;
      });
    }
  }

  renumberArray() {
    return new Promise((resolve) => {
      let order = 1;
      this.statuses.map((s) => {
        s.order = order;
        order++;
        this._srv.updateChecklistExecStatus(s).subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            console.log(error);
          }
        );
      });
    });
  }

  getClass(color: string) {
    return color + "-background";
  }
}

import { ActionItem } from "src/app/common/models/action-item";

export class ActionItemsRefresh {
  static readonly type = '[ActionItems] Refresh';
  constructor(public AbsiId: number) { }
}

export class ActionItemsCreate {
  static readonly type = '[ActionItems] Create';

  constructor(public item: ActionItem) { }
}

export class ActionItemsUpdate {
  static readonly type = '[ActionItems] Update';

  constructor(public id: number, public item: ActionItem) { }
}

export class ActionItemsDelete {
  static readonly type = '[ActionItems] Delete';
  constructor(public id: number) { }
}

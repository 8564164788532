import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { AbsiResource, AbsiStatusResource, SaveAbsiStatusResource, AbsiTemplateTypeResource } from './absi';
import { BaseService } from 'src/app/common/base/base.service';
import { FormType, FormStatusEnum } from 'src/app/common/enumerations/enumerations';
import { OlogDataBaseObject } from 'src/app/components/olog/olog';
import { SaveTaskResource, WFSectionLocalResource } from 'src/app/services/work-flow/work-flow';
import { Schedule } from '../schedules/models/schedule';

@Injectable()
export class AbsiService extends BaseService<AbsiResource, number> {

  SerialNumber = '/SerialNumbers/' + FormType.KE;
  Locations = '/Locations';

  Api = '/Absi';
  apiAbsiTemplateTypes = this.BASE_URL + '/AbsiTemplateType';

  constructor(
    protected override injector: Injector
  ) {
    super(injector, '/Absi');
  }

  // Gets the Serial Number of the Form
  getSerialNo(): Observable<SerialNumber> {
    const httpresult1 = this.http.get<SerialNumber>(this.BASE_URL + this.SerialNumber);
    return httpresult1;
  }

  getOpen(): Observable<AbsiResource[]> {
    const httpresult = this.http.get<AbsiResource[]>(this.BASE_URL + this.Api + '/Open');
    return httpresult;
  }

  // getAll(): Observable<AbsiResource[]> {
  //   const httpresult = this.http.get<AbsiResource[]>(this.BASE_URL + this.Api);
  //   return httpresult;
  // }

  // getBySerialNo(sn: string): Observable<AbsiResource> {
  //   const httpresult = this.http.get<AbsiResource>(this.BASE_URL + this.Api + '/SerialNo/' + sn);
  //   return httpresult;
  // }

  getStatusComplete(wfTableID: number, formStatus: FormStatusEnum): Observable<AbsiStatusResource> {
    const httpresult = this.http.get<AbsiStatusResource>(this.BASE_URL + this.Api + '/StatusComplete/' + wfTableID + '/' + formStatus);
    return httpresult;
  }

  // getById(id: number): Observable<AbsiResource> {
  //   const url = this.BASE_URL + this.Api + '/' + id;
  //   const httpresult = this.http.get<AbsiResource>(url);
  //   return httpresult;
  // }

  getOlog(serialNo: string, schedule: Schedule): Observable<OlogDataBaseObject> {
    const httpresult = this.http.put<OlogDataBaseObject>(this.BASE_URL + this.Api + '/GetOlog/' + serialNo, schedule);
    return httpresult;
  }

  getFirstSignatureDate(wfTableId: number): Observable<Date> {
    const httpresult = this.http.get<Date>(this.BASE_URL + this.Api + '/FirstSignatureDate/' + wfTableId);
    return httpresult;
  }

  // post(schedule: Schedule) {
  //   return this.http.post<AbsiResource>(this.BASE_URL + this.Api, schedule);
  // }

  setStatus(saveAbsiStatus: SaveAbsiStatusResource, refresh: boolean) {
    return this.http.put<AbsiResource>(this.BASE_URL + this.Api + `/SetStatus/${saveAbsiStatus.id}/${refresh}`, saveAbsiStatus);
  }

  // put(saveKE: SaveAbsiResource) {
  //   return this.http.put<AbsiResource>(this.BASE_URL + this.Api + '/' + saveKE.id, saveKE);
  // }

  /////

  public sign(taskId: number, actionId: number, status?: FormStatusEnum) {
    let url: string = this.BASE_URL + this.Api + '/Sign?task=' + taskId + '&action=' + actionId;
    if (status) url += '&status=' + status;
    const httpresult = this.http.put<any>(url, null);
    return httpresult;
  }

  public readWFBySection(id: number, sectionNumber: number) {
    const httpresult = this.http.get<any[]>(this.BASE_URL + this.Api + '/WFBySection/' + id + '?SectionNumber=' + sectionNumber);
    return httpresult;
  }

  public saveWFTask(s: SaveTaskResource) {
    const httpresult = this.http.put<any>(this.BASE_URL + this.Api + '/Save/' + s.id, s);
    return httpresult;
  }

  public readAllSections(wfTableId: number): Observable<WFSectionLocalResource[]> {
    const httpresult = this.http.get<WFSectionLocalResource[]>(this.BASE_URL + this.Api + '/WFSectionLocals/' + wfTableId);
    return httpresult;
  }

  public readBySection(wfTableId: number, sectionId: number): Observable<WFSectionLocalResource> {
    const httpresult = this.http.get<WFSectionLocalResource>(this.BASE_URL + this.Api + '/WFSectionLocal/' + wfTableId + '/' + sectionId);
    return httpresult;
  }

  //#region AbsiTemplateType

  public getAllAbsiTemplateTypes(): Observable<AbsiTemplateTypeResource[]> {
    return this.http.get<AbsiTemplateTypeResource[]>(this.apiAbsiTemplateTypes);
  }

  public getAbsiTemplateTypeById(absiTemplateTypeId: number): Observable<AbsiTemplateTypeResource> {
    return this.http.get<AbsiTemplateTypeResource>(this.apiAbsiTemplateTypes + '/' + absiTemplateTypeId);
  }

  public createAbsiTemplateType(absiTemplateType: AbsiTemplateTypeResource): Observable<AbsiTemplateTypeResource> {
    return this.http.post<AbsiTemplateTypeResource>(this.apiAbsiTemplateTypes, absiTemplateType);
  }

  public updateAbsiTemplateType(absiTemplateTypeId: number, absiTemplateType: AbsiTemplateTypeResource): Observable<AbsiTemplateTypeResource> {
    return this.http.put<AbsiTemplateTypeResource>(this.apiAbsiTemplateTypes + '/' + absiTemplateTypeId, absiTemplateType);
  }

  public deleteAbsiTemplateType(absiTemplateTypeId: number, deleteReason: string): Observable<AbsiTemplateTypeResource> {
    return this.http.delete<AbsiTemplateTypeResource>(this.apiAbsiTemplateTypes + '/' + absiTemplateTypeId + '/' + deleteReason);
  }

  public refreshRnC(wfTableID: number) {
    return this.http.patch(this.BASE_URL + this.Api + '/RefreshRnC/' + wfTableID, null);
  }

  //#endregion
}

class SerialNumber {
  serialNo!: string;
}

import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { Component, EventEmitter, Injector, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, } from "@angular/core";
import { MatDialogConfig } from "@angular/material/dialog";
import { MatTableDataSource, MatTableDataSourcePaginator } from "@angular/material/table";
import { Observable, Subscription } from "rxjs";
import { BaseComponent } from "src/app/common/base/base.component";
import { ResourceType } from "src/app/common/enumerations/enumerations";
import { Resource, ResourceRelation } from "src/app/components/catalogs/beamline-catalog/resource/resources";
import { AcceleratorShieldingDialogComponent } from "./accelerator-shielding-dialog/accelerator-shielding-dialog.component";
import { MessagePlaceholder } from "src/app/common/models/placeholder";

@Component({
  selector: "accelerator-shielding",
  templateUrl: "./accelerator-shielding.component.html",
  styleUrls: ["./accelerator-shielding.component.scss"],
})
export class AcceleratorShieldingComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {
  @Input() accelerator!: Resource;
  @Input() isAdding: boolean = false;
  @Input() isEditing: boolean = false;
  @Input() showPrev!: boolean;
  @Input() shieldings!: Shielding[];
  @Input() enableMove!: boolean;

  @Output() changed = new EventEmitter<Shielding[]>();

  dataSource: MatTableDataSource<Shielding> = new MatTableDataSource();
  displayedColumns!: string[];

  resources!: Resource[];
  resourcesFiltered!: Resource[];
  resources$!: Observable<Resource[]>;
  resourcesSubs!: Subscription;

  resourceRelations!: ResourceRelation[];
  resourceRelationsFiltered!: ResourceRelation[];
  resourceRelations$!: Observable<ResourceRelation[]>;
  resourceRelationsSubs!: Subscription;

  constructor(
    protected override injector: Injector,
  ) {
    super(injector);
  }

  ngOnInit() { }

  ngOnChanges(changes: SimpleChanges): void {
    this.loadResources();
    this.displayedColumns =
      this.isEditing || this.isAdding
        ? [
          "position",
          "shieldingName",
          "keText",
          "shutdown",
          "checked",
          "tunnel",
          "subLocation",
          "sharedWith",
          "isRSSD",
          "add",
        ]
        : [
          "position",
          "shieldingName",
          "keText",
          "shutdown",
          "checked",
          "tunnel",
          "subLocation",
          "sharedWith",
          "isRSSD"
        ];
  }

  override ngOnDestroy(): void {
    this.resourcesSubs?.unsubscribe();
    this.resourceRelationsSubs?.unsubscribe();
    super.ngOnDestroy();
  }

  loadResources() {
    this.resources$ = this.store.select((state) => state.Resources.data);
    this.resourcesSubs = this.resources$.subscribe((data) => {
      if (data.length) {
        this.resources = data;
        this.loadResourceRelations();
      }
    });
  }

  loadResourceRelations() {
    this.resourceRelations$ = this.store.select(
      (state) => state.ResourceRelations.data
    );
    this.resourceRelationsSubs = this.resourceRelations$.subscribe((data) => {
      if (data.length) {
        this.resourceRelations = data;
        this.createTableData();
      }
    });
  }

  createTableData() {
    if (!this.shieldings) {
      this.shieldings = [];
    }
    this.shieldings.map((s) => {
      s.childResources?.map((l) => {
        l.childResource = this.resources.find((x) => x.id == l.childResourceID);
        l.childResourceName = l.childResource?.name;
      });
      s.subLocationNames = s.subLocationNames
        ? s.subLocationNames
        : this.resources
          .filter((r) =>
            (
              this.utils.JSONparse(
                this.accelerator.childResources?.find(
                  (x) => x.childResourceID == s.resourceID
                )?.locationsIDs
              ) as number[]
            )?.includes(r.id)
          )
          .map((r) => r.name)
          .join(",  ");
      const sharedWithLocations = this.getSharedWith(s.resource);
      const sharedWithParents = this.resourceRelations
        .filter(
          (x) =>
            x.childResourceID == s.resourceID &&
            x.parentResourceType != ResourceType.Accelerator
        )
        .map((x) => x.parentResourceName)
        .join(", ");
      s.sharedWithNames =
        sharedWithLocations +
        (sharedWithParents && sharedWithLocations ? ", " : "") +
        sharedWithParents;
    });
    this.dataSource = new MatTableDataSource(
      this.ordererShieldings(this.shieldings)
    );
  }

  getSharedWith(s?: Resource) {
    let sharedWith = '';
    if (s) {
      let rr = this.resourceRelations.filter(
        (x) =>
          x.childResourceID == s.id && x.parentResourceID != this.accelerator.id
      );
      const locationIDs: number[] = [];
      rr.map((r) => {
        if (r.locationsIDs) {
          const ls: number[] = JSON.parse(r.locationsIDs);
          if (ls?.length) {
            ls.map((id) => {
              if (!locationIDs.includes(id)) {
                locationIDs.push(id);
              }
            });
          }
        }
      });
      sharedWith = this.resources
        .filter((x) => locationIDs.includes(x.id))
        .map((x) => x.name)
        .join(", ");
    }
    return sharedWith;
  }

  getSubLocations(shielding: Resource) {
    return this.resourceRelations.filter(
      (x) =>
        x.parentResourceID == shielding.id &&
        x.childResourceType == ResourceType.AcceleratorLocation
    );
  }

  reorderTable(
    event: CdkDragDrop<MatTableDataSource<Shielding, MatTableDataSourcePaginator>, any, any>,
    dataSource: MatTableDataSource<any>
  ) {
    if (this.isAdding || this.isEditing) {
      moveItemInArray(dataSource.data, event.previousIndex, event.currentIndex);
      this.reorderPositions(dataSource.data);
      dataSource.filter = "";
      this.changed.emit(this.shieldings);
    }
  }

  reorderPositions(resources: Resource[]) {
    let addPosition = 1;
    resources.map((element) => {
      element.position = addPosition++;
    });
  }

  openAddShieldingDialog() {
    const dialogConfig = new MatDialogConfig();
    const shielding = new Shielding();
    shielding.checkboxVisible = true;
    dialogConfig.data = {
      shielding,
      accelerator: this.accelerator,
      isAdding: true,
    };
    dialogConfig.width = "450px";
    dialogConfig.autoFocus = true;
    const dialogRef = this.dialog.open(
      AcceleratorShieldingDialogComponent,
      dialogConfig
    );
    dialogRef.afterClosed().subscribe((shielding: Shielding) => {
      if (shielding) {
        shielding.subLocationNames = shielding.childResources?.map((s) => s.childResourceName).join(", ");
        shielding.id = this.getID();
        this.shieldings.push(shielding);
        this.createTableData();
        this.changed.emit(this.shieldings);
      }
    });
  }

  getID(ID: number = -1): number {
    if (!this.shieldings.map((s) => s.id).includes(ID)) {
      return ID;
    } else {
      ID--;
      return this.getID(ID);
    }
  }

  openEditShieldingDialog(shielding: Resource) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      shielding,
      accelerator: this.accelerator,
      title: "Edit Shielding",
      isAdding: false,
    };
    dialogConfig.width = "450px";
    dialogConfig.autoFocus = false;
    const dialogRef = this.dialog.open(
      AcceleratorShieldingDialogComponent,
      dialogConfig
    );
    dialogRef.afterClosed().subscribe((shielding: Shielding) => {
      if (shielding) {
        const index = this.shieldings.findIndex(
          (x) =>
            x.resourceID == shielding.resourceID &&
            x.position == shielding.position
        );
        this.shieldings.splice(index, 1);
        shielding.subLocationNames = shielding.childResources?.map((s) => s.childResource).map((r) => r?.name).join(", ");
        this.shieldings.push(shielding);
        this.dataSource = new MatTableDataSource(
          this.ordererShieldings(this.shieldings)
        );
        this.changed.emit(this.shieldings);
      }
    });
  }

  isResourceRepeated(
    resources: Resource[],
    resource: Resource,
    typeName: string
  ) {
    if (
      resources.filter(
        (x) => x.name?.toLowerCase() === resource.name?.toLowerCase()
      ).length
    ) {
      this.alert.message("resource_Added", [
        new MessagePlaceholder("{resourceTypeName}", typeName),
        new MessagePlaceholder("{resourceName}", resource.name?.toUpperCase()),
      ]);
      return false;
    }
    return true;
  }

  ordererShieldings(shieldings: Shielding[]) {
    let position = 1;
    const older = shieldings
      .filter((x) => x.position)
      .sort((a, b) => this.utils.sort(a.position, b.position));
    const newer = shieldings.filter((x) => !x.position).sort((a, b) => this.utils.sort(Math.abs(a.id ?? 0), Math.abs(b.id ?? 0)));
    shieldings = older.concat(newer);
    shieldings.map((s) => {
      s.position = position;
      position++;
    });
    return shieldings;
  }

  deleteShielding(s: Shielding) {
    const index = this.shieldings.findIndex(
      (x) => x.resourceID == s.resourceID && x.position == s.position
    );
    this.shieldings.splice(index, 1);
    this.dataSource = new MatTableDataSource(
      this.ordererShieldings(this.shieldings)
    );
    this.changed.emit(this.shieldings);
  }

  checkedChange(s: Shielding) {
    if (!s.checkboxVisible) {
      s.longShutdown = false;
    }
    this.changed.emit(this.shieldings);
  }

  someChange(s: Shielding) {
    this.changed.emit(this.shieldings);
  }

  longChanged(s: Shielding) {
    if (s.longShutdown) {
      s.checkboxVisible = true;
    }
    this.changed.emit(this.shieldings);
  }

  // getClass(color: string) {
  //   return color;
  // }
}

export class Shielding {
  id?: number;
  name?: string | null;
  resourceID!: number;
  resource?: Resource;
  relatedShielding?: boolean;
  shared?: boolean;
  position?: number;
  childResources?: ResourceRelation[];
  subLocationNames?: string;
  sharedWith?: Resource[];
  sharedWithNames?: string;
  checkboxVisible?: boolean;
  longShutdown?: boolean;
  inTunnel?: boolean;
  isRSSD?: boolean;
  isModified?: boolean;
  // cssClass?: string;
  constructor() {
    this.checkboxVisible = true;
    this.inTunnel = true;
  }
}

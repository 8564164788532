import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldDefaultOptions, MatFormFieldModule, MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatTableModule } from '@angular/material/table';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AppComponent } from 'src/app/app.component';
import { MatButtonModule } from '@angular/material/button';
import { BrowserModule } from '@angular/platform-browser';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatMenuModule } from '@angular/material/menu';
import { MatDialogModule } from '@angular/material/dialog';
import { HttpClientModule } from '@angular/common/http';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule, MatOptionModule } from '@angular/material/core';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatListModule } from '@angular/material/list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatCardModule } from '@angular/material/card';
import { MatRadioModule } from '@angular/material/radio';
import { MatFileUploadModule } from 'src/app/modules/file-upload/file-upload-module';
import { MatStepperModule } from '@angular/material/stepper';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatTreeModule } from '@angular/material/tree';
import { MatExpansionModule } from '@angular/material/expansion';
import { ScheduleModule } from '../schedules/schedule.module';
import { CommonModule } from '@angular/common';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { httpInterceptorProviders } from 'src/app/interceptors/interceptorProviders';
import { SharedModule } from 'src/app/modules/shared.module';
import { ProcedureTrainingService } from 'src/app/components/procedure/services/procedure-training.service';
import { ProcedureService } from 'src/app/components/procedure/services/procedure.service';
import { ProcedureAllV2Component } from './procedure-all-v2/procedure-all-v2.component';
import { ProcedureTableComponent } from './procedure-all-v2/procedure-table/procedure-table.component';
import { ProcedureReportsComponent } from './procedure-reports/procedure-reports/procedure-reports.component';
import { MatTableExporterModule } from 'mat-table-exporter';
import { ProcedurePopupComponent } from './dialogs/procedure-popup/procedure-popup.component';
import { ProcedureCategoryPopupComponent } from './dialogs/procedure-category-popup/procedure-category-popup.component';
import { ProcedureUpdateV2Component } from './procedure-update-v2/procedure-update-v2.component';
import { ProcedureNotePopupComponent } from './dialogs/procedure-note-popup/procedure-note-popup.component';
import { ProcedureUpdateTableComponent } from './procedure-update-v2/procedure-update-table/procedure-update-table.component';
import { ExtraProcedureTrainingPopupComponent } from './procedure-training-v2/extra-procedure-training-popup/extra-procedure-training-popup.component';
import { ProcedureTrainingV2Component } from './procedure-training-v2/procedure-training-v2.component';
import { ProcedureTrainingsTableComponent } from './procedure-training-v2/procedure-trainings-table/procedure-trainings-table.component';
import { CreditedDatePopupComponent } from './procedure-training-v2/credited-date-popup/credited-date-popup.component';
import { ProcedureTrainingsAppendicesComponent } from './procedure-training-v2/procedure-trainings-table/procedure-trainings-appendices/procedure-trainings-appendices.component';

const appRoutes: Routes = [
  { path: 'procedure-update', component: ProcedureUpdateV2Component },
  { path: 'procedure-all', component: ProcedureAllV2Component },
  { path: 'procedure-training', component: ProcedureTrainingV2Component },
  // { path: 'procedure-template', component: ProcedureTemplateComponent },
  { path: 'procedure-report', component: ProcedureReportsComponent },
];

const appearance: MatFormFieldDefaultOptions = {
  appearance: 'outline'
};

@NgModule({
  declarations: [
    ProcedureCategoryPopupComponent,
    ProcedurePopupComponent,
    ProcedureNotePopupComponent,
    // ProcedureTemplateComponent,
    ProcedureUpdateTableComponent,
    ProcedureTableComponent,
    ProcedureTrainingsTableComponent,
    ExtraProcedureTrainingPopupComponent,
    CreditedDatePopupComponent,
    ProcedureUpdateV2Component,
    ProcedureAllV2Component,
    ProcedureTrainingV2Component,
    // ProcedureTrainingComponent,
    ProcedureReportsComponent,
    ProcedureTrainingsAppendicesComponent
  ],

  imports: [
    BrowserModule,
    NgbModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatMenuModule,
    MatButtonModule,
    MatDialogModule,
    HttpClientModule,
    ScrollingModule,
    MatSelectModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatChipsModule,
    MatIconModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatListModule,
    MatProgressSpinnerModule,
    MatCardModule,
    MatRadioModule,
    MatFileUploadModule,
    MatStepperModule,
    MatSlideToggleModule,
    MatTabsModule,
    MatTooltipModule,
    MatGridListModule,
    MatProgressBarModule,
    DragDropModule,
    MatTreeModule,
    MatExpansionModule,
    ScheduleModule,
    MatSidenavModule,
    SharedModule,
    MatToolbarModule,
    MatTableExporterModule,
    CommonModule,
    MatOptionModule,
    RouterModule.forRoot(
      appRoutes,
      {
        enableTracing: true,
        useHash: true,
        anchorScrolling: 'enabled',
        preloadingStrategy: PreloadAllModules
      }
    ),
    FormsModule,
    NgxsReduxDevtoolsPluginModule.forRoot(),
  ],
  exports: [
    RouterModule,
    SharedModule,
  ],
  providers: [
    // ProcedureListener,
    // ProcedureCategoryService,
    ProcedureService,
    ProcedureTrainingService,
    // ProcedureCreditedService,
    // ProcedureTemplateService,
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: appearance
    },
    httpInterceptorProviders,
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ProcedureModule { }

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PV, PVInfo } from './pv-info';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PVInfoService {

  BASE_URL = environment.urls.baseApiUrl; // Api URL
  Api = '/PVInfo';

  constructor(private http: HttpClient) { }

  getPVInfo(channel: any): Observable<PVInfo> {
    const httpresult = this.http.get<PVInfo>(this.BASE_URL + this.Api + '/' + channel);
    return httpresult;
  }

  subscribe(channel: string) {
    const pv = { pvName: channel } as PV;
    const httpresult = this.http.patch<PV>(this.BASE_URL + this.Api + '/SubscribePV', pv);
    return httpresult;
  }

  // getPVInfos(channels: PVData[]): Observable<PVData[]> {
  //   const httpresult = this.http.patch<PVData[]>(this.BASE_URL + this.Api, channels);
  //   return httpresult;
  // }

  getAll(): Observable<PV[]> {
    const httpresult = this.http.get<PV[]>(this.BASE_URL + this.Api);
    return httpresult;
  }

}

<div class="main-panel p-2">
  <div class="pt-2" *ngFor="let section of sections">
    <mat-accordion>
      <absi-section-wrapper
        id="{{ section.name }}"
        #sectionX
        [section]="section"
        [absi]="absi"
        (loading)="setLoading($event, section.number ?? 0)"
      ></absi-section-wrapper>
    </mat-accordion>
  </div>

  <absi-review-and-concurrence
    *ngIf="sectionInfo?.id"
    id="ReviewAndConcurrence"
    #section100
    [sectionInfo]="sectionInfo"
    [absi]="absi"
    (loading)="setLoading($event, 100)"
  ></absi-review-and-concurrence>

  <!-- <absi-main-panel-close
    [currentDocument]="currentDocument"
    *ngIf="currentDocument?.status == formStatus.Closed"
  ></absi-main-panel-close>
  <div
    class="pl-3 pr-3 pt-3"
    [ngClass]="{
      collapse: !currentDocument?.id,
      'hide-comments': isSectionLoading
    }"
  > -->

  <div class="mt-3 pl-3">
    <comments-and-attachments
      *ngIf="absi?.wfTable"
      [currentDocument]="absi"
      [headerClass]="'h5'"
    ></comments-and-attachments>
  </div>

  <!--
  </div>
</div> -->
  <div class="dummySpace"></div>
  <!--This is so that the main section does not show a blank space when the side panel is open-->
</div>

import { WFSectionLocalResource } from 'src/app/services/work-flow/work-flow';
import { ChecklistTemplate } from '../../checklists';
import { FormType } from 'src/app/common/enumerations/enumerations';

export class ChecklistTemplatesRefresh {
  static readonly type = '[ChecklistTemplates] Refresh';
}

export class ChecklistTemplatesRefreshById {
  static readonly type = '[ChecklistTemplates] RefreshById';
  constructor(public id: number) { }
}

export class ChecklistTemplatesRefreshByDocument {
  static readonly type = '[ChecklistTemplates] RefreshByDocument';
  constructor(public id: FormType) { }
}

export class ChecklistTemplatesCreate {
  static readonly type = '[ChecklistTemplates] Create';

  constructor(public item: ChecklistTemplate) { }
}

export class ChecklistTemplatesUpdate {
  static readonly type = '[ChecklistTemplates] Update';

  constructor(public id: number, public item: ChecklistTemplate) { }
}

export class ChecklistTemplatesDelete {
  static readonly type = '[ChecklistTemplates] Delete';
  constructor(public id: number) { }
}

export class ChecklistTemplatesUpdateSection {
  static readonly type = '[ChecklistTemplates] UpdateSection';
  constructor(public id: number, public item: WFSectionLocalResource) { }
}

export class ChecklistTemplatesRefreshSection {
  static readonly type = '[ChecklistTemplates] RefreshSection';
  constructor(public id: number) { }
}

<div class="card full-width" [ngClass]="{ 'card-deleted': section.logID }">
  <div class="card-header">
    <div class="row d-flex align-items-center">
      <div class="col d-flex nowrap">
        <div class="d-flex align-items-center">
          <h4 class="border0">{{ section.number }}.</h4>
          <h4
            *ngIf="!editing"
            [ngClass]="{
              'section-name': !disabled,
              'section-name-nohover': disabled
            }"
            (click)="edit()"
          >
            {{ section.name }}
          </h4>
          <h4 *ngIf="editing && !disabled" class="input-sizer stacked">
            <textarea
              aria-label="text"
              matInput
              onmouseover="this.parentNode.dataset.value = this.value"
              onfocus="this.parentNode.dataset.value = this.value"
              oninput="this.parentNode.dataset.value = this.value"
              rows="1"
              (change)="save()"
              [(ngModel)]="section.name"
              appTextareaAutoresize
              style="overflow: hidden; height: 100%"
            ></textarea>
          </h4>
        </div>
      </div>
      <div class="col d-flex align-items-center small">
        <mat-checkbox
          color="primary"
          [disabled]="disabled"
          [(ngModel)]="configuration.duplicate"
          (change)="configurationChanged()"
          >Allow Duplicates</mat-checkbox
        >
        <checklist-roles-chips
          *ngIf="configuration.duplicate"
          [configuration]="configuration"
          [disabled]="disabled"
          (configurationChanged)="configurationChanged()"
        ></checklist-roles-chips>
      </div>
      <div class="col d-flex justify-content-between align-items-center">
        <div class="d-flex flex-column">
          <mat-slide-toggle
            [(ngModel)]="section.collapsible"
            [disabled]="disabled"
            class="small"
            (change)="save()"
            >Collapsible Section</mat-slide-toggle
          >
          <mat-slide-toggle
            class="small"
            *ngIf="section.collapsible"
            [(ngModel)]="section.collapsed"
            [disabled]="disabled"
            (change)="save()"
            >Collapsed by default</mat-slide-toggle
          >
        </div>
        <div
          class="card-header-pills float-right"
          *ngIf="!editing && !disabled"
        >
          <div
            *ngIf="section.number && !disabled"
            class="btn btn-blue"
            (click)="duplicate()"
            matTooltip="Duplicate Section"
          >
            <mat-icon>content_copy</mat-icon>
          </div>
          <div
            *ngIf="section.number && !disabled"
            class="btn btn-red"
            (click)="delete()"
            matTooltip="Delete Section"
          >
            <mat-icon>delete</mat-icon>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="card-body">
    <checklist-builder-steps
      #steps
      [steps]="wfStepLocals ?? []"
      [wfSectionLocalID]="section.id"
      [isDeletedSection]="(section.logID ?? 0) > 0"
      [disabled]="disabled"
      [currentVersion]="currentVersion"
      [procedure]="procedure"
      [showDeleted]="showDeleted"
      (loading)="loading.emit($event)"
      [sectionDropLists]="sectionDropLists"
      [stepDropLists]="stepDropLists"
      (refreshPreviousStep)="refreshPreviousStep.emit($event)"
    ></checklist-builder-steps>
  </div>
</div>

import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Observable, Subscription } from 'rxjs';
import { PendingCatalogs, PendingCatalog, PendingCatalogType } from 'src/app/services/pending-approvals/pending-approval';
import { CatalogItem } from '../pending-approvals-catalogs/pending-approvals-catalogs.component';
import { PendingApprovalsV2Service } from '../pending-approvals-v2.service';

@Component({
  selector: 'pending-approvals-changes',
  templateUrl: './pending-approvals-changes.component.html',
  styleUrls: ['./pending-approvals-changes.component.scss']
})
export class PendingApprovalsChangesComponent implements OnInit, OnDestroy {

  @Output() updateMyChanges = new EventEmitter<CatalogItem[]>();

  pendingCatalogs!: PendingCatalogs;
  pendingCatalogs$!: Observable<PendingCatalogs>;
  pendingCatalogsSubs!: Subscription;

  pendingApprovals!: number;
  pendingCatalogType = PendingCatalogType;

  catalogsData: CatalogItem[] = [{ name: 'Accelerator Catalog', id: 'accCatalog' }, { name: 'Beamline Catalog', id: 'beamCatalog' }, { name: 'Users', id: 'users' }, { name: 'Roles', id: 'roles' }, { name: 'Notification Templates', id: 'notificationTemplates' }, { name: 'RSS Database', id: 'rssdb' }];

  public dataSourcePendingAccelerator = new MatTableDataSource<PendingCatalog>();
  public dataSourcePendingResource = new MatTableDataSource<PendingCatalog>;
  public dataSourcePendingUser = new MatTableDataSource<PendingCatalog>;
  public dataSourcePendingRole = new MatTableDataSource<PendingCatalog>;
  public dataSourcePendingNotificationTemplate = new MatTableDataSource<PendingCatalog>;
  public dataSourcePendingRSSDatabase = new MatTableDataSource<PendingCatalog>;

  constructor(
    private service: PendingApprovalsV2Service,
  ) {
    this.service.pendingCatalogsSubject.subscribe(data => {
      this.loadMyPendingChanges();
    });
  }

  ngOnDestroy(): void {
    this.pendingCatalogsSubs?.unsubscribe();
  }

  ngOnInit(): void {
    this.clear();
    this.loadMyPendingChanges();
  }

  refresh() {
    this.clear();
    this.loadMyPendingChanges();
  }

  loadMyPendingChanges() {
    this.dataSourcePendingAccelerator = new MatTableDataSource(this.service.myPendingChanges?.pendingAccelerators ?? []);
    this.dataSourcePendingUser = new MatTableDataSource(this.service.myPendingChanges?.pendingUsers ?? []);
    this.dataSourcePendingRole = new MatTableDataSource(this.service.myPendingChanges?.pendingRoles ?? []);
    this.dataSourcePendingResource = new MatTableDataSource(this.service.myPendingChanges?.pendingResources ?? []);
    this.dataSourcePendingNotificationTemplate = new MatTableDataSource(this.service.myPendingChanges?.pendingNotificationTemplates ?? []);
    this.dataSourcePendingRSSDatabase = new MatTableDataSource(this.service.myPendingChanges?.pendingRSSDb ?? []);
    this.pendingApprovals = this.service.myPendingChanges?.total ?? 0;
    const catalogDataMapping = [
      { id: 'accCatalog', dataSource: this.dataSourcePendingAccelerator },
      { id: 'beamCatalog', dataSource: this.dataSourcePendingResource },
      { id: 'rssdb', dataSource: this.dataSourcePendingRSSDatabase },
      { id: 'users', dataSource: this.dataSourcePendingUser },
      { id: 'roles', dataSource: this.dataSourcePendingRole },
      { id: 'notificationTemplates', dataSource: this.dataSourcePendingNotificationTemplate },
    ];

    for (const mapping of catalogDataMapping) {
      const catalog = this.catalogsData.find(x => x.id === mapping.id);
      if (catalog) {
        catalog.qty = mapping.dataSource.data.length;
      }
    }
    this.updateMyChanges.emit(this.catalogsData);
  }

  clear() {
    this.dataSourcePendingAccelerator = new MatTableDataSource();
    this.dataSourcePendingResource = new MatTableDataSource();
    this.dataSourcePendingUser = new MatTableDataSource();
    this.dataSourcePendingRole = new MatTableDataSource();
    this.dataSourcePendingRSSDatabase = new MatTableDataSource();
  }

}

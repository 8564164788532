import { Component, EventEmitter, Injector, Input, Output, QueryList, SimpleChanges, ViewChildren, ViewContainerRef } from '@angular/core';
import { MatTableDataSource, MatRow } from '@angular/material/table';
import { Observable, Subscription } from 'rxjs';
import { BaseComponent } from 'src/app/common/base/base.component';
import { FormStatusEnum, ResourceType } from 'src/app/common/enumerations/enumerations';
import { utils } from 'src/app/modules/libs/utils';
import { Resource } from '../../beamline-catalog/resource/resources';
import { ResourcesService } from '../../beamline-catalog/resource/resources.service';

@Component({
  selector: 'rssd-list-side',
  templateUrl: './rssd-list-side.component.html',
  styleUrls: ['./rssd-list-side.component.scss']
})
export class RssdListSideComponent extends BaseComponent {

  @Input() filter?: string | null;
  @Output() loading = new EventEmitter<boolean>();

  displayedColumns: string[] = [
    'name',
    'type'
  ];
  dataSource = new MatTableDataSource<Resource>();

  @Output() rowSelected = new EventEmitter<any>();
  @Output() rowsFiltered = new EventEmitter<Resource[]>();

  @ViewChildren('matrow', { read: ViewContainerRef }) rows?: QueryList<ViewContainerRef>;
  @ViewChildren('matrow') datarows?: QueryList<MatRow>;

  allowNew = false;
  disabled = false;
  maxHeight!: string;

  resource?: Resource | null;
  resources?: Resource[];
  resourcesFiltered?: Resource[];
  resources$!: Observable<Resource[]>;
  resourcesSubs!: Subscription;

  constructor(
    protected override injector: Injector,
    private service: ResourcesService
  ) {
    super(injector);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['filter']) {
      this.applyFilter();
    }
  }

  override ngOnDestroy(): void {
    this.resourcesSubs?.unsubscribe();
  }

  ngOnInit(): void {
    this.loadResources();
  }

  loadResources() {
    this.resources$ = this.store.select(state => state.Resources.data);
    this.resourcesSubs = this.resources$.subscribe(data => {
      if (data?.length) {
        this.resources = data.filter(r => r.type == ResourceType.Accelerator || r.type == ResourceType.Shutter);
        this.createDataSource();
      }
    });
  }

  createDataSource() {
    this.resourcesFiltered = this.resources?.filter(r => this.hasRSSDs(r))
      .filter(r => this.filter ? (r.displayName?.includes(this.filter.toLowerCase()) || r.name?.includes(this.filter.toLowerCase())) : true);

    this.resourcesFiltered?.map(r => r.displayName = r.type == ResourceType.Accelerator ? r.name : r.childResources?.find(r => r.childResourceType == ResourceType.Beamline)?.childResourceName);
    this.resourcesFiltered = this.resourcesFiltered?.sort((a, b) => this.utils.sortArrayAlphabeticallyWithComplexNumbers((a.type?.toString() ?? '0') + a.displayName, (b.type?.toString() ?? '0') + b.displayName));
    this.dataSource = new MatTableDataSource(this.resourcesFiltered);
    this.rowsFiltered.emit(this.resourcesFiltered);
  }

  hasRSSDs(r: Resource) {
    return r.childResources?.some(c => c.childResourceType == ResourceType.Shielding && c.childResource?.isRSSD);
  }

  rowUnselected() {
    this.resource = null;
    this.rowSelected.emit(null);
  }

  rowClicked(row: Resource) {
    if (this.resource?.id == row.id) {
      this.rowUnselected();
      this.loading.emit(false);
    }
    else {
      this.getValues(row);
    }
  }

  async getValues(row: Resource) {
    if (row?.id) {
      this.resource = row;
      this.rowSelected.emit(this.resource);
    }
  }
  //#endregion LOAD

  //#region ADD

  unselect() {

  }

  //#endregion

  //#region FILTERS

  applyFilter() {
    this.createDataSource();
    this.dataSource.filter = this.filter?.trim().toLowerCase() ?? '';
  }

  changeClosed() {
    this.applyFilter();
  }

  async refresh() {

  }

  //#endregion

  // #region TableScroll
  showElement(index: number, height: number) {
    const indexstr = index.toString();
    const row = this.rows?.find(
      (r) => r.element.nativeElement.getAttribute('position') === indexstr
    );
    if (row) {
      const rect = row.element.nativeElement.getBoundingClientRect();
      if (rect.y <= 0 || rect.y + rect.height > height) {
        row.element.nativeElement.scrollIntoView(false, {
          behavior: 'instant',
        });
      }
      return;
    }
    console.log('not found');
  }

  getPosition(row: any): number {
    const i = this.dataSource.data.findIndex((r) => {
      return r.id === row.id;
    });
    return i;
  }

  formatLocationNames(locationNames: string) {
    if (locationNames) {
      return utils.replaceAll(locationNames, ',', ', ');
    }
    return '';
  }

}

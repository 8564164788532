import { Component, Input, OnInit, OnDestroy, OnChanges, SimpleChanges, ViewChild, ElementRef, Output, EventEmitter, Injector } from "@angular/core";
import { Subscription } from "rxjs/internal/Subscription";
import { Observable } from "rxjs";
import { AbstractControl, FormControl, Validators } from "@angular/forms";
import { SCFPrivileges, SCFStatus, SCFWorkRequired, ScfV2Service } from "../../scf-v2.service";
import { MatAutocomplete, MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { MatChipInputEvent } from "@angular/material/chips";
import * as moment from "moment";
import { CdkDragDrop, CdkDragMove, CdkDropList, CdkDropListGroup, moveItemInArray } from "@angular/cdk/drag-drop";
import { ViewportRuler } from "@angular/cdk/scrolling";
import { Roles, WorkRequired, ResourceType, NotificationType, Action, FormType, OlogNotificationType } from "src/app/common/enumerations/enumerations";
import { BeamlineRequiringApproval } from "src/app/components/catalogs/bratoi/models/beamline-requiring-approval";
import { utils } from "src/app/modules/libs/utils";
import { RelatedDocument } from "src/app/services/documents/documents";
import { NotificationMessage } from "src/app/components/catalogs/notifications/services/notification.models";
import { ResourceRelation, Resource, Location, ShieldingGroup, ResourceGroup } from "src/app/components/catalogs/beamline-catalog/resource/resources";
import { TOCA } from "src/app/components/catalogs/beamline-catalog/resource/toca";
import { Restriction } from "src/app/controls/restrictions/services/restriction";
import { SCFMaster, SCFAmendment } from "../../scf";
import { BaseComponent } from "src/app/common/base/base.component";
import { CommentsBoxComponent } from "src/app/controls/comments-box/comments-box.component";
import { RelatedLink } from "src/app/common/models/related-link";
import { NotificationService } from "src/app/components/catalogs/notifications/services/notification.service";
import { LocationsService } from "src/app/components/catalogs/beamline-catalog/resource/locations.service";
import { OLogService } from "src/app/components/olog/olog.service";
import { NotificationComponent } from "src/app/controls/notifications/notification.component";
import { PartialOlogComponent } from "src/app/controls/olog/partial-olog/partial-olog.component";
import { AddRelatedLinkComponent } from "src/app/controls/add-related-link/add-related-link.component";
import { User } from "src/app/components/catalogs/user-catalog/services/user";
import { RelatedLinkBase } from "src/app/components/catalogs/navigation-links/link/related-link-base";

@Component({
  selector: "scf-v2-main-s1",
  templateUrl: "./scf-v2-main-s1.component.html",
  styleUrls: ["./scf-v2-main-s1.component.scss"],
})
export class ScfV2MainS1Component extends BaseComponent implements OnInit, OnDestroy, OnChanges {
  @Input() scfMaster?: SCFMaster | null;
  @Input() disabled? = true;
  @Input() privileges?: SCFPrivileges;
  @Input() status?: SCFStatus;

  @Output() changed = new EventEmitter<SCFMaster>();
  @Output() loading = new EventEmitter<boolean>();
  @Output() workRequiredChanged = new EventEmitter<any>();

  @ViewChild("locationInput")
  locationInput!: ElementRef<HTMLInputElement>;
  @ViewChild("shieldingInput")
  shieldingInput!: ElementRef<HTMLInputElement>;
  @ViewChild("autoLoc")
  matAutocompleteLoc!: MatAutocomplete;
  @ViewChild("autoShl") matAutocompleteShl!: MatAutocomplete;
  @ViewChild("relatedLinksInput")
  relatedLinksInput!: ElementRef<HTMLInputElement>;


  @ViewChild(CommentsBoxComponent)
  descriptionComponent?: CommentsBoxComponent;
  @ViewChild("chipListLoc")
  chipListLoc!: { errorState: boolean; };
  @ViewChild("chipListShl")
  chipListShl!: { errorState: boolean; };

  currentSCFMaster!: SCFMaster;

  addOnBlur = true;
  descriptionHasError = false;


  disableControls = true;

  locations: Location[] | null = [];
  allLocations!: Location[];
  filteredLocations!: Location[];
  locationGroups!: ResourceGroup[];
  shieldingGroups!: ShieldingGroup[];

  allShieldings: ResourceRelation[] = [];
  resources!: Resource[];
  resources$!: Observable<Resource[]>;
  resourcesSubs!: Subscription;

  resourceRelations!: ResourceRelation[];
  resourceRelations$!: Observable<ResourceRelation[]>;
  resourceRelationsSubs!: Subscription;

  restrictions!: Restriction[];
  restrictions$!: Observable<Restriction[]>;
  restrictionsSubs!: Subscription;

  requesters!: User[];

  //  requesterRoles: number[] = [5, 7, 8, 12, 13, 21, 24, 47, 1048];
  requesterRoles: Roles[] = [
    Roles.ATS,
    Roles.AO,
    Roles.ALTBLSCI,
    Roles.BLSCI,
    Roles.FO,
    Roles.MT,
    Roles.RFEng,
    Roles.SnA,
    Roles.VAC,
    Roles.RP,
  ];

  bratois!: BeamlineRequiringApproval[];
  bratois$!: Observable<BeamlineRequiringApproval[]>;
  bratoisSubs!: Subscription;

  shieldings: ResourceRelation[] = [];
  filteredShieldings: ResourceRelation[] = [];

  documents?: RelatedDocument[] | null = [];

  relatedLinks: RelatedLinkBase[] = [];

  notificationMessage!: NotificationMessage | null;
  notificationButtonLabel!: string;
  notificationButtonDisabled!: boolean;

  tocas!: TOCA[];
  tocas$!: Observable<TOCA[]>;
  tocasSubs!: Subscription;

  today = new Date();

  scfAmendment?: SCFAmendment;

  descriptionCtrlText!: string | null;
  workRequired = WorkRequired;

  public get checkAccRFCtrl(): AbstractControl | null { return this.formGroup?.get("checkAccRFCtrl"); }
  public get locationsCtrl(): AbstractControl | null { return this.formGroup?.get("locationsCtrl"); }
  public get shieldingsCtrl(): AbstractControl | null { return this.formGroup?.get("shieldingsCtrl"); }
  public get checkBlShCtrl(): AbstractControl | null { return this.formGroup?.get("checkBlShCtrl"); }
  public get checkTocaMovCtrl(): AbstractControl | null { return this.formGroup?.get("checkTocaMovCtrl"); }
  public get checkPermBlCtrl(): AbstractControl | null { return this.formGroup?.get("checkPermBlCtrl"); }
  public get checkPermAccCtrl(): AbstractControl | null { return this.formGroup?.get("checkPermAccCtrl"); }
  public get inputWorkHiddenCtrl(): AbstractControl | null { return this.formGroup?.get("inputWorkHiddenCtrl"); }
  public get inputNotificationsHiddenCtrl(): AbstractControl | null { return this.formGroup?.get("inputNotificationsHiddenCtrl"); }
  public get relatedLinksCtrl(): AbstractControl | null { return this.formGroup?.get("relatedLinksCtrl"); }
  public get requesterCtrl(): AbstractControl | null { return this.formGroup?.get("requesterCtrl"); }
  public get estimatedInitCtrl(): AbstractControl | null { return this.formGroup?.get("estimatedInitCtrl"); }
  public get estimatedEndCtrl(): AbstractControl | null { return this.formGroup?.get("estimatedEndCtrl"); }
  public get notiCheckHPCtrl(): AbstractControl | null { return this.formGroup?.get("notiCheckHPCtrl"); }
  public get notiCheckBLCtrl(): AbstractControl | null { return this.formGroup?.get("notiCheckBLCtrl"); }
  public get notiCheckBRCCtrl(): AbstractControl | null { return this.formGroup?.get("notiCheckBRCCtrl"); }
  public get notiCheckRFCtrl(): AbstractControl | null { return this.formGroup?.get("notiCheckRFCtrl"); }
  public get notiCheckSACtrl(): AbstractControl | null { return this.formGroup?.get("notiCheckSACtrl"); }
  public get notiCheckARCtrl(): AbstractControl | null { return this.formGroup?.get("notiCheckARCtrl"); }
  public get notiCheckRPCtrl(): AbstractControl | null { return this.formGroup?.get("notiCheckRPCtrl"); }
  descriptionCtrl = new FormControl();


  @ViewChild(CdkDropListGroup)
  listGroup!: CdkDropListGroup<CdkDropList>;
  @ViewChild(CdkDropList)
  placeholder!: CdkDropList;

  public target: any;
  public targetParent: any;
  public targetIndex!: number;
  public sourceIndex!: number;
  public dragIndex!: number;

  constructor(
    private locationsService: LocationsService,
    private notificationService: NotificationService,
    private ologService: OLogService,
    private scfService: ScfV2Service,
    private viewportRuler: ViewportRuler,
    protected override injector: Injector
  ) {
    super(injector);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.loadResourceRelations();
    this.loadLocations();
    this.loadBratoi();
    this.loadTocas();
    this.getValues();
    this.setDisabled();
    this.loadRequesters();
    this.getNotification();
  }

  override ngOnDestroy(): void {
    this.resourcesSubs?.unsubscribe();
    this.restrictionsSubs?.unsubscribe();
    this.bratoisSubs?.unsubscribe();
    this.tocasSubs?.unsubscribe();
    this.resourceRelationsSubs?.unsubscribe();
  }

  ngOnInit(): void {
    this.initializeForm();
  }

  initializeForm() {
    this.formGroup = this.formBuilder.group({
      locationsCtrl: [
        { value: "" },
        [Validators.required, utils.IsWhiteSpaceReactiveForm],
      ],
      shieldingsCtrl: [{ value: null }],
      checkAccRFCtrl: [{ value: false }],
      checkBlShCtrl: [{ value: false }],
      checkTocaMovCtrl: [{ value: false }],
      checkPermBlCtrl: [{ value: false }],
      checkPermAccCtrl: [{ value: false }],
      inputWorkHiddenCtrl: [{ value: null }],
      documentCtrl: [{ value: null }],
      relatedLinksCtrl: [{ value: null }],
      requesterCtrl: [
        { value: null },
        [Validators.required, utils.IsWhiteSpaceReactiveForm],
      ],
      estimatedInitCtrl: [{ value: null }],
      estimatedEndCtrl: [{ value: null }],
      notiCheckHPCtrl: [{ value: null }],
      notiCheckBLCtrl: [{ value: null }],
      notiCheckBRCCtrl: [{ value: null }],
      notiCheckRFCtrl: [{ value: null }],
      notiCheckSACtrl: [{ value: null }],
      notiCheckARCtrl: [{ value: null }],
      notiCheckRPCtrl: [{ value: null }],
      inputNotificationsHiddenCtrl: [{ value: null }],
      descriptionCtrl: this.descriptionCtrl
    });
    if (this.relatedLinksInput) {
      this.relatedLinksInput.nativeElement.value = "";
    }
    this.formGroup.disable();
    this.formGroup.valueChanges.subscribe(() => {
      this.formValid();
    });
  }

  getValues() {
    if (this.scfMaster) {
      if (this.status?.isViewingAmendment) {
        this.scfAmendment = this.scfMaster.scfAmendments?.find((x) => x.active);
        if (this.scfAmendment) {
          this.locations = this.getLocationsFromAmendment(this.scfAmendment);
          this.shieldings = this.getShieldings(this.scfAmendment.shieldingAffected);
          this.relatedLinks = this.getRelatedLinks(
            this.scfAmendment.relatedLinks
          );
          this.checkAccRFCtrl?.setValue(this.scfAmendment.accRFShielding);
          this.checkBlShCtrl?.setValue(this.scfAmendment.blShielding);
          this.checkTocaMovCtrl?.setValue(this.scfAmendment.tocaMovement);
          this.checkPermBlCtrl?.setValue(this.scfAmendment.permanentBLRemoval);
          this.checkPermAccCtrl?.setValue(this.scfAmendment.permanentAccRemoval);
          this.requesterCtrl?.setValue(this.scfAmendment.requesterId);
          this.estimatedInitCtrl?.setValue(this.scfAmendment.estimatedStartOn);
          this.estimatedEndCtrl?.setValue(this.scfAmendment.estimatedEndOn);
          this.documents = this.scfAmendment?.relatedDocuments;
          this.descriptionCtrlText = this.scfAmendment?.description;
          this.notiCheckHPCtrl?.setValue(this.scfAmendment.notifyHP);
          this.notiCheckBLCtrl?.setValue(this.scfAmendment.notifyBLSci);
          this.notiCheckBRCCtrl?.setValue(this.scfAmendment.notifyBRC);
          this.notiCheckRFCtrl?.setValue(this.scfAmendment.notifyRF);
          this.notiCheckSACtrl?.setValue(this.scfAmendment.notifySnA);
          this.notiCheckARCtrl?.setValue(this.scfAmendment.notifyARC);
          this.notiCheckRPCtrl?.setValue(this.scfAmendment.notifyRP);
        }

      } else {
        this.locations = this.scfMaster?.locations;
        this.shieldings = this.scfMaster.shieldingAffected ? this.getShieldings(this.scfMaster?.shieldingAffected) : [];
        this.documents = this.scfMaster?.relatedDocuments;
        this.relatedLinks = this.scfMaster.relatedLinks ? this.getRelatedLinks(this.scfMaster?.relatedLinks) : [];
        this.checkBlShCtrl?.setValue(this.scfMaster?.blShielding);
        this.checkTocaMovCtrl?.setValue(this.scfMaster?.tocaMovement);
        this.checkPermBlCtrl?.setValue(this.scfMaster?.permanentBLRemoval);
        this.checkPermAccCtrl?.setValue(this.scfMaster?.permanentAccRemoval);
        this.requesterCtrl?.setValue(this.scfMaster?.requesterId);
        this.estimatedInitCtrl?.setValue(this.scfMaster?.estimatedStartOn);
        this.estimatedEndCtrl?.setValue(this.scfMaster?.estimatedEndOn);
        this.descriptionCtrlText = this.scfMaster?.description ? this.scfMaster?.description : null;
        this.notiCheckHPCtrl?.setValue(this.scfMaster.notifyHP);
        this.notiCheckBLCtrl?.setValue(this.scfMaster.notifyBLSci);
        this.notiCheckBRCCtrl?.setValue(this.scfMaster.notifyBRC);
        this.notiCheckRFCtrl?.setValue(this.scfMaster.notifyRF);
        this.notiCheckSACtrl?.setValue(this.scfMaster.notifySnA);
        this.notiCheckARCtrl?.setValue(this.scfMaster.notifyARC);
        this.notiCheckRPCtrl?.setValue(this.scfMaster.notifyRP);
        if (this.checkAccRFCtrl) {
          this.checkAccRFCtrl?.setValue(this.scfMaster?.accRFShielding);
        }
      }
      this.notiCheckHPCtrl?.disable()
      if (this.locations?.some((l) => l.type === ResourceType.Beamline) ?? false) this.notiCheckBLCtrl?.disable();
      if (this.checkPermBlCtrl?.value) this.notiCheckBRCCtrl?.disable();
      if (this.checkPermAccCtrl?.value) this.notiCheckARCtrl?.disable();
      if (this.checkPermAccCtrl?.value || this.checkPermBlCtrl?.value) this.notiCheckRPCtrl?.disable();
      if (this.checkTocaMovCtrl?.value) this.notiCheckSACtrl?.disable();
      this.setNotificationChecks(WorkRequired.None, false);
      this.loadLocations();
    } else {
      this.locations = null;
      this.shieldings = [];
      this.documents = null;
      this.relatedLinks = [];

      this.checkBlShCtrl?.setValue(false);
      this.checkTocaMovCtrl?.setValue(false);
      this.checkPermBlCtrl?.setValue(false);
      this.checkPermAccCtrl?.setValue(false);
      this.requesterCtrl?.setValue(null);
      this.estimatedInitCtrl?.setValue(null);
      this.estimatedEndCtrl?.setValue(null);
      this.descriptionCtrlText = null;
      this.notiCheckHPCtrl?.setValue(false);
      this.notiCheckBLCtrl?.setValue(false);
      this.notiCheckBRCCtrl?.setValue(false);
      this.notiCheckRFCtrl?.setValue(false);
      this.notiCheckSACtrl?.setValue(false);
      this.notiCheckARCtrl?.setValue(false);
      this.notiCheckRPCtrl?.setValue(false);
    }
    this.loading.emit(false);
  }

  getRelatedLinks(relatedLinksString: string): RelatedLinkBase[] {
    if (relatedLinksString) {
      const relatedLinksArray = utils.JSONparse(relatedLinksString);
      let relatedLinks: RelatedLinkBase[] = [];
      if (relatedLinksArray.some((r: any) => r.URL && r.Text)) {
        // Use RelatedLink
        relatedLinksArray.map((r: RelatedLink) => {
          relatedLinks.push({ text: r.Text, url: r.URL, formID: 1, id: 0 } as RelatedLinkBase);
        });
      }
      else {
        relatedLinks = relatedLinksArray;
      }
      return relatedLinks;
    }
    return [];
  }

  getLocationsFromAmendment(scfAmendment: SCFAmendment) {
    if (!this.resources?.length) {
      this.loadLocations();
    }
    const locations: Location[] = [];
    const locationIds: string[] = scfAmendment.locationIds?.split(",");
    const locationNames: string[] = scfAmendment.locationNames?.split(",");
    locationIds.map((id, i) => {
      const location = {
        id: +id,
        name: locationNames[i],
        type: this.resources?.find((x) => x.id == +id)?.type,
      } as Location;
      locations.push(location);
    });
    return locations;
  }

  setValues() {
    if (this.scfMaster) {
      if (!this.status?.isViewingAmendment) {
        this.scfMaster.description = this.descriptionCtrlText ?? '';
        this.scfMaster.locations = this.locations ?? [];
        this.scfMaster.locationIds = this.locations?.map((l) => l.id).join(",");
        this.scfMaster.locationNames = this.locations
          ?.map((l) => l.name)
          .join(", ");
        this.scfMaster.shieldingAffected = utils.JSONstringify(this.shieldings);
        this.scfMaster.relatedDocuments = this.documents ?? [];
        this.scfMaster.relatedLinks = utils.JSONstringify(this.relatedLinks);
        this.scfMaster.accRFShielding = this.checkAccRFCtrl?.value;
        this.scfMaster.blShielding = this.checkBlShCtrl?.value;
        this.scfMaster.tocaMovement = this.checkTocaMovCtrl?.value;
        this.scfMaster.permanentBLRemoval = this.checkPermBlCtrl?.value;
        this.scfMaster.permanentAccRemoval = this.checkPermAccCtrl?.value;
        this.scfMaster.requesterId = this.requesterCtrl?.value;
        this.scfMaster.estimatedStartOn = this.estimatedInitCtrl?.value;
        this.scfMaster.estimatedEndOn = this.estimatedEndCtrl?.value;
        this.scfMaster.notifyARC = this.notiCheckARCtrl?.value;
        this.scfMaster.notifyRP = this.notiCheckRPCtrl?.value;
        this.scfMaster.notifyBLSci = this.notiCheckBLCtrl?.value;
        this.scfMaster.notifyBRC = this.notiCheckBRCCtrl?.value;
        this.scfMaster.notifyHP = this.notiCheckHPCtrl?.value;
        this.scfMaster.notifyRF = this.notiCheckRFCtrl?.value;
        this.scfMaster.notifySnA = this.notiCheckSACtrl?.value;
        if (this.locations?.some((l) => l.type === ResourceType.Beamline) ?? false) this.notiCheckBLCtrl?.disable();
        else this.notiCheckBLCtrl?.enable();
      } else {
        if (this.scfAmendment) {
          this.scfAmendment.description = this.descriptionCtrlText ?? '';
          this.scfAmendment.locations = this.locations ?? [];
          this.scfAmendment.locationIds = this.locations?.map((l) => l.id).join(",") ?? '';
          this.scfAmendment.locationNames = this.locations?.map((l) => l.name).join(",") ?? '';
          this.scfAmendment.shieldingAffected = utils.JSONstringify(
            this.shieldings
          );
          this.scfAmendment.relatedDocuments = this.documents ?? [];
          this.scfAmendment.relatedLinks = utils.JSONstringify(this.relatedLinks);
          this.scfAmendment.accRFShielding = this.checkAccRFCtrl?.value;
          this.scfAmendment.blShielding = this.checkBlShCtrl?.value;
          this.scfAmendment.tocaMovement = this.checkTocaMovCtrl?.value;
          this.scfAmendment.permanentBLRemoval = this.checkPermBlCtrl?.value;
          this.scfAmendment.permanentAccRemoval = this.checkPermAccCtrl?.value;
          this.scfAmendment.requesterId = this.requesterCtrl?.value;
          this.scfAmendment.estimatedStartOn = this.estimatedInitCtrl?.value;
          this.scfAmendment.estimatedEndOn = this.estimatedEndCtrl?.value;
          this.scfAmendment.notifyARC = this.notiCheckARCtrl?.value;
          this.scfAmendment.notifyRP = this.notiCheckRPCtrl?.value;
          this.scfAmendment.notifyBLSci = this.notiCheckBLCtrl?.value;
          this.scfAmendment.notifyBRC = this.notiCheckBRCCtrl?.value;
          this.scfAmendment.notifyHP = this.notiCheckHPCtrl?.value;
          this.scfAmendment.notifyRF = this.notiCheckRFCtrl?.value;
          this.scfAmendment.notifySnA = this.notiCheckSACtrl?.value;
          if (this.locations?.some((l) => l.type === ResourceType.Beamline) ?? false) this.notiCheckBLCtrl?.disable();
          else this.notiCheckBLCtrl?.enable();
        }
      }
      this.setFormDirty();
      this.formValid();
      this.changed.emit(this.scfMaster);
    }
  }

  setDisabled() {
    if (this.disabled) {
      this.formGroup?.disable();
      this.disableControls = true;
    } else if (this.status && (
      this.status.isCreating ||
      this.status.isEditing ||
      this.status.isCreatingAmendment ||
      this.status.isEditingAmendment)
    ) {
      this.formGroup?.enable();
      this.disableControls = false;
      this.notiCheckHPCtrl?.disable()
      if (this.locations?.some((l) => l.type === ResourceType.Beamline) ?? false) this.notiCheckBLCtrl?.disable();
      if (this.checkPermBlCtrl?.value) this.notiCheckBRCCtrl?.disable();
      if (this.checkPermAccCtrl?.value) this.notiCheckARCtrl?.disable();
      if (this.checkPermAccCtrl?.value || this.checkPermBlCtrl?.value) this.notiCheckRPCtrl?.disable();
      if (this.checkTocaMovCtrl?.value) this.notiCheckSACtrl?.disable();
    } else {
      this.formGroup?.disable();
      this.disableControls = true;
    }
    this.relatedLinksCtrl?.disable();
  }

  loadRequesters() {
    this.requesters = this.getUsers().filter(x => utils.intersect(this.requesterRoles, x.userRole?.map((u) => u.roleID)));
  }
  //#region Locations

  loadResourceRelations() {
    this.resourceRelations$ = this.store.select(
      (state) => state.ResourceRelations.data
    );
    this.resourceRelationsSubs = this.resourceRelations$.subscribe((data) => {
      if (data.length) {
        this.resourceRelations = data;
      }
    });
  }

  loadLocations() {
    this.resources$ = this.store.select((state) => state.Resources.data);
    this.resourcesSubs = this.resources$.subscribe((data) => {
      if (data.length) {
        this.resources = data;
        this.allLocations = this.resources.filter((x) => x.status !== 0 && x.resourceType?.location)?.map(x => {
          const location: Location = {
            id: x.id,
            name: x.name,
            type: x.type,
            resourceType: x.resourceType,
          };
          return location;
        }).sort((a, b) => utils.sortArrayAlphabeticallyWithComplexNumbers(a.name ?? '', b.name ?? '')) ?? [];

        this.filteredLocations = this.allLocations.filter((x) => !this.locations?.map((l) => l.id).includes(x.id));
        this.createLocationGroups();
        this.loadRelatedShieldings();
      }
    });
  }

  createLocationGroups() {
    this.locationGroups = [];
    this.filteredLocations.map((l) => {
      if (l.type == ResourceType.Accelerator) {
        if (!this.locationGroups.map((g) => g.id).includes(l.id)) {
          const relations = this.resourceRelations?.filter(
            (rr) =>
              rr.parentResourceID == l.id &&
              !this.locations?.map((x) => x.id).includes(rr.childResourceID)
          );
          const locs = relations
            ?.filter(
              (l) => l.childResourceType == ResourceType.AcceleratorLocation
            )
            .map((rr) => {
              return {
                id: rr.childResourceID,
                name: rr.childResourceName,
                type: rr.childResourceType,
              } as Location;
            });
          this.locationGroups.push({
            name: l.name,
            type: l.type,
            id: l.id,
            resources: locs,
          });
        }
      } else {
        if (
          !this.locationGroups.map((g) => g.type).includes(l.type) &&
          l.type != ResourceType.AcceleratorLocation
        ) {
          this.locationGroups.push({
            name: l.resourceType?.name,
            type: l.type,
            resources: this.filteredLocations.filter((x) => x.type == l.type),
          });
        }
      }
    });
    this.locationGroups = this.locationGroups.sort((a, b) => (a.type ?? 0) - (b.type ?? 0));
  }

  getChildLocations(location: Location) { }

  removeLoc(location: Location): void {
    const index = this.locations?.indexOf(location) ?? -1;
    if (index >= 0) {
      this.locations?.splice(index, 1);
      this.setValues();
      this.validateCheckboxes();
      this.loadLocations();
    }
    this.saveNotification();
  }

  checkRemove(location: Location) {
    const parentID = this.resourceRelations?.find(r => r.childResourceID == location.id)?.parentResourceID;
    const childResourceIDs = this.resourceRelations?.filter(r => r.parentResourceID === parentID).map(r => r.childResourceID);
    const shieldingMatch = childResourceIDs?.some(childID => this.shieldings?.some(s => s.childResourceID === childID));
    const restrictionMatch = this.scfMaster?.scfRestrictionsV2?.some(r => r.restriction?.resourceID == parentID);
    return shieldingMatch || restrictionMatch;
  }

  selectedLoc(event: MatAutocompleteSelectedEvent) {
    if (!this.locations) {
      this.locations = [];
    }
    if (
      !this.locations?.some((l) => l.id === event.option.value?.id) &&
      event.option.value !== null &&
      event.option.value !== undefined
    ) {
      this.locations.push(event.option.value);
      this.setValues();
      this.validateCheckboxes();
      this.loadLocations();
      this.locationInput.nativeElement.value = "";
      this.locationsCtrl?.setValue(this.locations);
    }
    this.saveNotification();
  }

  async addLoc(event: MatChipInputEvent) {
    if (!this.matAutocompleteLoc.isOpen) {
      const input = event.input;
      const value = event.value;
      if (input) {
        input.value = "";
      }
      if (value !== "") {
        const newLocation: Location = {
          id: 0,
          name: value,
          type: -1,
          newLocation: true,
        };
        this.locations?.push(newLocation);
        this.setValues();
      }
      this.locationsCtrl?.setValue(this.locations);
      this.loadLocations();
    }
  }

  onLocChange(code?: string) {
    if (!code?.includes("Arrow")) {
      this.loadLocations();
      this.filteredLocations = this.filteredLocations.filter((loc) =>
        loc.name
          ?.toLowerCase()
          .includes(this.locationsCtrl?.value?.trim().toLowerCase())
      );
      this.createLocationGroups();
    }
  }
  //#endregion

  //#region Checkboxes
  validateCheckboxes() {
    this.notiCheckBLCtrl?.setValue(this.locations?.some((x) => x.type === ResourceType.Beamline));
  }
  //#endregion

  //#region Shieldings
  getShieldings(shieldingAffected: string) {
    const shld: ResourceRelation[] = [];
    if (shieldingAffected && shieldingAffected !== "") {
      const shieldings: ResourceRelation[] = JSON.parse(shieldingAffected);
      return shieldings.sort((a, b) => (a.position ?? 0) - (b.position ?? 0));
    }
    return [];
  }

  async loadRelatedShieldings() {
    this.allShieldings = [];
    this.locations?.map((location) => {
      if (location.type ?? 0 > 0) {
        let resource = this.resources?.find(x => x.id == location.id);
        if (resource && resource.type == ResourceType.Branchline) {
          resource = this.resources.find(r => resource?.childResources?.length && r.id == resource.childResources[0]?.parentResourceID
          );
        }

        const shutterShieldings = this.resources?.find(r => r.id == resource?.parentResources?.find(x => x.parentResourceType == ResourceType.Shutter)?.parentResourceID)?.childResources?.filter(x => x.childResourceType == ResourceType.Shielding);

        if (shutterShieldings) {
          shutterShieldings.map(s => {
            const parents = this.resourceRelations?.filter(x => x.childResourceID == s.childResourceID);
            s.shared = parents?.length > 1;
            s.childResourceType = ResourceType.Shielding;
            s.childResourceTypeName = "Shielding";
          });
          this.allShieldings = this.allShieldings.concat(shutterShieldings.sort((a, b) => utils.SortBeamlines(a.childResourceName, b.childResourceName)));
        }

        const accShieldings = this.resources.find(r => r.id == resource?.parentResources?.find(x => x.parentResourceType == ResourceType.Accelerator)?.parentResourceID)?.childResources?.filter(x => x.childResourceType == ResourceType.Shielding).filter(x => (utils.JSONparse(x.locationsIDs) as number[]).includes(resource?.id ?? 0));

        if (accShieldings) {
          accShieldings.map(s => {
            const parents = this.resources.find(r => r.id == s.childResourceID)?.parentResources;
            s.shared = parents?.length ?? 0 > 1 ? true : false;
            s.sharedWith = parents?.filter(rr => rr.id != s.id)?.map(rr => rr.locationsIDs ?? '');
            s.childResourceType = ResourceType.Shielding;
            s.childResourceTypeName = "Shielding";
          });
          this.allShieldings = this.allShieldings.concat(accShieldings);
        }

        if (resource?.type == ResourceType.AcceleratorLocation) {
          const locationShieldings = this.resourceRelations?.filter(r => (utils.JSONparse(r.locationsIDs) as number[])?.includes(resource?.id ?? 0));
          locationShieldings?.map((x) => {
            if (!this.allShieldings.map((s) => s.id).includes(x.id)) {
              x.childResourceTypeName = "Accelerator Location";
              x.childResourceType = ResourceType.AcceleratorLocation;
              this.allShieldings.push(x);
            }
          });
        }

        const tocas = this.bratois?.find(
          (x) => x.action == Action.Approve && x.resourceId == location.id
        )?.tocas;
        if (tocas) {
          tocas.map((t) => {
            const toca = {
              childResourceID: -t.id,
              childResourceName: t.name,
              shared: true,
              position: 0,
              childResourceType: 99,
              childResourceTypeName: "TOCA",
            } as ResourceRelation;
            if (
              !this.allShieldings
                .map((x) => x.childResourceID)
                .includes(toca.childResourceID)
            ) {
              this.allShieldings.push(toca);
            }
          });
        }
      }
    });
    this.filterShieldings();
  }

  loadTocas() {
    this.tocas$ = this.store.select((state) => state.TOCA.data);
    this.tocasSubs = this.tocas$.subscribe((data) => {
      this.tocas = data;
    });
  }

  filterShieldings() {
    this.filteredShieldings = this.allShieldings.filter(
      (x) =>
        !this.shieldings
          .map((s) => s?.childResourceID)
          .includes(x?.childResourceID)
    );
    this.createShieldingGroups();
  }

  createShieldingGroups() {
    this.shieldingGroups = [];
    this.filteredShieldings.map((l) => {
      if (l.childResourceType == ResourceType.Accelerator) {
        if (!this.shieldingGroups.map((g) => g.id).includes(l.id)) {
          const relations = this.resourceRelations?.filter(
            (rr) =>
              rr.parentResourceID == l.id &&
              !this.locations?.map((x) => x.id).includes(rr.childResourceID)
          );
          const shieldings = relations?.filter(
            (l) => l.childResourceType == ResourceType.AcceleratorLocation
          );
          this.shieldingGroups.push({
            name: l.childResourceName,
            type: l.childResourceType,
            id: l.id,
            shieldings,
          });
        }
      } else {
        if (
          !this.shieldingGroups.map((g) => g.type).includes(l.childResourceType ?? 0) && l.childResourceType != ResourceType.AcceleratorLocation) {
          this.shieldingGroups.push({
            name: l.childResourceTypeName,
            type: l.childResourceType,
            shieldings: this.filteredShieldings.filter(
              (x) => x.childResourceType == l.childResourceType
            ),
          });
        }
      }
    });
    this.shieldingGroups = this.shieldingGroups.sort((a, b) => (a.type ?? 0) - (b.type ?? 0));
  }

  onShlChange(code?: string) {
    if (!code?.includes("Arrow")) {
      this.loadRelatedShieldings();
      this.filteredShieldings = this.filteredShieldings.filter((s) =>
        s.childResourceName
          ?.toLowerCase()
          .includes(this.shieldingsCtrl?.value?.trim()?.toLowerCase())
      );
      this.createShieldingGroups();
    }
  }

  addShl(event: any): void {
    const input = event.input;
    const value = this.shieldingsCtrl?.value;
    // Add our location
    if (value && typeof value == "string") {
      let s = undefined;
      const found = this.allShieldings.find((sh) => sh.childResourceName?.trim()?.toLowerCase() == value?.trim()?.toLowerCase());
      if (found) {
        s = found;
      } else {
        s = {
          id: 0,
          parentResourceID: 0,
          parentResource: null,
          parentResourceName: "manualEntry",
          childResourceID: 0,
          childResourceName: value,
        };
      }
      if (s) {
        this.shieldings.push(s);
        this.orderShieldings();
        this.setValues();
      }
    }
    // Reset the input value
    if (input) {
      input.value = "";
    }
    this.shieldingsCtrl?.setValue(null);
    this.loadRelatedShieldings();
  }

  async selectedShl(event: MatAutocompleteSelectedEvent) {
    if (this.shieldings === null) {
      this.shieldings = [];
    }
    const option: ResourceRelation = event.option.value;
    if (!this.shieldings.includes(option)) {
      this.shieldings.push(option);
      this.orderShieldings();
      this.setValues();
      this.shieldingInput.nativeElement.value = "";
      this.shieldingsCtrl?.setValue(null);
      if (option.childResourceType === 0) {
        this.checkTocaMovCtrl?.setValue(true);
        this.setValues();
        const tocaId = -(option?.childResourceID ?? 0);
        const toca = this.tocas.find((x) => x.id == tocaId);
        const relations = toca?.beamlineRequiringApprovalRelations?.filter((x) => x.id != option.id);
        const resources = relations?.map((x) => x.beamlineRequiringApproval.resource);
        resources?.map((r) => {
          const loc = {} as Location;
          loc.autoLocation = true;
          loc.id = r.id;
          loc.name = r.name;
          const matOption: MatAutocompleteSelectedEvent = JSON.parse(JSON.stringify({ option: { value: loc, }, }));
          this.selectedLoc(matOption);
        });
      } else {
        let locations = await this.locationsService.GetLocationByShieldingID(option.childResourceID ?? 0).toPromise();
        if (!locations?.length && option.locationsIDs) {
          const locationsIDs = utils.JSONparse(option.locationsIDs) as number[];
          locations = this.resources
            .filter((x) => locationsIDs.includes(x.id ?? 0)).map((r) => {
              return {
                id: r.id,
                name: r.name,
                type: r.type,
                status: r.status,
                description: r.description,
                resourceTypeID: r.resourceTypeID,
                resourceType: r.resourceType,
              } as Location;
            });
          if (option.shared) {
            let locationsIDs: any[] = [];
            option.sharedWith?.map((sw) => {
              const IDs = utils.JSONparse(sw);
              locationsIDs = locationsIDs?.concat(IDs);
            });
            locationsIDs = [...new Set(locationsIDs)];
            const locationsShared = this.resources
              .filter((x) => locationsIDs.includes(x.id))
              .map((r) => {
                return {
                  id: r.id,
                  name: r.name,
                  type: r.type,
                  status: r.status,
                  description: r.description,
                  resourceTypeID: r.resourceTypeID,
                  resourceType: r.resourceType,
                } as Location;
              });
            locations = locations.concat(locationsShared);
          }
        }

        locations?.map(loc => {
          loc.autoLocation = true;
          const matOption: MatAutocompleteSelectedEvent = JSON.parse(JSON.stringify({ option: { value: loc, }, }));
          this.selectedLoc(matOption);
        });

        this.setValues();
      }
    }
  }

  removeShl(shielding: ResourceRelation): void {
    const index = this.shieldings.indexOf(shielding);
    if (index >= 0) {
      this.shieldings.splice(index, 1);
      if (!this.shieldings.filter((x) => x.childResourceType === 0).length) {
        // TOCA
        this.checkTocaMovCtrl?.setValue(false);
      }
      this.orderShieldings();
      this.setValues();
    }
    this.loadRelatedShieldings();
  }

  drop(event: CdkDragDrop<ResourceRelation[]>) {
    moveItemInArray(
      this.shieldings,
      event.item.data.position,
      event.currentIndex
    );
    this.orderShieldings();
    this.formGroup.markAsDirty();
  }

  dropListDropped(event: CdkDragDrop<ResourceRelation[]>) {
    if (this.sourceIndex != this.targetIndex && this.targetIndex > -1) {
      moveItemInArray(this.shieldings, this.sourceIndex, this.targetIndex);
      this.sourceIndex = this.targetIndex;
      this.orderShieldings();
      this.setValues();
    }
  }

  dragMoved(e: CdkDragMove) {
    const point = this.getPointerPositionOnPage(e.event);
    const drag = e.source.element;
    this.sourceIndex = e.source.data.position;
    let index = 0;

    this.placeholder.element.nativeElement.firstChild?.childNodes.forEach(
      (node: any) => {
        if (node.nodeName != "#comment") {
          if (
            __isInsideDropListClientRect(node, point.x, point.y) &&
            index != this.sourceIndex
          ) {
            this.targetIndex = index;
          }
          index++;
        }
      }
    );
  }

  getPointerPositionOnPage(event: MouseEvent | TouchEvent) {
    // `touches` will be empty for start/end events so we have to fall back to `changedTouches`.
    const point = __isTouchEvent(event)
      ? event.touches[0] || event.changedTouches[0]
      : event;
    const scrollPosition = this.viewportRuler.getViewportScrollPosition();

    return {
      x: point.pageX - scrollPosition.left,
      y: point.pageY - scrollPosition.top,
    };
  }

  orderShieldings() {
    let i = 0;
    this.shieldings.map((s) => {
      s.position = i;
      i++;
    });
  }

  shared(shielding: ResourceRelation) {
    if (shielding.parentResourceType == this.resourceTypeEnum.Shutter)
      return shielding.shared;
    else
      return shielding.sharedWith?.length ?? 0 > 0;
  }

  sortedShieldings() {
    return this.shieldings?.sort((a, b) => (a.position ?? 0) - (b.position ?? 0));
  }
  //#endregion

  //#region Bratoi
  loadBratoi() {
    this.bratois$ = this.store.select((state) => state.Bratoi.data);
    this.bratoisSubs = this.bratois$.subscribe((data) => {
      if (data?.length > 0) {
        this.bratois = data
          .sort((a, b) =>
            utils.SortBeamlines(a.resourceString, b.resourceString)
          )
          .filter((bl) => bl.isActive);
      }
    });
  }
  //#endregion

  //#region Related Links
  addRelatedLink() {
    if (
      !this.disabled &&
      (this.status && (this.status.isCreating ||
        this.status.isEditing ||
        this.status.isCreatingAmendment ||
        this.status.isEditingAmendment))
    ) {
      const dialogRef = this.dialog.open(AddRelatedLinkComponent, {
        maxWidth: "800px",
        data: {
          obj: {
            Text: null,
            URL: null,
          } as RelatedLink,
        },
      });
      dialogRef.afterClosed().subscribe((result: RelatedLink) => {
        if (result) {
          this.relatedLinksCtrl?.markAsDirty();
          this.relatedLinks.push({ text: result.Text, url: result.URL, formID: 1, id: 0 } as RelatedLinkBase);
          this.setValues();
        }
      });
    }
  }

  removeRelatedLink(i: number) {
    this.relatedLinks.splice(i, 1);
    this.relatedLinksCtrl?.markAsDirty();
    this.setValues();
  }

  //#endregion

  //#region Documents


  removeDoc(document: RelatedDocument): void {
    const index = this.documents?.indexOf(document) ?? -1;

    if (index >= 0) {
      this.documents?.splice(index, 1);
    }
    this.setValues();
  }

  openDocument(e: RelatedDocument) {
    window.location.href = "#/redirect/" + e.relatedDocumentSerialNo;
  }
  //#region Notification

  setNotificationChecks(workRequired: WorkRequired = 0, e: any) {
    if (this.scfMaster && !this.status?.isViewingAmendment) {
      this.scfMaster.notifyHP = true;
      this.scfMaster.notifyBLSci =
        this.scfMaster.notifyBLSci ||
        this.locations?.some((l) => l.type === ResourceType.Beamline);
      this.scfMaster.notifyBRC =
        this.scfMaster.notifyBRC || this.checkPermBlCtrl?.value;
      this.scfMaster.notifyARC =
        this.scfMaster.notifyARC || this.checkPermAccCtrl?.value;
      this.scfMaster.notifyRP =
        this.scfMaster.notifyRP ||
        this.checkPermAccCtrl?.value ||
        this.checkPermBlCtrl?.value;
      this.scfMaster.notifySnA =
        this.scfMaster.notifySnA || this.checkTocaMovCtrl?.value;
      this.notiCheckHPCtrl?.setValue(this.scfMaster?.notifyHP);
      this.notiCheckBLCtrl?.setValue(this.scfMaster?.notifyBLSci);
      this.notiCheckBRCCtrl?.setValue(this.scfMaster?.notifyBRC);
      this.notiCheckRFCtrl?.setValue(this.scfMaster?.notifyRF);
      this.notiCheckSACtrl?.setValue(this.scfMaster?.notifySnA);
      this.notiCheckARCtrl?.setValue(this.scfMaster?.notifyARC);
      this.notiCheckRPCtrl?.setValue(this.scfMaster?.notifyRP);
    } else if (this.status?.isViewingAmendment) {
      if (this.scfAmendment) {
        this.scfAmendment.notifyHP = true;
        this.scfAmendment.notifyBLSci = this.scfAmendment.notifyBLSci || (this.locations?.some((l) => l.type === ResourceType.Beamline) ?? false);
        this.scfAmendment.notifyBRC =
          this.scfAmendment.notifyBRC || this.checkPermBlCtrl?.value;
        this.scfAmendment.notifyARC =
          this.scfAmendment.notifyARC || this.checkPermAccCtrl?.value;
        this.scfAmendment.notifyRP =
          this.scfAmendment.notifyRP ||
          this.checkPermAccCtrl?.value || this.checkPermBlCtrl?.value;
        this.scfAmendment.notifySnA =
          this.scfAmendment.notifySnA || this.checkTocaMovCtrl?.value;
        this.notiCheckHPCtrl?.setValue(this.scfAmendment.notifyHP);
        this.notiCheckBLCtrl?.setValue(this.scfAmendment.notifyBLSci);
        this.notiCheckBRCCtrl?.setValue(this.scfAmendment.notifyBRC);
        this.notiCheckRFCtrl?.setValue(this.scfAmendment.notifyRF);
        this.notiCheckSACtrl?.setValue(this.scfAmendment.notifySnA);
        this.notiCheckARCtrl?.setValue(this.scfAmendment.notifyARC);
        this.notiCheckRPCtrl?.setValue(this.scfAmendment.notifyRP);
      }
    }
    const scfWorkRequired: SCFWorkRequired = {
      workRequired,
      checked: e.checked,
    };
    if (workRequired) {
      if (
        workRequired == WorkRequired.PermanentAccRemoval ||
        workRequired == WorkRequired.PermanentBLRemoval ||
        workRequired == WorkRequired.TocaMovement
      ) {
        this.loading.emit(true);
      }

      this.setValues();
      this.workRequiredChanged.emit(scfWorkRequired);
    }
  }

  saveNotification() {
    this.setValues();
  }
  //#endregion

  selectedRequester(e: any) {
    this.setValues();
  }

  descriptionChanged(e: any) {
    this.descriptionCtrlText = e;
    this.setValues();
  }

  changedDate(e: { value: string | number | Date; }) {
    const estimatedStartOn = new Date(e.value);
    const estimatedEndOn = new Date(this.estimatedEndCtrl?.value);
    if (moment(estimatedEndOn).isBefore(moment(estimatedStartOn))) {
      this.estimatedEndCtrl?.setValue(estimatedStartOn);
    }
    this.setValues();
  }

  async editOlog() {
    if (this.scfMaster?.id) {
      try {
        const data = await this.ologService.getOlog(FormType.SCF, this.scfMaster.id, OlogNotificationType.Activate).toPromise();
        if (data) {
          const dialogRef = this.dialog.open(PartialOlogComponent, {
            height: "fit-content",
            width: "80%",
            data,
          });
          dialogRef.afterClosed().subscribe((isSaved) => {
            console.log("The dialog was closed");
            if (isSaved) {
              console.log(isSaved);
            }
          });
        }
      } catch (error) {
        console.log(error);
      }
    }
  }

  async editNotification() {
    if (this.notificationMessage) {
      this.notificationService
        .getNotification(
          NotificationType.CreateSCF,
          this.scfMaster?.id ?? 0,
          this.locations ?? []
        )
        .toPromise().then(async (res: any) => {
          if (res) {
            this.notificationMessage = res;
            if (this.scfMaster) {
              const emails = await this.scfService.getEmails(this.scfMaster);
              this.openNotificationDialog(
                res,
                emails,
                this.disableControls
              );
            }
          }
        });
    } else {
      if (this.scfMaster) {
        const emails = await this.scfService.getEmails(this.scfMaster);
        const notification = {
          notificationId: NotificationType.CreateSCF,
          documentId: this.scfMaster?.id,
          toAddresses: this.utils.JSONstringify({ computed: emails, added: [] }),
          type: 5,
          locations: this.locations ?? []
        } as NotificationMessage;
        this.notificationService.createNotification(notification).toPromise().then(
          async (res: any) => {
            this.openNotificationDialog(
              res,
              emails,
              this.disableControls
            );
          },
          (error: any) => {
            console.log(error);
          }
        );
      }
    }
  }

  getNotification() {
    this.notificationMessage = null;
    if (this.scfMaster?.id) {
      this.notificationService
        .getNotification(
          NotificationType.CreateSCF,
          this.scfMaster?.id,
          this.locations ?? []
        )
        .toPromise()
        .then(
          (data: any) => {
            if (data) {
              this.notificationMessage = data;
            }
            if (this.disableControls) {
              this.notificationButtonLabel = "View Notification";
              this.notificationButtonDisabled = false;
            } else {
              this.notificationButtonDisabled = false;
              this.notificationButtonLabel = "Edit Notification";
            }
          },
          (error: any) => {
            console.log(error);
          }
        );
    }
  }

  openNotificationDialog(
    data: NotificationMessage,
    emailAddresses?: string[],
    readOnly?: boolean
  ): void {
    const dialogRef = this.dialog.open(NotificationComponent, {
      height: "fit-content",
      width: "80%",
      data: {
        message: data,
        emailAddresses,
        readOnly,
      },
    });

    dialogRef
      .afterClosed()
      .toPromise()
      .then(() => {
        this.getNotification();
      });
  }


  //#region Differences

  diffWorkRequired() {
    return (
      utils.isNull(this.checkAccRFCtrl?.value, false) !==
      utils.isNull(this.scfMaster?.accRFShielding, false) ||
      utils.isNull(this.checkBlShCtrl?.value, false) !==
      utils.isNull(this.scfMaster?.blShielding, false) ||
      utils.isNull(this.checkPermAccCtrl?.value, false) !==
      utils.isNull(this.scfMaster?.permanentAccRemoval, false) ||
      utils.isNull(this.checkPermBlCtrl?.value, false) !==
      utils.isNull(this.scfMaster?.permanentBLRemoval, false) ||
      utils.isNull(this.checkTocaMovCtrl?.value, false) !==
      utils.isNull(this.scfMaster?.tocaMovement, false)
    );
  }

  diffLocations() {
    const current = utils.JSONstringify(this.locations?.sort((a, b) => (a.id ?? 0) - (b.id ?? 0)).map((l) => {
      return { id: l.id, name: l.name } as Location;
    }))?.replace(/\s/g, "");
    const original = utils.JSONstringify(this.scfMaster?.locations?.sort((a, b) => (a.id ?? 0) - (b.id ?? 0)).map((l) => {
      return { id: l.id, name: l.name } as Location;
    }))?.replace(/\s/g, "");
    return current !== original;
  }

  diffDocuments() {
    const current = utils.JSONstringify(
      this.documents
        ?.sort((a, b) =>
          utils.sort(
            a.relatedDocumentSerialNo,
            b.relatedDocumentSerialNo,
            true
          )
        )
        .map((l) => {
          return {
            relatedDocumentId: l.relatedDocumentId,
            relatedDocumentSerialNo: l.relatedDocumentSerialNo,
          } as RelatedDocument;
        })
    )?.replace(/\s/g, "");
    const original = utils.JSONstringify(
      this.scfMaster?.relatedDocuments
        ?.sort((a, b) =>
          utils.sort(
            a.relatedDocumentSerialNo,
            b.relatedDocumentSerialNo,
            true
          )
        )
        .map((l) => {
          return {
            relatedDocumentId: l.relatedDocumentId,
            relatedDocumentSerialNo: l.relatedDocumentSerialNo,
          } as RelatedDocument;
        })
    )?.replace(/\s/g, "");
    return current != original;
  }

  diffRelatedLinks() {
    const current = utils.JSONstringify(
      this.relatedLinks
        ?.sort((a, b) => utils.sort(a.text, b.text, true))
    ).replace(/\s/g, "");
    if (this.scfMaster?.relatedLinks) {
      const original = utils.JSONstringify(
        this.getRelatedLinks(this.scfMaster.relatedLinks)
          ?.sort((a: any, b: any) => utils.sort(a.Text, b.Text, true))
      ).replace(/\s/g, "");
      return current != original;
    }
    return false;
  }

  diffShieldings() {
    const current = utils.JSONstringify(
      this.shieldings
        ?.sort((a, b) =>
          utils.sort(a.childResourceID, b.childResourceID, true)
        )
        .map((l) => {
          return {
            childResourceID: l.childResourceID,
            childResourceName: l.childResourceName,
          } as ResourceRelation;
        })
    ).replace(/\s/g, "");
    if (this.scfMaster?.shieldingAffected) {
      const original = utils.JSONstringify(
        this.getShieldings(this.scfMaster.shieldingAffected)
          ?.sort((a, b) =>
            utils.sort(a.childResourceID, b.childResourceID, true)
          )
          .map((l) => {
            return {
              childResourceID: l.childResourceID,
              childResourceName: l.childResourceName,
            } as ResourceRelation;
          })
      ).replace(/\s/g, "");
      return current != original;
    }
    return false;
  }

  diffNotificationChecks() {
    return (
      utils.isNull(this.notiCheckARCtrl?.value, false) !==
      utils.isNull(this.scfMaster?.notifyARC, false) ||
      utils.isNull(this.notiCheckBLCtrl?.value, false) !==
      utils.isNull(this.scfMaster?.notifyBLSci, false) ||
      utils.isNull(this.notiCheckBRCCtrl?.value, false) !==
      utils.isNull(this.scfMaster?.notifyBRC, false) ||
      utils.isNull(this.notiCheckHPCtrl?.value, false) !==
      utils.isNull(this.scfMaster?.notifyHP, false) ||
      utils.isNull(this.notiCheckRFCtrl?.value, false) !==
      utils.isNull(this.scfMaster?.notifyRF, false) ||
      utils.isNull(this.notiCheckSACtrl?.value, false) !==
      utils.isNull(this.scfMaster?.notifySnA, false) ||
      utils.isNull(this.notiCheckRPCtrl?.value, false) !==
      utils.isNull(this.scfMaster?.notifyRP, false)
    );
  }
  /////

  formValid() {
    if (
      this.scfMaster && this.status &&
      (this.status.isCreating ||
        this.status.isCreatingAmendment ||
        this.status.isEditing ||
        this.status.isEditingAmendment)
    ) {
      if (this.locations?.length == 0) {
        this.locationsCtrl?.setErrors({ required: true });
        this.chipListLoc.errorState = true;
      } else {
        this.locationsCtrl?.setErrors(null);
        this.chipListLoc.errorState = false;
      }
      if (this.shieldings?.length == 0) {
        this.shieldingsCtrl?.setErrors({ required: true });
        this.chipListShl.errorState = true;
      } else {
        this.shieldingsCtrl?.setErrors(null);
        this.chipListShl.errorState = false;
      }
      if (!this.requesterCtrl?.value) {
        this.requesterCtrl?.setErrors({ required: true });
        this.requesterCtrl?.markAsTouched();
      }
      const estimatedInitOn = moment(this.estimatedInitCtrl?.value);
      const estimatedEndOn = moment(this.estimatedInitCtrl?.value);
      const today = moment(this.today).date();
      if (
        !estimatedInitOn.isValid() ||
        estimatedInitOn.isBefore(moment(today))
      ) {
        this.estimatedInitCtrl?.setErrors({ invalidDate: true });
        this.estimatedInitCtrl?.markAsTouched();
      } else {
        this.estimatedInitCtrl?.setErrors(null);
      }
      if (
        !estimatedEndOn.isValid() ||
        estimatedEndOn.isBefore(estimatedInitOn)
      ) {
        this.estimatedEndCtrl?.setErrors({ invalidDate: true });
        this.estimatedEndCtrl?.markAsTouched();
      } else {
        this.estimatedEndCtrl?.setErrors(null);
      }
      if (
        (!this.status?.isViewingAmendment && !this.scfMaster?.description) ||
        (this.status?.isViewingAmendment && !this.scfAmendment?.description)
      ) {
        this.descriptionCtrl.setErrors({ required: true });
        this.descriptionHasError = true;
      } else {
        this.descriptionCtrl.setErrors(null);
        this.descriptionHasError = false;
      }
      if (
        !this.checkAccRFCtrl?.value &&
        !this.checkBlShCtrl?.value &&
        !this.checkPermAccCtrl?.value &&
        !this.checkPermBlCtrl?.value &&
        !this.checkTocaMovCtrl?.value
      ) {
        this.inputWorkHiddenCtrl?.setErrors({ check: true });
        this.inputWorkHiddenCtrl?.markAsTouched();
      } else {
        this.inputWorkHiddenCtrl?.setErrors(null);
      }
    } else {
      this.locationsCtrl?.setErrors(null);
      if (this.chipListLoc) {
        this.chipListLoc.errorState = false;
      }
      this.shieldingsCtrl?.setErrors(null);
      if (this.chipListShl) {
        this.chipListShl.errorState = false;
      }
      this.descriptionCtrl.setErrors(null);
      this.descriptionHasError = false;
    }
    return this.formGroup.valid && !this.descriptionHasError;
  }
}

function __isTouchEvent(event: MouseEvent | TouchEvent): event is TouchEvent {
  return event.type.startsWith("touch");
}

function __isInsideDropListClientRect(node: any, x: number, y: number) {
  const { top, bottom, left, right } = node.getBoundingClientRect();
  return y >= top && y <= bottom && x >= left && x <= right;
}

function __indexOf(collection: { children: any; }, node: any) {
  return Array.prototype.indexOf.call(collection.children, node);
}

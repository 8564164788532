<mat-form-field appearance="outline">
  <mat-label>{{ placeholder }}</mat-label>
  <input
    #resourceInput
    matInput
    type="text"
    [disabled]="disabled"
    [attr.disabled]="disabled"
    [matAutocomplete]="resourceAutocomplete"
    (keyup)="onResourceInputKeyup($event)"
  />
  <button
    matSuffix
    mat-icon-button
    class="right"
    color="warn"
    *ngIf="resourceInput.value.length > 0 && !disabled"
    (click)="clear()"
  >
    <mat-icon>close</mat-icon>
  </button>
  <mat-autocomplete
    #resourceAutocomplete="matAutocomplete"
    (optionSelected)="select($event)"
  >
    <mat-option *ngFor="let resource of filteredResources" [value]="resource">{{
      resource.name
    }}</mat-option>
  </mat-autocomplete>
</mat-form-field>

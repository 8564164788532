<div class="card">
  <div class="card-body">
    <div class="row">
      <div class="col-1 pt-2">
        <h6>{{ number ?? "#" }}</h6>
      </div>
      <div class="col-11 pt-2">
        <mat-label
          [innerHTML]="question ?? '' | placeholderHighlight : editor"
        ></mat-label>
      </div>
      <div class="col-11 offset-1 pt-2">
        <div class="pb-3 mat-table-container">
          <div class="mat-container">
            <mat-table [dataSource]="dataSource" matSort>
              <ng-container matColumnDef="serialNumber">
                <mat-header-cell *matHeaderCellDef mat-sort-header
                  >Serial No.</mat-header-cell
                >
                <mat-cell
                  class="font-weight-bold"
                  *matCellDef="let element"
                  nowrap
                >
                  {{ element.serialNumber }}
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="calibrationDate">
                <mat-header-cell *matHeaderCellDef mat-sort-header>
                  Calibration
                </mat-header-cell>
                <mat-cell *matCellDef="let element">
                  <span *ngIf="!canModify()" class="date">
                    {{ element.calibrationDueDate | date }}
                  </span>
                  <span
                    *ngIf="canModify() && dueDateChanging != element.id"
                    matTooltip="Click to change Cal Due Date"
                    ><mat-label
                      class="link"
                      (click)="changeCalDueDate(element)"
                      >{{ element.calibrationDueDate | date }}</mat-label
                    ></span
                  >
                  <span *ngIf="canModify() && dueDateChanging == element.id">
                    <mat-form-field class="w-120">
                      <mat-label>Choose a date</mat-label>
                      <input
                        aria-label="date"
                        matInput
                        [matDatepicker]="picker"
                        [(ngModel)]="element.calibrationDueDate"
                        (change)="calDueDateChanged(element)"
                        (dateChange)="calDueDateChanged(element)"
                      />
                      <mat-datepicker-toggle
                        matSuffix
                        [for]="picker"
                      ></mat-datepicker-toggle>
                      <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                  </span>
                  <span
                    class="warning"
                    *ngIf="element.calibrationDueDate"
                    [matTooltip]="'Calibration Expired'"
                  >
                    <mat-icon
                      class="small-icon"
                      color="warn"
                      *ngIf="isDateExpired(element.calibrationDueDate)"
                      >warning</mat-icon
                    >
                  </span>
                  <span
                    class="initials"
                    *ngIf="!dueDateChanging"
                    [matTooltip]="
                      element.calibrationDueDateChangedBy?.name +
                      ' on ' +
                      (element.calibrationDueDateChangedOn | date)
                    "
                  >
                    {{ element.calibrationDueDateChangedBy?.initials }}
                  </span>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="model">
                <mat-header-cell *matHeaderCellDef mat-sort-header
                  >Model</mat-header-cell
                >
                <mat-cell class="wrap" *matCellDef="let element">
                  {{ element.radMeterModel?.name }}
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="type">
                <mat-header-cell *matHeaderCellDef mat-sort-header
                  >Type</mat-header-cell
                >
                <mat-cell class="wrap" *matCellDef="let element">
                  {{ element.radMeterType?.name }}
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="unit">
                <mat-header-cell *matHeaderCellDef mat-sort-header
                  >Units</mat-header-cell
                >
                <mat-cell *matCellDef="let element" nowrap>
                  {{ element.radMeterUnit?.name }}
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="sourceReading">
                <mat-header-cell *matHeaderCellDef mat-sort-header
                  >Source Reading</mat-header-cell
                >
                <mat-cell *matCellDef="let element" nowrap class="pr-2">
                  <span *ngIf="!canModify()" class="bold">{{
                    element.sourceReading
                  }}</span>
                  <span
                    *ngIf="canModify() && sourceReadingChanging != element.id"
                    matTooltip="Click to change Source Reading"
                    ><mat-label
                      [ngClass]="{
                        link: element.sourceReading,
                        placeholder: !element.sourceReading
                      }"
                      (click)="changeSourceReading(element)"
                      >{{
                        element.sourceReading ? element.sourceReading : space
                      }}</mat-label
                    ></span
                  >
                  <span
                    *ngIf="canModify() && sourceReadingChanging == element.id"
                  >
                    <mat-form-field class="w-120">
                      <mat-label>Source Reading</mat-label>
                      <input
                        aria-label="source"
                        matInput
                        type="text"
                        [disabled]="disabled ?? false"
                        [(ngModel)]="element.sourceReading"
                        (blur)="textSourceChanged(element)"
                      />
                    </mat-form-field>
                  </span>
                  <span
                    class="initials"
                    *ngIf="!sourceReadingChanging"
                    [matTooltip]="
                      element.sourceReadingChangedBy?.name +
                      ' on ' +
                      (element.sourceReadingChangedOn | date)
                    "
                  >
                    {{ element.sourceReadingChangedBy?.initials }}
                  </span>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="backgroundReading">
                <mat-header-cell *matHeaderCellDef mat-sort-header
                  >Background Reading</mat-header-cell
                >
                <mat-cell *matCellDef="let element" nowrap>
                  <span *ngIf="!canModify()" class="pr-2 w-120">{{
                    element.backgroundReading
                  }}</span>
                  <span
                    *ngIf="
                      canModify() && backgroundReadingChanging != element.id
                    "
                    matTooltip="Click to change Background Reading"
                    ><mat-label
                      [ngClass]="{
                        link: element.backgroundReading,
                        placeholder: !element.backgroundReading
                      }"
                      (click)="changeBackgroundReading(element)"
                      >{{
                        element.backgroundReading
                          ? element.backgroundReading
                          : space
                      }}</mat-label
                    ></span
                  >
                  <span
                    *ngIf="
                      canModify() && backgroundReadingChanging == element.id
                    "
                  >
                    <mat-form-field class="w-120">
                      <mat-label>Background Reading</mat-label>
                      <input
                        aria-label="background"
                        matInput
                        type="text"
                        [disabled]="disabled ?? false"
                        [(ngModel)]="element.backgroundReading"
                        (blur)="textBackgroundChanged(element)"
                      />
                    </mat-form-field>
                  </span>
                  <span
                    class="initials"
                    *ngIf="!backgroundReadingChanging"
                    [matTooltip]="
                      element.backgroundReadingChangedBy?.name +
                      ' on ' +
                      (element.backgroundReadingChangedOn | date)
                    "
                  >
                    {{ element.backgroundReadingChangedBy?.initials }}
                  </span>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="actions" *ngIf="canModify()">
                <mat-header-cell *matHeaderCellDef>
                  <button
                    class="add"
                    mat-icon-button
                    (click)="addMeter()"
                    [matTooltip]="'Add Meter'"
                  >
                    <mat-icon>add</mat-icon>
                  </button>
                </mat-header-cell>
                <mat-cell *matCellDef="let element">
                  <div *ngIf="canModify()">
                    <button
                      mat-icon-button
                      color="warn"
                      [matTooltip]="'Delete Meter'"
                      (click)="deleteRowMeter(element)"
                    >
                      <mat-icon class="icon">delete</mat-icon>
                    </button>
                  </div>
                </mat-cell>
              </ng-container>

              <mat-header-row
                *matHeaderRowDef="displayedColumns"
              ></mat-header-row>
              <mat-row
                #matrow
                *matRowDef="let row; columns: displayedColumns"
                class="table-row"
                style="white-space: nowrap"
              ></mat-row>
            </mat-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row" *ngIf="editor">
  <div class="col-4">
    <button mat-flat-button [color]="color" (click)="addMeter()">
      Add Meter
    </button>
  </div>
</div>

import { Injectable, Injector } from "@angular/core";
import { BehaviorSubject, Observable, Subscription } from "rxjs";
import { CdkDragMove } from '@angular/cdk/drag-drop';
import { utils } from "src/app/modules/libs/utils";
import { TokenInfoService } from "src/app/services/authentication/token-info.service";
import { FormStatus } from "src/app/services/forms/form-status";
import { ProcedureSignatureType } from "src/app/components/procedure/enums/procedure-signature-type.enum";
import { ProcedureSignature } from "src/app/components/procedure/models/procedure-signature.model";
import { Procedure } from "src/app/components/procedure/models/procedure.model";
import { Resource } from "src/app/components/catalogs/beamline-catalog/resource/resources";
import { WFSectionLocalResource, WFStepLocalResource, WfTaskLocalResource, WfTaskResource, WfActionLocalResource, WFRoleLocal, WfSignature } from "src/app/services/work-flow/work-flow";
import { ScheduleStatusEnum } from "../../schedules/models/enums";
import { Schedule } from "../../schedules/models/schedule";
import { ScheduleResources } from "../../schedules/models/schedule-resources";
import { ScheduleSubtype } from "../../schedules/models/schedule-subtype";
import { ScheduleType } from "../../schedules/models/schedule-type";
import { ChecklistTemplateStatus, TemplateType, TemplateRolePermission, ChecklistTemplate, ChecklistExecStatus, ChecklistPriv, ChecklistTemplateStatusEnum, RoleOption, Option } from "../checklists";
import { ProcedureService } from "src/app/components/procedure/services/procedure.service";
import { UserRole } from "src/app/services/user-roles/user-role";
import { Action, ComponentType } from "src/app/common/enumerations/enumerations";
import { DocumentType } from "src/app/services/documents/documents";
import { BaseService } from "src/app/common/base/base.service";
import { ChecklistExecComponent } from "../checklist-exec/checklist-exec.component";

@Injectable()
export class ChecklistBuilderService extends BaseService<ChecklistTemplate, number> {
  Api = "/ChecklistBuilder";
  ApiTT = "/ChecklistBuilder/TemplateType";
  ApiStatus = "/ChecklistTemplateStatus";
  ApiKE = "/KEBuilder";
  ApiChecklistExecStatus = "/ChecklistExecStatus";

  public checklistTemplateStatuses: ChecklistTemplateStatus[] = [];
  procedure: BehaviorSubject<Procedure | null>;

  procedures!: Procedure[];
  procedures$!: Observable<Procedure[]>;
  subsProcedures!: Subscription;
  procedureTypeID!: number | null;

  scheduleType!: ScheduleType;
  scheduleTypes!: ScheduleType[];
  scheduleTypes$!: Observable<ScheduleType[]>;
  scheduleTypesSubs!: Subscription;

  scheduleSubtype!: ScheduleSubtype;
  scheduleSubtypes!: ScheduleSubtype[];
  scheduleSubtypes$!: Observable<ScheduleSubtype[]>;
  scheduleSubtypesSubs!: Subscription;

  subsAppState!: Subscription;

  preparers?: ProcedureSignature[];
  reviewers?: ProcedureSignature[];
  approvers?: ProcedureSignature[];

  pointerY!: number;

  currentTemplateType?: TemplateType | null;
  currentSchedule?: Schedule | null;
  documentRolePermissions!: TemplateRolePermission[];
  currentTemplate?: BehaviorSubject<ChecklistTemplate | null | undefined>;// ChecklistTemplate | null;
  currentTemplateValue?: ChecklistTemplate | null;
  checklistTemplates?: ChecklistTemplate[];

  constructor(
    protected override injector: Injector,
    private _token: TokenInfoService,
    private _procedure: ProcedureService,
  ) {
    super(injector, 'ChecklistBuilder');
    this.procedure = new BehaviorSubject<Procedure | null>(null);
    this.currentTemplate = new BehaviorSubject<ChecklistTemplate | null | undefined>(null);// ChecklistTemplate | null;
  }

  getProcedure(): Observable<Procedure | null> {
    return this.procedure.asObservable();
  }

  setProcedure(value: Procedure | null) {
    const procedure = this.procedures?.find((x) => x.id == value?.id);
    if (procedure)
      this.procedure.next(value);
  }

  setChecklistTemplate(checklistTemplate?: ChecklistTemplate | null) {
    this.currentTemplateValue = checklistTemplate;
    this.currentTemplate?.next(checklistTemplate);
  }

  getChecklistTemplate() {
    return this.currentTemplate?.asObservable();
  }

  getchecklistTemplateById(id: number): Observable<ChecklistTemplate> {
    const httpresult = this.http.get<ChecklistTemplate>(
      this.BASE_URL + this.Api + "/" + id
    );
    return httpresult;
  }

  createChecklistTemplate(
    checklistTemplate: ChecklistTemplate
  ): Observable<ChecklistTemplate> {
    return this.http.post<ChecklistTemplate>(
      this.BASE_URL + this.Api,
      checklistTemplate
    );
  }

  duplicateChecklistTemplate(
    checklistTemplateToDuplicate: ChecklistTemplate,
    checklistTemplate: ChecklistTemplate
  ): Observable<ChecklistTemplate> {
    return this.http.post<ChecklistTemplate>(this.BASE_URL + this.Api + "/Duplicate/" + checklistTemplateToDuplicate.id, checklistTemplate);
  }

  updateChecklistTemplate(
    checklistTemplate: ChecklistTemplate
  ): Observable<ChecklistTemplate> {
    return this.http.put<ChecklistTemplate>(
      this.BASE_URL + this.Api,
      checklistTemplate
    );
  }

  deleteChecklistTemplate(
    checklistTemplate: ChecklistTemplate,
    text: string
  ): Observable<ChecklistTemplate> {
    return this.http.delete<any>(
      this.BASE_URL + this.Api + "/" + checklistTemplate.id + "/" + text
    );
  }

  getChecklistTemplatesByTypeAndResource(
    typeId: number,
    resourceId: number
  ): Observable<ChecklistTemplate[]> {
    const httpresult = this.http.get<ChecklistTemplate[]>(
      this.BASE_URL +
      this.Api +
      "/TemplatesByTypeAndResource/" +
      typeId +
      "/" +
      resourceId
    );
    return httpresult;
  }

  getChecklistTemplatesByDocumentTypeID(
    documentTypeID: number
  ): Observable<ChecklistTemplate[]> {
    return this.http.get<ChecklistTemplate[]>(
      this.BASE_URL + this.Api + "/ByDocumentType/" + documentTypeID.toString()
    );
  }

  // Template Types

  getAllTemplateTypes(): Observable<TemplateType[]> {
    const httpresult = this.http.get<TemplateType[]>(
      this.BASE_URL + this.Api + "/AllTemplateTypes"
    );
    return httpresult;
  }

  getConfigTemplateTypes(): Observable<TemplateType[]> {
    const httpresult = this.http.get<TemplateType[]>(
      this.BASE_URL + this.Api + "/ConfigTemplateTypes"
    );
    return httpresult;
  }

  getTemplateTypesByDocumentId(id: number): Observable<TemplateType[]> {
    const httpresult = this.http.get<TemplateType[]>(
      this.BASE_URL + this.Api + "/TemplateTypesByDocument/" + id
    );
    return httpresult;
  }

  createTemplateType(templateType: TemplateType): Observable<TemplateType> {
    return this.http.post<TemplateType>(
      this.BASE_URL + this.ApiTT,
      templateType
    );
  }

  updateTemplateType(templateType: TemplateType): Observable<TemplateType> {
    return this.http.put<TemplateType>(
      this.BASE_URL + this.ApiTT,
      templateType
    );
  }

  deleteTemplateType(
    templateType: TemplateType,
    text: string
  ): Observable<TemplateType> {
    return this.http.delete<any>(
      this.BASE_URL + this.ApiTT + "/" + templateType.id + "/" + text
    );
  }

  getTemplateType(id: number): Observable<TemplateType> {
    return this.http.get<TemplateType>(
      this.BASE_URL + this.Api + "/TemplateType/" + id
    );
  }

  // Permissions

  createTemplateRolePersmission(
    templateRolePermissions: TemplateRolePermission
  ): Observable<TemplateRolePermission> {
    return this.http.post<TemplateRolePermission>(
      this.BASE_URL + this.Api + "/CreateTemplateRolePermission",
      templateRolePermissions
    );
  }

  deleteTemplateRolePermission(templateRolePermission: TemplateRolePermission) {
    return this.http.delete<any>(
      this.BASE_URL +
      this.Api +
      "/DeleteTemplateRolePermission/" +
      templateRolePermission.id
    );
  }

  getTemplateRolePermissionsByDocumentTypeID(
    id: number
  ): Observable<TemplateRolePermission[]> {
    return this.http.get<TemplateRolePermission[]>(
      this.BASE_URL + this.Api + "/TemplateRolePermissionByDocumentID/" + id
    );
  }

  // Sections
  updateSection(
    section: WFSectionLocalResource
  ): Observable<WFSectionLocalResource> {
    return this.http.put<WFSectionLocalResource>(
      this.BASE_URL + this.Api + "/UpdateSection",
      section
    );
  }

  updateSections(
    sections: WFSectionLocalResource[]
  ): Observable<WFSectionLocalResource> {
    return this.http.put<WFSectionLocalResource>(
      this.BASE_URL + this.Api + "/UpdateSections",
      sections
    );
  }

  createSection(wfTableID: number): Observable<WFSectionLocalResource> {
    return this.http.post<WFSectionLocalResource>(
      this.BASE_URL + this.Api + "/CreateSection",
      wfTableID
    );
  }

  duplicateSection(wfSectionLocalID: number): Observable<WFSectionLocalResource> {
    return this.http.post<WFSectionLocalResource>(this.BASE_URL + this.Api + "/DuplicateSection", wfSectionLocalID);
  }

  deleteSection(section: WFSectionLocalResource) {
    return this.http.delete<any>(
      this.BASE_URL + this.Api + "/DeleteSection/" + section.id
    );
  }

  getDeletedSections(checklistTemplateID: number) {
    return this.http.get<WFSectionLocalResource[]>(
      this.BASE_URL + this.Api + "/DeletedSections/" + checklistTemplateID
    );
  }

  restoreSection(logID: number) {
    return this.http.patch<WFSectionLocalResource>(
      this.BASE_URL + this.Api + "/RestoreSection/" + logID, null);
  }

  // Steps
  updateStep(step: WFStepLocalResource): Observable<WFStepLocalResource> {
    return this.http.put<WFStepLocalResource>(
      this.BASE_URL + this.Api + "/UpdateStep",
      step
    );
  }

  updateSteps(steps: WFStepLocalResource[]): Observable<WFStepLocalResource> {
    return this.http.put<WFStepLocalResource>(
      this.BASE_URL + this.Api + "/UpdateSteps",
      steps
    );
  }

  createStep(wfSectionLocalID: number): Observable<WFStepLocalResource> {
    return this.http.post<WFStepLocalResource>(
      this.BASE_URL + this.Api + "/CreateStep",
      wfSectionLocalID
    );
  }

  duplicateStep(wfStepLocalID: number): Observable<WFStepLocalResource> {
    return this.http.post<WFStepLocalResource>(this.BASE_URL + this.Api + "/DuplicateStep", wfStepLocalID);
  }

  deleteStep(step: WFStepLocalResource) {
    return this.http.delete<WFStepLocalResource>(
      this.BASE_URL + this.Api + "/DeleteStep/" + step.id
    );
  }

  restoreStep(logID: number) {
    return this.http.patch<WFStepLocalResource>(
      this.BASE_URL + this.Api + "/RestoreStep/" + logID, null);
  }

  getDeletedSteps(checklistTemplateID: number) {
    return this.http.get<WFStepLocalResource[]>(
      this.BASE_URL + this.Api + "/DeletedSteps/" + checklistTemplateID
    );
  }

  // Tasks
  getWFTaskLocal(wfTaskLocalID: number): Observable<WfTaskLocalResource> {
    return this.http.get<WfTaskLocalResource>(
      this.BASE_URL + this.Api + "/GetTaskLocal/" + wfTaskLocalID
    );
  }

  getTasksByWFStepLocalID(
    wfStepLocalID: number
  ): Observable<WfTaskLocalResource[]> {
    return this.http.get<WfTaskLocalResource[]>(
      this.BASE_URL + this.Api + "/GetTasksByWFStepLocalID/" + wfStepLocalID
    );
  }

  updateTask(task: WfTaskLocalResource): Observable<WfTaskLocalResource> {
    return this.http.put<WfTaskLocalResource>(
      this.BASE_URL + this.Api + "/UpdateTask",
      task
    );
  }

  createEmptyTask(wfStepLocalID: number): Observable<WfTaskLocalResource> {
    return this.http.post<WfTaskLocalResource>(
      this.BASE_URL + this.Api + "/CreateEmptyTask",
      wfStepLocalID
    );
  }

  createTask(wfTask: WfTaskLocalResource): Observable<WfTaskLocalResource> {
    return this.http.post<WfTaskLocalResource>(
      this.BASE_URL + this.Api + "/CreateTask",
      wfTask
    );
  }

  duplicateTask(wfTaskLocalID: number): Observable<WfTaskLocalResource> {
    return this.http.put<WfTaskLocalResource>(
      this.BASE_URL + this.Api + "/DuplicateTask/" + wfTaskLocalID, null
    );
  }

  deleteTask(wfTask: WfTaskLocalResource) {
    return this.http.delete<WfTaskLocalResource>(
      this.BASE_URL + this.Api + "/DeleteTask/" + wfTask.id
    );
  }

  restoreTask(logID: number) {
    return this.http.patch<WfTaskLocalResource>(
      this.BASE_URL + this.Api + "/RestoreTask/" + logID, null);
  }

  getDeletedTasks(checklistTemplateID: number) {
    return this.http.get<WfTaskLocalResource[]>(
      this.BASE_URL + this.Api + "/DeletedTasks/" + checklistTemplateID
    );
  }

  saveRequiredById(
    wfTaskId: number,
    required: boolean
  ): Observable<WfTaskLocalResource> {
    return this.http.patch<WfTaskLocalResource>(
      this.BASE_URL + this.Api + "/SaveRequired/" + wfTaskId + "/" + required,
      null
    );
  }

  saveRequired(wfTask: WfTaskResource): Observable<WfTaskLocalResource> {
    return this.http.patch<WfTaskLocalResource>(
      this.BASE_URL + this.Api + "/SaveRequired",
      wfTask
    );
  }

  saveOrder(wfTasks: WfTaskLocalResource[], refresh?: boolean): Observable<WfTaskLocalResource[]> {
    return this.http.patch<WfTaskLocalResource[]>(
      this.BASE_URL + this.Api + "/SaveOrder/" + (refresh ?? false), wfTasks);
  }

  // Status

  createChecklistExecStatus(c: ChecklistExecStatus) {
    return this.http.post<ChecklistExecStatus>(
      this.BASE_URL + this.ApiChecklistExecStatus,
      c
    );
  }

  updateChecklistExecStatus(c: ChecklistExecStatus) {
    return this.http.put<ChecklistExecStatus>(
      this.BASE_URL + this.ApiChecklistExecStatus + "/" + c.id,
      c
    );
  }

  deleteChecklistExecStatus(c: ChecklistExecStatus) {
    return this.http.delete<ChecklistExecStatus>(
      this.BASE_URL + this.ApiChecklistExecStatus + "/" + c.id
    );
  }

  // Other

  CreateWFActionLocal(wfActionLocalResource: WfActionLocalResource) {
    return this.http.post<WfActionLocalResource>(
      this.BASE_URL + this.ApiKE + "/WFAction",
      wfActionLocalResource
    );
  }

  UpdateWFActionLocal(wfActionLocalResource: WfActionLocalResource) {
    return this.http.put<WfActionLocalResource>(
      this.BASE_URL + this.ApiKE + "/WFAction",
      wfActionLocalResource
    );
  }

  DeleteWFActionLocal(wfActionLocalResource: WfActionLocalResource) {
    return this.http.delete(
      this.BASE_URL + this.ApiKE + "/WFAction/" + wfActionLocalResource.id
    );
  }

  CreateWFRoleLocal(wfRoleLocal: WFRoleLocal) {
    return this.http.post<WfActionLocalResource>(
      this.BASE_URL + this.ApiKE + "/WFRole",
      wfRoleLocal
    );
  }

  DeleteWFRoleLocal(id: number) {
    return this.http.delete(this.BASE_URL + this.ApiKE + "/WFRole/" + id);
  }

  // General Methods for ALL Checklists

  createWFTaskLocal(data: any): Observable<WfTaskLocalResource> {
    const t = this.createWFTaskLocalResource(data);
    return this.createTask(t);
  }

  updateWFTaskLocal(data: any): Observable<WfTaskLocalResource> {
    const t = this.createWFTaskLocalResource(data);
    return this.updateTask(t);
  }

  createWFTaskLocalResource(data: any): WfTaskLocalResource {
    const s: WfSignature = data.s;
    const wfTaskLocalResource: WfTaskLocalResource = {
      id: s.id,
      name: s.type === 7 ? "Note:" : (s.name ?? ''),
      name2: s.question2,
      name3: s.question,
      name4: s.question3,
      scheduleResourceId: s.scheduleResourceId,
      scheduleType: s.scheduleTypeId,
      resourceId: s.resourceId,
      resourceName: s.locationName,
      type: s.type,
      configuration: s.configuration,
      options: JSON.stringify(s.options),
      code: s.code,
      condition: s.condition,
      componentID: s.type,
      wfActionLocals: this.actions(s),
      wfRoleLocals: this.roles(s, data.roles),
      order: s.order,
      wfStepLocalID: s.stepId,
      component: s.component,
    };
    return wfTaskLocalResource;
  }

  roles(s: WfSignature, roles: UserRole[]): WFRoleLocal[] {
    const wfRoleLocals: WFRoleLocal[] = [];
    roles?.map((r) => {
      const wfRoleLocal = {
        roleID: r.roleID ? r.roleID : r.id,
      };
      wfRoleLocals.push(wfRoleLocal as WFRoleLocal);
    });
    return wfRoleLocals;
  }

  actions(s: WfSignature) {
    const actions: WfActionLocalResource[] = [];
    let action: WfActionLocalResource;
    const configuration = utils.JSONparse(s.configuration);
    if (s.approve) {
      action = {
        id: s.approveId,
        wfTaskLocalID: s.id,
        name: "Approve",
        label: "Approve",
        action: Action.Approve,
        nextStatusID: configuration?.status
          ? configuration.status
          : configuration?.approveNextStatus,
      };
      actions.push(action);
    }
    if (s.unapprove) {
      action = {
        id: s.unapproveId,
        wfTaskLocalID: s.id,
        name: "Unapprove",
        label: "Unapprove",
        action: Action.Unapprove,
        nextStatusID: configuration?.unapproveNextStatus,
      };
      actions.push(action);
    }
    if (s.disapprove) {
      action = {
        id: s.disapproveId,
        wfTaskLocalID: s.id,
        name: "Disapprove",
        label: "Disapprove",
        action: Action.Disapprove,
        nextStatusID: configuration?.disapproveNextStatus,
      };
      actions.push(action);
    }
    return actions;
  }

  getStatuses() {
    return this.http.get<ChecklistTemplateStatus[]>(
      this.BASE_URL + this.ApiStatus
    );
  }

  getFormStatuses() {
    return this.http.get<FormStatus[]>(
      this.BASE_URL + this.ApiStatus + "/FormStatus"
    );
  }

  getNewChecklistTemplate(documentType: DocumentType | undefined, templateType: TemplateType | undefined | null, schedule: Schedule | undefined | null, resource: Resource | undefined | null, procedure?: Procedure | null, name?: string): ChecklistTemplate {
    let scheduleResource: ScheduleResources | null | undefined = null;
    if (templateType?.scheduleTypeID) {
      scheduleResource = schedule?.scheduleResources.find((x) => (x.resourceId ?? 0) > 0);
      if (!scheduleResource) {
        scheduleResource = schedule?.scheduleResources[0];
      }
    } else if (resource) {
      scheduleResource = {
        resource,
        resourceId: resource?.id,
      };
    }
    const checklistTemplate: ChecklistTemplate = {
      id: 0,
      status: 0,
      name,
      wfTableID: 0,
      documentTypeID: documentType?.id,
      templateTypeID: templateType?.id,
      resourceID: scheduleResource?.resourceId
        ? scheduleResource.resourceId
        : resource?.id,
      resourceString: scheduleResource?.resourceString
        ? scheduleResource.resourceString
        : null,
      procedureMasterID: procedure?.procedureMasterID,
      procedureID: this.getProcedureByMasterID(procedure?.procedureMasterID ?? 0)
        ?.id,
      scheduleID: schedule?.id
    };
    return checklistTemplate;
  }

  getProcedureByMasterID(id: number): Procedure | undefined {
    const procedure = this.procedures?.find(
      (x) => x.procedureMasterID == id && x.active && x.status == 1
    );
    this.preparers = procedure?.procedureSignatures?.filter(
      (x) => x.signatureType == ProcedureSignatureType.Preparer
    );
    this.reviewers = procedure?.procedureSignatures?.filter(
      (x) => x.signatureType == ProcedureSignatureType.Reviewer
    );
    this.approvers = procedure?.procedureSignatures?.filter(
      (x) => x.signatureType == ProcedureSignatureType.Approver
    );
    return procedure;
  }

  loadProcedures() {
    this.procedures$ = this.store.select((state) => state.Procedures.data);
    this.subsProcedures = this.procedures$.subscribe((data) => {
      this.procedures = data;
      const procedure = this.getProcedureValue();
      if (procedure) {
        const p = this.procedures.find((x) => x.id == procedure.id)
        if (p)
          this.setProcedure(p);
      }
    });
  }

  loadScheduleTypes() {
    this.scheduleTypes$ = this.store.select(
      (state) => state.ScheduleTypes.data
    );
    this.scheduleTypesSubs = this.scheduleTypes$.subscribe((data) => {
      this.scheduleTypes = data;
    });
  }

  loadScheduleSubtypes() {
    this.scheduleSubtypes$ = this.store.select(
      (state) => state.ScheduleSubtypes.data
    );
    this.scheduleSubtypesSubs = this.scheduleSubtypes$.subscribe((data) => {
      this.scheduleSubtypes = data;
    });
  }

  getProcedureValue(): Procedure | null {
    let procedure = null;
    this.getProcedure()
      .toPromise()
      .then((value) => {
        procedure = value;
      });
    return procedure;
  }

  is(signatureType: ProcedureSignatureType) {
    this.procedureTypeID = null;
    const checklistPriv =
      signatureType == ProcedureSignatureType.Preparer
        ? ChecklistPriv.Prepare
        : signatureType == ProcedureSignatureType.Reviewer
          ? ChecklistPriv.Review
          : signatureType == ProcedureSignatureType.Approver
            ? ChecklistPriv.Approve
            : null;
    let result = false;
    let procedure: Procedure | null | undefined = this.getProcedureValue();
    if (this.currentTemplateValue) {
      procedure = this.currentTemplateValue.procedure;
    }

    if (procedure) {
      const users = procedure.procedureSignatures?.filter(
        (x) => x.signatureType == signatureType
      );
      result = users?.map((x) => x.userID).includes(this.currentUser?.id ?? 0) ?? false;
    } else {
      const interD = utils.intersect(
        this.documentRolePermissions
          ?.filter((x) => x.checklistPrivilege == checklistPriv)
          .map((x) => x.roleID),
        this.currentUser?.userRole?.map((x) => x.roleID)
      );
      const canApprove =
        ((this.currentTemplateType?.samePersonCanApprove ||
          (!this.currentTemplateType?.samePersonCanApprove &&
            this.currentUser?.id != this.currentTemplateValue?.updatedBy)) &&
          signatureType == ProcedureSignatureType.Approver) ||
        signatureType != ProcedureSignatureType.Approver;
      result = interD && canApprove;
    }
    // }
    return result;
  }

  filterSchedulesByTemplateType(schedules: Schedule[], templateType?: TemplateType | null) {
    let filteredSchedules: Schedule[] = [];
    if (schedules) {
      const hideCustomSchedules = templateType?.hideCustomSchedules;
      const scheduleSubtypeID = templateType?.scheduleSubtypeID;
      if (scheduleSubtypeID && scheduleSubtypeID > 0) {
        filteredSchedules = schedules.filter(
          (x) =>
            x.typeId == templateType?.scheduleTypeID &&
            x.scheduleSubtypeId == scheduleSubtypeID
        );
        if (filteredSchedules.length == 0) {
          const firstScheduleSubtype = templateType.scheduleType?.scheduleSubtypes[0];
          if (firstScheduleSubtype?.id == scheduleSubtypeID) {
            filteredSchedules = schedules.filter(
              (x) => x.typeId == templateType?.scheduleTypeID
            );
          }
        }
      } else if (scheduleSubtypeID && scheduleSubtypeID < 0) {
        filteredSchedules = schedules.filter(
          (x) => x.isActive && x.typeId == 5 && x.statusId == 1 && x.subdetail
        );
      } else if (templateType?.scheduleTypeID && !scheduleSubtypeID) {
        filteredSchedules = schedules.filter(
          (x) =>
            (x.procedure?.procedureCategoryID == 19 && templateType.id == 32) ||
            (x.procedure?.procedureCategoryID == 23 && templateType.id == 31) ||
            (x.procedure?.procedureCategoryID == 24 && templateType.id == 33)
        );
      } else {
        filteredSchedules = schedules.filter(
          (x) => x.typeId == templateType?.scheduleTypeID
        );
      }
      filteredSchedules = filteredSchedules.filter(
        (x) =>
          x.statusId == ScheduleStatusEnum.new ||
          x.statusId == ScheduleStatusEnum.extensionGranted ||
          x.statusId == ScheduleStatusEnum.refuseExtension ||
          x.statusId == ScheduleStatusEnum.requestExtension
      );
      if (this.getProcedure()) {
        filteredSchedules = filteredSchedules.filter(
          (x) => x.procedureMasterID
        );
      }
      if (hideCustomSchedules) {
        filteredSchedules = filteredSchedules.filter(
          (x) => x.scheduleResources.filter((s) => (s.resourceId ?? 0) > 0).length > 0
        );
      }
      filteredSchedules.map((schedule) => {
        schedule.name = schedule.scheduleResources
          .map((val) => {
            const names = [];
            if (val.resourceId == null && !val.resourceString?.includes("(EC")) {
              names.push(val.resourceString);
            } else {
              if (val.resource) {
                names.push(val.resource.name);
              }
            }
            return names;
          }).join("-");
        schedule.description = schedule.scheduleResources
          .map((val) => {
            const descs = [];
            if (val.resourceId == null && !val.resourceString?.includes("(EC")) {
              descs.push(val.resourceString);
            } else {
              if (val.resource) {
                descs.push(val.resource.description);
              }
            }
            return descs;
          }).join("");
      });
      filteredSchedules.map(s => s.objectInfo = this.hasTemplatesInProgress(s));
      filteredSchedules = filteredSchedules.sort((a, b) =>
        utils.sortArrayAlphabeticallyWithComplexNumbers(a.name, b.name)
      );
    }
    return filteredSchedules;
  }

  hasTemplatesInProgress(s: Schedule) {
    let checkSchedule = false;
    let checkProcedure = false;
    const checklistTemplate = this.checklistTemplates?.find(t => t.schedule?.id == s.id);
    if (checklistTemplate) {
      checkSchedule = ![ChecklistTemplateStatusEnum.Active, ChecklistTemplateStatusEnum.Archived].includes(checklistTemplate.status as number);
    }
    return checkSchedule || checkProcedure;
  }

  setResources(checklistTemplates: ChecklistTemplate[] | undefined, templateTypes: TemplateType[], schedules: Schedule[]) {
    this.checklistTemplates = checklistTemplates;
    checklistTemplates?.map(async (c) => {
      c.templateType = templateTypes?.find((x) => x.id == c.templateTypeID);
      if (c.templateType?.scheduleTypeID) {
        if (schedules?.length > 0) {
          let schedule = null;
          if (c.templateType.scheduleSubtypeID != -1) {
            schedule = schedules.find(
              (x) =>
                ((x.scheduleResources
                  .map((r) => r.resourceId)
                  .includes(c.resourceID) &&
                  c.resourceID) ||
                  (c.resourceString &&
                    x.scheduleResources
                      .map((r) => r.resourceString)
                      .includes(c.resourceString))) &&
                x.typeId == c.templateType?.scheduleTypeID &&
                (c.templateType?.scheduleSubtypeID
                  ? x.scheduleSubtypeId == c.templateType.scheduleSubtypeID
                  : true)
            );
            c.schedule = schedule;
            c.scheduleID = schedule?.id;
            if (!c.procedureID && c.schedule?.procedure) {
              await this.updateProcedure(c, c.schedule.procedure);
              c.procedure = c.schedule.procedure;
            }
            else if (c.procedureID) {
              c.procedure = this.procedures?.find(x => x.id == c.procedureID);
            }
          } else {
            schedule = schedules.find(
              (x) =>
                x.subdetail &&
                x.typeId == c.templateType?.scheduleTypeID &&
                ((x.scheduleResources
                  .map((r) => r.resourceId)
                  .includes(c.resourceID) &&
                  c.resourceID != null) ||
                  (c.resourceString != null &&
                    x.scheduleResources
                      .map((r) => r.resourceString)
                      .includes(c.resourceString)))
            );
            c.schedule = schedule;
            if (!c.procedureID && c.schedule?.subdetail?.procedure) {
              await this.updateProcedure(c, c.schedule.subdetail.procedure);
              c.procedure = c.schedule.subdetail.procedure;
            }
            else if (c.procedureID) {
              c.procedure = this.procedures.find(x => x.id == c.procedureID);
            }
          }
          c.procedureMasterID = c.procedure?.procedureMasterID;
          const resource = schedule?.scheduleResources.find(
            (x) => (x.resourceId ?? 0) > 0
          );
          if (
            resource?.resource &&
            (!c.resourceID ||
              !c.resourceString ||
              c.resourceID != resource.resourceId)
          ) {
            await this.updateResource(c, resource.resource);
          }
        }
      } else {
        if (c.resourceID) {
          c.resourceString = c.resource?.name;
        } else if (c.templateType?.allowMultipleVersions) {
          c.resourceString = c.name;
        }
      }
      if (!c.procedure && c.procedureMasterID) {
        const procedure = this.getProcedureByMasterID(c.procedureMasterID);
        if (procedure) {
          await this.updateProcedure(c, procedure);
          c.procedure = procedure;
        }
      }
    });
    this.checklistTemplates = checklistTemplates;
    return checklistTemplates;
  }

  async updateProcedure(c: ChecklistTemplate, p: Procedure) {
    if (!c.procedureID && p) {
      c.procedureID = p?.id;
      c.procedureMasterID = p?.procedureMasterID;
      this.updateTemplate(c);
    }
  }

  async updateResource(c: ChecklistTemplate, r: Resource) {
    c.resourceID = r.id;
    c.resourceString = r.name;
    this.updateTemplate(c);
  }

  async updateTemplate(c: ChecklistTemplate) {
    this.updateChecklistTemplate(utils.Serialize(c)).toPromise();
  }

  userCanBeAdded(
    procedure: Procedure,
    procedureSignatureType: ProcedureSignatureType
  ) {
    const privilege = this._procedure.getPrivilege(
      procedure?.procedureCategory?.procedureTypeID ?? 0,
      procedureSignatureType
    );
    const addedAlready = procedure?.procedureSignatures?.some((x) => x.userID === this.currentUser?.id);
    if (privilege) {
      const hasPrivilege = this._token.hasPrivilege(privilege);
      return (
        this.statusCheckAdd(procedureSignatureType) &&
        hasPrivilege &&
        (!addedAlready || this.isSubscribedAsApprover(procedure, procedureSignatureType))
      );
    } else {
      const roles =
        this.currentTemplateType?.documentType?.templateRolePermissions
          ?.filter(
            (x) =>
              (x.checklistPrivilege == 1 &&
                procedureSignatureType == ProcedureSignatureType.Preparer) ||
              (x.checklistPrivilege == 9 &&
                procedureSignatureType == ProcedureSignatureType.Approver)
          )
          .map((x) => x.roleID);
      const hasRole = utils.intersect(this.currentUser?.userRole?.map((x) => x.roleID), roles);
      return hasRole && !addedAlready;
    }
  }

  isSubscribedAsApprover(procedure: Procedure, procedureSignatureType: ProcedureSignatureType) {
    return procedure.procedureSignatures?.find(x => x.userID == this.currentUser?.id && x.signatureType == ProcedureSignatureType.Approver) && !this.isSubscribedAsPreparer(procedure, procedureSignatureType) && !this.isSubscribedAsReviewer(procedure, procedureSignatureType) && procedureSignatureType != ProcedureSignatureType.Approver;
  }

  isSubscribedAsPreparer(procedure: Procedure, procedureSignatureType: ProcedureSignatureType) {
    return procedure.procedureSignatures?.find(x => x.userID == this.currentUser?.id && x.signatureType == ProcedureSignatureType.Preparer) && procedureSignatureType == ProcedureSignatureType.Preparer;
  }

  isSubscribedAsReviewer(procedure: Procedure, procedureSignatureType: ProcedureSignatureType) {
    return procedure.procedureSignatures?.find(x => x.userID == this.currentUser?.id && x.signatureType == ProcedureSignatureType.Reviewer) && procedureSignatureType == ProcedureSignatureType.Reviewer;
  }

  otherUserCanBeAdded(
    procedure: Procedure,
    procedureSignatureType: ProcedureSignatureType
  ) {
    if (procedure?.procedureCategory?.procedureTypeID) {
      const privilege = this._procedure.getPrivilege(procedure?.procedureCategory?.procedureTypeID, procedureSignatureType);
      if (privilege) {
        return this.statusCheckAdd(procedureSignatureType);
      } else {
        const roles =
          this.currentTemplateType?.documentType?.templateRolePermissions
            ?.filter(
              (x) =>
                (x.checklistPrivilege == 1 &&
                  procedureSignatureType == ProcedureSignatureType.Preparer) ||
                (x.checklistPrivilege == 9 &&
                  procedureSignatureType == ProcedureSignatureType.Approver)
            )
            .map((x) => x.roleID);
        const hasRole =
          utils.intersect(this.currentUser?.userRole?.map((x) => x.roleID), roles);
        return hasRole;
      }
    }
    return false;
  }

  statusCheckAdd(procedureSignatureType: ProcedureSignatureType) {
    let statusCheck = false;
    switch (procedureSignatureType) {
      case ProcedureSignatureType.Preparer:
        statusCheck =
          this.currentTemplateValue?.status == ChecklistTemplateStatusEnum.Draft ||
          this.currentTemplateValue?.status ==
          ChecklistTemplateStatusEnum.CorrectionsInProgress ||
          this.currentTemplateValue?.status ==
          ChecklistTemplateStatusEnum.CorrectionsPending ||
          !this.currentTemplate;
        break;
      case ProcedureSignatureType.Reviewer:
        statusCheck =
          this.currentTemplateValue?.status == ChecklistTemplateStatusEnum.Draft ||
          this.currentTemplateValue?.status ==
          ChecklistTemplateStatusEnum.CorrectionsInProgress ||
          this.currentTemplateValue?.status ==
          ChecklistTemplateStatusEnum.CorrectionsPending ||
          this.currentTemplateValue?.status ==
          ChecklistTemplateStatusEnum.InReview ||
          this.currentTemplateValue?.status ==
          ChecklistTemplateStatusEnum.PendingRev ||
          this.currentTemplateValue?.status == ChecklistTemplateStatusEnum.Rejected;
        break;
      case ProcedureSignatureType.Approver:
        statusCheck =
          this.currentTemplateValue?.status == ChecklistTemplateStatusEnum.Draft ||
          this.currentTemplateValue?.status ==
          ChecklistTemplateStatusEnum.CorrectionsInProgress ||
          this.currentTemplateValue?.status ==
          ChecklistTemplateStatusEnum.CorrectionsPending ||
          this.currentTemplateValue?.status ==
          ChecklistTemplateStatusEnum.InReview ||
          this.currentTemplateValue?.status ==
          ChecklistTemplateStatusEnum.PendingRev ||
          this.currentTemplateValue?.status ==
          ChecklistTemplateStatusEnum.Rejected ||
          this.currentTemplateValue?.status ==
          ChecklistTemplateStatusEnum.PendingApr ||
          this.currentTemplateValue?.status ==
          ChecklistTemplateStatusEnum.Approving ||
          this.currentTemplateValue?.status ==
          ChecklistTemplateStatusEnum.Suggesting;
        break;
    }
    return statusCheck;
  }

  onDragMoved(e: CdkDragMove) {
    const headerH = 170;
    const scrollThreshold = 50;
    const scrollDistance = 50;
    const scrollableContainer = document.querySelector('.sidenav-content');
    const clientHeight = scrollableContainer?.clientHeight ?? 0;
    // console.log('pointer: ' + this.pointerY + ' - height: ' + (clientHeight - 50));
    if (this.pointerY > clientHeight - scrollThreshold + headerH)
      scrollableContainer?.scrollBy({ top: scrollDistance, left: 0 });
    if (this.pointerY < headerH + scrollThreshold)
      scrollableContainer?.scrollBy({ top: -scrollDistance, left: 0 });
  }

  hasErrors(task: WfTaskLocalResource, statuses?: any[]) {
    const errorMessages = [];
    let value = false;

    if (task) {
      switch (task.componentID) {
        case ComponentType.Note:
        case ComponentType.Subtitle:
        case ComponentType.TextNumericHead:
          value = false;
          break;

        case ComponentType.CheckboxMultipleSign:
        case ComponentType.ShieldingsComponent:
          value = (utils.JSONparse(task.options) as RoleOption[]).length == 0;
          if (value) errorMessages.push("No Roles were defined");
          break;

        case ComponentType.StatusChange:
          const statusId = utils.JSONparse(task.configuration)?.status;
          const status = statuses?.find((x) => x.id == statusId);
          value = !status;
          if (value) {
            errorMessages.push("Wrong status Setting!");
          }
          break;

        case ComponentType.CheckboxMultiple:
        case ComponentType.CheckboxAndRadio:
          const options = utils.JSONparse(task.options) as Option[];
          if (!options.length) {
            value = true;
            errorMessages.push("No Options were defined");
          };
          if (options.length && options.some(o => !o.value)) {
            value = true;
            errorMessages.push("No Values were defined for one or more Options");
          }
          break;

        case ComponentType.CheckboxMultipleWithTextbox:
          const optionsx = utils.JSONparse(task.options) as Option[];
          if (optionsx.length && optionsx.some(o => !o.value)) {
            value = true;
            errorMessages.push("No Values were defined for one or more Options");
          }
          break;

        case ComponentType.CheckboxRadioLocation:
          const options1 = utils.JSONparse(task.options) as Option[];
          if (!options1.length) {
            value = true;
            errorMessages.push("No Options were defined");
          };
          if (options1.length && options1.some(o => !o.value)) {
            value = true;
            errorMessages.push("No Values were defined for one or more Options");
          }
          const val = JSON.parse(task.configuration ?? '').val;
          if (!val) {
            value = true;
            errorMessages.push("No Location defined");
          }
          break;

        case ComponentType.BRATOI:
          const id = JSON.parse(task.configuration ?? '').idBRATOIRelated;
          value = !id;
          if (value) errorMessages.push("No BRATOI defined");
          break;

        case ComponentType.PlaceholderCondition:

          break;

        case ComponentType.Comments:
        case ComponentType.Catl:
          if (!task.wfRoleLocals?.length) {
            value = true;
            errorMessages.push("No Roles were defined");
          }
          break;

        default:
          if (!task.wfRoleLocals?.length) {
            value = true;
            errorMessages.push("No Roles were defined");
          }
          if (!task.wfActionLocals?.length) {
            value = true;
            errorMessages.push("No Actions were defined");
          }
          break;
      }
    }
    const result = { value, errors: errorMessages.join(", ") + "!" } as TaskError;
    return result;
  }

  checkExistingTemplates(checklistTemplate: ChecklistTemplate) {
    if (checklistTemplate.scheduleID && checklistTemplate?.procedureID) {
      const existingTemplate = this.checklistTemplates?.find(x => checklistTemplate?.procedureID && x.procedureID == checklistTemplate?.procedureID && x.status != ChecklistTemplateStatusEnum.Active && x.status != ChecklistTemplateStatusEnum.Archived) != null;
      const existingActiveTemplate = this.checklistTemplates?.find(x => checklistTemplate?.procedureID && x.procedureID == checklistTemplate?.procedureID && x.status == ChecklistTemplateStatusEnum.Active) != null;
      return existingTemplate || existingActiveTemplate;
    }
    return false;
  }
}

export interface TaskError {
  value: boolean;
  errors: string;
}

import { Component, Input, OnInit, Output, EventEmitter, OnDestroy, OnChanges, SimpleChanges, Injector } from '@angular/core';
import { Subscription, Observable } from 'rxjs';
import { ProcedureSignatureType } from 'src/app/components/procedure/enums/procedure-signature-type.enum';
import { ProcedureSignature } from 'src/app/components/procedure/models/procedure-signature.model';
import { Procedure } from 'src/app/components/procedure/models/procedure.model';
import { ProcedureService } from 'src/app/components/procedure/services/procedure.service';
import { RolePrivilege } from 'src/app/components/catalogs/roles/services/role-privilege';
import { TemplateType, ChecklistTemplate, ChecklistTemplateStatusEnum } from '../../../checklists';
import { ChecklistBuilderService } from '../../checklist-builder.service';
import { BaseComponent } from 'src/app/common/base/base.component';
import { UserAutocompletePopupComponent, UserAutocompletePopupParameters } from 'src/app/controls/user-autocomplete-popup/user-autocomplete-popup.component';

@Component({
  selector: 'checklist-builder-review-signatures',
  templateUrl: './checklist-builder-review-signatures.component.html',
  styleUrls: ['./checklist-builder-review-signatures.component.scss']
})
export class ChecklistBuilderReviewSignaturesComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {

  @Input() type!: ProcedureSignatureType;
  @Input() title!: string;
  @Input() procedure!: Procedure;
  @Input() templateType!: TemplateType;
  @Input() currentTemplate!: ChecklistTemplate;

  @Output() requestRefresh = new EventEmitter();

  signed!: boolean;
  signatures?: ProcedureSignature[];

  procedures!: Procedure[];
  procedures$!: Observable<Procedure[]>;
  proceduresSubs!: Subscription;

  rolePrivileges!: RolePrivilege[];
  rolePrivileges$!: Observable<RolePrivilege[]>;
  rolePrivilegesSubs!: Subscription;

  constructor(
    protected override injector: Injector,
    private procedureService: ProcedureService,
    private _builder: ChecklistBuilderService,
  ) {
    super(injector);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.loadProcedures();
    this.loadRolePrivileges();
  }

  override ngOnDestroy(): void {
    this.proceduresSubs?.unsubscribe();
    this.rolePrivilegesSubs?.unsubscribe();
    super.ngOnDestroy();
  }

  ngOnInit() {

  }

  loadProcedures() {
    this.procedures$ = this.store.select(state => state.Procedures.data);
    this.proceduresSubs = this.procedures$.subscribe(data => {
      this.procedures = data;
      if (this.procedure) {
        const procedure = this.procedures?.find(x => x.id == this.procedure?.id);
        if (procedure)
          this.procedure = procedure;
        this.signatures = this.procedure?.procedureSignatures?.filter(x => x.signatureType == this.type);
      }
    });
  }

  loadRolePrivileges() {
    this.rolePrivileges$ = this.store.select(state => state.RolePrivileges.data);
    this.rolePrivilegesSubs = this.rolePrivileges$.subscribe(data => {
      this.rolePrivileges = data;
    });
  }

  remove(signature: ProcedureSignature) {
    this.procedureService.deleteSignature(signature.id).toPromise().then(() => {
      const index = this.procedure?.procedureSignatures?.findIndex(x => x.id == signature.id) ?? -1;
      if (index >= 0) {
        this.procedure?.procedureSignatures?.splice(index, 1);
      }
      this._builder.setProcedure(this.procedure);
      this.requestRefresh.emit();
    });
  }

  async add() {
    if (this.getCurrentUser()?.id) {
      const user = this.getCurrentUser();

      if (!this.signatures) { this.signatures = []; }
      if (user?.id)
        await this.addUser(user.id);
    }
  }

  addOther() {
    const privilege = this.procedureService.getPrivilege(this.procedure?.procedureCategory?.procedureTypeID ?? 0, this.type);
    this.dialog.open(UserAutocompletePopupComponent, {
      width: '30vw',
      data: {
        placeholder: 'Select a user',
        selectedUsers: this.procedure?.procedureSignatures,
        privileges: [privilege],
      } as UserAutocompletePopupParameters,
    }).afterClosed().subscribe(async data => {
      if (data) {
        await this.addUser(data.user.id);
      }
    });
  }

  async addUser(userID: number) {
    this.procedureService.createSignature({
      id: 0,
      procedureID: this.procedure.id,
      signatureType: this.type,
      userID
    }).toPromise().then(
      data => {
        if (data && !this.procedure.procedureSignatures?.map(x => x.userID).includes(this.getCurrentUser()?.id ?? 0)) {
          this.procedure.procedureSignatures?.push(data);
        }
        this._builder.setProcedure(this.procedure);
        this.requestRefresh.emit();
      },
      error => console.log(error)
    );
  }

  userCanBeAdded() {
    return this._builder.userCanBeAdded(this.procedure, this.type);
  }

  otherUserCanBeAdded() {
    return this._builder.otherUserCanBeAdded(this.procedure, this.type);
  }

  isPreparer() {
    return this.procedure.procedureSignatures?.find(x => x.signatureType == ProcedureSignatureType.Preparer && x.userID == this.getCurrentUser()?.id) != null;
  }

  isApprover() {
    return this.procedure.procedureSignatures?.find(x => x.signatureType == ProcedureSignatureType.Approver && x.userID == this.getCurrentUser()?.id) != null;
  }


  userCanBeRemoved(signature: ProcedureSignature | null = null) {
    if (signature && signature.signedOn) {
      return false;
    }
    if (signature && !signature.signedOn) {
      if (this.procedure?.procedureSignatures) {
        return this.statusCheckRemove();
      }
      else {
        return true;
      }
    }
    return false;
  }

  statusCheckRemove() {
    let statusCheck = false;
    switch (this.type) {
      case ProcedureSignatureType.Preparer:
        statusCheck = this.currentTemplate?.status == ChecklistTemplateStatusEnum.Draft || this.currentTemplate?.status == ChecklistTemplateStatusEnum.CorrectionsInProgress || this.currentTemplate?.status == ChecklistTemplateStatusEnum.CorrectionsPending || this.currentTemplate?.status == ChecklistTemplateStatusEnum.Temp || !this.currentTemplate;
        break;
      case ProcedureSignatureType.Reviewer:
        statusCheck = this.currentTemplate?.status == ChecklistTemplateStatusEnum.Draft || this.currentTemplate?.status == ChecklistTemplateStatusEnum.PendingRev || this.currentTemplate?.status == ChecklistTemplateStatusEnum.InReview || this.currentTemplate?.status == ChecklistTemplateStatusEnum.CorrectionsPending || this.currentTemplate?.status == ChecklistTemplateStatusEnum.CorrectionsInProgress;
        break;
      case ProcedureSignatureType.Approver:
        statusCheck = !(this.currentTemplate?.status == ChecklistTemplateStatusEnum.PendingApr || this.currentTemplate?.status == ChecklistTemplateStatusEnum.Approving);
        break;
    }
    return statusCheck;
  }

  getRoles() {
    const privilege = this.procedureService.getPrivilege(this.procedure?.procedureCategory?.procedureTypeID ?? 0, this.type);
    let roles = null;
    if (privilege) {
      roles = [...new Set(this.rolePrivileges.filter(x => x.privilege == privilege).map(x => x.role?.code))].join('/');
    }
    else {
      roles = [...new Set(this.templateType?.documentType?.templateRolePermissions?.filter(x => x.checklistPrivilege == 1 && this.type == 1 || x.checklistPrivilege == 9 && this.type == 3).map(x => x.role.code))].join('/');
    }
    return roles;
  }

}

<mat-expansion-panel
  (opened)="panelOpen(true)"
  (closed)="panelOpen(false)"
  [expanded]="section?.expanded"
>
  <mat-expansion-panel-header
    [ngClass]="{
      'expansion-header-expanded': section?.expanded,
      'expansion-header-collapsed': !section?.expanded
    }"
  >
    <mat-panel-title>
      <h5>
        {{ section?.name }}
        <div *ngIf="isLoading && !section?.expanded">
          <div class="lds-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </h5>
      <mat-progress-bar
        class="progress-bar"
        mode="indeterminate"
        *ngIf="isLoading && section?.expanded"
      ></mat-progress-bar>
      <div
        *ngIf="!section?.expanded"
        class="ml-3"
        style="height: 4em; width: 100%"
      >
        <absi-section-signature
          [date]="date"
          [user]="user"
          [catl]="catl"
        ></absi-section-signature>
      </div>
    </mat-panel-title>
  </mat-expansion-panel-header>
  <absi-sectionX
    #sectionComponent
    [section]="section"
    [absi]="absi"
    [collapse]="false"
    (loading)="setLoading($event)"
  ></absi-sectionX>
</mat-expansion-panel>

import { Component, ElementRef, Inject, Input, OnInit, ViewChild, ChangeDetectorRef, Injector } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ActionItemService } from '../action-item.service';
import * as QuillNamespace from 'quill';
import { ImageDrop } from 'quill-image-drop-module';
import ImageResize from 'quill-image-resize-module';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormType } from 'src/app/common/enumerations/enumerations';
import { ActionItem } from 'src/app/common/models/action-item';
import CustomImageResize from 'src/app/modules/libs/quill/quill-custom-image-resize/custom-image-resize';
import { ImageUploadService } from 'src/app/services/file/image-upload.service';
import { User } from 'src/app/components/catalogs/user-catalog/services/user';
import { saveActionItemResource } from '../action-item';
import { ActionItemsCreate, ActionItemsUpdate } from '../store/actionItems.action';
import { BaseComponent } from 'src/app/common/base/base.component';

class Owner {
  id?: number;
  name?: string;
}

@Component({
  selector: 'action-item-edit',
  templateUrl: './action-item-edit.component.html',
  styleUrls: ['./action-item-edit.component.scss']
})
export class ActionItemEditComponent extends BaseComponent implements OnInit {

  @Input() formType: string;
  @Input() formId: number;
  @Input() sectionNumber: number;
  @Input() sectionId: number;
  @Input() taskId: number;

  loading!: boolean;

  /** Form Controls **/
  public form!: FormGroup;
  ownerCtrl = new FormControl();
  locationCtrl = new FormControl();
  radioCtrl = new FormControl();
  quillCtrl = new FormControl();

  actionItem: ActionItem;
  tmpText?: string | null;
  textValue?: string | null;
  textValueSanitized!: SafeHtml;
  ownerValue!: Owner;
  locationValue?: string;
  radioValue?: number;

  filteredUsers!: User[];
  saving!: boolean;
  serialNo: string;

  @ViewChild('autoOwner')
  matAutocompleteLoc!: MatAutocomplete;
  @ViewChild('ownerInput')
  ownerInput!: ElementRef<HTMLInputElement>;
  @ViewChild('ownerInput', { read: MatAutocompleteTrigger })
  ownerTrigger!: MatAutocompleteTrigger;

  radioOptions: any[] = [{ value: 30, label: '30 days' }, { value: 60, label: '60 days' }, { value: 90, label: '90 days' }];

  constructor(
    protected override injector: Injector,
    public dialogRef: MatDialogRef<ActionItemEditComponent>,
    private sanitizer: DomSanitizer,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _image: ImageUploadService,
    private _action: ActionItemService,
    private cdRef: ChangeDetectorRef
  ) {
    super(injector);
    this.actionItem = data.actionItem;
    this.formType = data.formType;
    this.formId = data.formId;
    this.sectionNumber = data.sectionNumber;
    this.sectionId = data.sectionId;
    this.taskId = data.taskId;
    this.serialNo = data.serialNo;
  }

  ngOnInit() {
    this.getUsers();
    this.buildForm();
    this.setValues();
    this.filterUsers();
  }

  buildForm() {
    this.form = new FormGroup({
      ownerCtrl: this.ownerCtrl,
      locationCtrl: this.locationCtrl,
      radioControl: this.radioCtrl,
      quillCrtl: this.quillCtrl
    });
  }

  setValues() {
    if (this.actionItem) {
      this.textValue = this.actionItem.description;
      this.tmpText = this.textValue;
      this.textValueSanitized = this.sanitizer.bypassSecurityTrustHtml(this.actionItem.description ? this.actionItem.description : '');
      if (!this.textValue) {
        this.textValue = null;
      }
      this.ownerValue = { id: this.actionItem.ownerUserId, name: this.actionItem.ownerName };
      this.locationValue = this.actionItem.location;
      this.radioValue = this.actionItem.duePeriod;

      this.ownerCtrl.setValue(this.ownerValue);
      this.locationCtrl.setValue(this.locationValue);
      this.radioCtrl.setValue(this.radioValue);
    }
  }

  filterUsers() {
    this.filteredUsers = this.getUsers().filter(x => x.id != this.ownerValue?.id);
  }

  async saveValues() {
    this.saving = true;
    await this.getValue();
    this.saveActionItem();
  }

  saveActionItem() {
    if (this.actionItem) {
      if (this.actionItem.id == 0) {
        const saveActionItem = this.actionItem as saveActionItemResource;
        this._action.postActionItem(saveActionItem).subscribe(data => {
          this.actionItem = data;
          this.store.dispatch(new ActionItemsCreate(this.actionItem));
          this.saving = false;
        });
      }
      else {
        const saveActionItem = this.actionItem as saveActionItemResource;
        if (this.actionItem.id)
          this._action.putActionItem(this.actionItem.id, saveActionItem).subscribe(data => {
            this.actionItem = data;
            if (this.actionItem.id)
              this.store.dispatch(new ActionItemsUpdate(this.actionItem.id, this.actionItem));
            this.saving = false;
          });

      }
    }
  }

  async getValue() {
    if (!this.actionItem) {
      this.actionItem = {};
    }

    this.actionItem.description = await this.uploadImages(this.textValue);
    this.actionItem.ownerUserId = this.ownerValue?.id;
    this.actionItem.ownerName = this.ownerValue?.name;
    this.actionItem.location = this.locationValue;
    this.actionItem.duePeriod = this.radioValue;
    this.actionItem.formType = FormType.ABSI;
    this.actionItem.formId = this.formId;
    this.actionItem.sectionNumber = this.sectionNumber;
    this.actionItem.wfSectionId = this.sectionId;
    this.actionItem.wfTaskId = this.taskId;

    return this.actionItem;
  }


  displayOwner(e: any) {
    return e ? e.name : null;
  }

  /* --- Individual event controls --*/
  textEdit() {
    this.tmpText = this.textValue;
  }

  textChanged(e: any) {
    // if (this.tmpText != this.textValue) {
    //   this.tmpText = this.textValue;
    // }
    this.textValue = e;
    this.tmpText = e;
    if (this.textValue)
      this.textValueSanitized = this.sanitizer.bypassSecurityTrustHtml(this.textValue);
    this.cdRef.checkNoChanges();
  }

  ownerSelected(event: MatAutocompleteSelectedEvent) {
    if (this.ownerCtrl.value.id) {
      this.ownerValue = new Owner();
      this.ownerValue.id = this.ownerCtrl.value.id;
      this.ownerValue.name = this.ownerCtrl.value.name;
    }
    else {
      this.ownerValue.id = 0;
      this.ownerValue.name = this.ownerCtrl.value;
    }
  }

  ownerChanged() {
    if (this.filteredUsers.length == 0) {
      this.ownerValue.id = 0;
      this.ownerValue.name = this.ownerCtrl.value;
    }
  }

  locationChanged() {
    this.locationValue = this.locationCtrl.value;
  }

  radioChanged(e: any) {
    this.radioValue = e.value;
  }

  onOwnerChange() {
    this.filterUsers();
    this.filteredUsers = this.filteredUsers?.filter(o => o.name?.toLowerCase().includes(this.ownerCtrl.value?.toLowerCase()));
  }

  async save() {
    this.loading = true;
    await this.saveValues();
    this.loading = false;
    this.dialogRef.close();
  }

  cancel() {
    this.dialogRef.close();
  }
  /*---------------------------*/



  onSelectionChanged = (event: any) => {
    if (event.oldRange == null) {
      this.onFocus();
    }
    if (event.range == null) {
      this.onBlur();
    }
  }



  onFocus = () => {
  }

  onBlur = () => {
    if (this.form.dirty) {
      this.saveValues();
    }
  }

  async uploadImages(textValue?: string | null) {
    if (textValue) {
      const text = await this._image.uploadImagesToServer(textValue, '/' + this.formType + '/' + this.serialNo + '/' + 'ActionItems/');
      return text;
    }
    return textValue;
  }

}

import { Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class SignalRService {
  BASE_URL = environment.urls.baseApiUrl.replace('api', 'apphub');
  public hubConnection!: signalR.HubConnection;

  public startConnection() {
    this.hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(this.BASE_URL)
      .withAutomaticReconnect()
      .configureLogging(signalR.LogLevel.Error)
      .build();
    this.hubConnection
      .start()
      .then(() => console.log('Connection started'))
      .catch(err => console.log('Error while starting connection: ' + err));
  }

  public listenOn(tag: string) {
    this.hubConnection?.on(tag, (data) => {
      console.log(data);
    });
  }

  public listenOn2 = (tag: string, funct: (...args: any[]) => any) => this.hubConnection?.on(tag, funct);
}

<a routerLink="home">
  <img
    alt="Berkeley Logo"
    src="../assets/images/berkeley_alt4_logo.png"
    id="logo"
    class="img-responsive log-img"
  />
</a>
<a class="white brand" href="/" [matTooltip]="environment.version">
  ASCC<sup class="ml-1">4</sup></a
>

import { WFStepLocalResource } from "src/app/services/work-flow/work-flow";


export class DeletedStepsRefresh {
  static readonly type = '[DeletedSteps] Refresh';
  constructor(public checklistTemplateID: number) { }
}

// export class DeletedStepsCreate {
//   static readonly type = '[DeletedSteps] Create';
//   constructor(public item: WFStepLocalResource) { }
// }

// export class DeletedStepsDelete {
//   static readonly type = '[DeletedSteps] Delete';
//   constructor(public logID: number) { }
// }

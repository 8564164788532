import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SendIdService {

  private idSource = new BehaviorSubject<number | null>(null);
  public currentId = this.idSource.asObservable();

  constructor() { }

  SendId(id: number) {
    this.idSource.next(id);
  }

}

import { Component, OnInit, Injector, OnDestroy } from '@angular/core';
import { Router } from "@angular/router";
import { CookieService } from "ngx-cookie-service";
import { Subscription } from "rxjs";
import { utils } from "src/app/modules/libs/utils";
import { AuthenticationService } from "src/app/services/authentication/authentication.service";
import { Role } from "src/app/components/catalogs/roles/services/role";
import { User } from "src/app/components/catalogs/user-catalog/services/user";
import { AppStateService } from "src/app/store/app-state-service";
import { Servers } from "src/app/common/enumerations/servers-enum";
import { LoginComponent } from "../../login/login.component";
import { BaseComponent } from "src/app/common/base/base.component";
import { MessagePlaceholder } from 'src/app/common/models/placeholder';
import { MessageTypeEnum } from '../../messages/services/message-banner.enums';
import { SetCurrentUser } from 'src/app/store/actions';
import { Token } from '../../../services/authentication/token-structure';
import { environment } from 'src/environments/environment';

@Component({
  selector: "head-login",
  templateUrl: "./head-login.component.html",
  styleUrls: ["./head-login.component.scss"],
})
export class HeadLoginComponent extends BaseComponent implements OnInit, OnDestroy {
  loggedIn = false;

  name!: string | null;
  roles?: string | null;
  rolesList?: (Role | undefined)[] = [];

  logout: boolean = false;
  assSubs: Subscription;

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private cookieService: CookieService,
    protected override injector: Injector,
    private appState: AppStateService
  ) {
    super(injector);
    this.assSubs = this.appState.AppState.subscribe((appState) => {
      this.setUser(appState?.currentUser);
    });
  }

  ngOnInit() {

  }

  override ngOnDestroy(): void {
    this.assSubs?.unsubscribe();
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(LoginComponent, {
      height: "32em",
      width: "25em",
    });

    dialogRef.afterClosed().subscribe(response => {
      if (response?.code == 423 || response?.code == 401) {
        response?.code === 423 ? this.alert.message('Login_UserInactive') : response?.code === 401 ? this.alert.message('Login_UserUndefined') : null;
      }
      this.store.dispatch(new SetCurrentUser(response?.token));
      this.router.navigate(["/"]);
    });
  }

  login() {
    if (document.location.hostname === Servers.PRODUCTION) {
      location.href = "https://ascc.als.lbl.gov/Shibboleth.sso/Login";
    } else {
      this.openDialog();
    }
  }

  setUser(user: User | null) {
    this.rolesList = [];
    this.loggedIn = false;
    if (user) {
      user.userRole?.sort((a, b) =>
        utils.sort(a.role?.name ?? '', b.role?.name ?? '', true)
      );
      this.loggedIn = true;
      this.name = user.name ?? '';

      this.rolesList = user.userRole?.map((x) => x.role)
        .sort((a, b) => utils.sort(a?.name ?? '', b?.name ?? '', true));
      this.roles = user.userRole?.map((r) => r.role?.code)
        .sort((a, b) => utils.sort(a ?? '', b ?? '', true))
        .join("/");
    }
  }

  async logOut() {
    if (await this.canDeactivate()) {
      this.name = null;
      this.roles = null;
      this.rolesList = [];
      this.loggedIn = false;
      this.authenticationService.logout();

      const el: HTMLElement | null = document.getElementById("userInfo");
      el?.classList.add("hidden");
      localStorage.clear();

      if (document.location.hostname === environment.productionEnvironment) {
        this.cookieService.delete("_shibobj", "/");
        this.alert.warning(
          "You <b>MUST</b> close your browser to complete the logout process."
        );
        this.logout = true;
        const that = this;
        setTimeout(() => {
          that.router.navigate(["/"]);
        }, 2000);
      } else {
        this.router.navigate(["/"]);
      }
    }
  }

  showUserInfo() {
    const el: HTMLElement | null = document.getElementById("userInfo");
    if (el?.classList.contains("hidden")) {
      el.classList.remove("hidden");
    } else {
      this.mouseleave();
    }
  }

  override mouseleave() {
    setTimeout(() => {
      const el: HTMLElement | null = document.getElementById("userInfo");
      el?.classList.add("hidden");
    }, 1000);
  }

  userCheck() {
    return this.currentUser?.id == 18 && document.location.hostname === Servers.PRODUCTION;
  }

}

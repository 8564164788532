import { Component, ElementRef, EventEmitter, Injector, Input, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from "@angular/core";
import { FormGroup, FormControl } from "@angular/forms";
import { MatAutocomplete, MatAutocompleteTrigger, MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { MatChipInputEvent } from "@angular/material/chips";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import { Router } from "@angular/router";
import { BaseComponent } from "src/app/common/base/base.component";
import { FormStatusEnum, ResourceType, AffectedSystemType, FormType, OlogNotificationType } from "src/app/common/enumerations/enumerations";
import { CancelDialogComponent } from "src/app/controls/cancel-dialog/cancel-dialog.component";
import { PartialOlogComponent } from "src/app/controls/olog/partial-olog/partial-olog.component";
import { YesNoDialogComponent } from "src/app/controls/yes-no-dialog/yes-no-dialog.component";
import { RelatedDocument } from "src/app/services/documents/documents";
import { FormRestriction } from "src/app/services/forms/form-restriction";
import { RelatedLinkBase } from "src/app/components/catalogs/navigation-links/link/related-link-base";
import { OlogDataBaseObject, OLogFormData } from "src/app/components/olog/olog";
import { OLogService } from "src/app/components/olog/olog.service";
import { Resource } from "src/app/components/catalogs/beamline-catalog/resource/resources";
import { ResourcesService } from "src/app/components/catalogs/beamline-catalog/resource/resources.service";
import { RestrictionService } from "src/app/controls/restrictions/services/restriction.service";
import { PrivilegeEnum } from "src/app/services/role-privilege/privilege-enum";
import { User } from "src/app/components/catalogs/user-catalog/services/user";
import { WfTaskLocalResource, WfTaskResource, WFConfiguration, WfSignatureResource, WFTaskSignatureResource } from "src/app/services/work-flow/work-flow";
import { WorkFlowService } from "src/app/services/work-flow/work-flow.service";
import { WorkPerformer, WorkPerformerGroup } from "src/app/services/work-performer/work-performer";
import { RSSWAResource, AffectedSystem, SaveRSSWAResource, } from "../../rsswa";
import { RSSWAAcceleratorLocation, SaveRSSWAAcceleratorLocation } from "../../rsswa-accelerator-location";
import { RSSWAOtherSystem } from "../../../../controls/other-system-chips/other-system";
import { RSSWAPerformingWorkUser } from "../../rsswa-performing-work-user";
import { RSSWAShutter, SaveRSSWAShutter } from "../../rsswa-shutter";
import { RsswaService } from "../../rsswa.service";
import { RestrictionAvailableAutocompleteComponent } from "src/app/controls/restrictions/restriction-available-autocomplete/restriction-available-autocomplete.component";
import { FormRestrictionTableComponent } from "src/app/controls/restrictions/form-restriction-table/form-restriction-table.component";
import { ResourceSummaryService } from "src/app/components/catalogs/beamline-catalog/resource/resource-summary.service";
import { Observable, Subscription } from "rxjs";
import { MessagePlaceholder } from "src/app/common/models/placeholder";
import * as moment from "moment";
import { utils } from "src/app/modules/libs/utils";
import { UserService } from "src/app/components/catalogs/user-catalog/services/user.service";
import { ChecklistExecPopupComponent, ChecklistBuilderPopupParameters } from "../checklist-exec-popup/checklist-exec-popup.component";
import { MessageTypeEnum } from "src/app/components/messages/services/message-banner.enums";
import { WorkPerformerService } from "src/app/services/work-performer/work-performers.service";


@Component({
  selector: 'rsswa-section1',
  templateUrl: './rsswa-section1.component.html',
  styleUrls: ['./rsswa-section1.component.scss']
})

export class RsswaSection1Component extends BaseComponent implements OnInit, OnDestroy {
  @Input() rsswa?: RSSWAResource | null;
  @Output() loading = new EventEmitter<boolean>();
  @Input() showAmendment?: boolean;

  loadingOlog = false;

  serialNo?: string;
  docLoading = false;
  perLoading = false;
  reqLoading = false;
  rssLoading = false;
  tomLoading = false;

  public saveButtonText?: string;

  showSubmit?: boolean = false;

  dateNow!: any;
  disabled = true;

  // #Form Controls

  requesterCtrl = new FormControl();
  documentCtrl = new FormControl();
  estInitCtrl = new FormControl({ disabled: true });
  estEndCtrl = new FormControl();
  performerCtrl = new FormControl();
  chkTopOffCtrl = new FormControl();
  chkRadMonitorCtrl = new FormControl();
  chkBeamlineCtrl = new FormControl();
  chkAcceleratorCtrl = new FormControl();
  chkETIAccessCtrl = new FormControl();
  chkTPLSetCtrl = new FormControl();
  chkTSoftwareCtrl = new FormControl();
  chkTopOffInterlockCtrl = new FormControl();
  chkRSSPLCSetCtrl = new FormControl();
  chkRSSSoftwareCtrl = new FormControl();
  chkFuncTestCtrl = new FormControl();
  descriptionCtrl = new FormControl();
  rsswaShuttersControl = new FormControl();
  rsswaAcceleratorLocationsControl = new FormControl();
  rsswaOtherSystemsControl = new FormControl();
  restrictionsControl = new FormControl();
  functionalTestCtrl = new FormControl();
  provisionalTestingRequiredCtrl = new FormControl();


  //#region Fields

  tmpDate?: Date;
  tmpOperator?: string;
  tmpRequester?: number | null;
  tmpRequesterName?: string | null;
  tmpStartDate? = new Date();
  tmpEndDate? = new Date();
  tmpSysAffectedBL? = false;
  tmpSysAffectedAcc? = false;
  tmpSysAffectedTombis? = false;
  tmpChkETIAccess? = false;
  tmpChkTPLCSet? = false;
  tmpChkTSoftware? = false;
  tmpChktopOffInterlockSetPoint? = false;
  tmpChkRepair? = false;
  tmpChkRPLCSet? = false;
  tmpChkRSoftware? = false;
  tmpDescription?: string;
  tmpTopOff? = false;
  tmpRadiationMonitoringSystem? = false;
  tmpBeamLine? = false;
  tmpAccelerator? = false;
  tmpOtherSystems? = false;
  tmpRsswaShutters?: RSSWAShutter[] = [];
  tmpRsswaAcceleratorLocations?: RSSWAAcceleratorLocation[] = [];
  tmpRsswaOtherSystems?: RSSWAOtherSystem[] = [];
  tmpRsswaRestrictions?: FormRestriction[] = [];
  tmpFunctionalTest? = false;
  tmpProvisionalTestingRequired?: string;
  tmpRsswaRelatedLinks?: RelatedLinkBase[] = [];
  //
  tmpWfTableId?: number | null = null;

  workFlow?: WfTaskLocalResource[];
  workFlowPending?: WfTaskResource[];

  wfConfiguration?: WFConfiguration;
  wfSignatures?: WfSignatureResource[];
  wfTaskSignature: WFTaskSignatureResource = new WFTaskSignatureResource();
  // #Tables

  affectedSystems?: AffectedSystem[];

  filteredRequesters: User[] = [];

  allWorkPerformers?: WorkPerformer[];
  filteredWorkPerformers?: WorkPerformer[];
  filteredPerformerGroups?: WorkPerformerGroup[];
  workPerformers?: WorkPerformer[];

  rsswaPrivilege?: boolean | null = false;
  rssFunctionalTestCreatePrivilege?: boolean | null = false;

  showCancelForm = false;
  showCancelDraft = false;
  cancelSignature?: WfSignatureResource;
  showUnSubmit = false;
  unSubmitSignature?: WfSignatureResource;

  systemsAffectedError = false;

  sr02areasrrf?: Resource;
  topOff!: Resource;
  accelerators: Resource[] = [];

  // #Mat Chip Variables
  selectable = true;
  removable = true;
  addOnBlur = true;
  tmpResourceIds!: number[];

  @ViewChild('performerInput') performerInput?: ElementRef<HTMLInputElement>;

  @ViewChild('autoDoc') matAutocompleteDoc?: MatAutocomplete;
  @ViewChild('autoPer') matAutocompletePer?: MatAutocomplete;

  @ViewChild(MatAutocompleteTrigger) documentInput?: MatAutocompleteTrigger;
  @ViewChild(MatAutocompleteTrigger) requesterInput?: MatAutocompleteTrigger;
  @ViewChild(RestrictionAvailableAutocompleteComponent) restrictionAvailableAutocomplete?: RestrictionAvailableAutocompleteComponent;
  @ViewChild(FormRestrictionTableComponent) formRestrictionTable?: FormRestrictionTableComponent;
  newDoc?: RelatedDocument;

  popclass = 'pop-description-hidden';

  newPer?: WorkPerformer;

  /////
  rsswas?: RSSWAResource[];
  rsswasFiltered?: RSSWAResource[];
  rsswas$!: Observable<RSSWAResource[]>;
  rsswasSubs!: Subscription;

  resources?: Resource[];
  resourcesFiltered?: Resource[];
  resources$!: Observable<Resource[]>;
  resourcesSubs!: Subscription;

  documents: RelatedDocument[] = [];

  signToUnsubmitAmendment: any;

  constructor(
    public service: RsswaService,
    private _performers: WorkPerformerService,
    public router: Router,
    public _wf: WorkFlowService,
    public _restriction: RestrictionService,
    public _resource: ResourcesService,
    public ologService: OLogService,
    private resourceSummaryService: ResourceSummaryService,
    private _users: UserService,
    protected override injector: Injector
  ) {
    super(injector);
  }

  //#region INIT

  ngOnInit() {
    this.rsswaPrivilege = this.hasPrivilege(PrivilegeEnum.RSSWA);
    this.rssFunctionalTestCreatePrivilege = this.hasPrivilege(PrivilegeEnum.RSSFunctionalTestCreate);
    this.loadRSSWAs();
    this.loadResources();
    this.buildForm();
    this.loadUsers();
    this.loadAllWorkPerformers();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.loadRSSWAs();
    this.refresh();
    this.filteredRequesters = this.getUsers();
  }

  loadRSSWAs() {
    this.rsswas$ = this.store.select(state => state.RSSWA.data);
    this.rsswasSubs = this.rsswas$.subscribe(data => {
      if (data?.length) {
        this.rsswas = data;
        if (this.rsswa?.id) {
          const rsswa = this.rsswas.find(r => r.id == this.rsswa?.id);
          if (rsswa) {
            this.rsswa = rsswa;
          } else {
            this.rsswa = null;
          };
        }
      }
    });
  }

  loadResources() {
    this.resources$ = this.store.select(state => state.Resources.data);
    this.resourcesSubs = this.resources$.subscribe(data => {
      if (data?.length) {
        this.resources = data;
        this.accelerators = this.resources.filter(r => r.type == ResourceType.Accelerator);
        this.sr02areasrrf = this.resources.find(r => r.code == 'SR10Area(BTS)');
        const topOff = this.resources.find(r => r.code == 'TopOff');
        if (topOff)
          this.topOff = topOff;
        this.fillResources();
      }
    });
  }

  buildForm() {
    this.formGroup = new FormGroup({
      requesterCtrl: this.requesterCtrl,
      documentCtrl: this.documentCtrl,
      estInitCtrl: this.estInitCtrl,
      estEndCtrl: this.estEndCtrl,
      performerCtrl: this.performerCtrl,
      chkTopOffCtrl: this.chkTopOffCtrl,
      chkRadMonitorCtrl: this.chkRadMonitorCtrl,
      chkBeamlineCtrl: this.chkBeamlineCtrl,
      chkAcceleratorCtrl: this.chkAcceleratorCtrl,
      chkETIAccessCtrl: this.chkETIAccessCtrl,
      chkTPLSetCtrl: this.chkTPLSetCtrl,
      chkTSoftwareCtrl: this.chkTSoftwareCtrl,
      chkTopOffInterlockCtrl: this.chkTopOffInterlockCtrl,
      chkRSSPLCSetCtrl: this.chkRSSPLCSetCtrl,
      chkRSSSoftwareCtrl: this.chkRSSSoftwareCtrl,
      descriptionCtrl: this.descriptionCtrl,
      chkFuncTestCtrl: this.chkFuncTestCtrl,
      restrictionsControl: this.restrictionsControl,
      rsswaOtherSystemsControl: this.rsswaOtherSystemsControl,
      rsswaShuttersControl: this.rsswaShuttersControl,
      rsswaAcceleratorLocationsControl: this.rsswaAcceleratorLocationsControl,
      functionalTestCtrl: this.functionalTestCtrl,
      provisionalTestingRequiredCtrl: this.provisionalTestingRequiredCtrl
    });
    this.formGroup.markAsPristine();
    // this.formGroup.statusChanges.subscribe(() => {
    //   this.setFormDirty(this.formGroup.dirty || !this.formGroup.pristine);
    // });

    this.checkEnableControls();
    this.systemDesign();
    this.systemsAffectedError = false;
  }

  checkEnableControls() {
    this.disabled = true;
    this.formGroup.disable();
    this.estInitCtrl?.disable();
    this.performerCtrl.disable();
    let enable: boolean | null = false;
    if (!this.service.showAmendment)
      enable = this.rsswa?.editingById == this.getCurrentUser()?.id && this.hasPrivilege(this.privilegeEnum.RSSWA) && (this.rsswa?.status == this.formStatusEnum.New || this.rsswa?.status == this.formStatusEnum.Draft);
    else
      enable = this.rsswa?.editingById == this.getCurrentUser()?.id && this.hasPrivilege(this.privilegeEnum.RSSWA) && (this.rsswa?.status == this.formStatusEnum.Amendment && this.hasPrivilege(this.privilegeEnum.EditRSSWAAmendment));

    if (enable) {
      this.disabled = false;
      this.formGroup.enable();
      this.estInitCtrl?.enable();
      this.performerCtrl.enable();
    }
  }

  clear() {
    this.rsswa = null;
    this.setFormDirty(false);
    this.loading.emit(false);
    this.formGroup?.reset();
    if (!this.getCurrentUser()) {
      'user_not_logged'
        ;
      return;
    }
    this.tmpDate = new Date();
    this.tmpOperator = this.getCurrentUser()?.name;
    this.tmpRequester = null;
    this.tmpRequesterName = null;

    this.workPerformers = [];
    this.documents = [];
    this.affectedSystems = [];
    this.tmpRsswaShutters = [];
    this.tmpRsswaAcceleratorLocations = [];
    this.tmpRsswaOtherSystems = [];
    this.tmpRsswaRestrictions = [];
    this.tmpRsswaRelatedLinks = [];
    // this.tmpRsswaPerformingWorkUser = [];
    this.restrictionAvailableAutocomplete?.reset();
    this.tmpStartDate = new Date();
    this.tmpEndDate = new Date();

    this.tmpTopOff = false;
    this.tmpRadiationMonitoringSystem = false;
    this.tmpBeamLine = false;
    this.tmpAccelerator = false;
    this.tmpOtherSystems = false;
    this.tmpSysAffectedAcc = false;
    this.tmpSysAffectedBL = false;
    this.tmpSysAffectedTombis = false;
    this.tmpFunctionalTest = false;
    this.tmpProvisionalTestingRequired = '';

    this.tmpChkETIAccess = false;
    this.tmpChkRepair = false;
    this.tmpChkRPLCSet = false;
    this.tmpChkRSoftware = false;
    this.tmpChkTPLCSet = false;
    this.tmpChkTSoftware = false;
    this.tmpChktopOffInterlockSetPoint = false;
    this.tmpDescription = '';

    $('#tmpChkRPLCSet').addClass('check-disabled');
    $('#tmpChkRSoftware').addClass('check-disabled');
    $('#tmpChkETIAccess').addClass('check-disabled');
    $('#tmpChkTPLCSet').addClass('check-disabled');
    $('#tmpChkTSoftware').addClass('check-disabled');
    $('#tmpChktopOffInterlockSetPoint').addClass('check-disabled');

    this.buildForm();

    this.chkETIAccessCtrl.disable();
    this.chkTPLSetCtrl.disable();
    this.chkTSoftwareCtrl.disable();
    this.chkTopOffInterlockCtrl.disable();
    this.chkRSSPLCSetCtrl.disable();
    this.chkRSSSoftwareCtrl.disable();
    this.showSubmit = false;

    this.systemsAffectedError = false;

    this.checkEnableControls();
    this.setButtonLabels();

    this.showCancelForm = false;

  }

  setButtonLabels() {
    this.saveButtonText = this.rsswa?.status == FormStatusEnum.New ? 'Save as Draft' : (this.service.showAmendment ? 'Update Amendment' : 'Update Draft');
  }

  //#endregion

  //#region CREATE
  create() {
    this.clear();
    this.disabled = false;
    this.formGroup.enable();
    this.requesterCtrl.enable();

    setTimeout(() => {
      this.requesterInput?.openPanel();
    }, 1500);

  }

  addEvent(type: string, event: MatDatepickerInputEvent<Date>) {
    if (moment(this.tmpEndDate).isBefore(this.tmpStartDate)) {
      this.tmpEndDate = this.tmpStartDate;
    }
  }
  //#endregion

  async refresh() {
    this.loading.emit(true);
    this.systemsAffectedError = false;
    if (this.rsswa) {
      if (this.rsswa?.disapprovalReason && this.rsswa?.status !== FormStatusEnum.WorkApprovals) {
        this.alert.message('HassDisapprovalReason', [new MessagePlaceholder('{DisapprovalReason}', this.rsswa.disapprovalReason)], MessageTypeEnum.Warning, 'Disaproval Warning', 5);
      }

      this.tmpDescription = this.service.showAmendment ? this.rsswa.rsswaAmendment?.description : this.rsswa.description;
      this.tmpDate = this.service.showAmendment ? this.rsswa.rsswaAmendment?.createdOn : this.rsswa.createdOn;
      this.tmpRequester = this.service.showAmendment ? this.rsswa.rsswaAmendment?.requesterId : this.rsswa.requesterId;
      this.tmpRequesterName = this.service.showAmendment ? this.rsswa.rsswaAmendment?.requesterName : this.rsswa.requesterName;
      this.tmpOperator = this.service.showAmendment ? this.rsswa.rsswaAmendment?.createdByName : this.rsswa.createdByName;
      this.documents = this.getDocuments();
      this.tmpStartDate = this.service.showAmendment ? this.rsswa.rsswaAmendment?.startOn : this.rsswa.startOn;
      this.tmpEndDate = this.service.showAmendment ? this.rsswa.rsswaAmendment?.completeOn : this.rsswa.completeOn;
      this.tmpTopOff = this.service.showAmendment ? this.rsswa.rsswaAmendment?.topOff : this.rsswa.topOff;
      this.tmpRadiationMonitoringSystem = this.service.showAmendment ? this.rsswa.rsswaAmendment?.radiationMonitoringSystem : this.rsswa.radiationMonitoringSystem;
      this.tmpBeamLine = this.service.showAmendment ? this.rsswa.rsswaAmendment?.beamLine : this.rsswa.beamLine;
      this.tmpAccelerator = this.service.showAmendment ? this.rsswa.rsswaAmendment?.accelerator : this.rsswa.accelerator;
      this.tmpRsswaShutters = this.service.showAmendment ? this.rsswa.rsswaAmendment?.rsswaShutters : this.rsswa.rsswaShutters;
      this.tmpRsswaAcceleratorLocations = this.service.showAmendment ? this.rsswa.rsswaAmendment?.rsswaAcceleratorLocations : this.rsswa.rsswaAcceleratorLocations;
      this.tmpRsswaOtherSystems = this.service.showAmendment ? this.rsswa.rsswaAmendment?.rsswaOtherSystems : this.rsswa.rsswaOtherSystems;
      this.tmpRsswaRestrictions = this.service.showAmendment ? this.rsswa.rsswaAmendment?.rsswaRestrictions : this.rsswa.rsswaRestrictions;
      this.tmpChkETIAccess = this.service.showAmendment ? this.rsswa.rsswaAmendment?.etiAccess : this.rsswa.etiAccess;
      this.tmpChkTPLCSet = this.service.showAmendment ? this.rsswa.rsswaAmendment?.tombisplcSet : this.rsswa.tombisplcSet;
      this.tmpChkTSoftware = this.service.showAmendment ? this.rsswa.rsswaAmendment?.tombisSoftware : this.rsswa.tombisSoftware;
      this.tmpChkRepair = this.service.showAmendment ? this.rsswa.rsswaAmendment?.repairRequired : this.rsswa.repairRequired;
      this.tmpChkRPLCSet = this.service.showAmendment ? this.rsswa.rsswaAmendment?.rssplcSet : this.rsswa.rssplcSet;
      this.tmpChkRSoftware = this.service.showAmendment ? this.rsswa.rsswaAmendment?.rssSoftware : this.rsswa.rssSoftware;
      this.tmpChktopOffInterlockSetPoint = this.service.showAmendment ? this.rsswa.rsswaAmendment?.topOffInterlockSetPoint : this.rsswa.topOffInterlockSetPoint;
      this.tmpWfTableId = this.service.showAmendment ? this.rsswa.rsswaAmendment?.wfTableID : this.rsswa.wfTableID;
      this.tmpFunctionalTest = this.service.showAmendment ? this.rsswa.rsswaAmendment?.functionalTest : this.rsswa.functionalTest;
      this.tmpProvisionalTestingRequired = this.service.showAmendment ? this.rsswa.rsswaAmendment?.provisionalTestingRequired : this.rsswa.provisionalTestingRequired;
      this.tmpRsswaRelatedLinks = this.service.showAmendment ? this.rsswa.rsswaAmendment?.rsswaRelatedLinks : this.rsswa.rsswaRelatedLinks;
      // this.tmpRsswaPerformingWorkUser = this.service.showAmendment ? this.rsswa.rsswaAmendment?.rsswaPerformingWorkUsers : this.rsswa.rsswaPerformingWorkUsers;
      this.workPerformers = this.getWorkPerformers();
      this.getSystemsAffected(this.rsswa.affectedSystems ?? []);
      this.fillResources();

      this.setButtonLabels();

      this.showSubmit = false;
      if (this.rsswa.wfTable) {
        const section = this.service.showAmendment ? 9 : 1;
        this.verifyActions(section);
      }

      this.systemDesign();
      this.buildForm();
      this.loading.emit(false);
    } else {
      this.clear();
    }

    // this.refreshLog.emit();
  }


  getSystemsAffected(as: AffectedSystem[]) {
    this.tmpSysAffectedBL = false;
    this.tmpSysAffectedAcc = false;
    this.tmpSysAffectedTombis = false;
    this.affectedSystems = as;

    as.map(a => {
      switch (a.type) {
        case AffectedSystemType.BLSystem:
          this.tmpSysAffectedBL = true;
          break;
        case AffectedSystemType.AccSystem:
          this.tmpSysAffectedAcc = true;
          break;
        case AffectedSystemType.TOMBISSystem:
          this.tmpSysAffectedTombis = true;
          break;
      }
    });

  }

  //#endregion

  //#region SAVE

  async saveForm(status: number) {
    const rsswaShutters: SaveRSSWAShutter[] = [];
    const rsswaAcceleratorLocations: SaveRSSWAAcceleratorLocation[] = [];
    const rsswaRestrictions: FormRestriction[] = [];
    const rsswaRelatedLinks: RelatedLinkBase[] = [];
    const rsswaPerformingWorkUsers: RSSWAPerformingWorkUser[] = [];

    this.tmpRsswaShutters?.map(x => {
      rsswaShutters.push({
        id: x.id,
        rsswaID: x.rsswaID,
        resourceID: x.resourceID,
      });
    });
    this.tmpRsswaAcceleratorLocations?.map(x => {
      rsswaAcceleratorLocations.push({
        id: x.id,
        rsswaID: x.rsswaID,
        resourceID: x.resourceID
      });
    });
    this.tmpRsswaRestrictions?.map(x => {
      rsswaRestrictions.push({
        id: x.id,
        formID: x.formID,
        restrictionID: x.restrictionID,
        text: x.text
      });
    });
    this.tmpRsswaRelatedLinks?.map(x => {
      rsswaRelatedLinks.push({
        id: x.id,
        formID: x.formID,
        text: x.text,
        url: x.url
      });
    });
    // this.tmpRsswaPerformingWorkUser.map(x => {
    //   rsswaPerformingWorkUsers.push({
    //     id: x.id,
    //     rsswaID: x.rsswaID,
    //     userID: x.userID
    //   });
    // });

    const rsswaResource: SaveRSSWAResource = {
      id: this.rsswa ? this.rsswa.id : 0,
      name: this.rsswa?.serialNo,
      description: this.tmpDescription,
      status: status === FormStatusEnum.WorkApprovals ? FormStatusEnum.Draft : status,
      type: FormType.RSSWA,
      code: 'RSSWA',
      serialNo: this.rsswa?.serialNo,
      requesterId: this.tmpRequester ? this.tmpRequester : 0,
      startOn: this.tmpStartDate,
      completeOn: this.tmpEndDate,
      beamlineRSS: this.tmpSysAffectedBL,
      acceleratorRSS: this.tmpSysAffectedAcc,
      tombis: this.tmpSysAffectedTombis,
      affectedSystems: [],
      etiAccess: this.tmpChkETIAccess,
      tombisPLCSet: this.tmpChkTPLCSet,
      tombisSoftware: this.tmpChkTSoftware,
      topOffInterlockSetPoint: this.tmpChktopOffInterlockSetPoint,
      repairRequired: this.tmpChkRepair,
      rssPLCSet: this.tmpChkRPLCSet,
      rssSoftware: this.tmpChkRSoftware,
      workPerformers: this.workPerformers,
      relatedDocuments: this.documents,
      topOff: this.tmpTopOff,
      radiationMonitoringSystem: this.tmpRadiationMonitoringSystem,
      beamLine: this.tmpBeamLine,
      accelerator: this.tmpAccelerator,
      rsswaShutters,
      rsswaAcceleratorLocations,
      rsswaOtherSystems: this.tmpRsswaOtherSystems ?? [],
      rsswaRestrictions,
      functionalTest: this.tmpFunctionalTest,
      provisionalTestingRequired: this.tmpProvisionalTestingRequired,
      rsswaRelatedLinks,
      rsswaPerformingWorkUsers,
      editingById: this.getCurrentUser()?.id
    };
    if (rsswaResource.id === 0) {
      if (this.isValid(status)) {
        this.loading.emit(true);
        rsswaResource.serialNo = this.serialNo;
        this.setFormDirty(false);
        this.service.create(rsswaResource).subscribe(data => {
          this.alert.message('DraftSaved', [new MessagePlaceholder('{serialNo}', rsswaResource.serialNo)]);
          this.loading.emit(false);
          this.setFormDirty(false);
        },
          error => {
            this.alert.defaultError();
            console.log(error);
            this.loading.emit(false);
          });
      }
    } else {
      if (this.isValid(status)) {
        this.loading.emit(true);
        this.setFormDirty(false);
        this.service.update(rsswaResource).toPromise().then(data => {
          if (status === 2) {
            if (this.wfSignatures)
              this.sign(this.wfSignatures[0].taskID, this.wfSignatures[0].approveID);
          } else {
            this.alert.message(status === 1 ? 'DraftUpdated' : 'Submitted', [new MessagePlaceholder('{serialNo}', data?.serialNo)]);
          }
          this.setFormDirty(false);
        },
          error => {
            this.alert.defaultError();
            console.log(error);
            this.loading.emit(false);
          }
        );
      } else {
        this.loading.emit(false);
      }
    }
  }

  isValid(option: number) {
    let result = true;
    if (moment(this.tmpStartDate).isBefore(moment(new Date('2020/01/01')).add(-1, "day"))) {
      this.alert.message('invalidStartDate');
      this.estInitCtrl?.setErrors({ invalidDate: true });
      this.alert.message('invalidStartDate');
      result = false;
    }

    if (moment(this.tmpEndDate).isBefore(this.tmpStartDate)) {
      this.alert.message('invalidEndDate');
      this.estEndCtrl.setErrors({ invalidDate: true });
      result = false;
    }

    if (option === 2) {
      if (!this.workPerformers?.length) {
        this.alert.message('Select at least one work performer group or one work performer user');
        this.performerCtrl.setErrors({ required: true });
        result = false;
      }
      if (!this.tmpTopOff && !this.tmpRadiationMonitoringSystem && !this.tmpBeamLine && !this.tmpAccelerator && !this.tmpRsswaOtherSystems?.length) {
        this.systemsAffectedError = true;
        this.alert.message('pleaseCheckOnAtLeastOneSystemAffected');
        result = false;
      }

      if (this.tmpRequester === null || this.tmpRequester === 0) {
        this.alert.message('invalidRequester');
        this.requesterCtrl.setErrors({ required: true });
        result = false;
      }

      if (!this.tmpDescription) {
        this.descriptionCtrl.setErrors({ required: true });
        this.alert.message('invalidDescription');
        result = false;
      }

      if (!this.tmpRsswaRestrictions?.length) {
        this.restrictionsControl.setErrors({ required: true });
        this.alert.message('No Standard Restrictions were added');
        result = false;
      }

      if (this.tmpBeamLine && !this.tmpRsswaShutters?.length) {
        this.alert.message('If beamline checkbox is marked, you must add at least 1 shutter');
        this.rsswaShuttersControl.setErrors({ required: true });
        this.systemsAffectedError = true;
        result = false;
      }
      else this.rsswaShuttersControl.setErrors(null);

      if (this.tmpAccelerator && !this.tmpRsswaAcceleratorLocations?.length) {
        this.alert.message('If accelerator checkbox is marked, you must add at least 1 location');
        this.rsswaAcceleratorLocationsControl.setErrors({ required: true });
        this.systemsAffectedError = true;
        result = false;
      }
      else this.rsswaAcceleratorLocationsControl.setErrors(null);

      if (this.tmpFunctionalTest && (!this.rsswa?.rssFunctionalTests?.length)) {
        this.alert.error('If functional test checkbox is marked, you must add at least 1 rss functional test');
        this.functionalTestCtrl.setErrors({ required: true });
        result = false;
      }
      else this.functionalTestCtrl.setErrors(null);

    }
    if (!result) this.formGroup.markAllAsTouched();
    return result;
  }

  //#endregion

  //#region Requester Control
  onReqChange(code?: string) {
    if (!code?.includes("Arrow")) {
      this.filteredRequesters = this.getUsers();
      const filter1 = this.filteredRequesters.filter(r =>
        r.name?.toLowerCase().includes(this.requesterCtrl.value.toLowerCase()));

      this.filteredRequesters = filter1;
    }
  }

  clickReq() {
    this.requesterInput?.openPanel();
  }

  selectedReq(e: any): void {
    this.tmpRequesterName = e.option.value;
    this.tmpRequester = this.getUsers()?.find(u => u.name == this.tmpRequesterName)?.id;
  }

  onClosedReq() {
    let found = false;
    this.filteredRequesters.map(r => {
      if (r.name === this.tmpRequesterName) {
        found = true;
      }
    });
  }


  clickDoc() {
    this.documentInput?.openPanel();
  }


  getDocuments(): RelatedDocument[] {
    let relatedDocs: RelatedDocument[] = [];

    if (this.rsswa) {
      if (!this.service.showAmendment)
        this.rsswa.relatedDocuments?.map(element => {
          relatedDocs.push(element);
        });
      else {
        this.rsswa.rsswaAmendment?.relatedDocuments?.map(element => {
          relatedDocs.push(element);
        })
      }
    }
    relatedDocs = relatedDocs.filter((item, index, self) => index === self.findIndex((t) => (t.relatedDocumentSerialNo === item.relatedDocumentSerialNo))).sort((a, b) => {
      if ((a.relatedDocumentTypeId ?? 0) < (b.relatedDocumentTypeId ?? 0)) return -1
      else if ((a.relatedDocumentTypeId ?? 0) > (b.relatedDocumentTypeId ?? 0)) return 1
      else return utils.sort(a.relatedDocumentSerialNo, b.relatedDocumentSerialNo)
    });

    return relatedDocs;
  }

  addDoc(event: MatChipInputEvent): void {
    // Add location only when MatAutocomplete is not open
    // To make sure this does not conflict with OptionSelected Event

    if (!this.matAutocompleteDoc?.isOpen) {
      const input = event.input;
      const value = event.value;

      // Reset the input value
      if (input) {
        input.value = '';
      }

      this.documentCtrl.setValue(null);
    }
  }

  removeDoc(document: RelatedDocument): void {
    const index = this.documents.indexOf(document);

    if (index >= 0) {
      this.documents.splice(index, 1);
    }
    this.setFormDirty();
  }

  selectedDoc(event: MatAutocompleteSelectedEvent): void {

    if (this.documents === null) { this.documents = []; }
    const selDoc = event.option.value;

    this.newDoc = {
      originalDocumentId: this.rsswa != undefined ? this.rsswa.id : 0,
      originalDocumentTypeId: FormType.RSSWA,
      relatedDocumentId: selDoc.id,
      relatedDocumentSerialNo: selDoc.name,
      relatedDocumentTypeId: selDoc.type
    };

    if (!this.documents.includes(this.newDoc)) {
      this.documents.push(this.newDoc);
      //  this.documentInput.nativeElement.value = '';
      this.documentCtrl.setValue(null);
    }
    // this.hideDescription();
  }


  //#region Custom Work Performers component functions:

  loadAllWorkPerformers() {
    this.perLoading = true;
    this._performers.Read().subscribe(data => {
      this.allWorkPerformers = data;
      this.filterWorkPerformers();
      this.perLoading = false;
    });
  }

  onPerChange(code?: string) {
    if (!code?.includes("Arrow")) {
      this.filterWorkPerformers();
      const filter = this.filteredWorkPerformers?.filter(w =>
        w.name.toLowerCase().includes(this.performerCtrl.value.toLowerCase()) ||
        w.code?.toLowerCase().includes(this.performerCtrl.value.toLowerCase()));

      this.filteredWorkPerformers = filter;
      this.createWorkPerformerGroups();
    }
  }


  filterWorkPerformers() {
    this.filteredWorkPerformers = [];

    this.allWorkPerformers?.map(w => {
      let found = false;
      if (this.workPerformers) {
        for (const d of this.workPerformers) {
          found = d.id === w.id;
          if (found) {
            break;
          }
        }
      }
      if (!found) {
        this.filteredWorkPerformers?.push(w);
      }
    });
    this.createWorkPerformerGroups();
  }

  createWorkPerformerGroups() {
    const groups: WorkPerformer[] = this.filteredWorkPerformers?.filter(w => w.type === 1) ?? [];
    const persons: WorkPerformer[] = this.filteredWorkPerformers?.filter(w => w.type === 2) ?? [];

    const workGroupGroups: WorkPerformerGroup = { name: 'Groups', workPerformers: groups };
    const workGroupPersons: WorkPerformerGroup = { name: 'Persons', workPerformers: persons };

    this.filteredPerformerGroups = [];
    if (groups.length > 0) {
      groups.sort((a, b) => {
        if (a.name < b.name) { return -1; }
        if (a.name > b.name) { return 1; }
        return 0;
      });
      this.filteredPerformerGroups.push(workGroupGroups);
    }
    if (persons.length > 0) {
      persons.sort((a, b) => {
        if (a.name < b.name) { return -1; }
        if (a.name > b.name) { return 1; }
        return 0;
      });
      this.filteredPerformerGroups.push(workGroupPersons);
    }
  }

  getWorkPerformers(): WorkPerformer[] {
    const workPerformers: WorkPerformer[] = [];

    if (this.rsswa) {
      if (!this.service.showAmendment)
        this.rsswa.workPerformers?.map(element => {
          workPerformers.push(element);
        });
      else
        this.rsswa?.rsswaAmendment?.workPerformers?.map(element => {
          workPerformers.push(element);
        });
    }
    return workPerformers;
  }

  addPer(event: MatChipInputEvent): void {
    // Add location only when MatAutocomplete is not open
    // To make sure this does not conflict with OptionSelected Event

    if (!this.matAutocompletePer?.isOpen) {
      const input = event.input;
      const value = event.value;

      // Reset the input value
      if (input) {
        input.value = '';
      }

      if (value != '') {
        const newPer: WorkPerformer = {
          id: 0,
          name: value,
          type: 1,
          rsswaId: this.rsswa?.id,
          roleId: 0,
          description: null,
          status: 1,
          code: null,
          persons: null
        };
        this.workPerformers?.push(newPer);
      }

      this.performerCtrl.setValue(null);
      this.filterWorkPerformers();
    }
  }

  removePer(wp: WorkPerformer): void {
    const index = this.workPerformers?.indexOf(wp) ?? -1;

    if (index >= 0) {
      this.workPerformers?.splice(index, 1);
    }
    this.filterWorkPerformers();
  }

  selectedPer(event: MatAutocompleteSelectedEvent): void {
    if (!this.workPerformers) { this.workPerformers = []; }
    const selPer = event.option.value;

    this.newPer = selPer;
    if (this.newPer) {
      this.newPer.id = 0;
      this.newPer.rsswaId = this.rsswa ? this.rsswa.id : 0;

      if (!this.workPerformers?.includes(this.newPer)) {
        this.workPerformers?.push(this.newPer);
        if (this.performerInput)
          this.performerInput.nativeElement.value = '';
        this.performerCtrl.setValue(null);
      }
    }
  }

  //#endregion

  //#region Checkboxes

  systemDesign() {
    if (this.tmpAccelerator || this.tmpBeamLine) {
      $('#tmpChkRPLCSet').removeClass('check-disabled');
      $('#tmpChkRSoftware').removeClass('check-disabled');
      if (!this.disabled) {
        this.chkRSSPLCSetCtrl.enable();
        this.chkRSSSoftwareCtrl.enable();
      }
    } else {
      $('#tmpChkRPLCSet').addClass('check-disabled');
      $('#tmpChkRSoftware').addClass('check-disabled');
      this.chkRSSPLCSetCtrl.disable();
      this.chkRSSSoftwareCtrl.disable();
    }

    if (this.tmpTopOff) {
      $('#tmpChkETIAccess').removeClass('check-disabled');
      $('#tmpChkTPLCSet').removeClass('check-disabled');
      $('#tmpChkTSoftware').removeClass('check-disabled');
      $('#tmpChktopOffInterlockSetPoint').removeClass('check-disabled');
      if (!this.disabled) {
        this.chkETIAccessCtrl.enable();
        this.chkTPLSetCtrl.enable();
        this.chkTSoftwareCtrl.enable();
        this.chkTopOffInterlockCtrl.enable();
      }
    } else {
      $('#tmpChkETIAccess').addClass('check-disabled');
      $('#tmpChkTPLCSet').addClass('check-disabled');
      $('#tmpChkTSoftware').addClass('check-disabled');
      $('#tmpChktopOffInterlockSetPoint').addClass('check-disabled');
      this.chkETIAccessCtrl.disable();
      this.chkTPLSetCtrl.disable();
      this.chkTSoftwareCtrl.disable();
      this.chkTopOffInterlockCtrl.disable();
    }
  }

  systemChanged() {

    if (this.tmpAccelerator || this.tmpBeamLine) {
      $('#tmpChkRPLCSet').removeClass('check-disabled');
      $('#tmpChkRSoftware').removeClass('check-disabled');
      if (!this.disabled) {
        this.chkRSSPLCSetCtrl.enable();
        this.chkRSSSoftwareCtrl.enable();
      }
    } else {
      $('#tmpChkRPLCSet').addClass('check-disabled');
      $('#tmpChkRSoftware').addClass('check-disabled');
      this.chkRSSPLCSetCtrl.disable();
      this.chkRSSSoftwareCtrl.disable();
      this.tmpChkRPLCSet = false;
      this.tmpChkRSoftware = false;
    }

    if (this.tmpTopOff) {
      $('#tmpChkETIAccess').removeClass('check-disabled');
      $('#tmpChkTPLCSet').removeClass('check-disabled');
      $('#tmpChkTSoftware').removeClass('check-disabled');
      $('#tmpChktopOffInterlockSetPoint').removeClass('check-disabled');
      if (!this.disabled) {
        this.chkETIAccessCtrl.enable();
        this.chkTPLSetCtrl.enable();
        this.chkTSoftwareCtrl.enable();
        this.chkTopOffInterlockCtrl.enable();
      }
    } else {
      $('#tmpChkETIAccess').addClass('check-disabled');
      $('#tmpChkTPLCSet').addClass('check-disabled');
      $('#tmpChkTSoftware').addClass('check-disabled');
      $('#tmpChktopOffInterlockSetPoint').addClass('check-disabled');
      this.chkETIAccessCtrl.disable();
      this.chkTPLSetCtrl.disable();
      this.chkTSoftwareCtrl.disable();
      this.chkTopOffInterlockCtrl.disable();
      this.tmpChkETIAccess = false;
      this.tmpChkTPLCSet = false;
      this.tmpChkTSoftware = false;
      this.tmpChktopOffInterlockSetPoint = false;
    }

    if (!this.tmpBeamLine) {
      this.tmpRsswaShutters = [];
    }

    if (!this.tmpAccelerator) {
      this.tmpRsswaAcceleratorLocations = [];
    }

    this.fillResources();
  }


  cancelDraft() {
    const serialNo = this.rsswa?.serialNo;
    const confirm = this.dialog.open(CancelDialogComponent, {
      width: '600px',
      data: { message: this.getMessage('CancelFormDialogTitle') }
    });

    confirm.afterClosed().subscribe(
      async data => {
        if (data?.accept && this.rsswa) {
          this.setFormDirty(false);
          await this.service.delete(this.rsswa.id).toPromise();
          await this.service.deleteSerialNo(serialNo ?? '').toPromise();
          this.alert.warning(serialNo + ' was Cancelled !');
          this.clear();
        }
      });
  }

  cancel() {
    const confirm = this.dialog.open(CancelDialogComponent, {
      width: '600px',
      data: {
        amendment: (this.rsswa?.status === 6 || this.rsswa?.status === 7 || this.rsswa?.status === 9)
      }
    });

    confirm.afterClosed().subscribe(
      async data => {
        if (data?.accept) {
          this.loading.emit(true);
          this.wfTaskSignature.taskId = this.cancelSignature?.taskID;
          this.wfTaskSignature.actionId = this.cancelSignature?.cancelID;
          this.wfTaskSignature.reason = data.text;

          await this._wf.sign(this.wfTaskSignature).toPromise().catch(error => this.alert.error(error));
          this.loading.emit(false);
          this.setFormDirty(false);
          this.service.showAmendment = false;
        }
      }
    );
  }

  // Workflow

  sign(taskId: number, actionId: number) {
    this.wfTaskSignature.taskId = taskId;
    this.wfTaskSignature.actionId = actionId;
    this._wf.sign(this.wfTaskSignature).toPromise().then(res => {
    }, error => {
      console.log(error);
      this.alert.defaultError();
    });
  }

  async editOlog() {
    if (this.rsswa?.id) {
      try {
        const data = await this.ologService.getOlog(FormType.RSSWA, this.rsswa.id, OlogNotificationType.Activate).toPromise();
        if (data) {
          const dialogRef = this.dialog.open(PartialOlogComponent, {
            height: "fit-content",
            width: "80%",
            data,
          });
          dialogRef.afterClosed().subscribe((isSaved) => {
            console.log("The dialog was closed");
            if (isSaved) {
              console.log(isSaved);
            }
          });
        }
      } catch (error) {
        console.log(error);
      }
    }
  }

  async submit_amendment() {
    this.loading.emit(true);
    const rsswaShutters: SaveRSSWAShutter[] = [];
    const rsswaAcceleratorLocations: SaveRSSWAAcceleratorLocation[] = [];
    const rsswaRestrictions: FormRestriction[] = [];
    const rsswaRelatedLinks: RelatedLinkBase[] = [];
    const rsswaPerformingWorkUsers: RSSWAPerformingWorkUser[] = [];

    if (!this.wfSignatures?.filter(x => !x.signed).length) {
      this.alert.error('You cant submit the amendment');
      return;
    }

    const taskID = this.wfSignatures.filter(x => !x.signed)[0].taskID;
    const actionID = this.wfSignatures.filter(x => !x.signed)[0].approveID;

    this.tmpRsswaShutters?.map(x => {
      rsswaShutters.push({
        id: x.id,
        rsswaID: x.rsswaID,
        resourceID: x.resourceID
      });
    });
    this.tmpRsswaAcceleratorLocations?.map(x => {
      rsswaAcceleratorLocations.push({
        id: x.id,
        rsswaID: x.rsswaID,
        resourceID: x.resourceID
      });
    });
    this.tmpRsswaRestrictions?.map(x => {
      rsswaRestrictions.push({
        id: x.id,
        formID: x.formID,
        restrictionID: x.restrictionID,
        text: x.text
      });
    });
    this.tmpRsswaRelatedLinks?.map(x => {
      rsswaRelatedLinks.push({
        id: x.id,
        formID: x.formID,
        text: x.text,
        url: x.url
      });
    });

    const saveRsswaSection1Resource: SaveRSSWAResource = {
      id: this.rsswa?.id,
      name: this.rsswa?.serialNo,
      description: this.tmpDescription,
      status: this.rsswa?.status,
      type: FormType.RSSWA,
      code: 'RSSWA',
      serialNo: this.rsswa?.serialNo,
      requesterId: this.tmpRequester ? this.tmpRequester : 0,
      startOn: this.tmpStartDate,
      completeOn: this.tmpEndDate,
      beamlineRSS: this.tmpSysAffectedBL,
      acceleratorRSS: this.tmpSysAffectedAcc,
      tombis: this.tmpSysAffectedTombis,
      affectedSystems: [],
      etiAccess: this.tmpChkETIAccess,
      tombisPLCSet: this.tmpChkTPLCSet,
      tombisSoftware: this.tmpChkTSoftware,
      repairRequired: this.tmpChkRepair,
      rssPLCSet: this.tmpChkRPLCSet,
      rssSoftware: this.tmpChkRSoftware,
      topOffInterlockSetPoint: this.tmpChktopOffInterlockSetPoint,
      workPerformers: this.workPerformers,
      relatedDocuments: this.documents,
      topOff: this.tmpTopOff,
      radiationMonitoringSystem: this.tmpRadiationMonitoringSystem,
      beamLine: this.tmpBeamLine,
      accelerator: this.tmpAccelerator,
      rsswaShutters,
      rsswaAcceleratorLocations,
      rsswaOtherSystems: this.tmpRsswaOtherSystems,
      rsswaRestrictions,
      functionalTest: this.tmpFunctionalTest,
      provisionalTestingRequired: this.tmpProvisionalTestingRequired,
      rsswaRelatedLinks,
      rsswaPerformingWorkUsers
    };
    if (this.isValid(2)) {
      this.setFormDirty(false);
      this.service.update(saveRsswaSection1Resource).toPromise().then(() => {
        this.setFormDirty(false);
        this.wfTaskSignature.taskId = taskID;
        this.wfTaskSignature.actionId = actionID;
        this._wf.sign(this.wfTaskSignature).toPromise().then(() => {
          this.loading.emit(false);
        },
          error => {
            this.loading.emit(false);
            this.alert.defaultError();
          });
      },
        error => {
          this.loading.emit(false);
          this.alert.defaultError();
        });
    } else {
      this.loading.emit(false);
    }
  }

  verifyActions(section: number) {
    this.showCancelDraft = false;
    this.showCancelForm = false;
    this.showSubmit = false;
    this.showUnSubmit = false;

    if (this.rsswa?.wfTableID) {
      const signatures = this.rsswa?.wfTable?.wfTableLocal?.wfSectionLocals.map(s => s.wfSignatures)?.flatMap(a => a || []);

      this.showCancelDraft = this.hasPrivilege(this.privilegeEnum.RSSWACancel) &&
        (((this.rsswa.status == this.formStatusEnum.New || this.rsswa.status == this.formStatusEnum.Draft) && this.rsswa.editingById == this.getCurrentUser()?.id) || (this.rsswa.status == this.formStatusEnum.WorkApprovals && !signatures?.some(s => s.sectionNumber == 3 && s.signed)));

      this.cancelSignature = signatures?.find(s => s.sectionNumber == section && s.cancelID);
      if (this.cancelSignature && !this.showCancelDraft) {
        this.showCancelForm = this.hasRoles(this.cancelSignature.roles) && this.hasPrivilege(section == 1 ? this.privilegeEnum.RSSWACancel : this.privilegeEnum.RSSWACancelAmendment) && (section == 1 && (this.rsswa.status == this.formStatusEnum.WorkApprovals || this.rsswa.status == this.formStatusEnum.Pending) || section == 9 && this.rsswa.status == this.formStatusEnum.Amendment);
      } else {
        this.showCancelForm = false;
      }

      const submitSignature = signatures?.find(s => s.sectionNumber == section);
      this.wfSignatures = this.rsswa?.wfTable?.wfTableLocal?.wfSectionLocals.find(s => s.number == section)?.wfSignatures;
      this.showSubmit = submitSignature?.approveEnabled && section === 1 && !submitSignature.signed;

      this.unSubmitSignature = this.rsswa?.wfTable?.wfTableLocal?.wfSectionLocals.find(s => s.number == 3)?.wfSignatures?.find(s => s.disapproveEnabled);
      this.showUnSubmit = submitSignature?.signedBy?.id == this.getCurrentUser()?.id && (this.unSubmitSignature?.disapproveEnabled ?? false) && !signatures?.some(s => s.sectionNumber == 3 && s.signed);
    }
  }

  async disapprove() {
    if (this.unSubmitSignature) {
      const unsubmitTask: WFTaskSignatureResource = {
        taskId: this.unSubmitSignature?.taskID,
        actionId: this.unSubmitSignature?.disapproveID
      }
      this.loading.emit(true);
      await this._wf.sign(unsubmitTask).toPromise();
    }
  }

  fillResources() {
    this.tmpResourceIds = [];
    this.tmpResourceIds = this.tmpResourceIds.concat(this.tmpRsswaShutters?.map(x => x.resourceID) ?? []);
    this.tmpResourceIds = this.tmpResourceIds.concat(this.tmpRsswaAcceleratorLocations?.map(x => x.resourceID) ?? []);
    if (this.tmpRadiationMonitoringSystem && this.sr02areasrrf)
      this.tmpResourceIds.push(this.sr02areasrrf.id);
    if (this.tmpTopOff && this.topOff) {
      this.tmpResourceIds.push(this.topOff.id);
    }
    if (this.tmpRsswaOtherSystems?.length)
      this.tmpResourceIds = this.tmpResourceIds.concat(this.accelerators.map(x => x.id));
    const r = this.tmpRsswaRestrictions?.filter(x => x.restrictionID)?.map(x => x.restriction) ?? [];
    this.restrictionAvailableAutocomplete?.refresh(this.tmpResourceIds, r);

  }

  restrictionsChanged(e: FormRestriction[]) {
    this.tmpRsswaRestrictions = e;
    this.setFormDirty();
    this.fillResources();
  }

  getResources(e: RSSWAShutter[] | RSSWAAcceleratorLocation[] | undefined): Resource[] {
    return e?.map(x => x.resource) ?? [];
  }

  onRsswaShuttersEdited(e: Resource[]) {
    if (!this.tmpRsswaShutters) this.tmpRsswaShutters = [];
    e.map(r => {
      if (!this.tmpRsswaShutters?.find(s => s.resourceID == r.id))
        this.tmpRsswaShutters?.push({ id: 0, rsswaID: this.rsswa?.id, resource: r, resourceID: r.id });
    });
    this.tmpRsswaShutters?.map((s, i) => {
      if (!e.find(r => r.id == s.resourceID))
        this.tmpRsswaShutters?.splice(i, 1);
    });
    this.setFormDirty();
    this.fillResources();
  }

  onRsswaAcceleratorLocationsEdited(e: Resource[]) {
    if (!this.tmpRsswaAcceleratorLocations) this.tmpRsswaAcceleratorLocations = [];
    e.map(r => {
      if (!this.tmpRsswaAcceleratorLocations?.find(s => s.resourceID == r.id))
        this.tmpRsswaAcceleratorLocations?.push({ id: 0, rsswaID: this.rsswa?.id, resource: r, resourceID: r.id });
    });
    this.tmpRsswaAcceleratorLocations?.map((s, i) => {
      if (!e.find(r => r.id == s.resourceID))
        this.tmpRsswaAcceleratorLocations?.splice(i, 1);
    });
    this.setFormDirty();
    this.fillResources();
  }

  onRsswaOtherSystemsEdited(e: any) {
    this.tmpRsswaOtherSystems = e;
    this.setFormDirty();
    this.fillResources();
  }


  onRelatedLinksEdited(e: any) {
    this.tmpRsswaRelatedLinks = e;
    this.setFormDirty();
  }

  descriptionChanged(e: any) {
    this.tmpDescription = e;
    this.setFormDirty();
  }

  createRSSFunctionalTest() {
    this.dialog.open(ChecklistExecPopupComponent, {
      width: '75vw',
      // height: '85vh',
      data: {
        rsswa: this.rsswa,
        amendment: this.service.showAmendment,
      } as ChecklistBuilderPopupParameters,
    }).afterClosed().toPromise().then(result => {
      if (result && this.rsswa) {
        this.service.getById(this.rsswa.id).toPromise().then(async data => {
          // this.service.currentDocument = data;
          this.refresh();
          // this.refreshRSSWAFunctionalTests.emit();
        });
      }
    });
  }

  confirmRSSFunctionalTest() {
    if (this.tmpFunctionalTest) {
      this.dialog.open(YesNoDialogComponent, {
        width: '500px',
        data: {
          message: 'You will need to add at least one RSS functional test, do you want to continue?',
        },
      }).afterClosed().subscribe(data => {
        if (!data) {
          this.tmpFunctionalTest = false;
        }
      });
    }
  }

  showAlert(e: any) {
    this.alert.error(e);
  }

  setUnsubmit() {

  }

  diffRelatedDocuments() {
    if (this.rsswa && this.service.showAmendment) {
      const r1 = this.rsswa?.relatedDocuments?.map(r => r.relatedDocumentSerialNo).sort((a, b) => utils.sort(a, b));
      const r2 = this.documents?.map(r => r.relatedDocumentSerialNo).sort((a, b) => utils.sort(a, b));
      return utils.JSONstringify(r1) != utils.JSONstringify(r2);
    }
    return false;
  }

  diffWorkPerformers() {
    if (this.rsswa && this.service.showAmendment) {
      const r1 = this.rsswa?.workPerformers?.map(r => r.roleId).sort((a, b) => utils.sort(a, b));
      const r2 = this.workPerformers?.map(r => r.roleId).sort((a, b) => utils.sort(a, b));
      return utils.JSONstringify(r1) != utils.JSONstringify(r2);
    }
    return false;
  }

  diffShutters() {
    if (this.rsswa && this.service.showAmendment) {
      const r1 = this.rsswa?.rsswaShutters?.map(r => r.resourceID).sort((a, b) => utils.sort(a, b));
      const r2 = this.tmpRsswaShutters?.map(r => r.resourceID).sort((a, b) => utils.sort(a, b));
      return utils.JSONstringify(r1) != utils.JSONstringify(r2);
    }
    return false;
  }

  diffAccelerators() {
    if (this.rsswa && this.service.showAmendment) {
      const r1 = this.rsswa?.rsswaAcceleratorLocations?.map(r => r.resourceID).sort((a, b) => utils.sort(a, b));
      const r2 = this.tmpRsswaAcceleratorLocations?.map(r => r.resourceID).sort((a, b) => utils.sort(a, b));
      return utils.JSONstringify(r1) != utils.JSONstringify(r2);
    }
    return false;
  }

  diffOtherSystems() {
    if (this.rsswa && this.service.showAmendment) {
      const r1 = this.rsswa?.rsswaOtherSystems?.map(r => r.text).sort((a, b) => utils.sort(a, b));
      const r2 = this.tmpRsswaOtherSystems?.map(r => r.text).sort((a, b) => utils.sort(a, b));
      return utils.JSONstringify(r1) != utils.JSONstringify(r2);
    }
    return false;
  }

  diffRelatedLinks() {
    if (this.rsswa && this.service.showAmendment) {
      const r1 = this.rsswa?.rsswaRelatedLinks?.map(r => { return { t: r.text, u: r.url }; }).sort((a, b) => utils.sort(a.t, b.t));
      const r2 = this.tmpRsswaRelatedLinks?.map(r => { return { t: r.text, u: r.url }; }).sort((a, b) => utils.sort(a.t, b.t));
      return utils.JSONstringify(r1) != utils.JSONstringify(r2);
    }
    return false;
  }

  diffRestrictions() {
    if (this.rsswa && this.service.showAmendment) {
      const r1 = this.rsswa?.rsswaRestrictions?.map(r => r.restrictionID).sort((a, b) => utils.sort(a, b));
      const r2 = this.tmpRsswaRestrictions?.map(r => r.restrictionID).sort((a, b) => utils.sort(a, b));
      this.tmpRsswaRestrictions?.map(r => {
        r.hilite = !r1?.find(s => s == r.restrictionID);
      });
      return utils.JSONstringify(r1) != utils.JSONstringify(r2);
    }
    return false;
  }

}

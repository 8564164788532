import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PVData } from '../cl-image-upload-table-data.component';
import { ClImageUploadTableDataService } from '../../cl-image-upload-table-data.service';
import { utils } from 'src/app/modules/libs/utils';
import { PVInfoService } from 'src/app/services/pv-info/pv-info.service';
import { Observable, Subscription } from 'rxjs';
import { PV } from 'src/app/services/pv-info/pv-info';
import { Store } from '@ngxs/store';

@Component({
  selector: 'app-cl-editor-table-channels-add',
  templateUrl: './cl-editor-table-channels-add.component.html',
  styleUrls: ['./cl-editor-table-channels-add.component.scss']
})
export class ClEditorTableChannelsAddComponent implements OnInit {

  public formGroup!: FormGroup;
  public get nameCtrl(): AbstractControl | null { return this.formGroup.get('nameCtrl'); }
  public get labelCtrl(): AbstractControl | null { return this.formGroup.get('labelCtrl'); }

  pVs?: PV[];
  pVsFiltered?: PV[];
  pVs$!: Observable<PV[]>;
  pVsSubs!: Subscription;

  value?: string;
  title?: string;

  constructor(
    public dialogRef: MatDialogRef<ClEditorTableChannelsAddComponent>,
    @Inject(MAT_DIALOG_DATA) public channel: PVData,
    private formBuilder: FormBuilder,
    private service: ClImageUploadTableDataService,
    private pvService: PVInfoService,
    private store: Store
  ) { }

  ngOnInit(): void {
    this.loadPVs();
    this.title = this.channel ? 'Edit' : 'Add';
    this.initializeForm();

    if (this.channel.name && !this.value) { this.getData(); }
  }

  initializeForm() {
    this.formGroup = this.formBuilder.group({
      nameCtrl: [{ value: '' }, [Validators.required, utils.IsWhiteSpaceReactiveForm]],
      labelCtrl: [{ value: '' }, [Validators.required]]
    });
    if (!this.channel) {
      this.channel = { pos: 99, name: '', label: '' };
    }
    this.nameCtrl?.setValue(this.channel.name);
    this.labelCtrl?.setValue(this.channel.label);
  }

  loadPVs() {
    this.pVs$ = this.store.select(state => state.PV.data);
    this.pVsSubs = this.pVs$.subscribe(data => {
      if (data?.length) {
        this.pVs = data;
        if (this.channel.name) {
          const value = this.pVs.find(p => p.pvName == this.channel.name)?.value;
          if (value) this.value = this.service.formatValue(value);
        }
      }
    });
  }

  getErrorMsg(control?: AbstractControl | null): string | null {
    if (control?.hasError('required')) { return 'You must enter a value'; }
    if (control?.hasError('hasWhiteSpace')) { return 'Enter a valid value'; }
    if (control?.hasError('invalid')) { return 'Invalid Channel name'; }
    return null;
  }

  save() {
    this.channel.name = this.nameCtrl?.value;
    this.channel.label = this.labelCtrl?.value;
    this.channel.value = null;
    this.dialogRef.close(this.channel);
  }

  getData() {
    if (this.nameCtrl?.value) {
      this.pvService.subscribe(this.nameCtrl.value).subscribe(data => {
        if (data)
          if (!data.value.includes('ERROR')) {
            this.value = this.service.formatValue(data.value);
            this.nameCtrl?.setErrors(null);
          }
          else {
            this.channel = { name: this.nameCtrl?.value } as PVData;
            this.nameCtrl?.setErrors({ invalid: true });
          }
      }, error => {
        this.nameCtrl?.setErrors({ invalid: true });
        console.log(error);
      }
      );
    }
  }

}

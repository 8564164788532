import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { DeletedStepsRefresh } from './deleted-step.action';
import { WFStepLocalResource } from 'src/app/services/work-flow/work-flow';
import { ChecklistBuilderService } from '../../checklist-builder/checklist-builder.service';

export class DeletedStepsStateModel {
  data: WFStepLocalResource[] = [];
}

@State<DeletedStepsStateModel>({
  name: 'DeletedWFStepLocals',
  defaults: {
    data: [],
  },
})
@Injectable()
export class DeletedStepsState {
  constructor(private service: ChecklistBuilderService) { }

  @Selector()
  static read(state: DeletedStepsStateModel) {
    return state.data;
  }

  @Action(DeletedStepsRefresh)
  refresh({ patchState }: StateContext<DeletedStepsStateModel>, { checklistTemplateID }: DeletedStepsRefresh) {
    this.service.getDeletedSteps(checklistTemplateID).toPromise().then((data) => {
      patchState({ data });
    },
      (error) => console.error(error)
    );
  }

  // @Action(DeletedStepsCreate)
  // create({ getState, patchState }: StateContext<DeletedStepsStateModel>, { item }: DeletedStepsCreate) {
  //   patchState({
  //     data: [...getState().data, item]
  //   });
  // }

  // @Action(DeletedStepsDelete)
  // delete({ getState, patchState }: StateContext<DeletedStepsStateModel>, { logID }: DeletedStepsDelete) {
  //   patchState({ data: getState().data.filter((x) => x.logID !== logID) });
  // }
}

import { Component, Input, OnInit } from '@angular/core';
import { MessageBannerService } from 'src/app/components/messages/services/message-banner.service';
import { User } from 'src/app/components/catalogs/user-catalog/services/user';

@Component({
  selector: 'absi-section-signature',
  templateUrl: './absi-section-signature.component.html',
  styleUrls: ['./absi-section-signature.component.scss']
})
/** absi-section-signature component*/
export class AbsiSectionSignatureComponent implements OnInit {

  @Input() date?: Date | null;
  @Input() user?: User | null;
  @Input() catl?: number | null;

  userName?: string;
  inspector: string = '';
  signatureDate: string = '';
  pendingActions: string = '';

  /** absi-section-signature ctor */
  constructor(private dMessages: MessageBannerService) {
  }
  ngOnInit(): void {
    this.loadMessagesToShow();
  }

  loadMessagesToShow() {
    this.inspector = this.dMessages.get('absi_signatureInspector')?.description;
    this.signatureDate = this.dMessages.get('absi_signatureDate')?.description;
    this.pendingActions = this.dMessages.get('absi_signaturePendingActions')?.description;
  }
}

<div class="row no-gutters pb-3">
  <div class="col-12">
    <div class="text-right small">
      <mat-slide-toggle
        color="warn"
        [(ngModel)]="showInactive"
        (change)="showActiveInactive()"
        >Show Inactive</mat-slide-toggle
      >
    </div>
  </div>
</div>
<div class="mat-container left-table-container">
  <mat-table [dataSource]="dataSource" matSort>
    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Name</mat-header-cell>
      <mat-cell
        class="green bold"
        *matCellDef="let element"
        [ngClass]="{
          yellow: element.pendingUser && (canApprove || canCreate),
          red: element.details.user.status == 0
        }"
      >
        <span
          [innerHTML]="element.details.user.name | highlightSearch : filter"
        ></span>
      </mat-cell>
    </ng-container>
    <!-- <ng-container matColumnDef="phone">
      <mat-header-cell *matHeaderCellDef mat-sort-header
        >Phone Ext</mat-header-cell
      >
      <mat-cell
        mat-cell
        class="green"
        *matCellDef="let element"
        [ngClass]="{
          yellow: element.pendingUser && (canApprove || canCreate),
          red: element.details.user.status == 0
        }"
      >
        {{ element.details.user.phoneExt }}
      </mat-cell>
    </ng-container> -->
    <ng-container matColumnDef="roles">
      <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header
        >Roles</mat-header-cell
      >
      <mat-cell mat-cell *matCellDef="let element" class="pt-2">
        <role-chips [roleCodes]="getRoleCodes(element)"></role-chips>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="status">
      <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header
        >Status</mat-header-cell
      >
      <mat-cell
        mat-cell
        class="green"
        *matCellDef="let element"
        [ngClass]="{
          yellow: element.pendingUser && (canApprove || canCreate),
          red: element.details.user.status == 0
        }"
      >
        <span *ngIf="element.pendingUser">
          {{
            element.pendingUser?.statusName +
              " - " +
              element.pendingUser.actionName
          }}
        </span>
        <span *ngIf="element.details.user.status == 0"> Inactive </span>
      </mat-cell>
    </ng-container>
    <mat-header-row
      *matHeaderRowDef="displayedColumns; sticky: true"
    ></mat-header-row>
    <mat-row
      #matrow
      *matRowDef="let row; columns: displayedColumns"
      [ngClass]="{ 'mat-row-highlight': selectedRowIndex == row.id }"
      [attr.position]="getPosition(row)"
      (click)="selectedRow(row)"
    ></mat-row>
  </mat-table>
</div>

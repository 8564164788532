import { Component, Inject } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActionItem } from 'src/app/common/models/action-item';
import { ActionItemService } from '../action-item/action-item.service';

@Component({
  selector: 'catl-report',
  templateUrl: './catl-report.component.html',
  styleUrls: ['./catl-report.component.scss']
})
/** catl-report component*/
export class CatlReportComponent {

  actionItems?: ActionItem[];
  loading: boolean = true;
  /** catl-report ctor */
  constructor(
    public dialogRef: MatDialogRef<CatlReportComponent>,
    @Inject(MAT_DIALOG_DATA) public currentDocument: any,
    private _actionItems: ActionItemService,
    private sanitizer: DomSanitizer
  ) {

  }

  ngOnInit() {
    this.getData();
  }

  displaySection(section: any) {
    return section ? section.sectionName : null;
  }

  getData() {
    this._actionItems.readByForm(this.currentDocument.id).subscribe(data => {
      this.actionItems = data;
      this.actionItems.map(actionItem => {
        actionItem.html = this.sanitizer.bypassSecurityTrustHtml(actionItem.description != undefined ? actionItem.description : '');
      });
      this.loading = false;
    });
  }

  close() {
    this.dialogRef.close();
  }
}

import { Component, Input, OnInit, OnChanges, SimpleChanges, OnDestroy, Injector, EventEmitter, Output, } from "@angular/core";
import { Checklist, ChecklistTemplate, ChecklistTemplateStatus, TemplateType } from "src/app/components/checklists/checklists";
import { Router } from "@angular/router";
import { BaseComponent } from "src/app/common/base/base.component";
import { FormControl } from "@angular/forms";
import { Observable, Subscription } from "rxjs";
import { FormStatusEnum, FormType } from "src/app/common/enumerations/enumerations";
import { ChecklistBuilderService } from "src/app/components/checklists/checklist-builder/checklist-builder.service";
import { ChecklistsService } from "src/app/components/checklists/checklists.service";
import { ChecklistTemplatesRefreshByDocument } from "src/app/components/checklists/store/checklist-templates/checklist-templates.action";
import { ChecklistsCreate } from "src/app/components/checklists/store/checklists/checklists.action";
import { Review, SaveReview } from "src/app/components/reviews/reviews.models";
import { ReviewsService } from "src/app/components/reviews/reviews.service";
import { ReviewBoardsUpdate } from "src/app/components/reviews/store/review-boards/review-boards.action";
import { ReviewsUpdate } from "src/app/components/reviews/store/reviews/reviews.action";
import { PrivilegeEnum } from "src/app/services/role-privilege/privilege-enum";
import { YesNoDialogComponent } from "src/app/controls/yes-no-dialog/yes-no-dialog.component";

@Component({
  selector: "reviews-boards-reviews-details-template",
  templateUrl: "./reviews-details-template.component.html",
  styleUrls: ["./reviews-details-template.component.scss"],
})
export class ReviewsBoardsReviewsDetailsTemplateComponent extends BaseComponent
  implements OnInit, OnChanges, OnDestroy {
  @Input() review?: Review;
  @Output() isLoading = new EventEmitter<boolean>();

  isTemplateEditing!: boolean;
  isChecklistEditing!: boolean;
  checklistTemplateCtrl = new FormControl();
  checklistCtrl = new FormControl();

  templateTypes$!: Observable<TemplateType[]>;
  templateTypes!: TemplateType[];
  templateTypesSubs!: Subscription;

  checklistTemplate!: ChecklistTemplate;
  checklistTemplates$!: Observable<ChecklistTemplate[]>;
  checklistTemplatesSubs!: Subscription;

  checklistTemplates!: ChecklistTemplate[];
  checklistTemplatesFiltered!: ChecklistTemplate[];
  templateStatuses!: ChecklistTemplateStatus[];
  templateStatus?: ChecklistTemplateStatus;

  checklists$!: Observable<Checklist[]>;
  checklists!: Checklist[];
  checklistsSubs!: Subscription;
  checklistsFiltered?: Checklist[];

  reviews$!: Observable<Review[]>;
  reviews!: Review[];
  reviewsSubs!: Subscription;

  privilege = PrivilegeEnum;

  constructor(
    protected override injector: Injector,
    private service: ReviewsService,
    private _builder: ChecklistBuilderService,
    private _checklist: ChecklistsService,
    private router: Router
  ) {
    super(injector);
  }

  override ngOnDestroy(): void {
    this.templateTypesSubs?.unsubscribe();
    this.checklistTemplatesSubs?.unsubscribe();
    this.checklistsSubs?.unsubscribe();
    super.ngOnDestroy();
  }


  ngOnChanges(changes: SimpleChanges): void {
    if (this.review?.reviewType.templateType.documentTypeID)
      this.store.dispatch(new ChecklistTemplatesRefreshByDocument(this.review?.reviewType?.templateType?.documentTypeID));
    this.loadReviews();
    this.loadChecklistTemplates();
    this.loadChecklists();
  }

  ngOnInit(): void {
    this.loadStatuses();
  }

  checkPrivilege(privilege: PrivilegeEnum) {
    return this.service.checkPrivilege(privilege, this.review?.reviewType.reviewBoardType, this.review?.reviewStatus
    );
  }

  edit() {
    this.isTemplateEditing = true;
  }

  save() {
    this.isLoading.emit(true);
    const saveReview: SaveReview = this.utils.Serialize(this.review) as SaveReview;
    saveReview.checklistTemplateID = this.checklistTemplateCtrl.value
      ? this.checklistTemplateCtrl.value.id
      : null;
    this.service
      .updateReview(saveReview)
      .toPromise()
      .then((data) => {
        this.review = data;
        this.templateStatus = this.templateStatuses?.find(
          (x) => x.statusEnum == this.review?.checklistTemplate?.status
        );
        if (this.review) {
          this.store.dispatch(new ReviewsUpdate(this.review.id, this.review));
          this.store.dispatch(
            new ReviewBoardsUpdate(
              this.review.reviewCycle.reviewBoardID,
              this.review.reviewCycle.reviewBoard
            )
          );
        }
        this.isTemplateEditing = false;
        this.isLoading.emit(false);
      });
  }

  cancel() {
    this.isTemplateEditing = false;
  }

  async loadStatuses() {
    if (this._builder.checklistTemplateStatuses.length == 0) {
      await this._builder.getStatuses().toPromise().then((data) => {
        if (data)
          this.templateStatuses = data.sort((a, b) => a.order - b.order);
        this._builder.checklistTemplateStatuses = this.templateStatuses;
        this.templateStatus = this.templateStatuses?.find((x) => x.statusEnum == this.review?.checklistTemplate?.status);
      });
    } else {
      this.templateStatuses = this._builder.checklistTemplateStatuses;
      this.templateStatus = this.templateStatuses?.find(
        (x) => x.statusEnum == this.review?.checklistTemplate?.status
      );
    }
  }

  loadReviews() {
    this.reviews$ = this.store.select(state => state.Reviews.data);
    this.reviewsSubs = this.reviews$.subscribe(data => {
      this.reviews = data;
    });
  }

  loadChecklistTemplates() {
    this.checklistTemplates$ = this.store.select(state => state.ChecklistTemplates.data);
    this.checklistTemplatesSubs = this.checklistTemplates$.subscribe(data => {
      if (data.length) {
        this.checklistTemplates = data.filter(x => x.templateTypeID == this.review?.reviewType.templateTypeID && x.status === 1 && (x.resourceID == this.review?.reviewCycle.reviewBoard.resourceID || !x.resourceID));
        this.checklistTemplatesFiltered = this.checklistTemplates;
        if (this.checklistTemplatesFiltered?.length == 1) {
          this.checklistTemplateCtrl.setValue(
            this.checklistTemplatesFiltered[0]
          );
        } else {
          this.checklistTemplateCtrl.setValue(null);
        }
        if (this.checklistTemplatesFiltered?.length == 1) {
          this.checklistTemplateCtrl.setValue(
            this.checklistTemplatesFiltered[0]
          );
        } else {
          this.checklistTemplateCtrl.setValue(null);
        }
      }
    });
  }

  loadChecklists() {
    this.checklists$ = this.store.select(state => state.Checklists.data);
    this.checklistsSubs = this.checklists$.subscribe(data => {
      if (data?.length) {
        this.checklists = data;
        const filtered = this.checklists.filter(x => x.checklistTemplate?.templateTypeID == this.review?.reviewType.templateTypeID).sort((a, b) => this.utils.sort(a.serialNo, b.serialNo, false));
        filtered.map(c => c.review = this.reviews.find(r => r.checklistID == c.id));
        this.checklistsFiltered = filtered.filter(f => !f.review);
      }
    });
  }

  getStatusClass(css?: string) {
    return css + "-background";
  }

  //called from reviews-boards-reviews-details
  createChecklist() {
    this.isLoading.emit(true);
    const checklist = {
      id: 0,
      resourceID: this.review?.checklistTemplate.resourceID,
      checklistTemplateID: this.review?.checklistTemplateID,
      status: FormStatusEnum.Draft,
    } as Checklist;
    this._checklist.post(checklist).toPromise().then(data => {
      if (data) {
        const checklist = data as Checklist;
        this.alert.success(this.getMessage("NewChecklistCreated").description.replace("{serialNo}", checklist.serialNo ?? ''));
        this.store.dispatch(new ChecklistsCreate(data));
        const review = this.utils.Serialize(this.review);
        if (review) {
          review.checklistID = checklist.id;
          this.service.updateReview(review).toPromise().then((reviewUpdated) => {
            this.review = reviewUpdated;
            if (this.review)
              this.store.dispatch(new ReviewsUpdate(this.review.id, this.review));
          });
        }
        this.isLoading.emit(false);
      }
    });
  }

  editChecklist() {
    this.isChecklistEditing = true;
  }

  deleteChecklist() {
    //unlink existing checklist
    this.isLoading.emit(true);
    const confirm = this.dialog.open(YesNoDialogComponent, {
      width: '400px',
      data: {
        message: this.getMessage("ReviewChecklistDeleted").description,
        icon: 'warn'
      }
    });
    confirm.afterClosed().subscribe(async res => {
      if (res) {
        const review = this.utils.Serialize(this.review) as SaveReview;
        if (review) {
          review.checklistID = null;
          await this.service
            .updateReview(review)
            .toPromise()
            .then((reviewUpdated) => {
              this.review = reviewUpdated;
              if (this.review)
                this.store.dispatch(new ReviewsUpdate(this.review.id, this.review));
            });
        }
        this.isLoading.emit(false);
      }
      else this.isLoading.emit(false);
    });
  }

  async saveChecklist() {
    //save new checklist
    this.isLoading.emit(true);
    this.isChecklistEditing = false;
    const review = this.utils.Serialize(this.review);
    if (review) {
      review.checklistID = this.checklistCtrl.value.id;
      this.service.updateReview(review).toPromise().then((reviewUpdated) => {
        if (reviewUpdated) {
          this.review = reviewUpdated;
          this.store.dispatch(new ReviewsUpdate(this.review.id, this.review));
        }
      });
    }
    this.isLoading.emit(false);
  }

  cancelChecklist() {
    this.isChecklistEditing = false;
  }

  openChecklistTemplate(e: any, checklistTemplate?: ChecklistTemplate) {
    e.stopPropagation();
    if (checklistTemplate) {
      this._builder.currentTemplateType = null;
      const newRelativeUrl = '/checklist-builder/200';
      localStorage.setItem('checklistTemplateID', checklistTemplate.id.toString());
      this.router.navigate([newRelativeUrl]);
    }
  }

  openChecklist(e: any, checklist?: Checklist) {
    e.stopPropagation();
    if (checklist) {
      // this._checklist.currentDocument = null;
      const newRelativeUrl = '/checklist-exec/200';
      this.setOpenDocument(FormType.RCP, checklist.id);
      this.router.navigate([newRelativeUrl]);
    }
  }

  checkStatus() {
    return (this.review?.checklistTemplate?.status != 1)
  }

}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { ScfV2Component } from './components/scf-v2/scf-v2.component';
import { RsswaComponent } from './components/rsswa/rsswa.component';
import { UnsavedChangesGuard } from './common/guards/unsaved-changes.guard';
import { EbComponent } from './components/eb/eb.component';
import { TemplateComponent } from './components/template/template.component';
import { RolesComponent } from './components/catalogs/roles/roles.component';
import { PpstbComponent } from './components/ppstb/ppstb.component';
import { KEComponent } from './components/kenable/kenable.component';
import { AbsiBuilderComponent } from './components/absi/absi-builder/absi-builder.component';
import { AbsiComponent } from './components/absi/absi.component';
import { PendingApprovalsV2Component } from './components/pending-approvals-v2/pending-approvals-v2.component';
import { MenuConfigComponent } from './components/menu-config/menu-config.component';
import { RedirectComponent } from './components/redirect/redirect.component';
import { OlogComponent } from './components/olog/olog.component';

const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: 'home', component: HomeComponent },
  { path: 'scf', component: ScfV2Component, canDeactivate: [UnsavedChangesGuard] },
  { path: 'rsswa', component: RsswaComponent, canDeactivate: [UnsavedChangesGuard] },
  { path: 'eb2', component: EbComponent, canDeactivate: [UnsavedChangesGuard] },
  { path: 'eb', component: EbComponent, canDeactivate: [UnsavedChangesGuard] },
  { path: 'ppstb', component: PpstbComponent, canDeactivate: [UnsavedChangesGuard] },
  { path: 'template', component: TemplateComponent },
  { path: 'roles', component: RolesComponent, canDeactivate: [UnsavedChangesGuard] },
  { path: 'kenable', component: KEComponent, canDeactivate: [UnsavedChangesGuard] },
  { path: 'ke', component: KEComponent, canDeactivate: [UnsavedChangesGuard] },
  { path: 'absi-builder', component: AbsiBuilderComponent, canDeactivate: [UnsavedChangesGuard] },
  { path: 'absi', component: AbsiComponent, canDeactivate: [UnsavedChangesGuard] },
  { path: 'pending-approvals', component: PendingApprovalsV2Component },
  { path: 'menu-config', component: MenuConfigComponent, canDeactivate: [UnsavedChangesGuard] },
  { path: 'redirect/:serialNo', component: RedirectComponent },
  { path: 'olog', component: OlogComponent },

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { Component, Inject, Injector, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { ChecklistBuilderService } from 'src/app/components/checklists/checklist-builder/checklist-builder.service';
import { ChecklistTempComponent } from 'src/app/components/checklists/checklist-temp/checklist-temp.component';
import { TemplateType, ChecklistTemplate, TemplateRolePermission, Checklist } from 'src/app/components/checklists/checklists';
import { ChecklistsService } from 'src/app/components/checklists/checklists.service';
import { TemplateTypesRefresh } from 'src/app/components/checklists/store/template-types/template-types.action';
import { TemplateTypesStoreAndListenersService } from 'src/app/components/checklists/store/template-types/template-types.store';
import { ScheduleStatusEnum } from 'src/app/components/schedules/models/enums';
import { Schedule } from 'src/app/components/schedules/models/schedule';
import { AlertService } from 'src/app/services/alert/alert.service';
import { Resource } from 'src/app/components/catalogs/beamline-catalog/resource/resources';
import { ResourcesService } from 'src/app/components/catalogs/beamline-catalog/resource/resources.service';
import { RSSFunctionalTestService } from 'src/app/services/rss-functional-test/rss-functional-test.service';
import { WFSectionLocalResource } from 'src/app/services/work-flow/work-flow';
import { RSSFunctionalTest } from '../../rss-functional-test';
import { RSSWAResource } from '../../rsswa';
import { BaseComponent } from '../../../../common/base/base.component';
import { ResourceAutocompleteComponent } from 'src/app/controls/resources/resource-autocomplete/resource-autocomplete.component';
import { DocumentType } from 'src/app/services/documents/documents';
import { ChecklistBuilderDuplicateComponent } from 'src/app/components/checklists/checklist-builder/checklist-builder-duplicate/checklist-builder-duplicate.component';
// import { AlertService } from 'src/app/controls/alert/alert.service';
// import { ResourceAutocompleteComponent } from 'src/app/controls/resources/resource-autocomplete/resource-autocomplete.component';
// import { ResourcesService } from 'src/app/Services/resources.service';
// import { RSSFunctionalTestService } from 'src/app/Services/rsswa/rssfunctional-test.service';
// import { WFSectionLocalResource } from 'src/app/Services/WorkFlow/work-flow';
// import { Resource } from 'src/app/_interface/Resources.models';
// import { RSSFunctionalTest } from 'src/app/_interface/RSSWA/rss-functional-test.model';
// import { DocumentType } from 'src/app/_interface/SCF.models';
// import { ScheduleStatusEnum } from 'src/app/_interface/Schedules/enums';
// import { Schedule } from 'src/app/_interface/Schedules/Schedule';
// import { ChecklistBuilderDuplicateComponent } from '../checklist-builder/checklist-builder-duplicate/checklist-builder-duplicate.component';
// import { ChecklistBuilderService } from '../../../checklists/checklist-builder/checklist-builder.service';
// import { ChecklistTempComponent } from '../../../checklists/checklist-temp/checklist-temp.component';
// import { Checklist, ChecklistTemplate, TemplateRolePermission, TemplateType } from '../../../checklists/checklists';
// import { ChecklistsService } from '../../../checklists/checklists.service';
// import { TemplateTypesStoreAndListenersService } from '../../../checklists/store/template-types/template-types.store';
// import { TemplateTypesRefresh } from '../../../checklists/store/template-types/template-types.action';
// import { RSSWAResource } from '../../rsswa';

export class ChecklistBuilderPopupParameters {
  public rsswa!: RSSWAResource;
  public amendment?: boolean;
}

@Component({
  selector: 'checklist-exec-popup',
  templateUrl: './checklist-exec-popup.component.html',
  styleUrls: ['./checklist-exec-popup.component.scss']
})
export class ChecklistExecPopupComponent extends BaseComponent implements OnInit, OnDestroy {
  documentType$!: Observable<DocumentType[]>;
  documentTypeSubscription!: Subscription;
  rssTestDocumentType!: DocumentType;
  rssFunctionalTestDocumentType!: DocumentType;

  templateType$!: Observable<TemplateType[]>;
  templateTypeSubscription!: Subscription;
  templateTypes!: TemplateType[];
  templateTypeID?: number;

  schedule$!: Observable<Schedule[]>;
  scheduleSubscription!: Subscription;
  schedules!: Schedule[];

  rssTestTemplates: ChecklistTemplate[] = [];
  rssFunctionalTestTemplates: ChecklistTemplate[] = [];
  rssTestTemplatesDS = new MatTableDataSource<ChecklistTemplate>();
  rssFunctionalTestTemplatesDS = new MatTableDataSource<ChecklistTemplate>();
  checklistTemplateColumns: string[] = ['version', 'resource', 'type', 'duplicate'];

  loadingRSSTestTemplates = false;
  loadingRSSFunctionalTestTemplates = false;
  loadingShutters = false;
  loading = false;
  name!: string;
  sections?: WFSectionLocalResource[];
  checklistTemplate!: ChecklistTemplate;
  rssFunctionalTest!: RSSFunctionalTest;
  resource?: Resource;
  resources: Resource[] = [];
  rssTestRolePermissions!: TemplateRolePermission[];
  rssFunctionalTestRolePermissions!: TemplateRolePermission[];

  @ViewChild(ResourceAutocompleteComponent) resourceAutocomplete!: ResourceAutocompleteComponent;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: ChecklistBuilderPopupParameters,
    public dialogRef: MatDialogRef<ChecklistExecPopupComponent>,
    private templateTypeStore: TemplateTypesStoreAndListenersService,
    private checklistBuilderService: ChecklistBuilderService,
    private checklistService: ChecklistsService,
    private rssFunctionalTestService: RSSFunctionalTestService,
    protected override injector: Injector
  ) {
    super(injector);
    this.templateTypeStore.initStore();
    this.templateTypeStore.initListeners();
  }

  ngOnInit(): void {
    $(window).bind('beforeunload', () => this.ngOnDestroy());
    this.store.dispatch(new TemplateTypesRefresh());
    this.loadingRSSTestTemplates = true;
    this.loadingRSSFunctionalTestTemplates = true;
    this.loadingShutters = true;
    this.loading = true;

    this.documentType$ = this.store.select(state => state.DocumentType.data);
    this.documentTypeSubscription = this.documentType$.subscribe(documentTypes => {
      this.rssTestDocumentType = documentTypes?.filter(x => x.id == 13)[0];
      this.rssFunctionalTestDocumentType = documentTypes?.filter(x => x.id == 18)[0];
    });

    this.templateType$ = this.store.select(state => state.TemplateTypes.data);
    this.templateTypeSubscription = this.templateType$.subscribe(templateTypes => {
      this.templateTypes = templateTypes?.filter(x => x.status == 1 && x.documentTypeID == 18);
    });

    this.schedule$ = this.store.select(state => state.Schedules.data);
    this.scheduleSubscription = this.schedule$.subscribe(schedules => {
      this.schedules = schedules.filter(x => x.typeId == 5 && (x.statusId == ScheduleStatusEnum.new || x.statusId == ScheduleStatusEnum.extensionGranted || x.statusId == ScheduleStatusEnum.refuseExtension || x.statusId == ScheduleStatusEnum.requestExtension));
      this.schedules.map(schedule => {
        schedule.name = schedule.scheduleResources.map(val => {
          const names = [];
          if (val.resourceId == null && !val.resourceString?.includes('(EC')) {
            names.push(val.resourceString);
          } else {
            if (val.resource) {
              names.push(val.resource.name);
            }
          }
          return names;
        }).join('');
        schedule.description = schedule.scheduleResources.map(val => {
          const descs = [];
          if (val.resourceId == null && !val.resourceString?.includes('(EC')) {
            descs.push(val.resourceString);
          } else {
            if (val.resource) {
              descs.push(val.resource.description);
            }
          }
          return descs;
        }).join('');
      });
      this.resources = [];
      this.schedules.map(schedule => {
        const res = schedule.scheduleResources.filter(x => x.resource).map(x => x.resource);
        res.map(r => {
          if (r)
            this.resources.push(r)
        });
      });
    });

    this.loadingShutters = false;
    this.loading = !(!this.loadingShutters && !this.loadingRSSTestTemplates && !this.loadingRSSFunctionalTestTemplates);

    // this.resourceService.getResourcesByType(7).subscribe(
    //   data => this.resources = data,
    //   error => this.alert.error(error.error),
    //   () => {
    //     this.loadingShutters = false;
    //     this.loading = !(!this.loadingShutters && !this.loadingRSSTestTemplates && !this.loadingRSSFunctionalTestTemplates);
    //   }
    // );

    this.checklistBuilderService.getChecklistTemplatesByDocumentTypeID(13).subscribe(
      data => {
        this.rssTestTemplates.push(...data.filter(x => x.code == 'ACTIVE'));
        this.rssFunctionalTestTemplates.push(...data.filter(x => x.code == 'TEMP'));
        this.rssTestTemplatesDS.data = this.rssTestTemplates;
        this.rssFunctionalTestTemplatesDS.data = this.rssFunctionalTestTemplates;
      },
      error => this.alert.error(error.error),
      () => {
        this.loadingRSSTestTemplates = false;
        this.loading = !(!this.loadingShutters && !this.loadingRSSTestTemplates && !this.loadingRSSFunctionalTestTemplates);
      },
    );
    this.checklistBuilderService.getChecklistTemplatesByDocumentTypeID(18).subscribe(
      data => {
        this.rssFunctionalTestTemplates.push(...data.filter(x => x.code == 'ACTIVE' || x.code == 'TEMP'));
        this.rssTestTemplatesDS.data = this.rssTestTemplates;
        this.rssFunctionalTestTemplatesDS.data = this.rssFunctionalTestTemplates;
      },
      error => this.alert.error(error.error),
      () => {
        this.loadingRSSFunctionalTestTemplates = false;
        this.loading = !(!this.loadingShutters && !this.loadingRSSTestTemplates && !this.loadingRSSFunctionalTestTemplates);
      },
    );

    this.checklistBuilderService.getTemplateRolePermissionsByDocumentTypeID(13).subscribe(
      data => this.rssTestRolePermissions = data.filter(x => x.templateTypeID === 0),
      error => this.alert.error(error.error),
    );

    this.checklistBuilderService.getTemplateRolePermissionsByDocumentTypeID(18).subscribe(
      data => {
        this.rssFunctionalTestRolePermissions = data.filter(x => x.templateTypeID === 0);
        this.checklistBuilderService.documentRolePermissions = this.rssFunctionalTestRolePermissions;
      },
      error => this.alert.error(error.error),
    );
  }

  override ngOnDestroy(): void {
    this.templateTypeSubscription?.unsubscribe();

    if (this.rssFunctionalTest == undefined || this.rssFunctionalTest == null) {
      if (this.checklistTemplate != undefined && this.checklistTemplate != null) {
        this.checklistBuilderService.deleteChecklistTemplate(this.checklistTemplate, 'RSS Functional Test not saved').subscribe();
      }
    }
  }

  createTemplate() {
    if (!this.name) {
      this.alert.error('You need to set a name');
      return;
    }

    if (!this.resource?.id) {
      this.alert.error('You need to select a shutter');
      return;
    }

    if (!this.templateTypeID) {
      this.alert.error('You need to select a template type');
      return;
    }

    const templateType = this.templateTypes.filter(x => x.id == this.templateTypeID)[0];
    const checklistTemplate = this.checklistBuilderService.getNewChecklistTemplate(this.rssFunctionalTestDocumentType, templateType, null, this.resource, null, '');
    checklistTemplate.status = -1;

    this.loading = true;
    this.checklistBuilderService.createChecklistTemplate(checklistTemplate).subscribe(
      data => {
        this.checklistTemplate = data;
        this.sections = this.checklistTemplate?.wfTable?.wfTableLocal?.wfSectionLocals?.sort((a, b) => this.utils.sort(a.order, b.order));
      },
      error => this.alert.error(error.error),
      () => this.loading = false,
    );
  }

  duplicate(checklistTemplate: ChecklistTemplate) {
    switch (checklistTemplate.documentTypeID) {
      case 13:
        this.duplicateRSSTest(checklistTemplate);
        break;

      case 18:
        this.duplicateRSSFunctionalTest(checklistTemplate);
        break;
    }
  }

  duplicateRSSTest(checklistTemplate: ChecklistTemplate) {
    const schedule = this.schedules.filter(x => x?.scheduleResources?.filter(x => x?.resourceId == this.resource?.id).length > 0)[0];
    // const schedules = this.schedules.filter(x => x?.subtype?.templateTypeID === checklistTemplate?.templateTypeID);
    const schedules = this.schedules;
    this.dialog.open(ChecklistBuilderDuplicateComponent, {
      data: {
        templateType: checklistTemplate?.templateType,
        documentType: this.rssTestDocumentType,
        templateVersion: checklistTemplate,
        resource: checklistTemplate?.resource,
        schedule,
        schedules,
        documentRolePermissions: this.rssTestRolePermissions,
        temp: true,
      },
      disableClose: true,
      autoFocus: false,
    }).afterClosed().subscribe(
      data => {
        if (data?.duplicatedVersion != undefined && data?.duplicatedVersion != null) {
          this.checklistTemplate = data.duplicatedVersion;
          this.sections = this.checklistTemplate?.wfTable?.wfTableLocal?.wfSectionLocals?.sort((a, b) => this.utils.sort(a.order, b.order));
          this.resource = this.checklistTemplate?.resource;
          this.templateTypeID = this.checklistTemplate?.templateTypeID;
          this.resourceAutocomplete?.select(this.resource);
          if (this.checklistTemplate.resourceID != undefined && this.checklistTemplate.resourceID != null) {
            if (this.checklistTemplate.resourceID <= 0) {
              this.checklistTemplate.resourceID = null;
            }
          }
        }
      }
    );
  }

  duplicateRSSFunctionalTest(checklistTemplate: ChecklistTemplate) {
    this.dialog.open(ChecklistBuilderDuplicateComponent, {
      data: {
        templateType: checklistTemplate?.templateType,
        documentType: this.rssFunctionalTestDocumentType,
        templateVersion: checklistTemplate,
        resource: checklistTemplate?.resource,
        resources: this.resources,
        documentRolePermissions: this.rssFunctionalTestRolePermissions,
        temp: true,
      },
      disableClose: true,
      autoFocus: false,
    }).afterClosed().subscribe(
      data => {
        if (data?.duplicatedVersion != undefined && data?.duplicatedVersion != null) {
          this.checklistTemplate = data.duplicatedVersion;
          this.sections = this.checklistTemplate?.wfTable?.wfTableLocal?.wfSectionLocals?.sort((a, b) => this.utils.sort(a.order, b.order));
          this.resource = this.checklistTemplate?.resource;
          this.templateTypeID = this.checklistTemplate?.templateTypeID;
          this.resourceAutocomplete?.select(this.resource);
          if (this.checklistTemplate.resourceID != undefined && this.checklistTemplate.resourceID != null) {
            if (this.checklistTemplate.resourceID <= 0) {
              this.checklistTemplate.resourceID = null;
            }
          }
        }
      }
    );
  }

  createRSSFunctionalTest() {
    this.loading = true;
    const rssFunctionalTest: RSSFunctionalTest = {
      name: this.name,
      rsswaID: this.data.rsswa.id,
      checklistTemplateID: this.checklistTemplate.id,
    };

    this.rssFunctionalTestService.create(rssFunctionalTest, this.data.amendment).subscribe(
      data => {
        this.rssFunctionalTest = data;
        this.dialogRef.close(true);
      },
      error => {
        this.alert.error(error.error);
        this.loading = false;
      },
      () => this.loading = false,
    );
  }

  openViewMode(checklistTemplate: ChecklistTemplate) {
    this.dialog.open(ChecklistTempComponent, {
      minWidth: '60vw',
      disableClose: true,
      data: {
        checklistTemplate,
      }
    })
      .afterClosed().toPromise().then((checklist: Checklist) => {
        if (checklist) {
          this.checklistService.deleteChecklist(checklist.id).toPromise();
        }
      });
  }

}

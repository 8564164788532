<div class="row small ml-10">
  <div class="col-4 d-flex">
    <span class="mr-1"> {{ signatureDate }}</span>
    <span class="border-bottom date nowrap px-2 w-min-150">
      {{ date | date : "short" }}
    </span>
  </div>
  <div class="col-4 d-flex">
    <span class="mr-1">{{ inspector }}</span>
    <span class="border-bottom bold px-2 w-min-150 nowrap ellipsis">
      {{ user && user.name ? user.name + " (" + user.initials + ")" : null }}
    </span>
  </div>
  <div class="col-4 d-flex">
    <span class="mr-1 text-right nowrap ellipsis"> {{ pendingActions }}</span>
    <span class="border-bottom bold px-2"> {{ catl }}</span>
  </div>
</div>

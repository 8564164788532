import { Component, ElementRef, EventEmitter, Injector, Input, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { Subscription } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { BaseComponent } from 'src/app/common/base/base.component';
import { Document, DocumentType, RelatedDocument } from 'src/app/services/documents/documents';
import { AbstractControl } from '@angular/forms';
import { FormStatusEnum, FormType } from 'src/app/common/enumerations/enumerations';
import { utils } from 'src/app/modules/libs/utils';
import { Router } from '@angular/router';

@Component({
  selector: 'related-documents',
  templateUrl: './related-documents.component.html',
  styleUrls: ['./related-documents.component.scss']
})
export class RelatedDocumentsComponent extends BaseComponent {
  @Input() relatedDocuments?: RelatedDocument[] | null;
  @Input() disabled?: boolean | null;
  @Input() formID?: number;
  @Input() formType?: FormType;
  @Input() hilite?: boolean | null;
  @Input() label?: string;
  @Input() disableTooltips?: boolean;

  @Output() changed = new EventEmitter<RelatedDocument[]>();

  @ViewChild("autoDoc") matAutocompleteDoc!: MatAutocomplete;
  @ViewChild("documentInput") documentInput!: ElementRef<HTMLInputElement>;

  public get documentCtrl(): AbstractControl | null { return this.formGroup?.get("documentCtrl"); }

  documents?: Document[];
  documentsFiltered?: Document[];
  documents$!: Observable<Document[]>;
  documentsSubs!: Subscription;
  documentTypes?: DocumentType[];

  filter: string = '';

  constructor(
    protected override injector: Injector,
    private router: Router
  ) {
    super(injector);
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.buildForm();
    this.loadDocuments();
  }

  getRelatedinfo() {
    this.relatedDocuments = this.relatedDocuments?.filter(r => r.relatedDocumentId);
    const relatedDocuments: RelatedDocument[] = [];
    this.relatedDocuments?.map(r => {
      const document = this.documents?.find(d => d.type == r.relatedDocumentTypeId && d.formID == r.relatedDocumentId);
      if (document) {
        const relatedDocument = r;
        relatedDocument.cssClass = document?.cssClass + '-background';
        relatedDocument.relatedDocumentSerialNo = document?.serialNo;
        relatedDocument.relatedDocumentStatus = document?.status;
        relatedDocuments.push(relatedDocument);
      }
    });
    this.relatedDocuments = relatedDocuments;
  }

  buildForm() {
    this.formGroup = this.formBuilder.group({
      documentCtrl: [{ value: null }],
    });
    if (this.disabled) this.formGroup.disable(); else this.formGroup.enable();
  }

  loadDocuments() {
    this.documents$ = this.store.select(state => state.Documents.data);
    this.documentsSubs = this.documents$.subscribe(data => {
      if (data?.length) {
        this.documents = data;
        this.documentsFiltered = data.filter(d => (d.status ?? 0) > FormStatusEnum.Draft && (d.status ?? 0) != FormStatusEnum.Canceled);
        this.getRelatedinfo();
        this.setDescriptions();
        this.filterDocuments();
        this.createGroups();
      }
    });
  }

  createGroups() {
    this.documentTypes = [];
    this.documentsFiltered?.map(d => {
      if (d.documentType?.id && !this.documentTypes?.map(t => t.id).includes(d.type ?? 0))
        if (d.documentType) {
          const documentType = d.documentType;
          documentType.documents = this.getDocuments(documentType);
          if (documentType.documents?.length)
            this.documentTypes?.push(documentType);
        }
    });
    this.documentTypes = this.documentTypes.sort((a, b) => utils.sort(a.id, b.id, true));
  }

  filterDocuments() {
    this.documentsFiltered = this.documents?.filter(d => {
      const relatedDocument = this.relatedDocuments?.find(r => r.relatedDocumentId == d.formID && r.relatedDocumentTypeId == d.type);
      if (relatedDocument) return false;
      return true;
    });
  }

  filterTypeDocuments(documents?: Document[]) {
    return documents?.filter((doc) => doc.serialNo?.toLowerCase().includes(this.filter) || doc.description?.toLowerCase().includes(this.filter));
  }

  setDescriptions() {
    this.documents?.map(d => {
      switch (d.type) {
        case FormType.KE:
          d.description = 'Affects ' + d.locations?.map(l => l.name).join(', ');
          break;
        case FormType.ABSI:
          d.description = 'ABSI Checklist for ' + d.locations?.map(l => l.name).join(', ');
          break;
        default:
          d.description = this.stripDesc(d.description) + (d.locationIDs ? (' (' + d.locations?.map(l => l.name).join(', ') + ')') : '');
      }
    });
  }

  removeDoc(document: RelatedDocument): void {
    const index = this.relatedDocuments?.indexOf(document) ?? -1;
    if (index >= 0) {
      this.relatedDocuments?.splice(index, 1);
    }
    if (!this.relatedDocuments?.length) this.filter = '';
    this.filterDocuments();
    this.createGroups();

    this.changed.emit(this.relatedDocuments ?? []);
  }

  openDocument(e: RelatedDocument) {
    const route = `redirect/${e.relatedDocumentSerialNo}`;
    this.router.navigate([route]);
  }

  addDoc(event: MatChipInputEvent): void {
    if (!this.matAutocompleteDoc.isOpen) {
      const input = event.input;
      const value = event.value;

      if (input) {
        input.value = "";
      }
      this.documentCtrl?.setValue(null);
      this.filterDocuments();
      this.createGroups();

      this.changed.emit(this.relatedDocuments ?? []);
    }
  }

  onDocChange(e: any) {
    const code = e.code;
    if (!code?.includes("Arrow")) {
      this.filterDocuments();
      this.filter = (e.target.value as string).toLowerCase();
      this.documentsFiltered = this.documentsFiltered?.filter((doc) => doc.serialNo?.toLowerCase().includes(this.filter) || doc.description?.toLowerCase().includes(this.filter));
      this.createGroups();
    }
  }

  selectedDoc(event: MatAutocompleteSelectedEvent): void {
    if (this.relatedDocuments == null) {
      this.relatedDocuments = [];
    }
    const selectedDoc = event.option.value as Document;
    const document: RelatedDocument = {
      originalDocumentId: this.formID,
      originalDocumentTypeId: this.formType,
      relatedDocumentId: selectedDoc.formID,
      relatedDocumentStatus: selectedDoc.status,
      relatedDocumentSerialNo: selectedDoc.serialNo,
      relatedDocumentTypeId: selectedDoc.type ?? 0,
    };
    this.filter = '';
    if (!this.relatedDocuments.includes(document)) {
      this.relatedDocuments.push(document);
      this.filterDocuments();
      this.createGroups();
      this.documentInput.nativeElement.value = "";
      this.documentCtrl?.setValue(null);
      this.changed.emit(this.relatedDocuments ?? []);
    }
  }

  getDocuments(type: DocumentType) {
    return this.documents?.filter(d => d.documentType?.id == type.id).sort((a, b) => utils.sortArrayAlphabeticallyWithComplexNumbers(a.serialNo, b.serialNo, null, false));
  }

}

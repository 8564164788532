// import { PendingCatalogs } from 'src/app/Services/PendingApprovals/pendingapproval';

import { PendingCatalogs } from "src/app/services/pending-approvals/pending-approval";

export class MyPendingCatalogsRefresh {
  static readonly type = '[MyPendingCatalogs] Refresh';
}
export class MyPendingCatalogsCreate {
  static readonly type = '[MyPendingCatalogs] Create';
  constructor(public item: PendingCatalogs) { }
}
export class MyPendingCatalogsUpdate {
  static readonly type = '[MyPendingCatalogs] Update';
  constructor(public id: number, public item: PendingCatalogs) { }
}
export class MyPendingCatalogsDelete {
  static readonly type = '[MyPendingCatalogs] Delete';
  constructor(public id: number) { }
}

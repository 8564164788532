import { Component, Input, Output, EventEmitter, OnDestroy, OnInit, OnChanges, SimpleChanges, Injector } from '@angular/core';
import { KEService } from '../../ke.service';
import { ChecklistsService } from '../../../checklists/checklists.service';
import { MatDialogConfig } from '@angular/material/dialog';
import { Subscription, Observable } from 'rxjs';
import { FormStatusEnum, Roles, WFSaveOptions, FormType, OlogNotificationType } from 'src/app/common/enumerations/enumerations';
import { WFComponent } from 'src/app/components/workflow/workflow';
import { PartialOlogComponent } from 'src/app/controls/olog/partial-olog/partial-olog.component';
import { YesNoDialogComponent } from 'src/app/controls/yes-no-dialog/yes-no-dialog.component';
import { OLogService } from 'src/app/components/olog/olog.service';
import { Resource, ShutterResource } from 'src/app/components/catalogs/beamline-catalog/resource/resources';
import { WfSignatureResource, WfSignature, WFTaskSignatureResource, SaveTaskResource } from 'src/app/services/work-flow/work-flow';
import { KEResource, EpicsValuesResource, SaveKEStatusResource } from '../../ke';
import { BaseComponent } from 'src/app/common/base/base.component';
import { MessagePlaceholder } from 'src/app/common/models/placeholder';
import { KEnableUpdate } from '../../store/ke/kenable.action';
import { PV } from 'src/app/services/pv-info/pv-info';
import { Document } from 'src/app/services/documents/documents';
import { SCFMaster } from 'src/app/components/scf-v2/scf';
import { Checklist, Option } from 'src/app/components/checklists/checklists';
import { ScfV2Service } from 'src/app/components/scf-v2/scf-v2.service';

@Component({
  selector: 'ke-sectionX',
  templateUrl: './ke-sectionX.component.html',
  styleUrls: ['./ke-sectionX.component.scss']
})

export class KESectionXComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {

  @Input() section!: number;
  @Input() ke?: KEResource | null;

  @Output() requestRefresh = new EventEmitter();
  @Output() refreshVacuum = new EventEmitter();
  @Output() loadingChanged = new EventEmitter<boolean>();

  loading!: boolean;

  epicsValues?: EpicsValuesResource[];
  epicsValues$!: Observable<EpicsValuesResource[]>;
  epicaValiesSubs!: Subscription;

  shutters!: ShutterResource[];
  shutters$!: Observable<ShutterResource[]>;
  shuttersSubs!: Subscription;

  scfMasters?: SCFMaster[];
  scfMasters$!: Observable<SCFMaster[]>;
  scfMastersSubs!: Subscription;

  checklists?: Checklist[];
  checklists$!: Observable<Checklist[]>;
  checklistsSubs!: Subscription;

  pvs?: PV[];
  pvs$!: Observable<PV[]>;
  pvsSubs!: Subscription;
  documents?: Document[];
  documents$!: Observable<Document[]>;
  documentsSubs!: Subscription;
  brcRestrictedShutters!: ShutterResource[];

  resources!: Resource[];
  resources$!: Observable<Resource[]>;
  resourcesSubs!: Subscription;

  isVacuumSection = false;
  isShieldingsSection = false;
  abbreviated!: boolean;

  resource?: ShutterResource;
  completeActive!: boolean;
  completeShieldings!: boolean;
  shieldingsUserId?: number;

  sectionName!: string | null;

  wfSignatures!: WfSignatureResource[];
  signatures!: WfSignature[];
  wfTaskSignature: WFTaskSignatureResource = new WFTaskSignatureResource();

  approvals!: number;

  disabled!: boolean;
  disableEditVacuum = true;

  ologButton = false;

  components!: WFComponent[];
  components$!: Observable<WFComponent[]>;
  componentSubs!: Subscription;

  vacuumLoaded!: boolean;

  identificator!: number;

  constructor(
    protected override injector: Injector,
    private _ke: KEService,
    private _olog: OLogService,
    private _checklist: ChecklistsService,
    private _scf: ScfV2Service,
  ) {
    super(injector);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.ngOnInit();
    if (this.ke?.id == 0 && this.ke.status == FormStatusEnum.New) {
      this.create();
    }
    else if (this.ke?.id) {
      this.refresh(this.ke);
    }
  }

  override ngOnDestroy(): void {
    this.epicaValiesSubs?.unsubscribe();
    this.componentSubs?.unsubscribe();
    this.pvsSubs?.unsubscribe();
    this.scfMastersSubs?.unsubscribe();
    this.checklistsSubs?.unsubscribe();
    super.ngOnDestroy();
  }

  ngOnInit() {
    this.loadPVs();
    this.loadChecklists();
    this.loadComponents();
    this.loadShutters();
    this.loadDocuments();
    this.loadResources();
    this.loadSCFMasters();
  }

  loadChecklists() {
    this.checklists$ = this.store.select(state => state.Checklists.data);
    this.checklistsSubs = this.checklists$.subscribe(data => {
      if (data?.length) {
        this.checklists = data;
      }
    });
  }

  loadSCFMasters() {
    this.scfMasters$ = this.store.select(state => state.SCFMasters.data);
    this.scfMastersSubs = this.scfMasters$.subscribe(data => {
      if (data?.length) {
        this.scfMasters = data;
      }
    });
  }

  loadPVs() {
    this.pvs$ = this.store.select(state => state.PV.data);
    this.pvsSubs = this.pvs$.subscribe(data => {
      if (data?.length) {
        this.pvs = data;
        this.loadEpicsValues();
      }
    });
  }

  loadComponents() {
    this.components$ = this.store.select(state => state.Components.data);
    this.componentSubs = this.components$.subscribe(data => {
      this.components = data.filter(x => x.status === 1).sort((a, b) => this.utils.sort(a.name, b.name));
    });
  }

  loadShutters() {
    this.shutters$ = this.store.select(state => state.Resources.shutters);
    this.shuttersSubs = this.shutters$.subscribe(data => {
      this.shutters = data;
    });
  }

  loadDocuments() {
    this.documents$ = this.store.select(state => state.Documents.data);
    this.documentsSubs = this.documents$.subscribe(data => {
      if (data?.length) {
        this.documents = data;
      }
    });
  }

  loadResources() {
    this.resources$ = this.store.select(state => state.Resources.data);
    this.resourcesSubs = this.resources$.subscribe(data => {
      if (data?.length != 0) {
        this.resources = data;
        const restrictedResources = this.resources.filter(x => x.resourceType?.id == this.resourceTypeEnum.Beamline && x.keRestricted).map(r => r.reviewRestrictedResourceID);
        this.brcRestrictedShutters = this.shutters.filter(s => restrictedResources.includes(s.id));
      }
    });
  }

  create() {
    this.wfSignatures = [];
    this.signatures = [];
    this.sectionName = null;
    this.isVacuumSection = false;
    this.vacuumLoaded = false;
  }

  async refresh(ke: KEResource | null = null) {
    this.setLoading(true);
    this.ke = ke ?? { id: 0, wfSignatures: [], serialNo: '', status: 0 } as KEResource;

    this.signatures = [];
    this.resource = this.ke.resource;
    this.abbreviated = this.ke.type === 0;

    if (this.ke.wfSignatures && this.ke.wfSignatures.length) {
      this.wfSignatures = this.ke.wfSignatures.filter(x => x.sectionNumber === this.section);

      if ((this.ke.status === FormStatusEnum.KeyEnable && this.section === 5) ||
        (this.ke.status === FormStatusEnum.Shielding && this.section === 4)) {
        this.shieldingsUserId = this.ke.wfSignatures.find(x => x.sectionNumber === 4 && x.signed)?.signedBy?.id;
      }

      this.setDisabled(this.wfSignatures[0]);
      this.sectionName = this.wfSignatures[0]?.sectionName ?? null;
      this.approvals = this.wfSignatures.length;

      this.checkConditions();
      this.signatures = [];

      // Use Promise.all to wait for all signatures to be processed
      const signaturePromises = this.wfSignatures.map(async t => {
        const signature = await this.getSignature(t, this.section);
        return signature;
      });

      this.signatures = await Promise.all(signaturePromises);

      this.renumberArray();

      if (this.ke.status === FormStatusEnum.Draft && this.section === 3 && !this.vacuumLoaded) {
        this.vacuumLoaded = true;
        this.refreshAndSaveVacuum();
      }

      this.setLoading(false);
    } else {
      this.sectionName = null;
      this.setLoading(false);
    }
  }



  async getSignature(t: WfSignatureResource, section: number) {
    this.isVacuumSection = section === 3;
    this.isShieldingsSection = section === 4;
    let vacuum;
    console.log(section);
    if (this.isVacuumSection) {
      vacuum = t.text && t.text.includes('EPICS') ? this.getEpicsValue(t) : null;
      t.warning = !vacuum?.isValid;
      t.enabled = t.text && t.text.includes('EPICS') ? t.doubleValue !== 0 && !t.warning : t.enabled;
    }

    this.ologButton = section === 5 && !this.disabled;
    const moreInfo = t.warning ? (t.moreInfo ? this.formatMoreInfo(t.moreInfo) : undefined) : undefined;

    const signature: WfSignature = {
      id: t.id,
      number: t.sectionNumber.toString(),
      approveId: t.approveID,
      unapproveId: t.unapproveID,
      disapproveId: t.disapproveID,
      amendmentId: t.amendmentID,
      name: t.titleBefore,
      question: t.question,
      question2: t.titleAfter,
      question3: t.titleAfter2,
      roles: t.roles.map(r => r?.id),
      roleCodes: t.roles.map(r => r?.code).join('/'),
      disabled: this.setDisabledTask(t) || t.disabled || this.disabled,
      approvedBy: t.signedBy,
      approved: t.signed,
      approvedOn: t.signedOn,
      approve: !t.signedBy && t.approveID != 0,
      unapprove: !t.signedBy && t.unapproveID != 0,
      disapprove: t.disapproveEnabled,
      amendment: t.amendmentEnabled,
      required: t.required,
      taskId: t.taskID,
      value: t.value,
      value2: t.numericValue,
      textValue: vacuum ? vacuum.question : t.text ? t.text.replace('^', '<sup>') : '',
      type: t.type,
      options: t.options ? await this.formatOptions(t) : [],
      sectionName: t.sectionName,
      isVacuum: vacuum != null,
      disableEdit: vacuum ? (this.disableEdit() || this.disabled || t.signed) : this.disabled || this.setDisabledTask(t),
      visible: t.visible,
      enabled: true,
      warning: (this.ke?.status === FormStatusEnum.Closed ? false : moreInfo?.hasValue()) ?? false,
      moreInfo,
      scheduleTypeId: t.scheduleType,
      scheduleResourceId: t.scheduleResourceId,
      dateValue1: t.dateValue1,
      dateValue2: t.dateValue2,
      dateValue3: t.dateValue3?.toString() !== '0001-01-01T00:00:00' ? t.dateValue3 : undefined,
      scheduleName: t.scheduleName,
      locationName: t.locationName,
      condition: t.condition,
      configuration: t.configuration,
    };
    return signature;
  }

  async formatOptions(t: WfSignatureResource) {
    const options = this.utils.JSONparse(t.options) as Option[];
    if (t.condition == '!SCFActive' && t.numericValue == 1) {
      const scfMasterIds = this.scfMasters?.filter(s => this.utils.JSONparse(t.moreInfo)?.includes(s.serialNo))?.map(s => s.id) as number[];
      if (scfMasterIds) {
        const scfRSObjs = await this.getSCFMasters(scfMasterIds);
        const yesOption = options.find(o => o.value == 1) as Option;
        yesOption.label = 'Yes ->' + scfRSObjs?.map(s => this.formatLabel(s))?.join(', ');
      }
    }
    return options;
  }

  formatLabel(scfrsObj: SCFRSObj) {
    let label = this.formatDocumentChip(scfrsObj.scfMaster.serialNo);
    if (scfrsObj.checklists?.length) {
      label += ' [' + scfrsObj.checklists.map(c => this.formatDocumentChip(c.serialNo ?? '')).join('') + ']';
    }
    return label;
  }

  formatDocumentChip(serialNo: string) {
    const document = this.documents?.find(d => d.serialNo == serialNo);
    let chip = `<span class="chip ${document?.cssClass + '-background'}"><a class="btn-link" href="#/redirect/${serialNo}">${serialNo}</a></span>`;
    return chip;
  }

  async getSCFMasters(ids: number[]): Promise<SCFRSObj[]> {
    const scfMasterPromises = ids.map(id => this._scf.getById(id).toPromise());
    let scfMasters = await Promise.all(scfMasterPromises);
    scfMasters = scfMasters.filter(scfMaster => scfMaster !== undefined) as SCFMaster[];
    const scfRSObjects: SCFRSObj[] = [];
    scfMasters.map(scf => {
      const s4 = scf?.wfTable?.wfTableLocal?.wfSectionLocals.find(s => s.number == 4);
      const rsTask = s4?.wfSignatures?.find(s => s.code == 'RADSVREQ');
      if (scf && rsTask) {
        const values = this.utils.JSONparse(rsTask.text);
        const checklists = this.checklists?.filter(c => values.checklistIds.includes(c.id));
        scfRSObjects.push({ scfMaster: scf, checklists });
      }
    });
    return scfRSObjects;
  }

  formatMoreInfo(moreInfo: string): string {
    if (!this.documents) this.loadDocuments();
    if (moreInfo.includes('SCF-') || moreInfo.includes('PPSTB-') || moreInfo.includes('EB-') || moreInfo.includes('RSSWA-')) {
      const forms = this.utils.JSONparse(moreInfo) as string[];
      const documents = this.documents?.filter(d => forms.includes(d.serialNo as string));
      if (documents?.length)
        moreInfo = '<div class="d-flex flex-column">' + documents?.map(d => {
          return '<div class="my-1 bold ' + d.cssClass + '">' + (
            !d.disableLink ? '<a href="#/redirect/' + d.serialNo + '">' + d.serialNo + '</a>' :
              '<span class="yellow">' + d.serialNo + '</span>') +
            '</div>';
        }).join('') + '</div>';
      else moreInfo = '';
    }
    return moreInfo;
  }

  setDisabled(t: WfSignatureResource) {
    if (!this.currentUser) {
      this.disabled = true;
      return this.disabled;
    }

    if (this.hasRoles([Roles.FO]) && this.section === 4 && (this.ke?.status === FormStatusEnum.Shielding)) {
      const signed = this.wfSignatures.find(s => s.signed);
      const e = signed == null || (signed != null && signed.signedBy?.id === this.currentUser?.id);
      this.disabled = !e;
      return this.disabled;
    }

    if (this.hasRoles([Roles.FO]) && this.section === 5 && (this.ke?.status === FormStatusEnum.KeyEnable)) {
      const e = this.shieldingsUserId === this.currentUser?.id;
      this.disabled = !e;
      return this.disabled;
    }

    if (this.hasRoles([Roles.BLSCI, Roles.ALTBLSCI]) && this.section === 6 && (this.ke?.status === FormStatusEnum.Handshake)) {
      const e = true;
      this.disabled = !e;
      return this.disabled;
    }

    if (this.hasRoles([Roles.FO, Roles.BRC]) && this.section === 1 && (this.ke?.status === FormStatusEnum.Draft || this.ke?.status === FormStatusEnum.Active)) {
      this.disabled = false;
      return this.disabled;
    }
    const enable = t.status === this.ke?.status;
    this.disabled = !enable;
    return this.disabled;
  }

  disableEdit() {
    return this.disableEditVacuum || !this.hasRoles([Roles.FO, Roles.VAC]);
  }

  disableButtons() {
    const enable = (this.ke?.status === FormStatusEnum.Active || this.ke?.status === FormStatusEnum.Shielding) && this.hasRoles([Roles.FO, Roles.VAC]);
    return !enable;
  }

  //Determine if task is disabled
  setDisabledTask(t: WfSignatureResource) {
    let enable = false;
    if (t.users)
      enable = t.users.map(u => u.id).includes(this.currentUser?.id) && this.hasRoles(t.roles) && this.validateWarningValues(t);
    else
      enable = this.hasRoles(t.roles) && this.validateWarningValues(t);
    return !enable;
  }

  //See if form or BRC restricts KE
  validateWarningValues(t: WfSignatureResource) {
    if (t.warning && this.section === 1 && t.condition === '!BRCRestriction') {
      return false;
    }
    if (t.warning && t.moreInfo && t.moreInfo !== '[]') {
      if (this.section === 1) {
        if ((t.condition === '!SCFActive' && !t.configuration) || t.condition === '!RSSWAActive' || t.condition === '!PPSTBActive' || t.condition === '!EBActive') {
          return false;
        }
      }
      if (this.section === 3 && !this.hasRoles([Roles.VAC])) {
        return false;
      }
    }
    return true;
  }

  renumberArray() {
    let index = 1;
    let order = 1;
    this.loadComponents();
    this.signatures.sort((a, b) => this.utils.sort(a.number, b.number)).map(s => {
      const control = this.components?.find(c => c.id === s.type);
      s.order = order;
      order++;

      if (s.required && control?.numericHeader) {
        const sectionNumber = this.section + '.' + index;
        s.number = sectionNumber;
        index++;
      }
      else {
        s.number = null;
      }
    });
  }

  //User checks or unchecks a box
  async check(check: boolean, taskId?: number, approveId?: number, unapproveId?: number, condition?: string, moreInfo?: string, configuration?: string) {
    const wfSignature = this.wfSignatures.find(s => s.taskID === taskId);
    if (check) {
      //If a box was checked, look for these conditions and if moreInfo exists
      //These conditions show a warning but allow the checkbox to remain enabled
      if ((condition === '!PPSTBActiveLoc' || condition === '!EBActiveLoc' || (condition === '!SCFActive' && configuration)) && moreInfo) {
        //Get confirmation message
        const message = this.getMessage(condition === '!PPSTBActiveLoc' ? 'KE_PPSTBActive' : condition === '!EBActiveLoc' ? 'KE_EBActive' : 'KE_SCFActive');
        const dialogConfig = new MatDialogConfig();
        dialogConfig.data = {
          message: message.description.replace('{forms}', '<b class="no-wrap">' + moreInfo + '</b>'),
          icon: 'warning',
          changeButtonLabels: true,
          labelButtonCancel: 'Cancel',
          labelButtonAccept: 'Accept'
        };
        dialogConfig.width = '600px';
        const dialogRef = this.dialog.open(YesNoDialogComponent, dialogConfig);
        dialogRef.afterClosed().toPromise().then(async data => {
          //If user confirms, sign checkbox
          if (data && wfSignature && wfSignature.wfTaskLocal.wfTasks?.length) {
            wfSignature.signed = true;
            wfSignature.signedBy = this.currentUser;
            wfSignature.signedOn = new Date();
            wfSignature.wfTaskLocal.wfTasks[0].signedBy = { id: this.currentUser?.id, name: this.currentUser?.name, initials: this.currentUser?.initials };
            await this.approve(taskId, approveId);
            this._ke.updateTask(wfSignature.wfTaskLocal);
            //If user does not confirm, go back
          } else {
            const signaturesCloned = this.utils.cloneDeep(this.signatures);
            this.signatures = signaturesCloned;
          }
        });
        //If this task has no conditions or moreInfo is empty, sign checkbox
      } else {
        if (wfSignature?.wfTaskLocal.wfTasks) {
          wfSignature.signed = true;
          wfSignature.signedBy = this.currentUser;
          wfSignature.signedOn = new Date();
          wfSignature.wfTaskLocal.wfTasks[0].signedBy = { id: this.currentUser?.id, name: this.currentUser?.name, initials: this.currentUser?.initials };
          await this.approve(taskId, approveId);
          this._ke.updateTask(wfSignature.wfTaskLocal);
        }
      }
      //Box was unchecked, show confirmation
    } else {
      await this.unapprove(taskId, unapproveId);
      if (wfSignature?.component?.hasRadioButtons) {
        wfSignature.numericValue = 0;
        const saveTask = {
          id: wfSignature.id,
          numericValue: 0,
          field: 'numericValue',
          option: WFSaveOptions.MANUAL
        } as SaveTaskResource;
        await this.saveTask(saveTask);
      }
      //Unsign checkbox
      if (wfSignature?.wfTaskLocal.wfTasks) {
        wfSignature.signed = false;
        wfSignature.signedBy = null;
        wfSignature.signedOn = null;
        wfSignature.wfTaskLocal.wfTasks[0].signedBy = null;
        this._ke.updateTask(wfSignature.wfTaskLocal);
      }
      this.refresh(this.ke);
    }
  }

  async change(e: number, taskId?: number, type?: number, isVacuum?: boolean) {
    let saveTask: SaveTaskResource;
    const wfSignature = this.wfSignatures.find(s => s.id === taskId);
    if (wfSignature && taskId)
      switch (type) {

        case 6:  // Radio
          const oldValue = wfSignature?.numericValue;
          saveTask = {
            id: taskId,
            numericValue: e,
            field: 'numericValue',
            option: WFSaveOptions.MANUAL
          };

          if (wfSignature?.signed && wfSignature.numericValue !== e) {
            const confirm = this.dialog.open(YesNoDialogComponent, {
              width: '400px',
              data: {
                message: this.getMessage('KERadioChangedWarning').description,
                icon: 'warn'
              }
            });
            wfSignature.numericValue = e;
            confirm.afterClosed().toPromise().then(async data => {
              if (data && wfSignature.unapproveID) {
                saveTask.numericValue = 0;
                await this.saveTask(saveTask);
                await this.unapprove(wfSignature.taskID, wfSignature.unapproveID);
              }
              else {
                const s = this.ke?.wfSignatures?.find(x => x.id == wfSignature.id);
                if (s)
                  s.numericValue = oldValue;
                this.refresh(this.ke);
              }
            });
          }
          else {
            if (wfSignature) {
              wfSignature.numericValue = e;
              wfSignature.signed = true;
              wfSignature.signedBy = this.currentUser;
              await this.approve(wfSignature.taskID, wfSignature.approveID, saveTask);
            }
          }
          break;

        case 8: // Text Input (Vacumm Values)
          if (isVacuum) {
            let str: string = e.toString();
            str = str.replace(/\s/g, '').toLowerCase().replace('x10^', 'e');
            saveTask = {
              id: taskId,
              doubleValue: +str,
              field: 'doubleValue',
              formType: FormType.KE,
              option: WFSaveOptions.MANUAL
            };
            wfSignature.doubleValue = +str;
            const shutter = this.shutters.find(x => x.id == this.ke?.resource?.id);
            let vacuumName = '';
            if (wfSignature.titleAfter != null) {
              vacuumName = wfSignature.titleAfter.replace('<strong>', '').replace('</strong> = ', '').replace('<b>', '').replace('</b> = ', '');
            }
            const vacuum = shutter?.vacuums.find(v => v.name == vacuumName);
            if (vacuum) {
              wfSignature.warning = !((vacuum.keVacuumMinRequirement ?? 0) <= wfSignature.doubleValue && wfSignature.doubleValue <= (vacuum.keVacuumMaxRequirement ?? 0));
              const moreInfos: string[] = [];
              moreInfos.push('Requirement:' + vacuum.keVacuumMinRequirement + ' to ' + vacuum.keVacuumMaxRequirement);
              wfSignature.moreInfo = wfSignature.warning ? JSON.stringify(moreInfos) : null;
              wfSignature.disabled = wfSignature.warning && !this.currentUser?.userRole?.map(x => x.roleID).includes(Roles.VAC);
            }
            // this.refresh(this.ke);
            const number = this.signatures.find(s => s.id == wfSignature.id)?.number;
            const signature = await this.getSignature(wfSignature, this.section);
            wfSignature.doubleValue = +str;
            signature.number = number;
            signature.textValue = this.formatSciNotation(wfSignature.doubleValue);
            const index = this.signatures.findIndex(s => s.id == signature.id);
            this.signatures[index] = signature;
            this.saveTask(saveTask);
          }
          else {
            saveTask = {
              id: taskId,
              text: e.toString(),
              field: 'text'
            };

            if (wfSignature.signed && wfSignature.text !== e.toString()) {
              const confirm = this.dialog.open(YesNoDialogComponent, {
                width: '400px',
                data: {
                  message: this.getMessage('KEVacuumEditTextWarning').description,
                  icon: 'warn'
                }
              });
              wfSignature.text = e.toString();
              confirm.afterClosed().toPromise().then(data => {
                if (data && wfSignature.unapproveID) {
                  this.saveTask(saveTask);
                  this.unapprove(wfSignature.taskID, wfSignature.unapproveID);
                }
                else {
                  this.refresh(this.ke);
                }
              });
            }
            else if (wfSignature.text !== e.toString()) {
              wfSignature.text = e.toString();
              this.approve(wfSignature.taskID, wfSignature.approveID, saveTask);
            }
          }
          break;

        default:
          saveTask = {
            id: taskId,
            text: e.toString(),
            field: 'text'
          };
          wfSignature.text = e.toString();
          this.saveTask(saveTask);
          if (this.ke?.status == FormStatusEnum.Draft) {
            const status: SaveKEStatusResource = {
              id: this.ke.id,
              status: FormStatusEnum.Active
            };
            this._ke.putStatus(status).toPromise().then(() => {
              if (this.ke?.id)
                this._ke.getById(this.ke.id).toPromise().then(data => {
                  if (data) {
                    this.ke = data;
                    this.alert.message('formStateChanged', [new MessagePlaceholder('{serialNo}', this.ke.serialNo), new MessagePlaceholder('{status}', data.statusName)]);
                  }
                });
            });
          }
          break;
      }
  }

  async saveTask(saveTask: SaveTaskResource): Promise<any> {
    try {
      const data = await this._ke.saveWFTask(saveTask).toPromise();

      if (data) {
        // this._checklist.currentDocument = this.ke;
        this._ke.updateTask(data.wfTaskLocal);
      }

      return data;
    } catch (error) {
      console.error(error);
      this.alert.defaultError();
      throw error; // Re-throw the error to indicate that the promise was rejected
    }
  }

  async approve(taskId?: number, actionId?: number, saveTask: SaveTaskResource | null = null) {
    if (taskId && actionId) {
      let verificator = true;

      if (this.section === 5 && this.signedAll()) {
        verificator = await this.ShowAproveTohandshakeMessage();
        if (verificator && this.ke?.status === FormStatusEnum.KeyEnable) {
          const response = await this._olog.postToOlog(FormType.KE, this.ke.id, OlogNotificationType.Handshake);
          verificator = response?.ok ?? false;
        }
        else {
          const wfSignature = this.wfSignatures.find(x => x.taskID == taskId);
          if (wfSignature?.wfTaskLocal.wfTasks) {
            wfSignature.signed = false;
            wfSignature.signedBy = null;
            wfSignature.signedOn = null;
            wfSignature.wfTaskLocal.wfTasks[0].signedBy = { id: null, name: null, initials: null };
            this.refresh(this.ke);
          }
        }
      }

      if (verificator) {
        this.setLoading(this.ke?.status == FormStatusEnum.Draft || this.signedAll());
        let wfSignature = this.wfSignatures.find(x => x.taskID == taskId);
        if (saveTask) wfSignature = await this.saveTask(saveTask);
        this._ke.sign(taskId, actionId).toPromise().then(async res => {
          if (wfSignature?.wfTaskLocal.wfTasks) {
            wfSignature.signed = true;
            wfSignature.signedBy = this.currentUser;
            wfSignature.signedOn = new Date();
            wfSignature.wfTaskLocal.wfTasks[0].signedBy = { id: this.currentUser?.id, name: this.currentUser?.name, initials: this.currentUser?.initials };
            this._ke.updateTask(wfSignature.wfTaskLocal);
            if (this.ke) {
              const section = await this._ke.getSection(this.ke?.id, this.section).toPromise();
              const index = this.ke.wfTable?.wfTableLocal?.wfSectionLocals.findIndex(s => s.number == section?.number) ?? -1;
              if (index >= 0 && section) {
                this.ke.wfTable?.wfTableLocal?.wfSectionLocals.splice(index, 1);
                this.ke.wfTable?.wfTableLocal?.wfSectionLocals.push(section);
                this.store.dispatch(new KEnableUpdate(this.ke.id, this.ke));
              };
            }
            this.refresh(this.ke);
            if (res || (this.section === 2 && this.wfSignatures.filter(x => !x.signed).length === 0)) {
              if (this.section === 2 && this.wfSignatures.filter(x => !x.signed).length === 0) {
                this.refreshVacuum.emit();
              }
            }
          }
        }, error => {
          console.log(error);
          this.setLoading(false);
          this.alert.error(error.error);
        });
      }
    }

  }

  async unapprove(taskId?: number, actionId?: number) {
    if (taskId && actionId) {
      this.wfTaskSignature.taskId = taskId;
      this.wfTaskSignature.actionId = actionId;
      const wfSignature = this.wfSignatures.find(x => x.taskID == taskId);
      if (wfSignature?.wfTaskLocal.wfTasks) {
        wfSignature.signed = false;
        wfSignature.signedBy = null;
        wfSignature.signedOn = null;
        wfSignature.wfTaskLocal.wfTasks[0].signedBy = null;
        this._ke.updateTask(wfSignature.wfTaskLocal);
        await this._ke.sign(taskId, actionId).toPromise().then(res => {
        }, error => {
          console.log(error);
          this.alert.error(error.error);
        });
      }
    }
  }

  getEpicsValue(t: WfSignatureResource) {
    const resource = this.createObject(t);
    if (!t.signed) {
      this.disableEditVacuum = false;
      t.doubleValue = resource.vacuumValue;
      return this.createObject(t);
    }
    else {
      const result = {} as EpicsValuesResource;
      result.question = this.formatSciNotation(t.doubleValue);
      result.vacuumValue = t.doubleValue;
      result.isValid = true;
      result.name = resource.name;
      result.channelName = resource.channelName;
      result.keVacuumMaxRequirement = resource.keVacuumMaxRequirement;
      result.keVacuumMinRequirement = resource.keVacuumMinRequirement;
      return result;
    }
  }

  createObject(t: WfSignatureResource) {
    const q = t.text;
    const resourceName = t.titleAfter.replace('<b>', '').replace('</b> = ', '');

    const channelName = q.match(/\{{(.*)\}}/)?.[1].match(/\[(.*)\]/)?.[1];
    let resource = this.epicsValues?.find(e => e.name === resourceName);

    if (resource != null) {
      let dvalue;
      if (t.signed) dvalue = t.doubleValue;
      else if (!t.signed && !t.doubleValue) dvalue = resource.vacuumValue;
      else dvalue = t.doubleValue;
      let value = this.formatSciNotation(dvalue);
      if (value == null || value == undefined) value = '';
      const match = q.match(/\{{(.*)\}}/) ?? q;
      resource.question = this.disabled && match ? null :
        resource ? q.replace(match[0], value).replace('{rack}', resource.location ?? '') : 'Value not found';

      resource.isValid = (dvalue == undefined || dvalue == null) ? false : (dvalue ?? 0) >= (resource.keVacuumMinRequirement ?? 0) && (dvalue ?? 0) <= (resource.keVacuumMaxRequirement ?? 0);

      t.enabled = !t.signed;
      resource.vacuumValue = dvalue;
    }
    else {
      resource = {
        id: 0,
        name: null,
        type: 4,
        channelName: null,
        vacuumValue: null,
        location: null,
        keVacuumMinRequirement: null,
        keVacuumMaxRequirement: null,
        question: null,
        isValid: false
      };
    }
    return resource;
  }

  loadEpicsValues() {
    this.epicsValues$ = this.store.select(state => state.Resources.epics);
    this.epicaValiesSubs = this.epicsValues$.subscribe(data => {
      this.epicsValues = data;
      this.epicsValues.map(e => {
        const vacummValue = this.pvs?.find(p => p.pvName == e.channelName)?.value;
        if (vacummValue)
          e.vacuumValue = +vacummValue;
        else e.vacuumValue = null;
      });
    });
  }

  saveEpicsValues() {
    const confirm = this.dialog.open(YesNoDialogComponent, {
      width: '400px',
      data: {
        message: this.getMessage('KEResetVacuumValues').description,
        icon: 'stop'
      }
    });
    confirm.afterClosed().toPromise().then(data => {
      if (data) {
        this.setLoading(true);
        this.refreshAndSaveVacuum().then(() => {
          this.setLoading(false);
        });
      }
    });
  }

  refreshAndSaveVacuum() {
    return new Promise(async (resolve, reject) => {
      let save = false;
      this.setLoading(true);
      const saveTasks: SaveTaskResource[] = [];
      let saveTask: SaveTaskResource;
      this.wfSignatures.map(async s => {
        saveTask = {
          id: s.id,
          option: WFSaveOptions.AUTO,
          formType: FormType.KE,
        };
        switch (s.type) {
          case 6:
            if (s.signed && s.unapproveID) {
              this.unapprove(s.taskID, s.unapproveID);
            }
            saveTask.field = 'numericValue';
            saveTask.numericValue = 0;
            break;
          case 8:
            if (s.text && s.text.includes('EPICS')) {
              const channelName = s.text.match(/\{{(.*)\}}/)?.[1].match(/\[(.*)\]/)?.[1].toUpperCase();
              const resource = this.epicsValues?.find(e => e.channelName?.toUpperCase() === channelName);
              saveTask.field = 'doubleValue';
              saveTask.doubleValue = resource?.vacuumValue == 0 ? 0 : resource?.vacuumValue ?? null;
              saveTask.resourceId = resource?.id;
              save = true;
            }
            else {
              saveTask.field = 'text';
              saveTask.text = null;
            }
            break;
        }
        saveTasks.push(saveTask);
      });
      if (save)
        this._ke.saveWFTasks(saveTasks).toPromise().catch(error => console.log(error));
    });
  }

  save(saveTask: SaveTaskResource) {
    return new Promise((resolve, reject) => {
      this._ke.saveWFTask(saveTask).toPromise().then(async wfSignatureResource => {
        const index = this.ke?.wfSignatures?.findIndex(x => x.id == wfSignatureResource?.id);
        if (index && wfSignatureResource) {
          let wfSignature = this.ke?.wfSignatures?.[index];
          if (wfSignature) {
            wfSignature = wfSignatureResource;
            this._ke.updateTask(wfSignatureResource.wfTaskLocal);
            resolve(wfSignatureResource);
          }
        }
      },
        error => {
          console.log(error);
          reject(error);
        });
    });
  }

  resetStatusToActive() {
    const confirm = this.dialog.open(YesNoDialogComponent, {
      width: '400px',
      data: {
        message: this.getMessage('KEResetToActive').description,
        icon: 'warn'
      }
    });

    confirm.afterClosed().toPromise().then(data => {
      if (data && this.ke?.id) {
        this.setLoading(true);
        const statusResource: SaveKEStatusResource = {
          id: this.ke?.id,
          status: FormStatusEnum.Active
        };

        this.alert.info(this.getMessage('formStateChanged').description.replace('{status}', 'Active').replace('{serialNo}', this.ke.serialNo));
        this.ke.status = FormStatusEnum.Active;
        this.ke.statusName = 'Active';
        this._ke.putStatus(statusResource).toPromise().then(async () => {
          this.wfSignatures.map(s => {
            if (s.signed) {
              this.wfTaskSignature.taskId = s.taskID;
              this.wfTaskSignature.actionId = s.unapproveID;
              this._ke.sign(s.taskID, s.unapproveID ?? 0).toPromise().then(res => {
              }, error => {
                console.log(error);
              });
            }
          });
          this.setLoading(false);
        });
      }
    });
  }

  skipStatusToShieldings() {
    if (this.ke?.id) {
      const statusResource: SaveKEStatusResource = {
        id: this.ke.id,
        status: FormStatusEnum.Shielding
      };
      this.ke.status = FormStatusEnum.Shielding;
      this.ke.statusName = 'Shielding';
      this.requestRefresh.emit(this.ke);

      this._ke.putStatus(statusResource).toPromise().then(() => {
        this.alert.info(this.getMessage('formStateChanged').description.replace('{status}', 'Shielding').replace('{serialNo}', this.ke?.serialNo ?? ''));
      });
    }
  }

  formatSciNotation(numberToFormat?: number | null) {
    if (numberToFormat != undefined && numberToFormat != null) {
      if (numberToFormat == 0) return '0.00';
      return numberToFormat.toExponential(2).toString().replace('e', ' x 10').replace('-', '<sup>-').replace('+', '<sup>');
    }
    else return null;
  }

  async editOlog() {
    try {
      if (this.ke) {
        const data = await this._ke.getOlog(this.ke.serialNo).toPromise();
        if (data)
          data.cc = data.cc?.filter(email => email.address !== '');
        const dialogRef = this.dialog.open(PartialOlogComponent, {
          height: 'fit-content',
          width: '80%',
          data
        });
        dialogRef.afterClosed().toPromise().then((isSaved) => {
          console.log('The dialog was closed');
          if (isSaved) {
            console.log(isSaved);
          }
        });
      }
    } catch (error) {
      console.log(error);
    }
  }


  //#region OLogService Call
  // async callOLogService(): Promise<boolean> {
  //   try {
  //     if (this.ke) {
  //       const olog = await this._ke.getOlog(this.ke.serialNo).toPromise();
  //       const siteConfig = await this.siteConfigurationService.getById(1).toPromise();

  //       if (olog && siteConfig) {
  //         let cc = olog?.cc;
  //         const body: OLogFormData = JSON.parse(olog.xmlBody.replace('\n', ''));
  //         const OlogObject: OLogEntry = {
  //           root: {
  //             author: siteConfig.ologUser,
  //             password: siteConfig.ologPassword,
  //             entry: body
  //           }
  //         };
  //         Array.prototype.push.apply(cc, OlogObject.root.entry.cc); // join all the emails
  //         cc = cc?.map(item => { return { address: item.address.toLowerCase() } as EmailAddress }); // change if it is necesary to lowercase (Specialy because Notifications don't make this)
  //         cc = [...new Set(cc)]; // Remove duplicates
  //         cc = cc.filter(mail => mail.address !== '');
  //         OlogObject.root.entry.cc = cc; // returns the uniques emails
  //         const xmlBody = await this._olog.createXMLBody(OlogObject, this.ke.serialNo); // create the XML String from the body
  //         const result = await this._olog.SendOLogEntry(xmlBody, this.ke.serialNo, olog);
  //         this.alert.success(this.getMessage('OLogEntryCreated').description.replace('{serialNo}', this.ke.serialNo));
  //         return result;
  //       }
  //     }
  //     return false;
  //   } catch (e) {
  //     this.alert.warning(this.getMessage('OlogEntryError').description);
  //     return false;
  //   }
  // }

  signedAll() {
    const ignoredComponentsTypes = [2];
    const wfSignaturesFiltered = this.section > 3 ? this.wfSignatures?.filter(x => x.required && !ignoredComponentsTypes.includes(x.component?.type ?? 0)) : this.ke?.wfSignatures?.filter(x => x.sectionNumber > 0 && x.sectionNumber <= 3 && x.required && !ignoredComponentsTypes.includes(x.component?.type ?? 0));
    const count = wfSignaturesFiltered?.filter(s => !s.signed).length;
    return count == 0;
  }

  async ShowAproveTohandshakeMessage(): Promise<boolean> {
    const confirm = this.dialog.open(YesNoDialogComponent, {
      width: '500px',
      data: {
        message: this.getMessage('toHandshakeWarning').description,
        icon: 'warn'
      }
    });
    const data: boolean = await confirm.afterClosed().toPromise();
    if (data) {
      return data;
    }
    return false;
  }

  returnButton() {
    return this.ke?.status === FormStatusEnum.Active && this.signedAll() && this.isVacuumSection;
  }

  canRestartShieldings() {
    let valid = false;

    if (!this.currentUser || !this.ke || this.currentUser?.id != this.shieldingsUserId || this.shieldingsUserId === 0) {
      return valid;
    }

    if (this.isShieldingsSection && this.ke.status === FormStatusEnum.Shielding || this.section === 5 && this.ke.status === FormStatusEnum.KeyEnable) {
      if (this.hasRoles([Roles.FO])) {
        valid = true;
      }
    }
    return valid;
  }

  async resetStatusShieldings() {
    const confirmed = await this.showResetConfirmationDialog();

    if (!confirmed) {
      return;
    }
    this.setLoading(true);
    this.resetSignatures();
    await this.updateStatusAndSignTasks();

    this.requestRefresh.emit(this.ke);
  }

  private async showResetConfirmationDialog(): Promise<boolean> {
    const dialogRef = this.dialog.open(YesNoDialogComponent, {
      width: '400px',
      data: {
        message: this.getMessage('KEResetShieldings').description,
        icon: 'warn'
      }
    });

    const result = await dialogRef.afterClosed().toPromise();
    return result !== null;
  }

  private resetSignatures() {
    this.wfSignatures.map(x => this.resetSignature(x));
    (this.ke?.wfSignatures?.filter(x => x.sectionNumber === 4) || []).map(x => this.resetSignature(x));
  }

  private resetSignature(signature: WfSignatureResource) {
    if (signature.signed) {
      signature.signed = false;
      signature.signedBy = null;
      signature.signedOn = null;
    }
  }

  private async updateStatusAndSignTasks() {
    if (this.ke) {
      const statusResource: SaveKEStatusResource = {
        id: this.ke.id,
        status: FormStatusEnum.Shielding
      };

      this.alert.info(this.getMessage('formStateChanged').description.replace('{serialNo}', this.ke.serialNo).replace('{status}', 'Shielding'));
      this.setLoading(true);
      await this._ke.putStatus(statusResource).toPromise();
      if (this.ke?.wfTableID) {
        this.setLoading(true);
        const dataSection4 = (await this._ke.readWFBySection(this.ke.wfTableID, 4).toPromise()) ?? [];
        const dataSection5 = await this._ke.readWFBySection(this.ke.wfTableID, 5).toPromise() ?? [];
        this.setLoading(true);
        await this.signTasks(dataSection4);
        await this.signTasks(dataSection5);
      }
    }
  }

  private async signTasks(tasks: WfSignatureResource[]) {
    for (const task of tasks) {
      if (task.signed) {
        this.wfTaskSignature.taskId = task.taskID;
        this.wfTaskSignature.actionId = task.unapproveID;

        try {
          this.setLoading(true);
          await this._ke.sign(task.taskID, task.unapproveID ?? 0).toPromise();
        } catch (error) {
          console.log(error);
        }
      }
    }
  }


  resetSection4(): string {
    return this.section === 4 ? 'Reset Section 4' : 'Reset & Return to Section 4';
  }

  duplicated() {
    this.refresh(this.ke);
  }

  checkConditions() {
    const tags = this.wfSignatures.filter(x => x.code).map(x => x.code);
    tags.map(tag => {
      if (tag) {
        const filteredSignatures = this.wfSignatures.filter(x => x.condition?.includes(tag));

        filteredSignatures.map(async signature => {
          if (signature.condition) {
            const value = this.utils.conditionParse(signature.condition, this._ke.allTasks);
            if (signature.required !== value) {
              signature.required = value;
              const task = signature.wfTaskLocal.wfTasks?.[0];
              if (task)
                task.required = value;
              await this._ke.saveRequiredWFTask(signature.taskID, value).toPromise();
            }
          }
        });
      }
    });
  }


  getChecked(wfSignature: WfSignatureResource): string {
    const component = this.components.find(x => x.id == wfSignature.type);
    if (component?.hasMultipleCheckboxes) {
      const storedValues = this.utils.JSONparse(wfSignature.text);
      const values = storedValues.find((x: any) => x.key == 'checked').val;
      return values.filter((x: any) => x.checked).map((y: any) => y.value).join(',');
    }
    if (component?.hasRadioButtons) {
      return wfSignature.numericValue?.toString() ?? '';
    }
    return '';
  }

  closed() {
    this.requestRefresh.emit(this.ke);
  }

  setLoading(value: boolean) {
    this.loading = value;
    this.loadingChanged.emit(this.loading);
  }

}

interface SCFRSObj {
  scfMaster: SCFMaster;
  checklists?: Checklist[];
}

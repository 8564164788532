
import { Role } from '../../roles/services/role';
import { PendingAccelerator, PendingResource } from '../../../../services/pending-approvals/pending-approval';
import { RSSTestType, PendingResourcesType } from '../../../../common/enumerations/enumerations';
import { Link } from '../../navigation-links/link/link';

import { Organization } from '../../../../services/organization/organization';
import { TOCA } from './toca';
import { Note } from '../../../../services/notes/note.model';
import { KETStatusResource, KEVersionResource } from 'src/app/components/kenable/ke';
import { AbsiTemplateResource } from 'src/app/components/absi/absi';
import { Restriction } from '../../../../controls/restrictions/services/restriction';
import { User } from 'src/app/components/catalogs/user-catalog/services/user';

export interface IResource {
  id: number;
  name: string;
  type: number;
  order: string;
  description: string;
  resourceTypeID: number;
  resourceTypename: string;
  beamLineExtension: string;
  beamLineSupervisorID: number;
  beamLineScientistID: number;
  beamLineAlternateScientistID: number;
  beamLineNotes: string;
  beamLineDCCSynch: string;
  beamLineDCCBrem: string;
  beamLineDCCOther: string;
  rssChannelName: string;
  pssChannelName: string;
  operationalLockOut: boolean;
  keReady?: string;
  keColor?: string;
  documents?: string[];
  moreInfo?: string;
}


export class Resource {
  constructor() {
    this.id = 0;
    this.name = null;
    this.type = null;
    this.order = null;
    this.status = null;
    this.description = null;
    this.resourceTypeID = null;
    this.beamLineExtension = null;
    this.keRestricted = null;
    this.reviewRestrictedResourceID = null;
    this.reviewRestrictedResource = null;
    this.rssChannelName = null;
    this.pssChannelName = null;
    this.statusResourceId = null;
    this.position = null;
    this.keText = null;
    this.cssClass = null;
    this.keRequirement = null;
    this.channelName = null;
    this.location = null;
    this.wfTableID = null;
    this.wfTableIDAbreviated = null;
    this.drawingPostLocation = null;
    this.rssRackLocation = null;
    this.epsRackLocation = null;
    this.fvsemLocation = null;
    this.checkboxVisible = false;
    this.longShutdown = false;
    this.inTunnel = false;
    this.keVacuumMinRequirement = null;
    this.keVacuumMaxRequirement = null;
    this.MinPressureString = null;
    this.MaxPressureString = null;
    this.sharedWith = null;
    this.subLocations = null;
    this.color = null;
    this.modifyingById = null;
    this.statusModel = null;
    this.operationalLockOut = false;
    this.newResource = false;
    this.code = null;
    this.absiTemplateTypeID = null;
    this.absiTemplateName = null;
    this.comment = null;
    this.lastCommentByUser = null;
    this.lastCommentByUserId = null;
    this.lastCommentOn = null;
    this.parentResources = null;
    this.childResources = null;

    this.RSSTestType = null;
    this.RSSTestProcedureNumber = null;
    this.RSSTestShutterKeyCode = null;
    this.RSSTestWiringDiagramPrint = null;
    this.RSSTestSafetyInterlockControlsChassisPrint = null;
    this.RSSTestBeamlineHutchInterlockControlsPrint = null;
    this.RSSTestRackLocation = null;
    this.RSSTestSectorNumber = null;
    this.RSSTestEPSPLCLocation = null;
    this.RSSTestHutchInterlockControlsChassisLocation = null;
    this.RSSTestInternalCrashOffSearchBoxLocations = null;
    this.RSSTestExternalCrashOffSearchBoxLocations = null;
    this.RSSTestSRRFINTLKSOKLampsLocation = null;
    this.RSSTestTimeDelay = null;
    this.RSSTestArcSectorInterlockSummingChassisLocation = null;
    this.RSSTestESVacuumSwitchInterfaceBox = null;
    this.RSSTestShortingPlugs = false;
    this.RSSTestMagnetPlate = false;
    this.RSSTestMicroswitchClamps = null;
    this.RSSTestShuttersRequiredToBeClosedForTest = null;
    this.isModified = false;
  }
  id!: number;
  name?: string | null;
  type: number | null;
  order?: string | null;
  status?: number | null;
  description?: string | null;
  resourceTypeID?: number | null;
  //resourceTypeName?: string;
  beamLineExtension?: string | null;
  beamLineSupervisorID?: number | null;
  beamLineScientistID?: number | null;
  beamLineAlternateScientistID?: number | null;
  beamLineNotes?: string | null;
  // beamLineDCCSynch?: string | null;
  // beamLineDCCBrem?: string | null;
  // beamLineDCCOther?: string | null;
  keRestricted?: boolean | null;
  reviewRestrictedResourceID?: number | null;
  reviewRestrictedResource?: Resource | null;
  rssChannelName?: string | null;
  pssChannelName?: string | null;
  statusResourceId?: number | null;
  position?: number | null;
  keText?: string | null;
  cssClass?: string | null;
  keRequirement?: string | null;
  channelName?: string | null;
  location?: string | null;
  wfTableID?: number | null;
  wfTableIDAbreviated?: number | null;
  drawingPostLocation?: string | null;
  rssRackLocation?: string | null;
  epsRackLocation?: string | null;
  fvsemLocation?: string | null;
  checkboxVisible?: boolean | null;
  longShutdown?: boolean | null;
  inTunnel?: boolean;
  isRSSD?: boolean;
  keVacuumMinRequirement?: number | null;
  keVacuumMaxRequirement?: number | null;
  MinPressureString?: string | null;
  MaxPressureString?: string | null;
  sharedWith?: string | null;
  subLocations?: string | null;
  color?: number | null;
  modifyingById?: number | null;
  statusModel?: StatusResource | null;
  operationalLockOut?: boolean | null;
  newResource?: boolean | null;
  code?: string | null;
  absiTemplateTypeID?: number | null;
  absiTemplateName?: string | null;
  comment?: string | null;
  lastCommentByUser?: User | null;
  lastCommentByUserId?: number | null;
  lastCommentOn?: Date | null;
  parentResources?: ResourceRelation[] | null;
  childResources?: ResourceRelation[] | null;
  resourceType?: ResourceType | null;
  pendingAccelerators?: PendingAccelerator[] | null;

  // RSS Test
  RSSTestType?: RSSTestType | null;
  RSSTestProcedureNumber?: string | null;
  RSSTestShutterKeyCode?: string | null;
  RSSTestWiringDiagramPrint?: string | null;
  RSSTestSafetyInterlockControlsChassisPrint?: string | null;
  RSSTestBeamlineHutchInterlockControlsPrint?: string | null;
  RSSTestRackLocation?: string | null;
  RSSTestSectorNumber?: string | null;
  RSSTestEPSPLCLocation?: string | null;
  RSSTestHutchInterlockControlsChassisLocation?: string | null;
  RSSTestInternalCrashOffSearchBoxLocations?: string | null;
  RSSTestExternalCrashOffSearchBoxLocations?: string | null;
  RSSTestSRRFINTLKSOKLampsLocation?: string | null;
  RSSTestTimeDelay?: string | null;
  RSSTestArcSectorInterlockSummingChassisLocation?: string | null;
  RSSTestESVacuumSwitchInterfaceBox?: string | null;
  RSSTestShortingPlugs?: boolean | null;
  RSSTestMagnetPlate?: boolean | null;
  RSSTestMicroswitchClamps?: string | null;
  RSSTestShuttersRequiredToBeClosedForTest?: RSSTestShutterRequiredToBeClosedForTest[] | null;

  // Compare
  isModified?: boolean;
  displayName?: string | null;
}

export class ResourceSummary {
  constructor() {
    this.shutter = new Resource();
    this.beamline = new Resource();
    this.endstations = [];
    this.branchlines = [];
    this.restrictions = [];
    this.shieldings = [];
    this.vacuums = [];
    this.organizations = [];
    this.supervisors = [];
    this.supervisors = [];
    this.scientists = [];
    this.alternateScientists = [];
    this.notes = [];
    this.otherLinks = [];
    this.meDrawing = [];
    this.apPhaseSpace = [];
    this.saSurvey = [];
    this.fastValveSensors = [];
    this.toca = [];
  }
  shutter?: Resource;
  beamline?: Resource;
  endstations?: Resource[];
  branchlines?: Resource[];
  restrictions?: Restriction[];
  shieldings?: Resource[];
  vacuums?: Resource[];
  organizations?: ResourceOrganization[];
  supervisors?: ResourceUserRelation[];
  scientists?: ResourceUserRelation[];
  alternateScientists?: ResourceUserRelation[];
  notes?: Note[];
  otherLinks?: Link[];
  meDrawing?: Link[];
  apPhaseSpace?: Link[];
  saSurvey?: Link[];
  fastValveSensors?: Resource[];
  toca?: TOCA[];
}

export class BeamlineShutterRelation {
  beamlineId?: number;
  beamlineName?: string;
  shutterId?: number;
  shutterName?: string;
  status?: number;
  beamlineStatus?: string;
  createdBy?: User;
  ketStatus!: KETStatusResource;
  isModifyng?: boolean;
  pendingResources!: PendingResource[];
  type: PendingResourcesType = 0;
  statusClass?: string;
}

// export class BeamlineStaff {
//   beamlineId?: number;
//   beamlineName?: string;
//   shutterId?: number;
//   shutterName?: string;
//   beamlineStatus?: string;
//   color?: number;
//   public dataSourceSupervisor?: ResourceUserRelation[] = [];
//   public dataSourceScientist?: ResourceUserRelation[] = [];
//   public dataSourceAlternateScientist?: ResourceUserRelation[] = [];

//   constructor(beamlineId_?: number, beamlineName_?: string, shutterId_?: number, shutterName_?: string, beamlineStatus_?: string, color?: number) {
//     this.beamlineId = beamlineId_;
//     this.beamlineName = beamlineName_ ?? '';
//     this.shutterId = shutterId_;
//     this.shutterName = shutterName_ ?? '';
//     this.beamlineStatus = beamlineStatus_ ?? '';
//     this.color = 0;
//   }

// }

export class ResourceUserRelation {
  id?: number;
  resourceId?: number;
  userId?: number;
  roleId?: number;
  endstationId?: number;
  branchlineId?: number;
  resource?: Resource;
  endstation?: Resource;
  branchline?: Resource;
  user?: User;
  role?: Role;
  joinEndstations?: string[];
  joinBranchlines?: string[];
  resourceUserRelations?: ResourceUserRelation[];
  isModified?: boolean;
}

export class ResourceTOCARelation {
  id?: number;
  resourceId?: number;
  tocaId?: number;
  resource?: Resource;
  toca?: TOCA;
}

export class StatusResource {
  id!: number;
  statusName!: string;
}

export class ResourceOrganization {
  id?: number;
  resourceId?: number;
  organizationId?: number;
  endstationId?: number;
  branchlineId?: number;
  resource?: Resource;
  endstation?: Resource;
  branchline?: Resource;
  organization?: Organization;
  isModified?: boolean;
}

export class SaveResource {
  name!: string | null | undefined;
  type!: number | null | undefined;
  description!: string;
  status!: number;
  order!: string | null | undefined;
  code?: string;
  resourceTypeID!: number | null;
  beamLineExtension!: string;
  keRestricted?: boolean | null;
  reviewRestrictedResourceID?: number | null;
  beamLineSupervisorID?: number | null;
  beamLineScientistID?: number | null;
  beamLineAlternateScientistID?: number | null;
  beamLineNotes?: string | null;
  // beamLineDCCSynch!: string;
  // beamLineDCCBrem!: string;
  // beamLineDCCOther!: string;
  rssChannelName?: string | null;
  pssChannelName?: string | null;
}

export interface ResourceRelation {
  id?: number;
  parentResourceID?: number;
  parentResourceName?: string;
  parentResourceType?: number;
  childResourceID?: number;
  parentResource?: Resource | null;
  childResourceName?: string | null;
  childResourceTypeName?: string;
  childResourceDescription?: string;
  childResourceType?: number | null;
  childResource?: Resource | null;
  relatedShielding?: boolean;
  shared?: boolean;
  sharedWith?: string[];
  position?: number;
  locationsIDs?: string;
  locations?: Resource[];
}

export class ResourceType {
  id!: number;
  name!: string;
  description!: string;
  location: boolean = false;
}

export interface IResourceType {
  id: number;
  name: string;
  description: string;
  location: boolean;
}

export interface BeamlineObj {
  beamlineId: number;
  beamlineName: string;
  shutterID: number;
  shutterName: string;
  rssStatus: string;
  shutterStatus: string;
  keReady: string;
  rssColor: string;
  pssColor: string;
  rssChannelName: string;
  pssChannelName: string;
  keColor: string;
  order: string;
  status: number;
  documents: Document[];
  moreInfo: string;
}

export interface Accelerator {
  id: number;
  name: string;
  code: string;
  status: string;
  statusColor: string;
  documents: string[];
  moreInfo: string;
}

export class ShutterResource {
  id?: number;
  name!: string;
  type!: number;
  order?: string;
  status!: number;
  description?: string;
  resourceTypeID?: number;
  resourceTypeName?: string;
  beamLineExtension?: string;
  beamLineSupervisorID?: number;
  beamLineScientistID?: number;
  beamLineAlternateScientistID?: number;
  beamLineNotes?: string;
  beamLineDCCSynch?: string;
  beamLineDCCBrem?: string;
  beamLineDCCOther?: string;
  rssChannelName?: string;
  pssChannelName?: string;
  statusResourceId?: number;
  position?: number;
  keText?: string;
  keRequirement?: string;
  channelName?: string;
  location?: string;
  beamline: Resource = new Resource;
  branchlines: Resource[] = [];
  wfTableID?: number;
  wfTableIDAbreviated?: number;
  keVersions: KEVersionResource[] = [];
  drawingPostLocation!: string;
  epsRackLocation!: string;
  rssRackLocation!: string;
  fvsemLocation!: string;
  vacuums: Resource[] = [];
}

// export interface ShutterResource extends ModelBase {
//   order: string;
//   resourceTypeName: string;
//   statusResourceId: number | null;
//   position: number | null;
//   kEText: string;
//   keRequirement: string;
//   drawingPostLocation: string;
//   fVSEMLocation: string;
//   rSSRackLocation: string;
//   ePSRackLocation: string;
//   channelName: string;
//   location: string;
//   branchlines: ResourceResource[];
//   vacuums: ResourceResource[];
//   kETexts: KETextResource[];
//   kEVersions: KEVersionResource[];
//   beamline: ResourceResource;
//   userRelations: ResourceUserRelation[];
//   beamlineName: string;
//   wFTableID: number;
//   wFTableIDAbreviated: number | null;
//   versionName: string;
// }

export class BeamlineResource {
  id?: number;
  name!: string;
  type!: number;
  order?: string;
  status!: number;
  description?: string;
  resourceTypeID?: number;
  resourceTypeName?: string;
  beamLineExtension?: string;
  beamLineSupervisorID?: number;
  beamLineScientistID?: number;
  beamLineAlternateScientistID?: number;
  beamLineNotes?: string;
  beamLineDCCSynch?: string;
  beamLineDCCBrem?: string;
  beamLineDCCOther?: string;
  rssChannelName?: string;
  pssChannelName?: string;
  statusResourceId?: number;
  position?: number;
  keText?: string;
  keRequirement?: string;
  channelName?: string;
  location?: string;
  branchlines: Resource[] = [];
  wfTableID?: number;
  templateVersions: AbsiTemplateResource[] = [];
  drawingPostLocation!: string;
  epsRackLocation!: string;
  rssRackLocation!: string;
  fvsemLocation!: string;

}

export class RSSTestShutterRequiredToBeClosedForTest {
  ID?: number;
  BeamlineID?: number;
  ShutterID?: number;
  Shutter?: Resource;
}

export interface Location {
  id?: number;
  name?: string | null;
  type?: number | null;
  status?: number;
  description?: string;
  resourceTypeID?: number;
  resourceType?: ResourceType | null;
  beamLineExtension?: string;
  beamLineSupervisorID?: string;
  beamLineScientistID?: string;
  beamLineAlternateScientistID?: string;
  beamLineNotes?: string;
  beamLineDCCSynch?: string;
  beamLineDCCBrem?: string;
  beamLineDCCOther?: string;
  newLocation?: boolean;
  autoLocation?: boolean;
}

export interface LocationGroup {
  name?: string | null;
  type?: number | null;
  id?: number;
  locations?: Location[];
}

export interface ResourceGroup {
  name?: string | null;
  type?: number | null;
  id?: number;
  resources?: (Resource | undefined | Location)[];
}

export class ShieldingGroup {
  name?: string | null;
  type?: number | null;
  id?: number;
  shieldings?: ResourceRelation[];
}

import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'head-logo',
  templateUrl: './head-logo.component.html',
  styleUrls: ['./head-logo.component.scss']
})
export class HeadLogoComponent {
  environment = environment;
}

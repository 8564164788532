import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Component, Input, QueryList, ViewChildren, OnChanges, SimpleChanges, Output, EventEmitter, Injector } from '@angular/core';
import { ChecklistTemplate } from '../../checklists';
import { ChecklistBuilderService } from '../checklist-builder.service';
import { ChecklistBuilderStepComponent } from './checklist-builder-step/checklist-builder-step.component';
import { Procedure } from 'src/app/components/procedure/models/procedure.model';
import { WFStepLocalResource } from 'src/app/services/work-flow/work-flow';
import { utils } from 'src/app/modules/libs/utils';
import { TransactionLogService } from 'src/app/services/logs/transaction-log.service';
import { BaseComponent } from 'src/app/common/base/base.component';
import { YesNoDialogComponent } from 'src/app/controls/yes-no-dialog/yes-no-dialog.component';

@Component({
  selector: 'checklist-builder-steps',
  templateUrl: './checklist-builder-steps.component.html',
  styleUrls: ['./checklist-builder-steps.component.scss']
})
export class ChecklistBuilderStepsComponent extends BaseComponent implements OnChanges {

  @Input() steps!: WFStepLocalResource[];
  @Input() currentVersion!: ChecklistTemplate;
  @Input() wfSectionLocalID!: number;
  @Input() disabled!: boolean;
  @Input() procedure?: Procedure | null;
  @Input() sectionDropLists!: string[];
  @Input() stepDropLists!: string[];
  @Input() showDeleted?: boolean;
  @Input() isDeletedSection?: boolean;

  @Output() loading = new EventEmitter();
  @Output() refreshPreviousStep = new EventEmitter<number>();
  @Output() refreshDeletedSteps = new EventEmitter();
  @Output() refreshMap = new EventEmitter();

  @ViewChildren(ChecklistBuilderStepComponent) stepComponents!: QueryList<ChecklistBuilderStepComponent>;

  constructor(
    protected override injector: Injector,
    public service: ChecklistBuilderService,
    private logService: TransactionLogService
  ) {
    super(injector);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.steps = this.steps?.sort((a, b) => a.order - b.order);
  }

  add() {
    this.service.createStep(this.wfSectionLocalID).subscribe(data => {
      this.steps.push(data);
      this.renumberArray();
    });
  }

  deleted(step: WFStepLocalResource) {
    this.refreshDeletedSteps.emit();
    const index = this.steps.findIndex(x => x.id === step.id);
    if (index !== -1) {
      this.steps.splice(index, 1);
    }
    // this.steps.splice(this.steps.findIndex(x => x.id == step.id), 1);
    this.renumberArray();
  }

  // Drag
  drop(event: CdkDragDrop<WFStepLocalResource[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
      this.renumberArray();
    } else {
      const sectionID = event.container.data[0].wfSectionLocalID;
      const step = event.previousContainer.data[event.previousIndex];
      step.wfSectionLocalID = sectionID;
      transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
      this.renumberArray();
    }
  }

  renumberArray() {
    let order = 0;
    this.steps.map(s => {
      if (s.order != order) {
        s.order = order;
        this.service.updateStep(s).toPromise().then();
      }
      order++;
    });
    this.steps = this.steps.sort((a, b) => a.order - b.order);
    this.refreshMap.emit();
  }

  // refreshRestored(step: WFStepLocalResource) {
  //   this.steps.push(step);
  //   this.steps = this.steps.sort((a, b) => utils.sort(a.order, b.order));
  //   this.renumberArray();
  // }

  setLoading(e: boolean) {
    this.loading.emit(e);
  }

  restore(step: WFStepLocalResource) {
    if (step.logID)
      this.service.restoreStep(step.logID).toPromise().then(data => {
        if (data) {
          data.order = (data.order ?? 0) - 1;
          this.steps.push(step);
          this.steps = this.steps.sort((a, b) => utils.sort(a.order, b.order));
          this.renumberArray();
          this.refreshDeletedSteps.emit();
          this.alert.success('Step: <b>' + data.name + '</b> has been Restored!.');
          this.scrollToStep(data.id);
        }
      });
  }

  deleteForever(step: WFStepLocalResource) {
    const yesno = this.dialog.open(YesNoDialogComponent, {
      width: "400px",
      data: {
        message: 'Are you sure you want to Remove this Deleted Step Forever?', // this.getMessage("CB_Delete_Step_Confirmation")          .description,
        icon: "stop",
      },
    });
    yesno.afterClosed().subscribe((data) => {
      if (data) {
        if (step.logID)
          this.logService.UpdateStatus(step.logID).toPromise().then(() => {
            this.refreshDeletedSteps.emit();
          });
      }
    });
  }

  scrollToStep(stepID: number) {
    setTimeout(() => {
      const element = document.getElementById('step-' + stepID);
      if (element) {
        element.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'start',
        });
      }
    }, 500);
  }
}

import { Component, OnInit, Inject, Output, EventEmitter, Injector } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { BakeoutService } from '../bakeout.service';
import { Bakeout } from '../bakeouts';
import { BaseComponent } from 'src/app/common/base/base.component';

@Component({
  selector: 'app-bakeout-archive',
  templateUrl: './archive.component.html',
  styleUrls: ['./archive.component.scss']
})
export class BakeoutArchiveComponent extends BaseComponent implements OnInit {
  @Output() copyItemEvent = new EventEmitter<Bakeout>();

  archives: Bakeout[] = [];
  filtered: Bakeout[] = [];

  //Filters
  Month!: number;
  Year!: number;
  Filter!: string;

  constructor(
    public modal: MatDialogRef<BakeoutArchiveComponent>,
    public service: BakeoutService,
    protected override injector: Injector
  ) {
    super(injector);
  }

  copy(item: Bakeout) {
    this.modal.close();
    this.copyItemEvent.emit(item);
  }

  numberOnly(event: any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }

    if (event.target.name == "Month") {
      const currentMonth = this.Month ? this.Month.toString() : "0"
      const testMonth = Number(currentMonth + event.key);
      if (testMonth > 12)
        return false;
    } else if (event.target.name == "Year") {
      const currentYear = this.Year ? this.Year.toString() : "0"
      const testYear = Number(currentYear + event.key);
      if (testYear > (new Date()).getFullYear())
        return false;
    }

    return true;
  }

  filterHistory() {
    let query: Bakeout[] = this.archives
    if (!isNaN(this.Month) && this.Month) {
      query = query.filter(item => new Date(item.createdOn).getMonth() == this.Month - 1);
    }

    if (!isNaN(this.Year) && this.Year) {
      query = query.filter(item => new Date(item.createdOn).getFullYear() == this.Year);
    }

    if (this.Filter && this.Filter != "") {
      query = query.filter(item => item.description.toLowerCase().includes(this.Filter.toLowerCase()));
    }

    this.filtered = query;
  }

  ngOnInit() {
    const self = this;
    this.service.getArchive().subscribe({
      next(data: Bakeout[]) {
        self.archives = self.service.loadArchives(data);
        self.filterHistory();
      },
      error(msg) {
        console.log(msg);
      }
    });
  }
}

<div class="container-fluid mb-3">
  <div class="row no-gutters">
    <div class="col-6">
      <h5 id="Section1Title">
        {{ sectionName }}
      </h5>
    </div>
    <div class="col-6 right-align">
      <span *ngIf="isVacuumSection && !disabled" class="btn float-right m-0">
        <button
          mat-flat-button
          color="warn"
          (click)="saveEpicsValues()"
          [disabled]="disableButtons()"
        >
          <mat-icon>update</mat-icon>&nbsp;Refresh Vacuum Values
        </button>
      </span>
      <span *ngIf="canRestartShieldings()" class="btn float-right m-0">
        <button
          mat-flat-button
          color="primary"
          (click)="resetStatusShieldings()"
        >
          <mat-icon color="warn">settings_backup_restore</mat-icon>
          {{ resetSection4() }}
        </button>
      </span>
      <span
        *ngIf="isShieldingsSection && !disabled"
        class="btn float-right m-0"
      >
        <button
          mat-flat-button
          color="primary"
          (click)="resetStatusToActive()"
          [disabled]="disableButtons()"
        >
          <mat-icon>settings_backup_restore</mat-icon>Revert to Previous Status
        </button>
      </span>
      <span *ngIf="ologButton" class="btn float-right m-0">
        <button mat-stroked-button color="primary" (click)="editOlog()">
          Edit Olog Notification&nbsp;<mat-icon>backup</mat-icon>
        </button>
      </span>
    </div>
  </div>

  <div *ngFor="let s of signatures">
    <div class="row no-gutters" *ngIf="s.required">
      <div class="col-12 p-1">
        <master-control
          [type]="s.type"
          [taskId]="s.id"
          [visible]="s.required"
          [enabled]="s.enabled"
          [disabled]="s.disabled"
          [color]="'primary'"
          [number]="s.number"
          [header]="s.number == null ? s.name : s.number.toString()"
          [question]="s.question"
          [label]="s.name"
          [text]="s.question"
          [radioQuestion]="s.question2"
          [radioOptions]="s.options"
          [radioSelection]="s.value2"
          [checkbox_checked]="s.approved"
          [approved]="s.approved"
          [approvedBy]="s.approvedBy"
          [approvedOn]="s.approvedOn"
          [roleIds]="s.roles"
          [roleCodes]="s.roleCodes"
          [textBefore]="
            s.type == 6 || s.type == 8 || s.type == 9 ? s.question2 : s.question
          "
          [textAfter]="
            s.type == 6 || s.type == 8 || s.type == 9 ? s.question : s.question2
          "
          [textValue]="s.textValue"
          [checkboxVisible]="s.visible"
          [disableEdit]="s.disableEdit"
          [warning]="s.warning"
          color="primary"
          [moreInfo]="s.moreInfo ?? ''"
          [textAfter2]="s.question3"
          [scheduleTypeId]="s.scheduleTypeId"
          [scheduleResourceId]="s.scheduleResourceId"
          [dateValue1]="s.dateValue1"
          [dateValue2]="s.dateValue2"
          [dateValue3]="s.dateValue3"
          [scheduleName]="s.scheduleName"
          [locationName]="s.locationName"
          [configuration]="s.configuration"
          [showApprove]="s.approve"
          [showUnapprove]="s.unapprove"
          [showDisapprove]="s.disapprove"
          [userNames]="s.moreInfo ?? ''"
          style="width: 100%"
          [currentChecklist]="ke ?? undefined"
          (changed)="change($event, s.id, s.type, s.isVacuum)"
          (duplicate)="duplicated()"
          (close)="closed()"
          (checked)="
            check(
              $event,
              s.taskId,
              s.approveId,
              s.unapproveId,
              s.condition,
              s.moreInfo ?? '',
              s.configuration
            )
          "
          (approve)="approve(s.taskId, s.approveId)"
          (unapprove)="unapprove(s.taskId, s.unapproveId)"
          (save)="change($event, s.id, s.type, s.isVacuum)"
          (loading)="setLoading($event)"
        >
        </master-control>
      </div>
    </div>
  </div>
  <div class="row no-gutters">
    <div class="col-3 offset-9">
      <span
        *ngIf="isVacuumSection && !disabled && abbreviated && !loading"
        class="btn float-right m-0"
      >
        <button
          mat-flat-button
          color="primary"
          (click)="skipStatusToShieldings()"
          [disabled]="disableButtons()"
        >
          <mat-icon color="warn">next_plan</mat-icon>&nbsp;Skip to Section 4
        </button>
      </span>
      <span
        *ngIf="
          isVacuumSection &&
          !disabled &&
          !abbreviated &&
          returnButton() &&
          !loading &&
          completeActive
        "
        class="btn float-right m-0"
      >
        <button
          mat-flat-button
          color="primary"
          (click)="skipStatusToShieldings()"
          [disabled]="disableButtons()"
        >
          <mat-icon color="warn">next_plan</mat-icon>&nbsp;Return to Section 4
        </button>
      </span>
    </div>
  </div>
</div>

import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { utils } from 'src/app/modules/libs/utils';

@Injectable({
  providedIn: 'root'
})
export class ClImageUploadTableDataService {


  formatValue(value: any) {
    let result = '';
    if (value)
      if (value?.includes('e-')) {
        const e = value.split('e');
        result = utils.roundNumber(e[0], 2) + ' x 10<sup>' + e[1] + '</sup>';
      }
      else {
        result = isNaN(+value) ? value : utils.roundNumber(value, 2);
      }
    return result;
  }
}

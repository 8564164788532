<div class="d-flex white">
  <button mat-icon-button (click)="openSearchDialog()" matTooltip="Search">
    <mat-icon>search</mat-icon>
  </button>
  <button mat-icon-button (click)="printPage()" matTooltip="Print Page">
    <mat-icon>print</mat-icon>
  </button>
  <button
    mat-icon-button
    *ngIf="helpFile"
    (click)="openDocumentHelp()"
    matTooltip="Help"
  >
    <mat-icon>help_outline</mat-icon>
  </button>
  <button
    mat-icon-button
    (click)="copyLink()"
    matTooltip="Copy link to this document to the Clipboard"
  >
    <mat-icon>link</mat-icon>
  </button>
  <button
    mat-icon-button
    *ngIf="checkToolbar()"
    (click)="toggleToolbar()"
    matTooltip="Expand/Collapse Header"
  >
    <mat-icon
      [ngClass]="{ 'arrow-icon-0': !collapsed, 'arrow-icon': collapsed }"
      >expand_circle_down</mat-icon
    >
  </button>
</div>

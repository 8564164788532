import { Component, EventEmitter, Injector, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { BaseComponent } from 'src/app/common/base/base.component';
import { FormStatusEnum } from 'src/app/common/enumerations/enumerations';
import { Schedule } from 'src/app/components/schedules/models/schedule';
import { Checklist, TemplateType } from '../../checklists';
import { ChecklistsService } from '../../checklists.service';
import { ChecklistsUpdate } from '../../store/checklists/checklists.action';
import { Location } from 'src/app/components/catalogs/beamline-catalog/resource/resources';
import { DocumentType } from 'src/app/services/documents/documents';
import { MessagePlaceholder } from 'src/app/common/models/placeholder';
import { Review } from 'src/app/components/reviews/reviews.models';

@Component({
  selector: 'checklist-exec-side',
  templateUrl: './checklist-exec-side.component.html',
  styleUrls: ['./checklist-exec-side.component.scss']
})

export class ChecklistExecSideComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {

  @Input() documentTypeId!: number;
  @Input() filter?: string | null;

  @Output() rowSelected = new EventEmitter<Checklist | null>();
  @Output() loading = new EventEmitter<any>();

  currentChecklist?: Checklist | null;
  checklists$!: Observable<Checklist[]>;
  checklists!: Checklist[];
  checklistsSubs!: Subscription;

  reviews!: Review[];
  reviews$!: Observable<Review[]>;
  reviewsSubs!: Subscription;

  inactiveFormStatuses = [
    FormStatusEnum.Closed,
    FormStatusEnum.Canceled
  ];

  public selectedRowIndex = -1;

  disabled!: boolean;
  isStatusChange!: boolean;

  filteredChecklists?: Checklist[];

  documentType?: DocumentType;
  documentTypes!: DocumentType[];
  documentTypes$!: Observable<DocumentType[]>;
  documentTypesSubs!: Subscription;

  templateType!: TemplateType;

  showClosed!: boolean;
  loaded!: boolean;

  schedules!: Schedule[];
  schedules$!: Observable<Schedule[]>;
  schedulesSubs!: Subscription;

  public displayedColumns: string[] = ['serialNo', 'resourceName', 'typeName', 'procedureNo', 'statusName'];
  public dataSource = new MatTableDataSource<Checklist>();

  formStatus = FormStatusEnum;

  constructor(
    protected override injector: Injector,
    private route: ActivatedRoute,
    private _service: ChecklistsService,
  ) {
    super(injector);

  }
  ngOnInit(): void {

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes['filter'])
      this.loadReviews();
    this.loadDocumentTypes();
    this.loadChecklists();
  }


  override ngOnDestroy(): void {
    this.checklistsSubs?.unsubscribe();
    this.schedulesSubs?.unsubscribe();
    this.documentTypesSubs?.unsubscribe();
    super.ngOnDestroy();
  }

  setColumns() {
    const templateTypes = this.filteredChecklists?.map(x => x.checklistTemplate?.templateType as TemplateType);
    if (templateTypes?.some(x => x.resourceTypeID) || templateTypes?.some(x => x.scheduleTypeID)) {
      this.displayedColumns = ['serialNo', 'resourceName', 'typeName', 'procedureNo', 'statusName'];
    }
    else if (this.filteredChecklists?.some(c => c.locations != null)) {
      this.displayedColumns = ['serialNo', 'resourceName', 'typeName', 'statusName'];
    }
    else {
      this.displayedColumns = ['serialNo', 'typeName', 'statusName'];
    }
    if (this.filteredChecklists?.some(c => c.review)) {
      // Find the index of 'statusName'
      const statusIndex = this.displayedColumns.indexOf('statusName');
      if (statusIndex !== -1) {
        // Insert 'review' before 'statusName'
        this.displayedColumns.splice(statusIndex, 0, 'review');
      } else {
        // Fallback: if 'statusName' is not found, add 'review' at the end
        this.displayedColumns.push('review');
      }
    }
  }

  loadChecklists() {
    if (!this.disabled) {
      this.setLoading(true, 125);
      this.checklists$ = this.store.select(state => state.Checklists.data);
      this.checklistsSubs = this.checklists$.subscribe(async data => {
        if (data.length) {
          this.checklists = data;
          this.filteredChecklists = this.checklists.filter(x => x.checklistTemplate?.documentTypeID == this.documentTypeId && (!this.inactiveFormStatuses.includes(x.checklistStatus?.formStatusID ?? 0) || this.showClosed)).sort((a, b) => this.utils.sort(a.serialNo, b.serialNo, false));
          this.setReviews();
          this.setColumns();
          this.dataSource = new MatTableDataSource<Checklist>(this.filteredChecklists);

          const openDocument = await this.checkOpenDocument<Checklist>(this.documentTypeId, data);
          if (openDocument && this.currentChecklist?.id != openDocument.document.id) {
            this.setLoading(true, 136);
            this.showClosed = this.showClosed ? this.showClosed : openDocument.closed;
            this.getValues(openDocument.document);
          }
          else this.setLoading(false, 136);

          this.checkStatus();
          this.setLocations();
          this.applyFilter();
          this.setLoading(false, 125);
        }
      });
    }
  }

  loadReviews() {
    this.reviews$ = this.store.select(state => state.Reviews.data);
    this.reviewsSubs = this.reviews$.subscribe(data => {
      this.reviews = data;
      this.setReviews();
    });
  }

  setReviews() {
    this.filteredChecklists?.map(c => {
      c.review = this.reviews?.find(r => r.checklistID == c.id);
    });
    this.setColumns();
  }

  setLocations() {
    this.filteredChecklists?.filter(c => !c.resourceName).map(c => c.resourceName = (this.utils.JSONparse(c.locations) as Location[])?.map(l => this.formatLocation(l))?.join(', '));
    this.filteredChecklists?.map(c => {
      if (c.resourceName)
        c.resourceNameSanitized = this.sanitize(c.resourceName);
    });
  }

  formatLocation(l: Location) {
    if (!l.newLocation) return l.name;
    return '<span style="text-decoration: underline dotted 1px; opacity: 60%; font-style: italic;">' + l.name + '</span>';
  }

  loadDocumentTypes() {
    this.setLoading(true, 181);
    this.documentTypes$ = this.store.select(state => state.DocumentType.data);
    this.documentTypesSubs = this.documentTypes$.subscribe(data => {
      this.documentTypes = data;
      this.setLoading(false, 181);
      this.documentType = this.documentTypes.find(x => x.id == this.documentTypeId);
    });
  }

  checkStatus() {
    if (this.currentChecklist) {
      const currentStatus = this.currentChecklist?.checklistStatus;
      this.currentChecklist = this.checklists.find(x => x.id == this.currentChecklist?.id);
      if (currentStatus && !this.currentChecklist) {
        return;
      }
      if (this.currentChecklist?.checklistStatus?.id != currentStatus?.id && !this.loaded) {
        this.alert.message('formStateChanged', [new MessagePlaceholder('{serialNo}', this.currentChecklist?.serialNo), new MessagePlaceholder('{status}', this.currentChecklist?.checklistStatus?.description)]);
      }
      if (!this.showClosed && (this.currentChecklist?.checklistStatus?.formStatusID == FormStatusEnum.Closed || this.currentChecklist?.checklistStatus?.formStatusID == FormStatusEnum.Canceled)) {
        this.showClosed = true;
      }
    }
  }

  getPosition(row: any): number {
    const i = this.dataSource.data?.findIndex(r => {
      return r.id == row.id;
    });
    return i;
  }

  async getValues(row: Checklist) {
    if (!this.disabled) {
      row.wfTable = null;
      this.setLoading(true, 215);
      this.rowSelected.emit(row);
      this.currentChecklist = row;
      this.disabled = true;
      this.selectedRowIndex = row.id;

      if (row?.id) {
        this.setOpenDocument(this.documentTypeId, row.id);
        this._service.getById(row.id).toPromise().then(data => {
          if (data) {
            this.currentChecklist = data;
            this.setOpenDocument(data.documentTypeID ?? 0, data.id);
            if (row.verificationList) {
              this.currentChecklist.verificationList = row.verificationList;
              this.currentChecklist.verifiedOK = row.verifiedOK;
            }

            this.disabled = false;
            if (data.id != row?.id || !row.wfTable)
              this.store.dispatch(new ChecklistsUpdate(data.id, data));
            this.rowSelected.emit(this.currentChecklist);
            this.setLoading(false, 215);
          }
        });
      }
    }
  }

  selectCreatedRow(e: any) {
    if (e.status == -1) {
      this.showClosed = true;
    }
    this.currentChecklist = e;
    this.disabled = false;
    this.getValues(e);
  }

  selectCanceledRow(e: any) {
    this.showClosed = true;
    this.currentChecklist = e;
    this.disabled = false;
    this.getValues(e);
  }

  unselect() {
    this.selectedRowIndex = -1;
    this.currentChecklist = null;
    this.rowSelected.emit(null);
  }

  applyFilter(filterValue: string | null = null) {
    const filter = filterValue ? filterValue.toLowerCase() : filterValue == '' ? '' : this.filter?.toLowerCase();
    this.filter = filter;
    if (filter) {
      this.dataSource = new MatTableDataSource(this.filteredChecklists?.filter(c => c.serialNo?.toLowerCase().includes(filter) || c.resourceName?.toLowerCase().includes(filter) || c.typeName?.toLowerCase().includes(filter) || c.statusName?.toLowerCase().includes(filter) || c.checklistTemplate?.templateType?.name?.toLowerCase().includes(filter) || c.checklistTemplate?.procedure?.procedureNumber?.toLowerCase().includes(filter) || c.checklistStatus?.description?.toLowerCase().includes(filter)));
    }
    else this.dataSource = new MatTableDataSource(this.filteredChecklists);
  }

  changeClosed() {
    this.loadChecklists();
  }

  getClass(s: number) {
    const checklistExecStatus = this.documentTypes?.find(x => x.id == this.documentTypeId)?.checklistExecStatuses?.find(x => x.formStatusID == s);
    return checklistExecStatus?.cssClass;
  }

  setLoading(val: boolean, l: number) {
    this.loading.emit({ val, l });
  }
}

<div class="full-width">
  <div
    class="mb-1"
    cdkDropList
    (cdkDropListDropped)="drop($event)"
    id="{{ 'section-' + wfSectionLocalID }}"
    [cdkDropListConnectedTo]="sectionDropLists"
    [cdkDropListData]="steps"
  >
    <div *ngFor="let step of steps" class="mb-2">
      <div
        cdkDrag
        (cdkDragMoved)="service.onDragMoved($event)"
        class="row no-gutters"
        cdkDragLockAxis="y"
        [cdkDragDisabled]="disabled || (step.logID ?? 0) > 0"
        cdkDragAutoScroll="true"
      >
        <div style="display: flex; width: 100%">
          <div class="drag-handler-container">
            <button
              mat-icon-button
              cdkDragHandle
              [class.no-display]="disabled || (step.logID ?? 0) > 0"
              color="accent"
              style="cursor: move"
            >
              <mat-icon>reorder</mat-icon>
            </button>
          </div>
          <div
            class="d-flex flex-column"
            *ngIf="step.logID && !isDeletedSection"
          >
            <button
              mat-icon-button
              matTooltip="Restore"
              class="restore-button"
              (click)="restore(step)"
            >
              <mat-icon color="primary">restore_from_trash</mat-icon>
            </button>

            <button
              mat-icon-button
              color="warn"
              [matTooltip]="'Delete forever'"
              (click)="deleteForever(step)"
            >
              <mat-icon>delete_forever</mat-icon>
            </button>
          </div>
          <div style="width: 100%">
            <checklist-builder-step
              [step]="step"
              class="full-width"
              (deleted)="deleted($event)"
              [disabled]="disabled"
              [showDeleted]="showDeleted"
              [isDeletedSection]="isDeletedSection"
              [currentVersion]="currentVersion"
              [procedure]="procedure"
              [stepDropLists]="stepDropLists"
              (loading)="setLoading($event)"
              (refreshMap)="refreshMap.emit()"
              (refreshPreviousStep)="refreshPreviousStep.emit($event)"
              id="{{ 'step-' + step?.id }}"
            ></checklist-builder-step>
          </div>
        </div>
      </div>
    </div>
  </div>
  <button
    mat-stroked-button
    class="ml-5"
    color="accent"
    *ngIf="steps && !disabled"
    (click)="add()"
    [matTooltip]="'Add New Step'"
  >
    <mat-icon>add_box</mat-icon> Add Step
  </button>
</div>

<div class="cell-container w-100 m-1" *ngIf="notes?.length">
  <button
    mat-button
    [ngClass]="{
      'note-button': !type || type == 1,
      'note-button-red': type == 2
    }"
    [matMenuTriggerFor]="noteList"
  >
    {{ notes?.length }}
  </button>
  <mat-menu #noteList="matMenu" class="notes">
    <div style="max-height: 50vh" class="scrollbar">
      <div *ngFor="let note of notes">
        <div class="note">
          <div
            class="normal-h d-flex justify-content-between"
            [ngClass]="{
              'normal-h': note.type == 1 || !note.type,
              'extension-h': note.type == 2,
              'rejection-h': note.type == 3
            }"
          >
            <span class="bold"> {{ note.user?.name }}</span>
            <span class="small date ml-2">{{
              note.date | date : "shortDate"
            }}</span>
            <mat-icon
              *ngIf="canDelete && currentUser?.id == note.user?.id"
              mat-list-icon
              class="red pointer ml-2"
              (click)="delete.emit(note)"
              >delete</mat-icon
            >
          </div>
          <div
            class="normal-h d-flex justify-content-between small nowrap"
            [ngClass]="{
              'normal-h': note.type == 1 || !note.type,
              'extension-h': note.type == 2,
              'rejection-h': note.type == 3
            }"
          >
            {{ note.noteName }}
          </div>
          <div
            class="normal-b"
            [ngClass]="{
              'normal-b': note.type == 1 || !note.type,
              'extension-b': note.type == 2,
              'rejection-b': note.type == 3
            }"
          >
            {{ note.description }}
          </div>
        </div>
      </div>
    </div>
  </mat-menu>
</div>

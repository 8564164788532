import { Component, Input, Output, EventEmitter, OnChanges, Injector } from '@angular/core';
import { moveItemInArray, CdkDragDrop } from '@angular/cdk/drag-drop';
import { AbsiBuilderService } from '../../absi-builder.service';
import { MatDialogConfig } from '@angular/material/dialog';
import { FormType } from 'src/app/common/enumerations/enumerations';
import { ChecklistBuilderService } from 'src/app/components/checklists/checklist-builder/checklist-builder.service';
import { WFSectionConfig } from 'src/app/components/workflow/workflow';
import { YesNoDialogComponent } from 'src/app/controls/yes-no-dialog/yes-no-dialog.component';
import { WfSignatureResource, WfSignature, WFTaskSignatureResource, WfTaskLocalResource } from 'src/app/services/work-flow/work-flow';
import { BaseComponent } from 'src/app/common/base/base.component';
import { ClEditorComponent } from 'src/app/controls/checklist-components/cl-editor/cl-editor.component';

@Component({
  selector: 'absi-builder-section',
  templateUrl: './absi-builder-section.component.html',
  styleUrls: ['./absi-builder-section.component.scss']
})
export class AbsiBuilderSectionComponent extends BaseComponent implements OnChanges {

  @Input() section!: WFSectionConfig;
  @Input() wfTableId!: number;
  @Input() disabled!: boolean;
  @Input() absiTemplateID!: number;
  @Input() serialNo!: string;

  @Output() requestRefresh = new EventEmitter();
  @Output() loading = new EventEmitter<boolean>();

  public chbTypes: Array<number> = [4, 5];

  public activeVersion!: boolean;

  sectionName!: string | null;

  adding!: boolean;
  editing!: boolean;
  editingId!: number;
  formType = FormType[FormType.ABSI];

  wfStepLocalId!: number;

  wfSignatures!: WfSignatureResource[];
  signatures!: WfSignature[];
  wfTaskSignature: WFTaskSignatureResource = new WFTaskSignatureResource();

  approvals!: number;

  controlOptions: any[] = [
    { label: 'Checkbox', value: 4 },
    { label: 'Note', value: 7 },
    { label: 'Checkbox & Text', value: 8 },
    { label: 'Schedule & Checkbox', value: 9 }
  ];

  constructor(
    protected override injector: Injector,
    private _builder: AbsiBuilderService,
    public _gBuilder: ChecklistBuilderService,
  ) {
    super(injector);
  }

  ngOnChanges() {
    this.refresh();
  }

  create() {
    this.wfSignatures = [];
    this.signatures = [];
    this.sectionName = null;
  }

  refresh() {

    if (this.wfTableId == undefined || this.wfTableId == 0) {
      this.create();
      return;
    }
    this.loading.emit(true);

    this._builder.ReadWFBySection(this.wfTableId, this.section.number).subscribe(data => {
      this.wfSignatures = data;

      this.wfStepLocalId = data[0] && data[0].wfTaskLocal && data[0].wfTaskLocal.wfTasks[0] ? data[0].wfTaskLocal.wfTasks[0].wfStep.wfStepLocalID : 0;

      this.sectionName = this.wfSignatures[0] ? this.wfSignatures[0].sectionName : null;

      let index = 1;
      const isOrdered = this.checkOrders();

      this.signatures = [];
      this.approvals = this.wfSignatures.length;
      this.wfSignatures.map(t => {
        const options: any[] = t.options ? JSON.parse(t.options) : [];
        const number = this.section.number + '.' + index;
        if (!t.wfTaskLocal.componentID) t.wfTaskLocal.componentID = t.type;
        const errors = this.getErrors(t.wfTaskLocal);
        const signature: WfSignature = {
          id: t.id,
          number: t.required ? number : null,
          approveId: t.approveID,
          unapproveId: t.unapproveID,
          disapproveId: t.disapproveID,
          amendmentId: t.amendmentID,
          name: t.titleBefore,
          question: t.question,
          question2: t.titleAfter,
          question3: t.titleAfter2,
          roles: t.roles.map(r => r.id),
          roleCodes: t.roles.map(r => r.code).join('/'),
          disabled: true,
          approvedBy: t.signedBy,
          approved: t.signed,
          approvedOn: t.signedOn,
          approve: t.approveID != 0,
          unapprove: t.unapproveID != 0,
          disapprove: t.disapproveID != 0,
          amendment: t.amendmentEnabled,
          required: t.required,
          taskId: t.taskID,
          value: t.value,
          value2: t.numericValue,
          type: t.type,
          options,
          sectionName: t.sectionName,
          iterable: t.iterable && (t.wfTaskLocal.name3?.includes('{KEStep}') || t.wfTaskLocal.name2?.includes('{vacuum}')),
          order: t.order,
          textValue: t.text,
          visible: t.visible,
          scheduleResourceId: t.scheduleResourceId,
          dateValue1: t.dateValue1,
          dateValue2: t.dateValue2,
          dateValue3: t.dateValue3 != null && t.dateValue3.toString() != '0001-01-01T00:00:00' ? t.dateValue3 : undefined,
          scheduleName: t.scheduleName,
          locationName: t.locationName,
          editMode: false,
          component: t.component,
          configuration: t.configuration,
          condition: t.condition,
          code: t.code,
          invalid: errors.value,
          errors: errors.errors
        };
        index++;
        this.signatures.push(signature);
      });
      this.renumberArray();
      if (!isOrdered && !this.disabled) {
        this.changeOrder();
      }

      this.loading.emit(false);

    });
  }

  checkOrders() {
    const order = this.wfSignatures.map(x => x.order);

    let i = 0;
    let result = true;

    order.map(o => {
      if (o != i + 1) {
        result = false;
      }
      i++;
    });

    return result;
  }

  edit(id?: number) {
    const order = (this.signatures[this.signatures.length - 1]?.order ?? 0) + 1;
    let signature: WfSignature | undefined = {
      name: '[New Name]',
      options: [],
      roles: [],
      required: true,
      approve: true,
      unapprove: true,
      order,
      stepId: this.wfSignatures[this.wfSignatures.length - 1].wfTaskLocal.wfStepLocalID
    };

    if (id)
      signature = this.signatures.find((x) => x.id === id);

    if (signature) {
      signature.stepId = this.wfStepLocalId;
      const dialogConfig = new MatDialogConfig();
      dialogConfig.data = {
        s: signature,
        formType: FormType[FormType.ABSI],
        serialNo: this.serialNo,
        roles: this.wfSignatures.find(x => x.id === signature?.id)?.roles,
        disabled: this.disabled
      };
      dialogConfig.width = '80vw';
      dialogConfig.autoFocus = false;
      const dialogRef = this.dialog.open(ClEditorComponent, dialogConfig);
      dialogRef.afterClosed().subscribe((data) => {
        if (data) {
          if (id) {
            this._gBuilder.updateWFTaskLocal(data).subscribe((data: WfTaskLocalResource) => {
              this.refresh();
              this.requestRefresh.emit();
            });
          }
          else {
            this._gBuilder.createWFTaskLocal(data).subscribe((data: WfTaskLocalResource) => {
              this.refresh();
              this.requestRefresh.emit();
            });
          }
        }
        else this.refresh();
      });
    }
  }

  delete(task: any) {
    const confirm = this.dialog.open(YesNoDialogComponent, {
      width: '400px',
      data: {
        message: this.getMessage('GenChklistBldr_DeleteTaskConfimation').description,
        icon: 'stop'
      }
    });

    confirm.afterClosed().subscribe(data => {
      if (data) {
        this._gBuilder.deleteTask(task).subscribe(data => {
          this.refresh();
        });
      }
    });
  }

  changeRequired(e: any, taskId: number) {
    const required = e.checked;
    this.renumberArray();
    this._builder.SaveRequired(taskId, required).subscribe(data => {
      this._builder.UpdateUpdater(this.absiTemplateID).subscribe(() => {
        this.requestRefresh.emit();
      });
    });
  }

  changeOrder() {
    this.signatures.map(s => {
      if (s.taskId && s.order) {
        this._builder.SaveOrder(s.taskId, s.order).subscribe(data => {
          this._builder.UpdateUpdater(this.absiTemplateID).subscribe(() => {
            this.requestRefresh.emit();
          });
        });
      }
    });
  }

  renumberArray() {
    let index = 1;
    let order = 1;
    this.signatures.map(s => {
      s.order = order;
      order++;

      if (s.required && s.component?.numericHeader) {
        s.number = this.section.number + '.' + index;
        index++;
      }
      else {
        s.number = null;
      }
    });
  }

  reorderTable(event: CdkDragDrop<WfSignature[]>) {
    if (event?.previousIndex !== event?.currentIndex) {
      moveItemInArray(this.signatures, event.previousIndex, event.currentIndex);
      this.renumberArray();
      this.changeOrder();
    }
  }

  getErrors(task: WfTaskLocalResource) {
    return this._gBuilder.hasErrors(task);
  }

}

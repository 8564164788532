<div class="card">
  <div class="card-header">
    <h5>Edit Action Item</h5>
  </div>
  <div class="card-body card-body-scroll">
    <form>
      <div class="row">
        <div class="col-12 col-xl-7 pr-2">
          <comments-box
            [textValue]="textValue"
            (textChanged)="textChanged($event)"
            [formType]="formType"
            [serialNo]="serialNo"
            [title]="'Action Item Description'"
          ></comments-box>
        </div>
        <div class="col-12 col-xl-5 pt-1">
          <div class="flex-display flex-column">
            <div>
              <mat-form-field class="whiteb">
                <mat-label>Owner</mat-label>
                <input
                  type="text"
                  #ownerInput
                  placeholder="Owner"
                  aria-label="Owner"
                  matInput
                  [formControl]="ownerCtrl"
                  [matAutocomplete]="autoOwner"
                  (keyup)="onOwnerChange()"
                  (change)="ownerChanged()"
                />
                <mat-autocomplete
                  #autoOwner="matAutocomplete"
                  [displayWith]="displayOwner"
                  #ownerAutocomplete
                  (optionSelected)="ownerSelected($event)"
                >
                  <mat-option *ngFor="let user of filteredUsers" [value]="user">
                    {{ user.name }}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
              <mat-form-field class="whiteb">
                <mat-label>Location</mat-label>
                <input
                  type="text"
                  placeholder="Location"
                  aria-label="Location"
                  matInput
                  [formControl]="locationCtrl"
                  (change)="locationChanged()"
                />
              </mat-form-field>
              <div class="d-flex align-items-center">
                <mat-label class="top-5">Due period: </mat-label>
                <mat-radio-group
                  class="radio-group"
                  aria-label="Select an option"
                  [formControl]="radioCtrl"
                >
                  <mat-radio-button
                    *ngFor="let r of radioOptions"
                    [value]="r.value"
                    (change)="radioChanged($event)"
                  >
                    {{ r.label }}
                  </mat-radio-button>
                </mat-radio-group>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="card-footer d-flex justify-content-end" matDialogActions>
    <div class="message" *ngIf="loading">
      Uploading images ...
      <span class="spinner-border spinner-border-sm mr-1"></span>
    </div>
    <div>
      <button
        mat-flat-button
        color="primary"
        class="ok mr-1"
        (click)="save()"
        [disabled]="loading"
        [ngClass]="{ disabled: loading }"
      >
        <mat-icon>save</mat-icon>&nbsp; Save
      </button>
      <button
        mat-stroked-button
        color="warn"
        (click)="cancel()"
        [disabled]="loading"
      >
        <mat-icon>cancel</mat-icon>&nbsp;Cancel
      </button>
    </div>
  </div>
</div>

<div
  class="container-fluid mt-2 pb-2"
  [ngClass]="{ invisible: !dataSourcePendingAccelerator.data.length }"
>
  <pending-approvals-changes-table
    id="accCatalog"
    [dataSource]="dataSourcePendingAccelerator"
    [pendingCatalog]="pendingCatalogType.Accelerator"
  ></pending-approvals-changes-table>
</div>

<div
  class="container-fluid mt-2 pb-2"
  [ngClass]="{ invisible: !dataSourcePendingResource.data.length }"
>
  <pending-approvals-changes-table
    id="beamCatalog"
    [dataSource]="dataSourcePendingResource"
    [pendingCatalog]="pendingCatalogType.Beamline"
  ></pending-approvals-changes-table>
</div>

<div
  class="container-fluid mt-2 pb-2"
  [ngClass]="{ invisible: !dataSourcePendingUser.data.length }"
>
  <pending-approvals-changes-table
    id="users"
    [dataSource]="dataSourcePendingUser"
    [pendingCatalog]="pendingCatalogType.User"
  ></pending-approvals-changes-table>
</div>

<div
  class="container-fluid mt-2 pb-2"
  [ngClass]="{ invisible: !dataSourcePendingRole.data.length }"
>
  <pending-approvals-changes-table
    id="roles"
    [dataSource]="dataSourcePendingRole"
    [pendingCatalog]="pendingCatalogType.Role"
  ></pending-approvals-changes-table>
</div>

<div
  class="container-fluid mt-2 pb-2"
  [ngClass]="{
    invisible: !dataSourcePendingNotificationTemplate.data.length
  }"
>
  <pending-approvals-changes-table
    id="notificationTemplates"
    [dataSource]="dataSourcePendingNotificationTemplate"
    [pendingCatalog]="pendingCatalogType.NotificationTemplate"
  ></pending-approvals-changes-table>
</div>

<div
  class="container-fluid mt-2 pb-2"
  [ngClass]="{ invisible: !dataSourcePendingRSSDatabase.data.length }"
>
  <pending-approvals-changes-table
    id="rssdb"
    [dataSource]="dataSourcePendingRSSDatabase"
    [pendingCatalog]="pendingCatalogType.RSSDb"
  ></pending-approvals-changes-table>
</div>
<!--
<div [ngClass]="{ invisible: !dataSourcePendingResource.data.length }">
  <div class="container-fluid mt-2">
    <h6 id="beamCatalog">Beamline Catalog</h6>
    <div class="mat-container scrollbar">
      <mat-table
        mat-table
        [dataSource]="dataSourcePendingResource"
        matSort
        #sortResource="matSort"
      >
        <ng-container matColumnDef="resourceName">
          <mat-header-cell *matHeaderCellDef mat-sort-header="resourceName">
            Name
          </mat-header-cell>
          <mat-cell class="font-weight-bold" *matCellDef="let element" nowrap>
            {{ element.resourceName }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="statusName">
          <mat-header-cell *matHeaderCellDef mat-sort-header="statusName">
            Status
          </mat-header-cell>
          <mat-cell class="pl-1" *matCellDef="let element">
            {{ element.statusName }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="actionName">
          <mat-header-cell *matHeaderCellDef mat-sort-header="actionName">
            Action
          </mat-header-cell>
          <mat-cell class="pl-1" *matCellDef="let element">
            {{ element.actionName }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="createdOn">
          <mat-header-cell *matHeaderCellDef mat-sort-header="createdOn">
            Date
          </mat-header-cell>
          <mat-cell class="pl-1" *matCellDef="let element">
            {{ element.createdOn | date : "longDate" }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="disapprovalReason">
          <mat-header-cell
            *matHeaderCellDef
            mat-sort-header="disapprovalReason"
          >
            Disapproval Reason
          </mat-header-cell>
          <mat-cell class="pl-1" *matCellDef="let element">
            {{ element.disapprovalReason }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="remove">
          <mat-header-cell *matHeaderCellDef></mat-header-cell>
          <mat-cell class="pl-1" *matCellDef="let element">
            <button
              class="float-right"
              mat-icon-button
              *ngIf="element.status == 3"
              class="delete"
              matTooltip="Acknowledge"
              (click)="removeRow(element, catalog.BeamlineCatalog)"
            >
              <mat-icon class="blue">visibility_off</mat-icon>
            </button>
          </mat-cell>
        </ng-container>
        <mat-header-row
          *matHeaderRowDef="displayedColumnsResource; sticky: true"
        ></mat-header-row>
        <mat-row
          *matRowDef="let row; columns: displayedColumnsResource"
          (click)="setItemResource(row)"
          class="table-row"
        ></mat-row>
      </mat-table>
    </div>
  </div>
</div>

<div
  class="container-fluid mt-2"
  [ngClass]="{ invisible: !dataSourcePendingUser.data.length }"
>
  <h6 id="users">Users</h6>
  <div class="mat-container scrollbar">
    <mat-table
      mat-table
      [dataSource]="dataSourcePendingUser"
      matSort
      #sortUser="matSort"
    >
      <ng-container matColumnDef="userName">
        <mat-header-cell *matHeaderCellDef mat-sort-header="userName">
          Name
        </mat-header-cell>
        <mat-cell class="font-weight-bold" *matCellDef="let element" nowrap>
          {{ element.userName }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="statusName">
        <mat-header-cell *matHeaderCellDef mat-sort-header="statusName">
          Status
        </mat-header-cell>
        <mat-cell class="pl-1" *matCellDef="let element">
          {{ element.statusName }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="actionName">
        <mat-header-cell *matHeaderCellDef mat-sort-header="actionName">
          Action
        </mat-header-cell>
        <mat-cell class="pl-1" *matCellDef="let element">
          {{ element.actionName }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="createdOn">
        <mat-header-cell *matHeaderCellDef mat-sort-header="createdOn">
          Date
        </mat-header-cell>
        <mat-cell class="pl-1" *matCellDef="let element">
          {{ element.createdOn | date : "longDate" }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="disapprovalReason">
        <mat-header-cell *matHeaderCellDef mat-sort-header="disapprovalReason">
          Disapproval Reason
        </mat-header-cell>
        <mat-cell class="pl-1" *matCellDef="let element">
          {{ element.disapprovalReason }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="remove">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell class="pl-1" *matCellDef="let element">
          <button
            class="float-right"
            mat-icon-button
            *ngIf="element.status == 3"
            class="delete"
            matTooltip="Acknowledge"
            (click)="removeRow(element, catalog.Users)"
          >
            <mat-icon class="blue">visibility_off</mat-icon>
          </button>
        </mat-cell>
      </ng-container>
      <mat-header-row
        *matHeaderRowDef="displayedColumnsUser; sticky: true"
      ></mat-header-row>
      <mat-row
        *matRowDef="let row; columns: displayedColumnsUser"
        (click)="setItemUser(row)"
        class="table-row"
      ></mat-row>
    </mat-table>
  </div>
</div>

<div class="container-fluid mt-2" *ngIf="dataSourcePendingRole.data.length">
  <h6 id="roles">Roles</h6>
  <div class="mat-container scrollbar">
    <mat-table
      mat-table
      [dataSource]="dataSourcePendingRole"
      matSort
      #sortRole="matSort"
    >
      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          Name
        </mat-header-cell>
        <mat-cell class="font-weight-bold" *matCellDef="let element" nowrap>
          {{ element.roleName }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="status">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          Status
        </mat-header-cell>
        <mat-cell class="pl-1" *matCellDef="let element">
          {{ element.statusName }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="changes">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          Changes
        </mat-header-cell>
        <mat-cell class="pl-1" *matCellDef="let element">
          {{ element.changes }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="action">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          Action
        </mat-header-cell>
        <mat-cell class="pl-1" *matCellDef="let element">
          {{ element.actionName }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="date">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          Date
        </mat-header-cell>
        <mat-cell class="pl-1" *matCellDef="let element">
          {{ element.createdOn | date : "longDate" }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="disapprovalReason">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          Disapproval Reason
        </mat-header-cell>
        <mat-cell class="pl-1" *matCellDef="let element">
          {{ element.disapprovalReason }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="remove">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell class="pl-1" *matCellDef="let element">
          <button
            class="float-right"
            mat-icon-button
            *ngIf="element.status == 3"
            class="delete"
            matTooltip="Acknowledge"
            (click)="removeRow(element, catalog.Roles)"
          >
            <mat-icon class="blue">visibility_off</mat-icon>
          </button>
        </mat-cell>
      </ng-container>
      <mat-header-row
        *matHeaderRowDef="rolesDisplayedColumns; sticky: true"
      ></mat-header-row>
      <mat-row
        *matRowDef="let row; columns: rolesDisplayedColumns"
        (click)="setItemRole(row)"
        class="table-row"
      ></mat-row>
    </mat-table>
  </div>
</div>

<div
  class="container-fluid mt-2"
  *ngIf="dataSourcePendingNotificationTemplate?.data?.length"
>
  <h6 id="notificationTemplates">Notification Templates</h6>
  <div class="mat-container scrollbar">
    <mat-table
      mat-table
      [dataSource]="dataSourcePendingNotificationTemplate"
      matSort
      #sortNotification="matSort"
    >
      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          Name
        </mat-header-cell>
        <mat-cell class="font-weight-bold" *matCellDef="let element" nowrap>
          {{ element.notificationTemplateName }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="status">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          Status
        </mat-header-cell>
        <mat-cell class="pl-1" *matCellDef="let element">
          {{ element.statusName }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="action">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          Action
        </mat-header-cell>
        <mat-cell class="pl-1" *matCellDef="let element">
          {{ element.actionName }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="date">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          Date
        </mat-header-cell>
        <mat-cell class="pl-1" *matCellDef="let element">
          {{ element.createdOn | date : "longDate" }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="disapprovalReason">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          Disapproval Reason
        </mat-header-cell>
        <mat-cell class="pl-1" *matCellDef="let element">
          {{ element.disapprovalReason }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="remove">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell class="pl-1" *matCellDef="let element">
          <button
            class="float-right"
            mat-icon-button
            *ngIf="element.status == 3"
            class="delete"
            matTooltip="Acknowledge"
            (click)="removeRow(element, catalog.EmailNotificationCatalog)"
          >
            <mat-icon class="blue">visibility_off</mat-icon>
          </button>
        </mat-cell>
      </ng-container>
      <mat-header-row
        *matHeaderRowDef="displayedColumns; sticky: true"
      ></mat-header-row>
      <mat-row
        *matRowDef="let row; columns: displayedColumns"
        (click)="setItemNotificationTemplate(row)"
        class="table-row"
      ></mat-row>
    </mat-table>
  </div>
</div>

<div
  class="container-fluid mt-2"
  *ngIf="dataSourcePendingRSSDatabase?.data?.length"
>
  <h6 id="rssdb">RSS Database</h6>
  <div class="mat-container scrollbar">
    <mat-table
      mat-table
      [dataSource]="dataSourcePendingRSSDatabase"
      matSort
      #sortRSS="matSort"
    >
      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          Name
        </mat-header-cell>
        <mat-cell class="font-weight-bold" *matCellDef="let element" nowrap>
          {{ element.resourceName }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="status">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          Status
        </mat-header-cell>
        <mat-cell class="pl-1" *matCellDef="let element">
          {{ element.statusName }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="action">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          Action
        </mat-header-cell>
        <mat-cell class="pl-1" *matCellDef="let element">
          {{ element.actionName }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="date">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          Date
        </mat-header-cell>
        <mat-cell class="pl-1" *matCellDef="let element">
          {{ element.createdOn | date : "longDate" }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="disapprovalReason">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          Disapproval Reason
        </mat-header-cell>
        <mat-cell class="pl-1" *matCellDef="let element">
          {{ element.disapprovalReason }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="remove">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell class="pl-1" *matCellDef="let element">
          <button
            class="float-right"
            mat-icon-button
            *ngIf="element.status == 3"
            class="delete"
            matTooltip="Acknowledge"
            (click)="removeRow(element, catalog.BeamlineCatalog)"
          >
            <mat-icon class="blue">visibility_off</mat-icon>
          </button>
        </mat-cell>
      </ng-container>
      <mat-header-row
        *matHeaderRowDef="displayedColumns; sticky: true"
      ></mat-header-row>
      <mat-row
        *matRowDef="let row; columns: displayedColumns"
        (click)="setItemRssDb(row)"
        class="table-row"
      ></mat-row>
    </mat-table>
  </div>
</div> -->

<mat-form-field>
  <mat-label>{{ placeholder }}</mat-label>
  <mat-chip-grid #rssFunctionalTestChips>
    <mat-chip-row
      *ngFor="let rssFunctionalTest of rssFunctionalTests"
      [selectable]="true"
      [removable]="true"
      (removed)="removeRSSFunctionalTest(rssFunctionalTest)"
    >
      <span
        class="rss-functional-test-name"
        (click)="openViewMode(rssFunctionalTest)"
        >{{
          rssFunctionalTest.name +
            " - " +
            (rssFunctionalTest.createdOn | date : "medium")
        }}</span
      >
      <mat-icon
        color="accent"
        *ngIf="!disabled && editPrivilege"
        class="pl-1 edit-icon"
        (click)="editRSSFunctionalTest(rssFunctionalTest)"
        >create</mat-icon
      >
      <mat-icon color="warn" matChipRemove *ngIf="!disabled && deletePrivilege"
        >cancel</mat-icon
      >
    </mat-chip-row>
    <input
      [placeholder]="placeholder"
      [matChipInputFor]="rssFunctionalTestChips"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      [formControl]="control"
    />
  </mat-chip-grid>
  <mat-error *ngIf="control.invalid">{{ getErrorMsg(control) }} </mat-error>
</mat-form-field>

<div class="row">
  <div class="col d-flex justify-content-end">
    <span class="small ml-auto mb-2"
      ><mat-slide-toggle
        color="warn"
        (change)="loadForms()"
        [(ngModel)]="showClosed"
        >Show Closed/Canceled</mat-slide-toggle
      ></span
    >
  </div>
</div>
<div class="left-table-container table-container mat-container scrollbar">
  <mat-table [dataSource]="dataSource">
    <ng-container matColumnDef="sequenceNumber">
      <mat-header-cell *matHeaderCellDef> No. </mat-header-cell>
      <mat-cell
        class="font-weight-bold"
        *matCellDef="let e"
        [ngClass]="e.formVersion.status.color"
        nowrap
        ><span
          [innerHTML]="e.sequenceNumber.number | highlightSearch : filter"
        ></span>
        <mat-icon color="accent" *ngIf="e.editingById" class="editing"
          >edit</mat-icon
        >
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="systemAffected">
      <mat-header-cell *matHeaderCellDef> System Affected </mat-header-cell>
      <mat-cell
        class="pl-1"
        *matCellDef="let e"
        [ngClass]="e.formVersion.status.color"
        ><span
          [innerHTML]="e.formVersion.systemAffected | highlightSearch : filter"
        ></span
      ></mat-cell>
    </ng-container>
    <ng-container matColumnDef="status">
      <mat-header-cell *matHeaderCellDef> Status </mat-header-cell>
      <mat-cell
        class="pl-1"
        *matCellDef="let e"
        [ngClass]="e.formVersion.status.color"
        ><span
          [innerHTML]="e.formVersion.status.name | highlightSearch : filter"
        ></span
      ></mat-cell>
    </ng-container>

    <mat-header-row
      *matHeaderRowDef="displayedColumns; sticky: true"
    ></mat-header-row>
    <mat-row
      *matRowDef="let row; columns: displayedColumns"
      (click)="getValues(row)"
      class="table-row"
      [matTooltip]="row.description"
      [matTooltipPosition]="'right'"
      matTooltipClass="tooltip-white tooltip-wide"
      [ngClass]="{ 'mat-row-highlight': this.form?.id == row.id }"
    ></mat-row>
  </mat-table>
</div>

import { Component, Injector, Input, OnChanges, SimpleChanges, EventEmitter, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BaseComponent } from 'src/app/common/base/base.component';
import { Form, FormHandler } from 'src/app/services/forms/form';
import { FormVersion, FormVersionHandler } from '../../../../services/forms/form-version';
import { EbService } from '../../eb.service';
import { FormRestriction } from 'src/app/services/forms/form-restriction';
import { EB2Shutter } from '../../eb-shutter';
import { EB2AcceleratorLocation } from '../../eb-accelerator-location';
import { Resource } from 'src/app/components/catalogs/beamline-catalog/resource/resources';
import { FormService } from 'src/app/controls/form-table/form.service';
import { MessagePlaceholder } from 'src/app/common/models/placeholder';
import { FormVersionService } from 'src/app/services/forms/form-version.service';
import { OtherSystem } from 'src/app/controls/other-system-chips/other-system';
import { CancelDialogComponent } from 'src/app/controls/cancel-dialog/cancel-dialog.component';
import { WfSignatureResource } from 'src/app/services/work-flow/work-flow';
import { FormsCreate } from 'src/app/controls/form-table/store/forms.action';
import * as moment from 'moment';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { utils } from 'src/app/modules/libs/utils';
import { PrivilegeEnum } from 'src/app/services/role-privilege/privilege-enum';
import { FormType } from 'src/app/common/enumerations/enumerations';
import { User } from 'src/app/components/catalogs/user-catalog/services/user';

@Component({
  selector: 'eb-main-s1',
  templateUrl: './eb-main-s1.component.html',
  styleUrls: ['./eb-main-s1.component.scss']
})
export class EbMainS1Component extends BaseComponent implements OnChanges {

  @Input() form?: Form | null;
  @Input() disabled: boolean = true;
  @Input() showAmendment?: boolean;

  @Output() loading = new EventEmitter<boolean>();
  @Output() formCreated = new EventEmitter<Form>();

  requesterCtrl = new FormControl();
  descriptionCtrl = new FormControl();
  shuttersCtrl = new FormControl();
  acceleratorCtrl = new FormControl();
  otherSystemCtrl = new FormControl();
  chkBeamlineCtrl = new FormControl();
  chkAcceleratorCtrl = new FormControl();
  startDateCtrl = new FormControl();
  completionDateCtrl = new FormControl();
  restrictionsCtrl = new FormControl();
  bypassCtrl = new FormControl();
  conditionsCtrl = new FormControl();
  systemCtrl = new FormControl();

  formID?: number;
  locationIDs?: (number | undefined)[];

  tmpChkBeamline!: boolean;
  tmpChkAccelerator!: boolean;
  tmpOtherSystems!: OtherSystem[];
  tmpStartDate!: Date;
  tmpCompletionDate!: Date;

  signature?: WfSignatureResource;
  amendmentSignature?: WfSignatureResource;
  canUnsubmit!: boolean;
  usersFiltered?: User[];

  formVersion?: FormVersion;
  cancelAvailable!: boolean;

  constructor(
    protected override injector: Injector,
    public service: EbService,
    private formService: FormService,
    private formVersionService: FormVersionService
  ) {
    super(injector);

  }
  // canDeactivate!: () => boolean | Observable<boolean> | Promise<boolean>;

  ngOnInit(): void {
    this.buildForm();
  }

  // override async canDeactivate(): Promise<boolean> {
  //   // Add additional logic specific to the child component
  //   return await this.canDeactivate(); // Call the parent method
  // }

  ngOnChanges(changes: SimpleChanges): void {
    this.refresh();
  }

  buildForm() {
    this.formGroup = this.formBuilder.group({
      // operatorCtrl: this.operatorCtrl,
      requesterCtrl: this.requesterCtrl,
      descriptionCtrl: this.descriptionCtrl,
      shuttersCtrl: this.shuttersCtrl,
      acceleratorCtrl: this.acceleratorCtrl,
      chkBeamlineCtrl: this.chkBeamlineCtrl,
      chkAcceleratorCtrl: this.chkAcceleratorCtrl,
      otherSystemCtrl: this.otherSystemCtrl,
      startDateCtrl: this.startDateCtrl,
      completionDateCtrl: this.completionDateCtrl,
      restrictionsCtrl: this.restrictionsCtrl,
      bypassCtrl: this.bypassCtrl,
      conditionsCtrl: this.conditionsCtrl,
      systemCtrl: this.systemCtrl
    });
    this.formGroup.markAsPristine();
    this.checkEnableControls();
  }

  checkEnableControls() {
    this.disabled = true;
    this.formGroup?.disable();

    if (this.form) {
      let enable: boolean | null = false;
      if (!this.showAmendment)
        enable = this.form?.editingById == this.currentUser?.id && this.hasPrivilege(this.privilegeEnum.EBNewForm) && ((this.form?.formVersion.statusID ?? 0) == this.formStatusEnum.New || this.form?.formVersion?.statusID == this.formStatusEnum.Draft);
      else
        enable = this.form?.editingById == this.currentUser?.id && this.hasPrivilege(this.privilegeEnum.EBNewForm) && (this.form?.formVersion?.statusID == this.formStatusEnum.Amendment && this.hasPrivilege(this.privilegeEnum.EBEditAmendment));

      if (enable) {
        this.disabled = false;
        this.formGroup?.enable();
      }

      this.cancelAvailable =
        (this.form?.formVersion?.statusID == this.formStatusEnum.WorkApprovals ||
          (this.form?.formVersion?.statusID == this.formStatusEnum.Pending && !this.form.wfTable?.wfTableLocal?.wfSectionLocals.find(s => s.number == 3)?.wfSignatures?.some(s => s.signed))) &&
        this.hasPrivilege(PrivilegeEnum.EBCancel);
    }
  }

  refresh() {
    if (this.form) {
      this.usersFiltered = this.getUsers();
      if (this.showAmendment) {
        this.formVersion = utils.cloneDeep(this.form.formVersion);
      }
      else {
        if (this.form.formVersion.previousVersion)
          this.formVersion = utils.cloneDeep(this.form.formVersion.previousVersion);
        else
          this.formVersion = utils.cloneDeep(this.form.formVersion);
      }
      this.requesterCtrl.setValue(this.formVersion.requester);
      this.chkBeamlineCtrl.setValue(this.formVersion.eB2.beamline);
      this.chkAcceleratorCtrl.setValue(this.formVersion.eB2.accelerator);
      this.tmpOtherSystems = this.formVersion.eB2.eB2OtherSystems.map(o => {
        const os: OtherSystem = { id: o.id, text: o.text };
        return os;
      });
      this.startDateCtrl.setValue(this.formVersion.eB2.permittedStartDate);
      this.completionDateCtrl.setValue(this.formVersion.eB2.estimatedCompletion);
      this.getLocationIDs();
      if (this.form.wfTable) {
        this.signature = this.form?.wfTable?.wfTableLocal?.wfSectionLocals.find(s => s.number == 1)?.wfSignatures?.[0];
        this.amendmentSignature = this.form?.wfTable?.wfTableLocal?.wfSectionLocals.find(s => s.number == 4)?.wfSignatures?.find(s => s.amendmentEnabled);
        this.canUnsubmit = !this.form?.wfTable?.wfTableLocal?.wfSectionLocals.find(s => s.number == 2)?.wfSignatures?.some(s => s.signed);
      }
    }
    else {
      this.clearFields();
    }
    this.formGroup?.disable();
    this.formGroup?.markAsPristine();
    this.checkEnableControls();
  }

  clearFields() {
    this.chkBeamlineCtrl.setValue(false);
    this.chkAcceleratorCtrl.setValue(false);
    this.tmpOtherSystems = [];
  }

  selectedReq(e: MatAutocompleteSelectedEvent) {
    const value = e.option.value;
    if (this.formVersion) {
      this.formVersion.requesterID = value.id;
      this.setFormDirty();
    }
  }

  onReqChange(e: any) {
    const value = (e.target.value)?.toLowerCase();
    this.usersFiltered = this.getUsers().filter(u => u.name?.toLowerCase().includes(value));
  }

  descriptionChanged(e: any) {
    if (this.formVersion) {
      this.formVersion.eB2.equipmentOrFunctionForBypassAndReason = e;
      this.descriptionCtrl.setValue(e);
      this.setFormDirty();
    }
  }

  relatedDocumentsChanged(e: any) {
    if (this.formVersion) {
      this.formVersion.eB2.relatedDocuments = e;
      this.setFormDirty();
    }
  }

  onShutterEdited(e: Resource[]) {
    e.map(r => {
      if (!this.formVersion?.eB2.eB2Shutters?.find(s => s.resourceID == r.id))
        this.formVersion?.eB2.eB2Shutters?.push({ id: 0, eB2ID: this.formVersion.eB2?.id, resource: r, resourceID: r.id });
    });
    this.formVersion?.eB2.eB2Shutters?.map((s, i) => {
      if (!e.find(r => r.id == s.resourceID))
        this.formVersion?.eB2.eB2Shutters?.splice(i, 1);
    });
    this.setFormDirty();
    this.getLocationIDs();
  }

  onAcceleratorEdited(e: Resource[]) {
    e.map(r => {
      if (!this.formVersion?.eB2.eB2AcceleratorLocations?.find(s => s.resourceID == r.id))
        this.formVersion?.eB2.eB2AcceleratorLocations?.push({ id: 0, eB2ID: this.formVersion?.eB2?.id, resource: r, resourceID: r.id });
    });
    this.formVersion?.eB2.eB2AcceleratorLocations?.map((s, i) => {
      if (!e.find(r => r.id == s.resourceID))
        this.formVersion?.eB2.eB2AcceleratorLocations?.splice(i, 1);
    });
    this.setFormDirty();
    this.getLocationIDs();
  }

  onOtherSystemsEdited(e: OtherSystem[]) {
    e.map(o => {
      if (!this.formVersion?.eB2.eB2OtherSystems?.find(x => x.text == o.text))
        this.formVersion?.eB2.eB2OtherSystems.push({ id: 0, text: o.text, eB2ID: this.formVersion?.eB2.id });
    });
    this.formVersion?.eB2.eB2OtherSystems.map((x, i) => {
      if (!e.find(o => o.text == x.text))
        this.formVersion?.eB2.eB2OtherSystems.splice(i, 1);
    });
    this.setFormDirty();
  }

  blCheckboxChanged(e: any) {
    const checked = e.checked;
    this.otherSystemCtrl.setErrors(null);

    if (this.formVersion) {
      this.setFormDirty();
      this.formVersion.eB2.beamline = checked;
      if (!checked)
        this.formVersion.eB2.eB2Shutters = [];
    }
    this.getLocationIDs();
  }

  accCheckboxChanged(e: any) {
    const checked = e.checked;
    this.otherSystemCtrl.setErrors(null);

    if (this.formVersion) {
      this.setFormDirty();
      this.formVersion.eB2.accelerator = checked;
      if (!checked)
        this.formVersion.eB2.eB2AcceleratorLocations = [];
    }
    this.getLocationIDs();
  }

  getLocationIDs() {
    this.locationIDs = this.formVersion?.eB2.eB2Shutters.map(s => s.resourceID).concat(this.formVersion.eB2.eB2AcceleratorLocations.map(l => l.resourceID ?? 0));
  }

  getResources(e: EB2Shutter[] | EB2AcceleratorLocation[] | undefined): Resource[] {
    return e?.map(x => x.resource) ?? [];
  }

  restrictionsChanged(e: FormRestriction[]) {
    if (this.formVersion?.eB2)
      this.formVersion.eB2.eB2Restrictions = e;
    this.setFormDirty();
  }

  bypassDetailsChanged(e: any) {
    if (this.formVersion?.eB2)
      this.formVersion.eB2.bypassDetails = e;
    this.bypassCtrl.setValue(e);
    this.setFormDirty();
  }

  conditionsAndlimitationsChanged(e: any) {
    if (this.formVersion?.eB2)
      this.formVersion.eB2.conditionsAndLimitations = e;
    this.conditionsCtrl.setValue(e);
    this.setFormDirty();
  }

  systemAndPrintChanged(e: any) {
    if (this.formVersion?.eB2)
      this.formVersion.eB2.systemAndPrintNumbers = e;
    this.systemCtrl.setValue(e);
    this.setFormDirty();
  }

  startDateChanged(e: any) {
    if (this.formVersion?.eB2)
      this.formVersion.eB2.permittedStartDate = e.value;
    this.setFormDirty();
  }

  completionDateChanged(e: any) {
    if (this.formVersion?.eB2)
      this.formVersion.eB2.estimatedCompletion = e.value;
    this.setFormDirty();
  }

  async save(keepLoading: boolean = false) {
    return new Promise<boolean>(async (resolve, reject) => {
      if (this.form && this.formVersion && !this.form?.id) {
        this.loading.emit(true);
        this.form.formVersion = this.formVersion;
        this.formService.create(FormHandler.toSave(this.form))?.subscribe((data: Form) => {
          this.formCreated.emit(data);
          this.setOpenDocument(FormType.EBF, data.id);
          this.setFormDirty(false);
          if (!keepLoading)
            this.alert.message('DraftSaved', [
              new MessagePlaceholder("{serialNo}", data.sequenceNumber.number)]);
          this.loading.emit(keepLoading);
          resolve(true);
        },
          (error) => {
            console.error(error);
            this.alert.defaultError();
            this.loading.emit(false);
            reject(false);
          }
        );
      } else if (this.formVersion) {
        this.loading.emit(true);
        this.formVersionService.update(FormVersionHandler.toSave(this.formVersion)).subscribe(() => {
          this.setOpenDocument(FormType.EBF, this.form?.id);
          this.loading.emit(keepLoading);
          this.setFormDirty(false);
          if (!keepLoading)
            this.showAmendment ? this.alert.message('amendment saved', [
              new MessagePlaceholder("{serialNo}", this.form?.sequenceNumber.number)
            ]) : this.alert.message('DraftUpdated', [
              new MessagePlaceholder("{serialNo}", this.form?.sequenceNumber.number)
            ]);
          resolve(true);
        },
          (error) => {
            console.error(error);
            this.alert.defaultError();
            this.loading.emit(false);
            reject(false);
          }
        );
      }
    });
  }

  async submit() {
    if (this.validateSubmit() && this.form) {
      this.loading.emit(true);
      this.setOpenDocument(FormType.EBF, this.form?.id);
      if (await this.save(true)) {
        await this.formService.setEditing(this.form.id, false).toPromise();
        if (this.signature) {
          this.setFormDirty(false);
          this.service.sign(this.form, this.signature.taskID, this.signature.approveID).then(data => {
            this.loading.emit(false);
          });
        }
      }
    }
  }

  unsubmit() {
    if (this.signature) {
      this.loading.emit(true);
      this.service.sign(this.form, this.signature.taskID, this.signature.unapproveID).then(() => {
        this.loading.emit(false);
      });
    }
  }

  validateSubmit(): boolean {
    let result = true;
    if (!this.formVersion?.requesterID) {
      this.alert.message('EB_No_Requester_Was_Specified');
      ;
      this.requesterCtrl.setErrors({ required: true });
      result = false;
    }

    if (
      !this.formVersion?.eB2.equipmentOrFunctionForBypassAndReason ||
      this.formVersion.eB2.equipmentOrFunctionForBypassAndReason.trim() === ""
    ) {
      this.alert.message('EB_No_Equipment_Or_Function_For_Bypass_And_Reason_Was_Specified');
      ;
      this.descriptionCtrl.setErrors({ required: true });
      result = false;
    }

    if (this.formVersion?.eB2.beamline && !this.formVersion.eB2.eB2Shutters.length) {
      this.shuttersCtrl.setErrors({ required: true });
      this.alert.message('EB_Please_Check_On_At_Least_One_System_Affected');
      ;
      result = false;
    }

    if (this.formVersion?.eB2.accelerator && !this.formVersion.eB2.eB2AcceleratorLocations) {
      this.acceleratorCtrl.setErrors({ required: true });
      this.alert.message('EB_Please_Check_On_At_Least_One_System_Affected');
      ;
      result = false;
    }

    if (!this.formVersion?.eB2.eB2OtherSystems.length &&
      !this.formVersion?.eB2.eB2Shutters.length &&
      !this.formVersion?.eB2.eB2AcceleratorLocations.length
    ) {
      this.alert.message('EB_Please_Check_On_At_Least_One_System_Affected');
      ;
      this.otherSystemCtrl.setErrors({ required: true });
      result = false;
    }

    if (!this.formVersion?.eB2.eB2Restrictions.length) {
      this.alert.message('EB_No_Restrictions_Were_Added');
      ;
      this.restrictionsCtrl.setErrors({ required: true });
      result = false;
    }

    if (!this.formVersion?.eB2.bypassDetails || this.formVersion.eB2.bypassDetails.trim() === "") {
      this.alert.message('EB_No_Bypass_Location_And_Details_Were_Specified');
      ;
      this.bypassCtrl.setErrors({ required: true });
      result = false;
    }

    if (!this.formVersion?.eB2.conditionsAndLimitations || this.formVersion.eB2.conditionsAndLimitations.trim() === "") {
      this.alert.message('EB_No_Conditions_And_Limitations_Were_Specified');
      ;
      this.conditionsCtrl.setErrors({ required: true });
      result = false;
    }

    if (!this.formVersion?.eB2.systemAndPrintNumbers || this.formVersion.eB2.systemAndPrintNumbers.trim() === "") {
      this.alert.message('EB_No_System_And_Print_Numbers_Were_Specified');
      ;
      this.systemCtrl.setErrors({ required: true });
      result = false;
    }

    if (!this.formVersion?.eB2.permittedStartDate) {
      this.alert.message('EB_No_Permitted_Start_Date_Was_Specified');
      ;
      this.startDateCtrl.setErrors({ required: true });
      result = false;
    }

    if (!this.formVersion?.eB2.estimatedCompletion) {
      this.alert.message('EB_No_Estimated_Completion_Date_Was_Specified');
      ;
      this.completionDateCtrl.setErrors({ required: true });
      result = false;
    }

    if (this.formVersion?.eB2.estimatedCompletion && this.formVersion.eB2.permittedStartDate) {
      if (moment(this.formVersion.eB2.estimatedCompletion).isBefore(this.formVersion.eB2.permittedStartDate)
      ) {
        this.alert.message('EB_The_Estimated_Completion_Date_Is_Less_Than_The_Permitted_Start_Date');
        ;
        this.completionDateCtrl.setErrors({ invalidDate: true })
        result = false;
      }
    }

    if (!result)
      this.formGroup?.markAllAsTouched();

    return result;
  }

  async submitAmendment() {
    if (this.validateSubmit() && this.form && this.formVersion) {
      this.loading.emit(true);
      this.setOpenDocument(FormType.EBF, this.form?.id);
      await this.formService.setEditing(this.form.id, false).toPromise();
      this.formVersionService.update(FormVersionHandler.toSave(this.formVersion)).subscribe(async (data: FormVersion) => {
        if (this.form && data) {
          this.formVersion = data;
          this.setFormDirty(false);
          const signature = this.form.wfTable?.wfTableLocal?.wfSectionLocals.find(s => s.number == 11)?.wfSignatures?.find(s => s.approveEnabled && !s.signed);
          if (signature)
            await this.service.sign(this.form, signature.taskID, signature.approveID).then(() => {
              this.loading.emit(false);
            });
        }
      },
        (error) => {
          console.error(error);
          this.alert.defaultError();
          this.loading.emit(false);
        }
      );
    }
  }

  async unsubmitAmendment() {
    const signature = this.form?.wfTable?.wfTableLocal?.wfSectionLocals.find(s => s.number == 11)?.wfSignatures?.find(s => s.unapproveEnabled);
    if (signature) {
      this.loading.emit(true);
      await this.service.sign(this.form, signature.taskID, signature.unapproveID).then(() => {
        this.loading.emit(false);
      });
    }
  }

  async cancelDraft() {
    if (this.form?.id) {
      const amendment = this.formVersion?.status.amendment;
      const confirm = this.dialog.open(CancelDialogComponent, {
        width: "600px",
        data: {
          amendment: this.formVersion?.status.amendment,
        },
      });

      confirm.afterClosed().subscribe((data) => {
        if (data?.accept) {
          if (this.form?.sequenceNumber.number) {
            this.formService.delete(this.form.sequenceNumber.number).subscribe(data => {
              this.alert.message('Form_SerialNo_deleted', [
                new MessagePlaceholder("{serialNo}", this.form?.sequenceNumber?.number),
              ]);
              this.form = null;
              this.formVersion = undefined;
              this.setFormDirty(false);
              this.formCreated.emit(undefined);
            });
          }
        }
      });
    }
    else if (await this.canDeactivate()) {
      this.formService.deleteSerialNumbers(FormType.EBF, this.currentUser?.id as number).toPromise();
      this.form = null;
      this.formVersion = undefined;
      this.setFormDirty(false);
      this.formCreated.emit(undefined);
    }
  }

  cancel() {

    const amendment = this.form?.formVersion.status.amendment;
    const confirm = this.dialog.open(CancelDialogComponent, {
      width: "600px",
      data: {
        amendment: this.form?.formVersion.status.amendment,
      },
    });

    const signature = amendment ? this.form?.wfTable?.wfTableLocal?.wfSectionLocals.find(s => s.number == 11)?.wfSignatures?.find(s => s.cancelEnabled) : this.signature;

    confirm.afterClosed().subscribe(async (data) => {
      if (data?.accept && signature) {
        await this.service.sign(this.form, signature.taskID, signature?.cancelID, data.text).then(() => {
          if (!amendment) {
            this.alert.message('Form_SerialNo_deleted', [new MessagePlaceholder("{serialNo}", this.form?.sequenceNumber?.number),]);
          }
        }).catch(() => { this.alert.defaultError(); });
      }
    });

  }


  diffRelatedDocuments(): boolean | undefined {
    if (!this.showAmendment) return;
    const current = utils.JSONstringify(
      this.formVersion?.eB2.relatedDocuments?.sort((a, b) => utils.sort(a.relatedDocumentId, b.relatedDocumentId, true)).map((l) => {
        return {
          id: l.relatedDocumentId
        };
      }))?.replace(/\s/g, "");
    const original = utils.JSONstringify(this.formVersion?.previousVersion?.eB2.relatedDocuments?.sort((a, b) => utils.sort(a.relatedDocumentId, b.relatedDocumentId, true)).map((l) => {
      return {
        id: l.relatedDocumentId
      };
    }))?.replace(/\s/g, "");
    return current != original;
  }

  diffRelatedLinks() {
    if (!this.showAmendment) return;
    const current = utils.JSONstringify(
      this.formVersion?.eB2.eB2RelatedLinks?.sort((a, b) => utils.sort(a.id, b.id, true)).map((l) => {
        return {
          id: l.id,
          text: l.text,
          url: l.url
        };
      }))?.replace(/\s/g, "");
    const original = utils.JSONstringify(this.formVersion?.previousVersion?.eB2.eB2RelatedLinks?.sort((a, b) => utils.sort(a.id, b.id, true)).map((l) => {
      return {
        id: l.id,
        text: l.text,
        url: l.url
      };
    }))?.replace(/\s/g, "");
    return current != original;
  }

  diffRestrictions() {
    if (!this.showAmendment) return;
    const current = utils.JSONstringify(
      this.formVersion?.eB2.eB2Restrictions?.sort((a, b) => utils.sort(a.restrictionID, b.restrictionID, true)).map((l) => {
        return {
          restrictionID: l.restrictionID,
          text: l.text,
        } as FormRestriction;
      }))?.replace(/\s/g, "");
    const original = utils.JSONstringify(this.formVersion?.previousVersion?.eB2.eB2Restrictions?.sort((a, b) => utils.sort(a.restrictionID, b.restrictionID, true)).map((l) => {
      return {
        restrictionID: l.restrictionID,
        text: l.text,
      } as FormRestriction;
    }))?.replace(/\s/g, "");
    this.formVersion?.eB2.eB2Restrictions.map(r => {
      r.hilite = this.formVersion?.previousVersion?.eB2.eB2Restrictions.find(p => p.restrictionID ? p.restrictionID == r.restrictionID : p.text == r.text) == null;
    });
    return current != original;
  }

  diffShutters() {
    if (!this.showAmendment) return;
    const current = utils.JSONstringify(
      this.formVersion?.eB2.eB2Shutters?.sort((a, b) => utils.sort(a.resourceID, b.resourceID, true)).map((l) => {
        return {
          id: l.resourceID
        };
      }))?.replace(/\s/g, "");
    const original = utils.JSONstringify(this.formVersion?.previousVersion?.eB2.eB2Shutters?.sort((a, b) => utils.sort(a.resourceID, b.resourceID, true)).map((l) => {
      return {
        id: l.resourceID
      };
    }))?.replace(/\s/g, "");
    return current != original;
  }

  diffAccelerators() {
    if (!this.showAmendment) return;
    const current = utils.JSONstringify(
      this.formVersion?.eB2.eB2AcceleratorLocations?.sort((a, b) => utils.sort(a.resourceID, b.resourceID, true)).map((l) => {
        return {
          id: l.resourceID
        };
      }))?.replace(/\s/g, "");
    const original = utils.JSONstringify(this.formVersion?.previousVersion?.eB2.eB2AcceleratorLocations?.sort((a, b) => utils.sort(a.resourceID, b.resourceID, true)).map((l) => {
      return {
        id: l.resourceID
      };
    }))?.replace(/\s/g, "");
    return current != original;
  }

  diffOtherSystems() {
    if (!this.showAmendment) return;
    const current = utils.JSONstringify(
      this.formVersion?.eB2.eB2OtherSystems?.sort((a, b) => utils.sort(a.text, b.text, true)).map((l) => {
        return {
          text: l.text,
        };
      }))?.replace(/\s/g, "");
    const original = utils.JSONstringify(this.formVersion?.previousVersion?.eB2.eB2OtherSystems?.sort((a, b) => utils.sort(a.text, b.text, true)).map((l) => {
      return {
        text: l.text,
      };
    }))?.replace(/\s/g, "");
    return current != original;
  }

}

import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
// import { PrivilegeCategory } from 'src/app/_interface/RolePrivilege';
import { Store } from '@ngxs/store';
// import { Privilege, RolePrivilege } from '../../../../../_interface/RolePrivilege';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
// import { Role } from '../../../../../Services/Roles/role';
import { filter } from 'rxjs/operators';
import { PrivilegeCategory, Privilege, RolePrivilege } from 'src/app/components/catalogs/roles/services/role-privilege';

@Component({
  selector: 'role-privilege-add',
  templateUrl: './role-privilege-add.component.html',
  styleUrls: ['./role-privilege-add.component.scss']
})
export class RolePrivilegeAddComponent implements OnInit, OnDestroy {

  privilegeCategory!: PrivilegeCategory;
  privilegeCategories!: PrivilegeCategory[];
  privilegeCategoriesFiltered!: PrivilegeCategory[];
  privilegeCategories$!: Observable<PrivilegeCategory[]>;
  privilegeCategoriesSubs!: Subscription;

  privilege!: Privilege;
  privileges!: Privilege[];
  privilegesFiltered!: Privilege[];

  rolePrivileges: RolePrivilege[];

  constructor(
    private store: Store,
    public dialogRef: MatDialogRef<RolePrivilegeAddComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.rolePrivileges = data.rolePrivileges;
  }

  ngOnDestroy(): void {
    this.privilegeCategoriesSubs?.unsubscribe();
  }

  ngOnInit(): void {
    this.loadPrivilegeCategories();
  }

  loadPrivilegeCategories() {
    this.privilegeCategories$ = this.store.select(state => state.PrivilegeCategories.data);
    this.privilegeCategoriesSubs = this.privilegeCategories$.subscribe(data => {
      this.privilegeCategories = data.filter(x => x.privileges?.filter(p => !this.rolePrivileges.map(r => r.privilege).includes(p.id ?? 0)).length);
      this.privilegeCategoriesFiltered = this.privilegeCategories;
    });
  }

  displayObject(e: any) {
    return e ? e.name : null;
  }

  changedCategory(e: any) {
    const text = e.target.value as string;
    this.privilegeCategoriesFiltered = this.privilegeCategories.filter(x => x.name?.toLowerCase().includes(text.toLowerCase()) || x.code?.toLowerCase().includes(text.toLowerCase()));
  }

  changedPrivilege(e: any) {
    const text = e.target.value as string;
    this.privilegesFiltered = this.privileges.filter(x => x.name?.toLowerCase().includes(text.toLowerCase()) || x.code?.toLowerCase().includes(text.toLowerCase()));
  }

  selectedType(e: any) {
    this.privileges = e.option.value.privileges.filter((x: any) => !this.rolePrivileges.map(r => r.privilege).includes(x.id));
    this.privilegesFiltered = this.privileges;
  }

  selectedPriv(e: any) {
    this.privilege = e.option.value;
  }

  cancel() {
    this.dialogRef.close();
  }

  accept() {
    this.dialogRef.close(this.privilege);
  }
}

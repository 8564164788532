import { AfterViewInit, Component, Injector, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { BaseComponent } from 'src/app/common/base/base.component';
import { CatalogService } from 'src/app/components/catalogs/catalog-service';
import { PendingCatalog, PendingCatalogType } from 'src/app/services/pending-approvals/pending-approval';

@Component({
  selector: 'pending-approvals-catalogs-table',
  templateUrl: './pending-approvals-catalogs-table.component.html',
  styleUrls: ['./pending-approvals-catalogs-table.component.scss']
})
export class PendingApprovalsCatalogsTableComponent extends BaseComponent implements OnChanges, AfterViewInit {

  @Input() dataSource!: MatTableDataSource<PendingCatalog>;
  @Input() pendingCatalog?: PendingCatalogType;

  public displayedColumns: string[] = ['name', 'statusName', 'actionName', 'createdOn', 'createdBy'];
  @ViewChild('sort', { static: true }) sort!: MatSort | null;

  route?: string;
  header?: string;

  constructor(
    protected override injector: Injector,
    private _catalogService: CatalogService,
    private router: Router,
  ) {
    super(injector);
  }

  ngAfterViewInit(): void {
    if (this.dataSource && this.sort)
      this.dataSource.sort = this.sort;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.ngAfterViewInit();
    this.setParams();
  }

  setItem(pendingCatalog: PendingCatalog) {
    this._catalogService.pendingCatalog = pendingCatalog;
    this.router.navigate([this.route]);
  }

  setParams() {
    switch (this.pendingCatalog) {
      case PendingCatalogType.Accelerator:
        this.route = '/accelerator-catalog';
        this.header = 'Accelerator Catalog';
        break;
      case PendingCatalogType.Beamline:
        this.route = '/resources-new';
        this.header = 'Beamline Catalog';
        break;
      case PendingCatalogType.User:
        this.route = '/users';
        this.header = 'User Catalog';
        break;
      case PendingCatalogType.Role:
        this.route = '/roles';
        this.header = 'Role Catalog';
        break;
      case PendingCatalogType.NotificationTemplate:
        this.route = '/email-notification-catalog';
        this.header = 'Notification Templates Catalog';
        break;
      case PendingCatalogType.RSSDb:
        this.route = '/resources-new?tab=2';
        this.header = 'RSS Database';
        break;
    }
  }
}

import { Component, OnInit, HostListener, AfterViewInit, Inject, Injector } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { ToxicGasEditorComponent } from './editor/toxic-gas-editor.component';
import { ToxicGasArchiveComponent } from './archive/toxic-gase-archive.component';
import { BaseComponent } from 'src/app/common/base/base.component';
import { ToxicGas } from './toxic-gas';
import { ToxicGasService } from './toxic-gas.service';
import { PrivilegeEnum } from 'src/app/services/role-privilege/privilege-enum';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-toxic-gas-panel',
  templateUrl: './toxic-gas-panel.component.html',
  styleUrls: ['./toxic-gas-panel.component.scss']
})
export class ToxicGasPanelComponent extends BaseComponent implements OnInit, AfterViewInit {

  maxHeight: number | null = null;
  loading = false;
  actives: ToxicGas[] = [];

  toxicGases?: ToxicGas[];
  toxicGasesFiltered?: ToxicGas[];
  toxicGases$!: Observable<ToxicGas[]>;
  toxicGasesSubs!: Subscription;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    public toxicGasService: ToxicGasService,
    protected override injector: Injector
  ) {
    super(injector);
  }

  private removeItemById(id: number) {
    const index = this.actives.findIndex(item => item.id === id);
    if (index !== -1) {
      this.actives.splice(index, 1);
    }
  }

  showArchive() {
    const self = this;
    const modal = this.dialog.open(ToxicGasArchiveComponent, { maxHeight: '500px' });
    modal.componentInstance.copyItemEvent.subscribe((itemToCopy: ToxicGas) => {
      itemToCopy.id = 0;
      self.showEdit(itemToCopy);
    });
  }

  showAdd() {
    this.showEdit({
      id: 0,
      name: '',
      description: '',
      date: new Date(),
      roofAccess: true,
      responsibleParty: '',
      createdByUser: this.getCurrentUser()?.name,
    });
  }

  showEdit(item: ToxicGas) {
    const self = this;
    const modal = this.dialog.open(ToxicGasEditorComponent, { width: '500px' });
    modal.componentInstance.dataItem = item;
    modal.componentInstance.returnedItemEvent.subscribe((returnedItem: ToxicGas) => {
      this.loading = true;

      self.removeItemById(returnedItem.id);
      self.actives.push(returnedItem);
      self.actives = self.toxicGasService.loadActives(self.actives);

      this.loading = false;
    });

    this.closeAllMenus();
  }

  archive(id: number) {
    this.closeAllMenus();
    this.toxicGasService.archive(id).toPromise().then(archiveItem => {
      if (archiveItem)
        this.removeItemById(archiveItem.id);
      this.actives = this.toxicGasService.loadActives(this.actives);
    }).catch(() => {
      this.alert.defaultError();

      ;
    });
  }

  toggleMenu(i: number) {
    const element = this.document.getElementById('toxicgas-menu-' + i.toString());
    if (element) {
      element.classList.toggle('collapse');

      if (!element.classList.contains('collapse')) {
        const rect = element.parentElement?.getBoundingClientRect();
        element.style.top = (rect?.top ?? 0 + 30) + 'px;';
        element.style.left = (rect?.right ?? 0 - 40) + 'px;';
      }
    }
  }

  closeAllMenus() {
    this.document.querySelectorAll('div.toxicgas-menu:not(.collapse)')
      .forEach(menu => { menu.classList.add('collapse'); });
  }

  ngOnInit() {
    this.loadToxicGases();
  }

  loadToxicGases() {
    this.loading = true;
    this.toxicGases$ = this.store.select(state => state.ToxicGas.data);
    this.toxicGasesSubs = this.toxicGases$.subscribe(data => {
      if (data?.length) {
        this.toxicGases = data;
        this.actives = this.toxicGases.filter(t => t.status == 1);
        this.loading = false;
      }
    });
  }

  refresh() {
    // this.responsiveFunction();
    this.loading = true;
    this.toxicGasService.getActive().toPromise().then(toxicGas => {
      if (toxicGas)
        this.actives = this.toxicGasService.loadActives(toxicGas);
      this.loading = false;
    }).catch(() => {
      this.alert.defaultError();

      ;
    });
  }

  ngAfterViewInit() {
    // this.responsiveFunction();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: { target: { innerWidth: any; innerHeight: any; }; }) {
    this.innerWidth = event.target.innerWidth;
    this.innerHeight = event.target.innerHeight;
    this.maxHeight = parseInt((this.innerHeight * 0.45).toFixed(0), 10);
  }

  // responsiveFunction() {
  //   this.innerWidth = window.innerWidth;
  //   this.innerHeight = window.innerHeight;
  //   this.maxHeight = parseInt((this.innerHeight * 0.45).toFixed(0), 10);
  // }
}

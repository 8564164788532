import { Component, OnInit, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngxs/store';
import { Subscription } from 'rxjs/internal/Subscription';
import { Observable } from 'rxjs';
import { ReviewBoardType } from 'src/app/components/reviews/reviews.models';
import { RedirectService } from './redirect.service';
import { Document } from 'src/app/services/documents/documents';
@Component({
  selector: 'app-redirect',
  templateUrl: './redirect.component.html',
  styleUrls: ['./redirect.component.scss']
})
export class RedirectComponent implements OnInit, OnChanges, OnDestroy {

  documents!: Document[];
  documents$!: Observable<Document[]>;
  documentsSubs!: Subscription;

  loading!: boolean;
  documentsLoading!: boolean;
  reviewBoardTypes?: ReviewBoardType[];
  isReview?: boolean;

  serialNo!: string;
  serial: any;
  notFoundText: string = '';
  message!: string;
  documentType?: number;

  constructor(
    private _route: ActivatedRoute,
    private service: RedirectService,
    private store: Store,
  ) {

  }

  ngOnChanges(changes: SimpleChanges): void {
    this.notFoundText = '';
  }

  ngOnDestroy(): void {
    this.documentsSubs?.unsubscribe();
  }


  loadDocuments() {
    this.documents$ = this.store.select(state => state.Documents.data);
    this.documentsSubs = this.documents$.subscribe(data => {
      if (data.length) {
        this.documents = data;
        this.redirect();
      }
    });
  }

  ngOnInit(): void {
    const serialNo = this._route.snapshot.paramMap.get('serialNo')?.toUpperCase();
    if (serialNo) {
      this.serialNo = serialNo;
      this.message = 'Redirecting to ' + this.serialNo;
      this.redirect();
    }
  }

  redirect() {
    this.loading = true;
    if (this.documents?.length) {
      const document = this.documents.find(x => x.serialNo == this.serialNo);
      if (document) {
        this.service.openDocument(document);
      }
      else {
        this.notFoundText = `${this.serialNo} Not Found!`;
        this.loading = false;
      }
    }
    else if (!this.documentsLoading) {
      this.loadDocuments();
    }
  }
}

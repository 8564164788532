<div class="pb-4 pr-1">
  <div class="full-width mb-1">
    <div cdkDropList (cdkDropListDropped)="drop($event)" cdkDragLockAxis="y">
      <div *ngFor="let section of sections" class="mb-2">
        <div
          cdkDrag
          class="row no-gutters"
          id="section-{{ section.id }}"
          (cdkDragMoved)="service.onDragMoved($event)"
        >
          <div class="d-flex full-width">
            <div class="d-flex justify-content-center align-items-center">
              <button
                mat-icon-button
                [class.no-display]="disabled || section.logID"
                cdkDragHandle
                color="accent"
                style="cursor: move"
              >
                <mat-icon>reorder</mat-icon>
              </button>
            </div>
            <div
              class="d-flex flex-column"
              *ngIf="showDeleted && section.logID"
            >
              <button
                mat-icon-button
                matTooltip="Restore"
                class="restore-button"
                (click)="restore(section)"
              >
                <mat-icon color="primary">restore_from_trash</mat-icon>
              </button>

              <button
                mat-icon-button
                color="warn"
                [matTooltip]="'Delete forever'"
                (click)="deleteForever(section)"
              >
                <mat-icon>delete_forever</mat-icon>
              </button>
            </div>
            <div style="width: 100%">
              <checklist-builder-section
                [section]="section"
                [showDeleted]="showDeleted"
                class="full-width"
                [disabled]="disabled"
                (deleted)="deleted($event)"
                [currentVersion]="currentVersion"
                [procedure]="procedure"
                (loading)="setLoading($event)"
                [sectionDropLists]="sectionDropLists"
                [stepDropLists]="stepDropLists"
                (refreshPreviousStep)="refreshPreviousStep($event)"
              ></checklist-builder-section>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <button
    mat-stroked-button
    color="accent"
    class="ml-5 mb-5"
    *ngIf="sections?.length && !disabled"
    (click)="add()"
    [matTooltip]="'Add New Section'"
  >
    <mat-icon>add_box</mat-icon> Add Section
  </button>
</div>

<div class="card section">
  <div class="card-body">
    <div class="row mb-3">
      <div class="col-1 pt-2">
        <h6>{{ number ?? "#" }}</h6>
      </div>
      <div class="col-11 pt-2">
        <span
          [innerHTML]="question ?? '' | placeholderHighlight : editor"
        ></span>
      </div>
    </div>

    <div class="check-all-container px-0 py-0 mb-1">
      <div
        class="row pl-3 pr-0 full-width no-gutters shielding-row align-items-center"
      >
        <div
          class="col-12 pr-0 d-flex flex-row align-items-center justify-content-end checkbox-col"
        >
          <span class="mr-2">Check All</span>
          <div class="checkbox-container justify-content-end d-flex flex-row">
            <div *ngFor="let o of shielding0?.roleOptions">
              <mat-checkbox-with-roles
                [labelPosition]="'before'"
                [checked]="o.checked"
                [disabled]="disabled || o.disabled"
                (change)="checkboxChanged($event, o.id)"
                [color]="color"
                [roleCodes]="o.roleCodes"
                [user]="o.user"
                [date]="o.date"
              >
              </mat-checkbox-with-roles>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="shielding-container px-0 py-0 mb-1"
      *ngFor="let s of shieldings; let i = index"
    >
      <div
        class="row pl-3 pr-0 full-width no-gutters shielding-row align-items-center"
      >
        <div class="col-1 bold">
          <h6 class="m-0">{{ (number ?? "#") + "." + (i + 1) }}</h6>
        </div>
        <div class="col-8 bold">
          <span class="mr-1" [ngClass]="s.resource?.cssClass">{{
            s.resource?.name
          }}</span
          >{{ s.resource?.keText }}
        </div>
        <div
          class="col-3 pr-0 d-flex flex-row align-items-center justify-content-end checkbox-col"
        >
          <mat-icon
            *ngIf="warning && checkboxVisible"
            color="warn"
            class="warn"
            [matTooltip]="moreInfo ?? ''"
            matTooltipPosition="left"
            >warning</mat-icon
          >
          <div class="checkbox-container justify-content-end d-flex flex-row">
            <div *ngFor="let o of s.roleOptions">
              <mat-checkbox-with-roles
                [labelPosition]="'before'"
                [checked]="o.checked"
                [disabled]="disabled || o.disabled"
                (change)="checkboxChanged($event, o.id, s.resource?.id)"
                [color]="color"
                [roleCodes]="o.roleCodes"
                [user]="o.user"
                [date]="o.date"
              >
              </mat-checkbox-with-roles>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

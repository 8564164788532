import { WfTaskLocalResource } from "src/app/services/work-flow/work-flow";


export class DeletedTasksRefresh {
  static readonly type = '[DeletedTasks] Refresh';
  constructor(public checklistTemplateID: number) { }
}

// export class DeletedTasksCreate {
//   static readonly type = '[DeletedTasks] Create';
//   constructor(public item: WfTaskLocalResource) { }
// }

// export class DeletedTasksDelete {
//   static readonly type = '[DeletedTasks] Delete';
//   constructor(public logID: number) { }
// }

<mat-expansion-panel [hideToggle]="hideToggle" class="checklist-expansion">
  <mat-expansion-panel-header class="card-header pb-3">
    <div class="row full-width">
      <div class="col-3">
        <span
          [ngClass]="currentChecklist?.checklistStatus?.cssClass"
          class="h5 mr-2"
        >
          {{ currentChecklist?.serialNo }}
        </span>
        <span class="h6" [ngClass]="currentChecklist?.checklistStatus?.cssClass"
          >({{ currentChecklist?.checklistStatus?.name }})</span
        >
      </div>
      <div class="col-6">
        <span
          [ngClass]="currentChecklist?.checklistStatus?.cssClass"
          class="h6"
        >
          {{ currentChecklist?.checklistTemplate?.templateType?.name }}
        </span>
      </div>
    </div>
  </mat-expansion-panel-header>
  <checklist-sections
    [currentChecklist]="currentChecklist"
    [fullHeight]="true"
  ></checklist-sections>
  <!-- <mat-action-row>
                <button mat-button>Action1</button>
              </mat-action-row> -->
</mat-expansion-panel>

<div
  #scheduleTable
  id="scheduleTable"
  class="mat-container scrollbar style-15 mb-3"
>
  <mat-table
    [dataSource]="scheduleSource"
    matSort
    (matSortChange)="changeSort($event)"
    [style.max-height.px]="innerWidth < 992 ? innerHeight * 0.7 : null"
  >
    <ng-container matColumnDef="resource">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Location
      </mat-header-cell>
      <mat-cell
        [style.color]="element.colorCompletedCol"
        *matCellDef="let element"
      >
        <span
          class="p-2 bold text-center"
          [innerHTML]="element?.name | highlightSearch : filter"
        ></span>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="procedureNumber">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Procedure number
      </mat-header-cell>
      <mat-cell
        [style.color]="element.colorCompletedCol"
        *matCellDef="let element"
      >
        <div
          class="cell-container full-width"
          *ngIf="!hutchGreaterThanHutchFunctional(element)"
        >
          <div
            class="cell"
            [ngClass]="{ 'bottom-border': element.subdetail }"
            [ngStyle]="{ 'background-color': getCellColor(element, false) }"
          >
            <div class="flex-display flex-row">
              <div
                [innerHTML]="
                  element.procedure?.procedureNumber | highlightSearch : filter
                "
              ></div>
              <!-- <div class="small pr-4" *ngIf="element.procedure?.revision">
								Rev: {{ element.procedure?.revision }}
							</div> -->
            </div>
          </div>
          <div
            *ngIf="element.subdetail"
            class="cell"
            [ngStyle]="{ 'background-color': getCellColor(element, true) }"
          >
            <div class="flex-display flex-row">
              <div
                [innerHTML]="
                  element.subdetail?.procedure?.procedureNumber
                    | highlightSearch : filter
                "
              ></div>
              <!-- <div
								class="small pr-4"
								*ngIf="element.subdetail?.procedure?.revision"
							>
								Rev: {{ element.subdetail?.procedure?.revision }}
							</div> -->
            </div>
          </div>
        </div>
        <div
          class="cell-container full-width"
          *ngIf="hutchGreaterThanHutchFunctional(element)"
        >
          <div
            *ngIf="element.subdetail"
            class="cell"
            [ngClass]="{ 'bottom-border': element.subdetail }"
            [ngStyle]="{ 'background-color': getCellColor(element, true) }"
          >
            <div class="flex-display flex-row">
              <div
                [innerHTML]="
                  element.subdetail?.procedure?.procedureNumber
                    | highlightSearch : filter
                "
              ></div>
              <!-- <div
								class="small pr-4"
								*ngIf="element.subdetail?.procedure?.revision"
							>
								Rev: {{ element.subdetail?.procedure?.revision }}
							</div> -->
            </div>
          </div>
          <div
            class="cell"
            [ngStyle]="{ 'background-color': getCellColor(element, false) }"
          >
            <div class="flex-display flex-row">
              <div
                [innerHTML]="
                  element.procedure?.procedureNumber | highlightSearch : filter
                "
              ></div>
              <!-- <div class="small pr-4" *ngIf="element.procedure?.revision">
								Rev: {{ element.procedure?.revision }}
							</div> -->
            </div>
          </div>
        </div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="subType">
      <mat-header-cell *matHeaderCellDef mat-sort-header
        ><span *ngIf="showTypeCol"> Type </span></mat-header-cell
      >
      <mat-cell
        [style.color]="element.colorCompletedCol"
        *matCellDef="let element"
      >
        <div class="cell-container full-width" *ngIf="element.typeId == 2">
          <div
            class="cell"
            [ngClass]="{ 'bottom-border': element.subdetail }"
            [ngStyle]="{ 'background-color': getCellColor(element, false) }"
          >
            <span
              [innerHTML]="
                element.procedure?.procedureCategory.name
                  | highlightSearch : filter
              "
            ></span>
          </div>
        </div>

        <div
          class="cell-container full-width"
          *ngIf="
            !hutchGreaterThanHutchFunctional(element) && element.typeId == 5
          "
        >
          <div
            class="cell"
            [ngClass]="{ 'bottom-border': element.subdetail }"
            [ngStyle]="{ 'background-color': getCellColor(element, false) }"
          >
            <span
              [innerHTML]="element.subtype?.name | highlightSearch : filter"
            ></span>
          </div>
          <div
            *ngIf="element.subdetail"
            [ngStyle]="{ 'background-color': getCellColor(element, true) }"
            class="cell"
          >
            {{ hutchKeyWord }} {{ functionalKeyWord }}
          </div>
        </div>
        <div
          class="cell-container full-width"
          *ngIf="
            hutchGreaterThanHutchFunctional(element) && element.typeId == 5
          "
        >
          <div
            *ngIf="element.subdetail"
            class="cell"
            [ngClass]="{ 'bottom-border': element.subdetail }"
            [ngStyle]="{ 'background-color': getCellColor(element, true) }"
          >
            {{ hutchKeyWord }} {{ functionalKeyWord }}
          </div>
          <div
            class="cell"
            [ngStyle]="{ 'background-color': getCellColor(element, false) }"
          >
            <span
              [innerHTML]="element.subtype?.name | highlightSearch : filter"
            ></span>
          </div>
        </div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="lastDate">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Last Completed
      </mat-header-cell>
      <mat-cell
        [style.color]="element.colorCompletedCol"
        *matCellDef="let element"
      >
        <div
          class="cell-container full-width"
          *ngIf="!hutchGreaterThanHutchFunctional(element)"
        >
          <div
            class="cell"
            [ngClass]="{ 'bottom-border': element.subdetail }"
            [ngStyle]="{ 'background-color': getCellColor(element, false) }"
          >
            {{ element.lastCompleted | date : "shortDate" }}
          </div>
          <div
            *ngIf="element.subdetail"
            class="cell"
            [ngStyle]="{ 'background-color': getCellColor(element, true) }"
          >
            {{ element.subdetail.lastCompleted | date : "shortDate" }}
          </div>
        </div>
        <div
          class="cell-container full-width"
          *ngIf="hutchGreaterThanHutchFunctional(element)"
        >
          <div
            *ngIf="element.subdetail"
            class="cell"
            [ngClass]="{ 'bottom-border': element.subdetail }"
            [ngStyle]="{ 'background-color': getCellColor(element, true) }"
          >
            {{ element.subdetail.lastCompleted | date : "shortDate" }}
          </div>
          <div
            class="cell"
            [ngStyle]="{ 'background-color': getCellColor(element, false) }"
          >
            {{ element.lastCompleted | date : "shortDate" }}
          </div>
        </div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="dueDate">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ getNextDueTitle() }}
      </mat-header-cell>
      <mat-cell
        [style.color]="element.colorCompletedCol"
        *matCellDef="let element"
      >
        <div
          class="cell-container full-width"
          *ngIf="!hutchGreaterThanHutchFunctional(element)"
        >
          <div
            class="cell"
            [ngClass]="{ 'bottom-border': element.subdetail }"
            [ngStyle]="{ 'background-color': getCellColor(element, false) }"
          >
            {{ element.nextDue | date : dateFormat }}
          </div>
          <div
            *ngIf="element.subdetail"
            class="cell"
            [ngStyle]="{ 'background-color': getCellColor(element, true) }"
          >
            {{ element.subdetail.nextDue | date : dateFormat }}
          </div>
        </div>
        <div
          class="cell-container full-width"
          *ngIf="hutchGreaterThanHutchFunctional(element)"
        >
          <div
            *ngIf="element.subdetail"
            class="cell"
            [ngClass]="{ 'bottom-border': element.subdetail }"
            [ngStyle]="{ 'background-color': getCellColor(element, true) }"
          >
            {{ element.subdetail.nextDue | date : dateFormat }}
          </div>
          <div
            class="cell"
            [ngStyle]="{ 'background-color': getCellColor(element, false) }"
          >
            {{ element.nextDue | date : dateFormat }}
          </div>
        </div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="scheduleDate">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Scheduled For
      </mat-header-cell>
      <mat-cell
        [style.color]="element.colorCompletedCol"
        *matCellDef="let element"
      >
        <div
          class="cell-container full-width"
          *ngIf="!hutchGreaterThanHutchFunctional(element)"
        >
          <div
            class="cell"
            [ngClass]="{ 'bottom-border': element.subdetail }"
            [ngStyle]="{ 'background-color': getCellColor(element, false) }"
          >
            {{ element.scheduleFor | date : "shortDate" }}
          </div>
          <div
            *ngIf="element.subdetail"
            class="cell"
            [ngStyle]="{ 'background-color': getCellColor(element, true) }"
          >
            <div style="opacity: 0"></div>
          </div>
        </div>
        <div
          class="cell-container full-width"
          *ngIf="hutchGreaterThanHutchFunctional(element)"
        >
          <div
            *ngIf="element.subdetail"
            class="cell"
            [ngClass]="{ 'bottom-border': element.subdetail }"
            [ngStyle]="{ 'background-color': getCellColor(element, true) }"
          >
            <div style="opacity: 0"></div>
          </div>
          <div
            class="cell"
            [ngStyle]="{ 'background-color': getCellColor(element, false) }"
          >
            {{ element.scheduleFor | date : "shortDate" }}
          </div>
        </div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="extendedDate">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Extended Date
      </mat-header-cell>
      <mat-cell
        [style.color]="element.colorCompletedCol"
        *matCellDef="let element"
      >
        <div
          class="cell-container full-width"
          *ngIf="!hutchGreaterThanHutchFunctional(element)"
        >
          <div
            class="cell"
            [ngClass]="{ 'bottom-border': element.subdetail }"
            [ngStyle]="{ 'background-color': getCellColor(element, false) }"
          >
            <div *ngIf="element.extendedFor != null">
              {{ element.extendedDate | date : "shortDate"
              }}<br *ngIf="element.extendedDate" />
              Requested for {{ element.extendedFor | date : "shortDate" }}
            </div>
            <div *ngIf="element.extendedFor == null">
              {{ element.extendedDate | date : "shortDate" }}
            </div>
          </div>
          <div
            *ngIf="element.subdetail"
            class="cell"
            [ngStyle]="{ 'background-color': getCellColor(element, true) }"
          >
            <div style="opacity: 0"></div>
          </div>
        </div>
        <div
          class="cell-container full-width"
          *ngIf="hutchGreaterThanHutchFunctional(element)"
        >
          <div
            *ngIf="element.subdetail"
            class="cell"
            [ngClass]="{ 'bottom-border': element.subdetail }"
            [ngStyle]="{ 'background-color': getCellColor(element, true) }"
          >
            <div style="opacity: 0"></div>
          </div>
          <div
            class="cell"
            [ngStyle]="{ 'background-color': getCellColor(element, false) }"
          >
            <div *ngIf="element.extendedFor != null">
              {{ element.extendedDate | date : "shortDate" }}
              <br *ngIf="element.extendedDate" />
              Requested for {{ element.extendedFor | date : "shortDate" }}
            </div>
            <div *ngIf="element.extendedFor == null">
              {{ element.extendedDate | date : "shortDate" }}
            </div>
          </div>
        </div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="notes">
      <mat-header-cell *matHeaderCellDef> Notes</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <div
          class="cell-container full-width"
          *ngIf="!hutchGreaterThanHutchFunctional(element)"
        >
          <div
            class="cell"
            [ngClass]="{ 'bottom-border': element.subdetail }"
            [ngStyle]="{ 'background-color': getCellColor(element, false) }"
          >
            <notes [notes]="element.notes"></notes>
          </div>
          <div
            *ngIf="element.subdetail"
            class="cell"
            [ngStyle]="{ 'background-color': getCellColor(element, true) }"
          >
            <notes [notes]="element.subdetail.notes"></notes>
          </div>
        </div>
        <div
          class="cell-container full-width"
          *ngIf="hutchGreaterThanHutchFunctional(element)"
        >
          <div
            *ngIf="element.subdetail"
            class="cell"
            [ngClass]="{ 'bottom-border': element.subdetail }"
            [ngStyle]="{ 'background-color': getCellColor(element, true) }"
          >
            <notes [notes]="element.subdetail.notes"></notes>
          </div>
          <div
            class="cell"
            [ngStyle]="{ 'background-color': getCellColor(element, false) }"
          >
            <notes [notes]="element.notes"></notes>
          </div>
        </div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="status">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Status</mat-header-cell
      >
      <mat-cell
        *matCellDef="let element"
        [style.color]="element.colorCompletedCol"
      >
        <div
          class="cell-container full-width"
          *ngIf="!hutchGreaterThanHutchFunctional(element)"
        >
          <div
            class="cell"
            [ngClass]="{ 'bottom-border': element.subdetail }"
            [ngStyle]="{ 'background-color': getCellColor(element, false) }"
          >
            {{
              element.statusTime == 4
                ? "Extension Granted"
                : ScheduleStatusTime[element.statusTime]
            }}
          </div>
          <div
            *ngIf="element.subdetail"
            class="cell"
            [ngStyle]="{ 'background-color': getCellColor(element, true) }"
          ></div>
        </div>
        <div
          class="cell-container full-width"
          *ngIf="hutchGreaterThanHutchFunctional(element)"
        >
          <div
            *ngIf="element.subdetail"
            class="cell"
            [ngClass]="{ 'bottom-border': element.subdetail }"
            [ngStyle]="{ 'background-color': getCellColor(element, true) }"
          ></div>
          <div
            class="cell"
            [ngStyle]="{ 'background-color': getCellColor(element, false) }"
          >
            {{
              element.statusTime == 4
                ? "Extension Granted"
                : ScheduleStatusTime[element.statusTime]
            }}
          </div>
        </div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="options">
      <mat-header-cell class="mat-column-opt" *matHeaderCellDef>
        Options
      </mat-header-cell>
      <mat-cell class="mat-column-opt" *matCellDef="let element">
        <div
          class="cell-container full-width"
          *ngIf="!hutchGreaterThanHutchFunctional(element)"
        >
          <div
            class="cell pt-1"
            [ngClass]="{ 'bottom-border': element.subdetail }"
            [ngStyle]="{ 'background-color': getCellColor(element, false) }"
          >
            <button
              mat-icon-button
              *ngIf="
                validateShowMenu(element.statusId, element) &&
                validateAction(element.statusId, ScheduleStatus.completed)
              "
              [matMenuTriggerFor]="menu"
              aria-label="Schedules menu"
            >
              <mat-icon color="primary">more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button
                mat-menu-item
                *ngIf="
                  sv.validateRole('createSchedule', tokenInfo, scheduleType)
                "
                (click)="openEditSchedule(element)"
              >
                <mat-icon class="yellow">edit</mat-icon>
                <span>Edit</span>
              </button>
              <button
                mat-menu-item
                *ngIf="
                  validateAction(element.statusId, ScheduleStatus.completed) &&
                  sv.validateRole('createNote', tokenInfo, scheduleType)
                "
                (click)="openAddNote(element)"
              >
                <mat-icon class="yellow">note_add</mat-icon>
                <span>Create Note</span>
              </button>
              <button
                mat-menu-item
                *ngIf="
                  validateAction(element.statusId, ScheduleStatus.completed) &&
                  sv.validateRole('completeSchedule', tokenInfo, scheduleType)
                "
                (click)="
                  openCompleteSchedule(element, ScheduleStatus.completed)
                "
              >
                <mat-icon class="green">done_outline</mat-icon>
                <span>Complete</span>
              </button>
              <button
                mat-menu-item
                *ngIf="
                  validateAction(
                    element.statusId,
                    ScheduleStatus.requestExtension
                  ) &&
                  sv.validateRole('requestExtension', tokenInfo, scheduleType)
                "
                (click)="changeStatus(element, ScheduleStatus.requestExtension)"
              >
                <mat-icon class="blue">today</mat-icon>
                <span>Request extension</span>
              </button>
              <button
                mat-menu-item
                *ngIf="
                  validateAction(
                    element.statusId,
                    ScheduleStatus.extensionGranted
                  ) &&
                  sv.validateRole(
                    'grantExtension',
                    tokenInfo,
                    scheduleType,
                    element
                  )
                "
                (click)="
                  grantExtension(element, ScheduleStatus.extensionGranted)
                "
              >
                <mat-icon class="orange">offline_pin</mat-icon>
                <span>Grant extension</span>
              </button>
              <button
                mat-menu-item
                *ngIf="
                  validateAction(
                    element.statusId,
                    ScheduleStatus.refuseExtension
                  ) &&
                  sv.validateRole(
                    'refuseSchedule',
                    tokenInfo,
                    scheduleType,
                    element
                  )
                "
                (click)="changeStatus(element, ScheduleStatus.refuseExtension)"
              >
                <mat-icon class="red">unpublished</mat-icon>
                <span>Reject extension</span>
              </button>
              <button
                mat-menu-item
                *ngIf="
                  validateAction(element.statusId, ScheduleStatus.remove) &&
                  sv.validateRole(
                    'deleteSchedule',
                    tokenInfo,
                    scheduleType,
                    element
                  )
                "
                (click)="deleteSchedule(element)"
              >
                <mat-icon class="red">delete</mat-icon>
                <span>Delete schedule</span>
              </button>
              <button
                mat-menu-item
                *ngIf="
                  sv.validateRole('editScheduledFor', tokenInfo, scheduleType)
                "
                (click)="openEditScheduledFor(element)"
              >
                <mat-icon class="purple">today</mat-icon>
                <span>Scheduled For</span>
              </button>
              <!--<button mat-menu-item *ngIf="showCreateABSI(element)" (click)="createAbsi(element)">
                      <mat-icon>assignment_turned_in</mat-icon>
                      <span>Create ABSI Checklist</span>
                    </button>-->
            </mat-menu>
          </div>
          <div
            *ngIf="element.subdetail"
            class="cell pt-1"
            [ngStyle]="{ 'background-color': getCellColor(element, true) }"
          >
            <button
              mat-icon-button
              *ngIf="
                validateShowMenu(element.statusId, element) &&
                validateAction(element.statusId, ScheduleStatus.completed)
              "
              [matMenuTriggerFor]="menu"
              aria-label="Schedules menu"
            >
              <mat-icon color="primary">more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button
                mat-menu-item
                *ngIf="
                  validateAction(element.statusId, ScheduleStatus.completed) &&
                  sv.validateRole('createNote', tokenInfo, scheduleType)
                "
                (click)="openAddNoteSub(element)"
              >
                <mat-icon class="yellow">note_add</mat-icon>
                <span>Create Note</span>
              </button>
              <button
                mat-menu-item
                *ngIf="
                  validateAction(element.statusId, ScheduleStatus.completed) &&
                  sv.validateRole('completeSchedule', tokenInfo, scheduleType)
                "
                (click)="
                  openCompleteSubdetail(
                    element.subdetail,
                    ScheduleStatus.completedSubdetail
                  )
                "
              >
                <mat-icon class="green">done_outline</mat-icon>
                <span>Complete</span>
              </button>
            </mat-menu>
          </div>
        </div>
        <div
          class="cell-container full-width"
          *ngIf="hutchGreaterThanHutchFunctional(element)"
        >
          <div
            *ngIf="element.subdetail"
            class="cell pt-1"
            [ngClass]="{ 'bottom-border': element.subdetail }"
            [ngStyle]="{ 'background-color': getCellColor(element, true) }"
          >
            <button
              mat-icon-button
              *ngIf="
                validateShowMenu(element.statusId, element) &&
                validateAction(element.statusId, ScheduleStatus.completed)
              "
              [matMenuTriggerFor]="menu"
              aria-label="Schedules menu"
            >
              <mat-icon color="primary">more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button
                mat-menu-item
                *ngIf="
                  validateAction(element.statusId, ScheduleStatus.completed) &&
                  sv.validateRole('createNote', tokenInfo, scheduleType)
                "
                (click)="openAddNoteSub(element)"
              >
                <mat-icon class="yellow">note_add</mat-icon>
                <span>Create Note</span>
              </button>
              <button
                mat-menu-item
                *ngIf="
                  validateAction(element.statusId, ScheduleStatus.completed) &&
                  sv.validateRole('completeSchedule', tokenInfo, scheduleType)
                "
                (click)="
                  openCompleteSubdetail(
                    element.subdetail,
                    ScheduleStatus.completedSubdetail
                  )
                "
              >
                <mat-icon class="green">done_outline</mat-icon>
                <span>Complete</span>
              </button>
            </mat-menu>
          </div>
          <div
            class="cell pt-1"
            [ngStyle]="{ 'background-color': getCellColor(element, false) }"
          >
            <button
              mat-icon-button
              *ngIf="
                validateShowMenu(element.statusId, element) &&
                validateAction(element.statusId, ScheduleStatus.completed)
              "
              [matMenuTriggerFor]="menu"
              aria-label="Schedules menu"
            >
              <mat-icon color="primary">more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button
                mat-menu-item
                *ngIf="
                  sv.validateRole(
                    'updateSchedule',
                    tokenInfo,
                    scheduleType,
                    element
                  )
                "
                (click)="openEditSchedule(element)"
              >
                <mat-icon class="yellow">edit</mat-icon>
                <span>Edit</span>
              </button>
              <button
                mat-menu-item
                *ngIf="
                  validateAction(element.statusId, ScheduleStatus.completed) &&
                  sv.validateRole('createNote', tokenInfo, scheduleType)
                "
                (click)="openAddNote(element)"
              >
                <mat-icon class="yellow">note_add</mat-icon>
                <span>Create Note</span>
              </button>
              <button
                mat-menu-item
                *ngIf="
                  validateAction(element.statusId, ScheduleStatus.completed) &&
                  sv.validateRole('completeSchedule', tokenInfo, scheduleType)
                "
                (click)="
                  openCompleteSchedule(element, ScheduleStatus.completed)
                "
              >
                <mat-icon class="green">done_outline</mat-icon>
                <span>Complete</span>
              </button>
              <button
                mat-menu-item
                *ngIf="
                  validateAction(
                    element.statusId,
                    ScheduleStatus.requestExtension
                  ) &&
                  sv.validateRole('requestExtension', tokenInfo, scheduleType)
                "
                (click)="changeStatus(element, ScheduleStatus.requestExtension)"
              >
                <mat-icon class="pink">today</mat-icon>
                <span>Request extension</span>
              </button>
              <button
                mat-menu-item
                *ngIf="
                  validateAction(
                    element.statusId,
                    ScheduleStatus.extensionGranted
                  ) &&
                  sv.validateRole(
                    'grantExtension',
                    tokenInfo,
                    scheduleType,
                    element
                  )
                "
                (click)="
                  grantExtension(element, ScheduleStatus.extensionGranted)
                "
              >
                <mat-icon class="orange">offline_pin</mat-icon>
                <span>Grant extension</span>
              </button>
              <button
                mat-menu-item
                *ngIf="
                  validateAction(
                    element.statusId,
                    ScheduleStatus.refuseExtension
                  ) &&
                  sv.validateRole(
                    'refuseSchedule',
                    tokenInfo,
                    scheduleType,
                    element
                  )
                "
                (click)="changeStatus(element, ScheduleStatus.refuseExtension)"
              >
                <mat-icon class="red">unpublished</mat-icon>
                <span>Reject extension</span>
              </button>
              <button
                mat-menu-item
                *ngIf="
                  validateAction(element.statusId, ScheduleStatus.remove) &&
                  sv.validateRole(
                    'deleteSchedule',
                    tokenInfo,
                    scheduleType,
                    element
                  )
                "
                (click)="deleteSchedule(element)"
              >
                <mat-icon class="red">delete</mat-icon>
                <span>Delete schedule</span>
              </button>
            </mat-menu>
          </div>
        </div>
      </mat-cell>
    </ng-container>
    <mat-header-row
      #header
      *matHeaderRowDef="displayedColumnsSchedule; sticky: true"
    >
    </mat-header-row>
    <mat-row
      #matrow
      *matRowDef="let row; columns: displayedColumnsSchedule"
      [attr.position]="getRowPosition(row)"
    >
    </mat-row>
  </mat-table>
</div>

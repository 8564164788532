import { Component, EventEmitter, Input, Output, OnChanges, SimpleChanges, Injector } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { CommentsBoxEditComponent } from './comments-box-edit/comments-box-edit.component';
import { PlaceholdersService } from 'src/app/services/placeholders/placeholders.service';
import { FloatLabelType } from '@angular/material/form-field';
import { FormControl } from '@angular/forms';
import { ClBaseComponent } from '../checklist-components/cl-base/cl-base.component';
import { ImageUploadService } from 'src/app/services/file/image-upload.service';

@Component({
  selector: 'comments-box',
  templateUrl: './comments-box.component.html',
  styleUrls: ['./comments-box.component.scss']
})
export class CommentsBoxComponent extends ClBaseComponent implements OnChanges {

  @Input() minHeight?: string;
  @Input() maxHeight?: string;
  @Input() hasError!: boolean;
  @Input() hilite?: boolean | null;
  @Input() control?: FormControl;
  @Input() noSubscript?: boolean;
  @Input() placeholder?: string;
  @Input() cssClass?: string;
  @Input() noBorder?: boolean = false;

  @Output() textChanged = new EventEmitter<any>();

  public loading!: boolean;
  textValueSanitized!: SafeHtml | null;

  constructor(
    protected override injector: Injector,
    private sanitizer: DomSanitizer,
    private placeholdersService: PlaceholdersService,
    private service: ImageUploadService,
  ) {
    super(injector);
  }


  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (this.textValue?.includes('data:image')) {
      const text = await this.uploadImages(this.textValue);
      if (text != this.textValue) {
        this.textChanged.emit(text);
        this.textValue = text;
      }
    }
    this.textValueSanitized = this.textValue ? this.transform(this.textValue) : null;
    if (this.textValueSanitized)

      if (this.control) {
        if (this.disabled)
          this.control.disable();
        else {
          this.control.enable();
        }
        if (this.hasError) this.control.setErrors({ required: true });
      }
  }

  editText(e: any, parent: any) {
    e.stopPropagation();
    const box = parent.getBoundingClientRect();
    if (!this.disabled && !this.loading) {
      const dialogConfig = new MatDialogConfig();
      const height = box.height + 52;
      let top = ((box.top - 38) > 0 ? (box.top - 38) : 0);
      if (top + height > window.innerHeight) {
        top = window.innerHeight - height < 120 ? 120 : window.innerHeight - height;
      }
      dialogConfig.width = (box.width + 1) + 'px';
      dialogConfig.height = this.minHeight ? this.minHeight : height + 'px';
      dialogConfig.position = { left: box.left + 'px', top: top + 'px' };
      dialogConfig.autoFocus = false;
      dialogConfig.disableClose = true;
      dialogConfig.maxWidth = '100%';
      dialogConfig.maxHeight = (window.innerHeight * .9 - 105) + 'px';
      dialogConfig.minHeight = this.minHeight ? this.minHeight : '0';
      dialogConfig.data = {
        textValue: this.textValue,
        formType: this.formType,
        serialNo: this.serialNo,
        title: this.title,
        minHeight: this.minHeight,
        maxHeight: (window.innerHeight * .9 - 120) + 'px'
      };
      const dialogRef = this.dialog.open(CommentsBoxEditComponent, dialogConfig);
      dialogRef.afterClosed().subscribe(async (data) => {
        if (data) {
          this.loading = true;
          this.textValue = data.textValue;
          this.emit(data.textValue);
          this.hasError = this.textValue ? false : true;
        } else if (!this.textValue) {
          this.hasError = true;
        }
      });
    }
  }

  emit(textValue: any) {
    this.textChanged.emit(textValue);
    this.loading = false;
  }

  getFloatLabel(): FloatLabelType {
    return this.textValue ? 'always' : 'auto';
  }

  transform(value: string): SafeHtml | null {
    if (value) {
      const res = value?.replace(new RegExp('\{([a-z,A-Z,0-9]+)\}', 'ig'), match => {
        value = this.placeholdersService.getValue(match);
        if (value !== undefined && !this.editor) {
          return '<b style="border-bottom: 1px dotted #ddd; color: #285E87">' + value + '</b>';
        }
        else {
          return '<b style="color: #E04E39">' + match + '</b>';
        }
      });
      return this.sanitizer.bypassSecurityTrustHtml(res);
    }
    else {
      return null;
    }
  }

  async uploadImages(textValue: string) {
    const text = await this.service.uploadImagesToServer(textValue, '/' + this.formType?.toString()?.trim() + '/' + this.serialNo + '/Comments/');
    return text;
  }
}

import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { AbsiService } from '../../absi.service';
import { AbsiSectionCreate, AbsiSectionDelete, AbsiSectionRefresh, AbsiSectionUpdate } from './absi-section.action';
import { WFSectionLocalResource } from 'src/app/services/work-flow/work-flow';

export class AbsiSectionStateModel {
  data: WFSectionLocalResource[] = [];
}

@State<AbsiSectionStateModel>({
  name: 'AbsiSections',
  defaults: {
    data: []
  }
})

@Injectable()
export class AbsiSectionState {
  constructor(
    private absiService: AbsiService
  ) { }

  @Selector()
  static read(state: AbsiSectionStateModel) {
    return state.data;
  }

  @Action(AbsiSectionRefresh)
  refresh({ patchState, getState }: StateContext<AbsiSectionStateModel>, { wfTableID }: AbsiSectionRefresh) {
    this.absiService.readAllSections(wfTableID).subscribe(data => {
      patchState({
        data
      });
    });
  }

  @Action(AbsiSectionCreate)
  create({ getState, patchState }: StateContext<AbsiSectionStateModel>, { item }: AbsiSectionCreate) {
    patchState({
      data: [...getState().data, item]
    });
  }

  @Action(AbsiSectionUpdate)
  update({ getState, patchState }: StateContext<AbsiSectionStateModel>, { id, item }: AbsiSectionUpdate) {
    const state = getState();
    const index = state.data.map(x => x.id).indexOf(id);

    if (index >= 0) {
      state.data.splice(index, 1);
      patchState({
        data: [...state.data, item]
      });
    }
  }

  @Action(AbsiSectionDelete)
  delete({ getState, patchState }: StateContext<AbsiSectionStateModel>, { id }: AbsiSectionDelete) {
    patchState({
      data: getState().data.filter(x => x.id != id)
    });
  }

}

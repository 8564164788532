import { Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren, OnDestroy, Injector, ChangeDetectorRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogConfig } from '@angular/material/dialog';
import { MatAccordion, MatExpansionPanel } from '@angular/material/expansion';
import { MatSidenavContainer } from '@angular/material/sidenav';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription, Observable } from 'rxjs';
import { BaseComponent } from 'src/app/common/base/base.component';
import { Roles } from 'src/app/common/enumerations/enumerations';
import { YesNoDialogComponent } from 'src/app/controls/yes-no-dialog/yes-no-dialog.component';
import { MessageBanner } from 'src/app/components/messages/services/message-banner';
import { PrivilegeEnum } from 'src/app/services/role-privilege/privilege-enum';
import { Role } from 'src/app/components/catalogs/roles/services/role';
import { User } from 'src/app/components/catalogs/user-catalog/services/user';
import { ProcedureObligationType } from '../enums/procedure-obligation-type.enum';
import { ProcedureCredited } from '../models/procedure-credited.model';
import { ProcedureTraining } from '../models/procedure-training.model';
import { Procedure } from '../models/procedure.model';
import { ProcedureTrainingService } from '../services/procedure-training.service';
import { ExtraProcedureTrainingPopupComponent } from './extra-procedure-training-popup/extra-procedure-training-popup.component';
import { UserService } from 'src/app/components/catalogs/user-catalog/services/user.service';

export class ProcedureTrainingTableInfo {
  trainingsWithRoles: any[] = [];
  trainingsWithoutRoles: any[] = [];
}

@Component({
  selector: 'app-procedure-training-v2',
  templateUrl: './procedure-training-v2.component.html',
  styleUrls: ['./procedure-training-v2.component.scss']
})
export class ProcedureTrainingV2Component extends BaseComponent implements OnInit, OnDestroy {

  disabled!: boolean;
  loading = false;
  loadingMessage!: string;

  filterCtrl = new FormControl();

  errorMessages!: string[];
  headerOnTop!: boolean;

  valueFilter!: string;
  createdOn!: string;

  ///////
  tmpFilter!: string;
  condition!: string;
  filteredFilter!: any[];
  currentFilter: any;
  filter: any;

  ///////////////////////
  @ViewChild(MatAccordion)
  accordion!: MatAccordion;
  @ViewChildren(MatExpansionPanel)
  panels!: QueryList<MatExpansionPanel>;

  admin!: boolean;
  supervised: User[] = [];
  activeUsers: User[] = [];
  user!: User;

  usersFiltered!: User[];
  userSelected!: User | null;
  usersInTables!: { id: any; name: any; }[];
  usersColumns: string[] = ['name'];
  userName!: string;

  filterName?: string;
  filterValue = '';

  extraTrainingsPermission = false;
  enableUserManageTrainings = false;
  showTrainingsUserManages = false;
  showArchived = false;

  showAppendices = false;

  originalTrainings: any[] = [];
  trainingsToFilter: any[] = [];
  navId = -1;

  trainings: any[] = [];

  customCollapsedHeight = '60px';
  customExpandedHeight = '50px';

  prevScrollpos = window.pageYOffset;
  headerWidth = '';
  links = ['Procedure', 'KE Checklist', 'RSS Test', 'EPS Test', 'EPS Drawings', 'Rad Survey'];
  activeLink = this.links[0];

  @ViewChild(MatSidenavContainer)
  sideNav!: MatSidenavContainer;
  @ViewChild('content')
  content!: ElementRef;

  procedures$!: Observable<Procedure[]>;
  allProcedures: Procedure[] = [];

  procedureTraining$!: Observable<ProcedureTraining[]>;
  procedureTrainingSubscription!: Subscription;
  procedureTrainings: ProcedureTraining[] = [];

  procedureCredited$!: Observable<ProcedureCredited[]>;
  procedureCreditedSubscription!: Subscription;
  proceduresCredited: ProcedureCredited[] = [];

  messageSubscription!: Subscription;
  messages$!: Observable<MessageBanner[]>;

  message_general_error!: MessageBanner;

  label_procedure_training!: string;
  label_procedure_number!: string;
  label_procedure_title!: string;
  label_revision!: string;
  label_complete!: string;
  label_completed!: string;
  label_obligation!: string;
  label_users_categories!: string;
  label_required_read_only!: string;
  label_required_follow_up!: string;
  label_recommended!: string;
  label_are_you_sure_you_want_to_delete_the_procedure!: string;
  label_training_complete_option!: string;
  label_training_remove_option!: string;

  constructor(
    protected override injector: Injector,
    private procedureTrainingService: ProcedureTrainingService,
    private userService: UserService,
    private cdRef: ChangeDetectorRef
  ) {
    super(injector);

  }

  override ngOnDestroy(): void {
    this.procedureCreditedSubscription?.unsubscribe();
    this.procedureTrainingSubscription?.unsubscribe();
    super.ngOnDestroy();
  }

  ngOnInit(): void {
    this.setLoading(true, 0);
    // this.initStore();
    this.admin = this.hasPrivilege(PrivilegeEnum.ALSProcedureTrainingAdmin);

    this.setUsers();

    this.loadProcedureTrainings();
    this.loadProcedureCredited();

    this.extraTrainingsPermission = this.hasRoles([Roles.SA, Roles.PC]);;
    this.procedures$ = this.store.select(state => state.Procedures.data);
    this.procedures$.subscribe((data) => {
      if (data.length) {
        this.allProcedures = data?.filter(x => x.status == 1 && x.active).sort((a, b) => a.procedureNumber < b.procedureNumber ? -1 : 1);
        this.checkLoading();
      }
    });

    this.setLabels();
  }

  checkLoading() {
    this.setLoading(true, 0);
    this.cdRef.detectChanges();
    if (this.allProcedures.length && this.procedureTrainings.length && this.proceduresCredited.length) {
      this.format();
    }
  }

  setUsers() {
    this.userService.readSupervisedForTraining().subscribe(
      data => {
        this.supervised = data;
        this.enableUserManageTrainings = this.supervised.some(x => x.id != this.currentUser?.id);
        this.users = this.getUsers();
        this.usersFiltered = this.users;
        this.activeUsers = this.users.filter(x => x.status == 1);
        this.checkLoading();
      },
      error => {
        this.alert.defaultError();
        console.error(error);
      },
    );
  }

  loadProcedureTrainings() {
    this.procedureTraining$ = this.store.select(state => state.ProcedureTraining.ProcedureTrainings);
    this.procedureTrainingSubscription = this.procedureTraining$.subscribe((data: ProcedureTraining[]) => {
      if (data.length) {
        this.procedureTrainings = data;
        this.checkLoading();
      }
    });
  }

  loadProcedureCredited() {
    this.procedureCredited$ = this.store.select(state => state.ProcedureCredited.ProceduresCredited);
    this.procedureCreditedSubscription = this.procedureCredited$.subscribe((data: ProcedureCredited[]) => {
      if (data.length) {
        this.proceduresCredited = data;
        this.checkLoading();
      }
    });
  }

  applyFilter(e: any) {
    this.filter = e;
  }

  filterUsers(e: any) {
    const value = e.target.value as string;
    this.usersFiltered = this.users.filter(x => x.name?.toLowerCase().includes(value.toLowerCase()));
  }

  setLabels() {
    this.label_procedure_training = this.getMessage('ALSPC_ProcedureTraining_ProcedureTraining')?.description;
    this.label_procedure_number = this.getMessage('ALSPC_ProcedureTraining_ProcedureNumber')?.description;
    this.label_procedure_title = this.getMessage('ALSPC_ProcedureTraining_Title')?.description;
    this.label_revision = this.getMessage('ALSPC_ProcedureTraining_Revision')?.description;
    this.label_complete = this.getMessage('ALSPC_ProcedureTraining_Complete')?.description;
    this.label_completed = this.getMessage('ALSPC_ProcedureTraining_Completed')?.description;
    this.label_obligation = this.getMessage('ALSPC_ProcedureTraining_Obligation')?.description;
    this.label_users_categories = 'Category';
    this.label_required_read_only = this.getMessage('ALSPC_RolesCatalog_Required_Read_Only')?.description;
    this.label_required_follow_up = this.getMessage('ALSPC_RolesCatalog_Required_Follow_Up')?.description;
    this.label_recommended = this.getMessage('ALSPC_RolesCatalog_Recommended')?.description;
    this.label_are_you_sure_you_want_to_delete_the_procedure = this.getMessage('ALSPC_RolesCatalog_AreYouSureYouWantToDeleteTheProcedure')?.description;
    this.label_training_complete_option = this.getMessage('Training_Complete_Option')?.description;
    this.label_training_remove_option = this.getMessage('Training_Remove_Option')?.description;
    if (!this.label_procedure_training) {
      setTimeout(() => {
        this.setLabels();
      }, 1000);
    }
  }

  format() {
    let users: User[] = [];
    if (!this.currentUser) { return; }

    this.trainings = [];

    if (this.userSelected) {
      users.push(this.userSelected);
    } else {
      users.push(this.currentUser);
      if (this.showTrainingsUserManages) {
        users = users.concat(this.supervised.filter(x => x.id != this.currentUser?.id));
      }
    }

    users.sort((a, b) => this.utils.sort(a.name, b.name, true)).map(user => {
      const userRoles: number[] = user.userRole?.map(x => x.roleID ?? 0) ?? [];
      const userProceduresCredited: ProcedureCredited[] = this.proceduresCredited.filter(x => x.userID == user?.id);
      const userProcedureTrainings: ProcedureTraining[] = this.procedureTrainings?.filter(x => ((userRoles.includes(x.roleID ?? 0) && x.role && x.role?.trainingRole) || (!x.role && x?.userID == user?.id)) || userProceduresCredited.map(y => y?.procedureID).includes(x.procedureMaster?.procedure?.id));
      const userTrainings = this.getUserTrainings(userProcedureTrainings, userProceduresCredited, userRoles, user);
      const trainingsBeingUsed = [...userTrainings.trainingsWithRoles, ...userTrainings.trainingsWithoutRoles];

      if (userTrainings) {
        this.trainings.push({ user, trainingsWithRole: this.getTrainingsMatTableDataSource(userTrainings.trainingsWithRoles), trainingsWithoutRole: this.getTrainingsMatTableDataSource(userTrainings.trainingsWithoutRoles), trainingsBeingUsed, showAppendices: this.showAppendices });
      }
    });

    if (!this.userSelected) {
      this.originalTrainings = this.trainings;
    }
    this.trainingsToFilter = this.trainings;
    this.setCurrentUserTrainingsFirst();
    this.applyFilterTrainings();
    this.setLoading(false, 0);
  }

  getTrainingsMatTableDataSource(userTrainings: any): MatTableDataSource<any> | null {
    if (userTrainings.length) {
      const userTrainingsSource = new MatTableDataSource<any>(userTrainings);
      userTrainingsSource.filterPredicate = (data, filter) => {
        return data.procedure.procedureNumber.trim().toLowerCase().includes(filter) ||
          data.procedure.title.trim().toLowerCase().includes(filter);
      };
      return userTrainingsSource;
    }
    return null;
  }

  getUserTrainings(userProcedureTrainings: ProcedureTraining[], userProceduresCredited: ProcedureCredited[], userRoles: number[], user: User): ProcedureTrainingTableInfo {
    const procedureIds: number[] = [];
    const trainingsInfo = new ProcedureTrainingTableInfo();

    const procedureTrainings = userProcedureTrainings?.map(x => x.procedureMaster?.procedure)
      .filter((value, index, self) => self.indexOf(value) === index)
      .sort((a, b) => this.utils.sort(a?.procedureNumber, b?.procedureNumber, true));

    procedureTrainings.map(procedure => {
      if (!procedureIds.some(x => procedure?.id == x)) {
        let proceduresCredited = this.utils.cloneDeep(userProceduresCredited.filter(x => x.procedure?.procedureMasterID == procedure?.procedureMasterID).sort((a, b) => this.utils.sort(a.procedure?.version, b.procedure?.version, false)));

        const lastProcedureApproved = proceduresCredited.find(x => x.status == 1);
        if (lastProcedureApproved) {
          proceduresCredited = proceduresCredited.filter(p => p.procedureID != lastProcedureApproved.procedureID || (p.status == 1 && p.procedureID == lastProcedureApproved.procedureID));
        }
        if (procedure) {
          let obligationType = this.getObligationTypeText(userProcedureTrainings.filter(x => (userRoles.includes(x.roleID ?? 0) && x.role) || (!x.role && x.userID == user.id)), procedure);
          let trainingApplicableRoles = this.getApplicableRoles(userProcedureTrainings.filter(x => x.role?.trainingRole), procedure, userRoles);


          let trainingInfo = {
            procedure,
            procedureCredited: proceduresCredited[0],
            applicableRoles: trainingApplicableRoles,
            obligationType,
            creditedRev: lastProcedureApproved?.procedure?.revision,
            creditedDate: lastProcedureApproved?.dateCredited,
            creditedBy: lastProcedureApproved?.approver,
            showAppendices: this.showAppendices
          };

          if (trainingApplicableRoles.length) {
            trainingsInfo.trainingsWithRoles.push(trainingInfo);
          } else {
            trainingInfo.obligationType = '';
            trainingsInfo.trainingsWithoutRoles.push(trainingInfo);
          }
          procedureIds.push(procedure?.id);
          if (this.showArchived) {
            const userProceduresCreditedArchived = userProceduresCredited.filter(x => x.procedure?.procedureMasterID == procedure?.procedureMasterID && x.procedure?.version != procedure.version).sort((a, b) => this.utils.sort(a.procedure?.version, b.procedure?.version, false));
            const procedureClone: Procedure = this.utils.cloneDeep(procedure);
            procedureClone.status = 0;
            obligationType = this.getObligationTypeText(userProcedureTrainings, procedureClone);
            trainingApplicableRoles = this.getApplicableRoles(userProcedureTrainings, procedureClone, userRoles);

            userProceduresCreditedArchived.map(userProcedCredited => {
              if (userProcedCredited.procedure) {
                userProcedCredited.procedure.status = 1;
                procedureClone.historyProcedure = true;

                trainingInfo = {
                  procedure: procedureClone,
                  procedureCredited: userProcedCredited,
                  applicableRoles: trainingApplicableRoles,
                  obligationType,
                  creditedRev: userProcedCredited.procedure.revision,
                  creditedDate: userProcedCredited.dateCredited,
                  creditedBy: userProcedCredited.approver,
                  showAppendices: this.showAppendices
                };
              }

              if (trainingApplicableRoles) {
                trainingsInfo.trainingsWithRoles.push(trainingInfo);
              } else {
                trainingsInfo.trainingsWithoutRoles.push(trainingInfo);
              }
              procedureIds.push(procedure?.id);
            });
          }
        }

      }
    });

    return trainingsInfo;
  }

  getObligationTypeText(userProcedureTrainings: ProcedureTraining[], procedure: Procedure): string | null {
    const maxObligationType = Math.max(...userProcedureTrainings.filter(userProcedures => userProcedures.procedureMasterID === procedure.procedureMasterID).map(o => o.obligationType));
    let obligationType: string | null = '';

    switch (maxObligationType) {
      case ProcedureObligationType.RequiredReadOnly:
        obligationType = this.label_required_read_only;
        break;
      case ProcedureObligationType.RequiredFollowUp:
        obligationType = this.label_required_follow_up;
        break;
      case ProcedureObligationType.Recommended:
        obligationType = this.label_recommended;
        break;
      case ProcedureObligationType.Extra:
        obligationType = null;
        break;
    }
    return obligationType;
  }

  getApplicableRoles(userProcedureTrainings: ProcedureTraining[], procedure: Procedure, userRoles: number[]): (Role | undefined)[] {
    const applicableRoles = userProcedureTrainings.filter(userProcedures => userProcedures.procedureMasterID === procedure.procedureMasterID && userRoles.includes(userProcedures.roleID ?? 0)).map(o => o.role);
    return applicableRoles;
  }

  applyFilterTrainings(event?: any) {
    if (!this.userSelected && !this.showTrainingsUserManages) {
      this.filterName = this.currentUser?.name?.trim().toLowerCase();
    }
    if (this.userSelected) {
      this.filterName = this.userSelected?.name?.trim().toLowerCase();
    }

    if (this.filterName && !this.showArchived) {
      this.trainingsToFilter = this.originalTrainings.filter(x => x.user.name.trim().toLowerCase().includes(this.filterName));
    }

    if (event) {
      this.filterValue = (event.target as HTMLInputElement).value.trim().toLowerCase();
    }

    const containsName = this.originalTrainings.some(x => x.user.name.trim().toLowerCase().includes(this.filterValue)) && !this.filterName;
    let filterByName = containsName && this.filterValue !== '';
    if (this.userSelected && !filterByName) {
      const userFound = this.originalTrainings.some(x => x.user.name.trim().toLowerCase().includes(this.filterName));
      if (!userFound) {
        filterByName = true;
      }
    }
    if ((this.filterValue === this.currentUser?.name?.trim().toLowerCase() && !this.userSelected) || (this.filterName === this.currentUser?.name?.trim().toLowerCase() && !this.userSelected) || this.filterValue === this.userSelected?.name?.trim().toLowerCase()) {
      filterByName = true;
    }

    if (this.trainingsToFilter?.length && !this.userSelected) {
      this.trainings = this.trainingsToFilter;
    }

    if (filterByName && !this.userSelected) {
      const filter = this.filterName ? this.filterName : this.filterValue;
      this.trainings = this.originalTrainings.filter(x => x.user.name.trim().toLowerCase().includes(filter));
    }
    this.trainings.map(element => {
      if (element.trainingsWithRole) {
        element.trainingsWithRole.filter = filterByName && !this.filterName ? '' : this.filterValue;
      }
      if (element.trainingsWithoutRole) {
        element.trainingsWithoutRole.filter = filterByName && !this.filterName ? '' : this.filterValue;
      }
    });
    if (!this.trainingsToFilter?.length) {
      this.trainingsToFilter = this.trainings;
    }

    if (event) {
      this.openPanels();
    }
  }

  setCurrentUserTrainingsFirst() {
    if (this.originalTrainings.length > 0) {
      const index = this.originalTrainings.findIndex(x => x.user.name === this.currentUser?.name);
      if (index >= 0) {
        const element = this.originalTrainings[index];
        this.originalTrainings.splice(index, 1);
        this.originalTrainings.unshift(element);
      }
    }

    this.usersInTables = this.trainings.map((x) => {
      return {
        id: x.user.id,
        name: x.user.name
      };
    });
  }

  openPanels() {
    this.panels.map(panel => {
      if (!panel.expanded) {
        panel.open();
      }
    });
  }

  changeRolesUserManages(data: any) {
    this.showTrainingsUserManages = data;
    this.filterName = '';
    this.userSelected = null;
    this.format();
  }

  changeArchived(data: any) {
    this.showArchived = data;
    this.format();
  }

  changeAppendices(data: any) {
    this.showAppendices = data;
    this.format();
  }

  getUserSelected(isSelected: boolean, user: User) {
    if (isSelected) {
      this.userSelected = user;
      this.format();
    }
  }

  trackByIdentity(item: any) {
    return item.name;
  }

  addProcedurePopup(training: any) {
    const selectedProcedures = training.trainingsBeingUsed.map((x: any) => x.procedure);

    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      allProcedures: this.allProcedures,
      selectedProcedures
    };

    dialogConfig.width = '30vw';
    const dialogRef = this.dialog.open(ExtraProcedureTrainingPopupComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((procedure: Procedure) => {
      if (procedure) {
        this.procedureTrainingService.create({
          procedureMasterID: procedure.procedureMasterID,
          roleID: 0,
          obligationType: ProcedureObligationType.Extra,
          userID: training.user.id
        }).subscribe();
      }
    });
  }

  deleteTraining(procedureInfo: { procedureId: number, userId: number }) {
    this.dialog.open(YesNoDialogComponent, {
      width: '25vw',
      data: {
        message: this.label_are_you_sure_you_want_to_delete_the_procedure,
      }
    }).afterClosed().subscribe(
      data => {
        if (data) {
          const procedureTraining = this.procedureTrainings.find(x => x.procedureMaster?.procedure?.id == procedureInfo.procedureId && x.roleID == 0 && x.userID == procedureInfo.userId);
          if (procedureTraining?.id)
            this.procedureTrainingService.delete(procedureTraining?.id).subscribe(
              () => { },
              () => {
                this.alert.defaultError();
              }
            );
        }
      }
    );
  }

  settings() {
    // const settings = this.dialog.open(ScfV2SettingsComponent, {
    //   width: '70vw',
    //   minWidth: '400px',
    //   minHeight: '400px',
    //   disableClose: true
    // });
    // settings.afterClosed().subscribe(() => {

    // });
  }


  navigate(id: number) {
    this.navId = id;
    document.getElementById('_' + id.toString())?.scrollIntoView({ behavior: 'smooth' });
  }

  initStore() {
    // this.store.dispatch(
    //   new ProcedureCategoryRefresh()
    // );
    // this.store.dispatch(
    //   new ProcedureTrainingRefresh()
    // );
    // this.store.dispatch(
    //   new ProcedureCreditedRefresh()
    // );
    // this.store.dispatch(
    //   new ProcedureTemplateRefresh()
    // );
    // this.store.dispatch(
    //   new ProcedureConfigurationRefresh()
    // );
  }

  setLoading(e: any, origin: any) {
    if (!e) {
      setTimeout(() => {
        this.showLoading(e, origin);
      }, 2000);
    }
    else { this.showLoading(e, origin); }
  }

  showLoading(e: boolean, origin: any) {
    setTimeout(() => {
      switch (origin) {
        case LoadingOrigin.Self:
          this.loadingSelf = e as boolean;
          break;
        case LoadingOrigin.Side:
          this.loadingSide = e as boolean;
          break;
        case LoadingOrigin.Buttons:
          this.loadingButtons = e as boolean;
          break;
        case LoadingOrigin.Main:
          this.loadingMain = e as boolean;
          break;
      }
      console.log('procedure-training-v2: ' + this.loadingSelf + '   procedure-taining-table: ' + this.loadingMain + '   extra trainings: ' + this.loadingButtons + '   s: ' + this.loadingSide);
      this.loading = this.loadingSelf || this.loadingMain || this.loadingButtons || this.loadingSide;
    }, 100);
  }

  loadingSide = false;
  loadingButtons = false;
  loadingMain = false;
  loadingSelf = false;
  loadingOrigin = LoadingOrigin;
}

enum LoadingOrigin {
  Self = 0, Side = 1, Buttons = 2, Main = 3
}

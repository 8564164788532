<div
  class="d-flex flex-row align-items-center login-container justify-content-end"
>
  <div *ngIf="loggedIn">
    <a
      class="user-roles nav-link text-white d-flex justify-content-end align-items-center px-0"
      (click)="showUserInfo()"
    >
      <span class="text-white font-weight-bold mr-2">{{ name }}</span>
      <mat-icon class="user-pic">account_circle</mat-icon>
    </a>
  </div>

  <div *ngIf="!loggedIn">
    <a class="nav-link text-white pointer pt-2 pr-2" (click)="login()"
      ><span class="top-10">Login </span><mat-icon>login</mat-icon></a
    >
  </div>
</div>

<div
  id="userInfo"
  class="card shadow user-details hidden"
  (mouseleave)="mouseleave()"
>
  <!-- (mouseleave)="mouseleave()" -->
  <div class="card-header pb-0">
    <div class="row">
      <div class="col-5">
        <div>
          <mat-icon *ngIf="!userCheck()" color="primary" class="user-pic-big"
            >account_circle</mat-icon
          >
          <img
            *ngIf="userCheck()"
            style="width: 75px; height: 75px"
            alt="user"
            src="https://ascc-docs.als.lbl.gov/ST/ST-23-007/bmo-11bae221-bedc-40c8-aa0c-4e556cb6ea56.gif"
          />
          <!-- <img alt="user" src="../../assets/images/user.png" /> -->
        </div>
      </div>
      <div class="col-7">
        <button
          class="float-right small"
          mat-stroked-button
          aria-label="logout"
          *ngIf="loggedIn"
          (click)="logOut()"
          color="warn"
        >
          Close Session
        </button>
      </div>
    </div>
    <div class="row py-2 mb-1 border-top">
      <div class="col text-center">
        <h5 class="name mb-0">{{ name }}</h5>
      </div>
    </div>
  </div>
  <div class="card-body py-2">
    <div class="row pb-2">
      <div class="col">
        <div class="row role-row" *ngFor="let role of rolesList">
          <div class="col d-flex justify-content-end">
            <role-chips [roleCodes]="role?.code"></role-chips>
          </div>
          <div class="col role pl-0 pointer" [matTooltip]="role?.name ?? ''">
            {{ role?.name }}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<iframe style="display: none" #iframe *ngIf="logout">
  <logout></logout>
</iframe>

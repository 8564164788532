// import { PendingApproval } from 'src/app/Services/PendingApprovals/pendingapproval';

import { PendingApproval } from "src/app/services/pending-approvals/pending-approval";

export class PendingApprovalsRefresh {
  static readonly type = '[PendingApprovals] Refresh';
}

export class PendingApprovalsCreate {
  static readonly type = '[PendingApprovals] Create';

  constructor(public item: PendingApproval) { }
}

export class PendingApprovalsUpdate {
  static readonly type = '[PendingApprovals] Update';

  constructor(public id: number, public item: PendingApproval) { }
}

export class PendingApprovalsDelete {
  static readonly type = '[PendingApprovals] Delete';
  constructor(public id: number) { }
}

import { Component, Output, EventEmitter, HostListener, OnInit, ViewChild, OnDestroy, Input, OnChanges, SimpleChanges, Injector } from '@angular/core';
import { KEResource } from '../ke';
import { KESection0Component } from './ke-section0/ke-section0.component';
import { Subscription } from 'rxjs/internal/Subscription';
import { CommentsAndAttachmentsComponent } from 'src/app/controls/comments-and-attachments/comments-and-attachments.component';
import { ChecklistsService } from '../../checklists/checklists.service';
import { Observable } from 'rxjs';
import { FormStatusEnum } from 'src/app/common/enumerations/enumerations';
import { BaseComponent } from 'src/app/common/base/base.component';
import { KESectionXComponent } from './ke-sectionX/ke-sectionX.component';

@Component({
  selector: 'ke-main-panel',
  templateUrl: './ke-main-panel.component.html',
  styleUrls: ['./ke-main-panel.component.scss']
})
export class KEMainPanelComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {

  @Input() ke?: KEResource | null;
  @Output() loading = new EventEmitter<boolean>();
  @Output() requestRefresh = new EventEmitter<KEResource>();
  @Output() unselectTable = new EventEmitter<any>();
  @Output() displayDocument = new EventEmitter<string>();
  @Output() newDocument = new EventEmitter<any>();
  @Output() toggleSidePanel = new EventEmitter<any>();

  kes!: KEResource[];
  kes$!: Observable<KEResource[]>;
  kesSubs!: Subscription;

  @ViewChild(KESection0Component) private section0!: KESection0Component;
  @ViewChild('section1') private section1!: KESectionXComponent;
  @ViewChild('section2') private section2!: KESectionXComponent;
  @ViewChild('section3') private section3!: KESectionXComponent;
  @ViewChild('section4') private section4!: KESectionXComponent;
  @ViewChild('section5') private section5!: KESectionXComponent;
  @ViewChild('section6') private section6!: KESectionXComponent;
  @ViewChild(CommentsAndAttachmentsComponent) private comments!: CommentsAndAttachmentsComponent;

  windowScrolled!: boolean;

  serialNo!: string;
  showComments!: boolean;

  constructor(
    protected override injector: Injector,
    private _checklist: ChecklistsService,
  ) {
    super(injector);

  }
  ngOnChanges(changes: SimpleChanges): void {
    this.showComments = this.ke?.status != 0;
  }

  override ngOnDestroy(): void {
    this.kesSubs?.unsubscribe();
  }

  ngOnInit() {
    this.loadKEs();
  }

  loadKEs() {
    this.loading.emit(true);
    this.kes$ = this.store.select(state => state.KEnables.data);
    this.kesSubs = this.kes$.subscribe(data => {
      if (data.length) {
        this.kes = data;
        if (this.ke) {
          const status = this.ke.status;
          this.ke = this.kes.find(x => x.id == this.ke?.id);
          if (this.ke && this.ke.wfTable?.wfTableLocal) {
            if (status != this.ke.status) {
              this.alert.info(this.getMessage('formStateChanged').description.replace('{serialNo}', this.ke.serialNo).replace('{status}', FormStatusEnum[this.ke.status]));
            }
            // this._checklist.currentDocument = this.ke;
            this._checklist.setAllTasks(this.ke.wfTable.wfTableLocal.wfSectionLocals);
          }
        }
        this.loading.emit(false);
      }
    });
  }

  //#region INIT
  public refresh(e: KEResource) {
    this.loading.emit(true);
    this.ke = e;
    this.showComments = this.ke?.status > 0;
    this.loading.emit(false);
  }

  create(e?: KEResource) {
    this.ke = e;
    this.showComments = this.ke?.status != 0;
    this.section0?.create();
  }

  //#endregion

  //#region EMMITERS
  request_refresh(e: KEResource) {
    this.requestRefresh.emit(e);
    if (e?.status == 5) {
      this.scrollToTop();
    }
  }

  showDocument(e: any) {
    this.displayDocument.emit(e);
  }
  //#endregion

  //#region OPTIONS

  unselect() {
    this.unselectTable.emit();
  }

  //#endregion

  //#region SCROLL

  @HostListener('window:scroll', [])
  onWindowScroll() {
    if (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop > 100) {
      this.windowScrolled = true;
    }
    else if (this.windowScrolled && window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop < 10) {
      this.windowScrolled = false;
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.innerWidth = window.innerWidth;
  }

  //#endregion

  togglePanel(): void {
    this.toggleSidePanel.next(true);
  }

  setLoading(e: boolean) {
    const loading = this.section1?.loading || this.section2?.loading || this.section3?.loading || this.section4?.loading || this.section5?.loading || this.section6?.loading;
    this.loading.emit(loading);
  }

}

import { AppState } from './reducer';
import { UsersState } from '../components/catalogs/user-catalog/store/users.state';
import { AcceleratorStatusState } from '../components/home/accelerator-status/store/accelerator-status.state';
import { BeamlineStatusState } from '../components/home/beamline-status/store/bl-status.state';
import { BulletinBoardState } from '../components/home/bulletin-board/store/bulletin-board.state';
import { BranchlineStatusState } from '../components/home/branchline-status/store/branchline-status.state';
import { SCFMastersState } from '../components/scf-v2/store/scf-master/scf-masters.state';
import { ResourceRelationsState } from '../components/catalogs/store/resources-relations/resource-relations.state';
import { ResourcesState } from '../components/catalogs/store/resources/resources.state';
import { DocumentsState } from '../services/documents/store/documents.state';
import { RestrictionsState } from '../components/scf-v2/store/restrictions/restrictions.state';
import { RoleState } from '../components/catalogs/roles/store/role.state';
import { CommentState } from '../controls/comments-and-attachments/store/comments.state';
import { SchedulesState } from '../components/schedules/store/schedules/schedule.state';
import { ScheduleTypesState } from '../components/schedules/store/schedule-types/schedule-types.state';
import { MenuItemState } from '../components/head-page/store/menu-item.state';
import { RSSWAState } from '../components/rsswa/store/rsswa.state';
import { WorkerVerificationState } from '../controls/worker-verification/store/worker-verification/worker-verification.state';
import { TemplateTypesState } from '../components/checklists/store/template-types/template-types.state';
import { FormsState } from '../controls/form-table/store/forms.state';
import { RolePrivilegeState } from '../services/role-privilege/store/role-privilege/role-privilege.state';
import { PrivilegeCategoryState } from '../services/role-privilege/store/privilege-categories/privilege-categories.state';
import { PrivilegeState } from '../services/role-privilege/store/privilege/privilege.state';
import { APLCategoryState } from '../components/apl/store/apl-category/apl-category.state';
import { APLGroupState } from '../components/apl/store/apl-group/apl-group.state';
import { ProcedureState } from '../components/procedure/store/procedure/procedure.state';
import { KEnableState } from '../components/kenable/store/ke/kenable.state';
import { FormStatusesState } from './form-status/form-status.state';
import { ComponentsState } from '../components/checklists/store/components/components.state';
import { AbsiTemplateVersionState } from '../components/absi/store/absi-template/absi-template.state';
import { ActionItemsState } from '../controls/catl/action-item/store/actionItems.state';
import { AbsiState } from '../components/absi/store/absi/absi.state';
import { DocumentTypeState } from '../components/catalogs/document-type/store/document-type/document-type.state';
import { ChecklistsState } from '../components/checklists/store/checklists/checklists.state';
import { ResourceTypesState } from '../components/catalogs/store/resource-types/resource-types.state';
import { BeamlineShutterState } from '../components/catalogs/store/beamline-shutter/beamline-shutter.state';
import { ChecklistTemplateStatusesState } from '../components/checklists/store/checklist-status/checklist-status.state';
import { ChecklistTemplatesState } from '../components/checklists/store/checklist-templates/checklist-templates.state';
import { RadMetersState } from '../components/catalogs/meters-catalog/store/rad-meters/rad-meters.state';
import { AnnotationsState } from '../components/checklists/store/annotations/annotations.state';
import { ReviewCyclesState } from '../components/reviews/store/review-cycles/review-cycles.state';
import { ReviewPermissionsState } from '../components/reviews/store/review-permissions/review-permissions.state';
import { ReviewBoardTypesState } from '../components/reviews/store/review-board-types/review-board-types.state';
import { ReviewTypesState } from '../components/reviews/store/review-types/review-types.state';
import { ReviewBoardsState } from '../components/reviews/store/review-boards/review-boards.state';
import { ReviewStatusState } from '../components/reviews/store/review-status/review-status.state';
import { ReviewAttendanceCategoriesState } from '../components/reviews/store/review-attendance-category/review-attendance-category.state';
import { ReviewActionItemTypesState } from '../components/reviews/store/review-action-item-types/review-action-item-types.state';
import { ReviewActionItemsState } from '../components/reviews/store/review-action-items/review-action-items.state';
import { ReviewsState } from '../components/reviews/store/reviews/reviews.state';
import { ReviewAttendanceRosterState } from '../components/reviews/store/review-attendance-roster/review-attendance-roster.state';
import { ScheduleRemindersState } from '../components/schedules/store/schedule-reminder/schedule-reminder.state';
import { ProcedureTemplateState } from '../components/procedure/store/procedure-template/procedure-template.state';
import { ProcedureCreditedState } from '../components/procedure/store/procedure-credited/procedure-credited.state';
import { ProcedureConfigurationState } from '../components/procedure/store/procedure-configuration/procedure-configuration.state';
import { ProcedureCategoryState } from '../components/procedure/store/procedure-category/procedure-category.state';
import { ProcedureTrainingState } from '../components/procedure/store/procedure-training/procedure-training.state';
import { RadLocationTypeState } from '../components/catalogs/rad-monitor/store/rad-location-type/rad-location-type.state';
import { RadLocationState } from '../components/catalogs/rad-monitor/store/rad-location/rad-location.state';
import { RadInterlockState } from '../components/catalogs/rad-monitor/store/rad-interlock/rad-interlock.state';
import { RadLocationInterlockRelationState } from '../components/catalogs/rad-monitor/store/rad-interlock-relations/rad-interlock-relation.state';
import { RadMonitorState } from '../components/catalogs/rad-monitor/store/rad-monitor/rad-monitor.state';
import { PendingResourcesState } from '../components/catalogs/store/pending-resources/pending-resources.state';
import { OlogCategoryState } from '../components/catalogs/document-type/store/olog-category/olog-category.state';
import { OlogEmailState } from '../components/catalogs/document-type/store/olog-email/olog-email.state';
import { AbsiTemplateTypeState } from '../components/absi/store/absi-template-type/absi-template-type.state';
import { NavigationLinkState } from '../components/catalogs/navigation-links/store/navigation-links.state';
import { MyPendingCatalogsState } from '../components/pending-approvals-v2/store/my-pending-catalogs/my-pending-catalogs.state';
import { PendingCatalogsState } from '../components/pending-approvals-v2/store/pending-catalogs/pending-catalogs.state';
import { BratoiState } from '../components/catalogs/bratoi/store/bratoi/bratoi.state';
import { ProcedureTypeState } from '../components/procedure/store/procedure-types/procedure-types.state';
import { ScheduleSubtypesState } from '../components/schedules/store/schedule-subtypes/schedule-subtypes.state';
import { NotificationTemplateState } from '../components/catalogs/notifications/store/notification-template.state';
import { AbsiSectionState } from '../components/absi/store/absi-sections/abs-section.state';
import { TOCAState } from '../components/catalogs/bratoi/store/toca/toca.state';
import { ToxicGasState } from '../components/home/toxic-gas-panel/store/toxic-gas/toxic-gas.state';
import { BakeoutsState } from '../components/home/bakeouts-panel/store/bakeouts/bakeouts.state';
import { ReviewAttendanceSaveState } from '../components/reviews/store/review-attendance-save/review-attendance-save.state';
import { PVState } from '../services/pv-info/store/pv-info.state';
import { ReviewSatisfactionChecksState } from '../components/reviews/store/review-satisfaction-checks/review-satisfaction-checks.state';
import { KEVersionState } from '../components/kenable/store/ke-version/ke-version.state';
import { MessageBannerState } from '../components/messages/store/message-banner.state';
import { AlertState } from '../services/alert/store/alert.state';
import { TasksState } from '../components/checklists/store/tasks/task.state';
import { RadiationAreaState } from '../components/home/radiation-area/store/radiation-area.state';
import { DeletedTasksState } from '../components/checklists/store/deleted-tasks/deleted-task.state';
import { DeletedStepsState } from '../components/checklists/store/deleted-steps/deleted-step.state';
import { DeletedSectionsState } from '../components/checklists/store/deleted-sections/deleted-section.state';


export const reducers = [
  AppState,
  AcceleratorStatusState,
  BulletinBoardState,
  RadiationAreaState,
  // WFTaskReducer,
  BranchlineStatusState,
  MessageBannerState,
  ProcedureCategoryState,
  ProcedureState,
  ProcedureTrainingState,
  ProcedureCreditedState,
  ProcedureTemplateState,
  ProcedureConfigurationState,
  // ClockTimerReducer,
  // AbsiSectionState,
  ActionItemsState,
  ChecklistsState,
  RadInterlockState,
  RadLocationInterlockRelationState,
  RadLocationState,
  RadLocationTypeState,
  RadMonitorState,
  // RadMonitorDescriptionState,
  // RadMonitorTypeState,
  TemplateTypesState,
  SchedulesState,
  DocumentTypeState,
  // BranchlineState,
  BratoiState,
  MenuItemState,
  ResourcesState,
  APLCategoryState,
  AbsiTemplateTypeState,
  ReviewBoardTypesState,
  ReviewBoardsState,
  ReviewsState,
  ResourceTypesState,
  ReviewCyclesState,
  ReviewStatusState,
  ReviewTypesState,
  ReviewActionItemsState,
  ReviewActionItemTypesState,
  FormStatusesState,
  OlogCategoryState,
  OlogEmailState,
  APLGroupState,
  ReviewAttendanceCategoriesState,
  ReviewAttendanceRosterState,
  // ReviewAttendanceSaveState,
  ReviewSatisfactionChecksState,
  ReviewPermissionsState,
  RoleState,
  RolePrivilegeState,
  UsersState,
  KEnableState,
  ComponentsState,
  TOCAState,
  // LinkTypesState,
  RadMetersState,
  ScheduleTypesState,
  ScheduleSubtypesState,
  ProcedureTypeState,
  BeamlineShutterState,
  SCFMastersState,
  RestrictionsState,
  DocumentsState,
  WorkerVerificationState,
  ChecklistTemplateStatusesState,
  ScheduleRemindersState,
  ChecklistTemplatesState,
  PrivilegeState,
  PrivilegeCategoryState,
  AnnotationsState,
  // PendingApprovalsState,
  PendingCatalogsState,
  MyPendingCatalogsState,
  FormsState,
  CommentState,
  TasksState,
  ResourceRelationsState,
  PendingResourcesState,
  BeamlineStatusState,
  RSSWAState,
  AbsiTemplateVersionState,
  AbsiState,
  AbsiSectionState,
  NavigationLinkState,
  NotificationTemplateState,
  ToxicGasState,
  BakeoutsState,
  ReviewAttendanceSaveState,
  PVState,
  ReviewActionItemsState,
  ReviewActionItemTypesState,
  PVState,
  KEVersionState,
  AlertState,
  DeletedTasksState,
  DeletedStepsState,
  DeletedSectionsState
];

import { Component, Input, OnInit, OnDestroy, OnChanges, SimpleChanges, Output, EventEmitter, ViewChild, Injector } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { BaseComponent } from 'src/app/common/base/base.component';
import { Action, ComponentType, FormStatusEnum, WFSaveOptions } from 'src/app/common/enumerations/enumerations';
import { MasterControlComponent } from 'src/app/controls/checklist-components/master-control/master-control.component';
import { WfTaskLocalResource, WfSignature, WFSectionLocalResource, WFStepLocalResource, SaveTaskResource } from 'src/app/services/work-flow/work-flow';
import { Checklist } from '../../../checklists';
import { ChecklistsService } from '../../../checklists.service';
import { ChecklistsRefreshById } from '../../../store/checklists/checklists.action';
import { User } from 'src/app/components/catalogs/user-catalog/services/user';
import { DuplicateAction, TaskDuplicateResource } from 'src/app/controls/checklist-components/button-signature-duplicate/button-signature-duplicate.component';
import { TasksCreate, TasksDelete, TasksUpdate } from '../../../store/tasks/task.action';

@Component({
  selector: "checklist-task",
  templateUrl: "./checklist-task.component.html",
  styleUrls: ["./checklist-task.component.scss"],
})
export class ChecklistTaskComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {
  @Input() currentChecklist?: Checklist | null;
  @Input() task!: WfTaskLocalResource;
  @Input() disabled!: boolean;
  @Input() precheck?: boolean;

  @Output() updateTask = new EventEmitter();
  @Output() loading = new EventEmitter<boolean>();
  @Output() checkShared = new EventEmitter<any>();

  @ViewChild(MasterControlComponent)
  masterControl!: MasterControlComponent;

  checklist!: Checklist;
  checklists!: Checklist[];
  checklists$!: Observable<Checklist[]>;
  checklistsSubs!: Subscription;

  s!: WfSignature;

  color!: string;

  section?: WFSectionLocalResource;
  step?: WFStepLocalResource;

  formStatus = FormStatusEnum;
  saving!: boolean;

  wfTaskLocal?: WfTaskLocalResource;
  wfTaskLocals?: WfTaskLocalResource[];
  wfTaskLocals$!: Observable<WfTaskLocalResource[]>;
  wfTaskLocalsSubs!: Subscription;

  constructor(
    protected override injector: Injector,
    private checklistsService: ChecklistsService,
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.loadWFTaskLocals();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.initRefresh();
  }

  loadWFTaskLocals() {
    this.wfTaskLocals$ = this.store.select(state => state.WFTaskLocals.data);
    this.wfTaskLocalsSubs = this.wfTaskLocals$.subscribe(data => {
      if (data?.length) {
        this.wfTaskLocals = data;
        const task = this.wfTaskLocals.find(t => t.id == this.task.id);
        if (task) {
          this.task = task;
          this.initRefresh();
        }
      }
    });
  }

  initRefresh() {
    this.step = this.task?.wfStepLocal;
    this.section = this.step?.wfSectionLocal;
    if (this.section && this.step && this.task) {
      this.step.wfSectionLocal = this.section;
      this.task.wfStepLocal = this.step;
    }
    this.refreshSignature();
  }

  override ngOnDestroy() {
    this.checklistsSubs?.unsubscribe();
    super.ngOnDestroy();
  }

  refreshSignature(t: WfTaskLocalResource | null = null) {
    if (t)
      this.task = t;
    if (this.task) {
      this.s = this.getSignature(this.task);
    }
    this.saving = false;
  }

  getSignature(t: WfTaskLocalResource) {
    const signature = {
      id: t.id ?? 0,
      type: t.componentID,
      code: t.code,
      number: t.component?.numericHeader ? t.name : null,
      required: t.required,
      name: t.name,
      question: t.name3,
      question2: t.name2,
      question3: t.name4,
      options: this.utils.JSONparse(t.options) ? this.utils.JSONparse(t.options) : [],
      condition: t.condition,
      configuration: t.configuration,
      taskId: t.wfTasks?.[0].id,
      disabled: t.disabled || this.disabled || this.disableUser(t),
      disableEdit: t.disabled || this.disabled || this.disableUser(t),
      enabled: true,
      value: t.value,
      value2: t.numericValue,
      textValue: this.utils.getTextValue(t.text),
      roles: t.wfRoleLocals?.map(x => x.roleID),
      roleCodes: t.wfRoleLocals?.map(x => x.role?.code).join('/'),
      approve: t.wfActionLocals?.find(x => x.action == Action.Approve) != null && !t.wfTasks?.[0].signedBy,
      approved: t.wfTasks?.[0]?.signedBy ? true : false,
      approveId: t.wfActionLocals?.find((x) => x.action == Action.Approve)?.id,
      unapprove: t.wfActionLocals?.find((x) => x.action == Action.Unapprove) != null &&
        t.wfTasks?.[0].signedBy?.id == this.getCurrentUser()?.id,
      unapproveId: t.wfActionLocals?.find(x => x.action == Action.Unapprove)?.id,
      disapprove: t.wfActionLocals?.find((x) => x.action == Action.Disapprove) != null,
      disapproveId: t.wfActionLocals?.find(x => x.action == Action.Disapprove)?.id,
      approvedBy: t.wfTasks?.[0]?.signedBy as User,
      approvedOn: t.wfTasks?.[0]?.signedOn,
      visible: t.wfTasks?.[0].required,
      scheduleTypeId: t.scheduleType,
      scheduleResourceId: t.scheduleResourceId,
      booleanValue: this.getBooleanValue(t.text),
      wfTaskLocal: this.currentChecklist?.wfTable?.wfTableLocal?.wfSectionLocals.find(s => s.id == this.section?.id)?.wfStepLocals?.find(s => s.id == this.step?.id)?.wfTaskLocals?.find(x => x.id == t.id)
    };
    if (this.s?.configuration) {
      this.color = JSON.parse(this.s?.configuration).color
        ? JSON.parse(this.s?.configuration).color
        : "primary";
    }
    this.loading.emit(false);
    return signature;
  }

  disableUser(t: WfTaskLocalResource) {
    if (!this.checklistsService.participants || t.code == "PARTICIPANT") {
      if (!t.wfRoleLocals?.length) return false;
      return !this.hasRoles(t.wfRoleLocals.map(r => r.roleID));
    } else {
      return !this.checklistsService.participants.map(x => x.id).includes(this.getCurrentUser()?.id);
    }
  }

  disabledClose(s: WfSignature) {
    return s.type == ComponentType.CloseButton && !this.checklistsService.readyToClose() && this.currentChecklist?.checklistTemplate?.templateType?.allSignaturesRequired;
  }

  getBooleanValue(text?: string): boolean {
    const storedValues = this.utils.JSONparse(text);
    if (
      storedValues &&
      storedValues[0]?.Name == null &&
      storedValues[0]?.stringValue
    ) {
      const values = this.utils.JSONparse(storedValues[0].stringValue);
      if (Array.isArray(values)) {
        return values?.find((x) => x.key == "checkedYes")?.val;
      }
    }
    return false;
  }

  sign(e: any, taskId?: number, actionId?: number, isASCC: boolean = false) {
    if (taskId && actionId) {
      this.saving = true;
      this.loading.emit(true);
      this.checklistsService.sign(taskId, actionId, null, null, isASCC, e).toPromise().then(async task => {
        if (task) {
          this.task = task;
          this.task.required = true;
          this.task.isCompleted = this.utils.taskComplete(this.task);
          this.updateTask.emit(this.task);
          if (this.currentChecklist && this.task.code && task.isCompleted)
            await this.checklistsService.processTags(this.currentChecklist, this.task);
        }
        this.loading.emit(false);
      })
        .catch((error) => {
          console.log(error);
          this.alert.defaultError();
        }).finally(() =>
          setTimeout(() => {
            this.loading.emit(false);
          }, 600));
    }
  }

  duplicate(data: TaskDuplicateResource) {
    this.saving = true;
    if (data.action == DuplicateAction.Duplicate) {
      if (data.task)
        this.store.dispatch(new TasksCreate(data.task));
    }
    else {
      this.store.dispatch(new TasksDelete(data.taskID));
    }
    if (this.currentChecklist)
      this.store.dispatch(new ChecklistsRefreshById(this.currentChecklist.id));
  }

  change(e: any, id?: number) {
    if (id && this.task) {
      let saveTask: SaveTaskResource;
      switch (this.task.component?.id) {
        case ComponentType.CheckboxAndRadio:
        case ComponentType.CheckboxRadioLocation:
        case ComponentType.PlaceholderCondition: // Radio
          this.task.numericValue = e;
          if (this.task.wfTasks)
            this.task.wfTasks[0].signedBy = this.getCurrentUser(true) as User;
          saveTask = {
            id,
            numericValue: e,
            field: "numericValue",
            option: WFSaveOptions.MANUAL,
          };
          this.saveTask(saveTask, false);
          break;
        case ComponentType.RadMonitorDueDate:
          this.task.text = e;
          saveTask = {
            id,
            text: e,
            field: "text",
            option: WFSaveOptions.MANUAL,
          };
          const values = this.utils.JSONparse(e);
          this.saveTask(saveTask, false, true, values, this.s.taskId, this.s.approveId, this.s.unapproveId);
          break;
        default: // Text Input
          this.task.text = e;
          saveTask = {
            id,
            text: e,
            field: "text",
            option: WFSaveOptions.MANUAL,
          };
          this.saveTask(saveTask, false);
          break;
      }
    }
  }

  save(e: any, id?: number, taskId?: number, approveId?: number, unapproveId?: number) {
    if (id && this.task) {
      let textValue = null;
      let check = false;
      const hasRefresh = this.currentChecklist?.checklistStatus?.formStatusID == FormStatusEnum.Draft || e.hasRefresh;
      if (typeof e == 'string') {
        textValue = e as string;
      }
      else if (e.text) {
        textValue = e.text;
        check = e.sign != null
      }
      this.task.text = textValue;
      let saveTaskResource = new SaveTaskResource();
      saveTaskResource = {
        id,
        text: textValue,
        field: "text",
        option: WFSaveOptions.MANUAL,
      };
      this.saveTask(saveTaskResource, hasRefresh, check, e.sign, taskId, approveId, unapproveId);
    }
  }

  saveTask(saveTaskResource: SaveTaskResource, hasRefresh?: boolean, check?: boolean, e?: any, taskId?: number, approveId?: number, unapproveId?: number) {
    if (this.task) {
      this.saving = true;
      this.task.isCompleted = this.utils.taskComplete(this.task);
      this.checklistsService.saveWFTask(saveTaskResource, hasRefresh).toPromise().then(task => {
        if (check) {
          this.check(e, taskId, approveId, unapproveId);
        }
        else {
          if (task) {
            this.task = task;
            this.task.required = true;
            this.task.isCompleted = this.utils.taskComplete(this.task);
            this.updateTask.emit(this.task);
          }
        }
      }, error => {
        console.log(error);
        this.alert.defaultError();
      });
    }
  }

  check(e: any, taskId?: number, approveId?: number, unapproveId?: number) {
    if (taskId)
      if (e == true || e.checked == true) {
        if (approveId)
          this.sign(null, taskId, approveId, e.isASCC);
      } else {
        if (unapproveId)
          this.sign(null, taskId, unapproveId);
      }
  }

  isReviewer() {
    return this.checklistsService.isReviewer;
  }

  annotationsChanged(e: any) {
    if (e)
      this.task = e;
  }

  close() {
    if (this.currentChecklist && this.task)
      this.checklistsService.processTags(this.currentChecklist, this.task);
  }

  completed() {
    if (this.task)
      return this.utils.taskComplete(this.task);
    return false;
  }

}

export class Value {
  index!: number;
  code!: string;
  val!: boolean;
}

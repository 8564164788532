import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { Component, EventEmitter, Input, OnInit, Output, OnChanges, SimpleChanges, ViewChild, ChangeDetectorRef, Injector } from '@angular/core';
import { MatChipInputEvent } from '@angular/material/chips';
import { ClEditorTableChannelsAddComponent } from './cl-editor-table-channels-add/cl-editor-table-channels-add.component';
import { ClImageUploadTableDataService } from '../cl-image-upload-table-data.service';
import { BaseComponent } from 'src/app/common/base/base.component';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { YesNoDialogComponent } from 'src/app/controls/yes-no-dialog/yes-no-dialog.component';
import { User } from 'src/app/components/catalogs/user-catalog/services/user';
import { PVInfoService } from 'src/app/services/pv-info/pv-info.service';
import { Subscription } from 'rxjs/internal/Subscription';
import { Observable } from 'rxjs';
import { PV } from 'src/app/services/pv-info/pv-info';

@Component({
  selector: 'cl-image-upload-table-data',
  templateUrl: './cl-image-upload-table-data.component.html',
  styleUrls: ['./cl-image-upload-table-data.component.scss']
})
export class ClImageUploadTableDataComponent extends BaseComponent implements OnInit, OnChanges {

  @Input() channelsData!: string;
  @Input() editor!: boolean;
  @Input() builder!: boolean;
  @Input() channelCopy!: PVData;
  @Input() disabled!: boolean;

  @Output() changed = new EventEmitter<PVData[]>();
  @Output() channelCopied = new EventEmitter<PVData | null>();
  @Output() loading = new EventEmitter<boolean>();

  pvs?: PV[];
  pvs$!: Observable<PV[]>;
  pvsSubs!: Subscription;

  error?: boolean;

  channels: PVData[] = [];
  updating!: boolean;

  constructor(
    protected override injector: Injector,
    private service: ClImageUploadTableDataService,
    private pvService: PVInfoService
  ) {
    super(injector);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.loadPVs();
    if (this.channelsData) {
      this.channels = this.utils.JSONparse(this.channelsData);
      if (!this.channels) { this.channels = []; }
      this.channels?.map(c => c.updating = false);
      this.channels = this.channels?.sort((a, b) => a.pos - b.pos);
      this.error = this.channels.some(x => x.error);
    }
  }

  ngOnInit(): void {
  }

  loadPVs() {
    this.pvs$ = this.store.select(state => state.PV.data);
    this.pvsSubs = this.pvs$.subscribe(data => {
      if (data?.length) {
        this.pvs = data;
      }
    });
  }

  add() {
    this.channelCopied.emit(null);
    this.dialog.open(ClEditorTableChannelsAddComponent, {
      width: '500px'
    }).afterClosed().subscribe((data: PVData) => {
      if (data) {
        this.channels.push(data);
        this.changed.emit(this.channels);
      }
    });
  }

  remove(channel: PVData) {
    this.channelCopied.emit(null);
    const index = this.channels.findIndex(x => x.name == channel.name);
    this.channels.splice(index, 1);
    this.changed.emit(this.channels);
  }


  edit(channel: PVData) {
    this.channelCopied.emit(null);
    this.dialog.open(ClEditorTableChannelsAddComponent, {
      width: '500px',
      data: channel
    }).afterClosed().subscribe((data: PVData) => {
      if (data) {
        const index = this.channels.findIndex(x => x.name == data.name);
        this.channels[index] = data;
        this.changed.emit(this.channels);
      }
    });
  }

  updateChannel(channel: PVData) {
    return new Promise((resolve, reject) => {
      channel.updating = true;
      this.pvService.getPVInfo(channel.name).subscribe(data => {
        const value = data.value.toString();
        channel.value = this.service.formatValue(value);
        if (!this.editor && !this.builder) {
          channel.date = new Date();
        }
        const user = this.getCurrentUser(true);
        if (user) {
          channel.user = user;
          channel.updating = false;
          channel.error = false;
          resolve(channel);
        }
      }, error => {
        console.log(error);
        channel.error = true;
        reject(channel);
      });
    });
  }

  updateChannelsConfirm() {
    if (this.channels[0].user) {
      const yesno = this.dialog.open(YesNoDialogComponent, {
        width: '400px',
        data: {
          message: this.getMessage('PVData_refreshValuesConfirmation').description,
          icon: 'stop'
        }
      });
      yesno.afterClosed().toPromise().then(res => {
        if (res) {
          this.updateChannels();
        }
      });
    }
    else {
      this.updateChannels();
    }
  }

  updateChannels() {
    const user = this.getCurrentUser(true);
    this.updating = true;
    this.loading.emit(true);
    this.error = false;
    this.channelsUpdated = [];
    this.channels.forEach(async (channel) => {
      const pv = this.pvs?.find(p => p.pvName === channel.name);
      if (pv) {
        channel.value = this.service.formatValue(pv.value);
        channel.user = user;
        channel.error = false;
        channel.date = new Date();
        channel.updating = false;
        this.updateEnd(channel);
      } else {
        const c = await this.subscribe(channel);
        channel.value = this.service.formatValue(c.value);
        channel.error = c.error;
        channel.updating = false;
        this.updateEnd(channel);
      }
    });
  }

  channelsUpdated: PVData[] = [];
  updateEnd(channel: PVData) {
    this.channelsUpdated.push(channel);
    if (this.channelsUpdated.length == this.channels.length) {
      this.channels = this.channelsUpdated;
      this.changed.emit(this.channels);
      this.updating = false;
      this.loading.emit(false);
      this.error = this.channels.some(x => x.error);
    }
  }

  subscribe(channel: PVData): Promise<PVData> {
    return new Promise((resolve, reject) => {
      this.pvService.subscribe(channel.name).toPromise().then(data => {
        if (data) {
          channel.value = data.value;
          if (data.value === 'ERROR') {
            channel.error = true;
            this.error = true;
            this.alert.error(`Channel:<br>${channel.name} (${channel.label})<br>Is not Available. Please Try Again!`);
          } else {
            channel.error = false;
          }
          resolve(channel);
        } else {
          reject('No data received');
        }
      }).catch(error => {
        console.error('Subscription error:', error);
        reject(error);
      });
    });
  }

  copy(e: any) {
    this.channelCopied.emit(e);
  }

  paste() {
    if (!this.channels) { this.channels = []; }
    this.channels.push(this.channelCopy);
    this.changed.emit(this.channels);
    this.channelCopied.emit(null);
  }

  pasteAllowed() {
    return !this.channels || !this.channels.map(c => c.name).includes(this.channelCopy.name);
  }

  dropListDropped(event: CdkDragDrop<PVData[]>) {
    moveItemInArray(this.channels, event.previousIndex, event.currentIndex);
    this.orderChannels();
    this.changed.emit(this.channels);
  }

  orderChannels() {
    let i = 0;
    this.channels.map(s => {
      s.pos = i;
      i++;
    });
  }
}


export interface PVData {
  pos: number;
  name: string;
  label: string;
  value?: any;
  updating?: boolean;
  error?: boolean;
  date?: Date | null;
  user?: User | null;
}

<div class="pb-3 mat-table-container" #resizableElement>
  <div
    id="cloned-header-container-{{ id }}"
    class="cloned-header-container"
  ></div>
  <div id="image-table-container-{{ id }}">
    <table
      cdkDropList
      [cdkDropListData]="dataSource"
      (cdkDropListDropped)="reorderTable($event, dataSource)"
      [cdkDropListDisabled]="!editor || disabled || !dragEnabled"
      [cdkDropListOrientation]="'vertical'"
      #matTable
      mat-table
      [dataSource]="dataSource"
      (mouseleave)="tableOut()"
      id="image-table-body-{{ id }}"
    >
      <ng-container
        matColumnDef="{{ column.name }}"
        *ngFor="let column of displayedColumns; let col = index"
      >
        <th
          class="header-title"
          mat-header-cell
          *matHeaderCellDef
          [ngClass]="{
            'w-25': col == 0,
            hidden: !editor && !canEdit && column.name == 'actions',
            'w-actions': column.name == 'actions',
            'w-spotid': column.name == 'spotid',
            'w-calcdose': column.name == 'calcdose',
            'w-notes': column.name == 'notes',
            'w-data': column.name == 'data'
          }"
        >
          <div [innerHTML]="column.label"></div>
          <div *ngIf="column.name == 'actions'">Row</div>
          <div *ngIf="col == 0 && editor && !disabled">
            <button
              mat-icon-button
              color="accent"
              (click)="dragEnabled = !dragEnabled"
            >
              <mat-icon>swipe_vertical</mat-icon>
            </button>
          </div>
        </th>
        <td
          mat-cell
          *matCellDef="let element; let row = index"
          [attr.rowspan]="rowSpan(column, element, row)"
          [style.display]="rowSpan(column, element, row) === 0 ? 'none' : ''"
          [ngClass]="{
            tgray:
              col == 0 ||
              (col > columnsBuilder.length &&
                (column.name == 'spotid' ||
                  column.name == 'calcdose' ||
                  column.name == 'actions')),
            tgreen: col <= columnsBuilder.length && col > 0 && colorize,
            tyellow:
              col > columnsBuilder.length &&
              column.name != 'actions' &&
              column.name != 'spotid' &&
              colorize,
            selected: selectedCellsState[row][col],
            unselected: !selectedCellsState[row][col],
            hidden: !editor && !canEdit && column.name == 'actions',
            'w-40': column.name == 'actions',
            'w-20 relative': column.name == 'spotid',
            'w-data': column.name == 'data',
            'w-10p':
              column.name != 'data' &&
              column.name != 'spotid' &&
              column.name != 'actions' &&
              column.name != 'id',
            text:
              canEdit &&
              col > columnsBuilder.length &&
              column.name != 'spotid' &&
              column.name != 'data' &&
              column.name != 'actions' &&
              column.name != 'calcdose'
          }"
          (click)="click(row, col, column.name)"
          (touch)="click(row, col, column.name)"
        >
          <span
            *ngIf="col == 0"
            class="d-flex justify-content-center align-items-center full-height full-width"
          >
            <span
              class="drag-handler pointer pr-2"
              *ngIf="editor && !disabled && dragEnabled"
            >
              <mat-icon cdkDragHandle color="accent">reorder</mat-icon>
            </span>
            <span [innerHTML]="columnValue(column, element)"></span>
          </span>

          <div
            id="data-{{ row }}-{{ col }}"
            class="column-data"
            *ngIf="
              !selectedCellsState[row][col] &&
              column.name != 'spotid' &&
              column.name != 'data' &&
              column.name != 'calcdose' &&
              column.name != 'actions' &&
              col > 0
            "
            [innerHTML]="element[column.name] ?? ''"
          ></div>

          <div
            id="data-{{ row }}-{{ col }}-fixed"
            class="column-data-fixed"
            *ngIf="
              !selectedCellsState[row][col] &&
              column.name != 'spotid' &&
              column.name != 'data' &&
              column.name != 'calcdose' &&
              column.name != 'actions' &&
              col > 0
            "
            [innerHTML]="element[column.name] ?? ''"
          ></div>

          <div
            #textarea
            class="small quill-container"
            *ngIf="
              selectedCellsState[row][col] &&
              column.name != 'actions' &&
              column.name != 'spotid' &&
              column.name != 'calcdose' &&
              column.name != 'id' &&
              column.name != 'data'
            "
          >
            <quill-editor
              [(ngModel)]="element[column.name]"
              theme="bubble"
              class="ql-edit"
              placeholder=""
              [modules]="quillConfig"
              (keyup)="textChanged($event, column, row, col)"
              (onEditorCreated)="editorCreated($event)"
            ></quill-editor>
            <!--        (onBlur)="blurTable(row, col)"         -->
          </div>

          <div
            *ngIf="column.name == 'data'"
            class="data-cell-container"
            [ngClass]="{
              'data-cell-updated':
                channelsUpdating == row || checkUpdatedTime(element.data)
            }"
          >
            <cl-image-upload-table-data
              class="data-cell"
              (changed)="channelsChanged($event, column, row, col)"
              [channelCopy]="channelCopy"
              [channelsData]="element.data"
              [editor]="editor && !disabled"
              [disabled]="!canEdit || channelsUpdating > 0 || disabled"
              [builder]="builder"
              (channelCopied)="channelCopied($event)"
              (loading)="channelsUpdating = $event ? row : 0"
            ></cl-image-upload-table-data>
          </div>

          <!-- Calculated Dose Rate -->
          <div
            *ngIf="column.name == 'calcdose'"
            class="full-height d-flex justify-content-center"
          >
            <div
              [ngClass]="{
                'warn-high':
                  +calculateDose(element.reading, element.time) >= 3.5
              }"
              [innerHTML]="calculateDose(element.reading, element.time)"
              [matTooltip]="
                +calculateDose(element.reading, element.time) >= 3.5
                  ? 'Rad Area Required'
                  : ''
              "
            ></div>
          </div>

          <!-- SPOT -->
          <div *ngIf="column.name == 'spotid'" id="{{ element.rowId }}">
            <div
              [ngClass]="{
                'spot-blue': spotColor(element) == spotClasses.BLUE,
                'spot-red': spotColor(element) == spotClasses.RED,
                'no-spot': !element.spotid,
                'spot-text':
                  (editor && !disabled) ||
                  (!builder &&
                    canEdit &&
                    spotColor(element) == spotClasses.BLUE)
              }"
              [innerHTML]="element.rowId"
            ></div>
            <div
              *ngIf="
                (editor || (!builder && canEdit)) &&
                !addingSpot &&
                currentRow == -1 &&
                !element.spotid &&
                !disabled
              "
              class="small btn-spot"
              [ngClass]="{
                actions: !addingSpot && (editor || (!builder && canEdit)),
                'spot-btn-visible':
                  (addingSpot && (editor || (!builder && canEdit))) ||
                  (utils.isMobileDevice() && canEdit)
              }"
              [hidden]="verifyActions(element.id)"
            >
              <mat-icon
                color="primary"
                class="small-icon"
                (click)="addSpot(element)"
                [matTooltip]="'Add New Spot'"
                >add_location_alt</mat-icon
              >
            </div>
            <div
              *ngIf="
                ((editor && !disabled) ||
                  (!builder &&
                    canEdit &&
                    currentRow == -1 &&
                    spotColor(element) == spotClasses.BLUE)) &&
                !addingSpot &&
                !disabled
              "
              class="small btn-spot"
              [ngClass]="{
                actions:
                  !addingSpot &&
                  (editor ||
                    (!builder &&
                      canEdit &&
                      spotColor(element) == spotClasses.BLUE)),
                'spot-btn-visible':
                  (addingSpot &&
                    (editor ||
                      (!builder &&
                        canEdit &&
                        spotColor(element) == spotClasses.BLUE))) ||
                  (utils.isMobileDevice() && canEdit)
              }"
              [hidden]="element.spotid == 0"
            >
              <mat-icon
                color="warn"
                class="small-icon"
                (click)="deleteSpot(element)"
                [matTooltip]="'Delete Spot'"
                >wrong_location</mat-icon
              >
            </div>
          </div>

          <!-- ACTIONS -->
          <div
            class="d-flex flex-column justify-content-between"
            *ngIf="column.name == 'actions' && (true || !addingSpot)"
          >
            <div
              *ngIf="
                ((editor && !disabled) || !builder) &&
                currentRow == -1 &&
                element.idSpan
              "
              [ngClass]="{
                actions: !addingSpot,
                'actions-visible': addingSpot || utils.isMobileDevice()
              }"
              class="small"
            >
              <mat-icon
                color="primary"
                class="small-icon"
                (click)="addRow(element)"
                [matTooltip]="'Add new Row to Item ' + element.id"
                >playlist_add</mat-icon
              >
            </div>

            <div
              *ngIf="
                ((editor && !disabled) || !builder) &&
                !element.spotid &&
                currentRow == -1 &&
                !element.idSpan
              "
              [ngClass]="{
                actions: !addingSpot,
                'actions-visible': addingSpot || utils.isMobileDevice()
              }"
              class="small"
            >
              <mat-icon
                color="warn"
                class="small-icon"
                (click)="deleteRow(element)"
                [matTooltip]="'Delete Row'"
                >playlist_remove</mat-icon
              >
            </div>
            <div
              *ngIf="editor && element.idSpan && !disabled"
              [ngClass]="{
                actions: !addingSpot,
                'actions-visible': addingSpot || utils.isMobileDevice()
              }"
              class="small"
            >
              <mat-icon
                color="primary"
                class="small-icon"
                (click)="duplicateRow(element)"
                [matTooltip]="'Duplicate entire row ' + element.id"
                >content_copy</mat-icon
              >
            </div>
          </div>
        </td>
      </ng-container>

      <tr
        mat-header-row
        *matHeaderRowDef="columns"
        id="image-table-header-{{ id }}"
      ></tr>
      <tr
        mat-row
        cdkDrag
        [cdkDragData]="row"
        [cdkDragDisabled]="disabled || !editor || !dragEnabled"
        cdkDragLockAxis="y"
        class="table-row"
        *matRowDef="let row; columns: columns"
        [ngClass]="{ 'thick-bottom-border': row.last }"
      >
        <td>xxx</td>
      </tr>
    </table>
  </div>
</div>

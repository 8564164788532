import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ResourceSummary } from './resources';

@Injectable({
  providedIn: 'root'
})
export class ResourceSummaryService {
  private api = environment.urls.baseApiUrl + '/ResourcesSummary';
  constructor(private http: HttpClient) { }

  GetResourceSummaryByShutter(shutterId: number) {
    return this.http.get<ResourceSummary>(this.api + '/GetResourceSummaryByShutter/' + shutterId);
  }

  GetBLSciAndAltBLSciEmailsByShutter(shutterId: number) {
    return this.http.get<string[]>(this.api + '/GetBLSciAndAltBLSciEmailsByShutter/' + shutterId);
  }

  async GetEmailsFromShuttersArray(shuttersIds: number[]): Promise<string[]> {
    const res: string[] = [];
    for (const shutterId of shuttersIds) {
      const emails = await this.GetBLSciAndAltBLSciEmailsByShutter(shutterId).toPromise();
      if (emails)
        for (const mail of emails) {
          const splited = mail.split(',');
          Array.prototype.push.apply(res, splited);
        }
    }
    return res;
  }
}

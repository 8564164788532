import { Injectable, Injector } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { BaseService } from 'src/app/common/base/base.service';
import { ActionPendingCatalog, StatusPendingCatalog } from 'src/app/common/enumerations/enumerations';
import { PendingCatalogs, PendingCatalog } from 'src/app/services/pending-approvals/pending-approval';

@Injectable({
  providedIn: 'root'
})
export class PendingApprovalsV2Service extends BaseService<PendingCatalogs, number> {

  public pendingCatalogsSubject!: BehaviorSubject<PendingCatalogs | null>;

  pendingApprovals!: PendingCatalogs;
  myPendingChanges!: PendingCatalogs;

  pendingCatalogs$!: Observable<PendingCatalogs>;
  pendingCatalogsSubs!: Subscription;

  catalogNames: string[] = [
    'None',
    'Beamline',
    'Users',
    'Role',
    'Accelerator',
    'Notifications',
    'Privileges',
    'Trainings'
  ];

  constructor(
    protected override injector: Injector,
  ) {
    super(injector, 'PendingApprovals');
    this.pendingCatalogsSubject = new BehaviorSubject<PendingCatalogs | null>(null);
    this.loadPendingApprovals();
  }

  override currentUserUpdated(): void {
    this.loadPendingApprovals();
  }

  loadPendingApprovals() {
    this.pendingCatalogs$ = this.store.select(state => state.PendingCatalogs.data);
    this.pendingCatalogsSubs = this.pendingCatalogs$.subscribe(data => {
      if (data) {
        const p: PendingCatalogs = {
          pendingAccelerators: this.getMyApprovals(data.pendingAccelerators ?? []),
          pendingUsers: this.getMyApprovals(data.pendingUsers ?? []),
          pendingRoles: this.getMyApprovals(data.pendingRoles ?? []),
          pendingNotificationTemplates: this.getMyApprovals(data.pendingNotificationTemplates ?? []),
          pendingResources: this.getMyApprovals(data.pendingResources ?? []),
          pendingRSSDb: this.getMyApprovals(data.pendingRSSDb ?? [])
        };
        p.total =
          (p.pendingAccelerators?.length ?? 0) +
          (p.pendingUsers?.length ?? 0) +
          (p.pendingRoles?.length ?? 0) +
          (p.pendingResources?.length ?? 0) +
          (p.pendingRSSDb?.length ?? 0);
        this.pendingApprovals = p;

        const q: PendingCatalogs = {
          pendingAccelerators: this.getMyChanges(data.pendingAccelerators ?? []),
          pendingUsers: this.getMyChanges(data.pendingUsers ?? []),
          pendingRoles: this.getMyChanges(data.pendingRoles ?? []),
          pendingNotificationTemplates: this.getMyChanges(data.pendingNotificationTemplates ?? []),
          pendingResources: this.getMyChanges(data.pendingResources ?? []),
          pendingRSSDb: this.getMyChanges(data.pendingRSSDb ?? [])
        };
        q.total =
          (q.pendingAccelerators?.length ?? 0) +
          (q.pendingUsers?.length ?? 0) +
          (q.pendingRoles?.length ?? 0) +
          (q.pendingResources?.length ?? 0) +
          (q.pendingRSSDb?.length ?? 0);
        this.myPendingChanges = q;
        this.pendingCatalogsSubject.next(data);
      }
    });
  }

  private setNames(data: PendingCatalog[]) {
    data?.map(x => {
      x.actionName = ActionPendingCatalog[x.action];
      x.statusName = StatusPendingCatalog[x.status];
    });
    return data;
  }

  private getMyChanges(data: PendingCatalog[]) {
    return this.setNames(data?.filter(x => x.createdBy == this.currentUser?.id && x.show && x.statusBy != this.currentUser.id));
  }

  private getMyApprovals(data: PendingCatalog[]) {
    return this.setNames(data?.filter(x => x.createdBy != this.currentUser?.id && x.status == StatusPendingCatalog.Pending));
  }
}

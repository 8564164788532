import { PendingResourcesType } from 'src/app/common/enumerations/enumerations';
import { AcceleratorCatalog } from '../../components/catalogs/accelerator-catalog/services/accelerator-catalog';
import { User } from '../../components/catalogs/user-catalog/services/user';
import { ModifyingResult } from '../../components/catalogs/services/catalog-model';

export class PendingApproval {
  public roleID!: number;
  public roleName!: string;
  public form!: number;
  public formID!: number;
  public serialNo!: string;
  public status!: number;
  public statusText!: string;
  public date!: Date;
  public action!: number;
  public step!: number;
  public disapproveReason!: string;
  public wfTableId!: number;
  public userID!: number;
}

export class PendingAccelerator {
  public id!: number;
  public acceleratorId!: number;
  public acceleratorName!: string;
  public createdBy!: number;
  public statusBy!: number;
  public createdOn!: Date;
  public statusOn!: Date;
  public disapprovalReason!: string;
  public acceleratorData!: string;
  public acceleratorCatalog!: AcceleratorCatalog;
  public status!: number;
  public action!: number;
  public statusName!: string;
  public actionName!: string;
  public userCreatedBy?: User;
  public userStatusBy?: User;
}

export class PendingResource {
  public id!: number;
  public resourceId!: number;
  public resourceName!: string;
  public beamlineName!: string;
  public shutterName!: string;
  public createdBy!: number;
  public statusBy!: number;
  public createdOn!: Date;
  public statusOn!: Date;
  public disapprovalReason!: string;
  public resourceData!: string;
  public resourceTypeId!: number;
  public status!: number;
  public action!: number;
  public statusName!: string;
  public actionName!: string;
  public userCreatedBy?: User;
  public type!: PendingResourcesType;
}

export class PendingUser {
  public id!: number;
  public userId!: number;
  public userName?: string;
  public createdBy!: number;
  public statusBy!: number;
  public createdOn!: Date;
  public statusOn!: Date;
  public disapprovalReason!: string;
  public userData!: string;
  public status!: number;
  public action!: number;
  public statusName!: string;
  public actionName!: string;
  public userCreatedBy!: User;
}

export class PendingRole {
  public id!: number;
  public name!: string;
  public roleId!: number;
  public roleName!: string;
  public roleCode!: string;
  public createdBy!: number;
  public statusBy!: number;
  public createdOn!: Date;
  public statusOn!: Date;
  public disapprovalReason!: string;
  public roleData!: string;
  public status!: number;
  public action!: number;
  public statusName!: string;
  public actionName!: string;
  public userCreatedBy!: User;
  public show?: boolean;
  public modifyingResult!: ModifyingResult;
  public changes!: string;
}

export class PendingNotificationTemplate {
  public id!: number;
  public notificationTemplateId!: number;
  public notificationTemplateName!: string;
  public notificationTemplateCode!: string;
  public notificationTemplateMessageSubject!: string;
  public notificationTemplateMessageBody!: string;
  public notificationTemplateSignature!: string;
  public createdBy!: number;
  public statusBy!: number;
  public createdOn!: Date;
  public statusOn!: Date;
  public disapprovalReason!: string;
  public notificationTemplateData!: string;
  public status!: number;
  public action!: number;
  public statusName!: string;
  public actionName!: string;
  public userCreatedBy!: User;
}

export class PendingCatalogs {
  public pendingAccelerators!: PendingAccelerator[];
  public pendingResources!: PendingResource[];
  public pendingUsers!: PendingUser[];
  public pendingRoles!: PendingRole[];
  public pendingNotificationTemplates!: PendingNotificationTemplate[];
}

export class detailsUsers {
  approverUserName?: string;
  updateUserName?: string;
  approvedOn?: Date;
  updatedOn?: Date;
}

export class PendingResourceUsers {
  approverId?: number;
  updateById?: number;
  approvedOn?: Date;
  updatedOn?: Date;
}

import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { LinkComponent } from 'src/app/components/catalogs/beamline-catalog/dialogs/link/link.component';
import { Link } from 'src/app/components/catalogs/navigation-links/link/link';
import { ResourcesService } from 'src/app/components/catalogs/beamline-catalog/resource/resources.service';

@Component({
  selector: 'app-resource-link-relation',
  templateUrl: './resource-link-relation.component.html',
  styleUrls: ['./resource-link-relation.component.scss']
})
export class ResourceLinkRelationComponent implements OnInit, OnChanges {
  @Input() isEdition!: boolean;
  @Input() dialogTitle!: string;
  @Input() componentTitle!: string;
  @Input() typeLink!: number;
  @Input() links!: Link[];
  @Input() resourceId?: number;
  @Input() changesAvailable!: boolean;
  @Input() loading!: boolean;
  @Output() changed = new EventEmitter();

  public displayedColumnsLink: string[] = ['hyperlink', 'webAddress'];
  public dataSourceLink: MatTableDataSource<Link> = new MatTableDataSource();

  public showChanges!: boolean;

  constructor(
    private dialog: MatDialog,
    private resourceService: ResourcesService
  ) { }

  ngOnInit() {
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (changes['links']?.currentValue) {
      this.dataSourceLink.data = changes['links'].currentValue;
      await this.addShowChanges();
    }
    if (changes['isEdition']?.currentValue) {
      this.create();
    } else { this.remove(); }
  }

  create() {
    this.displayedColumnsLink.push('add');
  }

  remove() {
    this.removeColumnOfDisplayColumns(this.displayedColumnsLink, 'add');
  }

  async addShowChanges() {
    this.removeColumnOfDisplayColumns(this.displayedColumnsLink, 'showChanges');
    this.showChanges = false;
    let linkChanges: Link[] = [];
    if (this.changesAvailable && this.resourceId && this.typeLink) {
      linkChanges = (await this.resourceService.getLinkChangesByResourceId(this.resourceId, this.typeLink, true).toPromise()) ?? [];
      if (this.dataSourceLink.data.some(x => x.status === 5)) {
        if (linkChanges.some(x => x.status === 0) || linkChanges.length !== this.dataSourceLink.data.length) {
          this.displayedColumnsLink.push('showChanges');
        }
      }
      else if (linkChanges.some(x => x.status === 0) && linkChanges.filter(x => x.status === 0).length !== this.dataSourceLink.data.length) {
        this.displayedColumnsLink.push('showChanges');
      }
    }
  }

  openAddLinkDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      link: new Link(),
      dialogTitle: 'Add New ' + this.dialogTitle,
      linkType: this.typeLink
    };
    const dialogRef = this.dialog.open(LinkComponent, dialogConfig);
    dialogRef.afterClosed().toPromise().then((data: Link) => {
      if (data) {
        this.dataSourceLink.data.push(data);
        this.dataSourceLink.filter = '';
        this.changed.emit();
      }
    });
  }

  openEditLinkDialog(link: Link) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      link,
      dialogTitle: 'Edit ' + this.dialogTitle,
      linkType: this.typeLink
    };
    const dialogRef = this.dialog.open(LinkComponent, dialogConfig);
    dialogRef.afterClosed().toPromise().then(data => {
      if (data)
        this.changed.emit();
    });
  }

  deleteLink(data: Link) {
    const index = this.dataSourceLink.data.indexOf(data);
    this.dataSourceLink.data.splice(index, 1);
    this.dataSourceLink.filter = '';
  }

  removeColumnOfDisplayColumns(displayColumn: string[], columnRemove: string) {
    if (displayColumn.includes(columnRemove)) {
      const index = displayColumn.indexOf(columnRemove);
      displayColumn.splice(index, 1);
    }
  }

  changesLinks() {
    if (this.resourceId)
      this.resourceService.getLinkChangesByResourceId(this.resourceId, this.typeLink, this.showChanges).toPromise().then(links => {
        this.dataSourceLink = new MatTableDataSource(links);
      });
  }

}

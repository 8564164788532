import { Component, OnInit, OnChanges, OnDestroy, ElementRef, ViewChild, Injector, ChangeDetectorRef } from "@angular/core";
import { ClBaseComponent } from "../cl-base/cl-base.component";

@Component({
  selector: "cl-checkbox-and-text-input",
  templateUrl: "./cl-checkbox-and-text-input.component.html",
  styleUrls: ["./cl-checkbox-and-text-input.component.scss"],
})
export class ClCheckboxAndTextInputComponent extends ClBaseComponent implements OnInit, OnChanges, OnDestroy {

  editing?: boolean;
  oldValue?: string | null;

  checkboxDisable = true;

  @ViewChild('txtInput', { static: false }) txtInput!: ElementRef;

  ngOnChanges() {
    this.initials = this.user ? this.user.initials : "";
    this.textValue = this.textValue?.trim();
    this.checkboxDisable = (!this.hasRoles(this.roleIds) || !this.textValue || this.disabled) ?? false;
  }

  ngOnInit() { }

  enableEdit() {
    if ((!this.disabled && !this.checked) || !this.disableEdit) {
      this.editing = !this.disableEdit;
    }
    this.edit();
  }

  edit() {
    if (this.editing) {
      this.oldValue = this.textValue;
      if (this.textValue == '<b class="red">Error!</b>') this.textValue = '';
      this.textValue = this.textValue ? this.textValue.replace("<sup>", "^") : null;
      setTimeout(() => {
        this.txtInput.nativeElement.focus();
      }, 200);
    }
  }

  checkboxChanged(e: any) {
    this.checked = e.checked;
    if (this.checked) {
      this.initials = this.checked ? this.getCurrentUser()?.initials : null;
      this.disableEdit = true;
    } else {
      this.initials = null;
      this.disableEdit = false;
    }
    this.answered.emit(this.checked);
  }


  keyup(e: any) {
    const code = e.code;
    if (code == "Enter") {
      this.textChanged();
    }
  }

  textChanged() {
    this.editing = false;
    this.textValue = this.textValue?.trim();
    if (this.textValue) {
      this.checkboxDisable = (!this.hasRoles(this.roleIds) || !this.textValue || this.disabled) ?? false;
      this.initials = this.getCurrentUser()?.initials;
      this.checked = true;
    } else {
      this.checkboxDisable = true;
      this.checked = false;
      this.initials = null;
    }
    this.changed.emit(this.textValue);
  }

  cancelEdit() {
    this.editing = false;
    this.textValue = this.oldValue;
    this.oldValue = null;
    this.textValue = this.textValue
      ? this.textValue.replace("^", "<sup>")
      : null;
  }

  inputBlurred() {
    if (this.textValue?.replace("^", "<sup>") == this.oldValue || !this.textValue) {
      this.cancelEdit();
    }

  }

}

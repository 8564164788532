import { Component, EventEmitter, Input, OnChanges, OnInit, Output, OnDestroy, Injector } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { Role } from 'src/app/components/catalogs/roles/services/role';
import { RoleService } from 'src/app/components/catalogs/roles/services/role.service';
import { TemplateType, TemplateRolePermission, ChecklistPriv } from '../../../checklists';
import { ChecklistBuilderService } from '../../checklist-builder.service';
import { DocumentType } from 'src/app/services/documents/documents';
import { utils } from 'src/app/modules/libs/utils';
import { BaseComponent } from 'src/app/common/base/base.component';

@Component({
  selector: 'checklist-builder-settings-permissions',
  templateUrl: './checklist-builder-settings-permissions.component.html',
  styleUrls: ['./checklist-builder-settings-permissions.component.scss']
})
export class ChecklistBuilderSettingsPermissionsComponent extends BaseComponent implements OnChanges, OnInit, OnDestroy {

  @Input() templateType?: TemplateType | null;
  @Input() documentType!: DocumentType;
  @Output() refresh = new EventEmitter();
  @Output() loading = new EventEmitter<boolean>();

  allRoles!: Role[];

  documentTypes$!: Observable<DocumentType[]>;
  documentTypes!: DocumentType[];

  documentTypeExec?: DocumentType;

  addRolePermissions: TemplateRolePermission[] = [];
  addRoleCtrl = new FormControl();
  addRoles?: Role[];

  apprRolePermissions: TemplateRolePermission[] = [];
  apprRoles?: Role[];
  apprRoleCtrl = new FormControl();

  chklRolePermissions: TemplateRolePermission[] = [];
  chklRoles?: Role[];
  chklRoleCtrl = new FormControl();

  sameToggle!: boolean;
  templateTypes$!: Observable<TemplateType[]>;
  templateTypes: TemplateType[] = [];

  subsTemplateTypes!: Subscription;
  subsDocumentTypes!: Subscription;

  saving!: boolean;

  constructor(
    protected override injector: Injector,
    private _role: RoleService,
    private _blds: ChecklistBuilderService,
  ) {
    super(injector);
  }

  override ngOnDestroy(): void {
    this.subsDocumentTypes?.unsubscribe();
    this.subsTemplateTypes?.unsubscribe();
  }

  ngOnInit() {
    this.loading.emit(true);
    this._role.Read().subscribe(data => {
      this.allRoles = data;
      this.loadTemplateTypes();
      this.loadDocumentTypes();
    });
    this._blds.getTemplateRolePermissionsByDocumentTypeID(this.documentType.id).subscribe(data => {
      this.addRolePermissions.push.apply(this.addRolePermissions, data.filter(x => x.checklistPrivilege == ChecklistPriv.Prepare && x.templateTypeID == 0));
      this.addRoles = this.addRolePermissions.map(x => x.role);
      this.apprRolePermissions.push.apply(this.apprRolePermissions, data.filter(x => x.checklistPrivilege == ChecklistPriv.Approve && x.templateTypeID == 0));
      this.apprRoles = this.apprRolePermissions.map(x => x.role);
      this.chklRolePermissions.push.apply(this.chklRolePermissions, data.filter(x => x.checklistPrivilege == ChecklistPriv.CreateChecklist && x.templateTypeID == 0));
      this.chklRoles = this.chklRolePermissions.map(x => x.role);
      // this.sortRoles();
      this.loading.emit(false);
    });
  }

  loadDocumentTypes() {
    this.documentTypes$ = this.store.select(state => state.DocumentType.data);
    this.subsDocumentTypes = this.documentTypes$.subscribe(data => {
      this.documentTypes = data;
      const documentTypeExec = this.documentTypes.find(x => x.id == this.documentType.type);
      if (documentTypeExec) this.documentTypeExec = documentTypeExec;
    });
  }

  loadTemplateTypes() {
    this.templateTypes$ = this.store.select(x => x.TemplateTypes.data);
    this.subsTemplateTypes = this.templateTypes$.subscribe(data => {
      this.templateTypes = data.filter(x => x.documentTypeID == this.documentType.id).sort((a, b) => utils.sort(a.order, b.order));
      this.sameToggle = this.templateTypes[0]?.samePersonCanApprove ?? false;
    });
  }

  ngOnChanges() {
    if (this.templateType?.id) {
      this.addRolePermissions = this.templateType.templateRolePermissions?.filter(x => x.checklistPrivilege == 1 && x.templateTypeID == this.templateType?.id) ?? [];
      this.apprRoles = this.templateType.templateRolePermissions?.filter(x => x.checklistPrivilege == 9 && x.templateTypeID == this.templateType?.id) ?? [];
      this.chklRoles = this.templateType.templateRolePermissions?.filter(x => x.checklistPrivilege == 11 && x.templateTypeID == this.templateType?.id) ?? [];
      // this.sortRoles();
      this.sameToggle = this.templateType.samePersonCanApprove ?? false;
    }
  }

  selectedAddRole(e: Role) {
    this.saving = true;
    const value = e;
    const templateRolePermission: TemplateRolePermission = {
      id: 0,
      role: value,
      roleID: value.id,
      checklistPrivilege: ChecklistPriv.Prepare,
      templateTypeID: this.templateType != undefined ? this.templateType.id : 0,
      documentTypeID: this.documentType.id,
      status: 1,
      type: this.templateType != undefined ? this.templateType.type : 0
    };
    this._blds.createTemplateRolePersmission(templateRolePermission).subscribe(data => {
      this.addRolePermissions.push(data);
      this.refresh.emit();
      this.saving = false;
    });
  }

  removeAddRole(role: Role) {
    this.saving = true;
    const permission = this.addRolePermissions.find(x => x.roleID == role.id);
    if (permission)
      this._blds.deleteTemplateRolePermission(permission).subscribe(data => {
        this.addRolePermissions.splice(this.addRolePermissions.findIndex(x => x.roleID == role.id), 1);
        this.refresh.emit();
        this.saving = false;
      });
  }

  selectedApprRole(e: Role) {
    this.saving = true;
    const value = e;
    const templateRolePermission: TemplateRolePermission = {
      id: 0,
      role: value,
      roleID: value.id,
      checklistPrivilege: ChecklistPriv.Approve,
      templateTypeID: this.templateType != undefined ? this.templateType.id : 0,
      documentTypeID: this.documentType.id,
      status: 1,
      type: this.templateType != undefined ? this.templateType.type : 0
    };
    this._blds.createTemplateRolePersmission(templateRolePermission).subscribe(data => {
      this.apprRolePermissions.push(data);
      this.refresh.emit();
      this.saving = false;
    });
  }

  removeApprRole(role: Role) {
    this.saving = true;
    const permission = this.apprRolePermissions.find(x => x.roleID == role.id);
    if (permission)
      this._blds.deleteTemplateRolePermission(permission).subscribe(data => {
        this.apprRolePermissions.splice(this.apprRolePermissions.findIndex(x => x.roleID == role.id), 1);
        this.refresh.emit();
        this.saving = false;
      });
  }

  selectedChklRole(e: Role) {
    this.saving = true;
    const value = e;
    const templateRolePermission: TemplateRolePermission = {
      id: 0,
      role: value,
      roleID: value.id,
      checklistPrivilege: ChecklistPriv.CreateChecklist,
      templateTypeID: this.templateType != undefined ? this.templateType.id : 0,
      documentTypeID: this.documentType.id,
      status: 1,
      type: this.templateType != undefined ? this.templateType.type : 0
    };
    this._blds.createTemplateRolePersmission(templateRolePermission).subscribe(data => {
      this.chklRolePermissions.push(data);
      this.refresh.emit();
      this.saving = false;
    });
  }

  removeChklRole(role: Role) {
    this.saving = true;
    const permission = this.chklRolePermissions.find(x => x.roleID == role.id);
    if (permission)
      this._blds.deleteTemplateRolePermission(permission).subscribe(data => {
        this.chklRolePermissions.splice(this.chklRolePermissions.findIndex(x => x.roleID == role.id), 1);
        this.refresh.emit();
        this.saving = false;
      });
  }

  sameToggleChanged(e: any) {
    this.saving = true;
    this.templateTypes.map((t, i) => {
      t.samePersonCanApprove = e.checked;
      t.checklistTemplates = [];
      this._blds.updateTemplateType(t).subscribe(data => {
        this.templateTypes[this.templateTypes.findIndex(x => x.id == t.id)] = data;
        if (i == this.templateTypes.length - 1) {
          this.saving = false;
        }
      });
    });
  }

  ///////
  removable(templateRolePermission: TemplateRolePermission) {
    return this.templateType?.id == templateRolePermission.templateTypeID || this.templateType?.id == undefined;
  }

}

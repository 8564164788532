import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import * as DeletedStepsActions from './deleted-step.action';
import { GeneralSignalRResponse } from 'src/app/services/signal-r/general-signal-r-response';
import { SignalRService } from 'src/app/services/signal-r/signal-r.service';
import { SignalRAction } from 'src/app/services/signal-r/signalr-enums';
import { Channels } from 'src/app/store/channels';

@Injectable({
  providedIn: 'root'
})
export class DeletedStepsStoreAndListenersService {
  constructor(private store: Store, private signalRService: SignalRService) { }

  initListeners() {
    this.signalRService.hubConnection?.on(Channels.DELETED_STEPS, (data: GeneralSignalRResponse) => {
      switch (data.action) {
        // case SignalRAction.Add:
        //   this.store.dispatch(new DeletedStepsActions.DeletedStepsCreate(data.obj));
        //   break;

        // case SignalRAction.Delete:
        //   this.store.dispatch(new DeletedStepsActions.DeletedStepsDelete(data.id));
        //   break;

        case SignalRAction.Get:
          this.store.dispatch(new DeletedStepsActions.DeletedStepsRefresh(data.id));
          break;
      }
    });
  }
}

import { Component, EventEmitter, Injector, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { Checklist } from '../../checklists';
import { BaseComponent } from 'src/app/common/base/base.component';
import { Observable, Subscription } from 'rxjs';
import { ChecklistsRefreshById } from '../../store/checklists/checklists.action';

@Component({
  selector: 'checklist-exec-standalone',
  templateUrl: './checklist-exec-standalone.component.html',
  styleUrls: ['./checklist-exec-standalone.component.scss']
})
export class ChecklistExecStandaloneComponent extends BaseComponent implements OnChanges, OnDestroy {

  @Input() currentChecklist?: Checklist;
  @Input() hideToggle?: boolean;

  @Output() loading = new EventEmitter<any>();

  checklists?: Checklist[];
  checklistsFiltered?: Checklist[];
  checklists$!: Observable<Checklist[]>;
  checklistsSubs!: Subscription;

  constructor(
    protected override injector: Injector,
  ) {
    super(injector);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.loadChecklists();
  }

  override ngOnDestroy(): void {
    this.checklistsSubs?.unsubscribe();
  }

  loadChecklists() {
    this.checklists$ = this.store.select(state => state.Checklists.data);
    this.checklistsSubs = this.checklists$.subscribe(data => {
      if (data?.length) {
        this.checklists = data;
        if (this.currentChecklist) {
          var checklist = this.checklists.find(c => c.id == this.currentChecklist?.id);
          if (!checklist?.wfTable) {
            this.store.dispatch(new ChecklistsRefreshById(this.currentChecklist.id));
          }
          else if (checklist)
            this.currentChecklist = checklist;
        }
      }
    });
  }

}

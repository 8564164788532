<div class="map-container small">
  <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
    <!-- This is the tree node template for leaf nodes -->
    <mat-tree-node
      *matTreeNodeDef="let node"
      matTreeNodePadding
      (click)="scrollToNode(node)"
      [matTooltip]="node.name"
    >
      <!-- use a disabled button to provide padding for tree leaf -->
      <button mat-icon-button disabled></button>
      <span
        [ngClass]="{
          bold: !node.deleted && !node.deletedChildren,
          'deleted-children': !node.deleted && node.deletedChildren,
          deleted: node.deleted
        }"
        >{{ node.name }}</span
      >
    </mat-tree-node>
    <!-- This is the tree node template for expandable nodes -->
    <mat-tree-node
      *matTreeNodeDef="let node; when: hasChild"
      matTreeNodePadding
      matTreeNodeToggle
      [cdkTreeNodeTypeaheadLabel]="node.name"
      (click)="scrollToNode(node)"
      [matTooltip]="node.name"
    >
      <button
        mat-icon-button
        matTreeNodeToggle
        [attr.aria-label]="'Toggle ' + node.name"
      >
        <mat-icon class="mat-icon-rtl-mirror">
          {{ treeControl.isExpanded(node) ? "expand_more" : "chevron_right" }}
        </mat-icon>
      </button>
      <span
        [ngClass]="{
          bold: !node.deleted && !node.deletedChildren,
          'deleted-children': !node.deleted && node.deletedChildren,
          deleted: node.deleted
        }"
        >{{ node.name }}</span
      >
    </mat-tree-node>
  </mat-tree>
</div>

import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgxsModule } from '@ngxs/store';
// import { NgxsPlugin, NGXS_PLUGINS } from '@ngxs/store';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from '@angular/common';
import { reducers } from 'src/app/store/reducers';
import { config } from 'src/assets/config';
// import { PreloadAllModules } from '@angular/router';

// Angular Modules
import { HttpClientModule } from '@angular/common/http';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ResizableModule } from 'angular-resizable-element';
// import { ConnectionServiceModule } from 'ng-connection-service';

// Angular Material Components
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule, MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldDefaultOptions } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatNativeDateModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatListModule } from '@angular/material/list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatCardModule } from '@angular/material/card';
import { MatRadioModule } from '@angular/material/radio';
import { MatStepperModule } from '@angular/material/stepper';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTabsModule, MAT_TAB_GROUP } from '@angular/material/tabs';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatTreeModule } from '@angular/material/tree';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTooltipModule, MAT_TOOLTIP_DEFAULT_OPTIONS } from '@angular/material/tooltip';
import { QuillModule } from 'ngx-quill';

// Components
import { BaseComponent } from './common/base/base.component';
import { HeadPageComponent } from './components/head-page/head-page.component';
import { HeadLogoComponent } from './components/head-page/head-logo/head-logo.component';
import { HeadMenuComponent } from './components/head-page/head-menu/head-menu.component';
import { HeadMenuDropmenuComponent } from './components/head-page/head-menu/head-menu-dropmenu/head-menu-dropmenu.component';
import { HeadMenuSubmenuComponent } from './components/head-page/head-menu/head-menu-submenu/head-menu-submenu.component';
import { ToastrModule, provideToastr } from 'ngx-toastr';
import { HeadLoginComponent } from './components/head-page/head-login/head-login.component';
import { LoginComponent } from './components/login/login.component';
import { LogoutComponent } from './components/login/logout/logout.component';
import { httpInterceptorProviders } from './interceptors/interceptorProviders';
import { HeadActionButtonsComponent } from './components/head-page/action-buttons/head-action-buttons.component';
import { HomeComponent } from './components/home/home.component';
import { AcceleratorStatusComponent } from './components/home/accelerator-status/accelerator-status.component';
import { BeamStatusComponent } from './components/home/beamline-status/beamline-status.component';
import { CurrentActivityComponent } from './components/home/current-activity/current-activity.component';
import { BulletinBoardComponent } from './components/home/bulletin-board/bulletin-board.component';
import { ModalHistoryBBComponent } from './components/home/bulletin-board/modal-history-bb/modal-history-bb.component';
import { ModalAddToBBComponent } from './components/home/bulletin-board/modal-add-to-bb/modal-add-to-bb.component';
import { BranchlineStatusComponent } from './components/home/branchline-status/branchline-status.component';
import { YesNoDialogComponent } from './controls/yes-no-dialog/yes-no-dialog.component';
import { ResourcesService } from './components/catalogs/beamline-catalog/resource/resources.service';
import { SendIdService } from './components/home/beamline-status/send-id.service';
import { ToxicGasPanelComponent } from './components/home/toxic-gas-panel/toxic-gas-panel.component';
import { ToxicGasArchiveComponent } from './components/home/toxic-gas-panel/archive/toxic-gase-archive.component';
import { ToxicGasEditorComponent } from './components/home/toxic-gas-panel/editor/toxic-gas-editor.component';
import { BakeoutsPanelComponent } from './components/home/bakeouts-panel/bakeouts-panel.component';
import { BakeoutArchiveComponent } from './components/home/bakeouts-panel/archive/archive.component';
import { BakeoutEditorComponent } from './components/home/bakeouts-panel/editor/editor.component';
import { PendingChangesDialogComponent } from './controls/pending-changes-dialog/pending-changes-dialog.component';
import { TooltipConfigService } from './services/tooltip-config.service';
import { SharedModule } from './modules/shared.module';
import { ScfV2Module } from './components/scf-v2/scf-v2.module';
import { RsswaModule } from './components/rsswa/rsswa.module';
import { KenableModule } from './components/kenable/kenable.module';
import { EbModule } from './components/eb/eb.module';
import { TemplateModule } from './components/template/template.module';
import { RolesModule } from './components/catalogs/roles/roles.module';
import { CanDeactivateService } from './common/guards/unsaved-changes.guard';
import { PpstbModule } from './components/ppstb/ppstb.module';
import { ComponentService } from './services/work-flow/component.service';
import { AbsiModule } from './components/absi/absi.module';
import { ChecklistsModule } from './components/checklists/checklists.module';
import { CatalogsModule } from './components/catalogs/catalogs.module';
import { ReviewsModule } from './components/reviews/reviews.module';
import { ScheduleModule } from './components/schedules/schedule.module';
import { ProcedureModule } from './components/procedure/procedure.module';
import { AplModule } from './components/apl/apl.module';
import { PendingApprovalsV2Module } from './components/pending-approvals-v2/pending-approvals-v2.module';
import { BratoiModule } from './components/catalogs/bratoi/bratoi.module';
import { SiteConfigurationComponent } from './components/site-configuration/site-configuration.component';
import { ComponentRegistry } from './common/extensions/decorator';
import { MessagesModule } from './components/messages/messages.module';
import { MenuConfigComponent } from './components/menu-config/menu-config.component';
import { MenuConfigHeadComponent } from './components/menu-config/menu-config-head/menu-config-head.component';
import { MenuConfigActionsComponent } from './components/menu-config/menu-config-actions/menu-config-actions.component';
import { MenuConfigEditComponent } from './components/menu-config/menu-config-edit/menu-config-edit.component';
import { SearchDialogComponent } from './components/head-page/head-search/search-dialog/search-dialog.component';
import { RedirectComponent } from './components/redirect/redirect.component';
import { OlogComponent } from './components/olog/olog.component';
import { OlogMainComponent } from './components/olog/olog-main/olog-main.component';
import 'src/app/common/extensions/extensions';
import { HighlightModule, HIGHLIGHT_OPTIONS } from 'ngx-highlightjs';
import { RadiationAreaComponent } from './components/home/radiation-area/radiation-area.component';
import { ModalAddToRAComponent } from './components/home/radiation-area/modal-add-to-ra/modal-add-to-ra.component';
import { MatFileUploadModule } from './modules/file-upload/file-upload-module';
import { BeamlineOverviewComponent } from './components/home/beamline-status/beamline-overview/beamline-overview/beamline-overview.component';
import { BeamlineOverviewDetailsComponent } from './components/home/beamline-status/beamline-overview/beamline-overview-details/beamline-overview-details.component';
import { RedirectDialogComponent } from './components/redirect/redirect-dialog/redirect-dialog.component';
import { RedirectService } from './components/redirect/redirect.service';


const appearance: MatFormFieldDefaultOptions = {
  appearance: 'outline'
};
@NgModule({
  declarations: [
    AppComponent,
    BaseComponent,
    HeadPageComponent,
    HeadLogoComponent,
    HeadMenuComponent,
    HeadMenuDropmenuComponent,
    HeadMenuSubmenuComponent,
    HeadLoginComponent,
    HeadActionButtonsComponent,
    LoginComponent,
    LogoutComponent,
    HomeComponent,
    AcceleratorStatusComponent,
    BeamStatusComponent,
    BeamlineOverviewComponent,
    BeamlineOverviewDetailsComponent,
    CurrentActivityComponent,
    BulletinBoardComponent,
    RadiationAreaComponent,
    ModalHistoryBBComponent,
    ModalAddToBBComponent,
    ModalAddToRAComponent,
    BranchlineStatusComponent,
    ToxicGasPanelComponent,
    ToxicGasArchiveComponent,
    ToxicGasEditorComponent,
    BakeoutsPanelComponent,
    BakeoutArchiveComponent,
    BakeoutEditorComponent,
    SiteConfigurationComponent,

    //Controls
    YesNoDialogComponent,
    PendingChangesDialogComponent,
    MenuConfigComponent,
    MenuConfigHeadComponent,
    MenuConfigActionsComponent,
    MenuConfigEditComponent,

    SearchDialogComponent,
    RedirectComponent,
    OlogComponent,
    OlogMainComponent,
    RedirectDialogComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    CommonModule,
    BrowserAnimationsModule,
    MatButtonModule,
    MatDialogModule,
    HttpClientModule,
    ScrollingModule,
    HttpClientModule,
    MatSelectModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatChipsModule,
    MatIconModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatListModule,
    MatProgressSpinnerModule,
    MatCardModule,
    MatRadioModule,
    MatFileUploadModule,
    MatStepperModule,
    MatSlideToggleModule,
    MatTabsModule,
    MatTooltipModule,
    MatGridListModule,
    FormsModule,
    ReactiveFormsModule,
    MatProgressBarModule,
    DragDropModule,
    MatTreeModule,
    MatExpansionModule,
    MatMenuModule,
    MatSidenavModule,
    HighlightModule,
    ResizableModule,
    ToastrModule.forRoot({
      timeOut: 3000,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true
    }),
    QuillModule.forRoot(),
    NgxsModule.forRoot(reducers, {
      developmentMode: config.production
    }),
    NgxsLoggerPluginModule.forRoot(),

    /// ASCC Modules
    TemplateModule,
    SharedModule,
    ScfV2Module,
    RsswaModule,
    KenableModule,
    RolesModule,
    EbModule,
    PpstbModule,
    AbsiModule,
    ChecklistsModule,
    CatalogsModule,
    ReviewsModule,
    ScheduleModule,
    ProcedureModule,
    AplModule,
    PendingApprovalsV2Module,
    BratoiModule,
    MessagesModule
  ],
  providers: [
    ResourcesService,
    SendIdService,
    ComponentRegistry,
    RedirectService,
    httpInterceptorProviders,
    {
      provide: MAT_TAB_GROUP,
      useValue: undefined,
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: appearance
    },
    {
      provide: HIGHLIGHT_OPTIONS,
      useValue: {
        fullLibraryLoader: () => import('highlight.js')
      },
    },
    // provideAnimations(), // required animations providers
    provideToastr(), // Toastr providers
    TooltipConfigService,
    CanDeactivateService,
    ComponentService,
    { provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useExisting: TooltipConfigService },
  ],
  exports: [
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {

}

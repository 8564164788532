import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { DeletedTasksRefresh } from './deleted-task.action';
import { WfTaskLocalResource } from 'src/app/services/work-flow/work-flow';
import { ChecklistBuilderService } from '../../checklist-builder/checklist-builder.service';

export class DeletedTasksStateModel {
  data: WfTaskLocalResource[] = [];
}

@State<DeletedTasksStateModel>({
  name: 'DeletedWFTaskLocals',
  defaults: {
    data: [],
  },
})
@Injectable()
export class DeletedTasksState {
  constructor(private service: ChecklistBuilderService) { }

  @Selector()
  static read(state: DeletedTasksStateModel) {
    return state.data;
  }

  @Action(DeletedTasksRefresh)
  refresh({ patchState }: StateContext<DeletedTasksStateModel>, { checklistTemplateID }: DeletedTasksRefresh) {
    this.service.getDeletedTasks(checklistTemplateID).toPromise().then((data) => {
      patchState({ data });
    },
      (error) => console.error(error)
    );
  }

  // @Action(DeletedTasksCreate)
  // create({ getState, patchState }: StateContext<DeletedTasksStateModel>, { item }: DeletedTasksCreate) {
  //   patchState({
  //     data: [...getState().data, item]
  //   });
  // }

  // @Action(DeletedTasksDelete)
  // delete({ getState, patchState }: StateContext<DeletedTasksStateModel>, { logID }: DeletedTasksDelete) {
  //   patchState({ data: getState().data.filter((x) => x.logID !== logID) });
  // }
}

import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { SystemCategoryService } from '../services/system-category.service';
import { SystemCategory } from 'src/app/components/messages/services/message-banner';
import { MatTableDataSource } from '@angular/material/table';
import { utils } from 'src/app/modules/libs/utils';
import { filter } from 'rxjs/operators';
import { MessageBannerService } from '../services/message-banner.service';

@Component({
  selector: 'messages-categories',
  templateUrl: './messages-categories.component.html',
  styleUrls: ['./messages-categories.component.scss']
})
export class MessagesCategoriesComponent implements OnInit, OnChanges {

  @Input() filter?: string;
  @Output() loading = new EventEmitter<boolean>();
  @Output() selectedRow = new EventEmitter<SystemCategory>();

  systemCategories?: SystemCategory[];
  systemCategoriesFiltered?: SystemCategory[];
  displayedColumns = ['name', 'description'];
  dataSource = new MatTableDataSource<SystemCategory>();

  selectedRowIndex?: number;

  constructor(private service: SystemCategoryService, private mbs: MessageBannerService) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['filter']) this.applyFilter();
  }

  ngOnInit(): void {
    this.loadSystemCategories();
  }

  loadSystemCategories() {
    this.loading.emit(true);
    this.service.GetAllSystemCategories().toPromise().then(data => {
      this.systemCategories = data?.sort((a, b) => utils.sort(a.name, b.name));
      this.applyFilter();
      this.loading.emit(false);
      this.mbs.categories = this.systemCategories ?? [];
    });
  }

  applyFilter() {
    this.systemCategoriesFiltered = this.systemCategories?.filter(c => c.name?.toLowerCase().includes(this.filter ?? '') || c.description?.toLowerCase().includes(this.filter ?? ''));
    this.dataSource = new MatTableDataSource(this.systemCategoriesFiltered);
  }

  getValues(row: SystemCategory) {
    this.selectedRowIndex = row.id;
    this.selectedRow.emit(row);
  }

}

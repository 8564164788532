import { Component, ChangeDetectorRef, ViewChild, OnChanges, SimpleChanges, OnInit, Injector } from '@angular/core';
import { CatlComponent } from '../../catl/catl.component';
import { ClBaseComponent } from '../cl-base/cl-base.component';
import { ActionItem } from 'src/app/common/models/action-item';
import { ActionItemService } from '../../catl/action-item/action-item.service';

@Component({
  selector: 'cl-catl',
  templateUrl: './cl-catl.component.html',
  styleUrls: ['./cl-catl.component.scss']
})
/** cl-catl component*/
export class ClCatlComponent extends ClBaseComponent implements OnChanges, OnInit {

  @ViewChild(CatlComponent)
  public catl!: CatlComponent;
  override label = 'Action Item Description';

  actionItems: ActionItem[] = [];
  /*--------------------------------*/

  constructor(
    protected override injector: Injector,
    private _catl: ActionItemService,
    private cdRef: ChangeDetectorRef
  ) {
    super(injector);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.initials = this.user ? this.user.initials : '';
    this.setValues();
  }

  setValues() {
    if (this.sectionId) {
      this._catl.readBySection(this.sectionId).subscribe(data => {
        this.actionItems = data;
      });
    }
  }

  ngOnInit() {
  }

  add() {
    this.catl.add();
  }

  getValues() {
    this.valueObjects = [];
    this.valueObjects.push({ key: 'actionItems', val: this.actionItems });
    return this.valueObjects;
  }

  catlChanged(actionItems: ActionItem[]) {
    if (this.sectionId)
      this._catl.readBySection(this.sectionId).subscribe(data => {
        this.actionItems = data;
      });
    this.saveValues(this.getValues());
  }


  /*---------------------------*/
}

<div *ngIf="!isLoading">
  <ul class="navbar-nav">
    <div
      class="d-flex flex-column flex-lg-row full-width justify-content-evenly pr-4"
      id="menu"
    >
      <li *ngIf="showCarrousel">
        <button mat-icon-button class="navButtons" (click)="next(false)">
          <mat-icon>navigate_before</mat-icon>
        </button>
      </li>
      <li
        *ngFor="let menuItem of showingMenu"
        class="nav-item d-flex align-items-center"
        [ngClass]="{ active: menuItem.name == 'Home' }"
      >
        <a
          class="nav-link p-0 relative"
          [routerLink]="menuItem.routerLink"
          *ngIf="
            menuItem.routerLink &&
            menuItem.type != menuItemTypes.PopUp &&
            ((menuItem.route == 'pending-approvals' &&
              (pendingApprovals ?? 0) + (myPendingChanges ?? 0) > 0 &&
              currentUser) ||
              menuItem.route != 'pending-approvals')
          "
        >
          {{ menuItem.name }}
          <div class="notification-container">
            <div
              class="notfication"
              *ngIf="
                menuItem.route == 'pending-approvals' &&
                pendingApprovals &&
                currentUser
              "
            >
              <span>{{ pendingApprovals }}</span>
            </div>
            <div
              class="notfication-my"
              *ngIf="
                menuItem.route == 'pending-approvals' &&
                myPendingChanges &&
                currentUser
              "
            >
              <span>{{ myPendingChanges }}</span>
            </div>
          </div>
        </a>
        <a
          *ngIf="menuItem.type == menuItemTypes.PopUp"
          class="nav-link p-0"
          (click)="openComponent(menuItem.route ?? '')"
          >{{ menuItem.name }}</a
        >
        <head-menu-dropmenu
          [menuItem]="menuItem"
          *ngIf="
            menuItem.type == menuItemTypes.Submenu ||
            menuItem.type == menuItemTypes.Table
          "
        ></head-menu-dropmenu>
      </li>
      <li *ngIf="showCarrousel">
        <button mat-icon-button class="navButtons" (click)="next(true)">
          <mat-icon>navigate_next</mat-icon>
        </button>
      </li>
    </div>
  </ul>
</div>
<div class="loading text-loading" *ngIf="isLoading">Building menu ...</div>

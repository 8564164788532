import { Component, ViewChild, Output, EventEmitter, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { CommentsAndAttachmentsComponent } from '../../../controls/comments-and-attachments/comments-and-attachments.component';
import { AbsiResource } from '../absi';
import { BaseComponent } from 'src/app/common/base/base.component';
import { FormStatusEnum } from 'src/app/common/enumerations/enumerations';
import { WFSectionLocalResource } from 'src/app/services/work-flow/work-flow';
import { load } from '../absi-builder/absi-builder-main/absi-builder-main.component';

@Component({
  selector: 'absi-main-panel',
  templateUrl: './absi-main-panel.component.html',
  styleUrls: ['./absi-main-panel.component.scss']
})
export class AbsiMainPanelComponent extends BaseComponent implements OnChanges, OnInit {

  @Input() absi?: AbsiResource;
  @Output() loading = new EventEmitter<boolean>();

  sections?: WFSectionLocalResource[];
  loadings: load[] = [];

  @ViewChild(CommentsAndAttachmentsComponent) private comments!: CommentsAndAttachmentsComponent;

  formStatus = FormStatusEnum;
  panelOpenState = false;
  sectionInfo?: WFSectionLocalResource;

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.absi && this.absi.wfTable) {
      this.sections = this.absi.wfTable?.wfTableLocal?.wfSectionLocals.filter(s => s.number && s.number < 100).sort((a, b) => this.utils.sort(a.number, b.number));
      this.sectionInfo = this.absi.wfTable?.wfTableLocal?.wfSectionLocals.find(s => s.number && s.number == 100);
    }
    else {
      this.sectionInfo = { id: 0 };
    }
  }

  setLoading(e: boolean, s: number) {
    const section = this.loadings.find(l => l.s == s);
    if (section) section.val = e;
    else this.loadings.push({ s, val: e });
    this.loading.emit(this.loadings.some(l => l.val));
    console.log('s: ' + s + ', val:' + e);
  }

}

import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges, OnDestroy, OnInit, Injector } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { Sort } from '@angular/material/sort';
import { FormStatusEnum, FormType } from 'src/app/common/enumerations/enumerations';
import { utils } from 'src/app/modules/libs/utils';
import { Form } from 'src/app/services/forms/form';
import { FormService } from './form.service';
import { MatTableDataSource } from '@angular/material/table';
import { EbService } from 'src/app/components/eb/eb.service';
import { BaseComponent } from 'src/app/common/base/base.component';
import { PpstbService } from 'src/app/components/ppstb/ppstb.service';

@Component({
  selector: 'form-table',
  templateUrl: './form-table.component.html',
  styleUrls: ['./form-table.component.scss']
})
export class FormTableComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {
  @Input() formType!: FormType;
  @Input() displayedColumns!: string[];
  @Input() filter?: string;

  @Output() error = new EventEmitter<string>();
  @Output() rowClick = new EventEmitter<Form | null>();
  @Output() loading = new EventEmitter<boolean>();

  form?: Form | null;
  forms!: Form[];
  forms$!: Observable<Form[]>;
  formsSubs!: Subscription;

  allForms: Form[] = [];
  filteredForms: Form[] = [];

  showClosed!: boolean;

  subEditing!: Subscription;
  subRefreshForms!: Subscription;
  sortDefault: Sort = { active: 'sequenceNumber', direction: 'asc' };
  pendingSelect: number | null = null;

  dataSource!: MatTableDataSource<Form>;
  service!: EbService | PpstbService;

  constructor(
    protected override injector: Injector,
    public formService: FormService,
    private ebService: EbService,
    private ppstbService: PpstbService
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.service = this.formType == this.formTypeEnum.PPSTB ? this.ppstbService : this.ebService;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.loading.emit(true);
    this.loadForms();
    if ('filter' in changes) {
      this.filterForms();
    }
  }

  loadForms() {
    this.forms$ = this.store.select(state => state.Forms.data);
    this.formsSubs = this.forms$.subscribe(async data => {
      if (data?.length) {
        this.allForms = [];
        this.allForms = data.filter(x => x.type == this.formType);
        this.filteredForms = [];
        this.filterForms();

        const openDocument = await this.checkOpenDocument<Form>(this.formType, data);
        if (openDocument && this.form?.id != openDocument.document.id) {
          this.setFormDirty(false);
          this.showClosed = openDocument.closed;
          this.getValues(openDocument.document);
        }
        else this.loading.emit(false);
        this.systemAffected();
      }
    });
  }

  override ngOnDestroy(): void {
    this.subEditing?.unsubscribe();
    this.subRefreshForms?.unsubscribe();
    this.formsSubs?.unsubscribe();
  }

  filterForms(): void {
    if (!this.filter) {
      this.filter = '';
    }
    this.filter = this.filter.toLowerCase();
    this.filteredForms = this.allForms.filter(x => !this.showClosed ? (x.formVersion.statusID != FormStatusEnum.Closed && x.formVersion.statusID != FormStatusEnum.Canceled) : true).filter(x =>
      x.sequenceNumber.number.trim().toLowerCase().includes(this.filter ?? '') ||
      x.formVersion.status.name.trim().toLowerCase().includes(this.filter ?? '') || x.formVersion.systemAffected?.toLowerCase().includes(this.filter ?? '')
    );
    this.dataSource = new MatTableDataSource(this.filteredForms.sort((a, b) => utils.sort(a.sequenceNumber.number, b.sequenceNumber.number, false)));
  }

  async getValues(form: Form) {
    if (await this.canDeactivate()) {
      this.form = form;
      this.setOpenDocument(form.type, form.id);
      this.loading.emit(true);
      this.rowClick.emit(form);
    }
  }

  clear() {
  }

  systemAffected() {
    switch (this.formType) {
      case FormType.PPSTB: {
        this.filteredForms.map(element => {
          const systemAffected = [];
          element.description = element.formVersion.ppstb.justificationForBypass;
          if (element.formVersion.ppstb.accelerator) {
            if (element.formVersion.ppstb.ppstbAcceleratorLocations.length > 0) {
              element.formVersion.ppstb.ppstbAcceleratorLocations.map(location => {
                systemAffected.push(location.resource?.name);
              });
            } else { systemAffected.push('Accelerator'); }
          }
          if (element.formVersion.ppstb.beamline) {
            if (element.formVersion.ppstb.ppstbShutters.length > 0) {
              element.formVersion.ppstb.ppstbShutters.map(shutter => {
                systemAffected.push(shutter.resource?.name);
              });
            } else { systemAffected.push('Beamline'); }
          }
          if (element.formVersion.ppstb.topOff) {
            systemAffected.push('Top-Off');
          }
          if (element.formVersion.ppstb.radiationMonitoringSystem) {
            systemAffected.push('Radiation Monitoring System');
          }
          if (element.formVersion.ppstb.ppstbOtherSystems.length > 0) {
            element.formVersion.ppstb.ppstbOtherSystems.map(otherSystem => {
              systemAffected.push(otherSystem.text);
            });
          }
          element.formVersion.systemAffected = systemAffected.join(', ');
        });
        break;
      }
      case FormType.EBF: {
        this.filteredForms.map(element => {
          const systemAffected = [];
          element.description = element.formVersion.eB2.equipmentOrFunctionForBypassAndReason;
          if (element.formVersion.eB2.accelerator) {
            if (element.formVersion.eB2.eB2AcceleratorLocations.length > 0) {
              element.formVersion.eB2.eB2AcceleratorLocations.map(location => {
                systemAffected.push(location.resource?.name);
              });
            } else { systemAffected.push('Accelerator'); }
          }
          if (element.formVersion.eB2.beamline) {
            if (element.formVersion.eB2.eB2Shutters.length > 0) {
              element.formVersion.eB2.eB2Shutters.map(shutter => {
                systemAffected.push(shutter.resource?.name);
              });
            } else { systemAffected.push('Beamline'); }
          }
          if (element.formVersion.eB2.eB2OtherSystems.length > 0) {
            element.formVersion.eB2.eB2OtherSystems.map(otherSystem => {
              systemAffected.push(otherSystem.text);
            });
          }
          element.formVersion.systemAffected = systemAffected.join(', ');
        });
        break;
      }
      default: break;
    }
  }

}

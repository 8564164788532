import { Component, Input, ViewChild, OnChanges, Output, EventEmitter, OnDestroy, SimpleChanges, OnInit, ChangeDetectorRef, Injector } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ChecklistTemplate, ChecklistTemplateStatus, TemplateType } from '../../checklists';
import { Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { ScheduleStatusEnum } from 'src/app/components/schedules/models/enums';
import { Schedule } from 'src/app/components/schedules/models/schedule';
import { Procedure } from 'src/app/components/procedure/models/procedure.model';
import { ChecklistBuilderService } from '../checklist-builder.service';
import { utils } from 'src/app/modules/libs/utils';
import { BaseComponent } from 'src/app/common/base/base.component';


@Component({
  selector: 'checklist-builder-list',
  templateUrl: './checklist-builder-list.component.html',
  styleUrls: ['./checklist-builder-list.component.scss']
})
export class ChecklistBuilderListComponent extends BaseComponent implements OnChanges, OnDestroy, OnInit {

  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  @Input() checklistTemplates?: ChecklistTemplate[];

  @Output() rowSelected = new EventEmitter<ChecklistTemplate>();
  @Output() loading = new EventEmitter();

  schedules!: Schedule[];
  schedules$!: Observable<Schedule[]>;
  subSchedules!: Subscription;

  procedure!: Procedure;
  procedures?: Procedure[];
  proceduresFiltered?: Procedure[];
  procedures$!: Observable<Procedure[]>;
  proceduresSubs!: Subscription;

  checklistTemplateStatuses!: ChecklistTemplateStatus[];

  templateTypes!: TemplateType[];
  templateTypes$!: Observable<TemplateType[]>;
  templateTypesSubs!: Subscription;

  loaded = false;

  public displayedColumns: string[] = ['serialNo', 'statusName', 'resourceName', 'procedureNumber', 'name'];
  public dataSource = new MatTableDataSource<ChecklistTemplate>();

  selectedRowIndex!: number;

  constructor(
    protected override injector: Injector,
    private _builder: ChecklistBuilderService) {
    super(injector);
  }

  async ngOnInit(): Promise<void> {
    this.dataSource.paginator = this.paginator;
    this.loadSchedules();
    this.loadProcedures();
    this.loadTemplateTypes();
    this.init();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.init();
  }

  async init() {
    this.loading.emit(true);
    this.loadStatuses();
    this._builder.loadProcedures();

    if (this.checklistTemplates) {
      this.setStatusName();
      await this.renderTable();

      const checklistTemplateID = localStorage.getItem('checklistTemplateID');
      if (checklistTemplateID && !this.loaded) {
        const template = this.checklistTemplates?.find(x => x.id == +checklistTemplateID);
        if (template) {
          this.rowClicked(template);
          localStorage.clear();
          this.loaded = true;
        }
      }
      this.loading.emit(false);
    }
    else this.loading.emit(false);
  }

  loadProcedures() {
    this.procedures$ = this.store.select(state => state.Procedures.data);
    this.proceduresSubs = this.procedures$.subscribe(data => {
      if (data?.length) {
        this.procedures = data;
        this.checklistTemplates?.map(c => {
          if (c.procedureID && !c.procedure) c.procedure = this.procedures?.find(p => p.id == c.procedureID);
        });
      }
    });
  }

  loadSchedules() {
    this.schedules$ = this.store.select(state => state.Schedules.data);
    this.subSchedules = this.schedules$.subscribe(async data => {
      this.schedules = data.filter(x => x.statusId != ScheduleStatusEnum.completed && x.statusId != ScheduleStatusEnum.remove);
      await this.renderTable();
    });
  }

  loadTemplateTypes() {
    this.templateTypes$ = this.store.select(state => state.TemplateTypes.data);
    this.templateTypesSubs = this.templateTypes$.subscribe(async data => {
      this.templateTypes = data;
      await this.renderTable();
    });
  }

  async renderTable() {
    this.checklistTemplates = await this._builder.setResources(this.checklistTemplates, this.templateTypes, this.schedules);
    this.dataSource = new MatTableDataSource(this.checklistTemplates?.filter(x => this.checklistTemplateStatuses?.filter(s => s.selected).map(s => s.statusEnum).includes(x.status)).sort((a, b) => utils.sort(a.statusOrder, b.statusOrder)));
    if (localStorage.getItem('cb-list-sort'))
      this.sort = this.utils.JSONparse(localStorage.getItem('cb-list-sort'));
    this.sortTable(this.sort);
  }

  override ngOnDestroy() {
    this.subSchedules?.unsubscribe();
    this.templateTypesSubs?.unsubscribe();
    this.proceduresSubs?.unsubscribe();
    super.ngOnDestroy();
  }

  loadStatuses() {
    this.checklistTemplateStatuses = this._builder.checklistTemplateStatuses;
  }

  setStatusName() {
    this.checklistTemplates?.map(c => {
      const status = this.checklistTemplateStatuses?.find(x => x.code == c.code);
      c.statusName = status?.description;
      c.statusOrder = status?.order;
    });
    this._builder.checklistTemplates = this.checklistTemplates;
  }

  getClass(code: string) {
    return this.checklistTemplateStatuses?.find(x => x.code == code)?.cssClass;
  }

  getPosition(row: any): number {
    const i = this.dataSource.data.findIndex(r => {
      return r.id == row.id;
    });
    return i;
  }

  rowClicked(row: ChecklistTemplate) {
    this.rowSelected.emit(row);
  }

  sortTable(sort: Sort) {
    const dir = sort.direction;
    localStorage.setItem('cb-list-sort', this.utils.JSONstringify(sort));
    let data = this.checklistTemplates?.filter(x => this.checklistTemplateStatuses?.filter(s => s.selected).map(s => s.statusEnum).includes(x.status));
    if (dir)
      switch (sort.active) {
        case 'serialNo':
          data = data?.sort((a, b) => this.utils.sortArrayAlphabeticallyWithComplexNumbers(a.serialNo, b.serialNo, dir));
          break;
        case 'statusName':
          data = data?.sort((a, b) => this.utils.sortArrayAlphabeticallyWithComplexNumbers(a.statusName, b.statusName, dir));
          break;
        case 'resourceName':
          data = data?.sort((a, b) => this.utils.sortArrayAlphabeticallyWithComplexNumbers(a.resourceString, b.resourceString, dir));
          break;
        case 'procedureNumber':
          data = data?.sort((a, b) => this.utils.sortArrayAlphabeticallyWithComplexNumbers(a.procedure?.procedureNumber, b.procedure?.procedureNumber, dir));
          break;
        case 'name':
          data = data?.sort((a, b) => this.utils.sortArrayAlphabeticallyWithComplexNumbers(a.templateType?.name, b.templateType?.name, dir));
          break;
      }
    this.dataSource = new MatTableDataSource(data);
  }


}

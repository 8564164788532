import { moveItemInArray, CdkDragDrop } from '@angular/cdk/drag-drop';
import { Component, Input, QueryList, ViewChildren, OnChanges, Output, EventEmitter, Injector } from '@angular/core';
import { ChecklistBuilderService } from '../checklist-builder.service';
import { ChecklistTemplate } from '../../checklists';
import { ChecklistBuilderSectionComponent } from './checklist-builder-section/checklist-builder-section.component';
import { Procedure } from 'src/app/components/procedure/models/procedure.model';
import { WFSectionLocalResource } from 'src/app/services/work-flow/work-flow';
import { BaseComponent } from 'src/app/common/base/base.component';
import { YesNoDialogComponent } from 'src/app/controls/yes-no-dialog/yes-no-dialog.component';
import { TransactionLogService } from 'src/app/services/logs/transaction-log.service';

@Component({
  selector: 'checklist-builder-sections',
  templateUrl: './checklist-builder-sections.component.html',
  styleUrls: ['./checklist-builder-sections.component.scss']
})
export class ChecklistBuilderSectionsComponent extends BaseComponent implements OnChanges {

  @Input() sections!: WFSectionLocalResource[];
  @Input() currentVersion!: ChecklistTemplate;
  @Input() wfTableID!: number;
  @Input() disabled!: boolean;
  @Input() procedure?: Procedure | null;
  @Input() showDeleted?: boolean;

  @Output() loading = new EventEmitter();

  @ViewChildren(ChecklistBuilderSectionComponent) sectionComponents!: QueryList<ChecklistBuilderSectionComponent>;

  sectionDropLists: string[] = [];
  stepDropLists: string[] = [];

  constructor(
    protected override injector: Injector,
    public service: ChecklistBuilderService,
    private logService: TransactionLogService
  ) {
    super(injector);
  }

  ngOnChanges() {
    this.sections = [...new Map(
      this.sections?.map(section => [section.id, section]) // Using the section `id` as the key to ensure uniqueness
    ).values() // Extract the unique values
    ].sort((a, b) => (a.order ?? 0) - (b.order ?? 0));
    this.renumberArray();
    this.updateDropLists();
  }

  add() {
    this.service.createSection(this.wfTableID).subscribe(data => {
      this.sections.push(data);
      this.updateDropLists();
      this.renumberArray();
    });
  }

  deleted(section: WFSectionLocalResource) {
    const index = this.sections.findIndex(x => x.id === section.id);
    if (index !== -1) {
      this.sections.splice(index, 1);
    }
    this.renumberArray();
    this.updateDropLists();
  }

  drop(event: CdkDragDrop<WFSectionLocalResource[]>) {
    if (event?.previousIndex !== event?.currentIndex) {
      moveItemInArray(this.sections, event.previousIndex, event.currentIndex);
      this.renumberArray();
    }
  }

  updateDropLists() {
    this.sectionDropLists = this.sections?.map(_ => 'section-' + _.id);
    this.sections?.map(s => {
      if (s.wfStepLocals)
        this.stepDropLists = this.stepDropLists.concat(s.wfStepLocals.map(_ => 'step-' + _.id));
    });
  }

  async renumberArray() {
    let order = 0;
    const sectionsToUpdate: WFSectionLocalResource[] = [];
    this.sections.filter(s => !s.logID).map(s => {
      const number = order + 1;
      if (s.order != order || s.number != number) {
        s.order = order;
        s.number = order + 1;
        sectionsToUpdate.push(s);
      }
      order++;
    });

    if (sectionsToUpdate.length)
      await this.service.updateSections(sectionsToUpdate).toPromise();

    this.sections = this.sections?.sort((a, b) => ((a.order ?? 0) - (b.order ?? 0)));
  }

  refreshPreviousStep(stepID: number) {
    this.sectionComponents.map(section => {
      const stepComponent = section.stepsComponent.stepComponents.find(s => s.step.id === stepID);
      stepComponent?.tasksComponent.refreshTasks();
    });
  }

  restore(section: WFSectionLocalResource) {
    if (section.logID) {
      this.loading.emit(true);
      this.service.restoreSection(section.logID).toPromise().then(data => {
        if (data) {
          this.renumberArray();
          data.order = (data.order ?? 0) - 1;
          this.alert.success('Step: <b>' + data.name + '</b> has been Restored!.');
          this.scrollToSection(data.id);
        }
        this.loading.emit(false);
      });
    }
  }

  deleteForever(section: WFSectionLocalResource) {
    const yesno = this.dialog.open(YesNoDialogComponent, {
      width: "400px",
      data: {
        message: 'Are you sure you want to Remove this Deleted Section Forever?',
        icon: "stop",
      },
    });
    yesno.afterClosed().subscribe((data) => {
      if (data) {
        this.loading.emit(true);
        if (section.logID)
          this.logService.UpdateStatus(section.logID).toPromise().then(() => {
            this.loading.emit(false);
          });
      }
    });
  }

  scrollToSection(sectionID: number) {
    setTimeout(() => {
      const element = document.getElementById('section-' + sectionID);
      if (element) {
        element.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'start',
        });
      }
    }, 500);
  }

  refreshRestored(section: WFSectionLocalResource) {
    this.sections.push(section);
    this.sections = this.sections.sort((a, b) => this.utils.sort(a.order, b.order));
    this.renumberArray();
  }

}

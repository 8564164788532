import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild, ElementRef, Output, EventEmitter, OnDestroy, Injector, Renderer2, HostListener } from "@angular/core";
import { MatTable, MatTableDataSource, MatTableDataSourcePaginator } from "@angular/material/table";
import { SelectionModel } from "@angular/cdk/collections";
import { PVData } from "./cl-image-upload-table-data/cl-image-upload-table-data.component";
import * as moment from "moment";
import { Spot } from "../cl-image-upload.component";
import { BaseComponent } from "src/app/common/base/base.component";
import { ImageActionsIds } from "src/app/modules/libs/quill/quill-custom-image-resize/quill-custom-names";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { PVInfoService } from "src/app/services/pv-info/pv-info.service";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { fromEvent, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { debounce } from 'lodash';

export interface MouseEvent {
  rowId: number;
  colId: number;
  cellsType: string;
}

export class TableColumn {
  name!: string;
  label?: string;
  readOnly?: boolean;
  editor?: any;
  type?: string;
  numericFormat?: any;
  source?: any;
  renderer?: any;
  className?: string;
  width?: number;
  span?: number;
  include?: boolean;
}

@Component({
  selector: "cl-image-upload-table",
  templateUrl: "./cl-image-upload-table.component.html",
  styleUrls: ["./cl-image-upload-table.component.scss"],
})
export class ClImageUploadTableComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {
  @Input() disabled!: boolean;
  @Input() configuration!: string;
  @Input() enableColumnsBuilder!: boolean;
  @Input() editor!: boolean;
  @Input() builder!: boolean;
  @Input() enableColumnsChecklist!: boolean;
  @Input() canEdit!: boolean;

  @ViewChild("textarea") textarea!: ElementRef;
  @ViewChild('matTable') matTable?: ElementRef;

  @ViewChild('displayContainer', { static: true }) displayContainer?: ElementRef;
  @ViewChild('resizableElement', { static: true }) resizableElement?: ElementRef;

  @Output() newSpot = new EventEmitter<number>();
  @Output() rowDeleted = new EventEmitter<number>();
  @Output() spotDeleted = new EventEmitter<number>();
  @Output() tableChanged = new EventEmitter<string>();
  @Output() newRow = new EventEmitter<number>();
  @Output() setRow = new EventEmitter<any>();
  @Output() rowDuplicated = new EventEmitter<any>();
  @Output() channelsUpdated = new EventEmitter<PVData[]>();
  @Output() setLoading = new EventEmitter<boolean>();

  smallId: string = ImageActionsIds.smallId;
  fitId: string = ImageActionsIds.fitId;
  largeId: string = ImageActionsIds.largeId;

  data!: any[];
  datasetExt: any[] = [];
  widths!: number[];

  currentRow = -1;
  currentColumn = -1;

  colWidths!: number[];
  colorize!: boolean;
  includeIM!: boolean;
  incIMPosition!: number;
  incChannels!: boolean;
  updating!: boolean;
  channelsUpdating!: number;
  spots!: Spot[];
  spotClasses = SpotClass;

  id?: number;

  public displayedColumns: TableColumn[] = [];
  public columns!: string[];
  public dataSource = new MatTableDataSource<any>();
  public IdSpotAdd = 0;

  selectedRowIndex!: number;

  tableMouseDown!: MouseEvent;
  tableMouseUp!: MouseEvent;
  isMobile!: boolean;
  FIRST_EDITABLE_ROW = 0;
  LAST_EDITABLE_ROW: number = this.dataSource.data.length - 1;
  FIRST_EDITABLE_COL = 1;
  LAST_EDITABLE_COL: number = this.displayedColumns.length - 1;
  newCellValue = "";

  editorText!: string;
  dragEnabled?: boolean;

  public columnInit: TableColumn[] = [
    { name: "id", label: "", width: 25, readOnly: true },
  ];
  public columnSpot: TableColumn[] = [
    { name: "spotid", label: "Spot", width: 25, readOnly: true },
  ];
  public columnActions: TableColumn[] = [
    { name: "actions", label: "", width: 25 },
  ];
  public columnData: TableColumn[] = [{ name: "data", label: "Data" }];
  public columnsBuilder!: TableColumn[];
  public columnsChecklist!: TableColumn[];
  columnsIntegratedMeasurements: TableColumn[] = [
    { name: "reading", label: "Int. Reading<br>(mrem)", type: "group" },
    { name: "time", label: "Int. Time<br>(m)", type: "group" },
    {
      name: "calcdose",
      label: "Calculated Dose Rate<br>(mrem/h)",
      type: "group",
    },
  ];

  dataset!: any[];

  private scrollTimeout: any;
  private scrollEvent?: () => void;
  private observer?: IntersectionObserver;
  private resizeObserver: ResizeObserver | null = null;
  private scrollableContainer?: HTMLElement | null;
  private intersectionObservers: IntersectionObserver[] = [];
  private resizeObservers: ResizeObserver[] = [];
  private mutationObservers: MutationObserver[] = [];
  private columnDatas?: any[];

  selectedCellsState: boolean[][] = [];
  selection = new SelectionModel<any[]>(true, []);
  enabled = true;
  images: string[] = [];
  selectedRow: any;

  addingSpot?: boolean;
  showUpdateData!: boolean;

  // warnHigh!: boolean;

  tooltipMessage!: string;
  channelCopy!: PVData;
  imageMaxWidth = 500;
  imageSizes!: [{ name: 'Small'; val: 255; }, { name: 'Medium'; val: 400; }, { name: 'Original'; val: 0; }];
  quillConfig = {
    toolbar: {
      container: [
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
        [{ align: [] }],
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ indent: '-1' }, { indent: '+1' }, { align: [] }],
        [{ script: 'sub' }, { script: 'super' }],      // superscript/subscript
        [{ color: [] }, { background: [] }],          // dropdown with defaults from theme
        ['table'], // Add the table option to the toolbar
        ['link', 'image', 'video'],
        ['clean']
      ],
    },
    keyboard: {
      bindings: {
        enter: {
          key: 13,
          handler: () => {
            return false;
          }
        },
        shiftEnter: {
          key: 13,
          shiftKey: true,
          handler: () => {
            return true;
          }
        },
        tab: {
          key: 9,
          handler: () => {
            return false;
          }
        }
      }
    },
    imageDrop: true,
    imageResize: true,
    customImageResize: true,
    imageCompressor: {
      quality: 0.3, // default
      maxWidth: this.imageMaxWidth, // default
      imageType: 'image/*', // default
      debug: true
    },
  };

  constructor(
    protected override injector: Injector,
    public sanitizer: DomSanitizer,
    private pvService: PVInfoService,
    private renderer: Renderer2,
    private el: ElementRef,
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.createSelectedStates();
    this.id = this.utils.getRandomInt(1, 100);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.disabled) { this.canEdit = false; }
    this.renderTable();
    this.addingSpot = false;
    setTimeout(() => {
      this.observeHeaders();
      this.observeCells();
    }, 1500);
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
    // Clean up the observer when the component is destroyed
    if (this.scrollEvent) {
      this.scrollEvent();
    }

    if (this.observer) {
      this.observer.disconnect();
    }

    if (this.scrollTimeout) {
      clearTimeout(this.scrollTimeout);
    }

    this.intersectionObservers.forEach(observer => observer.disconnect());
    this.resizeObservers.forEach(observer => observer.disconnect());
    this.mutationObservers.forEach(observer => observer.disconnect());
    // window.removeEventListener('resize', this.adjustStickyContents.bind(this));
  }

  renderTable() {
    if (this.configuration) {
      const configuration = this.utils.cloneDeep(JSON.parse(this.configuration));
      this.columnsBuilder = configuration.columnsBuilder;
      this.columnsChecklist = configuration.columnsChecklist;
      this.images = configuration.images;
      this.colorize = configuration.colorize;
      this.includeIM = configuration.includeIM;
      this.incIMPosition = configuration.includeIMPos;
      this.incChannels = configuration.channels;
      this.spots = configuration.spots;

      if (!this.incIMPosition) {
        this.incIMPosition = this.columnsChecklist.length;
      }

      let columns = this.columnInit
        .concat(this.columnsBuilder)
        .concat(this.columnSpot);
      if (this.includeIM) {
        const chklistCols = this.utils.insertArrayAt(
          this.utils.cloneDeep(this.columnsChecklist),
          this.incIMPosition,
          this.columnsIntegratedMeasurements
        );
        columns = columns.concat(chklistCols);
      } else {
        columns = columns.concat(this.columnsChecklist);
      }
      if (this.incChannels) {
        const pos = columns.find((x) => x.name == "notes")
          ? columns.length - 1
          : columns.length;
        columns = this.utils.insertArrayAt(
          this.utils.cloneDeep(columns),
          pos,
          this.columnData
        );
        this.checkChannelData(configuration);
      }
      columns = columns.concat(this.columnActions);

      const data = configuration.data;
      this.processData();
      const columsDisplayed = columns.filter((x) => x.name != "values" && x.name != "placeholder");
      this.columns = columsDisplayed.map((x) => x.name);
      this.displayedColumns = columsDisplayed;
      this.datasetExt.map(row => {
        const rowsSameId = this.datasetExt.filter(r => r.id == row.id);
        const index = rowsSameId.findIndex(r => r == row);
        row.last = index == rowsSameId.length - 1;
      });
      // this.datasetExt.map((row: any[]) => {
      //   row.map(col => { col = this.sanitizer.bypassSecurityTrustHtml(col) });
      // })
      this.dataSource = new MatTableDataSource(this.datasetExt);

      this.LAST_EDITABLE_ROW = data.length;
      this.LAST_EDITABLE_COL = columns.length;
    }
  }

  checkChannelData(configuration: any) {
    const data = this.pvDataRows(configuration);
    if (data.length) {
      const date = data.find((d) => d.date)?.date as Date;
      const sdate = moment(new Date(date)).format("LLL");
      this.tooltipMessage = date
        ? `Last update on <b class="b-500">${sdate}</b><br><small>Click to Update</small>`
        : "Click to get data";
    }
  }

  async updatePVData() {
    await this.getPVData().then((data: PVData[]) => {
      this.updateConfiguration(data);
      this.updating = false;
      this.tableChanged.emit(this.configuration);
    });
  }

  updateConfiguration(channels: PVData[]) {
    const configuration = JSON.parse(this.configuration);
    const rows = configuration.data;
    rows.map((r: any) => {
      const data = this.utils.JSONparse(r.data) as PVData[];
      data?.map((c: PVData) => {
        const ch = channels.find((x) => x.name == c.name);
        c.date = ch?.date;
        c.value = ch?.value;
      });
      r.data = this.utils.JSONstringify(data);
    });
    this.configuration = this.utils.JSONstringify(configuration);
  }

  getPVData(): Promise<PVData[]> {
    return new Promise(async (resolve) => {
      const data = this.pvDataRows();
      if (!this.updating) {
        this.updating = true;
        data.map(async (c, i) => {
          c.error = false;
          c.updating = true;
          c.date = null;
          await this.updateChannel(c).then((channel: PVData) => {
            data[i] = channel;
            data[i].updating = false;
            if (!data.find((d) => !d.date)) {
              resolve(data);
            }
          });
        });
      }
    });
  }

  updateChannel(channel: PVData): Promise<PVData> {
    return new Promise((resolve, reject) => {
      channel.updating = true;
      this.pvService.getPVInfo(channel.name).subscribe(
        (data) => {
          const value = data.toString();
          if (value.includes("e")) {
            const e = value.split("e");
            channel.value =
              this.utils.roundNumber(e[0], 5) + " x 10<sup>" + e[1] + "</sup>";
          } else {
            channel.value = this.utils.roundNumber(value, 5);
          }
          if (!this.editor && !this.builder) {
            channel.date = new Date();
          }
          resolve(channel);
        },
        (error) => {
          console.log(error);
          channel.error = true;
          reject();
        }
      );
    });
  }

  pvDataRows(configuration?: any) {
    configuration = configuration
      ? configuration
      : JSON.parse(this.configuration);
    const rows = configuration.data;
    const data: PVData[] = [];
    rows?.map((r: any) => {
      const rowData = this.utils.JSONparse(r.data);
      rowData?.map((d: any) => {
        data.push(d);
      });
    });
    return data;
  }

  rowSpan(column: any, item: any, row: any) {
    if (
      row % 1 == 0 ||
      column.name == "id" ||
      column.name == "spotid" ||
      column.name == "settings" ||
      column.name == "values"
    ) {
      if (column.name == "settings" || column.name == "values") {
        return 1;
      } else if (column.name == "id" || this.isBuilderColumn(column.name)) {
        return item.idSpan;
      } else if (
        column.name == "spotid" ||
        !this.isBuilderColumn(column.name)
      ) {
        return item.spotSpan;
      }
    }
    return 0;
  }

  isBuilderColumn(column: any): boolean {
    const colsBuilder: any[] = this.columnsBuilder.map((x) => x.name);
    return colsBuilder.includes(column);
  }

  columnValue(column: any, element: any): SafeHtml {
    if (column.name == "spotid") {
      let value = element[column.name];
      if (element[column.name]?.toString() === "0") {
        value = "";
      } else if (element.imageid) {
        if (Number(element.imageid) == 0) {
          value = 0;
        }
      }
      return this.sanitizer.bypassSecurityTrustHtml(value ?? '');
    } else {
      if (element[column.name])
        return this.sanitizer.bypassSecurityTrustHtml(element[column.name] ?? '');
      else return '';
    }
  }

  addSpot(e: any) {
    const row = this.dataSource.data.find((x) => x.rowId == e.rowId);
    this.setRow.emit(row);
  }

  cancelAddSpot() {
    this.IdSpotAdd = 0;
    this.addingSpot = false;
    this.newSpot.emit(0);
  }

  addRow(e: any) {
    this.newRow.emit(+e.id);
  }

  deleteSpot(e: any) {
    this.spotDeleted.emit(e);
  }

  deleteRow(e: any) {
    this.rowDeleted.emit(e);
  }

  duplicateRow(e: any) {
    this.rowDuplicated.emit(e);
  }

  verifyActions(id: number): boolean {
    let value: boolean = true;
    if (this.configuration) {
      if (!this.images) {
        value = true;
        this.enabled = false;
      } else if (!this.images.length) {
        value = true;
        this.enabled = false;
      } else if (!this.IdSpotAdd) {
        value = false;
        this.enabled = true;
      } else {
        if (this.IdSpotAdd == id) {
          value = this.addingSpot ?? false;
          this.enabled = true;
        }
      }
    } else {
      value = true;
      this.enabled = true;
    }
    return value;
  }

  setEditorSize() {
    if (this.textarea?.nativeElement) {
      this.textarea.nativeElement.style.minHeight =
        this.textarea.nativeElement.parentNode.clientHeight - 10 + "px";
    }
  }

  click(row: number, col: number, cellsType: string) {
    if (!this.disabled) {
      this.onMouseDown(row, col, cellsType);
      this.onMouseUp(row, col, cellsType);
    }
  }

  editorCreated(editor: any) {
    editor.focus();
    setTimeout(() => editor.setSelection(editor.getLength(), 0));
  }

  onMouseDown(row: number, col: number, cellsType: string) {
    let enableColums = true;
    const countBuilderColumn = this.columnsBuilder.filter(
      (x) => x.name == cellsType
    ).length;

    if (countBuilderColumn > 0) {
      if (
        this.enableColumnsBuilder == undefined ||
        this.enableColumnsBuilder == false
      ) {
        enableColums = false;
      }
    }
    if (enableColums && ((this.editor && !this.disabled) || (!this.builder && this.canEdit))) {
      if (
        cellsType != "actions" &&
        cellsType != "spotid" &&
        cellsType != "id" &&
        cellsType != "calcdose" &&
        cellsType != "data"
      ) {
        if (!this.selectedCellsState[row][col]) {
          this.tableMouseDown = { rowId: row, colId: col, cellsType };
          this.utils.removeExistingCustomContainers();
        } else {
          this.setEditorSize();
        }

        if (!(this.currentRow == row && this.currentColumn == col)) {
          if (this.currentColumn == -1 && this.currentRow == -1) {
            this.currentRow = row;
            this.currentColumn = col;
          } else {
            this.formatText(row, col);
            this.currentRow = row;
            this.currentColumn = col;
          }
        }
      }
    }
  }

  onMouseUp(row: number, col: number, cellsType: string) {
    let enableColums = true;
    const countBuilderColumn = this.columnsBuilder.filter(
      (x) => x.name == cellsType
    ).length;
    if (countBuilderColumn > 0) {
      if (
        this.enableColumnsBuilder == undefined ||
        this.enableColumnsBuilder == false
      ) {
        enableColums = false;
      }
    }

    if (enableColums) {
      if (
        cellsType != "actions" &&
        cellsType != "spotid" &&
        cellsType != "id" &&
        cellsType != "calcdose"
      ) {
        if (!this.selectedCellsState[row][col]) {
          this.tableMouseUp = { rowId: row, colId: col, cellsType };
          if (this.tableMouseDown) {
            this.newCellValue = "";
            this.updateSelectedCellsState(
              this.tableMouseDown.colId,
              this.tableMouseUp.colId,
              this.tableMouseDown.rowId,
              this.tableMouseUp.rowId
            );
            this.setEditorSize();
          }
          this.editorText =
            this.dataSource.data[row] && this.dataSource.data[row][cellsType]
              ? this.dataSource.data[row][cellsType]
              : "";
        }
      }
    }
  }

  formatText(row: any, col: any) {
    if (this.dataSource.data[row][col]) {
      if (col != "actions" && col != "spotid" && col != "id") {
        const valueCell = this.utils.replaceAll(
          this.utils.replaceAll(
            this.utils.replaceAll(
              this.utils.replaceAll(
                this.dataSource.data[row][col],
                "</p><p><br></p><p>",
                "<br><br>"
              ),
              "</p><p>",
              "<br>"
            ),
            "<p>",
            ""
          ),
          "</p>",
          ""
        );
        this.dataSource.data[row][col] = valueCell;
        this.updateCellSelectedValue(row, col, valueCell);
      }
    }
  }

  createSelectedStates() {
    const cols = new Array(1000).fill(false);
    for (let c = 0; c < 1000; c++) {
      cols[c] = new Array(1000).fill(false);
    }

    this.selectedCellsState = cols;
  }

  updateCellSelectedValue(row: any, columName: any, valueCell: any) {
    if (this.isBuilderColumn(columName)) {
      const IdRow = this.dataSource.data[row].id;
      this.dataSource.data.map((item) => {
        if (item.id == IdRow) {
          item[columName] = valueCell;
        }
      });
    }
  }

  updateSelectedCellsValues(text: string) {
    if (text == null) {
      return;
    }

    if (this.tableMouseDown && this.tableMouseUp) {
      if (this.tableMouseDown.cellsType === this.tableMouseUp.cellsType) {
        const dataCopy: any[] = this.dataSource.data.slice(); // copy and mutate
        let startCol: number;
        let endCol: number;
        let startRow: number;
        let endRow: number;

        if (this.tableMouseDown.colId <= this.tableMouseUp.colId) {
          startCol = this.tableMouseDown.colId;
          endCol = this.tableMouseUp.colId;
        } else {
          endCol = this.tableMouseDown.colId;
          startCol = this.tableMouseUp.colId;
        }

        if (this.tableMouseDown.rowId <= this.tableMouseUp.rowId) {
          startRow = this.tableMouseDown.rowId;
          endRow = this.tableMouseUp.rowId;
        } else {
          endRow = this.tableMouseDown.rowId;
          startRow = this.tableMouseUp.rowId;
        }

        // --Edit cells from the same column
        if (startCol === endCol) {
          console.log("--Edit cells from the same column");
          for (let i = startRow; i <= endRow; i++) {
            dataCopy[i][this.columns[startCol]] = text;
          }
        } else {
          // --Edit cells starting and ending not on the same column
          console.log(
            "--Edit cells starting and ending not on the same column"
          );

          for (let i = startRow; i <= endRow; i++) {
            for (let j = startCol; j <= endCol; j++) {
              dataCopy[i][this.columns[j]] = text;
            }
          }
        }
        console.log(
          "--update: " +
          startRow +
          ", " +
          startCol +
          " to " +
          endRow +
          ", " +
          endCol
        );
        this.dataSource = new MatTableDataSource(dataCopy);
      }
    }
  }

  private updateSelectedCellsState(
    mouseDownColId: number,
    mouseUpColId: number,
    mouseDownRowId: number,
    mouseUpRowId: number
  ) {
    // init selected cells
    for (let i = this.FIRST_EDITABLE_ROW; i <= this.LAST_EDITABLE_ROW; i++) {
      for (let j = this.FIRST_EDITABLE_COL; j <= this.LAST_EDITABLE_COL; j++) {
        this.selectedCellsState[i][j] = false;
      }
    }
    // update selected cells
    let startCol: number;
    let endCol: number;
    let startRow: number;
    let endRow: number;
    if (mouseDownColId <= mouseUpColId) {
      startCol = mouseDownColId;
      endCol = mouseUpColId;
    } else {
      endCol = mouseDownColId;
      startCol = mouseUpColId;
    }

    if (mouseDownRowId <= mouseUpRowId) {
      startRow = mouseDownRowId;
      endRow = mouseUpRowId;
    } else {
      endRow = mouseDownRowId;
      startRow = mouseUpRowId;
    }

    for (let i = startRow; i <= endRow; i++) {
      for (let j = startCol; j <= endCol; j++) {
        this.selectedCellsState[i][j] = true;
      }
    }
    // this.cdRef.detectChanges();
    this.setEditorSize();
  }

  textChanged(e: any, column: any, row: any, col: any) {
    if (e.keyCode === 13 && !e.shiftKey && !e.altKey) {  // Enter key but not shift/alt + enter
      // Ensure the current row is not the last editable row
      if (row < this.LAST_EDITABLE_ROW - 1) {
        // Move the focus to the cell in the next row
        this.currentRow = row + 1;
        row = this.currentRow;
        this.currentColumn = col;
        this.selectedCellsState[row][col] = false;
        this.selectedCellsState[row + 1][col] = true;
        this.click(this.currentRow, this.currentColumn, column.name);
      }
      else { this.escapeKey(e, column, this.currentRow, this.currentColumn) }

    }
    else if (e.keyCode === 9) {  // Tab key
      // Ensure the current column is not the last column
      if (col < this.displayedColumns.length) {
        // Move the focus to the cell in the next column
        this.currentRow = row;
        this.currentColumn = col + 1;
        col = this.currentColumn;
        this.selectedCellsState[row][col] = false;
        this.selectedCellsState[row][col + 1] = true;
        // Get the next column name
        const currentColumnIndex = this.displayedColumns.findIndex(c => c.name === column.name);
        const nextColumnName = this.displayedColumns[currentColumnIndex + 1].name;
        if (
          nextColumnName == "actions" ||
          nextColumnName == "spotid" ||
          nextColumnName == "id" ||
          nextColumnName == "calcdose" ||
          nextColumnName == 'data'
        ) { this.escapeKey(e, this.displayedColumns[col], this.currentRow, this.currentColumn) }
        else this.click(this.currentRow, this.currentColumn, nextColumnName);
      }
    }
    else if (e.keyCode === 27) { this.escapeKey(e, column, row, col) }
  }

  // Escape key or Enter on last row or Tab on last col
  escapeKey(e: any, column: any, row: any, col: any) {
    const contentToRemove = `<br><a id=${this.smallId} style="cursor: pointer">Small</a> | <a id=${this.fitId} style="cursor: pointer">Fit</a> | <a id=${this.largeId} style="cursor: pointer">Original</a><br>`;
    const valueCell = this.utils.replaceAll(
      this.utils.replaceAll(
        this.utils.replaceAll(
          this.utils.replaceAll(
            this.utils.replaceAll(
              this.dataSource.data[row][column.name], contentToRemove, ''),
            "</p><p><br></p><p>",
            "<br><br>"
          ),
          "</p><p>",
          "<br>"
        ),
        "<p>",
        ""
      ),
      "</p>",
      ""
    );
    this.dataSource.data[row][column.name] = valueCell;
    this.updateCellSelectedValue(row, column.name, valueCell);
    const newConfiguration = JSON.parse(this.configuration);
    newConfiguration.data = this.dataSource.data;
    this.tableChanged.emit(JSON.stringify(newConfiguration));
    this.currentRow = -1;
    this.currentColumn = -1;
    this.selectedCellsState[row][col] = false;
  }

  channelsChanged(e: any, column: any, row: any, col: any) {
    const valueCell = this.dataSource.data[row][column.name];
    const newConfiguration = JSON.parse(this.configuration);
    this.dataSource.data[row].data = this.utils.JSONstringify(e);
    newConfiguration.data = this.dataSource.data;
    this.tableChanged.emit(JSON.stringify(newConfiguration));
    this.currentRow = -1;
    this.currentColumn = -1;
    this.selectedCellsState[row][col] = false;
  }

  blurTable(row: any, col: any) {
    this.utils.removeExistingCustomContainers();
    this.selectedCellsState[row][col] = false;
  }

  mouseOut() {
    this.utils.removeExistingCustomContainers();
    // this.selectedCellsState[row][col] = false;
  }

  tableOut() {
    this.utils.removeExistingCustomContainers();
    setTimeout(() => {
      if (!(this.currentColumn == -1 && this.currentRow == -1)) {
        for (let i = this.FIRST_EDITABLE_ROW; i <= this.LAST_EDITABLE_ROW; i++) {
          for (
            let j = this.FIRST_EDITABLE_COL;
            j <= this.LAST_EDITABLE_COL;
            j++
          ) {
            this.selectedCellsState[i][j] = false;
          }
        }
        this.formatText(this.currentRow, this.currentColumn);
        const newConfiguration = JSON.parse(this.configuration);
        newConfiguration.data = this.dataSource.data;
        this.tableChanged.emit(JSON.stringify(newConfiguration));
        this.currentRow = -1;
        this.currentColumn = -1;
      }
    }, 500);
  }

  private processData() {
    const idSeen: any = {};
    const spotSeen: any = {};
    if (this.configuration) {
      const configuration = JSON.parse(this.configuration);
      const localdata: any[] = configuration.data;

      const dataSort = localdata.sort((a, b) => {
        if (a.id == b.id) {
          const spota = +a.spotid?.toString().split(".")[1];
          const spotb = +b.spotid?.toString().split(".")[1];
          return spota - spotb;
        }
        return a.id > b.id ? 1 : -1;
      });
      let dataChanged = false;
      dataSort.map((d) => {
        if (!d.rowId) {
          d.rowId = d.id + ".1";
          dataChanged = true;
        }
      });
      if (dataChanged) {
        this.tableChanged.emit(JSON.stringify(configuration));
      }
      this.datasetExt = dataSort.map((item: any) => {
        const idSpan = idSeen[item.id] ? 0 : localdata.filter((y) => y.id === item.id).length;
        idSeen[item.id] = true;
        spotSeen[item.id] = spotSeen[item.id] || {};
        spotSeen[item.id][item.spotid] = true;
        return { ...item, idSpan, spotSpan: 1 };
      });
    }
  }

  spotColor(e: any) {
    if (e.spotid) {
      const spot = this.spots.find((x) => x.spotText == e.spotid);
      return spot?.origin ? SpotClass.BLUE : SpotClass.RED;
    }
    return SpotClass.BLUE;
  }

  // isMobileDevice() {
  //   return this.utils.isMobileDevice();
  // }

  // rowSelected(row) {
  //   this.newSpot.emit(row.id);
  //   this.setRow.emit(row);
  // }

  calculateDose(reading: string, time: string) {
    if (reading?.includes("^")) {
      reading = reading.replace("^", "e");
    }
    if (time?.includes("^")) {
      time = time.replace("^", "e");
    }
    const ntime = +this.utils.cleanHTML(time);
    const nreading = +this.utils.cleanHTML(reading);
    const calc = (nreading / ntime) * 60;
    // this.warnHigh = !isNaN(calc) && calc > 3.5;
    return isNaN(calc) ? "" : this.utils.roundNumber(calc, 5);
  }

  channelCopied(e: any) {
    this.channelCopy = e;
  }

  checkUpdatedTime(d: any) {
    const data = this.utils.JSONparse(d) as PVData[];
    const now = moment();
    return data.some(item => {
      const itemDate = moment(item.date);
      const timeDifference = Math.abs(itemDate.diff(now, 'seconds')); // Difference in seconds
      return timeDifference < 5;
    });
  }

  reorderTable(
    event: CdkDragDrop<MatTableDataSource<any, MatTableDataSourcePaginator>, any, any>,
    dataSource: MatTableDataSource<any>
  ) {
    if (this.editor) {
      dataSource.data = this.moveItemInArray(dataSource.data, event.previousIndex, event.currentIndex);
      dataSource.filter = "";
      const newConfiguration = JSON.parse(this.configuration);
      newConfiguration.data = this.dataSource.data;
      let id = 0;
      let last = 0;
      this.dataSource.data.map(d => {
        if (d.id != last) {
          last = d.id;
          id++;
        }
        if (d.id != id) {
          d.id = id;
          const rowId = d.rowId.split('.')[1];
          d.rowId = d.id + '.' + rowId;
          if (d.spotid) {
            const spot = this.spots.find(s => s.spotText == d.spotid);
            const spotId = d.spotid.split('.')[1];
            d.spotid = id + '.' + spotId;
            if (spot) spot.spotText = d.spotid;
          }
        }
      });
      newConfiguration.spots = this.spots;
      this.tableChanged.emit(JSON.stringify(newConfiguration));
    }
  }

  moveItemInArray(data: any[], previousIndex: number, currentIndex: number) {
    const currentId = data[previousIndex].id;
    const targetId = data[currentIndex].id;

    // Filter out the items to be moved
    const itemsToMove = data.filter(item => item.id === currentId);
    // Filter out the items that will stay in place
    const itemsNotToMove = data.filter(item => item.id !== currentId);

    // Find the index where items with targetId end
    const targetIndex = currentIndex > previousIndex ? itemsNotToMove.map(item => item.id).lastIndexOf(targetId) + 1 : itemsNotToMove.map(item => item.id).indexOf(targetId);

    // Insert the itemsToMove after the items with targetId
    const result = [
      ...itemsNotToMove.slice(0, targetIndex),
      ...itemsToMove,
      ...itemsNotToMove.slice(targetIndex)
    ];

    return result;
  }

  observeHeaders() {
    // Set up scroll detection
    this.scrollableContainer = document.getElementById('scrollableContainer' + (this.editor ? '-editor' : ''));
    this.scrollEvent = this.renderer.listen(this.scrollableContainer, 'scroll', this.onScroll.bind(this));

    // Set up IntersectionObserver for the table element
    this.observeTableInView();

    // Set up ResizeObserver for the table width
    this.resizeObserver = new ResizeObserver(() => {
      setTimeout(() => {
        this.checkTableVisibility(); // Re-clone the header if the table width changes
      }, 200);
    });

    // Observe the table element for width changes
    const tableElement = document.getElementById('image-table-body-' + this.id);
    if (tableElement) {
      this.resizeObserver.observe(tableElement);
    }
  }

  private observeTableInView(): void {
    const tableElement = document.getElementById('image-table-body-' + this.id);

    if (!tableElement) {
      console.warn(`Table element with ID 'image-table-body-${this.id}' not found.`);
      return;
    }

    this.observer = new IntersectionObserver(this.onTableInView.bind(this), {
      root: null,
      rootMargin: '0px',
      threshold: 0,
    });

    this.observer.observe(tableElement);
  }

  private onScroll(): void {
    if (this.scrollTimeout) {
      clearTimeout(this.scrollTimeout);
    }

    this.scrollTimeout = setTimeout(() => {
      this.checkTableVisibility();
      this.checkCellsVisibility();
      // this.adjustStickyContents();
    }, 200); // Adjust this duration as needed
  }

  private checkTableVisibility(): void {
    const tableElement = document.getElementById('image-table-body-' + this.id);
    // const scrollableContainer = document.getElementById('scrollableContainer');
    const tableHeader = document.getElementById('image-table-header-' + this.id);

    if (tableElement && this.scrollableContainer && tableHeader) {
      const rect = tableElement.getBoundingClientRect();
      const containerTop = this.scrollableContainer.getBoundingClientRect().top;
      const headerHeight = tableHeader.getBoundingClientRect().height;

      const topVisible = rect.top < containerTop;
      const bottomVisible = rect.bottom > containerTop + headerHeight;

      if (topVisible && bottomVisible) {
        this.cloneElement();
      } else {
        this.removeClonedElement();
      }
    }
  }

  private onTableInView(entries: IntersectionObserverEntry[]): void {
    const entry = entries[0];

    if (entry && entry.isIntersecting) {
      this.checkTableVisibility();
    } else {
      this.removeClonedElement();
    }
  }

  private cloneElement(): void {
    const tableHeader = document.getElementById('image-table-header-' + this.id);
    const tableBody = document.getElementById('image-table-body-' + this.id);
    const tableContainer = document.getElementById('image-table-container-' + this.id);

    if (tableHeader) {
      const clonedHeader = tableHeader.cloneNode(true) as HTMLElement;
      clonedHeader.id = 'cloned-header-' + this.id;
      const targetDiv = document.getElementById('cloned-header-container-' + this.id);

      if (targetDiv && this.scrollableContainer) {
        targetDiv.innerHTML = '';
        const containerRect = this.scrollableContainer?.getBoundingClientRect();
        targetDiv.style.top = `${containerRect.top}px`;

        this.applyStyles(tableHeader, clonedHeader);
        clonedHeader.classList.add('fixed-position');

        // Calculate the visible width of the table within the scrolling container
        let tableWidth = 0;
        if (!this.builder)
          tableWidth = (tableContainer?.getBoundingClientRect().width ?? 0) + 8;
        else tableWidth = tableBody?.getBoundingClientRect().width ?? 0;

        targetDiv.appendChild(clonedHeader);

        // Set the width of the cloned header to the visible width
        targetDiv.style.width = `${(tableWidth)}px`;

        console.log('Cloned element added to target div');
      }
    }
  }

  private removeClonedElement(): void {
    const targetDiv = document.getElementById('cloned-header-container-' + this.id);
    if (targetDiv) {
      targetDiv.innerHTML = '';
      console.log('Cloned element removed from target div');
    }
  }

  private applyStyles(originalElement: HTMLElement, clonedElement: HTMLElement): void {
    const computedStyles = window.getComputedStyle(originalElement);

    for (let i = 0; i < computedStyles.length; i++) {
      const property = computedStyles[i];
      if (property != 'display' && property != 'height')
        clonedElement.style.setProperty(property, computedStyles.getPropertyValue(property));
    }

    clonedElement.style.width = computedStyles.width;
    clonedElement.style.height = computedStyles.height;
    clonedElement.style.position = computedStyles.position;
    clonedElement.style.left = computedStyles.left;
    clonedElement.style.right = computedStyles.right;

    const originalChildren = originalElement.children;
    const clonedChildren = clonedElement.children;

    for (let i = 0; i < originalChildren.length; i++) {
      this.applyStyles(originalChildren[i] as HTMLElement, clonedChildren[i] as HTMLElement);
    }
  }


  observeCells(): void {
    const scrollableContainerHeight = this.scrollableContainer?.getBoundingClientRect().height ?? 0;
    this.columnDatas = Array.from(document.querySelectorAll('.column-data')).filter(x => x.innerHTML && x.clientHeight > (scrollableContainerHeight / 2));

    this.columnDatas.forEach(columnData => {
      const columnDataElement = columnData as HTMLElement;

      if (this.scrollableContainer && columnDataElement.offsetHeight > this.scrollableContainer.offsetHeight) {
        const intersectionObserver = new IntersectionObserver(
          (entries) => {
            entries.forEach(entry => {
              this.checkCellsVisibility();
            });
          },
          {
            root: this.scrollableContainer,
            threshold: [0],
          }
        );

        intersectionObserver.observe(columnDataElement);
        this.intersectionObservers.push(intersectionObserver);

        const mutationObserver = new MutationObserver(() => {
          this.checkCellsVisibility();
        });

        mutationObserver.observe(columnDataElement, {
          childList: true, // Observe direct children
          subtree: false, // Observe only direct children, not all descendants
          attributes: false, // Optionally observe attributes if needed
        });
        this.mutationObservers.push(mutationObserver);

        const resizeObserver = new ResizeObserver(() => {
          this.checkCellsVisibility();
        });

        resizeObserver.observe(columnDataElement);
        this.resizeObservers.push(resizeObserver);
      }
    });
  }

  private checkCellsVisibility() {
    this.columnDatas?.forEach(columnData => {
      const firstChild = columnData.firstChild;
      if (this.isElementInViewport(columnData)) {
        if (!this.isElementInViewport(firstChild)) {
          this.makeSticky(columnData);
        }
        else {
          this.resetSticky(columnData);
        }
      }
      else {
        this.resetSticky(columnData);
      }
    });
  }

  isElementInViewport(element: HTMLElement): boolean | null {
    const tableHeader = document.getElementById('image-table-header-' + this.id);
    const elementRect = element.getBoundingClientRect();
    const containerRect = this.scrollableContainer?.getBoundingClientRect();
    const headerHeight = tableHeader?.getBoundingClientRect().height ?? 0;
    if (containerRect)
      // Check if the element is within the scrollable container's viewport
      return (
        elementRect.top < containerRect.bottom && elementRect.bottom > containerRect.top + headerHeight + 5
      );
    else return null;
  }

  private makeSticky(element: HTMLElement): void {
    const tableHeader = document.getElementById('image-table-header-' + this.id);
    const headerHeight = tableHeader?.getBoundingClientRect().height ?? 0;
    const containerTop = this.scrollableContainer?.getBoundingClientRect().top ?? 0;
    const columnDataFixed = document.getElementById(element.id + '-fixed');
    if (columnDataFixed) {
      this.applyStyles(element, columnDataFixed);
      columnDataFixed?.classList.add('display-block');
      columnDataFixed.style.top = `${(containerTop + headerHeight) + 5}px`;
      const height = columnDataFixed.getBoundingClientRect().height ?? 0;
      const bottom = columnDataFixed.getBoundingClientRect().bottom ?? 0;
      const parentBottom = columnDataFixed.parentElement?.getBoundingClientRect().bottom ?? 0;
      if (bottom > parentBottom) {
        const newHeight = height - (bottom - parentBottom) - 5;
        $('#' + columnDataFixed.id).height(newHeight);
        console.log(columnDataFixed);
      }
    }
  }

  private resetSticky(element: HTMLElement): void {
    const columnDataFixed = document.getElementById(element.id + '-fixed');
    columnDataFixed?.classList.remove('display-block');
  }

}

export enum SpotClass {
  RED = "red",
  BLUE = "blue",
}

import { Component, Input, SimpleChanges, OnChanges, OnDestroy, AfterViewInit, OnInit, Injector, Output, EventEmitter } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { BaseComponent } from 'src/app/common/base/base.component';
import { FormType, FormStatusEnum, Roles, WFSaveOptions } from 'src/app/common/enumerations/enumerations';
import { WFComponent } from 'src/app/components/workflow/workflow';
import { MessageBanner } from 'src/app/components/messages/services/message-banner';
import { Resource } from 'src/app/components/catalogs/beamline-catalog/resource/resources';
import { WFSectionLocalResource, WfSignatureResource, WfSignature, SaveTaskResource } from 'src/app/services/work-flow/work-flow';
import { AbsiResource } from '../../../absi';
import { AbsiService } from '../../../absi.service';

@Component({
  selector: 'absi-sectionX',
  templateUrl: './absi-section-x.component.html',
  styleUrls: ['./absi-section-x.component.scss']
})
export class AbsiSectionXComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {

  @Input() section?: WFSectionLocalResource;
  @Input() absi?: AbsiResource | null;
  @Input() collapse!: boolean;
  @Input() message!: MessageBanner;

  @Output() loading = new EventEmitter<boolean>();

  isVacuumSection = false;
  isShieldingsSection = false;
  abbreviated!: boolean;

  beamline!: Resource;

  sectionName!: string;

  FormType = FormType;

  wfSignatures$!: Observable<WFSectionLocalResource[]>;
  wfSignatures?: WfSignatureResource[];
  wfSignaturesSR!: WfSignatureResource[];
  signatures!: WfSignature[];
  subscription!: Subscription;

  components!: WFComponent[];
  components$!: Observable<WFComponent[]>;
  componentsSubs!: Subscription;

  ologButton = false;

  identificator!: number;

  showCompleteButton!: boolean;
  showCloseButton!: boolean;

  completeSignature?: WfSignature;
  closeSignature?: WfSignature;
  loaded = false;

  constructor(
    protected override injector: Injector,
    private _absi: AbsiService,
  ) {
    super(injector);
  }

  ngOnInit(): void {

  }

  ngAfterViewInit(): void {

  }

  ngOnChanges(changes: SimpleChanges) {
    // this.loading.emit(true);
    this.loadComponents();
    this.getData();
  }

  override ngOnDestroy(): void {
    this.componentsSubs?.unsubscribe();
    super.ngOnDestroy();
  }

  loadComponents() {
    this.components$ = this.store.select(state => state.Components.data);
    this.componentsSubs = this.components$.subscribe(data => {
      if (data) {
        this.components = data;
      }
    });
  }

  getData() {
    if (this.section && this.absi?.wfTable) {
      this.wfSignatures = this.section.wfSignatures;
    }
    this.setTasks();
  }

  create() {
    this.wfSignatures = [];
    this.signatures = [];
  }

  setTasks() {
    if (this.wfSignatures) {
      let index = 1;
      if (!this.signatures) {
        this.signatures = [];
      }
      const signatures: WfSignature[] = [];
      this.wfSignatures.map(t => {
        let number = this.section?.number + '.' + index;
        const signature = this.createSignature(t, number);
        if (signature) {
          index++;
          t.component = this.components.find(x => x.id == t.type);
          signatures.push(signature);
        }
      });
      this.signatures = this.renumberArray(signatures);

      if (this.section?.number == 100) {
        this.completeSignature = this.signatures.find(x => x.type == -1 && x.status == FormStatusEnum.Active);
        this.closeSignature = this.signatures.find(x => x.type == -1 && x.status == FormStatusEnum.ReviewAndConcurrence);
        this.signatures = this.signatures.filter(s => s.type == 1).sort((a, b) => this.utils.sort(a.name, b.name));
        this.showCompleteButton = !this.completeSignature?.disabled && this.completeSignature?.status == this.absi?.status;
        this.showCloseButton = !this.closeSignature?.disabled && this.closeSignature?.status == this.absi?.status;
      }
    }
    // this.loading.emit(false);
  }

  createSignature(t?: WfSignatureResource, number: string | null = null): WfSignature | null {
    if (t) {
      const signature: WfSignature = {
        id: t.id,
        number: number,
        approveId: t.approveID,
        unapproveId: t.unapproveID,
        disapproveId: t.disapproveID,
        amendmentId: t.amendmentID,
        name: t.titleBefore,
        question: t.question,
        question2: t.titleAfter,
        question3: t.titleAfter2,
        roles: t.roles.map(r => r.id),
        roleCodes: t.roles.map(r => r.code).join('/'),
        disabled: this.setDisabledTask(t) || this.disabled(t),
        approvedBy: t.signedBy,
        approved: t.signed,
        approvedOn: t.signedOn,
        approve: t.approveEnabled,
        unapprove: t.unapproveEnabled,
        disapprove: t.disapproveEnabled,
        amendment: t.amendmentEnabled,
        required: t.required,
        taskId: t.taskID,
        value: t.value,
        value2: t.numericValue,
        textValue: t.text ? t.text.replace('^', '<sup>') : null,
        type: t.type,
        options: t.options ? JSON.parse(t.options) : [],
        sectionName: t.sectionName,
        isVacuum: false,
        disableEdit: this.disabled(t) || this.setDisabledTask(t),
        visible: t.visible,
        warning: this.absi?.status == FormStatusEnum.Closed ? false : t.warning,
        moreInfo: t.moreInfo ? JSON.parse(t.moreInfo).join('\n') : null,
        component: t.component,
        configuration: t.configuration,
        condition: t.condition,
        code: t.code,
        status: t.status
      };
      return signature;
    }
    else return null;
  }

  updateSignature(t: WfSignatureResource) {
    const index = this.signatures.findIndex(x => x.id == t.id);
    // console.log(this.signatures[index]);

    if (index >= 0) {
      this.signatures[index].disabled = this.setDisabledTask(t) || !t.enabled || this.disabled(t);
      this.signatures[index].approvedBy = t.signedBy;
      this.signatures[index].approved = t.signed;
      this.signatures[index].approvedOn = t.signedOn;
      this.signatures[index].approve = t.approveEnabled;
      this.signatures[index].unapprove = t.unapproveEnabled;
      this.signatures[index].disapprove = t.disapproveEnabled;
      this.signatures[index].amendment = t.amendmentEnabled;
      this.signatures[index].value = t.value;
      this.signatures[index].value2 = t.numericValue;
      this.signatures[index].textValue = t.text ? t.text.replace('^', '<sup>') : null;
      this.signatures[index].disableEdit = this.disabled(t) || this.setDisabledTask(t);
      this.signatures[index].visible = t.visible;
      this.signatures[index].warning = this.absi?.status == FormStatusEnum.Closed ? false : t.warning;
      this.signatures[index].moreInfo = t.moreInfo ? JSON.parse(t.moreInfo).join('\n') : null;
      this.signatures[index].status = t.status;
    }
    // this.cdRef.detectChanges();
  }

  disabled(t: WfSignatureResource) {
    if (!this.getCurrentUser()) {
      return true;
    }
    return !(t.status == this.absi?.status);
  }

  disableButtons() {
    const enable = (this.absi?.status == FormStatusEnum.Active || this.absi?.status == FormStatusEnum.Shielding) && this.hasRoles([Roles.FO, Roles.VAC]);
    return !enable;
  }

  setDisabledTask(t: WfSignatureResource) {
    const enable = this.validateRole(t);
    const approveDisable = this.wfSignatures?.find(x => x.type == 1)?.signed && t.type != 1 && this.section?.number != 100;
    return !enable || approveDisable;
  }

  validateRole(t: WfSignatureResource) {
    let valid = false;

    if (!this.getCurrentUser()) {
      return valid;
    }

    valid = this.hasRoles(t.roles);

    if (t.users.length) {
      valid = t.users.map(u => u.id).includes(this.getCurrentUser()?.id);
    }

    return valid;
  }

  renumberArray(signatures: WfSignature[]) {
    let index = 1;
    let order = 1;
    signatures.map(s => {
      s.order = order;
      order++;
      if (s.required && s.component?.numericHeader) {
        let number = this.section?.number + '.' + index;
        s.number = number;
        index++;
      }
      else {
        s.number = null;
      }
    });
    return signatures;
  }

  check(e: any, taskId?: number, approveId?: number, unapproveId?: number) {
    if (e == true || e.checked == true) {
      this.approve(taskId, approveId);
    }
    else {
      this.unapprove(taskId, unapproveId);
    }
  }

  save(e: any, taskId?: number, taskLocalId?: number, approveId?: number, code?: string) {
    let saveTaskResource = new SaveTaskResource();
    const signature = this.wfSignatures?.find(x => x.taskID == taskLocalId);
    if (signature) {
      signature.text = e;
      signature.wfTaskLocal.text = e;
      signature.signedBy = this.getCurrentUser();
      if (code) {
        const wfTaskLocals = this.section?.wfSignatures?.map(x => x.wfTaskLocal);
        this.wfSignatures?.map(t => {
          if (t.condition?.includes(code) && wfTaskLocals)
            t.required = this.utils.conditionParse(t.condition, wfTaskLocals);
        });
        this.setTasks();
      }
      saveTaskResource = {
        id: taskId ?? 0,
        text: e,
        field: 'text',
        option: WFSaveOptions.MANUAL
      };
      this.saveTask(saveTaskResource);
    }
  }

  saveTask(saveTaskResource: SaveTaskResource) {
    this._absi.saveWFTask(saveTaskResource).subscribe(data => {
      //  this.requestRefresh.emit();
    }, error => {
      console.log(error);
      this.alert.error(error.statusText);
    });
  }

  getChecked(e: any) {
    const keyValues = JSON.parse(e);
    const values = keyValues.find((x: any) => x.key == 'checked').val;
    return values.filter((x: any) => x.checked).map((y: any) => y.value).join(',');
  }

  approve(taskId?: number, actionId?: number) {
    if (taskId && actionId)
      this._absi.sign(taskId, actionId).subscribe(res => {

      }, error => {
        console.log(error);
        this.alert.error(error.error);
      });
  }

  unapprove(taskId?: number, actionId?: number) {
    if (taskId && actionId) {
      this._absi.sign(taskId, actionId).subscribe(res => {
      }, error => {
        console.log(error);
        this.alert.error(error.error);
      });
    }
  }

  signedAll() {
    return !this.signaturesCount();
  }

  signaturesCount() {
    return this.wfSignatures?.filter(s => s.signed).length;
  }
}

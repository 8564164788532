import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { ActionItemsCreate, ActionItemsDelete, ActionItemsRefresh, ActionItemsUpdate } from "./actionItems.action";
import { ActionItem } from "src/app/common/models/action-item";
import { ActionItemService } from "../action-item.service";

export class ActionItemsStateModel {
  ActionItems: ActionItem[] = [];
}

@State<ActionItemsStateModel>({
  name: 'ActionItems',
  defaults: {
    ActionItems: [],
  }
})
@Injectable()
export class ActionItemsState {
  constructor(
    private ActionItemsService: ActionItemService
  ) { }

  @Selector()
  static read(state: ActionItemsStateModel) {
    return state.ActionItems;
  }

  @Action(ActionItemsRefresh)
  refresh({ patchState }: StateContext<ActionItemsStateModel>, { AbsiId }: ActionItemsRefresh) {
    if (AbsiId) {
      this.ActionItemsService.readByForm(AbsiId).subscribe(
        data => patchState({ ActionItems: data }),
        error => console.error(error)
      );
    }
    else {
      patchState({ ActionItems: [] });
    }
  }

  @Action(ActionItemsCreate)
  create({ getState, patchState }: StateContext<ActionItemsStateModel>, { item }: ActionItemsCreate) {
    const state = getState();
    const index = state.ActionItems.map(x => x.id).indexOf(item.id);

    if (index >= 0) {
      state.ActionItems.splice(index, 1);
      patchState({
        ActionItems: [...state.ActionItems, item]
      });
    }
    else {
      patchState({ ActionItems: [...state.ActionItems, item] });
    }
  }

  @Action(ActionItemsUpdate)
  update({ getState, patchState }: StateContext<ActionItemsStateModel>, { id, item }: ActionItemsUpdate) {
    const state = getState();
    const index = state.ActionItems.map(x => x.id).indexOf(id);

    if (index >= 0) {
      state.ActionItems.splice(index, 1);
      patchState({
        ActionItems: [...state.ActionItems, item]
      });
    }
  }

  @Action(ActionItemsDelete)
  delete({ getState, patchState }: StateContext<ActionItemsStateModel>, { id }: ActionItemsDelete) {
    patchState({
      ActionItems: getState().ActionItems.filter(x => x.id != id)
    });
  }


}

import { Component, EventEmitter, OnDestroy, OnInit, Output, QueryList, ViewChildren, ViewContainerRef, Injector, OnChanges, SimpleChanges } from '@angular/core';

import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { BaseComponent } from 'src/app/common/base/base.component';
import { CatalogService } from '../../catalog-service';
import { Observable, Subscription } from 'rxjs';
import { Roles, Catalog } from 'src/app/common/enumerations/enumerations';
import { Role } from 'src/app/components/catalogs/roles/services/role';
import { utils } from 'src/app/modules/libs/utils';
import { Catalogs } from '../../catalog-model';
import { PendingCatalog, PendingRole } from 'src/app/services/pending-approvals/pending-approval';

@Component({
  selector: 'app-roles-list',
  templateUrl: './roles-list.component.html',
  styleUrls: ['./roles-list.component.scss']
})
export class RolesListComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {

  @Output() loading = new EventEmitter<boolean>();
  @Output() rowSelected = new EventEmitter<any>();
  @Output() clearEvent = new EventEmitter<any>();

  roles!: Role[];
  rolesfiltered!: Role[];
  roles$!: Observable<Role[]>;
  rolesSubs!: Subscription;
  roleCreatedCode!: string | null;
  filter!: string;

  constructor(
    protected override injector: Injector,
    private _catalogService: CatalogService,
    private route: ActivatedRoute,
  ) {
    super(injector);
  }
  public displayedColumnsRoles: string[] = ['role', 'category', 'group', 'name'];
  public dataSource!: MatTableDataSource<Role>;

  public selectedRowIndex?: number;
  public roleIndex!: number;

  public createAvailable?: boolean;
  public showInactive!: boolean;
  public pending = false;
  rowHighlighted: any;
  paramRoleID?: number;

  @ViewChildren('matrow', { read: ViewContainerRef }) rows!: QueryList<ViewContainerRef>;

  override ngOnDestroy(): void {
    this.rolesSubs?.unsubscribe();
  }

  ngOnInit() {
    this.clear();
    this.refreshRoles();

    this.route.params.subscribe(params => {
      const roleID = +params['roleID'];
      if (roleID) {
        this.paramRoleID = roleID;
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.createAvailable = this.getCurrentUser()?.userRole?.some(r => r.roleID === Roles.ADM || r.roleID == Roles.PC);
  }

  refreshRoles(event?: any) {
    if (!this.dataSource) {
      this.dataSource = new MatTableDataSource();
    }
    this.loadRoles(this.isSA());
  }

  selectParam(id: number) {
    const role = this.roles?.find(x => x.id === id);
    if (role) {
      this.rowHighlighted = null;
      this.showIndex(role.id);
      // this.getRoleInfo(role);
    }
  }

  loadRoles(join: boolean) {
    this.loading.emit(true);
    this.clear();
    const pendingRole = this._catalogService.pendingCatalog;
    if (pendingRole) this.showInactive = true;
    this._catalogService.GetJoinCreated(Catalog.Roles, join).toPromise().then(data => {
      if (data && !(data?.roles?.length)) {
        data.roles = [];
      }
      if (data && this.showInactive)
        this.roles = data.roles as Role[];
      else if (data)
        this.roles = data.roles?.filter(x => x.status == 1) as Role[];
      this.applyFilter();
      this.roles = this.roles.sort((a, b) => this.utils.sort(a.code, b.code));
      this.dataSource = new MatTableDataSource(this.roles);

      if (pendingRole && pendingRole.id > 0) {
        this.showIndex(pendingRole.id);
      }
      else if (pendingRole && !pendingRole.id) {
        this.showIndex(pendingRole.id * -1);
      } else if (this.roleCreatedCode) {
        const row = this.dataSource.data.find(x => x.code == this.roleCreatedCode);
        this.getRoleInfo(row);
        this.roleCreatedCode = null;
      }
      else if (this.paramRoleID) {
        this.selectParam(this.paramRoleID);
        this.paramRoleID = 0;
      }
      else {
        this.loading.emit(false);
      }
      this.loading.emit(false);
    }).finally(() => {
      this.loading.emit(false);
    });
  }

  applyFilter(filterValue?: string) {
    this.filter = filterValue?.toLowerCase() ?? '';
    this.dataSource = new MatTableDataSource(this.roles.filter(r => r.aplGroupRole?.aplGroup?.aplGroupMaster?.aplCategoryMaster?.aplCategory?.code?.toLowerCase().includes(this.filter) || r.name?.toLowerCase().includes(this.filter) || r.code?.toLowerCase().includes(this.filter) || r.aplGroupRole?.aplGroup?.name?.toLowerCase().includes(this.filter)));
  }

  showRolesInactive() {
    this.clearEvent.emit();
    this.refreshRoles();
  }

  roleCreated(code: string) {
    this.roleCreatedCode = code;
    this._catalogService.pendingCatalog = undefined;
    this.rowHighlighted = null;
    this.pending = false;
    this.refreshRoles();
  }

  async getRoleInfo(row?: Role) {
    if (this.rowHighlighted?.id == row?.id) {
      this.rowHighlighted = null;
      this.selectedRowIndex = -1;
      this._catalogService.pendingCatalog = undefined;
      this.rowSelected.emit(null);
    }
    else {
      if (await this.canDeactivate()) {
        const role = utils.cloneDeep(row);
        this.rowHighlighted = role;
        this._catalogService.pendingCatalog = { id: role?.id } as PendingCatalog;
        this.rowSelected.emit(this.rowHighlighted);
      }
    }
  }

  setOriginalRole(id: number) {
    this.selectParam(id);
  }

  async createRole() {
    if (await this.canDeactivate()) {
      this.selectedRowIndex = 0;
      const role = new Role();
      role.id = 0;
      this.rowSelected.emit(role);
    }
  }

  clear() {
    this.roles = [];
  }

  sortData(sort: Sort) {
    const data = this.roles;
    let sortedData: Role[];
    if (!sort.active || sort.direction === '') {
      sortedData = data;
      return;
    }
    const isDesc = sort.direction === 'desc';
    sortedData = data.sort((a, b) => utils.sort(a.code, b.code, isDesc));
    this.dataSource = new MatTableDataSource(sortedData);
  }

  showIndex(id: number) {
    this.roleIndex = id;
    this.selectedRowIndex = this.dataSource.data.findIndex(x => x.id == id);
    if (this.selectedRowIndex == -1) {
      this.selectedRowIndex = this.dataSource.data.findIndex(x => x.id == -id);
    }
    const roleSelected = this.dataSource.data[this.selectedRowIndex];
    this.showElement(this.selectedRowIndex, 600);
    if (roleSelected) {
      this.getRoleInfo(roleSelected);
    }
    this.loading.emit(false);
  }

  showElement(index: number, height: number) {
    const that = this;
    setTimeout(() => {
      const indexstr = index.toString();
      const row = that.rows.find(r => r.element.nativeElement.getAttribute('position') === indexstr);
      if (row) {
        const rect = row.element.nativeElement.getBoundingClientRect();
        if ((rect.y <= 0) || ((rect.y + rect.height) > height)) {
          row.element.nativeElement.scrollIntoView(false, { behavior: 'instant' });
        }
      }
    }, 300);
  }

  getPosition(row: any): number {
    const i = this.dataSource.data.findIndex(r => {
      return r.id == row.id;
    });
    return i;
  }

}

import { Component, Inject, Injector, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
// import { AlertService } from 'src/app/controls/alert/alert.service';
// import { RSSFunctionalTestService } from 'src/app/Services/rsswa/rssfunctional-test.service';
// import { WFSectionLocalResource } from 'src/app/Services/WorkFlow/work-flow';
// import { RSSFunctionalTest } from 'src/app/_interface/RSSWA/rss-functional-test.model';
// import { ChecklistBuilderService } from '../checklist-builder/checklist-builder.service';
import { ChecklistTemplate } from '../../checklists';
import { AlertService } from 'src/app/services/alert/alert.service';
import { RSSFunctionalTestService } from 'src/app/services/rss-functional-test/rss-functional-test.service';
import { WFSectionLocalResource } from 'src/app/services/work-flow/work-flow';
import { RSSFunctionalTest } from '../../../rsswa/rss-functional-test';
import { BaseComponent } from 'src/app/common/base/base.component';
import { ChecklistBuilderService } from '../checklist-builder.service';

export class ChecklistBuilderEditPopupParameters {
  public rssFunctionalTest!: RSSFunctionalTest;
  public amendment!: boolean;
}

@Component({
  selector: 'checklist-builder-edit-popup',
  templateUrl: './checklist-builder-edit-popup.component.html',
  styleUrls: ['./checklist-builder-edit-popup.component.scss']
})
export class ChecklistBuilderEditPopupComponent extends BaseComponent implements OnInit {
  loading: boolean = false;
  checklistTemplate!: ChecklistTemplate;
  sections?: WFSectionLocalResource[];
  resource?: string | null;
  templateType?: string;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: ChecklistBuilderEditPopupParameters,
    public dialogRef: MatDialogRef<ChecklistBuilderEditPopupComponent>,
    private checklistBuilderService: ChecklistBuilderService,
    private rssFunctionalTestService: RSSFunctionalTestService,
    protected override injector: Injector
  ) {
    super(injector);
    this.resource = this.data?.rssFunctionalTest?.checklistTemplate?.resource?.name;
    this.templateType = this.data?.rssFunctionalTest?.checklistTemplate?.templateType?.name;
  }

  ngOnInit(): void {
    this.loading = true;
    this.checklistBuilderService.getchecklistTemplateById(this.data.rssFunctionalTest.checklistTemplateID).subscribe(
      data => {
        this.checklistTemplate = data;
        this.sections = this.checklistTemplate?.wfTable?.wfTableLocal?.wfSectionLocals?.sort((a, b) => this.utils.sort(a.order, b.order));
      },
      error => {
        this.alert.error(error.error);
        this.dialogRef.close();
      },
      () => this.loading = false,
    );
  }

  save() {
    this.loading = true;
    if (this.data.rssFunctionalTest.id)
      this.rssFunctionalTestService.update(this.data.rssFunctionalTest.id, this.data.rssFunctionalTest, this.data.amendment).subscribe(
        () => this.alert.success("The RSS functional test was updated"),
        error => this.alert.error(error.error),
        () => this.loading = false,
      );
  }

}

<div class="card section">
  <div class="card-body">
    <div class="row">
      <div class="col-1 pt-2">
        <h6>{{ number ?? "#" }}</h6>
      </div>
      <div class="col-9 pt-2 d-flex justify-content-between">
        <mat-label
          *ngIf="radioQuestion"
          [innerHTML]="question ?? '' | placeholderHighlight : editor"
        ></mat-label>
        <div class="d-flex justify-content-start">
          <!-- <mat-label
            *ngIf="radioQuestion"
            [innerHTML]="radioQuestion | placeholderHighlight : editor"
          ></mat-label> -->
          <div class="ml-2">
            <fieldset
              class="field-group"
              [ngClass]="{ 'border-none': !radioQuestion }"
            >
              <legend *ngIf="radioQuestion">
                <span
                  [innerHTML]="radioQuestion | placeholderHighlight : editor"
                ></span>
              </legend>
              <legend *ngIf="!radioQuestion && question">
                <span
                  [innerHTML]="question | placeholderHighlight : editor"
                ></span>
              </legend>

              <mat-radio-group
                [disabled]="disabled || checked"
                [ngClass]="{ 'd-flex': !radioQuestion }"
              >
                <mat-radio-button
                  class="mx-2"
                  *ngFor="let r of radioOptions"
                  [checked]="r.checked"
                  [value]="r.value"
                  (change)="radioChange($event)"
                >
                  <span
                    [innerHTML]="r.label | placeholderHighlight : editor"
                  ></span>
                </mat-radio-button>
              </mat-radio-group>
            </fieldset>
          </div>
        </div>
      </div>

      <div class="col-2 d-flex justify-content-end">
        <div *ngIf="checkboxVisible" class="d-flex flex-row align-items-center">
          <mat-checkbox-with-roles
            [labelPosition]="'before'"
            [checked]="checked"
            [disabled]="!checkboxEnabled()"
            (change)="checkboxChanged($event)"
            [color]="color"
            [roleCodes]="roleCodes"
            [warning]="moreInfo"
            [user]="user"
            [date]="date"
          >
          </mat-checkbox-with-roles>
        </div>
      </div>
    </div>
  </div>
</div>

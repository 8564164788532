<rsswa-section1
  id="section1"
  [rsswa]="rsswa"
  [showAmendment]="showAmendment"
  #section1
  (loading)="setLoading($event)"
></rsswa-section1>
<div
  *ngIf="
    rsswa?.id &&
    rsswa?.status != formStatusEnum.New &&
    rsswa?.status != formStatusEnum.Draft
  "
>
  <rsswa-section3
    id="section3"
    [rsswa]="rsswa"
    #section3
    (loading)="setLoading($event)"
  ></rsswa-section3>
  <rsswa-section4
    id="section4"
    [rsswa]="rsswa"
    #section4
    (loading)="setLoading($event)"
  ></rsswa-section4>
  <div class="container-fluid">
    <worker-verification-table
      [currentDocument]="rsswa"
      [disabled]="testingCompleteSigned"
      *ngIf="
        rsswa &&
        rsswa?.status !== formStatusEnum.New &&
        rsswa?.status != formStatusEnum.Draft
      "
    ></worker-verification-table>
  </div>
  <rsswa-section5
    [rsswa]="rsswa"
    id="section5"
    #section5
    (loading)="setLoading($event)"
  ></rsswa-section5>
  <rsswa-section6
    id="section6"
    [rsswa]="rsswa"
    #section6
    (loading)="setLoading($event)"
  ></rsswa-section6>
  <div class="container-fluid mt-4">
    <comments-and-attachments
      id="section8"
      #section8
      [currentDocument]="rsswa"
      headerClass="h5"
    ></comments-and-attachments>
    <rsswa-log id="log" [rsswa]="rsswa" class="d-print-none" #log> </rsswa-log>
  </div>
</div>

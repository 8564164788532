import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  OnInit,
  OnDestroy,
  OnChanges,
  SimpleChanges,
  Injector,
} from '@angular/core';
import { Subscription, Observable } from 'rxjs';
import { BaseComponent } from 'src/app/common/base/base.component';
import { WFComponent } from 'src/app/components/workflow/workflow';
import { ClEditorComponent } from 'src/app/controls/checklist-components/cl-editor/cl-editor.component';
import { YesNoDialogComponent } from 'src/app/controls/yes-no-dialog/yes-no-dialog.component';
import { ProcedureSignatureType } from 'src/app/components/procedure/enums/procedure-signature-type.enum';
import { Procedure } from 'src/app/components/procedure/models/procedure.model';
import {
  WfTaskLocalResource,
  WfSignature,
} from 'src/app/services/work-flow/work-flow';
import {
  ChecklistTemplate,
  ChecklistTemplateStatusEnum,
  Option,
  RoleOption,
} from '../../../checklists';
import { ChecklistBuilderService } from '../../checklist-builder.service';
import { ChecklistBuilderTaskMoreComponent } from './checklist-builder-task-more/checklist-builder-task-more.component';
import {
  Action,
  ComponentType,
} from 'src/app/common/enumerations/enumerations';
import { ChecklistBuilderAnnotationEditComponent } from '../../checklist-builder-annotations/checklist-builder-annotation-edit/checklist-builder-annotation-edit.component';
import { ChecklistBuilderAnnotationsComponent } from '../../checklist-builder-annotations/checklist-builder-annotations.component';

@Component({
  selector: 'checklist-builder-task',
  templateUrl: './checklist-builder-task.component.html',
  styleUrls: ['./checklist-builder-task.component.scss'],
})
export class ChecklistBuilderTaskComponent
  extends BaseComponent
  implements OnInit, OnChanges, OnDestroy {
  @Input() task!: WfTaskLocalResource;
  @Input() currentVersion!: ChecklistTemplate;
  @Input() procedure?: Procedure | null;
  @Input() disabled: boolean = false;

  @Output() deletedTask = new EventEmitter();
  @Output() updated = new EventEmitter();
  @Output() loading = new EventEmitter();
  @Output() duplicated = new EventEmitter();

  @ViewChild(ChecklistBuilderAnnotationsComponent)
  public annotations!: ChecklistBuilderAnnotationsComponent;
  @ViewChild(ChecklistBuilderTaskMoreComponent)
  public moreinfo!: ChecklistBuilderTaskMoreComponent;

  editing!: boolean;
  tmpName!: string;

  color!: string;
  checklistTemplateStatusEnum = ChecklistTemplateStatusEnum;
  expanded!: boolean;
  errorMessage!: string;
  subAppState!: Subscription;

  isRCP!: boolean;
  s: WfSignature = {};

  components!: WFComponent[];
  components$!: Observable<WFComponent[]>;
  componentsSubs!: Subscription;

  hasAnnotations!: boolean;

  componentsToSwapText = [
    ComponentType.BRATOI,
    ComponentType.CheckboxAndRadio,
    ComponentType.CheckboxAndTextInput,
    ComponentType.ScheduleAndCheckbox,
  ];

  constructor(
    protected override injector: Injector,

    private service: ChecklistBuilderService,
    public cdRef: ChangeDetectorRef
  ) {
    super(injector);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.disabled = this.disabled || (this.task.logID ?? 0) > 0;
  }

  ngOnInit() {
    this.loadComponents();
    this.isRCP = this.currentVersion?.documentTypeID == 200;
    this.refreshSignature();
  }

  loadComponents() {
    this.components$ = this.store.select((state) => state.Components.data);
    this.componentsSubs = this.components$.subscribe((data) => {
      if (data.length) this.components = data;
    });
  }

  refreshSignature() {
    if (this.task && !this.task.component)
      this.task.component = this.components.find(
        (c) => c.id == this.task.componentID
      );
    this.getSignature(this.task);
    if (this.annotations) {
      setTimeout(() => {
        this.hasAnnotations = this.annotations?.hasAnnotations;
      }, 100);
    }
  }

  getSignature(t: WfTaskLocalResource) {
    if (t && t.wfTasks?.length) {
      this.s = {
        id: t.id,
        type: t.componentID,
        component: this.components.find((c) => c.id == t.componentID),
        number: t.component?.numericHeader
          ? (t.name ?? 0 + 1).toString()
          : null,
        required: t.wfTasks?.[0].required,
        name: t.name,
        code: t.code,
        question: t.name3,
        question2: t.name2,
        question3: t.name4,
        options: this.utils.JSONparse(t.options)
          ? this.utils.JSONparse(t.options)
          : [],
        condition: t.condition,
        configuration: t.configuration,
        scheduleResourceId: t.scheduleResourceId,
        scheduleTypeId: t.scheduleType,
        taskId: 0,
        order: t.order,
        disabled: true,
        enabled: true,
        stepId: t.wfStepLocalID,
        roles: t.wfRoleLocals?.map((x) => x.roleID),
        roleCodes: t.wfRoleLocals?.map((x) => x.role?.code).join('/'),
        approve:
          t.wfActionLocals?.find((x) => x.action == Action.Approve) != null,
        approveId: t.wfActionLocals?.find((x) => x.action == Action.Approve)
          ?.id,
        unapprove:
          t.wfActionLocals?.find((x) => x.action == Action.Unapprove) != null,
        unapproveId: t.wfActionLocals?.find((x) => x.action == Action.Unapprove)
          ?.id,
        disapprove:
          t.wfActionLocals?.find((x) => x.action == Action.Disapprove) != null,
        disapproveId: t.wfActionLocals?.find(
          (x) => x.action == Action.Disapprove
        )?.id,
        invalid: this.hasErrors(),
      };
      if (this.s?.configuration) {
        this.color = JSON.parse(this.s?.configuration).color
          ? JSON.parse(this.s?.configuration).color
          : 'primary';
      }
    }

    return this.s;
  }

  edit(disabled: boolean) {
    const s = this.s;
    const editor = this.dialog.open(ClEditorComponent, {
      maxHeight: window.innerHeight + 'px',
      maxWidth: '100%',
      minWidth: '60%',
      data: {
        s,
        formType: this.currentVersion.documentType?.code,
        serialNo: this.currentVersion.serialNo,
        roles: this.task.wfRoleLocals?.map((x) => x.role),
        checklistTemplate: this.currentVersion,
        disabled,
      },
      disableClose: true,
      autoFocus: false,
      hasBackdrop: true,
    });
    editor.afterClosed().subscribe(async (data) => {
      if (data) {
        this.service
          .updateWFTaskLocal(data)
          .subscribe((data: WfTaskLocalResource) => {
            this.task = data;
            this.updated.emit(this.task);
          });
      }
    });
  }

  duplicate() {
    const yesno = this.dialog.open(YesNoDialogComponent, {
      width: '400px',
      data: {
        message: this.getMessage('GenChklistBldr_DuplicateTaskConfimation')
          .description,
        icon: 'question',
      },
    });
    yesno.afterClosed().subscribe((data) => {
      if (data && this.task.id) {
        this.service.duplicateTask(this.task.id).subscribe(
          (task) => {
            task.component = this.components.find(
              (c) => c.id == task.componentID
            );
            this.duplicated.emit(task);
          },
          (error) => {
            console.log(error);
          }
        );
      }
    });
  }

  delete() {
    const yesno = this.dialog.open(YesNoDialogComponent, {
      width: '400px',
      data: {
        message: this.getMessage('GenChklistBldr_DeleteTaskConfimation')
          .description,
        icon: 'stop',
      },
    });
    yesno.afterClosed().subscribe((data) => {
      if (data) {
        this.loading.emit(true);
        this.service.deleteTask(this.task).toPromise().then(() => {
          this.deletedTask.emit(this.task);
        },
          (error) => {
            console.log(error);
          }
        ).finally(() => this.loading.emit(false));
      }
    });
  }

  cancel() {
    this.editing = false;
  }

  changeRequired(e: any) {
    const required = e.checked;
    if (this.task.wfTasks?.length) {
      this.task.wfTasks[0].required = required;
      this.service.saveRequired(this.task.wfTasks[0]).subscribe((data) => {
        this.task = data;
        this.updated.emit(this.task);
      });
    }
  }

  change(e: any) {
    let configuration = this.utils.JSONparse(this.s.configuration);
    if (!configuration) {
      configuration = {};
    }
    const data = this.utils.JSONparse(e).data;
    if (data) {
      configuration.data = data;
    }
    const widths = JSON.parse(e).widths;
    if (widths) {
      configuration.widths = widths;
    }
    this.s.configuration = JSON.stringify(configuration);
    const wf = { s: this.s, roles: this.task.wfRoleLocals };
    this.service
      .updateWFTaskLocal(wf)
      .subscribe((data: WfTaskLocalResource) => {
        this.task = data;
        this.refreshSignature();
        this.updated.emit(this.task);
      });
  }

  annotate() {
    const editor = this.dialog.open(ChecklistBuilderAnnotationEditComponent, {
      width: '80vw',
      maxHeight: '90vh',
      data: {
        s: this.s,
        task: this.task,
        annotation: null,
        currentVersion: this.currentVersion,
        templateId: this.currentVersion.id,
      },
      autoFocus: false,
      disableClose: true,
      hasBackdrop: true,
    });
    editor.afterClosed().subscribe(async (data) => {
      if (data) {
        this.task = data;
        this.refreshSignature();
      }
    });
  }

  hasErrors() {
    const taskError = this.service.hasErrors(
      this.task,
      this.currentVersion?.documentType?.checklistExecStatuses
    );
    this.errorMessage = taskError.errors;
    return taskError.value;
  }

  isReviewer() {
    if (this.procedure) {
      const users = this.procedure.procedureSignatures?.filter(
        (x) => x.signatureType == ProcedureSignatureType.Reviewer && !x.signedOn
      );
      return users
        ?.map((x) => x.userID)
        .includes(this.getCurrentUser()?.id ?? 0);
    }
    return false;
  }

  isApprover() {
    if (this.procedure) {
      const users = this.procedure.procedureSignatures?.filter(
        (x) => x.signatureType == ProcedureSignatureType.Approver && !x.signedOn
      );
      return users
        ?.map((x) => x.userID)
        .includes(this.getCurrentUser()?.id ?? 0);
    }
    return false;
  }

  isPreparer() {
    return this.hasRoles(
      this.currentVersion.documentType?.templateRolePermissions?.map(
        (x) => x.roleID
      )
    );
  }

  setLoading(e: boolean) {
    this.loading.emit(e);
  }

  annotationChanged(e: WfTaskLocalResource) {
    this.task = e;
    this.refreshSignature();
  }
}

import { WFSectionLocalResource } from "src/app/services/work-flow/work-flow";


export class DeletedSectionsRefresh {
  static readonly type = '[DeletedSections] Refresh';
  constructor(public checklistTemplateID: number) { }
}

// export class DeletedSectionsCreate {
//   static readonly type = '[DeletedSections] Create';
//   constructor(public item: WFSectionLocalResource) { }
// }

// export class DeletedSectionsDelete {
//   static readonly type = '[DeletedSections] Delete';
//   constructor(public logID: number) { }
// }

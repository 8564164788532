import { Injectable, Injector } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { BaseService } from 'src/app/common/base/base.service';
import { ActionPendingCatalog, StatusPendingCatalog, Catalog, Roles, PendingResourcesType } from 'src/app/common/enumerations/enumerations';
import { TokenInfoService } from 'src/app/services/authentication/token-info.service';
import { PendingCatalogs, PendingUser, PendingRole, PendingAccelerator, PendingNotificationTemplate, PendingResource } from 'src/app/services/pending-approvals/pending-approval';
import { BeamlineShutterRelation, Resource } from 'src/app/components/catalogs/beamline-catalog/resource/resources';
import { PrivilegeEnum } from 'src/app/services/role-privilege/privilege-enum';
import { Role } from 'src/app/components/catalogs/roles/services/role';
import { User } from 'src/app/components/catalogs/user-catalog/services/user';
import { AppStateService } from 'src/app/store/app-state-service';

@Injectable({
  providedIn: 'root'
})
export class PendingApprovalsV2Service extends BaseService<PendingApprovals, number> {

  resources!: BeamlineShutterRelation[];
  resources$!: Observable<BeamlineShutterRelation[]>;
  resourcesSubs!: Subscription;

  allResources!: Resource[];
  allResources$!: Observable<Resource[]>;
  allResourcesSubs!: Subscription;

  users!: User[];
  users$!: Observable<User[]>;
  usersSubs!: Subscription;

  roles!: Role[];
  roles$!: Observable<Role[]>;
  rolesSubs!: Subscription;

  pendingCatalogs!: PendingCatalogs;
  pendingCatalogs$!: Observable<PendingCatalogs>;
  pendingCatalogsSubs!: Subscription;

  myPendingCatalogs!: PendingCatalogs;
  myPendingCatalogs$!: Observable<PendingCatalogs>;
  myPendingCatalogsSubs!: Subscription;

  assSubs!: Subscription;

  catalogNames: string[] = [
    'None',
    'Beamline',
    'Users',
    'Role',
    'Accelerator',
    'Notifications',
    'Privileges',
    'Trainings'
  ];

  constructor(
    protected override injector: Injector,
    private tis: TokenInfoService
  ) {
    super(injector, 'reviews')
  }

  private loadResources() {
    this.resources$ = this.store.select(state => state.BeamlineShutter.data);
    this.resourcesSubs = this.resources$.subscribe(data => {
      if (data?.length > 0) {
        this.resources = data;
      }
    });
  }

  loadAllResources() {
    this.allResources$ = this.store.select(state => state.Resources.data);
    this.allResourcesSubs = this.allResources$.subscribe(data => {
      this.allResources = data;
      this.loadResourcesData();
      this.loadRSSDatabase();
    });
  }

  private loadUsers() {
    this.users$ = this.store.select(state => state.Users.data);
    this.usersSubs = this.users$.subscribe(data => {
      this.users = data;
    });
  }

  loadRoles() {
    this.roles$ = this.store.select(state => state.Roles.data);
    this.rolesSubs = this.roles$.subscribe(data => {
      this.roles = data;
    });
  }

  getPendingApprovals(): Promise<PendingApprovals> {
    return new Promise((resolve) => {
      this.pendingCatalogs$ = this.store.select(state => state.PendingCatalogs.data);
      this.pendingCatalogsSubs = this.pendingCatalogs$.subscribe(data => {
        this.loadResources();
        this.loadAllResources();
        this.loadUsers();
        this.loadRoles();
        if (data && this.currentUser) {
          const p: PendingApprovals = {
            accelerators: this.loadAcceleratorData(data).filter(() => this.isSA()),
            users: this.filterPendingUsers(this.loadUsersData(data)),
            roles: this.filterPendingRoles(this.loadRolesData(data)),
            notifications: this.loadNotificationData(data).filter(() => this.isSA()),
            resources: this.loadResourcesData(),
            rssDatabase: this.loadRSSDatabase()
          };
          p.total = p.accelerators.length + p.users.length + p.roles?.length + p.notifications.length + p.resources.length + p.rssDatabase.length;
          resolve(p);
        }
      });
    });
  }

  private loadAcceleratorData(data: any) {
    const p = data.pendingAccelerators;
    p?.map((x: any) => {
      x.acceleratorName = this.allResources?.find(r => r.id == x.acceleratorId) ? this.allResources?.find(r => r.id == x.acceleratorId)?.name : x.acceleratorName;
      x.actionName = ActionPendingCatalog[x.action];
      x.statusName = StatusPendingCatalog[x.status];
    });
    return p ? p : [];
  }

  private loadUsersData(data: any) {
    const p = data.pendingUsers as PendingUser[];
    p?.map(x => {
      x.userName = this.users?.find(u => u.id == x.userId) ? this.users?.find(u => u.id == x.userId)?.name : x.userName;
      x.actionName = ActionPendingCatalog[x.action];
      x.statusName = StatusPendingCatalog[x.status];
    });
    return p ? p : [];
  }

  private loadRolesData(data: any) {
    const p = data.pendingRoles as PendingRole[];
    p?.map(x => {
      const role = this.roles.find(r => r.id == x.roleId);
      x.name = role ? (role.code + ' - ' + role.name) : (x.roleCode + ' - ' + x.roleName);
      x.actionName = ActionPendingCatalog[x.action];
      x.statusName = StatusPendingCatalog[x.status];
      x.changes = this.getChanges(x.modifyingResult.catalogs ?? []);
    });
    return p ? p : [];
  }

  private getChanges(catalogs: Catalog[]) {
    const names: string[] = [];
    catalogs.map(x => {
      names.push(this.catalogNames[x]);
    });
    return names.join(', ');
  }

  private filterPendingRoles(data: PendingRole[]) {
    const p = data.filter(r => {
      const role = this.roles.find(x => x.id == r.roleId);
      const apl = (role?.aplGroupRole?.aplGroup.aplGroupManagers.map(x => x.userID).includes(this.currentUser?.id) || role?.aplGroupRole?.aplGroup.aplGroupMaster?.aplCategoryMaster?.aplCategory?.aplCategoryManagers.map(x => x.userID).includes(this.currentUser?.id ?? 0) || this.currentUser?.userRole?.map(x => x.roleID).includes(Roles.SA)) && this.currentUser?.id != r.createdBy;
      const priv = this.isSA() || (!this.isSA() && (r.modifyingResult.catalogs?.length == 1 && !r.modifyingResult.catalogs.includes(Catalog.RolePrivileges) || r.modifyingResult.catalogs?.length != 1));
      return apl && priv;
    });
    return p;
  }

  private filterPendingUsers(data: PendingUser[]) {
    const p = data.filter(u => this.isSA() && u.createdBy != this.currentUser?.id);
    return p;
  }

  private loadNotificationData(data: any) {
    const p = data.pendingNotificationTemplates;
    p?.map((x: any) => {
      x.actionName = ActionPendingCatalog[x.action];
      x.statusName = StatusPendingCatalog[x.status];
    });
    return p ? p : [];
  }

  private loadResourcesData() {
    const pending = this.resources?.filter(x => x.pendingResources.length);
    if (pending?.length) {
      const pendingResources = pending.map(x => x.pendingResources).reduce((acc, arr) => [...acc, ...arr]).filter(x => x.createdBy != this.currentUser?.id);
      const p = pendingResources?.filter(x => x.type != PendingResourcesType.Rss_Database && this.currentUser?.userRole?.find(x => x.roleID == Roles.SA));
      p?.map(x => {
        x.userCreatedBy = this.users.find(u => u.id == x.createdBy);
        if (x.shutterName != null) {
          x.resourceName = x.beamlineName + ' - ' + x.shutterName;
        }
        else {
          x.resourceName = x.beamlineName;
        }
        x.actionName = ActionPendingCatalog[x.action];
        x.statusName = StatusPendingCatalog[x.status];
      });
      return p ? p : [];
    }
    else { return []; }
  }

  private loadRSSDatabase() {
    const pending = this.resources?.filter(x => x.pendingResources.length);
    if (pending?.length) {
      const pendingResources = pending.map(x => x.pendingResources).reduce((acc, arr) => [...acc, ...arr]).filter(x => x.createdBy != this.currentUser?.id);
      const p = pendingResources?.filter(x => x.type == PendingResourcesType.Rss_Database && this.tis.hasPrivilege(PrivilegeEnum.RSSTestDBAuth));
      p?.map(x => {
        x.userCreatedBy = this.users.find(u => u.id == x.createdBy);
        if (x.shutterName != null) {
          x.resourceName = x.beamlineName + ' - ' + x.shutterName;
        }
        else {
          x.resourceName = x.beamlineName;
        }
        x.actionName = ActionPendingCatalog[x.action];
        x.statusName = StatusPendingCatalog[x.status];
      });
      return p ? p : [];
    }
    else { return []; }
  }

  getMyPendingChanges(): Promise<PendingApprovals> {
    return new Promise(resolve => {
      this.myPendingCatalogs$ = this.store.select(state => state.MyPendingCatalogs.data);
      this.myPendingCatalogsSubs = this.myPendingCatalogs$.subscribe(data => {
        if (data) {
          const p: PendingApprovals = {
            accelerators: this.loadAcceleratorData(data),
            users: this.loadUsersData(data),
            roles: this.loadRolesData(data),
            notifications: this.loadNotificationData(data),
            resources: this.loadMyResourcesData(data),
            rssDatabase: this.loadMyRSSDatabaseData(data)
          };
          p.total = p.accelerators?.length + p.users?.length + p.roles?.length + p.notifications?.length + p.resources?.length + p.rssDatabase?.length;
          resolve(p);
        }
      });
    });
  }

  getMyPendingChangesIndicator(): Promise<PendingApprovals> {
    return new Promise(resolve => {
      this.myPendingCatalogs$ = this.store.select(state => state.MyPendingCatalogs.data);
      this.myPendingCatalogsSubs = this.myPendingCatalogs$.subscribe(data => {
        if (data) {
          const p: PendingApprovals = {
            accelerators: this.loadAcceleratorData(data),
            users: this.loadUsersData(data),
            roles: this.loadRolesData(data),
            notifications: this.loadNotificationData(data),
            resources: this.loadMyResourcesData(data),
            rssDatabase: this.loadMyRSSDatabaseData(data)
          };
          p.total = p.accelerators?.length + p.users?.length + p.roles?.length + p.notifications?.length + p.resources?.length + p.rssDatabase?.length;
          resolve(p);
        }
      });
    });
  }

  private loadMyResourcesData(data: PendingCatalogs) {
    const pending = this.resources?.filter(x => x.pendingResources.length);
    if (pending?.length) {
      const pendingResources = pending.map(x => x.pendingResources).reduce((acc, arr) => [...acc, ...arr]).filter(x => x.createdBy == this.currentUser?.id);
      const p = pendingResources.filter(x => x.type != PendingResourcesType.Rss_Database);
      p?.map(x => {
        const createdBy = data.pendingResources.find(p => p.id == x.id)?.createdBy;
        x.userCreatedBy = this.users.find(x => x.id == createdBy);
        if (x.shutterName != null) {
          x.resourceName = x.beamlineName + ' - ' + x.shutterName;
        }
        else {
          x.resourceName = x.beamlineName;
        }
        x.actionName = ActionPendingCatalog[x.action];
        x.statusName = StatusPendingCatalog[x.status];
      });
      return p ? p : [];
    }
    else { return []; }
  }

  private loadMyRSSDatabaseData(data: PendingCatalogs) {
    const pending = this.resources?.filter(x => x.pendingResources.length);
    if (pending?.length) {
      const pendingResources = pending.map(x => x.pendingResources).reduce((acc, arr) => [...acc, ...arr]).filter(x => x.createdBy == this.currentUser?.id);
      const p = pendingResources.filter(x => x.type == PendingResourcesType.Rss_Database);
      p?.map(x => {
        const createdBy = data.pendingResources.find(p => p.id == x.id)?.createdBy;
        x.userCreatedBy = this.users.find(x => x.id == createdBy);
        if (x.shutterName != null) {
          x.resourceName = x.beamlineName + ' - ' + x.shutterName;
        }
        else {
          x.resourceName = x.beamlineName;
        }
        x.actionName = ActionPendingCatalog[x.action];
        x.statusName = StatusPendingCatalog[x.status];
      });
      return p ? p : [];
    }
    else { return []; }
  }

  private isSA() {
    return this.currentUser?.userRole?.map(r => r.roleID).includes(Roles.SA);
  }

  private isPC() {
    return this.currentUser?.userRole?.map(r => r.roleID).includes(Roles.PC);
  }
}

export class PendingApprovals {
  accelerators!: PendingAccelerator[];
  users!: PendingUser[];
  roles!: PendingRole[];
  notifications!: PendingNotificationTemplate[];
  resources!: PendingResource[];
  rssDatabase!: PendingResource[];
  total?: number;
}

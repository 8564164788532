import { DragDropModule } from '@angular/cdk/drag-drop';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule, DatePipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule, MatOptionModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldDefaultOptions, MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { QuillModule } from 'ngx-quill';
import { AppRoutingModule } from '../../app-routing.module';
import { MatToolbarModule } from '@angular/material/toolbar';
import { AppComponent } from 'src/app/app.component';
import { ChecklistComponentsModule } from 'src/app/controls/checklist-components/checklist-components.module';
import { SharedModule } from 'src/app/modules/shared.module';
import { ChecklistBuilderEditPopupComponent } from './checklist-builder/checklist-builder-edit-popup/checklist-builder-edit-popup.component';
import { ChecklistBuilderService } from './checklist-builder/checklist-builder.service';
import { ChecklistTempComponent } from './checklist-temp/checklist-temp.component';
import { ChecklistsService } from './checklists.service';
import { ChecklistBuilderDuplicateComponent } from './checklist-builder/checklist-builder-duplicate/checklist-builder-duplicate.component';
import { ChecklistBuilderPlaceholdersComponent } from './checklist-builder/checklist-builder-placeholders/checklist-builder-placeholders.component';
import { ChecklistBuilderComponent } from './checklist-builder/checklist-builder.component';
import { ChecklistBuilderListComponent } from './checklist-builder/checklist-builder-list/checklist-builder-list.component';
import { ChecklistBuilderReviewSummaryComponent } from './checklist-builder/checklist-builder-review-summary/checklist-builder-review-summary.component';
import { ChecklistBuilderSectionComponent } from './checklist-builder/checklist-builder-sections/checklist-builder-section/checklist-builder-section.component';
import { ChecklistBuilderStepComponent } from './checklist-builder/checklist-builder-steps/checklist-builder-step/checklist-builder-step.component';
import { ChecklistBuilderStepsComponent } from './checklist-builder/checklist-builder-steps/checklist-builder-steps.component';
import { ChecklistBuilderTaskComponent } from './checklist-builder/checklist-builder-tasks/checklist-builder-task/checklist-builder-task.component';
import { ChecklistBuilderTasksComponent } from './checklist-builder/checklist-builder-tasks/checklist-builder-tasks.component';
import { ChecklistBuilderStepConfigComponent } from './checklist-builder/checklist-builder-steps/checklist-builder-step/checklist-builder-step-config/checklist-builder-step-config.component';
import { ChecklistBuilderAnnotationEditComponent } from './checklist-builder/checklist-builder-annotations/checklist-builder-annotation-edit/checklist-builder-annotation-edit.component';
import { ChecklistBuilderTaskMoreComponent } from './checklist-builder/checklist-builder-tasks/checklist-builder-task/checklist-builder-task-more/checklist-builder-task-more.component';
import { ChecklistBuilderAnnotationComponent } from './checklist-builder/checklist-builder-annotations/checklist-builder-annotation/checklist-builder-annotation.component';
import { ChecklistBuilderAnnotationsComponent } from './checklist-builder/checklist-builder-annotations/checklist-builder-annotations.component';
import { ChecklistBuilderSettingsGeneralComponent } from './checklist-builder/checklist-builder-settings/checklist-builder-settings-general/checklist-builder-settings-general.component';
import { ChecklistBuilderSettingsPermissionsComponent } from './checklist-builder/checklist-builder-settings/checklist-builder-settings-permissions/checklist-builder-settings-permissions.component';
import { ChecklistBuilderSettingsComponent } from './checklist-builder/checklist-builder-settings/checklist-builder-settings.component';
import { ChecklistBuilderSectionsComponent } from './checklist-builder/checklist-builder-sections/checklist-builder-sections.component';
import { ChecklistBuilderSettingsStatusComponent } from './checklist-builder/checklist-builder-settings/checklist-builder-settings-statuses/checklist-builder-settings-status/checklist-builder-settings-status.component';
import { ChecklistBuilderSettingsStatusesComponent } from './checklist-builder/checklist-builder-settings/checklist-builder-settings-statuses/checklist-builder-settings-statuses.component';
import { ChecklistBuilderReviewSignaturesComponent } from './checklist-builder/checklist-builder-review-summary/checklist-builder-review-signatures/checklist-builder-review-signatures.component';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTreeModule } from '@angular/material/tree';
import { ChecklistBuilderAnnotationsService } from './checklist-builder/checklist-builder-annotations/checklist-builder-annotations.service';
import { ChecklistBuilderRejectComponent } from './checklist-builder/checklist-builder-reject/checklist-builder-reject.component';
import { ChecklistExecComponent } from './checklist-exec/checklist-exec.component';
import { ChecklistExecSideComponent } from './checklist-exec/checklist-exec-side/checklist-exec-side.component';
import { ChecklistSectionComponent } from './checklist-exec/checklist-sections/checklist-section/checklist-section.component';
import { ChecklistSectionsComponent } from './checklist-exec/checklist-sections/checklist-sections.component';
import { ChecklistStepComponent } from './checklist-exec/checklist-steps/checklist-step/checklist-step.component';
import { ChecklistStepsComponent } from './checklist-exec/checklist-steps/checklist-steps.component';
import { ChecklistTaskComponent } from './checklist-exec/checklist-tasks/checklist-task/checklist-task.component';
import { ChecklistTasksComponent } from './checklist-exec/checklist-tasks/checklist-tasks.component';
import { ChecklistTaskCounterComponent } from './checklist-exec/checklist-task-counter/checklist-task-counter.component';
import { ChecklistExecReviewSummaryComponent } from './checklist-exec/checklist-exec-review-summary/checklist-exec-review-summary.component';
import { ChecklistTaskReviewComponent } from './checklist-exec/checklist-tasks/checklist-task-review/checklist-task-review.component';
import { ChecklistTasksAnnotationsEditComponent } from './checklist-exec/checklist-tasks/checklist-tasks-annotations/checklist-tasks-annotations-annotation/checklist-tasks-annotations-edit/checklist-tasks-annotations-edit.component';
import { ChecklistTasksAnnotationsAnnotationComponent } from './checklist-exec/checklist-tasks/checklist-tasks-annotations/checklist-tasks-annotations-annotation/checklist-tasks-annotations-annotation.component';
import { ChecklistTasksAnnotationsComponent } from './checklist-exec/checklist-tasks/checklist-tasks-annotations/checklist-tasks-annotations.component';
import { ChecklistTaskRcpactionsComponent } from './checklist-exec/checklist-tasks/checklist-task-rcpactions/checklist-task-rcpactions.component';
import { ChecklistTaskActionitemsComponent } from './checklist-exec/checklist-tasks/checklist-task-rcpactions/checklist-task-actionitems/checklist-task-actionitems.component';
import { ChecklistRolesChipsComponent } from './checklist-builder/checklist-builder-common/checklist-roles-chips/checklist-roles-chips.component';
import { ChecklistTaskReviewSatisfactionCheckComponent } from './checklist-exec/checklist-tasks/checklist-task-rcpactions/checklist-task-review-satisfaction-check/checklist-task-review-satisfaction-check.component';
import { ChecklistExecRelatedComponent } from './checklist-exec/checklist-exec-related/checklist-exec-related.component';
import { ChecklistExecStandaloneComponent } from './checklist-exec/checklist-exec-standalone/checklist-exec-standalone.component';
import { ChecklistBuilderMapComponent } from './checklist-builder/checklist-builder-map/checklist-builder-map.component';

const appRoutes: Routes = [
  {
    path: 'checklist-builder/:type',
    component: ChecklistBuilderComponent
  },
  {
    path: 'checklist-exec/:type',
    component: ChecklistExecComponent
  },
  { path: 'rss-test-view-mode', component: ChecklistTempComponent }
];

const appearance: MatFormFieldDefaultOptions = {
  appearance: 'outline'
};

@NgModule({
  declarations: [

    // Builder
    ChecklistBuilderComponent,
    ChecklistBuilderSectionsComponent,
    ChecklistBuilderSectionComponent,
    ChecklistBuilderStepsComponent,
    ChecklistBuilderStepComponent,
    ChecklistBuilderStepConfigComponent,
    ChecklistBuilderTasksComponent,
    ChecklistBuilderTaskComponent,
    ChecklistBuilderTaskMoreComponent,
    ChecklistBuilderSettingsComponent,
    ChecklistBuilderSettingsGeneralComponent,
    ChecklistBuilderSettingsPermissionsComponent,
    ChecklistBuilderDuplicateComponent,
    ChecklistBuilderListComponent,
    ChecklistBuilderReviewSummaryComponent,
    ChecklistBuilderReviewSignaturesComponent,
    ChecklistBuilderAnnotationsComponent,
    ChecklistBuilderAnnotationComponent,
    ChecklistBuilderAnnotationEditComponent,
    ChecklistBuilderRejectComponent,
    ChecklistRolesChipsComponent,


    // ChecklistExecPopupComponent,

    // Execution
    ChecklistExecComponent,
    ChecklistExecSideComponent,
    ChecklistSectionsComponent,
    ChecklistSectionComponent,
    ChecklistStepsComponent,
    ChecklistStepComponent,
    ChecklistTasksComponent,
    ChecklistTaskComponent,
    ChecklistTaskCounterComponent,


    // ChecklistExecVerificationListComponent,

    // Temp
    ChecklistTempComponent,

    // Common
    ChecklistBuilderPlaceholdersComponent,
    ChecklistBuilderEditPopupComponent,
    ChecklistTaskReviewComponent,
    ChecklistTasksAnnotationsComponent,
    ChecklistTasksAnnotationsAnnotationComponent,
    ChecklistTasksAnnotationsEditComponent,
    ChecklistBuilderSettingsStatusesComponent,
    ChecklistBuilderSettingsStatusComponent,
    ChecklistExecReviewSummaryComponent,
    ChecklistTaskRcpactionsComponent,
    ChecklistTaskActionitemsComponent,
    ChecklistTaskReviewSatisfactionCheckComponent,
    ChecklistExecRelatedComponent,
    // ChecklistTaskReviewSatisfactionCheckComponent,
    ChecklistExecStandaloneComponent,
    ChecklistBuilderMapComponent,
    // ChecklistExecPrecheckToggleComponent,
    // ChecklistRolesChipsComponent
  ],

  imports: [
    CommonModule,
    BrowserModule,
    NgbModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatMenuModule,
    MatButtonModule,
    MatDialogModule,
    HttpClientModule,
    ScrollingModule,
    HttpClientModule,
    MatSelectModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatChipsModule,
    MatIconModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatListModule,
    MatProgressSpinnerModule,
    MatCardModule,
    MatRadioModule,
    MatStepperModule,
    MatSlideToggleModule,
    MatTabsModule,
    MatTooltipModule,
    MatGridListModule,
    FormsModule,
    MatToolbarModule,
    ReactiveFormsModule,
    MatProgressBarModule,
    DragDropModule,
    MatTreeModule,
    MatExpansionModule,
    MatOptionModule,
    MatSidenavModule,
    DatePipe,
    QuillModule.forRoot(),
    RouterModule.forRoot(
      appRoutes,
      {
        enableTracing: true,
        useHash: true,
        anchorScrolling: 'enabled',
        preloadingStrategy: PreloadAllModules,
      }
    ),
    SharedModule,
    ChecklistComponentsModule,
  ],
  exports: [
    CommonModule,
    MatButtonModule,
    MatNativeDateModule,
    MatIconModule,
    MatSidenavModule,
    MatListModule,
    MatToolbarModule,
    ChecklistComponentsModule,
    ChecklistTaskComponent,
    ChecklistBuilderRejectComponent,
    ChecklistExecStandaloneComponent
  ],
  providers: [
    ChecklistBuilderService,
    ChecklistBuilderAnnotationsService,
    ChecklistsService,
    // PlaceholderHighlightPipe

  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ChecklistsModule {
}

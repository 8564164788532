<div class="card p-0">
  <div class="d-flex align-items-center px-3 py-2" *ngIf="schedule">
    <mat-slide-toggle
      [(ngModel)]="isChecked"
      [disabled]="disabled ?? false"
      color="primary"
      labelPosition="before"
      (ngModelChange)="toggleChanged()"
    >
      <span [innerHTML]="toggleLabel" class="mr-2"></span
      ><span [ngClass]="{ 'bold red': !isChecked, gray: isChecked }"
        >NO</span
      ></mat-slide-toggle
    ><span class="ml-1" [ngClass]="{ 'bold blue': isChecked, gray: !isChecked }"
      >YES</span
    >
  </div>
  <div class="card-header p-3">
    <div class="row pb-0 flex-row">
      <div class="col">
        <div class="row">
          <div class="col-12 text-wrap d-flex flex-row">
            <h6 [innerHTML]="title ?? '' | placeholderHighlight : editor">
              <!-- {{ title }} -->
            </h6>
            <role-chips [roleCodes]="roleCodes" class="ml-3"></role-chips>
          </div>
        </div>
      </div>
      <div class="col-4">
        <div
          class="row-buttons row flex-column d-flex d-print-none align-items-end pr-2"
          style="height: 100%; justify-content: flex-start"
        >
          <div class="col-buttons col-12 p-0">
            <button
              mat-flat-button
              class="float-right"
              *ngIf="!value"
              [disabled]="buttonDisabled()"
              color="warn"
              (click)="click()"
            >
              {{ label }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="card-body p-3" *ngIf="value">
    <div class="row no-gutters">
      <div class="col-12 col-xl-3 fit-content pb-0">Closed By:</div>
      <div class="col-12 col-xl-9 fit-content pb-0">
        <span class="name">{{ name }}</span>
      </div>
    </div>
    <div class="row no-gutters">
      <div class="col-12 col-xl-3 pt-0">
        <small>Date:</small>
      </div>
      <div class="col-12 col-xl-9 fit-content pt-0">
        <span class="date small">{{ date | date : "medium" }}</span>
      </div>
    </div>
  </div>
</div>

import { Injectable, Injector } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Schedule, ScheduleSubdetail } from '../models/schedule';
import { BaseService } from 'src/app/common/base/base.service';

@Injectable({
  providedIn: 'root'
})
export class ScheduleService extends BaseService<Schedule, number> {

  protected rootURL: string;

  constructor(protected override injector: Injector) {
    super(injector, '/schedule');
    this.rootURL = this.BASE_URL + '/schedule';
  }

  // create(data: Schedule): Observable<Schedule> {
  //   return this.http.post<Schedule>(this.rootURL, data);
  // }

  readAll(): Observable<Schedule[]> {
    return this.http.get<Schedule[]>(this.rootURL);
  }

  read(id: number): Observable<Schedule> {
    const url = this.rootURL + '/' + id;
    return this.http.get<Schedule>(url);
  }

  // update(data: Schedule): Observable<Schedule> {
  //   return this.http.put<Schedule>(this.rootURL, data);
  // }

  // delete(id: number): Observable<any> {
  //   const url = this.rootURL + '/' + id;
  //   return this.http.delete<Schedule>(url).pipe(catchError(e => 'Delete: ' + id));
  // }

  readByType(type: number, scheduleCustomTabId: number | null = null): Observable<Schedule[]> {
    const scheduleQuery = scheduleCustomTabId ? '?customTabId=' + scheduleCustomTabId : "";
    return this.http.get<Schedule[]>(this.rootURL + '/type/' + type + scheduleQuery);
  }

  updateStatus(schedule: Schedule): Observable<Schedule> {
    return this.http.put<Schedule>(this.rootURL + '/status', schedule);
  }

  updateCompleteStatus(schedule: Schedule): Observable<any> {
    return this.http.put<any>(this.rootURL + '/status', schedule);
  }

  updateCompleteSubdetailStatus(subdetail: ScheduleSubdetail): Observable<any> {
    return this.http.put<any>(this.rootURL + '/SubdetailStatus', subdetail);
  }

  getScheduleByScheduleResourceId(scheduleResourceId: number): Observable<any> {
    return this.http.get<any>(this.rootURL + '/ScheduleResource/' + scheduleResourceId);
  }
}

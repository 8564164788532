import { distinctUntilChanged, Observable, Subscription } from 'rxjs';
import { Component, Inject, OnInit, OnDestroy, Injector } from '@angular/core';
import { ChecklistTemplate, TemplateType, TemplateRolePermission, ChecklistTemplateStatusEnum } from '../../checklists';
import { BaseComponent } from '../../../../common/base/base.component';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Schedule } from 'src/app/components/schedules/models/schedule';
import { ScheduleType } from 'src/app/components/schedules/models/schedule-type';
import { ProcedureSignatureType } from 'src/app/components/procedure/enums/procedure-signature-type.enum';
import { Procedure } from 'src/app/components/procedure/models/procedure.model';
import { ProcedureService } from 'src/app/components/procedure/services/procedure.service';
import { Resource } from 'src/app/components/catalogs/beamline-catalog/resource/resources';
import { TemplateTypesStoreAndListenersService } from '../../store/template-types/template-types.store';
import { ChecklistBuilderService } from '../checklist-builder.service';
import { DocumentType } from 'src/app/services/documents/documents';
import { MessageTypeEnum } from 'src/app/components/messages/services/message-banner.enums';

@Component({
  selector: 'checklist-builder-duplicate',
  templateUrl: './checklist-builder-duplicate.component.html',
  styleUrls: ['./checklist-builder-duplicate.component.scss']
})

export class ChecklistBuilderDuplicateComponent extends BaseComponent implements OnInit, OnDestroy {

  templateType: TemplateType;
  documentType?: DocumentType;
  templateVersion: ChecklistTemplate;

  duplicatedVersion!: ChecklistTemplate;
  message = { type: 0, text: '' };
  resource: Resource;
  resources!: Resource[];
  resources$!: Observable<Resource[]>;
  resourcesSubs!: Subscription;
  tmpResource!: string | null;
  currentResource!: Resource | null;
  currentSchedule!: Schedule | null;
  filteredResources!: Resource[];
  loadingResources!: boolean;

  name!: string;

  currentTemplateType!: TemplateType;
  templateTypes$!: Observable<TemplateType[]>;
  templateTypes!: TemplateType[];
  filteredTemplateTypes!: TemplateType[];

  checklistTemplates?: ChecklistTemplate[];
  checklistTemplates$!: Observable<ChecklistTemplate[]>;
  checklistTemplatesSubs!: Subscription;
  checklistTemplatesInProgress!: ChecklistTemplate[];

  tmpSchedule!: string;

  schedule?: Schedule;
  schedules!: Schedule[];
  schedules$!: Observable<Schedule[]>;
  filteredSchedules!: Schedule[];
  schedulesSubs!: Subscription;

  scheduleType!: ScheduleType;
  scheduleTypes!: ScheduleType[];
  scheduleTypes$!: Observable<ScheduleType[]>;
  scheduleTypesSubs!: Subscription;

  loadingTypes!: boolean;
  typeCtrl = new FormControl();

  loading!: boolean;
  disabled = false;
  documentRolePermissions: TemplateRolePermission[];

  procedure?: Procedure | null;
  procedures!: Procedure[];
  procedures$!: Observable<Procedure[]>;
  subsProcedures!: Subscription;

  newVersion? = 'New Version';
  subscription!: Subscription;

  temp?: boolean;
  subscribeAsPreparer!: boolean;

  messageType = MessageTypeEnum;

  constructor(
    public dialogRef: MatDialogRef<ChecklistBuilderDuplicateComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _builder: ChecklistBuilderService,
    private templateTypeStore: TemplateTypesStoreAndListenersService,
    private procedureService: ProcedureService,
    protected override injector: Injector
  ) {
    super(injector);
    // this.templateTypeStore.initStore();
    // this.templateTypeStore.initListeners();
    this.templateType = data.templateType;
    this.documentType = data.templateType.documentType;
    this.templateVersion = data.templateVersion;
    this.resource = data.resource;
    this.schedule = data.schedule;

    this.documentRolePermissions = data.documentRolePermissions;
    this.temp = data.temp;
  }

  override ngOnDestroy(): void {
    this.checklistTemplatesSubs?.unsubscribe();
    this.scheduleTypesSubs?.unsubscribe();
  }

  ngOnInit() {
    this.loadTemplateTypes();
    this.loadResources();
  }

  loadTemplateTypes() {
    this.templateTypes$ = this.store.select(x => x.TemplateTypes.data);
    this.subscription = this.templateTypes$.pipe(distinctUntilChanged()).subscribe(data => {
      this.templateTypes = data.filter(x => x.documentTypeID == this.documentType?.id && x.status == 1);
      if (this.duplicatedVersion) {
        this.typeCtrl.setValue(this.duplicatedVersion?.templateType);
      }
      this.filteredTemplateTypes = this.templateTypes.sort((a, b) => this.utils.sort(a.order, b.order));
      this.loadScheduleTypes();
    });
  }

  loadScheduleTypes() {
    this.scheduleTypes$ = this.store.select(state => state.ScheduleTypes.data);
    this.scheduleTypesSubs = this.scheduleTypes$.pipe(distinctUntilChanged()).subscribe(data => {
      this.scheduleTypes = data;
      this.loadProcedures();
    });
  }

  loadProcedures() {
    this.procedures$ = this.store.select(state => state.Procedures.data);
    this.subsProcedures = this.procedures$.pipe(distinctUntilChanged()).subscribe(data => {
      this.procedures = data;
      this.loadSchedules();
    });
  }

  loadSchedules() {
    this.schedules$ = this.store.select(state => state.Schedules.data);
    this.schedulesSubs = this.schedules$.pipe(distinctUntilChanged()).subscribe(data => {
      if (data.length) {
        this.schedules = data;
        this.filteredSchedules = this._builder.filterSchedulesByTemplateType(this.schedules, this.currentTemplateType);
        this.loadChecklistTemplates();
      }
    });
  }

  loadChecklistTemplates() {
    this.checklistTemplates$ = this.store.select(state => state.ChecklistTemplates.data);
    this.checklistTemplatesSubs = this.checklistTemplates$.pipe(distinctUntilChanged()).subscribe(async data => {
      this.checklistTemplates = data;
      this.checklistTemplates = await this._builder.setResources(this.checklistTemplates, this.templateTypes, this.schedules);
      this.checklistTemplatesInProgress = data?.filter(x => x.templateTypeID == this.templateType?.id && x.status != ChecklistTemplateStatusEnum.Active && x.status != ChecklistTemplateStatusEnum.Archived);
    });
  }

  loadResources() {
    this.resources$ = this.store.select(state => state.Resources.data);
    this.resourcesSubs = this.resources$.pipe(distinctUntilChanged()).subscribe(data => {
      if (data.length > 0) {
        this.resources = data.sort((a, b) => this.utils.SortBeamlines(a.name, b.name));
        this.filteredResources = this.resources.filter(x => x.type == this.currentTemplateType?.resourceTypeID);
      }
    });
  }

  close() {
    this.data.duplicatedVersion = this.duplicatedVersion;
    this.dialogRef.close(this.data);
  }

  changedResource() {
    this.filteredResources = this.resources.filter(x => x.name?.toLowerCase().includes(this.tmpResource?.toLowerCase() ?? ''));
  }

  selectedResource(e: MatAutocompleteSelectedEvent) {
    const value = e.option.value;
    this.currentResource = value;
  }

  selectedSchedule(e: MatAutocompleteSelectedEvent) {
    const value = e.option.value;
    this.currentSchedule = value;
    if (this.currentSchedule?.subdetail && this.templateType?.code == 'HFUNC') {
      this.procedure = this.procedures?.find(x => x.procedureMasterID == this.currentSchedule?.subdetail?.procedureMasterID && x.active && x.status == 1);
    }
    else {
      this.procedure = this.procedures?.find(x => x.procedureMasterID == this.currentSchedule?.procedureMasterID && x.active && x.status == 1);
    }
  }

  filterTemplateTypes() {
    this.filteredTemplateTypes = this.templateTypes.sort((a, b) => this.utils.sort(a.order, b.order));
    if (this.filteredTemplateTypes?.length == 1 && this.currentSchedule) {
      this.currentTemplateType = this.filteredTemplateTypes[0];
      this.typeCtrl.setValue(this.currentTemplateType);
    }
  }



  selectedType(e: MatAutocompleteSelectedEvent) {
    const value = e.option.value;
    this.currentTemplateType = value;
    this.reset();
    this.loadResources();
    this.loadSchedules();
  }

  reset() {
    this.name = '';
    this.currentSchedule = null;
    this.currentResource = null;
    this.procedure = null;
    this.tmpResource = '';
    this.tmpSchedule = '';
  }

  enable() {
    // Early exit if there's no current template type
    if (!this.currentTemplateType) return false;

    let result = true;
    this.message = { type: 0, text: '' };
    let message = '';
    this.subscribeAsPreparer = false;

    if (this.duplicatedVersion) {
      message = 'You can close this window now!';
      this.alertAndDisplayMessage(MessageTypeEnum.Success, message);
      result = false;
    }

    if (result) {
      if (this.currentTemplateType.scheduleTypeID) {
        if (this.currentSchedule) {
          const exists = this.checklistTemplatesInProgress?.some(t => t.procedureID == this.currentSchedule?.procedure?.id || t.scheduleID == this.currentSchedule?.id);
          const scheduleType = this.scheduleTypes.find(x => x.id == this.currentTemplateType.scheduleTypeID);

          // Check if the current template version matches the procedure ID
          if (this.templateVersion.procedureID && this.currentSchedule?.procedure?.id == this.templateVersion.procedureID) {
            message = this.getMessage('SameProcedureRevision').description as string;
            this.alertAndDisplayMessage(MessageTypeEnum.Error, message);
            result = false;
          }
          if (result && this.currentSchedule && this.checklistTemplates?.some(t => t.procedureID == this.currentSchedule?.procedure?.id)) {
            const existing = this.checklistTemplates.find(t => t.procedureID == this.currentSchedule?.procedure?.id);
            message = (this.getMessage('AlreadyTemplateWithSameProcedure').description as string)
              .replace('{procedure}', '<b>' + existing?.procedure?.procedureNumber + '</b>')
              .replace('{rev}', '<b>' + existing?.procedure?.revision + '</b>');
            this.alertAndDisplayMessage(MessageTypeEnum.Error, message);
            result = false;
          }
          // Check if the schedule type has a procedure type ID and if a checklist template already exists
          if (result && scheduleType?.procedureTypeID && exists) {
            message = this.getMessage('ExistingTemplate').description as string;
            this.alertAndDisplayMessage(MessageTypeEnum.Error, message);
            result = false;
          }
          // Further checks based on schedule type
          if (result && scheduleType?.procedureTypeID) {
            this._builder.currentSchedule = this.currentSchedule;
            this._builder.currentTemplateType = this.currentTemplateType;
            const privilege = this.procedureService.getPrivilege(this.procedure?.procedureCategory?.procedureTypeID ?? 0, ProcedureSignatureType.Preparer);

            if (privilege) {
              const hasPrivilege = this.hasPrivilege(privilege);
              if (hasPrivilege) {
                const isPreparer = this.is(ProcedureSignatureType.Preparer);
                if (!isPreparer) {
                  message = this.getMessage('ShouldSubscribeAsPreparer').description?.replace('{procedure}', (this.currentSchedule?.procedure?.procedureNumber + ' - Rev: ' + this.currentSchedule?.procedure?.revision)) as string;
                  this.alertAndDisplayMessage(MessageTypeEnum.Info, message);
                  this.subscribeAsPreparer = true;
                }
              } else {
                message = this.getMessage('DuplicateNoPreparer').description?.replace('{procedure}', (this.currentSchedule?.procedure?.procedureNumber + ' - Rev: ' + this.currentSchedule?.procedure?.revision)) as string;
                this.alertAndDisplayMessage(MessageTypeEnum.Warning, message);
                this.subscribeAsPreparer = false;
              }
            } else {
              if (this.currentSchedule && this.procedure) {
                message = this.getMessage('NotAllowedToCreateTemplates').description as string;
                this.alertAndDisplayMessage(MessageTypeEnum.Warning, message);
                result = false;
              }
            }
          }
        }
        else result = false;
      }
      else if (this.currentTemplateType.resourceTypeID) {

      }
      else if (this.currentTemplateType.allowMultipleVersions) {
        if (this.name) {
          const existing = this.checklistTemplates?.find(t => t.name == this.name);
          if (existing) {
            message = this.getMessage('TemplateSameName').description as string;
            this.alertAndDisplayMessage(MessageTypeEnum.Error, message);
            result = false;
          }
        }
        else result = false;
      }
    }

    // Display success message if the template creation conditions are met
    if (result && !message) {
      const name = this.name ? ', Named: <b>' + this.name + '</b>' : '';
      const schedule = this.currentSchedule ? ', Resource: <b>' + this.currentSchedule.scheduleResources?.[0]?.resource?.name + '</b>' : '';
      const resource = this.currentResource ? ', Resource: <b>' + this.currentResource.name + '</b>' : '';
      const procedure = this.currentSchedule ? ', Procedure: <b>' + this.currentSchedule.procedure?.procedureNumber + '</b>' + ', Rev: <b>' + this.currentSchedule.procedure?.revision + '</b>' : '';
      message = 'A new template can be created for Type: <b>' + this.currentTemplateType.name + '</b>' + name + schedule + resource + procedure;
      this.alertAndDisplayMessage(MessageTypeEnum.Success, message);
    }

    return result;
  }


  alertAndDisplayMessage(type: MessageTypeEnum, text: string) {
    // this.alert.message(text, [], type);
    this.message = { type, text };
  }

  is(signatureType: ProcedureSignatureType) {
    const signatures = this.procedure?.procedureSignatures?.filter(x => x.signatureType == signatureType);
    return signatures?.map(x => x.userID).includes(this.getCurrentUser()?.id ?? 0);
  }

  duplicate() {
    if (this.documentType) {
      const checklistTemplate = this._builder.getNewChecklistTemplate(this.documentType, this.currentTemplateType, this.currentSchedule, this.currentResource, this.procedure, this.name);
      this._builder.checklistTemplates = this.checklistTemplates;
      this.loading = true;
      checklistTemplate.status = this.temp == true ? -1 : checklistTemplate.status;
      checklistTemplate.procedureID = this.procedure?.id;
      checklistTemplate.procedureMasterID = this.procedure?.procedureMasterID;
      this._builder.duplicateChecklistTemplate(this.templateVersion, checklistTemplate).subscribe(data => {
        this.loading = false;
        this.disabled = true;
        this.newVersion = data.serialNo;
        this.duplicatedVersion = data;
      },
        error => {
          this.loading = false;
          console.log(error);
        }
      );
    }
  }

  changedSchedule() {
    this.filteredSchedules = this._builder.filterSchedulesByTemplateType(this.schedules, this.currentTemplateType);
    this.filteredSchedules = this.filteredSchedules.filter(x => x.scheduleResources?.some(r => r.resource?.name?.toLowerCase().includes(this.tmpSchedule.toLowerCase())) || x.scheduleResources?.some(r => r.resourceString?.toLowerCase().includes(this.tmpSchedule.toLowerCase())));
  }

}

<div class="row closeBtn">
  <div class="col-md-12">
    <button
      mat-mini-fab
      class="closex float-right"
      color="warn"
      (click)="dialogRef?.close(checklist)"
    >
      <mat-icon>close</mat-icon>
    </button>
    <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
  </div>
</div>
<div [ngClass]="{ content: !isPrintView, 'container-fluid': isPrintView }">
  <div class="row">
    <div class="col-md-12">
      <checklist-sections
        [sections]="sections"
        [disabled]="true"
        [currentChecklist]="checklist"
        [isPrintView]="isPrintView"
      ></checklist-sections>
    </div>
  </div>
</div>
<button
  mat-mini-fab
  aria-label="Print view"
  *ngIf="!isPrintView"
  class="printBtn"
  (click)="sendPrintView()"
>
  <mat-icon>print</mat-icon>
</button>

import { Component, Input, OnInit, OnDestroy, OnChanges, SimpleChanges, Output, EventEmitter, ViewChild } from '@angular/core';
import { Annotation, ChecklistTemplate, AnnotationStatus, ChecklistTemplateStatusEnum, TemplateType } from '../../checklists';
import { ChecklistBuilderReviewSignaturesComponent } from './checklist-builder-review-signatures/checklist-builder-review-signatures.component';
import { Observable, Subscription } from 'rxjs';
import { Store } from '@ngxs/store';
import { ProcedureSignatureType } from 'src/app/components/procedure/enums/procedure-signature-type.enum';
import { ProcedureSignature } from 'src/app/components/procedure/models/procedure-signature.model';
import { Procedure } from 'src/app/components/procedure/models/procedure.model';
import { ChecklistBuilderService } from '../checklist-builder.service';

@Component({
  selector: 'checklist-builder-review-summary',
  templateUrl: './checklist-builder-review-summary.component.html',
  styleUrls: ['./checklist-builder-review-summary.component.scss']
})
export class ChecklistBuilderReviewSummaryComponent implements OnInit, OnDestroy, OnChanges {

  @Input() currentVersion!: ChecklistTemplate;
  @Input() templateType!: TemplateType;

  @Output() requestRefresh = new EventEmitter();
  @ViewChild(ChecklistBuilderReviewSignaturesComponent) signatures!: ChecklistBuilderReviewSignaturesComponent;

  annotations?: Annotation[];
  annotationsFiltered!: Annotation[];
  annotations$!: Observable<Annotation[]>;
  annotationsSubs!: Subscription;

  procedure!: Procedure;
  procedures?: Procedure[];
  procedures$!: Observable<Procedure[]>;
  proceduresSubs!: Subscription;

  preparers?: ProcedureSignature[];
  reviewers?: ProcedureSignature[];
  approvers?: ProcedureSignature[];

  totalAnnotations?: number;
  activeAnnotations?: number;
  checklistTemplateStatusEnum = ChecklistTemplateStatusEnum;

  constructor(
    public _builder: ChecklistBuilderService,
    private store: Store
  ) {

  }

  ngOnDestroy(): void {
    this.annotationsSubs?.unsubscribe();
    this.proceduresSubs?.unsubscribe();
  }

  ngOnInit() {
    this.loadAnnotations();
    this.loadProcedure();
  }

  loadProcedure() {
    this._builder.getProcedure().subscribe(procedure => {
      this.procedure = procedure;
    });
    if (!this.procedure) {
      this.loadProcedures();
    }
  }

  loadProcedures() {
    this.procedures$ = this.store.select(state => state.Procedures.data);
    this.proceduresSubs = this.procedures$.subscribe(data => {
      if (data?.length) {
        this.procedures = data;
        if (this.currentVersion.procedureID) {
          this.procedure = this.procedures.find(p => p.id == this.currentVersion.procedureID) as Procedure;
          this._builder.setProcedure(this.procedure);
        }
      }
    });
  }

  loadAnnotations() {
    this.annotations$ = this.store.select(state => state.Annotations.data);
    this.annotationsSubs = this.annotations$.subscribe(data => {
      if (data.length) {
        this.annotations = data.filter(x => x.checklistTemplateID == this.currentVersion?.id);
        this.countAnnotations();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.loadAnnotations();
    // this.annotations = this.currentVersion?.annotations;
    this.countAnnotations();
    this.preparers = this.procedure?.procedureSignatures?.filter(x => x.signatureType == ProcedureSignatureType.Preparer);
    this.reviewers = this.procedure?.procedureSignatures?.filter(x => x.signatureType == ProcedureSignatureType.Reviewer);
    this.approvers = this.procedure?.procedureSignatures?.filter(x => x.signatureType == ProcedureSignatureType.Approver);
    this.requestRefresh.emit();
  }

  countAnnotations() {
    this.totalAnnotations = this.annotations?.length;
    this.activeAnnotations = this.annotations?.filter(x => x.status == AnnotationStatus.Active)?.length;
  }


}

import { OnChanges, SimpleChanges, OnDestroy, Injector } from '@angular/core';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { RadMeter } from '../../../components/catalogs/meters-catalog/rad-meters';
import { Observable, Subscription } from 'rxjs';
import { ClRadMetersAddComponent } from './cl-rad-meters-add/cl-rad-meters-add.component';
import * as moment from 'moment';
import { ClBaseComponent } from '../cl-base/cl-base.component';
import { FormStatusEnum } from 'src/app/common/enumerations/enumerations';
import { Checklist } from 'src/app/components/checklists/checklists';
import { YesNoDialogComponent } from '../../yes-no-dialog/yes-no-dialog.component';
import { RadMetersService } from 'src/app/components/catalogs/meters-catalog/services/rad-meters.service';
import { RadMetersRefresh, RadMetersUpdate } from 'src/app/components/catalogs/meters-catalog/store/rad-meters/rad-meters.action';

@Component({
  selector: 'cl-rad-meters',
  templateUrl: './cl-rad-meters.component.html',
  styleUrls: ['./cl-rad-meters.component.scss']
})
export class ClRadMetersComponent extends ClBaseComponent implements OnInit, OnChanges, OnDestroy {

  // @Input() id!: string;

  radMetersList!: RadMeter[];
  radMeters!: RadMeter[];
  radMeters$!: Observable<RadMeter[]>;
  radMetersSubs!: Subscription;

  selectedRowIndex!: number;

  dueDateChanging?: number | null;
  sourceReadingChanging?: number | null;
  backgroundReadingChanging?: number | null;

  currentChecklist?: Checklist;

  public dataSource: MatTableDataSource<RadMeter> = new MatTableDataSource<RadMeter>();
  public displayedColumns!: string[];

  space = '_________________';

  constructor(
    protected override injector: Injector,
    private service: RadMetersService
  ) {
    super(injector);
    this.store.dispatch(new RadMetersRefresh());
  }

  override ngOnDestroy(): void {
    this.radMetersSubs?.unsubscribe();
    super.ngOnDestroy();
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    this.currentChecklist = this.checklist as Checklist;

    this.loadRadMeters();
    this.displayedColumns = this.canModify() ? ['serialNumber', 'calibrationDate', 'model', 'type', 'unit', 'sourceReading', 'backgroundReading', 'actions'] : ['serialNumber', 'calibrationDate', 'model', 'type', 'unit', 'sourceReading', 'backgroundReading'];
    this.loadData();
  }

  async ngOnInit(): Promise<void> {

  }

  loadRadMeters() {
    this.radMeters$ = this.store.select(state => state.RadMeters.data);
    this.radMetersSubs = this.radMeters$.subscribe(data => {
      this.radMeters = data;
      this.loadData();
    });
  }

  loadData() {
    if (this.configuration) {
      this.radMetersList = this.utils.JSONparse(this.configuration).data;
      this.radMetersList.map(radMeter => {
        radMeter.backgroundReadingChangedBy = this.getUsers()?.find(u => u.id == radMeter.backgroundReadingChangedByID);
        radMeter.sourceReadingChangedBy = this.getUsers()?.find(u => u.id == radMeter.sourceReadingChangedByID);
        radMeter.calibrationDueDateChangedBy = this.getUsers()?.find(u => u.id == radMeter.calibrationDueDateChangedByID);
        radMeter.radMeterModel = this.radMeters?.find(r => r.id == radMeter.id)?.radMeterModel;
        radMeter.radMeterType = this.radMeters?.find(r => r.id == radMeter.id)?.radMeterType;
        radMeter.radMeterUnit = this.radMeters?.find(r => r.id == radMeter.id)?.radMeterUnit;
      });
      this.dataSource.data = this.radMetersList;
    }
    else {
      this.radMetersList = [];
    }

    if (this.checklist?.status == FormStatusEnum.Active || this.checklist?.status == FormStatusEnum.Draft) {
      this.radMetersList.map(m => {
        const radMeter = this.radMeters?.find(x => x.id == m.id);
        if (radMeter) {
          radMeter.backgroundReadingChangedBy = this.getUsers()?.find(u => u.id == radMeter.backgroundReadingChangedByID);
          radMeter.sourceReadingChangedBy = this.getUsers()?.find(u => u.id == radMeter.sourceReadingChangedByID);
          radMeter.calibrationDueDateChangedBy = this.getUsers()?.find(u => u.id == radMeter.calibrationDueDateChangedByID);
          if (radMeter && m.calibrationDueDate != radMeter.calibrationDueDate) {
            m.calibrationDueDate = radMeter.calibrationDueDate;
            m.calibrationDueDateChangedBy = radMeter.calibrationDueDateChangedBy;
            m.calibrationDueDateChangedByID = radMeter.calibrationDueDateChangedByID;
            m.calibrationDueDateChangedOn = radMeter.calibrationDueDateChangedOn;
            this.saveData();
          }
        }
      });
    }

  }

  change() {
    try {
      this.changed.emit(this.configuration);
    } catch (e) { }
  }

  accept() {
    this.save.emit(this.configuration);
  }

  addMeter() {
    const dialog = this.dialog.open(ClRadMetersAddComponent,
      {
        data: this.radMetersList,
        disableClose: true,
        width: '600px',
        autoFocus: false
      });
    dialog.afterClosed().subscribe(data => {
      if (data) {
        this.radMetersList.push(data);
        this.saveData();
      }
    });
  }

  deleteRowMeter(rowMeter: RadMeter) {
    const confirm = this.dialog.open(YesNoDialogComponent, {
      width: '400px',
      data: {
        message: this.getMessage('RadMeterDeleteConfirm').description,
        icon: 'stop'
      }
    });

    confirm.afterClosed().subscribe(data => {
      if (data) {
        const index = this.radMetersList.findIndex(x => x.id == rowMeter.id);
        this.radMetersList.splice(index, 1);
        this.saveData();
      }
    });
  }

  canModify() {
    return this.hasRoles(this.roleIds) && !this.disabled;
  }


  textSourceChanged(r: RadMeter) {
    const radMeter = this.radMetersList.find(x => x.id == r.id);
    if (radMeter) {
      if (!r.sourceReading) {
        radMeter.sourceReading = null;
        radMeter.sourceReadingChangedBy = null;
        radMeter.sourceReadingChangedByID = null;
        radMeter.sourceReadingChangedOn = null;
      }
      else {
        radMeter.sourceReadingChangedBy = this.getCurrentUser();
        radMeter.sourceReadingChangedByID = this.getCurrentUser()?.id;
        radMeter.sourceReadingChangedOn = new Date();
      }
      this.sourceReadingChanging = null;
      this.saveData();
    }
  }

  textBackgroundChanged(r: RadMeter) {
    const radMeter = this.radMetersList.find(x => x.id == r.id);
    if (radMeter) {
      if (!r.backgroundReading) {
        radMeter.backgroundReading = null;
        radMeter.backgroundReadingChangedBy = null;
        radMeter.backgroundReadingChangedByID = null;
        radMeter.backgroundReadingChangedOn = null;
      }
      else {
        radMeter.backgroundReadingChangedBy = this.getCurrentUser();
        radMeter.backgroundReadingChangedByID = this.getCurrentUser()?.id;
        radMeter.backgroundReadingChangedOn = new Date();
      }
      this.backgroundReadingChanging = null;
      this.saveData();
    }
  }

  changeCalDueDate(e: RadMeter) {
    const confirm = this.dialog.open(YesNoDialogComponent, {
      data: {
        message: this.getMessage('RadMeterChangeCalDate').description,
        icon: 'stop'
      }
    });

    confirm.afterClosed().subscribe(data => {
      if (data) {
        this.dueDateChanging = e.id;
      }
    });
  }

  changeSourceReading(e: RadMeter) {
    this.sourceReadingChanging = e.id;
  }

  changeBackgroundReading(e: RadMeter) {
    this.backgroundReadingChanging = e.id;
  }

  calDueDateChanged(r: RadMeter) {
    const date = moment(r.calibrationDueDate);
    if (date.isValid()) {
      const radMeter = this.radMetersList.find(x => x.id == r.id);
      if (radMeter) {
        radMeter.calibrationDueDate = r.calibrationDueDate;
        radMeter.calibrationDueDateChangedBy = this.getCurrentUser();
        radMeter.calibrationDueDateChangedByID = this.getCurrentUser()?.id;
        radMeter.calibrationDueDateChangedOn = new Date();
        this.dueDateChanging = null;
        this.saveData();
        this.saveCalDueDate(radMeter);
      }
    }
  }

  saveData() {
    const radMetersList: any[] = [];
    this.radMetersList.map(r => {
      radMetersList.push(this.utils.Serialize(r));
    });
    this.configuration = this.utils.JSONstringify({ data: radMetersList });
    this.dataSource.data = this.radMetersList;
    this.changed.emit(this.configuration);
  }

  saveCalDueDate(radMeter: RadMeter) {
    this.service.updateRadMeterCatalog(radMeter).toPromise();
  }

  isDateExpired(date: Date) {
    if (this.currentChecklist?.checklistStatus?.formStatusID !== FormStatusEnum.Closed && this.currentChecklist?.checklistStatus?.formStatusID !== FormStatusEnum.Canceled) {
      const mdate = moment(date);
      return mdate.isBefore(new Date());
    }
    return false;
  }
}

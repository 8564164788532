import { Component, EventEmitter, Injector, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ChecklistTemplate } from '../../checklists';
import { WFSectionLocalResource, WFStepLocalResource, WfTaskLocalResource } from 'src/app/services/work-flow/work-flow';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { FlatTreeControl } from '@angular/cdk/tree';
import { utils } from 'src/app/modules/libs/utils';
import { ChecklistBuilderService } from '../checklist-builder.service';
import { distinctUntilChanged, Observable, Subscription } from 'rxjs';
import { BaseComponent } from 'src/app/common/base/base.component';
@Component({
  selector: 'checklist-builder-map',
  templateUrl: './checklist-builder-map.component.html',
  styleUrls: ['./checklist-builder-map.component.scss']
})
export class ChecklistBuilderMapComponent extends BaseComponent implements OnInit, OnChanges {

  // @Input() checklistTemplate?: ChecklistTemplate | null;
  @Input() showDeleted?: boolean;

  checklistTemplate?: ChecklistTemplate | null;

  wfSectionLocals?: WFSectionLocalResource[];

  checklistTemplates?: ChecklistTemplate[];
  checklistTemplatesFiltered?: ChecklistTemplate[];
  checklistTemplates$!: Observable<ChecklistTemplate[]>;
  checklistTemplatesSubs!: Subscription;

  deletedTaskLocals?: WfTaskLocalResource[];
  deletedTaskLocals$!: Observable<WfTaskLocalResource[]>;
  deletedTaskLocalsSubs!: Subscription;

  deletedStepLocals?: WFStepLocalResource[];
  deletedStepLocals$!: Observable<WFStepLocalResource[]>;
  deletedStepLocalsSubs!: Subscription;

  deletedSectionLocals?: WFSectionLocalResource[];
  deletedSectionLocals$!: Observable<WFSectionLocalResource[]>;
  deletedSectionLocalsSubs!: Subscription;

  // To store expanded node IDs
  expandedNodeSet = new Set<number>();

  private _transformer = (node: WFNode, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      name: node.name,
      level: level,
      type: node.type,
      id: node.id,
      deleted: node.deleted,
      deletedChildren: node.deletedChildren
    } as FlatNode;
  };

  treeControl = new FlatTreeControl<FlatNode>(
    node => node.level,
    node => node.expandable,
  );

  treeFlattener = new MatTreeFlattener(
    this._transformer,
    node => node.level,
    node => node.expandable,
    node => node.children,
  );

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
  hasChild = (_: number, node: FlatNode) => node.expandable;

  constructor(
    protected override injector: Injector,
    private service: ChecklistBuilderService
  ) { super(injector); }

  ngOnChanges(changes: SimpleChanges): void {
    this.loadDeletedSections();
    this.loadDeletedSteps();
    this.loadDeletedTasks();
  }

  ngOnInit(): void {
    // this.loadChecklistTemplates();
    this.service.currentTemplate?.subscribe(data => {
      this.checklistTemplate = data;
      this.buildTree();
    });
  }

  // loadChecklistTemplates() {
  //   this.checklistTemplates$ = this.store.select(state => state.ChecklistTemplates.data);
  //   this.checklistTemplatesSubs = this.checklistTemplates$.pipe(distinctUntilChanged()).subscribe(data => {
  //     if (data?.length) {
  //       this.checklistTemplates = data;
  //       if (this.checklistTemplate) {
  //         const checklistTemplate = this.checklistTemplates.find(x => x.id == this.checklistTemplate?.id);
  //         if (checklistTemplate) {
  //           this.checklistTemplate = checklistTemplate;
  //           this.buildTree();
  //         }
  //       }
  //     }
  //   });
  // }

  // Save expanded node state before refresh
  saveExpandedState() {
    this.expandedNodeSet.clear();
    this.treeControl.dataNodes?.forEach(node => {
      if (this.treeControl.isExpanded(node)) {
        this.expandedNodeSet.add(node.id); // Assuming each node has a unique 'id'
      }
    });
  }

  // Restore expanded node state after refresh
  restoreExpandedState() {
    this.treeControl.dataNodes?.forEach(node => {
      if (this.expandedNodeSet.has(node.id)) {
        this.treeControl.expand(node);
      }
    });
  }

  loadDeletedSections() {
    this.deletedSectionLocals$ = this.store.select((state) => state.DeletedWFSectionLocals.data);
    this.deletedSectionLocalsSubs = this.deletedSectionLocals$.pipe(distinctUntilChanged()).subscribe((data) => {
      this.deletedSectionLocals = data.filter(t => t.checklistTemplateID == this.checklistTemplate?.id);
      this.buildTree();
    });
  }

  loadDeletedSteps() {
    this.deletedStepLocals$ = this.store.select((state) => state.DeletedWFStepLocals.data);
    this.deletedStepLocalsSubs = this.deletedStepLocals$.pipe(distinctUntilChanged()).subscribe((data) => {
      this.deletedStepLocals = data.filter(t => t.checklistTemplateID == this.checklistTemplate?.id);
      this.buildTree();
    });
  }

  loadDeletedTasks() {
    this.deletedTaskLocals$ = this.store.select((state) => state.DeletedWFTaskLocals.data);
    this.deletedTaskLocalsSubs = this.deletedTaskLocals$.pipe(distinctUntilChanged()).subscribe((data) => {
      this.deletedTaskLocals = data.filter(t => t.checklistTemplateID == this.checklistTemplate?.id);
      this.buildTree();
    });
  }

  buildTree() {
    this.saveExpandedState(); // Save expanded nodes before refresh

    const sectionNodes: WFNode[] = [];
    this.wfSectionLocals = this.checklistTemplate?.wfTable?.wfTableLocal?.wfSectionLocals.sort((a, b) => utils.sort(a.order, b.order));
    if (this.showDeleted)
      this.wfSectionLocals = this.wfSectionLocals?.concat(this.deletedSectionLocals?.filter(d => !this.wfSectionLocals?.map(i => i.id).includes(d.id)) ?? []).sort((a, b) => utils.sort(a.order, b.order));
    this.wfSectionLocals?.map(s => {
      const stepNodes: WFNode[] = [];
      let steps = s.wfStepLocals?.sort((a, b) => utils.sort(a.order, b.order));
      if (this.showDeleted)
        steps = steps?.concat(this.deletedStepLocals?.filter(d => d.wfSectionLocalID == s.id && !steps?.map(i => i.id).includes(d.id)) ?? []).sort((a, b) => utils.sort(a.order, b.order));
      steps?.map(x => {
        const taskNodes: WFNode[] = [];
        let tasks = x.wfTaskLocals.sort((a, b) => utils.sort(a.order, b.order));
        if (this.showDeleted)
          tasks = tasks.concat(this.deletedTaskLocals?.filter(d => d.wfStepLocalID == x.id && !tasks.map(i => i.id).includes(d.id)) ?? []).sort((a, b) => utils.sort(a.order, b.order));
        tasks.map(t => {
          const taskNode: WFNode = { number: t.component?.numericHeader ? t.name : '', name: this.formatTaskName(t), type: 'task', id: t.id ?? 0, deleted: (t.logID ?? 0) > 0, deletedChildren: false };
          taskNodes.push(taskNode);
        });
        const stepNode: WFNode = { name: x.name, children: taskNodes, type: 'step', id: x.id, deleted: (x.logID ?? 0) > 0, deletedChildren: taskNodes.some(n => n.deleted) };
        stepNodes.push(stepNode);
      });
      const sectionNode: WFNode = { name: s.number + '. ' + s.name, children: stepNodes, type: 'section', id: s.id, deleted: (s.logID ?? 0) > 0, deletedChildren: stepNodes.some(n => n.deleted) || stepNodes.some(n => n.deletedChildren) };
      sectionNodes.push(sectionNode);
    });
    this.dataSource.data = sectionNodes;
    this.restoreExpandedState(); // Restore expanded nodes after refresh
  }

  hasDeleted() {
    const sectionNodes: WFNode[] = [];
    const sections = this.wfSectionLocals?.concat(this.deletedSectionLocals?.filter(d => !this.wfSectionLocals?.map(i => i.id).includes(d.id)) ?? []);
    sections?.map(s => {
      const stepNodes: WFNode[] = [];
      let steps = s.wfStepLocals;
      steps = steps?.concat(this.deletedStepLocals?.filter(d => d.wfSectionLocalID == s.id && !steps?.map(i => i.id).includes(d.id)) ?? []);
      steps?.map(x => {
        const taskNodes: WFNode[] = [];
        let tasks = x.wfTaskLocals;
        tasks = tasks.concat(this.deletedTaskLocals?.filter(d => d.wfStepLocalID == x.id && !tasks.map(i => i.id).includes(d.id)) ?? []);
        tasks.map(t => {
          const taskNode: WFNode = { number: t.component?.numericHeader ? t.name : '', name: t.name, type: 'task', id: t.id ?? 0, deleted: (t.logID ?? 0) > 0, deletedChildren: false };
          taskNodes.push(taskNode);
        });
        const stepNode: WFNode = { name: x.name, children: taskNodes, type: 'step', id: x.id, deleted: (x.logID ?? 0) > 0, deletedChildren: taskNodes.some(n => n.deleted) };
        stepNodes.push(stepNode);
      });
      const sectionNode: WFNode = { name: s.number + '. ' + s.name, children: stepNodes, type: 'section', id: s.id, deleted: (s.logID ?? 0) > 0, deletedChildren: stepNodes.some(n => n.deleted) || stepNodes.some(n => n.deletedChildren) };
      sectionNodes.push(sectionNode);
    });
    return sectionNodes.some(n => n.deleted || n.deletedChildren);
  }

  formatTaskName(t: WfTaskLocalResource) {
    if (t.component?.textFields)
      return (t.component?.numericHeader ? t.name + ' - ' : '') + utils.stripHTML(t.name3 ?? t.name2 ?? t.name);
    else return t.name;
  }

  scrollToNode(node: FlatNode) {
    const element = document.getElementById(node.type + '-' + node.id);
    if (element) {
      element?.scrollIntoView({ behavior: 'smooth' });
    }
  }
}




interface WFNode {
  name: string;
  type: string;
  id: number;
  number?: string;
  children?: WFNode[];
  deleted: boolean;
  deletedChildren: boolean;
}

interface FlatNode {
  expandable: boolean;
  name: string;
  level: number;
  type: string;
  id: number;
  deleted: boolean;
  deletedChildren?: boolean;
}

import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import * as MyPendingCatalogsActions from './my-pending-catalogs.action';
import { GeneralSignalRResponse } from 'src/app/services/signal-r/general-signal-r-response';
import { SignalRService } from 'src/app/services/signal-r/signal-r.service';
import { SignalRAction } from 'src/app/services/signal-r/signalr-enums';
import { Channels } from 'src/app/store/channels';


@Injectable({
  providedIn: 'root'
})
export class MyPendingCatalogsStoreAndListenersService {
  constructor(private store: Store, private signalRService: SignalRService) { }
  initStore() {
    this.store.dispatch(new MyPendingCatalogsActions.MyPendingCatalogsRefresh());
  }

  initListeners() {
    this.signalRService.hubConnection?.on(Channels.MY_PENDING_CATALOGS, (data: GeneralSignalRResponse) => {
      switch (data.action) {
        case SignalRAction.Get:
          this.store.dispatch(new MyPendingCatalogsActions.MyPendingCatalogsRefresh());
      }
    });
  }
}
